import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './login'
import adminReducer from './admin'
import navReducer from './nav'


export const store = configureStore({
  reducer: {
    login: loginReducer,
    admin: adminReducer,
    nav: navReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
//export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
//export type AppDispatch = typeof store.dispatch