import i18n from '../../i18n/i18n'

export const Statuses = {

    get: function(){
    return(
        [
            {id:1, name: i18n.t('AsigneeStatus - Assigned'), color:"#027DB4"},
            {id:2, name: i18n.t('AsigneeStatus - In Progress'), color:"#C1A5FF"},
            {id:3, name: i18n.t('AsigneeStatus - Not completed'), color:"#F15D5D"},
            {id:4, name: i18n.t('AsigneeStatus - Disabled'), color:"#EEEEEE"},
            {id:5, name: i18n.t('AsigneeStatus - Timeout'), color:"#F15D5D"},
            {id:100, name: i18n.t('AsigneeStatus - Employee - Not assigned'), color:"#aaaaaa"},
            {id:101, name: i18n.t('AsigneeStatus - Employee - Assigned'), color:"#027DB4"},
            {id:102, name: i18n.t('AsigneeStatus - Employee - In Progress'), color:"#C1A5FF"},
            {id:103, name: i18n.t('AsigneeStatus - Employee - Not completed'), color:"#F15D5D"},
            {id:104, name: i18n.t('AsigneeStatus - Employee - Completed'), color:"#08BB4F99"},
            {id:111, name: i18n.t('AsigneeStatus - Employee - Ongoing Assigned'), color:"#027DB488"},
            {id:114, name: i18n.t('AsigneeStatus - Employee - Self Review Completed'), color:"#08BB4F"}, 
        ]
    )}
}

