import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: '1.5vh',//theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      width:'100%',
      height:'18.5vh',
      display:'flex',
    },
    
    noteTitle:{
        fontSize:16,
        color:"#5F6066",
        fontWeight: 400, 
        textAlign:'center'
    },
    noteValue:{
        fontSize:40,
        color:"#5F6066",
        fontWeight: 400,
        textAlign:"start"
    },
    navLink:{
      textDecoration:"none",
    },
    
  });
  
class DashboardNote extends React.Component {
  
  state = {
    open: this.props.open,
    operationIcon: null,
    userName: this.props.userName,
  };
  
  render() {
    const { classes } = this.props;
    return (
        <div style={{}}>

        <Grid container spacing={3} style={{}}>
            
            <Grid item xs={12 } style={{height:'100%'}}>
                <Paper className={classes.paper}>
                        <Grid item xs={5} style={{padding:5}}>
                            <img src={this.props.icon} style={{width:'90%'}} alt="noteicon"></img>
                        </Grid>
                        <Grid item xs={7} style={{padding:0, paddingInlineStart:15}}>
                            <Grid container spacing={3} style={{}}>
                                <Grid item xs={12 } style={{padding:0}}>
                                  <Typography variant={'h4'} style={{textAlign:'start', paddingTop:40}}> {this.props.noteTitle} </Typography>
                                </Grid>
                                <Grid item xs={12 } style={{padding:0}}>
                                  <Link className={classes.navLink} to={this.props.noteLink}><Typography className={classes.noteValue}> {this.props.noteValue>0?this.props.noteValue:'-'} </Typography></Link>          
                                </Grid>
                            </Grid>
                        </Grid>
                </Paper>
            </Grid>
        </Grid>
        </div>   
    );
  }
}
DashboardNote.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(DashboardNote),DashboardNote);