import Cookies  from 'js-cookie';
import env from "react-dotenv";

const EgHttp = {
    fixUri: (base, uri) => {
        if (uri.startsWith("/") || base.endsWith("/")) {
            return base + uri
        } else {
            return `${base}/${uri}`
        }
    },

    fetch: async function(uri, method, body, isAssess){
        const response = await fetch(this.fixUri(env.PROXY, uri), {
                method: method,
                headers: {
                    Accept: 'application/json','Content-Type': 'application/json',
                    authorization:isAssess?Cookies.get('jwtc'):Cookies.get('jwt')
                },
                body: body
        })
        return response;
    },

    get: async function(uri, isAssess){
        const response = await fetch(this.fixUri(env.PROXY, uri), {
                method: "GET",
                headers: {
                    Accept: 'application/json','Content-Type': 'application/json',
                    authorization:isAssess?Cookies.get('jwtc'):Cookies.get('jwt')
                },
        })
        return response;
    },
    getDoc: async function(uri){
        const response = await fetch(this.fixUri(env.PROXY, uri), {
                method: "GET",
                headers: {
                    Accept: 'application/vnd.openxmlformats','Content-Type': 'application/vnd.openxmlformats',
                    authorization:Cookies.get('jwt')
                },
        })
        return response;
    },

    post: async function(uri, body, isAssess){
        const response = await fetch(this.fixUri(env.PROXY, uri), {
                method: "POST",
                headers: {
                    Accept: 'application/json','Content-Type': 'application/json',
                    authorization:isAssess?Cookies.get('jwtc'):Cookies.get('jwt')
                },
                body: body
        })
        return response;
    },

    postFile: async function(uri, file, moreInfo){
        const response = await fetch(this.fixUri(env.PROXY, uri), {
                method: "POST",
                headers: {
                    Accept: 'multipart/form-data',
                    authorization:Cookies.get('jwt'),
                    moreInfo:moreInfo
                },
                body:file,
                file:file
        })
        return response;
    },

    putFile: async function(uri, formData, isAssess) {
        const response = await fetch(this.fixUri(env.PROXY, uri), {
            method: "PUT",
            headers: {
                Accept: 'multipart/form-data',
                authorization:isAssess?Cookies.get('jwtc'):Cookies.get('jwt')
            },
            body: formData
        })

        return response;
    },

    put: async function(uri, body, isAssess){
        const response = await fetch(this.fixUri(env.PROXY, uri), {
                method: "PUT",
                headers: {
                    Accept: 'application/json','Content-Type': 'application/json',
                    authorization:isAssess?Cookies.get('jwtc'):Cookies.get('jwt')
                },
                body: body
        })
        return response;
        
    },
    delete: async function(uri, body, isAssess){
        const response = await fetch(this.fixUri(env.PROXY, uri), {
                method: "DELETE",
                headers: {
                    Accept: 'application/json','Content-Type': 'application/json',
                    authorization:isAssess?Cookies.get('jwtc'):Cookies.get('jwt')
                },
                body: body
        })
        return response;
    },
}

export default EgHttp;