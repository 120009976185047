import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { styled } from '@material-ui/core/styles';
import LangPicker from "../util/LangPicker";


const BarBlue = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 0,
    paddingTop:10
  },
  colorPrimary: {
    backgroundColor: '#fafafb',//theme.palette.gradebg, 
  },
  bar: {
    borderRadius: 3,
    backgroundColor: '#0085AF', 
    
  }
}))(LinearProgress);
const BarPurpule = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 0
    },
    colorPrimary: {
      backgroundColor: '#fafafb'//theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 3,
      backgroundColor: '#C1A5FF', 
    }
  }))(LinearProgress);

  const BarPurpuleWide = withStyles((theme) => ({
    root: {
      height: 20,
      borderRadius: 0
    },
    colorPrimary: {
      backgroundColor: '#fafafb'//theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 3,
      backgroundColor: '#C1A5FF', 
    }
  }))(LinearProgress);
  

const BarBlueN = styled(BarBlue)(({ theme, ...props }) => ({
    
    '& .MuiLinearProgress-bar':{
      borderRadius: 3,
      backgroundColor: '#0085AF', 
      transform: `translateX(${props.value}%) !important`
    },
}))
const BarPurpuleN = styled(BarPurpule)(({ theme, ...props }) => ({
    
  '& .MuiLinearProgress-bar':{
    borderRadius: 3,
    backgroundColor: '#C1A5FF', 
    transform: `translateX(${props.value}%) !important`
  },
}))
const BarPurpuleNWide = styled(BarPurpuleWide)(({ theme, ...props }) => ({
    
  '& .MuiLinearProgress-bar':{
    borderRadius: 3,
    backgroundColor: '#0085AF', 
    transform: `translateX(${props.value}%) !important`
  },
}))
  
export default function CompareBar(props) {
  let value = 0
  let baseline=1
  if (props.value) value = props.value
  if (props.baseline) baseline = 100/props.baseline
  
  return (
    props.wide?
      <BarPurpuleNWide variant="determinate"  value={LangPicker.direction()==='rtl'?(100-(value*baseline)):-(100-(value*baseline))}  style={{width:'100%'}} />   
    :
    <div>
        {
        props.isref?
            <BarBlueN variant="determinate"  value={LangPicker.direction()==='rtl'?(100-(value*baseline)):-(100-(value*baseline))}   />
        :
            <BarPurpuleN variant="determinate"  value={LangPicker.direction()==='rtl'?(100-(value*baseline)):-(100-(value*baseline))}   />
        }
    </div>
  );
}