import React from 'react';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles  } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Topbar from '../components/Topbar'
import { Grid, Typography } from '@material-ui/core';
import EgProgress from '../components/EgProgress';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as AppConstants from '../util/constants/AppConstants';
import EgStatusAlert from '../components/EgStatusAlert';
import EgHttp from '../util/EgHttp';
import EgButton from '../components/EgButton';
import NavigationManager from '../managers/NavigationManager';
import { withCookies, Cookies } from 'react-cookie';
import ExtendedNavBar from '../components/ExtendedNavBar';
import AddLookalikeCampaign from './lookalike/AddLookalikeCampaign';
import LookalikeCampaignCard from './lookalike/LookalikeCampaignCard';
import { setAdminDetails } from "../redux/admin";
import { connect } from "react-redux";
import { setNavCand } from "../redux/nav";
import LookalikeCampaignTable from './lookalike/LookalikeCampaignTable';
import PredefinedModels from './lookalike/PredefinedModels';
import qs from 'qs';
import EgCommonData from '../util/EgCommonData';

const drawerWidth = AppConstants.NavigationDrawerWidth;

const mapStateToProps = state => ({
    admin: state.admin.value,
    nav: state.nav.value
});

const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%',   
  },

  appRight:{
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        background:"#FAFAFA",
      },
      marginRight: drawerWidth
  },
  appLeft:{
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        background:"#FAFAFA",
      },
      marginLeft: drawerWidth
  },

  toolbar:{
      minHeigt:64,
      marginTop:50
  },
  content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width:'100%',
      height:window.innerHeight,

  },
  actionBtns:{
      marginLeft:5,
      marginRight:5
  }
  
});

class Lookalike extends React.Component {
    state = {
      open: this.props.open,
      openAdd: false,
      openCampaignId:null,
      rows:[],
      originalRows:[],
      statusBar:null,
      loading:<EgProgress />,
    };

    constructor(props) {
        super(props);    
        if (!this.props.login.logged) {
            this.props.history.push('/signup') 
        } 
        else{
            NavigationManager.setLast(this.props, '/lookalike')
        }

        this.openAddCampaign = this.openAddCampaign.bind(this);       
        this.handleDrawerState = this.handleDrawerState.bind(this); 
        this.openEditCampaign = this.openEditCampaign.bind(this); 
        this.getCampaigns = this.getCampaigns.bind(this); 
        this.refresh = this.refresh.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this);
        this.search = this.search.bind(this);
        this.openCampaign = this.openCampaign.bind(this); 
        this.closeCampaign = this.closeCampaign.bind(this);   
        this.openPredefinedModels = this.openPredefinedModels.bind(this); 
        this.closePredefinedModels = this.closePredefinedModels.bind(this);
        this.openAddModel = this.openAddModel.bind(this)
    }

    componentDidMount(){
        this.initializeData()
    }

    async initializeData(){
      const teams = await EgCommonData.getTeams();
      this.setState({teams:teams})
      await this.getCampaigns();
    }
    
    async getCampaigns(){
        try{
          this.setState({loading:<EgProgress />})
          let response = await EgHttp.get( '/lookalike/campaigns')
          if (response.status === 401) {
            this.props.history.push('/signin') 
            return;
          }
          let rowsres = await response.json();
          const origres = [...rowsres]
          this.setState({rows:rowsres, loading:null});
          this.setState({originalRows:origres});
          return;
        }
        catch(err){
          console.error(err)
        }
    }
    

    handleStatusClose(){
      this.setState({statusBar:null})
    }

    close(){
      this.setState({singleEmployeeOpen:false})
      this.setState({openEmployeeId:null})
      this.initializeData()
    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }

    
    refresh(status, message){
      this.getCampaigns()
      if (status === 'SUCCESS'){
          this.showStatusBar(message)
      }
    }

    openAddCampaign(){
      this.setState({openCampaignId:null}, ()=> {
        this.setState({openAdd:true})
      })
    }

    openEditCampaign(id){
      this.setState({openCampaignId:id}, ()=> {
        this.setState({openAdd:true})
      })
    }

    openCampaign(id){
      this.setState({openCampaignId:id}, ()=> {
        this.setState({singleCampaignOpen:true})
      })
    }

    openPredefinedModels(){
        this.setState({preDefinedModelsOpen:true})
    }

    closeCampaign(){
      this.setState({singleCampaignOpen:false})
      this.setState({preDefinedModelsOpen:false})
      this.setState({openCampaignId:null})
      this.initializeData()
    }
    closePredefinedModels(){
      this.setState({singleCampaignOpen:false})
      this.setState({preDefinedModelsOpen:false})
      this.setState({openCampaignId:null})
      this.initializeData()
    }

    handleDrawerState(val){
            this.setState({openAdd:val})
    }

    filterItems(arr, query) {
      return arr.filter(function(el) {
        return (el.campaign_name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      })
    }

    search(term){
      this.setState({searchTerm: term})
      if (term && term.length>0){
        let filteredRows;
        filteredRows = this.filterItems(this.state.originalRows, term)
        this.setState({rows: filteredRows})
      }
      else{
        this.setState({rows: this.state.originalRows})
      }
    }
    openAddModel(){
      this.setState({preDefinedModelsOpen:true})
    }
  
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
          this.state.singleCampaignOpen?
          <div className={classes.root} >
            <CssBaseline />
            <ExtendedNavBar navPos={8}></ExtendedNavBar>
            <LookalikeCampaignCard openState={this.state.singleCampaignOpen} campaignId={this.state.openCampaignId} refresh={this.refresh} close={this.closeCampaign}></LookalikeCampaignCard> 
          </div>
          :
          this.state.preDefinedModelsOpen?
          <div className={classes.root} >
            <CssBaseline />
            <ExtendedNavBar navPos={8}></ExtendedNavBar>
            <PredefinedModels openState={this.state.preDefinedModelsOpen}  refresh={this.refresh} close={this.closePredefinedModels} ></PredefinedModels> 
          </div>
          :
          <div className={classes.root} >
            <CssBaseline />
            <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} style={{boxShadow:"0px 0px 0px" }}>
                {this.state.loading}
                <Toolbar >
                    <Topbar login={this.props.login} page={this.props.page} search={this.search}></Topbar>
                </Toolbar>
            </AppBar>

            <ExtendedNavBar navPos={8}></ExtendedNavBar>

            <main className={classes.content}>
                <div className={classes.toolbar} />
              
                <Grid container fullwidth='true' justify="center" direction="row" alignItems="flex-start">
                  <Grid item xs={12 }>
                    <Grid container  justify="left" direction="row" alignItems="flex-start">
                        <Grid item xs={6} justify="left">
                          <Typography variant="h1" > {t('Candidates - Lookalike Candidates - Campaigns')} </Typography>
                        </Grid>
                        <Grid item xs={6} justify="right" style={{textAlign:"end", display:'flex', justifyContent:'end'}} >
                            <EgButton secondary="true" outlined="true" onClick={this.openPredefinedModels} className={classes.actionBtns}>{t('Lookalike - Predefined Models')}</EgButton>
                            <EgButton  onClick={this.openAddCampaign} className={classes.actionBtns}>{t('Lookalike - Add Campaign')}</EgButton>
                        </Grid>
                      
                        <Grid item xs={12} style={{marginTop:10}}>
                              <LookalikeCampaignTable noteTitle={t('Lookalike - Campaign List')} login={this.props.login} editCampaign={this.openEditCampaign} addNewCampaign={this.openAddCampaign} openCampaign={this.openCampaign} rows={this.state.rows} refresh={this.refresh} loading={this.state?false:true} searchTerm={this.state.searchTerm} teams={this.state.teams}> </LookalikeCampaignTable>
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
            </main>
            <AddLookalikeCampaign handleDrawerState={this.handleDrawerState} openState={this.state.openAdd} campaignId={this.state.openCampaignId} teams={this.state.teams} refresh={this.refresh} openAddModel ={this.openAddModel} ></AddLookalikeCampaign> 
            {this.state.statusBar}
          </div>
    );
    
}}

Lookalike.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default connect(mapStateToProps, { setAdminDetails, setNavCand })(withTranslation()(withCookies(withRouter((withStyles(styles))(Lookalike),Lookalike))))
