import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core";
const styles = theme => ({
    
    root: {
      display: 'flex',
      width:'100%'
    },
    answerNum:{  
        padding:2,
        margin:5,
        marginInlineEnd:30,
        textAlign:'center',
        
    },
    answerNumTxt:{
        fontSize:16, 
        border:'1px solid #E4E4E4' , 
        borderRadius:100,
        padding:10,
        paddingLeft:15,
        paddingRight:15,
        width:40
    }
})

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 12,
    margin: `0 0 ${grid}px 0`,
    border: isDragging? '1px solid #11D6A7':'1px solid #E4E4E4',
    borderRadius:4,
    boxShadow: isDragging? '1px 1px 10px 5px #11D6A7':'',
    color:isDragging?'#11D6A7':'#5F6060',
    
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
 
  padding: 0,
  minWidth: 300,
  fontSize: 16,
  backgroundColor: '#ffffff',
  
});

class AnswerPrioritze extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        items: [],//getItems(5)
        selectionArr:[]
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.availAnswers!==this.state.availAnswers ){   
            this.setState({availAnswers:nextProps.availAnswers}); 
            this.buildAnswersObj(nextProps.availAnswers.anslist);    
            
        } 
        if (nextProps.nextButton!==this.state.nextButton ){   
            this.setState({nextButton:nextProps.nextButton});       
        } 
    }

    buildAnswersObj(arr){  
          
        console.log(arr)
        
        let objArr = arr.map((x,index) => {
        let obj={}
            obj={id:(index+1).toString(), content:x}
            return obj;
        });
        this.setState({items:objArr})
        let resultArr = objArr.map(a => a.id);
        this.props.setSelected(0, resultArr);
       
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(this.state.items, result.source.index, result.destination.index);
        this.setState({items});
        let resultArr = items.map(a => a.id);
        this.props.setSelected(1, resultArr);
    }
    
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { classes } = this.props
    return (
        <Grid container>
            <Grid xs={1}>
                <Grid container>
                    {this.state.items.map((item, index) => (
                        <Grid xs={12} className={classes.answerNum}><Typography variant={'h4'} className={classes.answerNumTxt}> {index+1}</Typography></Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid xs={11} style={{paddingInlineStart:10}}>
                <div style={{display:'flex'}}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" style={{fontSize:16, padding:12}}>
                    {(provided, snapshot) => (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        >
                        {this.state.items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                                <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                                >
                                {item.content}
                                </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
                </div>
            </Grid>
        </Grid>
    );
  }
}

AnswerPrioritze.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(AnswerPrioritze);