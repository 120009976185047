import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
//import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import 'react-phone-input-2/lib/style.css'
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import EgProgress from '../../components/EgProgress';

require('typeface-montserrat')

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    phoneText:{
        fontSize:'15px !important', 
        paddingTop:30,
        paddingBottom:30,
        paddingLeft:10,
        fontFamily: 'Montserrat',
        width:'100% !important',
        borderColor: '#E4E4E4 !important',
    },
    dropdownClass1:{
        backgroundColor:theme.palette.whitebg,
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width: 150,
        margin: 5
    },
    sideButton:{
        paddingTop:10,
        paddingBottom:10,
        margin:5
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
});
  
class AddTeam extends React.Component {
  
    constructor(props) {
        super(props);        
        
        this.closeDrawer = this.closeDrawer.bind(this);  
        this.saveTeam = this.saveTeam.bind(this);  
        this.validateName = this.validateName.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.addManager = this.addManager.bind(this)
    }

    state = {
        addOpen: this.props.addOpen,
        teamId: this.props.teamId,
        leaderId: this.props.leaderId,
        context: this.props.context,
        parentId: this.props.parentId,
        nameVal: true,
        selectedLeader:0,
        accounts:null
    };

    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){         
            this.setState({addOpen:nextProps.openState}); 
            if (!this.state.teamId){  
                this.setState({name:null, leaderId: null, teamId: null,  parentId: null})  
            }
                
        }
        if (nextProps.teamId !== this.state.teamId){
            this.setState({teamId:nextProps.teamId});  
            if (nextProps.teamId){
                this.getTeam(nextProps.teamId)   
            }
        }
    }
    componentDidMount(){
        this.getAccounts()    
        this.getTeams()
    }

    async getAccounts(){
        this.setState({loading:<EgProgress />})
        let response = await EgHttp.get('/accounts/org/0' )
        let rowsres = await response.json();
        const origres = [...rowsres]
        this.setState({accounts:rowsres, loading:null}); 
        this.setState({accounts:origres});
        return;
    }

    async getTeams(){
        this.setState({loading:<EgProgress />})
        let response = await EgHttp.get('/teams' ) 
        let rowsres = await response.json();
        this.setState({teams:rowsres, loading:null});
        return;
    }

    validateName(event){
        this.setState({nameVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
   
    validateAll(){
        let failed = false;
        if (!this.state.name) return false
        if (!this.state.name.trim() || this.state.name.trim().length<2) {
            this.setState({nameVal:false})
            failed=true;
        } 
        if (failed) return false;
        return true;
    }

    async getTeam(id){
        let response;
        response = await EgHttp.get('/teams/'+ id)
        let rowsres = await response.json();
        this.setState({name:rowsres.name, selectedAccount:rowsres.leader_id,  selectedParent: rowsres.parent_team_id});
        return;

    }

  
    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    
    closeDrawer(){
        this.setState({addOpen:false, name:null, selectedAccount:null,  selectedParent:null})
        this.props.handleDrawerState()
    }

    addManager(){
        this.props.history.push('/settings?tab='+2)
    }

    async saveTeam(){
        const { t } = this.props;
        if (!this.validateAll()) return;

        let response;
        let body = JSON.stringify({
            teamName: this.state.name.trim(),
            leaderId: this.state.selectedAccount,
            parentId: this.state.selectedParent,
            id:this.state.teamId,
            orgId: this.props.admin.org.id
        })
       
        if (this.state.teamId){
            response = await EgHttp.put('/teams', body)
        }
        else{
            response = await EgHttp.post('/teams', body)
            await response.json();
        }
        
        //EventLog.insert(this.props.admin.org.id, this.state.hrId?this.state.hrId:rowsres.id, this.state.hrId?EventLog.EDIT_CANDIDATE:EventLog.INSERT_CANDIDATE, this.props.admin.account.id)
    
        this.closeDrawer();
        this.props.refresh("SUCCESS", this.state.hrId?t('Teams - Add - Team details updated successfully'):t('Team - Add - New team added successfully'));
        return;

    }

    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>
             {this.state.loading}
            <React.Fragment key={i18n.dir()==='rtl'?'left':'right'}>
           
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:600}}>
            <div style={{width:600}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={8} justify="left">
                                {this.state.hrId?
                                    <Typography variant={'h1'}> {t('Teams - Add - Edit Team')}  </Typography>
                                :
                                    <Typography variant={'h1'}> {t('Teams - Add - Add New Team')}  </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} justify="end" style={{textAlign:"end"}} >
                                 
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:30}}>
                                <Typography variant={'h5'}>{t('Teams - Table - Name')}</Typography>
                                <TextField autoComplete="name" name="name" variant="outlined" required fullWidth='true' id="name" label="" value={this.state.name} onChange={this.handleChange} onBlur={this.validateName}
                                    error={this.state.nameVal?false:true}
                                    helperText={this.state.nameVal?"":t('Teams - Add - Please enter a valid team name')}
                                    className={classes.signupTxt}
                                />
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow}>
                                <Grid container >

                                    <Grid item xs={8}>
                                        <Typography variant={'h5'}>{t('Teams - Table - Leader')}</Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="selectedAccountInp"></InputLabel>
                                            <Select variant="outlined"
                                                labelId="simple-select-label"
                                                id="selectedAccount"
                                                name="selectedAccount"
                                                value={this.state.selectedAccount?this.state.selectedAccount:0}
                                                onChange={this.handleChange}
                                                className={classes.selectBox}
                                            >
                                                <MenuItem value={0}>&nbsp;</MenuItem>
                                            {this.state.accounts?
                                                this.state.accounts.map(function(acc) {
                                                    return <MenuItem value={acc.id} >{acc.firstname + " " + acc.lastname}</MenuItem>
                                                }):''
                                                }    
                                            
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} style={{display:'flex', alignItems:'flex-end', justifyContent:'end'}}>
                                        <EgButton outlined="true" secondary="true" className={classes.sideButton} onClick={this.addManager}>{t('Teams - Add - Add Manager')}</EgButton>  
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Teams - Table - Parent Team')}</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="simple-select-label"></InputLabel>
                                    <Select variant="outlined"
                                        labelId="simple-select-label"
                                        id="selectedParent"
                                        name="selectedParent"
                                        value={this.state.selectedParent?this.state.selectedParent:0}
                                        onChange={this.handleChange}
                                        className={classes.selectBox}
                                    >
                                        <MenuItem value={0}>&nbsp;</MenuItem>
                                       {this.state.teams?
                                        this.state.teams.map(function(t) {
                                            return <MenuItem value={t.id}>{t.name}</MenuItem>
                                        }):''
                                      }    
                                      
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton className={classes.actionButton} onClick={this.saveTeam}>{t('Candidates - Add - Save')}</EgButton>
                                <EgButton outlined="true" secondary="true" className={classes.actionButton} onClick={this.closeDrawer}>{t('Candidates - Add - Cancel')}</EgButton>   
                            </Grid>
                            
                        </Grid>
                </Paper>
            </div>
            </Drawer>
            </React.Fragment>

        </div>     
      
    );
  }
}
AddTeam.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AddTeam),AddTeam)))