
const EgSegmentParser = {
        
    parse: function(str, inner,refresh ){
        if (!str) return
        let openindex = str.indexOf('[')
        if (openindex === -1) return str
        let clsindex,literal, literalStr,tag;
        let literalArr = []
        let gcntr = 0;
        while (openindex > -1 && gcntr++<100){ //Set hard limit incase the text is broken
            tag = str[openindex+1]
            switch(tag){
                
                case 'G': {
                    clsindex = str.indexOf(['/G'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <div style={{ paddingBottom:'1vh', lineHeight:1.7}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'Q': {
                    clsindex = str.indexOf(['/Q'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <ul style={{paddingInlineStart:10,margin:0}}><li style={{paddingTop:'0.5vh', paddingBottom:'0.5vh', fontWeight:600, fontSize:14,lineHeight:1.7, listStylePosition: 'outside'}}>{literalStr}</li></ul>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'L': {
                    clsindex = str.indexOf(['/L'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <ul style={{paddingInlineStart:10,margin:0}}><li style={{paddingTop:'0.1vh', paddingBottom:'0.1vh', fontWeight:400, fontSize:14,lineHeight:1.7, listStylePosition: 'outside'}}>{literalStr}</li></ul>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'A':{
                    clsindex = str.indexOf(['/A'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <div style={{paddingTop:'0vh', paddingBottom:'1vh',paddingInlineStart:10,borderBottom: '1px solid #F2F2F2', lineHeight:1.7}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;

                }
                case 'T':{
                    clsindex = str.indexOf(['/T'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <div style={{paddingTop:'2vh', paddingBottom:'1vh',fontWeight:600, lineHeight:1.7, fontSize:16}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'D':{
                    clsindex = str.indexOf(['/D'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <div style={{  lineHeight:1.7}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                default:{
                    break;
                }
                
            }
        }
        return literalArr
    },

    parseQSummary: function(str, length){
        if (!str) return
        if(!length) length=1
        let openindex = str.indexOf('[')
        let clsindex,literal, literalStr,tag
        let literalArr = []
        let gcntr = 0;
        while (openindex > -1 && gcntr++<100){ //Set hard limit incase the text is broken
            tag = str[openindex+1]
            switch(tag){
                case 'G':{
                    clsindex = str.indexOf(['/G'])
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'C':{
                    //clsindex = str.indexOf(['/C'])
                    str = str.substring(openindex+3,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'A':{
                    clsindex = str.indexOf(['/A'])
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'T':{
                    clsindex = str.indexOf(['/T'])
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'D':{
                    clsindex = str.indexOf(['/D'])
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'Q': {
                    clsindex = str.indexOf(['/Q'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    if (literalStr.length > 120) literalStr = literalStr.substr(0,120) + "..." 
                    literal = <div style={{paddingTop:'1vh', paddingBottom:'1.5vh',borderBottom: '1px solid #F2F2F2'}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'L': {
                    clsindex = str.indexOf(['/L'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    if (literalStr.length > 120) literalStr = literalStr.substr(0,120) + "..." 
                    literal = <div style={{paddingTop:'1vh', paddingBottom:'1.5vh',borderBottom: '1px solid #F2F2F2'}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                default:{
                    break;
                }
            }
        }
        if (literalArr.length > 1 && length>1) return literalArr.slice(0,1)
        if (literalArr.length > 1 && length<=1) return literalArr.slice(0,2)
        else return literalArr
    },

    parseOngoing(str, seed){
        if (!str) return
        if(!seed) seed=1
        let openindex = str.indexOf('[')
        let clsindex,literal, literalStr,tag
        let literalArr = []
        let gcntr = 0;
        while (openindex > -1 && gcntr++<100){ //Set hard limit incase the text is broken
            tag = str[openindex+1]
            switch(tag){
                case 'S': {
                    clsindex = str.indexOf(['/S'])
                    literalStr = str.substring(openindex,clsindex+3)
                    literal = literalStr
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                default:{
                    break;
                }
            }
        }
        console.log(literalArr)
        console.log(literalArr.length)
        if (literalArr.length<3) return literalArr.join('\n')
        else {
            const ind = seed%literalArr.length
            console.log("ind:" + ind)
            let ind2 
            if (ind===literalArr.length-1) ind2=0 
            else ind2=ind+1
            return literalArr[ind] +'\n'+ literalArr[ind2]
        }
        
    }
}
export default EgSegmentParser;