import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {  Table, TableBody, TableCell,TableRow, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
//import LangPicker from '../../../util/LangPicker';
import { ValidityLevels } from '../../../util/constants/ValidityLevels';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EgValidityPatch from '../../../components/EgValidityPatch';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import LogRocket from 'logrocket';



const Accordion = withStyles({
    root: {
        borderTop: '1px solid #f6f6f6',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
        width:'100%'
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#fffffff',
      marginBottom: -1,
      minHeight: '6vh',
      '&$expanded': {
        minHeight: '6vh',
        backgroundColor:'#fafafa'
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      width:'100%'
    },
  }))(MuiAccordionDetails);

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
  });

class VideoScreenAnalysis extends React.Component {
  
    constructor(props) {
        super(props); 
        this.intializeData = this.intializeData.bind(this);         
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:null,
        videoHasNoIssues: true
    };
    
    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
        if (nextProps.expanded!==this.state.expanded ){
            this.setState({expanded:nextProps.expanded});  
        } 
        if (nextProps.videoIssues && nextProps.videoIssues !== this.state.videoIssues){
            this.setState({videoIssues:nextProps.videoIssues});

            const face_changes = nextProps.videoIssues.face_changes
            const no_face = nextProps.videoIssues.no_face
            const two_faces = nextProps.videoIssues.two_faces
            if ((face_changes && Object.values(face_changes).length > 0) ||
                (no_face && Object.values(no_face).length > 0) ||
                (two_faces && Object.values(two_faces).length > 0)
                ) {
              LogRocket.info('Video Validity - Has Video')
              this.setState({videoHasNoIssues:false})
            } else {
              LogRocket.info('Video Validity - No VIDEO')
              this.setState({videoHasNoIssues:true})
            }
          }
    }
    
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    intializeData(report, reportParts){
        this.setState({report})
        this.setState({reportParts})
        if (report && !report.encCameraUrl && !report.encCameraUrlencScreenUrl){
          this.setState({videoNA:true})
        }
        if (!report) return
  }
   
    
    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            <Accordion className={classes.accord} expanded={this.state.expanded === 'video'} onChange={(event)=>{this.props.handleChange(event,'video')}}>
                <AccordionSummary style={{width:'100%'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Grid container style={{paddingTop:'0vh', paddingBottom:'0vh',display:'flex', justifyContent:'center', position:'relative'}}>
                        <Grid xs={3}></Grid>
                        <Grid xs={3} style={{paddingInline:10, display:'flex', alignItems:'center', justifyContent:'start'}} >
                            <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                {t('Report - Validity - Video and Screen Recording Analysis')}
                            </Typography>
                        </Grid>
                        <Grid xs={2} style={{display:'flex', alignItems:'center'}}></Grid>
                        <Grid xs={2} style={{display:'flex', alignItems:'center'}}>
                            <EgValidityPatch validityLevel={this.state.report && this.state.report.validity_obj && !this.state.videoNA? this.state.report.validity_obj.video:ValidityLevels.UNKNOWN}/>                                      
                        </Grid>
                        <Grid xs={2}></Grid>     
                    </Grid> 
                </AccordionSummary>
                
                <AccordionDetails style={{textAlign:'start'}}>
                    <Grid container>
                        <Grid xs={12} style={{paddingInlineStart:20,  paddingTop:'0.5vh'}}>
                            <Table><TableBody><TableRow>
                            <TableCell style={{border:0, padding:5, width:0, paddingBottom:0}}><OndemandVideoOutlinedIcon style={{fontSize:16, color:'#666666'}}/></TableCell>
                            <TableCell style={{border:0, padding:5}}><Typography variant={'h3'} > {t('Report - Validity - Video and Screen Recording Analysis')}</Typography></TableCell>
                            </TableRow></TableBody></Table>
                            
                        </Grid>
                        <Grid xs={12} style={{paddingInline:52, paddingBottom:'3vh'}}>
                            <Typography variant={'h5'} style={{color:'#58666B', textAlign:'start'}}>{t('Report - Validity - Video and Screen Recording Analysis')}</Typography>
                        </Grid>
                        <Grid xs={12} style={{paddingInline:52, paddingBottom:'6vh'}}>
                              {
                                this.state.videoNA?
                                  <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                      {t('Report - Validity - Video is nt available')}
                                  </Typography>
                              :
                              this.state.videoHasNoIssues?
                                <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                  {t('Report - Validity - No suspicious activity deetcted')} 
                                </Typography>
                              :
                                <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
  
                                </Typography>
                              }
                              {
                                this.state.videoHasNoIssues?
                                  <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>

                                  </Typography>
                                :
                                  this.state.videoIssues.face_changes && Object.values(this.state.videoIssues.face_changes).length?
                                    <Grid container>
                                        <Grid xs={12}>
                                          <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                            {t('Report - Validity - Face Change')}
                                          </Typography>
                                        </Grid>
                                          {this.state.videoIssues.face_changes.map((frame) => (
                                            <Grid xs={2}>
                                              <div><img src={frame.url} style={{width:200}}></img></div>
                                              <div style={{fontSize:12}}>{frame.issue_text}</div>
                                            </Grid>
                                          ))}
                                    </Grid>
                                  :
                                    <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                      
                                    </Typography>
                            }
                              {
                                this.state.videoHasNoIssues?
                                  <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>

                                  </Typography>
                              :
                                  this.state.videoIssues.two_faces && Object.values(this.state.videoIssues.two_faces).length?
                                    <Grid container>
                                        <Grid xs={12}>
                                          <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                          {t('Report - Validity - Too Many Faces')}
                                          </Typography>
                                        </Grid>
                                        {this.state.videoIssues.two_faces.map((frame) => (
                                        <Grid xs={2}>
                                            <div><img src={frame.url} style={{width:200}}></img></div>
                                            <div style={{fontSize:12}}>{frame.issue_text}</div>
                                        </Grid>
                                        ))}
                                    </Grid>
                                  :
                                    <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                        
                                    </Typography>
                            }
                              {
                                this.state.videoHasNoIssues?
                                  <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>

                                  </Typography>
                                :
                                  this.state.videoIssues.no_face && Object.values(this.state.videoIssues.no_face).length?
                                    <Grid container>
                                        <Grid xs={12}>
                                          <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                            {t('Report - Validity - No Face')}
                                          </Typography>
                                        </Grid>
                                        {this.state.videoIssues.no_face.map((frame) => (
                                        <Grid xs={2}>
                                            <div><img src={frame.url} style={{width:200}}></img></div>
                                            <div style={{fontSize:12}}>{frame.issue_text}</div>
                                        </Grid>
                                        ))}
                                    </Grid>
                                  :
                                    <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>

                                    </Typography>
                            }                          
                        </Grid>
                    </Grid> 
                </AccordionDetails>
            </Accordion>
    );
  }
}
VideoScreenAnalysis.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(VideoScreenAnalysis),VideoScreenAnalysis);