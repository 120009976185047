import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Typography, Checkbox, FormControlLabel} from '@material-ui/core';
import testIntro from '../../img/enable_cam.svg';
import camera_enabled_bg from '../../img/camera_enabled_bg.svg';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import AppUtils from '../../util/AppUtils';
import EgActivityRecorder from '../../util/EgActivityRecorder';

const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%'
  },
  
  logo:{
    width:'100%',
    paddingTop:5,
    paddingBottom:5,
    
    },
    makesure:{
        width:350,
        paddingTop:5,
        paddingBottom:5,
        
        },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'55vh',
        display:'flex', 
        alignItems:'center',
      },
    
    check:{
        color: '#666666',
        fontSize: '10px !important',
    },
    
    actionButton:{
        fontSize:12,
        margin: 5
    },  
});

const GreenCheckbox = withStyles({
    root: {
      color: "#0085AF",
      padding:5,
      '&$checked': {
        color: "#0085AF",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  
class AssessVideoRec extends React.Component {
  
    state = {
        candidate: this.props.candidate,
        videoApproved: false,
        camApproveChk: true,
        privacyApproveChk: true,
        cameraTrack: null
    };

    constructor(props) {
        super(props);  
        this.start = this.start.bind(this);  
        this.cameraBoxChange = this.cameraBoxChange.bind(this)
        this.privacyBoxChange = this.privacyBoxChange.bind(this)
        this.setState({videoApproved: this.props.videoApproved})
        this.setState({cameraTrack: this.props.cameraTrack})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});    
        }
        if (nextProps.videoApproved !== this.state.videoApproved) {
            this.setState({videoApproved: nextProps.videoApproved})
        }
        if (nextProps.cameraTrack !== this.state.cameraTrack) {
            this.setState({cameraTrack: nextProps.cameraTrack})
            
            if (nextProps.cameraTrack) {
                console.log('Play camera on local video player')
                nextProps.cameraTrack.play("local-player");
            }
        }
    }

    openHelp(){
        window.FreshworksWidget('open');
    }


    start() {
        this.props.continue();
    }

    cameraBoxChange(event){
        this.setState({camApproveChk:!this.state.camApproveChk})
        
    }
    privacyBoxChange(event){
        console.log(event.target.checked)
        this.setState({privacyApproveChk:!this.state.privacyApproveChk})
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div>
                <Paper className={classes.paper}>
                    <Grid container justify="left" direction="row" alignItems="flex-start" style={{ paddingLeft: 30, paddingRight: 30  }}>
                        <Grid item xs={6} >
                            <Grid container justify="left" direction="row" >
                                <Grid item xs={12} >
                                    <Typography variant={'h1'}>{t('Assessment - Opener - Allow Camera')}</Typography>
                                </Grid>

                                <Grid item xs={12} style={{ paddingTop: 30 }}>
                                    <Typography variant={'h4'}>{t('Assessment - Opener - Click Allow on the message above to allow using your camera')}</Typography>
                                </Grid>
                                
                                
                                <Grid item xs={12} style={{ paddingTop: 30 }}>
                                    {this.props.requireVideo ?
                                        <FormControlLabel className={classes.check}
                                            control={<GreenCheckbox checked={this.state.camApproveChk} onChange={this.cameraBoxChange} inputProps={{ 'aria-label': 'primary checkbox', fontSize: 10 }} ></GreenCheckbox>}
                                            label={<Typography variant={'h5'}> {t('Assessment - Opener - allow camera')}</Typography>} />
                                    : 
                                        <div style={{ paddingBottom: 30 }}></div>
                                    }
                                </Grid>
                                
                                <Grid item xs={2} style={{ paddingTop: 20 }}>
                                    <EgButton variant="contained" 
                                        className={classes.actionButton} 
                                        id="continue-btn" onClick={this.start} 
                                        disabled={!this.state.videoApproved || !this.state.camApproveChk || !this.state.privacyApproveChk || this.props.videoFrameNotValidated}
                                        onMouseEnter={EgActivityRecorder.handleMouseEnter}
                                        onMouseLeave={EgActivityRecorder.handleMouseLeave}
                                    >
                                        {t('Assessment - Opener - Continue')}
                                    </EgButton>
                                </Grid>
                                <Grid item xs={10} style={{ paddingTop: 20 }}>
                                    <Typography variant={'h5'} style={{color:'#FC264D', paddingTop:15, paddingInlineStart:15}}>{this.props.videoFrameNotValidated?t('Assessment - Opener - No face detected'):''}</Typography>
                                </Grid>

                                <Grid item xs={12} style={{ paddingTop:'3vh'}}>
                                <Typography variant={'h4'} style={{paddingBottom:10, textAlign:'start'}}><a href='#0' style={{textDecoration:'none', color:'#0085AF'}} onClick={this.openHelp}>{t('Assessment - Opener - Why do I need to allow camera')}</a></Typography>
                            </Grid> 
                            </Grid>
                        </Grid>

                        <Grid item xs={6} justify="center" style={{ display: "flex", alignItems: "center" }}>
                            {this.props.requireVideo ?
                                <div style={{ width: '100%',  padding: 3, position: 'relative' }}>
                                    {!this.state.videoApproved?
                                        <div style={{ position: 'absolute', top: 3, left: 3 }}>
                                            <img src={testIntro} className={classes.logo} alt="testIntro" />
                                        </div>
                                        :
                                        <div>
                                            <div style={{ position: 'absolute', top: 0, left: 0 }}>
                                                <img src={camera_enabled_bg} className={classes.logo} alt="makeSure" />
                                            </div>
                                            <div id="local-player" style={AppUtils.isMobile()?{ position: 'absolute', top: '3.95vh', left: '1.2vw', width: '37vw', height: '24vh', display: 'flex', flexDirection: 'column', flex: 1, border:'8px solid #ffffff', borderRadius:5 }:{ position: 'absolute', top: '3.95vh', left: '4.2vw', width: '16.9vw', height: '24vh', display: 'flex', flexDirection: 'column', flex: 1, border:'8px solid #ffffff', borderRadius:5 }}>
                                            </div>
                                        </div>
                                    
                                    }
                                </div>
                                :
                                <div></div>
                            }
                        </Grid>

                    </Grid>
                </Paper> 
                {this.state.errDialog}
            </div>
    );
  }
}
AssessVideoRec.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(AssessVideoRec),AssessVideoRec);


