

//----------------------------------------------------------------------------------------
// AppConstants - this file includes all the constant variable used on multiple
// components across the application
//---------------------------------------------------------------------------------------


export const AVATAR_COLORS = ["#8080FF", "#70B603", "#81D3F8","#F15D5D","#D8B253", "#E18906","#3B5998", "B61411", "#9077F6", "#46484D"];

export const Views = {
	DASHBOARD: "Dashboard",
    ASSESSMENT: "Assessment",
	ASSIGNED: "Assigned",
	CANDIDATES: "Candidates",
	REPORTS:"Reports",
    SETTINGS:"Settings",
    SIGNUP:"Signup",
    SIGNIN:"Signin",
    COMPANY:"Company",
    PROFILE:"Profile",
    CONFIRM:"Confirm",
    RESETPASS:"ResetPassword",
    BACKOFFICE: "BackOffice",
    ALL_EMPLOYEES:"AllEmployees",
    TEAMS:"Teams",
    LOOKALIKE: "Lookalike",
    ANALYTICS:"Analytics",
    REMOTE_INTERVIEW:"RemoteIntervew"
}

export const NavigationDrawerWidth = 200;
export const AssessmentTime = 1000*60*30; //30 min
export const Roles = {
    ADMIN:{name:'Admin', value:null},
    VIEWER:{name:'Viewer', value:1},
}

export const AssessmentStatus = {
    NOT_STARTED:0,
    IN_PROGRESS:1,
    COMPLETED:2
}

export const EgHRStatus = {
    
    HR_STATUS_CANDIDATE_NEW: 0,
    HR_STATUS_CANDIDATE_ASSIGNED: 1,
    HR_STATUS_CANDIDATE_ASSESS_IN_PROGRESS: 2,
    HR_STATUS_CANDIDATE_ASSESS_ABORTED: 3,
    HR_STATUS_CANDIDATE_ASSESS_COMPLETED: 4,
    HR_STATUS_CANDIDATE_ASSESS_TIMED_OUT: 5,
    HR_STATUS_EMPLOYEE_NEW: 100,
    HR_STATUS_EMPLOYEE_INITIAL_ASSIGNED: 101,
    HR_STATUS_EMPLOYEE_INITIAL_IN_PROGRESS: 102,
    HR_STATUS_EMPLOYEE_INITIAL_ABORTED: 103,
    HR_SATAUS_EMPLOYEE_INITIAL_COMPLETED: 104,
    HR_STATUS_EMPLOYEE_ONGOING_ASSIGNED: 111,
    HR_STATUS_EMPLOYEE_ONGOING_IN_PROGRESS: 112,
    HR_STATUS_EMPLOYEE_ONGOING_ABORTED: 113,
    HR_SATAUS_EMPLOYEE_ONGOING_COMPLETED: 114,
    HR_STATUS_MANAGER_ONGOING_ASSIGNED: 211,
    HR_STATUS_MANAGER_ONGOING_IN_PROGRESS: 212,
    HR_STATUS_MANAGER_ONGOINGL_ABORTED: 213,
    HR_SATAUS_MANAGER_ONGOING_COMPLETED: 214

}
    



