import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import GradeBar from '../../components/GradeBar';
import LangPicker from '../../util/LangPicker';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import EgHtmlToolTip from '../../components/EgHtmlToolTip';
import EgEmptyState from '../../components/EgEmptyState';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    videoBox:{
        border:'1px solid #E4E4E4',
        height:'100%',
        overflow:'auto'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    },  
   
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:theme.palette.text.black,
        border:0,
        paddingBottom:'0.5vh',
        paddingTop:'0.5vh',
        textAlign:'start',
    },
  });

const VIDICTIVENESS = 1002

class SingleReportPersonalityBox extends React.Component {
  
    constructor(props) {
        super(props);    
        this.setDuration = this.setDuration.bind(this)
        this.initialize = this.initialize.bind(this)
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:{},
        displayScores:[],
    };
    componentDidMount(){
        console.log("REPORT AFTER MOUNT")
        
        if (this.props.report ) {
            this.setState({report: this.props.report})
            this.initialize(this.props.report)
        }
        if (this.props.reportParts) {
            this.setState({reportParts: this.props.reportParts})
        }
        
    }

    initialize(report){
        if (report){
            let scores = [...report.assessment.assessment_scores]
            if (report.assessment.type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT){
                scores = scores.filter(score => score.trait_id !== VIDICTIVENESS)
            }
            const sortedScores = scores.sort((a,b) => a.trait_adjusted_score?b.trait_adjusted_score - a.trait_adjusted_score : b.trait_weighted_score*100/b.trait_potential_score - a.trait_weighted_score*100/a.trait_potential_score);
            const displayScoresPositive = sortedScores.filter(score => score.trait.dark===false && !score.trait.is_dominant && score.trait.is_display)
            this.setState({displayScoresPos:displayScoresPositive.slice(0,4)})
            const displayScoresNeg = sortedScores.filter(score => score.trait.dark===true && !score.trait.is_dominant && score.trait.is_display)
            this.setState({displayScoresNeg:displayScoresNeg.slice(0,4)})
            
            if (report.dominant_traits && report.dominant_traits[0] === 0){
                this.setState({invalidReport:true})
            }
        }
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.report && nextProps.report !== this.state.report){
            console.log("CHANGING REPORT FROM UPDATE")
            this.setState({report:nextProps.report}); 
            this.initialize(nextProps.report)
        }
        if (nextProps.reportParts && nextProps.reportParts !== this.state.reportParts){
            this.setState({reportParts:nextProps.reportParts});  
        }
    }

    setDuration(sec){
        this.setState({videoDuration:sec})
    }
    isValidityIssues(id){
        if (this.state.report){
            if (this.state.report.validity_obj && this.state.report.validity_obj.tooSlowAnswers &&  this.state.report.validity_obj.tooSlowAnswers.length > 0){
                const ind = this.state.report.validity_obj.tooSlowAnswers.findIndex(({ trait }) => trait.id === id )
                if (ind > -1){
                    return true
                }
            }
            if (this.state.report.validity_obj && this.state.report.validity_obj.discrapencyArr && this.state.report.validity_obj.discrapencyArr.length > 0){
                const indd = this.state.report.validity_obj.discrapencyArr.findIndex((t) => t.trait_id===id )
                if (indd > -1){
                    return true
                }
            }
            return false
        }
    }

   
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const thisIns = this
        return (
            <Paper elevation={0} className={classes.videoBox} >
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Report - Summary - Top Productive Traits')} </Typography>
                    <div style={{overflow:'auto', paddingTop:'0.5vh'}}>
                    <Table className={classes.tableInner} aria-label="inner table" >
                        <TableBody >
                            {this.state.displayScoresPos && this.state.displayScoresPos.length?
                            this.state.displayScoresPos.map((score, index) => (
                                this.state.invalidReport?
                                <TableRow key={"char" + index }  >
                                    <TableCell className={classes.tableCell} style={{width:'82%', paddingInlineStart:20}}>
                                        <Typography variant={'h4'} style={{paddingBottom:'0.8vh'}}>
                                            &nbsp;
                                        </Typography>
                                        <GradeBar variant="determinate" value={0} negative={score.trait.dark} boundaries={score.trait.boundaries}></GradeBar>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{width:'18%',paddingBottom:0,paddingInlineStart:0, paddingTop:'2.6vh'}}>
                                        <div style={{direction:'ltr'}}><span style={{fontWeight:500}}>{0}</span><span style={{fontSize:9}}> / 100</span></div>
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow key={"char" + index }  >
                                    <TableCell className={classes.tableCell} style={{width:'82%', paddingInlineStart:20}}>
                                        
                                        <Typography variant={'h4'} style={{paddingBottom:'0.5vh'}}>
                                            {LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name}
                                            {thisIns.isValidityIssues(score.trait.id)?
                                            <EgHtmlToolTip title={t('Report - Validity - Validity Issues')} body={t('Report - Validity - There appear to be validity issues')} context={'warn'}
                                                innerElement={<WarningRoundedIcon style={{marginInline:5,color:'#F9A846', marginBottom:-5, cursor:'pointer'}} onClick={(event)=>{this.props.moveTo(3,event)}}/>}
                                            />:''
                                        }
                                        </Typography>
                                        
                                        {score.trait_adjusted_score?
                                            <GradeBar variant="determinate" wide value={score.trait_adjusted_score} negative={score.trait.dark} boundaries={score.trait.boundaries} isInvalid={this.state.report.validity_obj?this.state.report.validity_obj.overallRisk===2:false}></GradeBar>:
                                            <GradeBar variant="determinate" wide value={score.trait_weighted_score*100/score.trait_potential_score } negative={score.trait.dark} boundaries={score.trait.boundaries} isInvalid={this.state.report.validity_obj?this.state.report.validity_obj.overallRisk===2:false}></GradeBar>
                                        }
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{width:'18%',paddingBottom:0,paddingInlineStart:0, paddingTop:'2.6vh'}}>
                                        {score.trait_adjusted_score?
                                        <div style={{direction:'ltr', textAlign:'center'}}><span style={{fontWeight:500}}>{Math.floor(score.trait_adjusted_score)}</span><span style={{fontSize:9}}> / 100</span></div>:
                                        <div style={{direction:'ltr', textAlign:'center'}}><span style={{fontWeight:500}}>{Math.floor(score.trait_weighted_score*100/score.trait_potential_score)}</span><span style={{fontSize:9}}> / 100</span></div>
                                        }
                                    </TableCell>
                                </TableRow>
                                
                               
                            )):
                            <div style={{paddingTop:'4.5vh', paddingBottom: '4vh'}}>
                                <EgEmptyState title={t('Report - No Initial Report Found')} size={'small'} />
                            </div>}
                            {this.state.displayScoresPos && this.state.displayScoresPos.length?
                            <TableRow key={"chare" } > 
                                {this.state.invalidReport?
                                    '':<TableCell style={{border:0, paddingInlineStart:20, paddingTop:'2vh', textAlign:'start'}}><a href="#0" onClick={()=>{this.props.moveTo(1)}} className={classes.link}>{t("Report - Single - more")}</a></TableCell>
                                }
                            </TableRow>:''}
                        </TableBody>
                    </Table> 
                    </div>
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Report - Summary - Top Counterproductive Traits')} </Typography>
                    <div style={{overflow:'auto', paddingTop:'0.5vh'}}>
                    <Table className={classes.tableInner} aria-label="inner table" >
                        <TableBody >
                            {this.state.displayScoresNeg && this.state.displayScoresNeg.length?
                            this.state.displayScoresNeg.map((score, index) => (
                                this.state.invalidReport?
                                <TableRow key={"char" + index }  >
                                    <TableCell className={classes.tableCell} style={{width:'82%', paddingInlineStart:20}}>
                                        <Typography variant={'h4'} style={{paddingBottom:'0.5vh'}}>
                                            &nbsp;
                                        </Typography>
                                        <GradeBar variant="determinate" value={0} negative={score.trait.dark} boundaries={score.trait.boundaries}></GradeBar>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{width:'18%',paddingBottom:0,paddingInlineStart:0, paddingTop:'2.6vh'}}>
                                        <div style={{direction:'ltr'}}><span style={{fontWeight:500}}>{0}</span><span style={{fontSize:9}}> / 100</span></div>
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow key={"char" + index }  >
                                    <TableCell className={classes.tableCell} style={{width:'82%', paddingInlineStart:20}}>
                                        <Typography variant={'h4'} style={{paddingBottom:'0.5vh'}}>
                                            {LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name}
                                            {thisIns.isValidityIssues(score.trait.id)?
                                            <EgHtmlToolTip title={t('Report - Validity - Validity Issues')} body={t('Report - Validity - There appear to be validity issues')} context={'warn'}
                                                innerElement={<WarningRoundedIcon style={{marginInline:5,color:'#F9A846', marginBottom:-5, cursor:'pointer'}} onClick={(event)=>{this.props.moveTo(3,event)}}/>}
                                            />
                                            :''}
                                        </Typography>
                                        {score.trait_adjusted_score?
                                            <GradeBar wide variant="determinate" value={score.trait_adjusted_score} negative={score.trait.dark} boundaries={score.trait.boundaries} isInvalid={this.state.report.validity_obj?this.state.report.validity_obj.overallRisk===2:false}></GradeBar>:
                                            <GradeBar wide variant="determinate" value={score.trait_weighted_score*100/score.trait_potential_score } negative={score.trait.dark} boundaries={score.trait.boundaries} isInvalid={this.state.report.validity_obj?this.state.report.validity_obj.overallRisk===2:false}></GradeBar>
                                        }
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{width:'18%',paddingBottom:0,paddingInlineStart:0, paddingTop:'2.6vh'}}>
                                        {score.trait_adjusted_score?
                                        <div style={{direction:'ltr', textAlign:'center'}}><span style={{fontWeight:500}}>{Math.floor(score.trait_adjusted_score)}</span><span style={{fontSize:9}}> / 100</span></div>:
                                        <div style={{direction:'ltr', textAlign:'center'}}><span style={{fontWeight:500}}>{Math.floor(score.trait_weighted_score*100/score.trait_potential_score)}</span><span style={{fontSize:9}}> / 100</span></div>
                                        }
                                    </TableCell>
                                </TableRow>
                            )):
                            <div style={{paddingTop:'4.5vh', paddingBottom: '2vh'}}>
                                <EgEmptyState title={t('Report - No Initial Report Found')} size={'small'} />
                            </div>}
                            <TableRow key={"chare" } > 
                                {this.state.displayScoresNeg && this.state.displayScoresNeg.length?
                                    <TableCell style={{border:0, paddingInlineStart:20, paddingTop:'2vh', textAlign:'start'}}><a href="#0" onClick={()=>{this.props.moveTo(1)}} className={classes.link}>{ t("Report - Single - more")}</a></TableCell>:''
                                }
                            </TableRow>
                        </TableBody>
                    </Table> 
                    </div>
            </Paper>
        );
    }
}
SingleReportPersonalityBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportPersonalityBox),SingleReportPersonalityBox);