import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import GradeBar from '../../components/GradeBar'
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import CompareCVtoRequirements from './CompareCVtoRequirements';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:'1.5vh'
    },
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:theme.palette.text.black,
        border:0,
        paddingBottom:'1.5vh',
        paddingTop:'1.5vh',
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0',
       
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto'
    },
    boxTitle:{
        padding:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'0px solid #F0F0F0',
        
    },
    
    overlay:{
        display:"block", 
        position:'absolute',
        top:0, 
        left:0, 
        zIndex:1000, 
        background:"#f0f0f066",
        height:'100%',
        width:'100%',
    },
    
});

  
const sim_boundaries=[ //TODO: get this from the db
    {"name":"low", "bottom":0, "top":55}, 
    {"name":"medium", "bottom":55, "top":75}, 
    {"name":"high","bottom":75, "top":100} 
]

class SingleCampaignCVComp extends React.Component {
  
    constructor(props) {
        super(props);           
        this.moveTo = this.moveTo.bind(this);
        this.handleDrawerState = this.handleDrawerState.bind(this)
    }

    state = {
        campaign: this.props.campaign?this.props.campaign:{},
        loading:true,
        overlayDiv: [],
    };

    componentDidMount(){
        if (this.props.campaign ) {
            this.setState({campaign: this.props.campaign})
            if (this.props.campaign){
                this.enrichCandidates(this.props.campaign)
            }
        }
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.campaign && nextProps.campaign!==this.state.campaign ){
            this.setState({campaign:nextProps.campaign}); 
            this.enrichCandidates(nextProps.campaign) 
        }
       
        if (nextProps.loading === this.state.loading) {
            this.setState({loading: nextProps.loading})
        }
    }

    moveTo(dest){
        this.props.moveTo(null, dest)
    }

    async enrichCandidates(campaign){
        try{
            const cand_with_scores = campaign.lookalike_campaign_compared_candidates.map(function(cand){
                if(cand.human_resource && cand.human_resource.custom_properties && cand.human_resource.custom_properties.cv && cand.human_resource.custom_properties.cvFit){
                    try{
                        const gr = JSON.parse(cand.human_resource.custom_properties.cvFit).grade
                        cand['cvGrade'] = gr
                        cand['cvCompMeeting'] = JSON.parse(cand.human_resource.custom_properties.cvFit).meetReq
                        cand['cvCompNotMeeting'] = JSON.parse(cand.human_resource.custom_properties.cvFit).notMeetingReq
                        cand['cvSummary'] = JSON.parse(cand.human_resource.custom_properties.cvFit).summary
                        cand['cvSuggestions'] = JSON.parse(cand.human_resource.custom_properties.cvFit).suggestions
                        return cand
                    }
                    catch(err){
                        console.error("Failed to aprse cfFit as JSON")
                        console.error(err.message)
                        cand['cvGrade'] = null
                        return cand
                    }
                }
                cand['cvGrade'] = null
                return cand
            })
            const cands = cand_with_scores.sort((a, b) => (a.cvGrade < b.cvGrade) ? 1 : -1)
            this.setState({candidatesWithScore:cands})
        }
        catch(err){
            console.error(err)
        }
    }

    openCompare(id){
        this.setState({openCompareId:id}, ()=> {
          this.setState({singleCompareOpen:true})
        })
    }
    
    handleDrawerState(val){
        this.setState({singleCompareOpen:val})
    }

    setHover(e, onOff, index){
        const { t } = this.props;
        let items = [...this.state.overlayDiv];
        const indexwp=index//+(this.state.rowsPerPage*this.state.page)
        let item = {...items[indexwp]};
        items = [];
        if (onOff){
            item =  <div className={this.props.classes.overlay}> 
                        <Grid container  justify="left" direction="row" alignItems="center" style={{height:'100%'}}>
                            <Grid item xs={12}  alignItems="center" style={{display:"flex", justifyContent:"flex-end", padding:10, paddingInline:30, height:'100%'}} >
                                <EgButton  onClick={()=>{this.openCompare(indexwp)}}>{t('Lookalike - Single - Compare CV')}</EgButton>  
                            </Grid>
                        </Grid>     
                    </div>     
        }
        else{
            item = null;
        }
        items[index] = item;
        this.setState({overlayDiv:items});
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:10, borderTop:'1px solid #e8e8e8'}}>
            <Grid container >
            
            <Grid item xs={12} className={classes.formRow} >
                <Paper elevation={0} className={classes.summaryBox} >
                    
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - CV Compare')} </Typography>
                    <div style={{overflow:'auto', paddingTop:'0.5vh'}}>
                    <Table className={classes.tableInner} aria-label="inner table" >
                        <TableBody >
                            <TableRow key={"charhead"}  >
                                <TableCell className={classes.tableCell} style={{width:'20%', paddingInlineStart:20, fontSize:12, fontWeight:500}}> 
                                        {t('Lookalike - Single - Candidate Name')}
                                </TableCell>
                                <TableCell className={classes.tableCell} colSpan={2} style={{width:'47%', paddingInlineStart:0, fontSize:12, fontWeight:500}}>
                                        {t('Lookalike - Single - CV Similarity')}
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{width:'38%',paddingBottom:0,paddingInlineStart:0, paddingTop:'0.3vh'}}>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                            
                            {this.state.candidatesWithScore && this.state.candidatesWithScore.length?
                            this.state.candidatesWithScore.map((cand, index) => (
                                <TableRow key={cand.human_resource.id} className={classes.tableRowBody} >
                                <TableCell className={classes.tableInnerCell} colSpan={4} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                    <div>
                                    <Table className={classes.tableInner} aria-label="inner table" > 
                                        <TableRow key={"char" + index } >
                                            <TableCell className={classes.tableCell} style={{width:'20%', paddingInlineStart:20}}>
                                                <Typography variant={'h4'} style={{paddingBottom:'0.8vh'}}>
                                                    {cand.human_resource.firstname + " " + cand.human_resource.lastname}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'2%',paddingBottom:0,paddingInlineStart:0, paddingTop:'0.3vh'}}>
                                                <div style={{direction:'ltr', textAlign:'end'}}><span style={{fontWeight:500}}>{" "}</span><span style={{fontSize:12}}>{cand.cvGrade || cand.cvGrade===0? cand.cvGrade+ "%":'' }</span></div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'40%', paddingInlineStart:20,paddingTop:18, textAlign: 'start'}}>
                                                {cand.cvGrade || cand.cvGrade===0?
                                                    <GradeBar wide={true} variant="determinate" value={cand.cvGrade} negative={false} boundaries={sim_boundaries}></GradeBar>:
                                                    <Typography variant={'h4'} style={{paddingBottom:'0.6vh',paddingTop:'0.6vh', backgroundColor:'#eeeeee', textAlign:'center', paddingLeft:0,paddingRight:0}}>{t('Lookalike - Single - No CV Found')}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'38%',paddingBottom:0,paddingInlineStart:0, paddingTop:'0.3vh'}}>
                                            &nbsp;
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                    </div>
                                    {cand.human_resource && cand.human_resource.custom_properties && cand.human_resource.custom_properties.cv && cand.human_resource.custom_properties.cvFit?this.state.overlayDiv[index]:''}
                                </TableCell>  
                                    </TableRow>
                               
                            )):
                            ''}
                            
                        </TableBody>
                    </Table> 
                    </div>
                
                </Paper>
            </Grid>

            </Grid>
            <CompareCVtoRequirements handleDrawerState={this.handleDrawerState} openState={this.state.singleCompareOpen} candidates={this.state.candidatesWithScore} comparedId={this.state.openCompareId}  refresh={this.refresh} boundaries={sim_boundaries} />               
        </div>
    );
  }
}
SingleCampaignCVComp.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleCampaignCVComp),SingleCampaignCVComp);