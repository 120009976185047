import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import Moment from 'react-moment';
import playbtb from '../../img/play_btn.svg';
import novideo from '../../img/no-video.png';

const REMOTE_INTERVIEW = 7;

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    videoBox:{
        border:'1px solid #E4E4E4',
        height:'34.8vh',
        overflow:'auto'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    },  
    videoPlayer:{
        position:'relative', 
        '& video':{
            borderRadius: '8px'
        }
    }
  });

class SingleReportSummaryRemoteBox extends React.Component {
  
    constructor(props) {
        super(props);    
        
        this.setDuration = this.setDuration.bind(this)
        this.moveToVideo = this.moveToVideo.bind(this)
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:{},
    };

    componentWillReceiveProps(nextProps) {   
        if (nextProps.report && nextProps.report !== this.state.report){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts !== this.state.reportParts){
            this.setState({reportParts:nextProps.reportParts});  
        }
    }

    setDuration(sec){
        this.setState({videoDuration:sec})
    }
    moveToVideo(){
        this.props.moveTo(REMOTE_INTERVIEW)
    }


    render() {
        const { classes } = this.props;
        const { t } = this.props;
        console.log(this.state.report)
        return (
            <Paper elevation={0} className={classes.videoBox} style={this.props.small?{maxHeight:'30vh'}:{maxHeight:'34.8vh'}}>
                <a href='#0' onClick={this.moveToVideo} className={classes.link}>
                <Typography variant={'h4'} className={classes.boxTitle}>{t('NavBar - Remote Interview')} 
                    {this.state.report.assessment && this.state.report.assessment.remote_interview && this.state.report.assessment.assessment_attributes.media?
                    <span style={{backgroundColor: '#efefef', padding:6, fontWeight:500, marginLeft:10, marginRight:20, borderRadius:5, fontSize:12}}><Moment format="mm:ss [min]">{this.state.videoCameraDuration*1000}</Moment></span> :''}
                </Typography>
               
                <Grid container justify='center' style={{paddingTop:7}}>
                    <Grid xs={12} style={{padding:10, display:'flex', justifyContent:'center'}}>
                        {this.state.report.assessment && this.state.report.assessment.remote_interview?
                        <div style={{position:'relative', paddingTop:'2.5vh'}} >
                            <a href='#0' onClick={this.moveToVideo}>
                            <ReactPlayer  url={this.state.report?this.state.report.encCameraUrl+'#t=2.0':''} controls={false}  height='15vh' width='15vw' onDuration={this.setDuration} className={classes.videoPlayer} />
                            <img src={playbtb} alt='play' style={{position:'absolute', top:'8vh', left:'6.5vw'}}></img>
                            </a>
                        </div>:
                        <div style={{paddingTop:20}}>
                            <img src={novideo} style={{width:60, margin:20}} alt="novideo"></img>
                            <Typography variant='h4'>{t('Report - Video - Video is not available')}</Typography>
                        </div>
                        }

                    </Grid>
                </Grid>
                </a>
            </Paper>
        );
    }
}
SingleReportSummaryRemoteBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportSummaryRemoteBox),SingleReportSummaryRemoteBox);