import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import EgHttp from '../../util/EgHttp';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    actionButton:{
        width: 100,
        margin: 5
    },
    messageHeader:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.secondary.light,
    },
    messageHeaderSuc:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.primary.light,
    },
    dialogCV:{
        maxWidth:'none',
        '&.MuiDialog-paperWidthSm':{
            maxWidth:1100
        }
    }
    
})

class CVDialog extends React.Component {
  
    state = {
        open: this.props.open,
        candidate:this.props.candidate,
        CvFileName: this.props.CvFileName
    };

    constructor(props) {
        super(props);  
        
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 

        if (this.props.hrId){
            this.setState({hrId:this.props.hrId});  
            this.downloadCV(this.props.hrId);       
        }
    }

    componentDidMount(){
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.hrId!==this.state.hrId ){             
            this.setState({hrId:nextProps.hrId});  
            this.downloadCV(nextProps.hrId)       
        }
        if (nextProps.CvFileName!==this.state.CvFileName ){         
            this.setState({CvFileName:nextProps.CvFileName});           
        }
        
    }

    async downloadCV(hrId){
        try{
            console.log(hrId)
            let response = await  EgHttp.getDoc('/candidates/downloadcv/'+ hrId)  
            let pdf,html
            if (this.props.cv.endsWith('.pdf')){
                pdf = await response.blob();
                this.setState({pdfBody:pdf})
            }
            else{
                const html = await response.json()
                this.setState({htmlBody: html.value })
            }
            
           
        }
        catch(err){
            console.error(err)
        }
    }
    

    close(){
        this.handleClose();
    }

    
    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };

    render() {
        const { classes } = this.props;
        const { t } = this.props;
       
        return (
            <div>
            
            <Dialog open={this.state.open} onClose={this.handleClose} PaperProps={{className: classes.dialogCV}} >
                <DialogContent name='Engard CV Dialog' style={{maxHeight:760}}>
                    
                       <Grid container justify='left'>
                            
                           <Grid item xs={12} className={classes.messageHeaderSuc}>
                               <Typography variant={'h2'} style={{color:'#043241', textAlign:'center'}}>{"CV"}</Typography>
                            </Grid>
                           
                            <Grid item xs={12} style={{paddingBottom:20, paddingTop:30}}>
                                <Typography variant={'h4'}>{this.props.message}</Typography> 
                                <div style={{maxWidth:1024, width:1024, maxHeight:560, height:560}}>
                                {this.state.pdfBody?
                                    
                                    <object data={URL.createObjectURL(this.state.pdfBody)} type="application/pdf" width="100%" height="100%">
                                    <p>Your browser does not support PDFs. <a href={URL.createObjectURL(this.state.pdfBody)}>Download the PDF</a> instead.</p>
                                    </object>
                                :
                                    <div style={{maxWidth:1024, width:1024, maxHeight:560, height:560, overflow:'scroll'}}>
                                        <div contentEditable='true' dangerouslySetInnerHTML={{ __html: this.state.htmlBody }}></div>
                                    </div>
                                }
                                
                                </div>
                            </Grid>
                            
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:0}} >
                                <EgButton secondary="true" outlined="true" className={classes.actionButton} onClick={this.close}>{t("ErrorDialog - Close")}</EgButton>  
                            </Grid>
                       </Grid>
                </DialogContent>
                
                
            </Dialog>
            </div>
        );
    }
}

CVDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(CVDialog),CVDialog)))