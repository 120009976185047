import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Box, Grid, Slider, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%',
      minWidth:100,
    },
    
    
})

const PrettoSlider = styled(Slider)({
    color: '#e1e1e1',
    
    '& .MuiSlider-rail': {
        border: '4px solid #e1e1e1',
        width:4,
        borderRadius:8,
        //width:'100%'
      },
      '& .MuiSlider-track': {
        border: '4px solid #13A7D6' ,
        color: '#13A7D6',
        width:4,
        borderRadius:8,
        //width:'100%',
      },
    '& .MuiSlider-thumb': {
      height: 18,
      width: 18,
      backgroundColor: '#fff',
      border: '2px solid #13A7D6',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      marginLeft: -4,
      marginBottom: -10,
      
    },
    '& .MuiSlider-markLabel':{
        left:50,
        color:'#5F6066'
    },
    '& .MuiSlider-markLabelActive':{
        border:'1px solid #00AC83',
        borderRadius:4,
        minWidth:250,
        padding:5,
        backgroundColor:'#11D6A7',
        color:'#ffffff',
        left:45,
    },
    '& .MuiSlider-mark':{
        width:0
    }
})
  

  class EgSliderWithValue extends React.Component {
  
    state = {
        value:0
    };

    constructor(props) {
        super(props); 
        this.handleSliderChange = this.handleSliderChange.bind(this); 
        this.handleInputChange = this.handleInputChange.bind(this); 
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.value!==this.state.value ){             
          this.setState({value:nextProps.value});     
        }
         
    }
    handleSliderChange(event ,value){
        this.setState({value})
        this.props.updateValue(value)

    }
    handleInputChange(event){
        let intVal = parseInt(event.target.value) 
        if (intVal <= 0 || event.target.value.length===0) intVal=0;
        if (intVal > 100) intVal = 100;
        this.setState({value:intVal})
        this.props.updateValue(intVal)
        
    }
    handleBlur(){
        
    }

    render(){

        return (
            <Box sx={{ width: 450 }}>
            
            <Grid container spacing={2} alignItems="center" style={{width:350, paddingBottom:10}}>
            <Grid item xs={4}>
                <TextField
                    variant={'outlined'}
                  value={this.state.value}
                  size="small"
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                /> 
            </Grid> 
              <Grid item xs={8}>
                <PrettoSlider
                  value={this.state.value}
                  onChange={this.handleSliderChange}
                  aria-labelledby="input-slider"
                  size='medium'
                />
              </Grid>
              
              
            </Grid>
          </Box>
        )
        }
    }

    
EgSliderWithValue.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(EgSliderWithValue);