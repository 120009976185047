import React from 'react';
import PropTypes from 'prop-types';

import Countdown,{ zeroPad } from "react-countdown";
import { withStyles  } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import LangPicker from '../../util/LangPicker';

const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%'
    },
    timer:{
        color:'#5F6066',
        fontSize: 16,
        fontWeight:400,
        display: 'flex',
        alignItems: 'center',
        backgroundColor:'#FAFAFB',
        border:'1px solid #F0F0F0',
        width: 200,
        padding:5
      } 
})


class TimerCountdown extends React.Component {
  
    state = {
        start:false,
        timeToTest: Date.now() + this.props.initialTestTime,//Date.now() + AppConstants.AssessmentTime,
        intialTestTime: this.props.initialTestTime
    };

    constructor(props) {
        super(props);  
       
        this.onTick = this.onTick.bind(this);  
    }
    componentWillReceiveProps(nextProps) {      
        if (nextProps.start!==this.state.start ){             
          this.setState({start:nextProps.start});  
          this.setState({timeToTest: Date.now() + this.state.initialTestTime})  
        }
        if (nextProps.initialTestTime!==this.state.initialTestTime ){             
          this.setState({initialTestTime:nextProps.initialTestTime});   
          this.setState({timeToTest: Date.now() + nextProps.initialTestTime})  
      }  
    }

    onTick(event){
      this.props.updateTimeLeft(event.total)
    }

    render(){
        const { classes } = this.props;
        const { t } = this.props;
        const renderer = ({ hours, minutes, seconds, completed }) => {
          if (completed) {
            // Render a completed state
            this.props.timeout()
            return <Completionist />;
          } else {
            return (
            <span>
               {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span> )
          }};
        const Completionist = () => <span>{t('Assessment - Questions - Your time is up')}</span>;
        return (
                this.state.start?
                <div className={classes.timer} style={{width:LangPicker.current()==='es'?270:190}} >
                    <AccessTimeIcon style={{marginRight:5, fontSize:18}}/>
                    <Typography style={{fontSize:16, color:'#5F6066', paddingInlineEnd:5, paddingInlineStart:5}}>{t('Assessment - Questions - Time left')}</Typography>
                    <Countdown date={this.state.timeToTest} zeroPadTime={3} renderer={renderer} onTick={this.onTick}>
                      <Completionist />                    
                    </Countdown>
                </div>  
                :
                <div></div>
            );
        }
    }

    
TimerCountdown.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(TimerCountdown),TimerCountdown);