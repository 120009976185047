import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import EgSliderWithValue from '../../components/EgSliderWithValue';

const styles = theme => ({
    
    root: {
      display: 'flex',
      width:'100%'
    },
      
})

class AnswerDistribute extends React.Component {
  
    state = {
        selected: -1,
        availAnswers:null,
        lastQuestion:null,
        nextbtn: [],
        values:[]
    };

    constructor(props) {
        super(props);  
        this.moveToNext = this.moveToNext.bind(this)
        this.updateValue = this.updateValue.bind(this)
        this.distributeInitial = this.distributeInitial.bind(this)
        this.distributeRest = this.distributeRest.bind(this)
        
        this.setState({availAnswers: this.props.availAnswers})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.availAnswers!==this.state.availAnswers ){   
            this.setState({availAnswers:nextProps.availAnswers});   
            this.distributeInitial(nextProps.availAnswers.anslist.length)
        } 
        if (nextProps.nextButton!==this.state.nextButton ){   
            this.setState({nextButton:nextProps.nextButton});       
        } 
    }

    distributeInitial(numOfAns){
        let init = 100/numOfAns
        let values = [];
        let totalDist = 0
        for (let i=0;i<numOfAns-1;i++){
            values[i] = Math.round(init);
            totalDist += Math.round(init)
        }
        values[numOfAns-1] = 100 - totalDist;
        this.setState({values})
        this.props.setSelected(1, values);
    }

    
    moveToNext(){
        this.cancelFadeTimer()
        this.setState({fadeTimer: null})
        this.clearBtnArr()
        this.props.nextButton()
    }

    updateValue(val, index){
        this.distributeRest(index, val)
        
    }
    distributeRest(index, value){
        let numOfAns = this.state.availAnswers.anslist.length
        let values = this.state.values;
        let leftDistVal = 0
        let totalDist = 0
        for (let j=0;j<index;j++){
            values[j] = this.state.values[j]
            totalDist += this.state.values[j];
        }
        values[index] = value;
        totalDist += value;
        let totalLeft = 100 - totalDist
        if (totalLeft >= 0 && index<numOfAns-1){
            leftDistVal = totalLeft/(numOfAns-(index+1))

            for (let i=index+1;i<numOfAns-1;i++){
                values[i] = Math.round(leftDistVal);
                totalDist += Math.round(leftDistVal)
            }
            values[numOfAns-1] = 100 - totalDist;
        }
        else{
            for (let m=0;m<numOfAns;m++){
                values[m] = 0;   
            }
            values[index] = value;
            totalDist = value;
            totalLeft = 100-totalDist;
            leftDistVal = totalLeft/(index)
            for (let k=0;k<index-1;k++){
                values[k] = Math.round(leftDistVal);
                totalDist += Math.round(leftDistVal);
            }
            values[index-1] = 100 - totalDist;
        }
        this.setState({values})
        this.props.setSelected(1, values);
    }

    render(){
        return (
           
                    this.props.availAnswers?
                    this.props.availAnswers.anslist.map((answer, index) => (
                        <Grid container>
                            <Grid xs={6}>
                                <EgSliderWithValue value={this.state.values[index]} updateValue={(value)=>this.updateValue(value,index)} variant={'outlined'} style={{width:350}}/>
                            </Grid>
                            <Grid xs={6} style={{paddingInlineStart:20, paddingBottom:15, display:'flex', alignItems:'center'}}>
                                <Typography variant='h2' style={{fontWeight:400, color:'#5F6066', fontSize:16}}>{answer}</Typography>
                            </Grid>
                        </Grid>
                       
                    )
                    ):
                    ''
        )
    }
}   
AnswerDistribute.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(AnswerDistribute);