import { ValidityLevels } from "../util/constants/ValidityLevels"

export default function EgValidityPatch(props) {
  const validityLevels = ValidityLevels.get()
  return (

      <div style={{maxWidth:props.maxw}}>
          {
          props.validityLevel !== null && props.validityLevel !== undefined && !isNaN(props.validityLevel) ?
                <div  style={{backgroundColor:validityLevels[props.validityLevel].color,width:200, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600, maxWidth:props.maxw} }>{validityLevels[props.validityLevel].name}</div>
                :
                <div  style={{backgroundColor:validityLevels[3].color,width:200, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600 , maxWidth:props.maxw} }>{validityLevels[3].name}</div>
        }
      </div>
    );
  }