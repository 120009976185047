import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import GradeBar from '../../components/GradeBar'
import GradeCircle from '../../components/GradeCircle'
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import Drawer from '@material-ui/core/Drawer';
import ExtendedTraitEvaluation from './ExtendedTraitEvaluation';
import LangPicker from '../../util/LangPicker';
import ReportsManager from '../../managers/ReportsManager';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
    
    tableCell:{
        border:0,
        paddingBottom:'1.2vh',
        paddingTop:'1.2vh',
    },
    tableCellBars:{
        fontSize: 14,   
        color: '#043241',
        paddingTop:'1.2vh',
        paddingBottom:'1.2vh',
        border:0
    },
    
    tableInner:{
        paddingTop:'2vh',
        paddingBottom:'2vh'
    },
    
    boxTitle:{
        paddingInline:20,
        paddingTop:'0.5vh',
        
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:20
    },
    
    actionButton:{
        marginLeft:10,
        marginRight:10,
        backgroundColor:'#ffffff00'
    } ,
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
    
});

class SingleReportEvaluation extends React.Component {
  
    constructor(props) {
        super(props);            
        console.info("REPORT EVALUATION")
        this.openExtendedTraits = this.openExtendedTraits.bind(this);
        this.closeExtended = this.closeExtended.bind(this)
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        traitsSummaryArr:[],
        execSummaryArr:[],
        invalidReport:false
        
    };
   
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
    }
    
    intializeData(report, reportParts){
        this.setState({report})
        this.setState({reportParts})
        if (!report) return
        const traitSumArr = ReportsManager.setTraitsSummary(report, reportParts)
        this.setState({traitsSummaryArr: traitSumArr})    
        if (report.dominant_traits && report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
    }
    
    closeExtended(){
        this.setState({extendedOpen: false})
    }

    openExtendedTraits(){
        this.setState({extendedOpen: true})
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            {this.state.invalidReport?
            <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'1vh', paddingLeft:10, paddingRight:10}}>
                <Typography variant={'h3'} className={classes.invalid}>{ t('Reports - Summary - Invalid Assessment')}</Typography>
            </Grid>:
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Grid container>
                        <Grid item xs={8} className={classes.boxTitle}>
                            <Typography variant={'h4'}  style={{paddingTop:'2vh', paddingBottom:'2vh', fontWeight:600}}>{t('Reports - Summary - Evaluation of Characteristics')} </Typography>
                        </Grid>
                        <Grid xs={4} style={{display:'flex', justifyContent:'end'}} className={classes.boxTitle} >
                            <EgButton secondary="true"  onClick={this.openExtendedTraits} className={classes.actionButton}>{t('Reports - Summary - Detailed Traits Analysis')}</EgButton> 
                        </Grid>
                    </Grid>
                    <div className={classes.tableInner}>
                        <Table aria-label="inner table" >
                            <TableBody >
                                {this.state.report && this.state.report.assessment?
                                this.state.report.assessment.assessment_scores.map((score, index) => (
                                    score.trait.is_display?
                                    <TableRow key={"char" + index }  >
                                        <TableCell style={{width:40, border:0}}></TableCell>
                                        <TableCell className={classes.tableCellBars} style={{width:'20%'}}>
                                            <Typography variant={'h4'} style={{textAlign:'start'}}>
                                                {LangPicker.current() === 'en'?score.trait.name:score.trait.trait_localized[0].localized_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBars} style={{width:'25%'}}>
                                            <GradeBar variant="determinate" value={score.trait_weighted_score*100/score.trait_potential_score} negative={score.trait.dark} boundaries={score.trait.boundaries}></GradeBar>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBars} style={{width:'18%', paddingBottom:'0.5vh',paddingInlineStart:0, paddingTop:'0vh'}}>
                                            <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{Math.floor(score.trait_weighted_score*100/score.trait_potential_score)}</span><span style={{fontSize:9}}>/100</span></Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBars} style={{width:'37%'}}>
                                        
                                        </TableCell>
                                    </TableRow>
                                    :''
                                )):''}
                            </TableBody>
                        </Table>
                    </div>
                    
                    <Typography variant={'h4'} className={classes.boxTitle} style={{paddingTop:'1.5vh', paddingBottom:'1.5vh'}}>{t('Reports - Summary - Characteristics Breakdown')}</Typography>
                    <div className={classes.tableInner}>
                        <Table className={classes.tableInner} aria-label="inner table" >
                        <TableBody >
                            {this.state.report && this.state.report.assessment?
                                this.state.report.assessment.assessment_scores.map((score, index) => (
                                    score.trait.is_display?
                                    <TableRow key={"char2" + index }   >
                                        <TableCell style={{width:40, border:0}}></TableCell>
                                        <TableCell className={classes.tableCell} style={{width:'5%', paddingTop:'1.2vh', paddingBottom:'2vh', verticalAlign:'top'}}>
                                            <GradeCircle value={Math.floor(score.trait_weighted_score*100/score.trait_potential_score)} negative={score.trait.dark} boundaries={score.trait.boundaries}/>
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{width:'50%'}}>
                                            <Typography variant={'h4'} style={{fontWeight:600, paddingBottom:5,textAlign:'start'}}>
                                                {LangPicker.current() === 'en'?score.trait.name:score.trait.trait_localized[0].localized_name}
                                            </Typography>
                                            <Typography variant={'h4'} style={{textAlign:'start', whiteSpace:'break-spaces'}}>{this.state.traitsSummaryArr.length? ReportsManager.getTemplateText(score.trait_id, this.state.traitsSummaryArr,this.state.reportParts):''}
                                            </Typography> 
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{width:'45%', paddingInlineStart:50}}>
                                        </TableCell>
                                    </TableRow>
                                    :''
                            )):''}
                        </TableBody>
                        </Table>
                    </div>
                </Paper>
            </Grid>
            }
            <Drawer open={this.state.extendedOpen} anchor='right' onClose={this.closeExtended} PaperProps={{ style: {  width:'50vw',overflow:'hidden' } }}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.closeExtended}
                    onKeyDown={this.closeExtended}
                >
                <ExtendedTraitEvaluation report={this.state.report} reportParts={this.state.reportParts}></ExtendedTraitEvaluation>
                </div>
            </Drawer>
            </div>               
    );
  }
}
SingleReportEvaluation.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportEvaluation),SingleReportEvaluation);