import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import logo from '../img/logo_blue.svg'

const styles = theme => ({
    root: {
      display: 'flex',
    },
})


class PoweredByFooter extends React.Component {
  
    state = {
        value:0
    };

    render(){

        return (
            <Grid container>
                <Grid xs={6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', paddingInlineEnd: 10 }}>
                <Typography variant={'h4'} style={{ color: '#000000', }}>Powered by </Typography>
                </Grid>
                <Grid xs={6} style={{ display: 'flex', justifyContent: 'start', }}>
                <span><img src={logo} alt="logo" style={{ width: 130 }}></img></span>
                </Grid>
          </Grid>
        );
    }
}

    
PoweredByFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PoweredByFooter);