import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import empty from '../img/empty_latest.svg';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from '../components/EgButton';



const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      width:'100%',
      minHeight:'73vh',
      overflow:'auto'
    },
    smallpaper: {
        padding: 0,
        textAlign: 'left',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'15vh',
        overflow:'auto'
      },
    noteTitle:{
        fontSize:16,
        color:"#7F7F7F",
        fontWeight: 500,
       
    },
    smallTitle:{
        fontSize: 13,
        color:"#7F7F7F",
        fontWeight: 500,
    },
    emptyStateImg:{
        width:'25vw',
        opacity:1,
        padding:30,
    },
    emptyStateImgSmall:{
        width:'13vw',
        opacity:1,
        padding:10,
    },
    addNewLink:{
        textDecoration:"none",
        color: theme.palette.text.white,
    },
    actionButton:{
        marginTop:'10vh'    }
  });
 

class EgEmptyState extends React.Component {
  
    render() {
        const { classes } = this.props;
        return (
            this.props.size === 'small'?
            <Paper elevation={0} className={classes.smallpaper}>
                    
                    <Grid item xs={12 }  style={{textAlign:'center'}}>
                        <img src={empty} className={classes.emptyStateImgSmall} alt="empty" />  
                    </Grid>  
                    <Grid item xs={12 } style={{textAlign:'center', padding:10}}>
                        <Typography variant={'h4'} style={{textAlign:'center'}}>{this.props.title} </Typography>
                    </Grid>  

                    <Grid item xs={12 }  style={{textAlign:'center'}}>
                        {this.props.emptyAction?
                        <EgButton type="submit"  variant="contained" onClick={this.props.emptyAction} className={classes.actionButton}>
                            {this.props.emptyActionTxt}
                        </EgButton>  :''}
                    </Grid>       
            </Paper>  :
            <Paper className={classes.paper}>
                    <Grid item xs={12 }>
                        <Typography className={classes.noteTitle}> </Typography>
                    </Grid>
                    <Grid item xs={12 }  style={{textAlign:'center', padding:50}}>
                        <Typography variant={'h2'} style={{textAlign:'center'}}>{this.props.title} </Typography>
                    </Grid>  

                    <Grid item xs={12 } style={{textAlign:'center'}}>
                        <img src={empty} className={classes.emptyStateImg} alt="empty" />  
                    </Grid>  

                    <Grid item xs={12 }  style={{textAlign:'center'}}>
                        {this.props.emptyAction?
                        <EgButton type="submit"  variant="contained" onClick={this.props.emptyAction} className={classes.actionButton}>
                            {this.props.emptyActionTxt}
                        </EgButton>  :''}
                    </Grid>       
            </Paper>  
        );
    }
}
EgEmptyState.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(EgEmptyState),EgEmptyState)))