import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import EgActivityRecorder from '../../util/EgActivityRecorder';
import LangPicker from '../../util/LangPicker';
import GradeBar from '../../components/GradeBar';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import EgButton from '../../components/EgButton';
import Moment from 'react-moment';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },

    messageHeader:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.secondary.light,
    },
    actionButton:{
        width: 100,
        margin: 5
    },
})

class OngoingReportDialog extends React.Component {
  
    state = {
        open: this.props.open,
        report:this.props.report,
    };

    constructor(props) {
        super(props);  
        this.handleClose = this.handleClose.bind(this);  
    }

    componentDidMount(){
        if (this.props.report){
            this.initialize(this.props.report)
        }
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.report && nextProps.report!==this.state.report ){             
            this.setState({report:nextProps.report});  
            this.initialize(nextProps.report)       
        }
    }

    initialize(report){
        if (report && report.assessment_scores){
            const scores = report.assessment_scores.filter(score => score.trait.father_id!==300 ) 
            const sortedScores = scores.sort((a,b) => a.trait_adjusted_score?b.trait_adjusted_score - a.trait_adjusted_score : b.trait_weighted_score*100/b.trait_potential_score - a.trait_weighted_score*100/a.trait_potential_score);
            this.setState({sortedScores:sortedScores})
            console.log(sortedScores)
        }
    }

    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.close();
    };

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='65vw'
            >
                <DialogContent
                        name='Engard Employee Ongoing Report Dialog'
                        onMouseEnter={EgActivityRecorder.handleMouseEnter} 
                        mouseenterdata={JSON.stringify({'report dialog':this.props.report?this.props.report.id:0})}
                        onMouseLeave={EgActivityRecorder.handleMouseLeave}
                        style={{paddingBottom:20, width:'65vw', maxWidth:'65vw'}} 
                >
                    
                    <Paper elevation={0} style={{width:'58vw'}}>    
                        <Grid container>
                            <Grid item xs={12} className={classes.boxTitle}>
                                <Typography variant={'h3'}  style={{paddingTop:'2vh', paddingBottom:'0.5vh', fontWeight:500}}>{t('Employee - Ongoing - Report')} </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.boxTitle}>
                                <Typography variant={'h4'}  style={{paddingTop:'2vh', paddingBottom:'1vh', fontWeight:500}}>{t('Employee - Assessment Date')}: <Moment format="MMM DD, YYYY">{this.state.report?this.state.report.completed_at:''}</Moment> </Typography>
                            </Grid>
                        </Grid>

                        <Grid container style={{paddingTop:'1vh', paddingBottom:'1vh', paddingInline: 20,display:'flex', justifyContent:'start'}}>
                            <Grid xs={12}>
                                <div className={classes.tableInner} style={{paddingTop:'1vh', paddingBottom:'2vh', cursor: 'pointer'}}  >
                                    
                                    {this.state.sortedScores?
                                    this.state.sortedScores.map((score, index) => (
                                       
                                        <Grid container style={{paddingTop:'1vh', paddingBottom:'0vh',display:'flex', justifyContent:'start'}}>
                                            <Grid xs={4} style={{paddingInline:10,paddingTop:6,paddingBottom:6, display:'flex', alignItems:'center', justifyContent:'start', minHeight:'4vh'}} >
                                                <Typography variant={'h5'} style={{textAlign:'start', fontWeight:400, }}>
                                                {LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={6} style={{display:'flex', alignItems:'center', borderInlineStart:'solid 1px black'}}>
                                                <GradeBar variant="determinate" wide={true} value={score.trait_adjusted_score} negative={score.trait.dark} boundaries={score.trait.boundaries} avg={this.state.compared?this.getAvgVal(score.trait_id):0} className={classes.valBar}></GradeBar>
                                            </Grid>
                                            <Grid xs={2} style={{display:'flex', alignItems:'center', paddingInline:10}}>
                                                <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{score.trait_adjusted_score}</span><span style={{fontSize:9}}>&#47;100</span></Typography>
                                            </Grid>
                                            <Divider style={{width:'90%', marginTop:'0.5vh', marginBottom:'0vh',padding:0,backgroundColor:'#f6f6f6' }}/>
                                        </Grid>  
                                    )):''}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:0}} >
                            <EgButton secondary="true" outlined="true" className={classes.actionButton} onClick={this.handleClose}>{t("ErrorDialog - Close")}</EgButton>  
                        </Grid>
                    </Paper>
                </DialogContent>
                
                
            </Dialog>
            </div>
        );
    }
}

OngoingReportDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(OngoingReportDialog),OngoingReportDialog)))