import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Tab, Tabs, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import Moment from 'react-moment';
import EgButton from '../../components/EgButton';
import CloseOutlinedIcon from '@material-ui/icons//CloseOutlined';
import EgStatusAlert from '../../components/EgStatusAlert';
import SkeletonReport from '../../components/SkeletonReport';
import SingleCampaignSummary from './SingleCampaignSummary';
import SingleCampaignCVComp from './SingleCampaignCVComp';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    formRow:{
        paddingTop:'2vh',
        paddingInline:18,
        borderBottom:'#e8e8e8'
    },
});
const AntTabs = withStyles((theme) => ({
    root: {
    },
    indicator: {
      backgroundColor: theme.palette.primary.dark,
    },
}))(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 20,
      fontSize:13,
      maxWidth:300,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(2),
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.primary.dark,
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

const COMPARE_TO_MODELS = 0;
const COMPARE_CV_TO_REQ = 1;

class LookalikeCampaignCard extends React.Component {
  
    constructor(props) {
        super(props);            
        this.handleChange = this.handleChange.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this)
    }

    state = {
        addOpen: this.props.addOpen,
        reportState: 0,
        moreMenuOpen: false,
        reportState: 0,
    };

    linkRef = React.createRef();

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.campaignId !== this.props.campaignId){
            this.setState({campaignId:nextProps.campaignId}); 
            this.getCampaignResults(nextProps.campaignId)
            
        }
    }
    componentDidMount(){
        if (this.props.campaignId){
            this.setState({campaignId:this.props.campaignId}); 
            this.getCampaignResults(this.props.campaignId)
            this.setState({reportState:COMPARE_TO_MODELS})
        }
    }
    

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }
    refresh(status, message){
        if (status === 'SUCCESS'){
            this.showStatusBar(message)
        }
    }

    handleStatusClose(){
        this.setState({statusBar:null})
    }

    async getCampaignResults(id){
        try{
            console.log(`Fetching lookalike results fot campaign ${id}`)
            this.setState({loading:true})
            let response = await EgHttp.get('/lookalike/campaign/' + id)
            let campaignres = await response.json();
            if (response.status !== 200 || campaignres === null || campaignres === undefined || campaignres.length === 0) {
                console.error(`Error: No campaign ready with id ${id}`)
                return
            }
            this.setState({campaign:campaignres, loading:false})
        }
        catch(err){
            console.error(err);
            return;
        }
        
    }

    handleChange(event, val){
        console.log(val)
        this.setState({reportState:val})
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{overflow: 'hidden',width:'100%'}}>
                 <a ref={this.linkRef}/>
            {this.state.campaign?
           
            <div style={{width:'100%', overflow:'hidden'}}>
                <Paper  elevation={0} style={{height: '100%'}}>

                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{ paddingTop:'4vh'}}>
                            
                            <Grid item xs={9} justify="left" style={{paddingInline:30}}>
                                <Typography variant={'h1'}>  {t('Lookalike - Single - Lookalike Campaign')} - {this.state.campaign.campaign_name} </Typography>
                            </Grid>
                            <Grid item xs={3} style={{textAlign:"end", paddingInlineEnd:20}} >
                                    <EgButton secondary="true" className={classes.closeIcon} onClick={()=>{this.props.close()}}>
                                        <CloseOutlinedIcon style={{fontSize:16}}></CloseOutlinedIcon>
                                    </EgButton>     
                            </Grid>
                            <Grid item xs={12} style={{paddingInline:30}}>
                                <Typography variant={'h4'} style={{textAlign:'start'}}> {t("Lookalike - Table - For Position")}: { this.state.campaign.for_position} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{t("Lookalike - Table - Campaign Start Date")}: <Moment format="MMM DD, YYYY">{ this.state.campaign.created_at}</Moment> </Typography>
                            </Grid>
                            
                            <Grid style={{paddingInline:20, paddingTop:'2vh'}}>
                                <AntTabs value={this.state.reportState} onChange={this.handleChange}>
                                    <AntTab label={t('Lookalike - Single - Compare to Models')} style={{padding:'1.5vh'}} ></AntTab>
                                    <AntTab label={t('Lookalike - Single - Compare CV to Requirements')} ></AntTab>
                                </AntTabs>
                            </Grid>
                            {
                                this.state.reportState === COMPARE_TO_MODELS?
                                <SingleCampaignSummary campaign={this.state.campaign}  moveTo={this.handleChange} loading={this.loading}/>
                                : this.state.reportState === COMPARE_CV_TO_REQ?
                                <SingleCampaignCVComp campaign={this.state.campaign} moveTo={this.handleChange}/>:''
                            }
                        </Grid>
                </Paper>
            </div>
            :
            <div>
                <SkeletonReport style={{width:'100%'}}></SkeletonReport>
            </div>
            }
            {this.state.moreMenuOpen}   
            {this.state.emailInput}
            {this.state.statusBar}

        </div>     
      
    );
  }
}
LookalikeCampaignCard.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(LookalikeCampaignCard),LookalikeCampaignCard);
