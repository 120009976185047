import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';


const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
})

class SkeletonReport extends React.Component {
    render() {
        return (
            <Grid container style={{paddingInlineStart:30, paddingTop:'5vh'}}>
                <Grid item xs={12}><Skeleton width={'30vw'}></Skeleton></Grid>
                <Grid item xs={12} style={{paddingBottom:'2vh'}}><Skeleton width={'40vw'}></Skeleton></Grid>
                <Grid item xs={1} ><Skeleton width={'5vw'} style={{paddingInline:50}}></Skeleton></Grid>
                <Grid item xs={1}><Skeleton width={'5vw'} style={{paddingInline:50}}></Skeleton></Grid>
                <Grid item xs={1}><Skeleton width={'5vw'} style={{paddingInline:50}}></Skeleton></Grid>
                <Grid item xs={1}><Skeleton width={'5vw'} style={{paddingInline:50}}></Skeleton></Grid>
                <Grid item xs={1}><Skeleton width={'5vw'} style={{paddingInline:50}}></Skeleton></Grid>
                <Grid item xs={1}><Skeleton width={'5vw'} style={{paddingInline:50}}></Skeleton></Grid>
                <Grid item xs={1}><Skeleton width={'5vw'} style={{paddingInline:50}}></Skeleton></Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={4} style={{ paddingTop:'2vh'}}><Skeleton  variant="rectangular" height={'73vh'} ></Skeleton></Grid>
                <Grid item xs={8} style={{paddingInline:30, paddingTop:'2vh'}}>
                    <Grid container>
                        <Grid item xs={12} style={{paddingBottom:'3vh'}}>
                            <Skeleton variant="rectangular" height={'35vh'} ></Skeleton>
                        </Grid>
                        <Grid item xs={4} style={{paddingInlineEnd:20}}>
                            <Skeleton variant="rectangular" height={'35vh'} ></Skeleton>
                        </Grid>
                        <Grid item xs={8} >
                            <Skeleton variant="rectangular" height={'35vh'} ></Skeleton>
                        </Grid>
                        
                    </Grid>
                    
                </Grid>

            </Grid>
        );
    }
}

SkeletonReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(SkeletonReport),SkeletonReport)))