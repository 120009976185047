import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './i18n/i18n';

require('typeface-montserrat')
require('typeface-rubik')

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Rubik',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    h1:{
      fontSize:24,
      fontWeight:400,
      color:"#043241",
      textAlign:'start',
    },
    h2:{
      fontSize:20,
      fontWeight:400,
      color:"#000000",
      textAlign:'start',
    },
    h3:{
      fontSize:18,
      fontWeight:500,
      color:"#043241",
      textAlign:'start',
    },
    h4:{
      fontSize:14,
      fontWeight:400,
      color:"#043241"
    },
    h5:{
      fontSize:12,
      fontWeight:400,
      color:"#043241",
    },
    h6:{
      fontSize:16,
      fontWeight:600,
      color:"#043241",
    }
  },
  palette: {
      primary:{
        main:'#13A7D6',
        dark:'#0085AF',
        light:'#E2F5FF'
      },
      secondary:{
        main:'#FC264D',
        dark:'#C70024',
        light:'#FFF0E9'
      },
      error:{
        main: '#FC264D',
        dark: '#C70024',
        light: '#FFF0E9'
      },
      text:{
        black: '#043241',
        white: '#ffffff'
      },
      severity:{
         low:  '#11D6A7',
         med:  '#FCBF54',
         high: '#FC264D',
      },
      blackbg: '#302F2E',
      whitebg: '#ffffff',
      gradebg: '#eeeeee',

  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.lo))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

