import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TablePagination, TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AssignTestDialog from '../../components/AssignTestDialog';
import Moment from 'react-moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EgHttp from '../../util/EgHttp';
import Skeleton from '@material-ui/lab/Skeleton';
import SkeletonTable from '../../components/SkeletonTable';
import EventLog from '../../util/EventLog';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { setNavCand } from "../../redux/nav";
import EgEmptyState from '../../components/EgEmptyState';
import EgValidityPatch from '../../components/EgValidityPatch';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import EgRiskPatch from '../../components/EgRiskPatch';
import RowActionsHover from '../../components/RowActionsHover';
import AppUtils from '../../util/AppUtils';

const mapStateToProps = state => ({
    admin: state.admin.value,
    nav: state.nav.value
});

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'start',
      color: theme.palette.text.secondary,
      width:'100%',
      minHeight:'75vh',
      overflow:'auto'
    },
    
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:"#272931",
        border:0,
        paddingLeft:10,
        paddingRight:10,
        paddingTop:14,
        paddingBottom:14,
        textAlign:'start',
        borderRight:'1px solid #F0F0F0',
        overflowWrap:'anywhere'
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'1px solid #F0F0F0',
        
    },
    tableHeadCell:{
        paddingTop:5,
        paddingBottom:5,
        fontSize: 12,
        fontWeight:400,
        color:"#5F6066",
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0'
    },
    
    
    tableRowInner:{
        minHeight:"6.5vh",
        maxHeight:"6.5vh",
        height:"6.5vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
});
    
class ReportsTable extends React.Component {
  
    state = {
        open: this.props.open,
        operationIcon: null,
        isEmpty: false,
        overlayDiv: [],
        rows: this.props.rows,
        moreMenuOpen: [],
        assignTestOpen: false,
        loading:true,
        rowsPerPage:10,
        page:0,
        order:'desc',
        orderBy:'created_at'
    };

    constructor(props) {
        super(props);  
        this.closeAssignTest = this.closeAssignTest.bind(this); 
        this.assignTest = this.assignTest.bind(this) ;   
        this.refresh = this.refresh.bind(this);   
        this.confirmDelete = this.confirmDelete.bind(this)  
        this.closeConfirm = this.closeConfirm.bind(this) 
        this.deleteCandidate = this.deleteCandidate.bind(this)  
        this.rejectCandidate = this.rejectCandidate.bind(this)  
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.saveAsEmployee = this.saveAsEmployee.bind(this)
        this.confirmMoveToEmployees = this.confirmMoveToEmployees.bind(this)  
        this.openReport = this.openReport.bind(this)
        this.editCandidate = this.editCandidate.bind(this)
        this.confirmReject = this.confirmReject.bind(this)
    }

    componentDidMount(){
        this.props.setNavCand(true)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.rows!==this.state.rows ){             
            this.setState({rows:nextProps.rows});   
        }
        if (nextProps.loading!==this.state.loading ){             
            this.setState({loading:nextProps.loading});           
        }
        if (nextProps.filter!==this.state.filter) {
            this.setState({filter: nextProps.filter})
            this.setState({page: 0})
        }
        if (nextProps.lastPage && nextProps.lastPage){
            console.log("changing the page")
            this.setState({page: nextProps.lastPage})
        }
    }

    refresh(code, msg){
        this.props.refresh(code, msg);
    }

    assignTest(index){
        let candidate = {...this.state.rows[index]};
        candidate.id = candidate.hr_id
        this.setState({assignTestOpen:true, assigned: this.state.rows[index]},
            this.setState({assignDialog:
                <AssignTestDialog login={this.props.login} open={true} closeDialog={this.closeAssignTest} human_resource={candidate} refresh={this.refresh} context='candidate'></AssignTestDialog>
            })
        )
    }

    closeConfirm(){
        this.setState({confirmDeleteDialog:null});
    }

    closeAssignTest(){
        this.setState({assignTestOpen:false});
        this.setState({assignDialog:null});
    }

    
    openReport(index){
        console.log(index)
        this.props.openReport(this.state.rows[index].id, this.state.page)
    }
    
    confirmDelete(index){
        const { t } = this.props; 
        this.setState({deleteIndex:index})
        this.setState({confirmDeleteDialog: <ConfirmationDialog title={t("Candidates - Table - Delete Candidate")} message={t("Candidates - Table - Are you sure")}  confirm={this.deleteCandidate} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }
    confirmReject(index){
        const { t } = this.props; 
        this.setState({deleteIndex:index})
        this.setState({confirmDeleteDialog: <ConfirmationDialog title={t("Candidates - Table - Reject Candidate")} message={t("Candidates - Table - Are you sure to reject")}  confirm={this.rejectCandidate} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }
    handleChangePage(event, newPage){
        this.setState({page:newPage})
        console.log(_.slice(this.state.rows,newPage*this.state.rowsPerPage, (newPage+1)*this.state.rowsPerPage))
    }
    handleChangeRowsPerPage(event){
        console.log(event)
        this.setState({rowsPerPage: parseInt(event.target.value, 10)})
    }
    createSortHandler = property => event => {
        this.onRequestSort(event, property);
    };
    onRequestSort = (event, property) => {
        
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }    
        this.setState({ order, orderBy });       
    };  

    // Delete candidate
    async deleteCandidate(){
        const { t } = this.props;
        this.setState({confirmDeleteDialog:null}); 
        const id = this.state.rows[this.state.deleteIndex].hr_id
        let response = await EgHttp.delete('/candidates/report/' + id)
        if (response.status===200){
            _.remove(this.state.rows, {
                hr_id: id
            });
        }
        this.setState({overlayDiv:[]})
        this.props.refresh("SUCCESS", t('Reports - Table - Candidate was deleted successfully'));
        return;   
    }

        // Reject candidate
        async rejectCandidate(){
            const { t } = this.props;
            this.setState({confirmDeleteDialog:null}); 
            const id = this.state.rows[this.state.deleteIndex].hr_id
            let response = await EgHttp.put('/candidates/reject/' + id)
            if (response.status===200){
                _.remove(this.state.rows, {
                    hr_id: id
                });
            }
            this.props.getRejected()
            this.setState({overlayDiv:[]})
            this.props.refresh("SUCCESS", t('Reports - Table - Candidate was rejected successfully'));
            return;   
        }
    
    editCandidate(index){
        this.props.openCandidate(this.state.rows[index].hr_id)
    }
     // Save candidate as employee
     async saveAsEmployee(){
        const { t } = this.props;  
        this.setState({confirmMoveDialog:null}); 
        const id = this.state.rows[this.state.moveToEmpIndex].hr_id
        let response = await EgHttp.put('/candidates/saveAsEmployee/' + id + '/104') //save as an employee who already did the initial assessment 
        if (response.status===200){
            _.remove(this.state.rows, {
                hr_id: id
            });
        }
        this.setState({overlayDiv:[]})
        this.props.refresh("SUCCESS", t('Candidates - Table - Candidate was saved as employee'));
        EventLog.insert(this.props.admin.org.id, id, EventLog.SAVE_AS_EMPLOYEE, this.props.admin.account.id)
        return;   
    }
    confirmMoveToEmployees(index){
        const { t } = this.props; 
        this.setState({moveToEmpIndex:index})
        this.setState({confirmMoveDialog: <ConfirmationDialog title={t("Candidates - Table - Save as Employee")} message={t("Candidates - Table - Are you sure to move")}  confirm={this.saveAsEmployee} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }

    
    setHover(e, onOff, index){
        const { t } = this.props;
        let item, items = []
        const submenuActions=[  {text:t('Reports - Table - Delete Candidate'), action:this.confirmDelete, disabled:false},
                                {text:t('Candidates - Table - Edit Candidate'), action:this.editCandidate, disabled:false},
                                {text:t('Reports - Table - Retake Assessment'), action:this.assignTest, disabled:false},
                                {text:t('Candidates - Table - Save as Employee'), action:this.confirmMoveToEmployees, disabled:!this.props.admin.org.license},
                                {text:t('Reports - Table - Reject Candidate'), action:this.confirmReject, disabled:this.props.filter === -1}
                             ]
        if (onOff){ 
            item = <RowActionsHover mainActionName={t('Reports - Table - Open Report')} mainAction={this.openReport} rowIndex={index} submenuActions={submenuActions} rowsPerPage={this.state.rowsPerPage} page={this.state.page}/>
        }
        else{
            item = null;
            this.setState({moreMenuOpen:[]});
        }
        items[index] = item;
        this.setState({overlayDiv:items});
    }
  
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
        <div style={{position:'relative'}}>

        <Grid container spacing={3} style={{}}>
            
            <Grid item xs={12 } style={{height:'100%'}}>
                
                {this.state.loading || (this.state.rows && this.state.rows.length)?
               <div>
               <Paper elevation={1} className={classes.paper} style={{maxHeight:'75vh'}}>
                    
                <Table className={classes.table} aria-label="simple table" >
                    <TableHead key={"th100"}>
                        <TableRow key={"tr100"}>
                            <TableCell key={'firstname'} sortDirection={this.state.orderBy === 'firstname' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'25%', }}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('firstname')} active={this.state.orderBy === 'firstname'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Reports - Table - Name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'created_at'} sortDirection={this.state.orderBy === 'created_at' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'10%'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('created_at')} active={this.state.orderBy === 'created_at'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Reports - Table - Assessment')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'position'} sortDirection={this.state.orderBy === 'position' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'25%'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('position')} active={this.state.orderBy === 'position'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Reports - Table - Position')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'email'} sortDirection={this.state.orderBy === 'email' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'20%'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('email')} active={this.state.orderBy === 'email'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Candidates - Assign - Assessment Type')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} align="left" style={{width:'20%'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody >
                    {
                    this.state.loading?
                    <TableRow key={'skeleton1'} className={classes.tableRowBody} >
                        <TableCell className={classes.tableInnerCell} colSpan={5}>
                            <SkeletonTable style={{width:'100%'}}></SkeletonTable>
                        </TableCell>
                    </TableRow>
                    :
                    _.slice(this.state.rows.sort(AppUtils.getSorting(this.state.order, this.state.orderBy)),this.state.page*this.state.rowsPerPage, (this.state.page+1)*this.state.rowsPerPage).map((row, index) => (
                    
                        <TableRow key={row.name} className={classes.tableRowBody} >
                            <TableCell className={classes.tableInnerCell} colSpan={5} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                <div>
                                    <Table className={classes.tableInner} aria-label="inner table" >
                                    <TableBody >
                                        <TableRow key={row.name + "_trin_" + index} className={classes.tableRowInner} >
                                            
                                            <TableCell className={classes.tableCell} style={{width:'15%',borderInlineEnd:0}} key={row.name + "_tdname_" + index} > 
                                                {row.firstname+ " " + row.lastname }
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="center" style={{width:'10%',borderInlineStart:0}} key={row.name + "_tdval_" + index} >
                                                {row.validity_obj && row.validity_obj.overallRisk === 2?<div style={{display:'flex', justifyContent:'center'}}>
                                                    <EgValidityPatch maxw={100} validityLevel={2} style={{maxWidth:50}}/></div>:
                                                  row.assessment_type === AssessmentTypes.INITIAL_RISK_ONLY_ASSESSMENT || row.assessment_type === AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT ?
                                                    <div style={{display:'flex', justifyContent:'center'}}><EgRiskPatch  maxw={100} direct={row.overall_risk}></EgRiskPatch></div>:
                                                    ''}
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'10%'}} key={row.name + "_tdcret_" + index}><Moment format="MMM DD, YYYY">{row.created_at}</Moment></TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'25%'} } key={row.name + "_tdpos_" + index}>{row.position}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'40%', borderInlineEnd:0}} key={row.name + "_tdtype_" + index}>
                                                {AssessmentTypes.getNameById(row.assessment_type)}   
                                            </TableCell>
                                            
                                        </TableRow>
                                    </TableBody >
                                    </Table>
                                </div>
                                {this.state.overlayDiv[index]}
                                {this.state.moreMenuOpen[index]}   
                            </TableCell>
                        </TableRow>   
                    ))}
                    </TableBody>
                </Table>
                
                </Paper>
                <TablePagination component="div"
                                count={this.state.rows?this.state.rows.length:0}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                labelRowsPerPage={t('General - Rows per page')} 
                                >
                </TablePagination>
                </div>
                :
                <EgEmptyState title={t('Reports - Table - No Reports Found')}/>
                }
            </Grid>  
        </Grid>
        {this.state.assignDialog}
        {this.state.confirmDeleteDialog}
        {this.state.confirmMoveDialog}
        </div>      
      
        );
    }
}
ReportsTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails, setNavCand })(withTranslation()(withStyles(styles)(ReportsTable),ReportsTable));