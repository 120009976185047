import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from '../components/EgButton';
import ManualPayDialog from './ManualPayDialog';
import EgStatusAlert from '../components/EgStatusAlert';



 
const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    formRow:{
        padding:10
    },
    actionButton:{
        width:200,
        marginLeft:5,
        marginRight:5
    },
    

    selectBox:{
        '& .MuiOutlinedInput-input':{
            paddingTop:18,
            paddingBottom:18, 
            fontSize:16,
            textAlign:'left'
        }
    }
    
  });
  
class ManualPayment extends React.Component {
  
    constructor(props) {
        super(props);           
        this.handleAmmountChange = this.handleAmmountChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.makePayment = this.makePayment.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this);
    }

    state = {
        ammount:null,
        currency:'USD',
        currencies:['USD', 'ILS', 'EUR']
    };

   
    componentDidMount(){
    }

    makePayment(){
        this.setState({payDialogOpen: <ManualPayDialog open closeDialog={this.handleClose} refresh={this.refresh} ammount={this.state.ammount} currency={this.state.currency}  style={{maxWidth:500}}/>})
    }
    handleClose(){
        this.setState({payDialogOpen:null});
      }
    refresh(status, message){
        if (status === 'SUCCESS'){
            this.showStatusBar(message)
        }
    }
    handleStatusClose(){
    this.setState({statusBar:null})
    }
    showStatusBar(message){
    this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }
    
    handleAmmountChange(event) {
        console.log(event.target)
        this.setState({ammount:event.target.value})
    }
    handleCurrencyChange(event) {
        this.setState({currency:event.target.value})
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.loading}
                <Paper style={{height:'100%', width:'100vw'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={10} justify="left">
                                <Typography variant={'h1'}>Manual Payment</Typography>
                            </Grid>
                            <Grid item xs={2} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton secondary="true" onClick={this.props.close} className={classes.actionButton}>Back</EgButton>
                            </Grid>
                            <Grid item xs={4} className={classes.formRow} style={{paddingTop:30}}>
                               <Typography variant='h4' style={{textAlign:'start'}}>Ammount</Typography>
                               <TextField autoComplete="ammount" name="ammount" variant="outlined" required fullWidth='true' id="ammount" label="" value={this.state.ammount} onChange={this.handleAmmountChange}></TextField>
                            </Grid>
                            <Grid item xs={8}/>
                            <Grid item xs={4} className={classes.formRow}>
                              
                                <Typography variant={'h5'} style={{textAlign:'start', paddingBottom:5}}>Currency</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select variant="outlined"
                                        labelId="demo-simple-select-label"
                                        id="currency"
                                        name="currency"
                                        value={this.state.selectedCurrency}
                                        onChange={this.handleCurrencyChange}
                                        className={classes.selectBox}
                                    >
                                        {this.state.currencies.map(function(cur) {
                                            return <MenuItem value={cur}>{cur}</MenuItem>
                                        })}
                                        
                                    </Select>
                                  </FormControl>      
                                
                            </Grid>
                            <Grid item xs={8}/>
                            <Grid item xs={4} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton  onClick={this.makePayment} className={classes.actionButton}>Make Payment</EgButton>
                            </Grid>
                            <Grid item xs={8}/>
                            
                        </Grid>
                </Paper>
                {this.state.payDialogOpen}
        </div>     
        
    );
  }
}
ManualPayment.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(ManualPayment),ManualPayment)))