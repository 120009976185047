import React from "react";
import CircularProgressWithLabel from '../components/CircularProgressWithLabel'
import { useTheme } from '@material-ui/core/styles';

export default function GradeCircle(props) {
  const theme = useTheme();
  return (
    <div>
        {
            props.negative?
                props.value < props.boundaries[0].top?
                <CircularProgressWithLabel variant="determinate"  value={props.value} color={theme.palette.severity.low} />
                :
                props.value < props.boundaries[1].top?
                <CircularProgressWithLabel variant="determinate"  value={props.value} color={theme.palette.severity.med}/>
                :
                <CircularProgressWithLabel variant="determinate"  value={props.value} color={theme.palette.severity.high}/>
            :
                props.value < props.boundaries[0].top?
                <CircularProgressWithLabel variant="determinate"  value={props.value} color={theme.palette.severity.high} />
                :
                props.value < props.boundaries[1].top?
                <CircularProgressWithLabel variant="determinate"  value={props.value} color={theme.palette.severity.med}/>
                :
                <CircularProgressWithLabel variant="determinate"  value={props.value} color={theme.palette.severity.low}/>
        }
    </div>
  );
}