import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TablePagination, TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import EventLog from '../../util/EventLog';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, increment } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {Statuses} from '../../util/constants/AsigneeStatus'
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EgHttp from '../../util/EgHttp';
import ReminderDialog from '../../components/ReminderDialog';
import Skeleton from '@material-ui/lab/Skeleton';
import SkeletonTable from '../../components/SkeletonTable';
import { setNavCand } from "../../redux/nav";
import EgEmptyState from '../../components/EgEmptyState';
import { EgHRStatus } from '../../util/constants/AppConstants';
import RowActionsHover from '../../components/RowActionsHover';
import AppUtils from '../../util/AppUtils';

const mapStateToProps = state => ({
    admin: state.admin.value,
    nav: state.nav.value
});

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'start',
      color: theme.palette.text.secondary,
      width:'100%',
      minHeight:'75vh',    
      overflow:'auto'  
    },
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:"#272931",
        border:0,
        paddingLeft:10,
        paddingRight:10,
        paddingTop:13,
        paddingBottom:13,
        textAlign:'start',
        borderRight:'1px solid #F0F0F0',
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'1px solid #F0F0F0',
        
    },
    tableHeadCell:{
        paddingTop:5,
        paddingBottom:5,
        fontSize: 12,
        fontWeight:400,
        color:"#5F6066",
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0'
    },
    tableRowInner:{
        minHeight:"6.5vh",
        maxHeight:"6.5vh",
        height:"6.5vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
   
    
    statusLabel:{
        border: "solid 1px",
        padding: 5,
        textAlign:'center',
        color:theme.palette.text.white,
    },
  });

class AssignedTable extends React.Component {
  
    state = {
        open: this.props.open,
        operationIcon: null,
        isEmpty: false,
        rows: this.props.rows,
        overlayDiv: [],
        moreMenuOpen: [],
        rowsPerPage:10,
        page:0,
        loading:true,
        order:'desc',
        orderBy:'updated_at'
    };

    constructor(props) {
        super(props);  
        this.refresh = this.refresh.bind(this);     
        this.confirmDelete = this.confirmDelete.bind(this)  
        this.closeConfirm = this.closeConfirm.bind(this) 
        this.deleteCandidate = this.deleteCandidate.bind(this)
        this.unassign = this.unassign.bind(this)
        this.closeRemindDialog = this.closeRemindDialog.bind(this)
        this.sendReminder = this.sendReminder.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.saveAsEmployee = this.saveAsEmployee.bind(this)
        this.confirmMoveToEmployees = this.confirmMoveToEmployees.bind(this)  
        this.confirmUnassign = this.confirmUnassign.bind(this)  
        this.editCandidate = this.editCandidate.bind(this)  
        this.addMoreTime = this.addMoreTime.bind(this)
        
    }
    componentDidMount(){
        this.props.setNavCand(true)
    }
    componentWillReceiveProps(nextProps) {   
        if (nextProps.rows!==this.state.rows ){             
            this.setState({rows:nextProps.rows});           
        }
        if (nextProps.loading!==this.state.loading ){             
            this.setState({loading:nextProps.loading});           
        }
    }
    addMoreTime(index, moreTime){
        this.sendReminder(index, 30)
    }
    sendReminder(index, moreTime){
        this.setState({sendReminderOpen:true, assigned: this.state.rows[index]},
            this.setState({remindDialog:
                <ReminderDialog login={this.props.login} open={true} closeDialog={this.closeRemindDialog} human_resource={this.state.rows[index]} refresh={this.refresh} assessIndex={0} moreTime={moreTime} context='candidate'></ReminderDialog>
            })
         )
    }

    closeRemindDialog(){
        this.setState({sendReminderOpen:false});
        this.setState({remindDialog:null});
    }
    closeConfirm(){
        this.setState({confirmDeleteDialog:null});
        this.setState({confirmUnassignDialog:null});
    }
    
    confirmDelete(index){
        const { t } = this.props; 
        this.setState({deleteIndex:index})
        this.setState({confirmDeleteDialog: <ConfirmationDialog title={t("Candidates - Table - Delete Candidate")} message={t("Candidates - Table - Are you sure")}  confirm={this.deleteCandidate} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }
    
    confirmUnassign(index){
        const { t } = this.props; 
        this.setState({unassignIndex:index})
        this.setState({confirmUnassignDialog: <ConfirmationDialog title={t("Assigned - Table - Unassign Assessment")} message={t("Assigned - Table - Are you sure")}  confirm={this.unassign} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }
    // Delete candidate
    async deleteCandidate(){
        try{
            this.setState({confirmDeleteDialog:null}); 
            const { t } = this.props;
            const id = this.state.rows[this.state.deleteIndex].id
            let response = await EgHttp.delete('/candidates/assigned/' + id)
            if (response.status === 401) {
                this.props.history.push('/signin') 
                return;
              }
            if (response.status===200){
                _.remove(this.state.rows, {
                    id: id
                });
            }
            this.setState({overlayDiv:[]})
            this.props.refresh("SUCCESS", t('Assigned - Table - Candidate was deleted successfully'));
            EventLog.insert(3, id, EventLog.DELETE_CANDIDATE, 0)
            this.props.increment();
            return;   
        }
        catch(err){
            console.error(err);
        }
    }
    handleChangePage(event, newPage){
        this.setState({page:newPage})
    }
    handleChangeRowsPerPage(event){
        this.setState({rowsPerPage: parseInt(event.target.value, 10)})
    }
    createSortHandler = property => event => {
        this.onRequestSort(event, property);
    };
    // Open candidate form in edit mode
    editCandidate(index){
        this.props.openCandidate(this.state.rows[index].id)
    }
    onRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }    
        console.log("IN onRequestSort propertt:" + orderBy + " order: " +order )
        this.setState({ order, orderBy });       
    };  
    // Unassign candidate
    async unassign(){
        try{
            const { t } = this.props;
            this.setState({confirmUnassignDialog:null}); 
            const id = this.state.rows[this.state.unassignIndex].id
            let response = await EgHttp.put('/candidates/unassign/' + id)
            if (response.status === 401) {
                this.props.history.push('/signin') 
                return;
              }
            if (response.status===200){
                _.remove(this.state.rows, {
                    id: id
                });
            }
            this.setState({overlayDiv:[]})
            this.props.refresh("SUCCESS", t('Assigned - Table - Candidate was unassigned successfully'));
            EventLog.insert(3, id, EventLog.ASSESSMENT_UNASSIGNED, 0)
            this.props.increment();
            return;  
        } 
        catch(err){
            console.error(err);
            return;
        }
    }

    // Save candidate as employee
    async saveAsEmployee(){
        const { t } = this.props;  
        this.setState({confirmMoveDialog:null}); 
        const id = this.state.rows[this.state.moveToEmpIndex].id
        let response = await EgHttp.put('/candidates/saveAsEmployee/' + id + '/101') //save as an employee who already did the initial assessment 
        if (response.status===200){
            _.remove(this.state.rows, {
                id: id
            });
        }
        this.setState({overlayDiv:[]})
        this.props.refresh("SUCCESS", t('Candidates - Table - Candidate was saved as employee'));
        EventLog.insert(this.props.admin.org.id, id, EventLog.SAVE_AS_EMPLOYEE, this.props.admin.account.id)
        return;   
    }
    confirmMoveToEmployees(index){
        const { t } = this.props; 
        this.setState({moveToEmpIndex:index})
        this.setState({confirmMoveDialog: <ConfirmationDialog title={t("Candidates - Table - Save as Employee")} message={t("Candidates - Table - Are you sure to move")}  confirm={this.saveAsEmployee} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }

    

    refresh(code, msg){
        this.props.refresh(code, msg);
    }
   
    setHover(e, onOff, index){
        const { t } = this.props;
        let item, items = []
        const indexwp=index+(this.state.rowsPerPage*this.state.page)
        const submenuActions=[  {text:t('Assigned - Table - Add Time'), action:this.addMoreTime, disabled:this.state.rows[indexwp] && this.state.rows[indexwp].status !== EgHRStatus.HR_STATUS_CANDIDATE_ASSESS_TIMED_OUT},
                                {text:t('Assigned - Table - Send Reminder'), action:this.sendReminder, disabled:false},
                                {text:t('Assigned - Table - Unassign'), action:this.confirmUnassign, disabled:false},
                                {text:t('Candidates - Table - Edit Candidate'), action:this.editCandidate, disabled:false},
                                {text:t('Assigned - Table - Delete Candidate'), action:this.confirmDelete, disabled:false},
                                {text:t('Candidates - Table - Save as Employee'), action:this.confirmMoveToEmployees, disabled:!this.props.admin.org.license},
                             ]
        if (onOff){ 
            item = <RowActionsHover mainActionName={null} mainAction={null} rowIndex={index} submenuActions={submenuActions} rowsPerPage={this.state.rowsPerPage} page={this.state.page}/>
        }
        else{
            item = null;
            this.setState({moreMenuOpen:[]});
        }
        items[index] = item;
        this.setState({overlayDiv:items});
    }
  
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const assigStatuses = Statuses.get()
        return (
            <div style={{}}>

            <Grid container spacing={3} style={{}}>
                <Grid item xs={12 } style={{height:'100%'}}>
                    {(this.state.loading || (this.state.rows && this.state.rows.length))?  
                    <div>
                    <Paper className={classes.paper} style={{maxHeight:'75vh'}}> 
                    <Table className={classes.table} aria-label="simple table" >
                        <TableHead>
                            <TableRow>
                                <TableCell key={'firstname'} sortDirection={this.state.orderBy === 'firstname' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'20%'}} >
                                    <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('firstname')} active={this.state.orderBy === 'firstname'}>
                                        {this.state.loading?<Skeleton style={{width:100}}/>:t('Assigned - Table - Name')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell key={'updated_at'} sortDirection={this.state.orderBy === 'updated_at' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'20%'}}>
                                    <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('updated_at')} active={this.state.orderBy === 'updated_at'}>
                                        {this.state.loading?<Skeleton style={{width:100}}/>:t('Assigned - Table - Assign Date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell key={'position'} sortDirection={this.state.orderBy === 'position' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'25%'}}>
                                    <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('position')} active={this.state.orderBy === 'position'}>
                                        {this.state.loading?<Skeleton style={{width:100}}/>:t('Assigned - Table - Position')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell key={'status'} sortDirection={this.state.orderBy === 'status' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'15%'}}>
                                    <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('status')} active={this.state.orderBy === 'status'}>
                                        {this.state.loading?<Skeleton style={{width:100}}/>:t('Assigned - Table - Status')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.tableHeadCell} style={{width:'20%'}}></TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody >
                        {
                        this.state.loading?
                        <TableRow key={'skeleton1'} className={classes.tableRowBody} >
                            <TableCell className={classes.tableInnerCell} colSpan={5}>
                                <SkeletonTable style={{width:'100%'}}></SkeletonTable>
                            </TableCell>
                        </TableRow>
                        :
                        _.slice(this.state.rows.sort(AppUtils.getSorting(this.state.order, this.state.orderBy)),this.state.page*this.state.rowsPerPage, (this.state.page+1)*this.state.rowsPerPage).sort(AppUtils.getSorting(this.state.order, this.state.orderBy)).map((row, index) => (
                        
                            <TableRow key={row.name} className={classes.tableRowBody} >
                                <TableCell className={classes.tableInnerCell} colSpan={5} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                    <div>
                                        <Table className={classes.tableInner} aria-label="inner table" >
                                            <TableRow key={row.name + "in"} className={classes.tableRowInner} >
                                                
                                                <TableCell className={classes.tableCell} style={{width:'20%'}}> 
                                                    {row.firstname+ " " + row.lastname}
                                                </TableCell>
                                                <TableCell className={classes.tableCell} align="left" style={{width:'20%'}}><Moment format="MMM DD, YYYY">{row.updated_at}</Moment></TableCell>
                                                <TableCell className={classes.tableCell} align="left" style={{width:'25%'}}>{row.position}</TableCell>
                                                <TableCell className={classes.tableCell} align="left" style={{width:'15%'}}>
                                                    <div className={classes.statusLabel} style={{backgroundColor:assigStatuses[row.status-1].color,width:'100%' } }>{assigStatuses[row.status-1].name}</div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell} align="left" style={{width:'20%',borderRight:0}}></TableCell>
                                            </TableRow>
                                        </Table>
                                    </div>
                                    {this.state.overlayDiv[index]}
                                    {this.state.moreMenuOpen[index]}    
                                </TableCell>  
                            </TableRow> 
                            
                        ))}
                        </TableBody>
                    </Table>
        
                    </Paper>
                    <TablePagination component="div"
                                    count={this.state.rows?this.state.rows.length:0}
                                    page={this.state.page}
                                    onPageChange={this.handleChangePage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    labelRowsPerPage={t('General - Rows per page')} 
                                    >                
                    </TablePagination>
                    </div>
                    :
                    !this.state.loading?
                    <EgEmptyState title={t('Assigned - Table - No Candidates Assigned Yet')} emptyAction={null} />
                    :
                    <div></div>
                    }

                </Grid> 
            </Grid>
            {this.state.remindDialog}
            {this.state.confirmDeleteDialog}
            {this.state.confirmUnassignDialog}
            {this.state.confirmMoveDialog}
            </div>  
        );
    }
}
AssignedTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails, increment, setNavCand })(withTranslation()(withRouter((withStyles(styles))(AssignedTable),AssignedTable)))