import React from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    hi: {
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #FC6626 , #FC264D)'
    },
    med: {
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #F9A846 , #FFD25F)'
        
    },
    low: {
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #00AC83 , #11D6A7)'
        
    },
  }))

//const LOW_RISK = 0;
const MODERATE_RISK = 1;
const HIGH_RISK = 2;
//const EXTREME_RISK = 3;

export default function EgTimeValidityIndicator(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme()
    let barText = '';
    let styleClass = null;
    if (props.severity === HIGH_RISK){
        barText = t('Reports - Invalid')
        styleClass = classes.hi
    }
    else if (props.severity === MODERATE_RISK){
        barText = t('Reports - Suspicious');
        styleClass = classes.med
    }
    else { //LOW_RISK
        barText = t('Reports - Valid');
        styleClass = classes.low
    }
    return (

        <div className={styleClass}>
            <Typography variant={'h4'} style={{color:theme.palette.text.white, fontWeight:500,  display:'flex', justifyContent: 'center', alignItems:'center', width:props.width}}>
                {barText}
            </Typography>
        </div>
    );
}
