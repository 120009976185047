import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@material-ui/core';
import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';

require('typeface-montserrat')

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'55vh',
        alignItems:'center',
        overflow:'auto'
    },
    
    formRow:{
        padding:5
    },
    actionButton:{
        width: 150,
        margin: 5
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
});
  
class AssessCustomDetails extends React.Component {
  
    constructor(props) {
        super(props);            
        this.saveCustomDetails = this.saveCustomDetails.bind(this);  
        this.start = this.start.bind(this);  
        this.validateAll = this.validateAll.bind(this);  
    }

    state = {
        customFields:[],
       customFieldsFinal:[]
    };

    componentDidMount(){
        if (this.props.orgConfig){
            this.setState({orgConfig: this.props.orgConfig})
            this.setState({orgCustomFields: this.props.orgConfig.hr_managed_properties})
        }
        if (this.props.candidate){
            this.setState({candidate:this.props.candidate})
        }
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.orgConfig!==this.state.orgConfig ){         
            this.setState({orgConfig:nextProps.orgConfig}); 
            this.setState({orgCustomFields: nextProps.orgConfig.hr_managed_properties})
           
        }
    }
  
    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
        let cf = this.state.customFields
        cf[event.target.id] = event.target.value
        this.setState({customFields: cf})
    };

   
    validateAll(){
        const thisRef = this
        let validated=true
        this.state.orgCustomFields.map(function(f){
            if (!thisRef.state.customFields[f.name] || thisRef.state.customFields[f.name].trim()===''){
                validated = false
            }
            return
        })
        return validated;
    }

    async saveCustomDetails(){
        const { t } = this.props;
        let rowsres;
        let response;
        if (!this.validateAll()){ 
            this.setState({customDetailsIncomplete:true})
            return
        }
        const thisReff = this
        const config = this.state.orgCustomFields.map(function(f){
            return {name: f.name, value: thisReff.state.customFields[f.name]}
        })
        let body = JSON.stringify({
            config
        })
        
        // Add or update candidate custom data

        if (this.state.candidate.hr_id){
            try{
                response = await EgHttp.post('/assess/set_managed_prop/' + this.state.candidate.hr_id, body,true)
                rowsres = await response.json()
            }
            catch(err){}
        }
        console.log("No matter what -> moving on")
        this.start()
        return
    }

    start() {
        this.props.continue();
    }

    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const thisRef = this
        return (

            <div >
                <Paper className={classes.paper}  style={{maxHeight:650}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:20}}>
                            <Grid item xs={12} justify="left">
                                <Typography variant={'h1'} style={{paddingBottom:10}}> {t('Assessment - Custom - Personal Details') }</Typography>
                            </Grid>
                            
                            {this.state.orgCustomFields? this.state.orgCustomFields.map(function(field) {
                                    return (
                                        <Grid item xs={6} className={classes.formRow} >
                                            <Typography variant={'h5'}>{field.name}</Typography>
                                            {field.type === 'mul'?
                                            <TextField name={field.name} size="small" variant="outlined" multiline rows={3} required fullWidth='true' id={field.name} label="" value={thisRef.state[field.name]} onChange={thisRef.handleChange} className={classes.signupTxt}/>:
                                            <TextField name={field.name} size="small" variant="outlined" required fullWidth='true' id={field.name} label="" value={thisRef.state[field.name]} onChange={thisRef.handleChange} className={classes.signupTxt}/>}
                                        </Grid>)
                                        }):null
                            }     
                            <Grid item xs={3}>
                            <EgButton variant="contained" className={classes.actionButton} id="continue-btn" onClick={this.saveCustomDetails}  >
                                {t('Assessment - Opener - Continue')}
                            </EgButton>
                            </Grid>
                            <Grid item xs={9} >
                            {this.state.customDetailsIncomplete?
                            <Typography style={{color: '#FC264D'}}>{t('Assessment - Custom - Personal Details incomplete')} </Typography>:''
                            }
                            </Grid>
                        </Grid>
                </Paper>
            </div>
    );
  }
}
AssessCustomDetails.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AssessCustomDetails),AssessCustomDetails)))