import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@material-ui/core';
import { connect } from "react-redux";
import { setAdminDetails } from "../../redux/admin";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import EgStatusAlert from '../../components/EgStatusAlert';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';

const mapStateToProps = state => ({
    admin: state.admin.value
});
  
const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:10,
        paddingInlineStart:30,
    },
    actionButton:{
        width: 100,
        margin: 5
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
    inputLabel:{
        height:30
    }
});
  
class ProfilePage extends React.Component {
  
    state = {
        rows:{} ,
        org: this.props.admin.org.name
    };

    constructor(props) {
        super(props);  
        this.saveAccount = this.saveAccount.bind(this); 
        this.handleStatusClose= this.handleStatusClose.bind(this)
        
        if (!this.props.admin.logged) {
        this.props.history.push('/signup') 
        } 

        this.getProfile()
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    async getProfile(){
        
        this.props.setProg(true)
        let response = await EgHttp.get('/accounts/' + this.props.admin.account.id)
        let rowsres = await response.json();
        this.setState({rows:rowsres});
        this.setState({firstName:rowsres.firstname, lastName:rowsres.lastname, email:rowsres.email, password:rowsres.token, accountId: rowsres.id})
        this.props.setProg(false);
        return;
    }

    async saveAccount(){
            
        await EgHttp.put('/accounts', JSON.stringify({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                role:  this.state.rows.role,
                token: this.state.password,
                id:this.state.accountId,
                orgId: this.props.admin.org.id
        }))
        .catch((error) => {
            this.showStatusBar('Failed to update profile details');
            return;
        });
          
        this.showStatusBar('Account details updated successfully!');
        return;

    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }
    handleStatusClose(){
        this.setState({statusBar:null})
    }
      
  
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <Grid container>
                <Grid container>
                    <Grid xs={12} style={{paddingInlineStart:30, paddingTop:20}}>
                        <Typography variant={'h2'}>{t('Settings - Profile - Profile')}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}>
                        <Typography variant={'h5'} style={{textAlign:'start'}}>{t('Settings - Profile - First Name')}</Typography>
                        <TextField size='small' autoComplete="firstname" name="FirstName" variant="outlined" required fullWidth='true' id="firstName" label="" value={this.state.firstName} onChange={this.handleChange} className={classes.signupTxt}
                        InputProps={{
                            classes: { input: classes.inputLabel }, 
                        }}>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}></Grid>
                    <Grid item xs={6} className={classes.formRow}>
                        <Typography variant={'h5'} style={{textAlign:'start'}}>{t('Settings - Profile - Last Name')}</Typography>
                        <TextField size='small' autoComplete="lastname" name="LastName" variant="outlined" required fullWidth='true' id="lastName" label="" value={this.state.lastName} onChange={this.handleChange} className={classes.signupTxt}
                        InputProps={{
                            classes: { input: classes.inputLabel }, 
                        }}>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}></Grid>
                    <Grid item xs={6} className={classes.formRow}>
                        <Typography variant={'h5'} style={{textAlign:'start'}}>{t('Settings - Profile - Email')}</Typography>
                        <TextField size='small' autoComplete="email" name="Email" variant="outlined" required fullWidth='true' id="email" label="" value={this.state.email} onChange={this.handleChange} className={classes.signupTxt}
                        InputProps={{
                            classes: { input: classes.inputLabel }, 
                        }}>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}></Grid>
                    <Grid item xs={6} className={classes.formRow}>
                        <Typography variant={'h5'} style={{textAlign:'start'}}>{t('Settings - Profile - Password (Set up new password)')}</Typography>
                        <TextField size='small' type="password" autoComplete="password" name="Password" variant="outlined" required fullWidth='true' id="password" label="" value={this.state.password} onChange={this.handleChange} className={classes.signupTxt}
                        InputProps={{
                            classes: { input: classes.inputLabel }, 
                        }}>
                            
                        </TextField>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}></Grid>
                    <Grid item xs={6} className={classes.formRow}>
                        <Typography variant={'h5'} style={{textAlign:'start'}}>{t('Settings - Profile - Company')}</Typography>
                        <TextField size='small' readonly name="Company" variant="outlined" required fullWidth='true' id="company" label="" value={this.state.org} onChange={this.handleChange} className={classes.signupTxt}
                        InputProps={{
                            classes: { input: classes.inputLabel }, 
                        }}>  
                        </TextField>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}></Grid>
                    <Grid item xs={6} justify="center" style={{textAlign:"center", paddingTop:15}} >
                        <EgButton className={classes.actionButton} onClick={this.saveAccount}>{t('Settings - Profile - Save')}</EgButton> 
                    </Grid>
                    <Grid item xs={6} size='small' className={classes.formRow} style={{paddingTop:15}}></Grid>
                </Grid>
            
                {this.state.statusBar}
            </Grid>
        );
    }
}
ProfilePage.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(ProfilePage),ProfilePage)))