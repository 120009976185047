import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import general_insights from '../../img/general_insights_full.svg';
import working_with_icon from '../../img/working_with_icon.svg';
import EgSegmentSlicer from '../../util/EgSegmentSlicer';
import ReportsManager from '../../managers/ReportsManager';
import EgHttp from '../../util/EgHttp';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import _ from 'lodash';
import LangPicker from '../../util/LangPicker';
import EgSegmentParser from '../../util/EgSegmentParser';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        paddingTop:'1.5vh',
        border:0,
        backgroundColor:'#FAFAFB'
    },
    
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:20
    },
    boxTitle:{
        paddingTop:'2vh',
        paddingBottom:'2vh',
        paddingInline:20,
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
  });

class EmployeeCardRecommendations extends React.Component {
  
    /*
    constructor(props) {
        super(props);              
    }*/

    state = {
        employee: this.props.employee?this.props.employee:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        recommSummaryArr:[],
        invalidReport:false
    };

    componentWillReceiveProps(nextProps) {  
        if (nextProps.employee && nextProps.employee.hr_id){
            this.setState({employee:nextProps.employee}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.employee, nextProps.reportParts)
        }
    }
    
    componentDidMount(){
        this.intializeData(this.props.employee, this.state.reportParts)
    }

    intializeData(employee, reportParts){
        this.setState({employee})
        if (employee.report && employee.report.length>0){
            const ongoingReport =  employee.report.filter(report => report.assessment.type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW)
            //const ongoingReportsWithOnlyRecomm = ongoingReport.filter(report => report.report_part.structure_section_id === 5)
            const sortedOngoingReports = ongoingReport.sort((a,b) => a.created_at < b.created_at)
            this.getReportPartsForOngoing(sortedOngoingReports)
            this.setState({ongoingReports: sortedOngoingReports})
        }
        this.setState({reportParts})
        if (!employee.report || employee.report.length===0){
            return
        }
        const reportInd = ReportsManager.findInitialReport(employee)
        const recArr = ReportsManager.setRecommendationsSummary(employee.report[reportInd], reportParts)
        this.setState({recommSummaryArr: recArr})    
        if (employee.report[reportInd].dominant_traits && this.props.employee.report[reportInd].dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
       
    }
    async getReportPartsForOngoing(reports){ 
        let flatArray = []
        reports.map(function(report){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 5 )
            if (ind > 0){
                const part = report.report_part[ind]
                if (Array.isArray(part.template_id)){
                    flatArray.push(part.template_id)
                    return null
                }
                else{
                    Object.values(part.template_id).forEach(function(arr){
                        flatArray.push(arr)
                    })
                    return null
                }
            }
            return null
        })
        flatArray = flatArray.flat()
        const uniqueParts = _.uniq(flatArray)
        const ongoingTemplateTexts = this.getAllOngoingRecommendations(uniqueParts, reports)
        this.setState({ongoingPartsText: ongoingTemplateTexts})
        console.log(uniqueParts)
    }

    async getAllOngoingRecommendations(uniqueParts ,reports){
        try{
            const thisref = this
            const uniquePartsStr = uniqueParts.join(',')
            const responseparts = await EgHttp.get('/reports/partsForAllOngoings/' + uniquePartsStr + '/' + LangPicker.current())
            let rowstexts = await responseparts.json();  
            const reportsWithText = reports.map(function(report){
                return {id:report.id, created_at:report.created_at, recomText:thisref.getTextsForParts(report, rowstexts)}
            })
            this.setState({reportsWithText: reportsWithText})
            return rowstexts
        }
        catch(err){
            console.error(err.message)
        }
    }
    getTextsForParts(report,ongoingTexts){
        const { t } = this.props;
        const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 5 )
        if (ind >-1){
            const templateIds = report.report_part[ind].template_id
            const availTexts = ongoingTexts;
            const dateStr = new Date(report.created_at).toDateString()
            let finalHtml = '[C]\n[T]'+t("Reports - Summary - Recommendations for") + ': ' +dateStr + '[/T]\n'      
            templateIds.map(function(tid){
                const tind = availTexts.findIndex(({id} ) => id === tid )
                if (tind > -1){
                    const reductedStr = EgSegmentParser.parseOngoing(LangPicker.current() === 'en'?availTexts[tind].text:availTexts[tind].report_template_localized[0].text, new Date(report.created_at).getMonth())
                    finalHtml +=  reductedStr + '\n'
                }
                return null
            })
            finalHtml += '[/C]'
            return finalHtml
        }
        return 

    }

    render() {

        const { classes } = this.props;
        const { t } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            {this.state.invalidReport?
            <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'1vh', paddingLeft:10, paddingRight:10}}>
                <Typography variant={'h3'} className={classes.invalid}>{ t('Reports - Summary - Invalid Assessment')}</Typography>
            </Grid>:
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Single - Work Recommendations')}</Typography>
                    
                    <Grid container style={{marginTop:0}}>
                        <Grid xs={8}> 
                            <Grid container>
                                <Grid xs={12} style={{paddingInlineStart:20,   paddingTop:'2vh'}}>
                                    <Table><TableBody><TableRow>
                                    <TableCell style={{border:0, padding:5, width:0,paddingBottom:0}}><img src={working_with_icon}  alt="overall" /></TableCell>
                                    <TableCell style={{border:0, padding:5}}><Typography variant={'h2'} >{t('Reports - Single - Working with')}&nbsp; {this.state.employee?this.state.employee.firstname:''}</Typography></TableCell>
                                    </TableRow></TableBody></Table>
                                    
                                </Grid>
                                <Grid xs={12} style={{paddingInline:65, paddingBottom:'3vh'}}>
                                    <Typography variant={'h5'} style={{color:'#58666B', textAlign:'start'}}>{t('Reports - Insights - These are recommendations for the day-to-day')}</Typography>
                                </Grid>
                                <Grid xs={12}>
                                    {this.state.recommSummaryArr && this.state.recommSummaryArr.length?
                                        this.state.recommSummaryArr.map((score, index) => (                        
                                            <Grid container>
                                                <Grid xs={12} style={{paddingInline:65}}>
                                                    <Typography variant={'h4'} style={{ textAlign:'justify', lineHeight:2, paddingBottom:'1.5vh'}}>
                                                        {this.state.recommSummaryArr.length?
                                                        <EgSegmentSlicer text = {ReportsManager.getTemplateText(score.trait_id, this.state.recommSummaryArr, this.state.reportParts)}/>:''
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )):''}
                                    {this.state.reportsWithText && this.state.reportsWithText.length?
                                        this.state.reportsWithText.map((report, index) => (
                                        <Grid container>
                                            <Grid xs={12} style={{paddingInline:65}}>
                                                <Typography variant={'h4'} style={{ textAlign:'justify', lineHeight:2, paddingBottom:'1.5vh'}}>
                                                    
                                                    <EgSegmentSlicer text = {report.recomText}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )):''}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={1}> </Grid>
                        <Grid xs={3}> 
                            <img src={general_insights}  alt="overall" style={{width:'60%', maxHeight:'67vh', paddingTop:20}}/>
                        </Grid>
                    </Grid>
                   
                    
                </Paper>
            </Grid>
            }
           </div>

    );
  }
}
EmployeeCardRecommendations.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(EmployeeCardRecommendations),EmployeeCardRecommendations);