import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, IconButton, Checkbox, FormGroup, FormControlLabel, Select, MenuItem, FormControl, InputLabel, TextField } from '@material-ui/core';
import EventLog from '../util/EventLog';
import { withRouter, Link } from 'react-router-dom';
import { setAdminDetails, decrement } from "../redux/admin";
import { connect } from "react-redux";
import empty from '../img/empty_tests.svg';
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next';
import EgHttp from '../util/EgHttp';
import EgButton from './EgButton';
import env from "react-dotenv";
import LangPicker from '../util/LangPicker'; 
import EgProgress from './EgProgress';
import { AssessmentTypes } from '../util/constants/AssessmentTypes';
import { Autocomplete } from '@material-ui/lab';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding:0,
    },
    signupLink:{
        textDecoration:"none",
        color: theme.palette.text.white,
    },
    emptyStateImg:{
        width:250,
        opacity:0.5
    },
    actionButton:{
        width: 100,
        margin: 5
    },
    actionButtonMini:{
        marginBottom:3,
        paddingTop:2,
        paddingBottom:2,
        fontSize: 10,
        marginInlineStart:'1vw'
    },
    tableLeftRow:{
        display:'flex', 
        alignItems:'center', 
        padding:15, 
        borderTop:'1px solid #efefef', 
        borderRight:'1px solid #efefef', 
        justifyContent:'right',
    },
    tableRightRow:{
        display:'flex', 
        alignItems:'center', 
        padding:15, 
        borderTop:'1px solid #efefef', 
        justifyContent:'left', 
    },
    postit:{
        backgroundColor: theme.palette.primary.light,
        display:'flex',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center',
    },
    actionButtonEmpty:{
        paddingInline:10
    },
    selectBox:{
        '& .MuiOutlinedInput-input':{
            fontSize:14,
            paddingTop:12,
            paddingBottom:12
        }
    }
})

const availSubjects = [{id:5001, name:'NUMERICAL_REAS', display_name:"Numeric Reasoning", translated_name:"חשיבה כמותית"}, {id:5002, name:'LOGICAL_REAS', display_name:"Logical Reasoning",translated_name:"חשיבה לוגית"}, {id:5003, name:'BASIC_ENG', display_name:"Basic English",translated_name:"אנגלית בסיסית"}, {id:5004, name:'MARINE_SUITABILITY', display_name:"Marine Suitability",translated_name:"התאמה לתפקיד ימי"}]

const ASSESSMENT_TYPE_INITIAL = 1;
const ASSESSMENT_TYPE_ONGOING_EMPLOYEE = 2;
const ASSESSMENT_TYPE_ONGOING_MANAGER = 3

class AssignTestDialog extends React.Component {
  
    state = {
        open: this.props.open,
        human_resource:this.props.human_resource,
        requireVideo: env.REQUIRE_VIDEO.toLowerCase(),
        requireFollowup:false,
        assessLang:LangPicker.current(),
        actionBtnDisabled: false,
        context:'candidate',
        remoteQuestionnaire:0,
        assessmentTypes: [],
        conextAseessmentTypes: [],
        assTypeStrings:{},
        availSubjects:availSubjects,
        subjectsToAdd:[]
    };

    constructor(props) {
        super(props);          
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 
        this.assign = this.assign.bind(this);
        this.setVideo = this.setVideo.bind(this)
        this.changeLang = this.changeLang.bind(this);
        this.changeType = this.changeType.bind(this);
        this.moveToAddNewQuestionnaire = this.moveToAddNewQuestionnaire.bind(this)
        this.changeQuestionnaire = this.changeQuestionnaire.bind(this)
        this.getAssessmentTypes = this.getAssessmentTypes.bind(this)
        this.changeSubjects = this.changeSubjects.bind(this)
        this.setFollowup = this.setFollowup.bind(this)
    }

    componentDidMount(){
        this.getQuestionnaires()
        this.getAssessmentTypes()
        this.setState({context:this.props.context})
        this.setState({requireVideo:this.props.admin.org.video_required === 1?true:false})
        this.setState({human_resource:this.props.human_resource})

        if (this.props.context === 'manager'){
            this.setState({assessType:ASSESSMENT_TYPE_ONGOING_MANAGER})
        }
        else if (this.props.context === 'employee' && !this.props.noInitial){
            this.setState({assessType:ASSESSMENT_TYPE_ONGOING_EMPLOYEE})
        }
        else{
            this.setState({assessType:AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT})
        }
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.human_resource!==this.state.human_resource ){             
            this.setState({human_resource:nextProps.human_resource});  
        }
    }

    async getAssessmentTypes() {
        const resp = await EgHttp.get('/assess/asstypes')
        const assessmentTypes = await resp.json()
       
        this.setState({assessmentTypes})
        if (this.props.context === 'manager'){
            this.setState({assessType:assessmentTypes.MANAGER_ONGING_REVIEW})
        }
        else if (this.props.context === 'employee' && !this.props.noInitial){
            this.setState({assessType:assessmentTypes.EMPLOYEE_ONGOING_REVIEW})
        }
        else{
            this.setState({assessType:assessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT})
        }

        const ctxAssListJson = await EgHttp.get(`/assess/asstypes/${this.props.context}`)
        const ctxAssList = await ctxAssListJson.json()
        console.log(ctxAssList)
        this.setState({conextAseessmentTypes: ctxAssList})
        const assTypeStrings = {
            'INITIAL_RISK_AND_PERSONALITY_ASSESSMENT': "Candidates - Assign - Assessment Type - Initial",
            'INITIAL_PERSONALITY_ONLY_ASSESSMENT':"Candidates - Assign - Assessment Type - Personality Only",
            'INITIAL_RISK_ONLY_ASSESSMENT':"Candidates - Assign - Assessment Type - Risk Only",
            'EMPLOYEE_ONGOING_REVIEW': "Candidates - Assign - Assessment Type - Employee Ongoing",
            'MANAGER_ONGING_REVIEW': "Candidates - Assign - Assessment Type - Manager Ongoing",
            'REMOTE_INTERVIEW': "Candidates - Assign - Assessment Type - Remote Interview",
            'INITIAL_SKILLS_TESTS': "Candidates - Assign - Assessment Type - Skills Tests"
        }
        this.setState({assTypeStrings})
        console.log('Assessment types of current context loaded')
        console.dir(ctxAssList)
        console.log('Strings:')
        console.dir(assTypeStrings)
    }

    async getQuestionnaires(){
        try{
            let response = await EgHttp.get( '/remoteinterviews/questionnaires')
            if (response.status === 401) {
                this.props.history.push('/signin') 
                return;
            }
            let rowsres = await response.json();
            this.setState({questionnaires:rowsres});
            return;
        }
        catch(err){
          console.error(err)
        }
    }

    close(){
        this.handleClose();
    }
    moveToAddNewQuestionnaire(){
        this.props.history.push('/remote')
    }

    async assign(){
        const { t } = this.props;
        // Check if trying to assign remote interview without selecting questeniere -> show error
        if (this.state.assessType === this.state.assessmentTypes.REMOTE_INTERVIEW && this.state.remoteQuestionnaire === 0){
            this.setState({errorEmptyRemote:<Grid item xs={12} style={{textAlign:"center"}}><Typography variant={'h5'} style={{color:'#FC264D', paddingTop:10}}>{t('Candidates - Assign - Error no remote')}</Typography> </Grid>})
            return
        }
        if (this.state.assessType === this.state.assessmentTypes.INITIAL_SKILLS_TESTS && (!this.state.subjectsToAdd || this.state.subjectsToAdd.length === 0)){
            this.setState({errorEmptyRemote:<Grid item xs={12} style={{textAlign:"center"}}><Typography variant={'h5'} style={{color:'#FC264D', paddingTop:10}}>{t('Candidates - Assign - Error no skills')}</Typography> </Grid>})
            return
        }
        
        this.setState({loading:<EgProgress />})
        this.setState({actionBtnDisabled:true})
        const jbody = JSON.stringify({
            id:this.state.human_resource.id,
            org_name: this.props.login.org.name,
            acc_name: this.props.login.account.firstname,
            hr_name: this.state.human_resource.firstname,
            org_id: this.props.login.org.id,
            email: this.state.human_resource.email,
            phone: this.state.human_resource.phone,
            video: this.state.requireVideo,
            followup: this.state.requireFollowup,
            lang:this.state.assessLang,
            type: this.state.assessType,
            assessTime: this.props.admin.org.time_limit*60*1000,
            subjects:this.state.subjectsToAdd
        }) 
        let res;
        let asres
        if (this.state.context === 'candidate'){
            res = await EgHttp.put('/candidates/assign', jbody)
            asres = await res.json();
            const qid = this.state.remoteQuestionnaire
            // If there is a remote interview selected, assign in to the assessment.
            if ((qid && qid>0) || qid === -1){
                const ribody = JSON.stringify({
                    assessment_id: asres[1].id,
                    questionnaire_id: qid
                })
                res = await EgHttp.post('/remoteinterviews/interview', ribody)
            }
        }
        else{
            res = await EgHttp.put('/employees/assign', jbody)
        }
        EventLog.insert(this.props.login.org.id, this.state.human_resource.id, EventLog.ASSESSMENT_ASSIGNED, 0)
        this.props.decrement();
        this.setState({loading:null})
        this.setState({actionBtnDisabled:false})
        this.handleClose();
        this.props.refresh("SUCCESS", t('Candidates - Assign - Assessment was assigned successfully'));
        return;
    }

    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };
    setVideo(){
        if (this.state.requireVideo === true || this.state.requireVideo === 'true'){
            this.setState({requireVideo:false})
        }
        else {
            this.setState({requireVideo:true})
        }
    }
    setFollowup(){
        if (this.state.requireFollowup === true || this.state.requireFollowup === 'true'){
            this.setState({requireFollowup:false, videoChkDisabled:false, remoteDisabled:false, remoteQuestionnaire:0})
        }
        else {
            this.setState({requireFollowup:true, remoteQuestionnaire : -1})
            this.setState({requireVideo:true, videoChkDisabled:true,  remoteDisabled:true})
        }
    }
    changeLang(event){
        this.setState({assessLang:event.target.value})
    }
    
    changeQuestionnaire(event){
        this.setState({remoteQuestionnaire:event.target.value})
        if (event.target.value > 0){
            this.setState({requireVideo:true, videoChkDisabled:true})
        }
        else{
            if (this.state.assessType !== this.state.assessmentTypes.REMOTE_INTERVIEW){
                this.setState({videoChkDisabled:false, requireFollowup:false})
            }
        }
    }

    changeType(event){
        this.setState({assessType:event.target.value})
        if (this.state.assessmentTypes && event.target.value === this.state.assessmentTypes.REMOTE_INTERVIEW){
            this.setState({requireVideo:true, videoChkDisabled:true, requireFollowup:false, followupDisabled:true, remoteDisabled:false, remoteQuestionnaire:0})
        }
        else{
            if (this.state.remoteQuestionnaire === 0){
                this.setState({videoChkDisabled:false})
            }
            this.setState({followupDisabled:false})
        }
        if (this.state.assessmentTypes && event.target.value === this.state.assessmentTypes.INITIAL_SKILLS_TESTS){
            this.setState({ requireFollowup:false, followupDisabled:true})
        }
    }
    
    changeSubjects(event, newValue){
        this.setState({subjectsToAdd:newValue})
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const weakThis = this;
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='800px'
            >
                <DialogContent style={{padding:0,width:800, maxWidth:800}}>
                    {this.state.loading}
                    {this.props.admin.org.tests>0?
                   <Paper elevation={0} style={{width:800, maxWidth:800, }} >
                       <Grid container  >
                           <Grid item xs={8} style={{paddingTop:20, paddingInlineStart:20}}>
                               <Typography variant={'h1'} >{t('Candidates - Assign - Assign Assessment')}</Typography>
                            </Grid>
                            {this.state.context === 'candidate'?
                            <Grid item xs={4 } justify="center" className={classes.postit}>
                               <Typography variant={'h4'} style={{color:'#0090B0'}}> <span style={{fontWeight:600, fontSize:18}}>{this.props.admin.org.tests}</span> {t('Candidates - Assign - assessments left')}</Typography>
                            </Grid>:<Grid item xs={4 }></Grid>}
                            <Grid item xs={12} style={{paddingInlineStart:20, paddingTop:30, }}>
                                <Typography variant={'h4'}>{t('Candidates - Assign - You are about to assign')} {" " + this.state.human_resource.firstname + " " + this.state.human_resource.lastname}</Typography> 
                            </Grid>
                            <Grid item xs={12} style={{padding:20}}>
                                <Grid container>
                               
                                <Grid item xs={3} className={LangPicker.direction()==='rtl'? classes.tableRightRow:classes.tableLeftRow}>
                                    <Typography variant={'h5'}>{t('Candidates - Assign - Assessment Type')}</Typography> 
                                </Grid>
                                
                                {this.state.context === 'candidate'?
                                <Grid item xs={9} className={LangPicker.direction()==='rtl'? classes.tableLeftRow:classes.tableRightRow} style={{ paddingTop:4, paddingBottom:4}}>
                                    <FormControl  style={{paddingTop:5, paddingBottom:5, minWidth:480}}>
                                        <Select variant="outlined" size='small' className={classes.selectBox} style={{fontSize:13, color:'#000000', width:'480'}}
                                            id="assess-type-select"
                                            value={this.state.assessType}
                                            onChange={this.changeType}  
                                        >
                                           {this.state.conextAseessmentTypes.map(function(assType){
                                                return (<MenuItem disabled={false} value={assType.value}>{t(weakThis.state.assTypeStrings[assType.key])}</MenuItem>)
                                           })}
                                        </Select>
                                        <FormGroup style={{paddingBottom:0}}>
                                            {this.state.requireFollowup?
                                            <FormControlLabel style={{marginInlineStart:-10}} control={<Checkbox disabled={this.state.followupDisabled} checked={this.state.requireFollowup} onChange={this.setFollowup} color="primary" size='small'/>} label={<Typography variant={'h4'} style={{fontSize:13}}>{t("Candidates - Assign - Followup Interview")}</Typography>} />:
                                            <FormControlLabel style={{marginInlineStart:-10}} control={<Checkbox disabled={this.state.followupDisabled} checked={false} onChange={this.setFollowup} color="primary" size='small'/>} label={<Typography variant={'h4'} style={{fontSize:13}}>{t("Candidates - Assign - Followup Interview")}</Typography>} />}
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                :
                                <Grid item xs={9} className={LangPicker.direction()==='rtl'? classes.tableLeftRow:classes.tableRightRow} style={{paddingTop:5, paddingBottom:5}}>
                                    <FormControl  style={{paddingTop:5, paddingBottom:5}}>
                                        <Select variant="outlined" size='small' className={classes.selectBox} style={{fontSize:13, color:'#000000'}}
                                            id="assess-type-select"
                                            value={this.state.assessType}
                                            onChange={this.changeType}
                                        >
                                            <MenuItem disabled={!this.props.noInitial} value={AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT}>{t('Candidates - Assign - Assessment Type - Initial')}</MenuItem>
                                            <MenuItem disabled={!this.props.noInitial} value={AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT}>{t('Candidates - Assign - Assessment Type - Personality Only')}</MenuItem>
                                            <MenuItem disabled={this.props.noInitial || this.state.assessType===AssessmentTypes.ASSESSMENT_TYPE_ONGOING_MANAGER} value={AssessmentTypes.EMPLOYEE_ONGOING_REVIEW}>{t('Candidates - Assign - Assessment Type - Employee Ongoing')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                }

                                {/* More subject that can be selected as part of the assessment */}
                                <Grid item xs={3} className={LangPicker.direction()==='rtl'? classes.tableRightRow:classes.tableLeftRow} style={{ paddingTop:5, paddingBottom:5}}>
                                    <Typography variant={'h5'}>{t('Candidates - Assign - Additional Subjects')}</Typography> 
                                </Grid>
                                <Grid item xs={9} className={LangPicker.direction()==='rtl'? classes.tableLeftRow:classes.tableRightRow} style={{  paddingTop:5, paddingBottom:5}}>
                                    <FormControl  style={{paddingTop:5, paddingBottom:5}}>
                                            <InputLabel id="selectedCandidatesInp" style={{fontSize:11}}></InputLabel>
                                            <Autocomplete size='small' variant="outlined" labelId="modellabel" multiple style={{width:480}}
                                                id="selectedSubjects" name="selectedSubjects"
                                                limitTags={3}
                                                value={this.state.subjectsToAdd}
                                                options={this.state.availSubjects?this.state.availSubjects:[]}
                                                getOptionLabel={(option) => option?LangPicker.current()==='en'?option.display_name:option.translated_name:''}
                                                filterSelectedOptions
                                                disableCloseOnSelect
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="" placeholder={t('Candidates - Assign - Add Subjects')} style={{fontSize:11}}/>
                                                )}
                                                className={classes.selectBox}
                                                onChange = {this.changeSubjects}
                                                ChipProps={{style: {backgroundColor: '#C1A5FF', color:'#ffffff'}}}
                                            >
                                            </Autocomplete>
                                    </FormControl>
                                    
                                </Grid>

                                {/* Remote (Recorded) Interview */}
                                <Grid item xs={3} className={LangPicker.direction()==='rtl'? classes.tableRightRow:classes.tableLeftRow} style={{ paddingTop:5, paddingBottom:5}}>
                                    <Typography variant={'h5'}>{t('Remote - Assign - Remote Interview')}</Typography> 
                                </Grid>
                                <Grid item xs={9} className={LangPicker.direction()==='rtl'? classes.tableLeftRow:classes.tableRightRow} style={{  paddingTop:5, paddingBottom:5}}>
                                    <FormControl  style={{paddingTop:5, paddingBottom:5}} disabled={this.state.remoteDisabled}>
                                        <Select variant="outlined" size='small'  className={classes.selectBox} style={{fontSize:13, color:'#000000', maxWidth:300, width:300}}
                                            id="assess-lang-select"
                                            value={this.state.remoteQuestionnaire}
                                            onChange={this.changeQuestionnaire}
                                        >
                                            <MenuItem value={0} >{t('Remote - Assign - Not Assigned') }</MenuItem>
                                           {this.state.questionnaires?this.state.questionnaires.map(function(qstnr){
                                                return (<MenuItem value={qstnr.id}>{qstnr.name}</MenuItem>)
                                           }):''}
                                        </Select>
                                    </FormControl>
                                    <EgButton  secondary="true" outlined="true"  className={classes.actionButtonMini} onClick={this.moveToAddNewQuestionnaire}>{t('Remote - Assign - Create New Questionnaire')}</EgButton> 
                                </Grid>
                                
                                
                                <Grid item xs={3} className={LangPicker.direction()==='rtl'? classes.tableRightRow:classes.tableLeftRow} style={{ paddingTop:5, paddingBottom:5}}>
                                    <Typography variant={'h5'}>{t('Candidates - Assign - Video')}</Typography> 
                                </Grid>
                                <Grid item xs={9} className={LangPicker.direction()==='rtl'?classes.tableLeftRow:classes.tableRightRow} style={{ paddingInline:0, paddingTop:5, paddingBottom:5}}>
                                    <FormGroup>
                                        <FormControlLabel style={{marginInlineStart:5}} control={<Checkbox disabled={this.state.videoChkDisabled} checked={this.state.requireVideo} onChange={this.setVideo} color="primary" size='small'/>} label={<Typography variant={'h4'} style={{fontSize:13}}>{t("Candidates - Assign - Require Video and Screen Recording")}</Typography>} />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3} className={LangPicker.direction()==='rtl'? classes.tableRightRow:classes.tableLeftRow} style={{paddingTop:5, paddingBottom:5}}>
                                    <Typography variant={'h5'}>{t('Candidates - Assign - Language')}</Typography> 
                                </Grid>
                                <Grid item xs={9} className={LangPicker.direction()==='rtl'? classes.tableLeftRow:classes.tableRightRow} style={{paddingTop:5, paddingBottom:5}}>
                                    <FormControl  style={{paddingTop:5, paddingBottom:5}}>
                                        <Select variant="outlined"  size='small' className={classes.selectBox}
                                            id="assess-lang-select"
                                            value={this.state.assessLang}
                                            onChange={this.changeLang}
                                            style={{fontSize:13, color:'#000000'}}
                                        >
                                            <MenuItem value={'en'}>{t('Candidates - Table - English')}</MenuItem>
                                            <MenuItem value={'he'}>{t('Candidates - Table - Hebrew')}</MenuItem>
                                            <MenuItem value={'es'}>{t('Candidates - Table - Spanish')}</MenuItem>
                                            <MenuItem value={'el'}>{t('Candidates - Table - Greek')}</MenuItem>
                                            <MenuItem value={'pt'}>{t('Candidates - Table - Portuguese')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                               
                                <Grid item xs={3} className={LangPicker.direction()==='rtl'? classes.tableRightRow:classes.tableLeftRow}>
                                    <Typography variant={'h5'} >{t('Candidates - Assign - Notify by Email to')}</Typography> 
                                </Grid>
                                <Grid item xs={9} className={LangPicker.direction()==='rtl'?classes.tableLeftRow:classes.tableRightRow}>
                                    <Typography variant={'h4'} style={{color:'#000000'}}>{this.state.human_resource.email}</Typography> 
                                </Grid>
                                <Grid item xs={3} className={LangPicker.direction()==='rtl'? classes.tableRightRow:classes.tableLeftRow} style={{borderBottom:'1px solid #efefef'}}>
                                    <Typography variant={'h5'}>{t('Candidates - Assign - Notify by Text to')}</Typography> 
                                </Grid>
                                <Grid item xs={9} className={LangPicker.direction()==='rtl'?classes.tableLeftRow:classes.tableRightRow} style={{borderBottom:'1px solid #efefef'}}>
                                    <Typography variant={'h4'} style={{color:'#000000'}}>+{this.state.human_resource.phone}</Typography> 
                                </Grid>
                                
                                {this.state.errorEmptyRemote}
                                 <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                    <EgButton disabled={this.state.actionBtnDisabled} onClick={this.assign} className={classes.actionButton}>{t('Candidates - Assign - Assign')}</EgButton>
                                    <EgButton  secondary="true" outlined="true"  className={classes.actionButton} onClick={this.close}>{t('Candidates - Assign - Cancel')}</EgButton>   
                                </Grid>
                            </Grid>
                            </Grid>
                       </Grid>
                   </Paper>
                   :
                   <Paper elevation={0}>
                       <Grid container justify='left'>
                            
                            <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end', paddingInlineEnd:20,paddingTop:20}}>
                                <IconButton aria-label="close" onClick={this.close} size="small">
                                    <CloseIcon></CloseIcon>
                                </IconButton>
                            </Grid>
                            <Grid item xs={12 } justify="center" style={{textAlign:'center'}}>
                                <img src={empty} className={classes.emptyStateImg} alt="empty" />  
                            </Grid>  
                           <Grid item xs={12} style={{ paddingInline:150, paddingTop:'1vh'}}>
                               <Typography variant={'h1'} >{t('Candidates - Assign - Sorry')},</Typography>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop:'0.5vh', paddingBottom:'1vh', paddingInline:150}}>
                                <Typography variant={'h2'}>{t('Candidates - Assign - You are out of assessments')}...</Typography> 
                            </Grid>
                            
                            <Grid item xs={12 } justify="center" style={{paddingTop:'1vh', textAlign:'start',paddingInline:150}}>
                                <EgButton className={classes.actionButtonEmpty}>
                                    <Link className={classes.signupLink} to="/settings">{t('Candidates - Assign - Get More Assessments')}</Link>
                                </EgButton> 
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30, paddingBottom:20}} >
                               
                            </Grid> 
                        </Grid>   
                       </Grid>
                   </Paper>
                }
                
                </DialogContent>
                
                
            </Dialog>
            </div>
        );
    }
}

AssignTestDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(AssignTestDialog),AssignTestDialog)))