import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
//import LangPicker from '../../util/LangPicker';
import EgBarChart from '../../components/EgBarChart';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import ReportsManager from '../../managers/ReportsManager';



const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0,
        padding:10
    },
    
    boxTitle:{
        padding:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        
        overflow:'auto',
        paddingBottom:'2vh'
    },
    boxTitleInd:{
        paddingInline:20,
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
  });

class EmployeeCardAnalytics extends React.Component {
  
    //constructor(props) {
    //    super(props);            
    //}

    state = {
        employee: this.props.employee?this.props.employee:{},
        timeSummaryArr:[]
    };
    componentDidMount(){
        if (this.props.employee ){
            this.setState({employee: this.props.employee})
            this.initialize(this.props.employee)
        }
    }
    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.employee && nextProps.employee !== this.state.employee){
            this.setState({employee:nextProps.employee});
            this.initialize(nextProps.employee)
        }
    }

    initialize(employee){
        if (employee){
            
            const initialAssessmentInd = ReportsManager.findInitialAssessment(employee)
            if (initialAssessmentInd>-1) this.setState({initialAssessment: employee.assessment[initialAssessmentInd]})
            const reports = employee.report
            const sortedReports = reports.sort((a,b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()); 
            const engagementGraphData = this.setGraphData(sortedReports, 'Engagement')
            const engagementBoundaries = this.setBoundaries(sortedReports, 'Engagement')
            this.setState({engagementGraphData: engagementGraphData, engagementBoundaries: engagementBoundaries})
            const satisfactionGraphData = this.setGraphData(sortedReports, 'Satisfaction')
            const satisfactionBoundaries = this.setBoundaries(sortedReports, 'Satisfaction')
            this.setState({satisfactionGraphData: satisfactionGraphData, satisfactionBoundaries: satisfactionBoundaries})
            const motivationGraphData = this.setGraphData(sortedReports, 'Motivation')
            const motivationBoundaries = this.setBoundaries(sortedReports, 'Motivation')
            this.setState({motivationGraphData: motivationGraphData, motivationBoundaries: motivationBoundaries})
            const fulfillmentGraphData = this.setGraphData(sortedReports, 'Fulfillment')
            const fulfillmentBoundaries = this.setBoundaries(sortedReports, 'Fulfillment')
            this.setState({fulfillmentGraphData: fulfillmentGraphData, fulfillmentBoundaries: fulfillmentBoundaries})
            const riskGraphData = this.setRiskGraphData(sortedReports)
            const riskBoundaries = this.setRiskBoundaries(sortedReports)
            this.setState({riskGraphData: riskGraphData, riskBoundaries: riskBoundaries})

            
        }
    }

    setRiskGraphData(reports){
        const graphData = reports.map(function(oneRep){
            if (oneRep.assessment.type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW){
                const riskScores = oneRep.assessment.assessment_scores.filter(score => score.trait.father_id===300 ) //TODO:take care of this
                let ongoingRiskAvg =  riskScores.reduce((total, next) => total + next.trait_adjusted_score, 0)/riskScores.length
                if (!ongoingRiskAvg) ongoingRiskAvg=0
                const assessDate = (new Date(oneRep.created_at).getMonth()+1) + "/" + `${new Date(oneRep.created_at).getFullYear()}`.substring(2)
                return ({x:assessDate, y:Math.round(ongoingRiskAvg)})
            }
            return null
        })
        const cleanGraphData = graphData.filter(function(e){return e});
        return cleanGraphData;
    }

    setGraphData(reports, traitName){
        const thisRef = this
        const graphData = reports.map(function(oneRep){
            if (oneRep.assessment.type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW){
                
                const ind = thisRef.getScoresIndexByTraitName(traitName, oneRep.assessment.assessment_scores)
                const engagementScore = oneRep.assessment.assessment_scores[ind].trait_adjusted_score
                const assessDate = (new Date(oneRep.created_at).getMonth()+1) + "/" + `${new Date(oneRep.created_at).getFullYear()}`.substring(2)
                return ({x:assessDate, y:engagementScore})
            }
            return null
        })
        const cleanGraphData = graphData.filter(function(e){return e});
        return cleanGraphData;
    }

    setRiskBoundaries(reports){
        let boundaries = null
        reports.map(function(oneRep){
            if (oneRep.assessment.type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW){
                const riskScores = oneRep.assessment.assessment_scores.filter(score => score.trait.father_id===300 ) //TODO:take care of this
                if (riskScores && riskScores.length>0 && !boundaries) boundaries = riskScores[0].trait.boundaries 
            }
            return null
        })
        return boundaries
    }

    setBoundaries(reports, traitName){
        const thisRef = this
        let boundaries = null
        reports.map(function(oneRep){
            if (oneRep.assessment.type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW){
                const ind = thisRef.getScoresIndexByTraitName(traitName, oneRep.assessment.assessment_scores)
                if (!boundaries) boundaries = oneRep.assessment.assessment_scores[ind].trait.boundaries 
            }
            return null
        })
        return boundaries
    }

    getScoresIndexByTraitName(traitName, scores){
        const ind = scores.findIndex(({trait}) => trait.name===traitName)
        return ind
    }

    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8',  paddingTop:'1vh'}}>
            <Grid container>
                <Grid item xs={4} className={classes.formRow}>
                    <Paper elevation={0}  className={classes.summaryBox} >
                        <Grid container>
                            <Grid xs={12} className={classes.boxTitle}>
                                <Typography variant={'h4'} style={{fontWeight:600}} >{t('Employees - Single - Engagement Index')} </Typography>
                            </Grid>   
                        </Grid>
                        <Grid container  style={{paddingTop:'1vh', paddingBottom:'1vh', overflow:'hidden'}}>
                            <Grid item xs={12} style={{paddingTop:'0vh', paddingBottom:'1vh', paddingInline:20}}>
                                <Typography variant={'h5'} style={{textAlign:'start'}}>{ t('Employee - Summary - Overall employee engagement level over time')}</Typography>
                            </Grid>
                            <Grid xs={12} style={{display:'flex', justifyContent:'start', paddingBottom:'1vh', paddingTop:'2vh'}}>
                                <Paper elevation={0} style={{height:'21vh', width:'100%'}}>
                                    <EgBarChart value={this.state.engagementGraphData} boundaries={this.state.engagementBoundaries} showWarnings={'true'} warnTitle={t('Employee - Single - Low Engagement Warning')} warnBody={t('Employee - Single - Employee shows low engagement')}></EgBarChart>
                                </Paper>
                            </Grid>
                            
                        </Grid>     
                    </Paper>
                </Grid>
            
                <Grid item xs={4} className={classes.formRow}>
                    <Paper elevation={0}  className={classes.summaryBox} >
                        <Grid container>
                            <Grid xs={12} className={classes.boxTitle}>
                                <Typography variant={'h4'} style={{fontWeight:600}} >{t('Employees - Single - Satisfaction Index')} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container  style={{paddingTop:'1vh', paddingBottom:'1vh', overflow:'hidden'}}>
                            <Grid item xs={12} style={{paddingTop:'0vh', paddingBottom:'1vh', paddingInline:20}}>
                                <Typography variant={'h5'} style={{textAlign:'start'}}>{ t('Employee - Summary - Overall employee satisfaction level over time')}</Typography>
                            </Grid>
                            <Grid xs={12} style={{display:'flex', justifyContent:'start', paddingBottom:'1vh', paddingTop:'2vh'}}>
                                <Paper elevation={0} style={{height:'21vh', width:'100%'}}>
                                    <EgBarChart value={this.state.satisfactionGraphData} boundaries={this.state.satisfactionBoundaries} showWarnings={'true'} warnTitle={t('Employee - Single - Low Satisfaction Warning')} warnBody={t('Employee - Single - Employee shows low satisfaction')}></EgBarChart>
                                </Paper>  
                            </Grid>
                        </Grid>     
                    </Paper>
                </Grid>

            <Grid item xs={4} className={classes.formRow}>
                <Paper elevation={0}  className={classes.summaryBox} >

                <Grid container>
                    <Grid xs={12} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Employees - Single - Motivation Index')} </Typography>
                    </Grid>
                </Grid>

                <Grid container  style={{paddingTop:'1vh', paddingBottom:'1vh', overflow:'hidden'}}>
                    <Grid item xs={12} style={{paddingTop:'0vh', paddingBottom:'1vh', paddingInline:20}}>
                        <Typography variant={'h5'} style={{textAlign:'start'}}>{ t('Employee - Summary - Overall employee motivation level over time')}</Typography>
                    </Grid>
                    <Grid xs={12} style={{display:'flex', justifyContent:'start', paddingBottom:'1vh', paddingTop:'2vh'}}>
                        <Paper elevation={0} style={{height:'21vh', width:'100%'}}>
                            <EgBarChart value={this.state.motivationGraphData} boundaries={this.state.fulfillmentBoundaries} showWarnings={'true'}  warnTitle={t('Employee - Single - Low Motivation Warning')} warnBody={t('Employee - Single - Employee shows low motivation')}></EgBarChart>
                        </Paper>
                    </Grid>
                            
                    
                </Grid>     
            </Paper>
            </Grid>
            <Grid item xs={4} className={classes.formRow}>
                <Paper elevation={0}  className={classes.summaryBox} >

                <Grid container>
                    <Grid xs={12} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Employees - Single - Fulfillment Index')} </Typography>
                    </Grid>
                    
                </Grid>

                <Grid container  style={{paddingTop:'1vh', paddingBottom:'1vh', overflow:'hidden'}}>
                    <Grid item xs={12} style={{paddingTop:'0vh', paddingBottom:'1vh', paddingInline:20}}>
                        <Typography variant={'h5'} style={{textAlign:'start'}}>{ t('Employee - Summary - Overall employee fulfillment level over time')}</Typography>
                    </Grid>
                    <Grid xs={12} style={{display:'flex', justifyContent:'start', paddingBottom:'1vh', paddingTop:'2vh'}}>
                        <Paper elevation={0} style={{height:'21vh', width:'100%'}}>
                            <EgBarChart value={this.state.fulfillmentGraphData} boundaries={this.state.engagementBoundaries} showWarnings={'true'} warnTitle={t('Employee - Single - Low Fulfillment Warning')} warnBody={t('Employee - Single - Employee shows low fulfillment')}></EgBarChart>
                        </Paper>  
                    </Grid>
                </Grid>     
            </Paper>
            </Grid>

            <Grid item xs={4} className={classes.formRow}>
                <Paper elevation={0}  className={classes.summaryBox} >

                <Grid container>
                    <Grid xs={12} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Employees - Single - Risk Index')} </Typography>
                    </Grid>
                    
                </Grid>
                {this.state.initialAssessment && (this.state.initialAssessment.type === AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT || this.state.initialAssessment.type === AssessmentTypes.INITIAL_RISK_ONLY_ASSESSMENT)}
                <Grid container  style={{paddingTop:'1vh', paddingBottom:'1vh', overflow:'hidden'}}>
                    <Grid item xs={12} style={{paddingTop:'0vh', paddingBottom:'1vh', paddingInline:20}}>
                        <Typography variant={'h5'} style={{textAlign:'start'}}>{ t('Employee - Summary - Overall employee risk level over time')}</Typography>
                    </Grid>
                    <Grid xs={12} style={{display:'flex', justifyContent:'start', paddingBottom:'1vh', paddingTop:'2vh'}}>
                        <Paper elevation={0} style={{height:'21vh', width:'100%'}}>
                            <EgBarChart value={this.state.riskGraphData} boundaries={this.state.riskBoundaries} showWarnings={'true'} warnTitle={t('Employee - Single - High Risk Potential Warning')} warnBody={t('Employee - Single - Employee shows higher risk potential')} negative={true}></EgBarChart>
                        </Paper>  
                    </Grid>
                </Grid>     
            </Paper>
            </Grid>
            </Grid>
        </div>
    );
  }
}
EmployeeCardAnalytics.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(EmployeeCardAnalytics),EmployeeCardAnalytics);