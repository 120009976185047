import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, IconButton } from '@material-ui/core';
//import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../../redux/admin";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import Dropzone from 'react-dropzone';
import EgProgress from '../../components/EgProgress';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding:0,
    },
    error:{
        fontSize:14,
        color:"#F15D5D",
        fontWeight: 500,
        textAlign:'center'
    },
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    }
})


class UploadDialog extends React.Component {
  
    state = {
        open: this.props.open,
        candidate:this.props.candidate,
        files:[],
        dropzoneRef: React.createRef(),
        errorMsg:null,
        loading:null,
    };

    constructor(props) {
        super(props); 
        
        
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 
        this.onDrop = this.onDrop.bind(this);
        this.openFileDialog = this.openFileDialog.bind(this)
        this.onDropRejected = this.onDropRejected.bind(this)
        this.uploadSelectedFile = this.uploadSelectedFile.bind(this)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});        
        }
    }

    close(){
        this.handleClose();
    }

    onDrop(files){
        this.setState({errorMsg:null})
        this.setState({files:files})
    }
    onDropRejected(rejected){
        const { t } = this.props;
        if(rejected.length>1){
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - You can select only one file')}</Typography> });
        }
        else if(rejected[0].errors[0].code === "file-too-large"){
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - File cannot acceed')}</Typography> });
        }
        else{
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - Illegal file type')}</Typography> });
        }
    }

    uploadSelectedFile(){
        const { t } = this.props;
        if (this.state.files && this.state.files.length) {
            this.upload()
        }
        else{
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - No files selected')}</Typography> });
        }
    }

    async upload(){
        this.setState({loading:<EgProgress />})
        const { t } = this.props;
        let formData = new FormData();
        formData.append('file', this.state.files[0]);
        let response = await EgHttp.postFile('/candidates/importcsv', formData);
        if (response.status === 200){
            this.setState({files:[]})
            let impRes = await response.json();
            if (impRes.inserted > 0){
                this.handleClose();
                this.props.refresh("SUCCESS", t('Candidates - Import - Successfull imported candidates from CSV') + " " + t('Candidates - Import - Imported') + impRes.inserted + " " + t('Candidates - Import - Failed') + impRes.failed);
            }
            else{
                this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - Failed to import candidates') +": "+ await response.text()}</Typography> });
            }
        }
        else{
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - Error') +": "+ await response.text()}</Typography> });
        }
        this.setState({ loading:null});
        console.log(this.props.admin)
        // EventLog.insertAcc(this.props.admin.org.id, 0, EventLog.INSERT_BULK_CANDIDATES, this.props.admin.account.id)
        return;  
    }

    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };
    openFileDialog() {
        if (this.state.dropzoneRef.current) {
            this.state.dropzoneRef.current.open()
        }
    };
    
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const files = this.state.files.map(file => (
            <li key={file.name}>
              {file.name} - {file.size} bytes
            </li>
          ));
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{padding:0}}>
                    
                   <Paper elevation={0} >
                   {this.state.loading}
                       <Grid container justify='left' >
                           <Grid item xs={10} style={{paddingTop:20, paddingInlineStart:20}}>
                               <Typography variant={'h1'} >{t('Candidates - Import - Import candidates from CSV')}</Typography>
                            </Grid>
                            <Grid item xs={2} style={{padding:10, textAlign:'end'}}>
                                <IconButton aria-label="close" onClick={this.close} size="small">
                                    <CloseIcon></CloseIcon>
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} style={{paddingInlineStart:20, paddingTop:20, }}>
                                <Typography variant={'h4'}>{t('Candidates - Import - Select a candidates file')}</Typography> 
                            </Grid>
                            <Grid item xs={12} style={{paddingInlineStart:20, paddingTop:10, }}>
                                <Typography variant={'h5'}>{t('Candidates - Import - The CSV should include 5 columns')} <i>firstname,lastname,email,phone,position</i> </Typography> 
                                <Typography variant={'h5'}>{t('Candidates - Import - It should be comma')}</Typography> 
                            </Grid>
                            <Grid item xs={12} style={{padding:20}}>
                                <Grid container>
                                    <Dropzone onDrop={this.onDrop} ref={this.state.dropzoneRef} maxFiles={1} onDropRejected={this.onDropRejected} accept={["text/csv","application/vnd.ms-excel"]} onSubmit={this.upload} maxSize={2000000}>
                                        {({getRootProps, getInputProps}) => (
                                        <section className={classes.container}>
                                            <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <p>{t('Candidates - Import - Drag and drop candidate file here')}</p>
                                            
                                            </div>
                                           
                                            <EgButton onClick={this.openFileDialog} secondary="true" outlined>{t('Candidates - Import - Select File')}</EgButton>
                                            <div><h4>File:</h4></div>
                                            <div>{files}</div>
                                            
                                            
                                        </section>
                                        )}
                                    </Dropzone>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{padding:10, display:'flex', justifyContent:'center'}}>
                                {this.state.errorMsg}
                            </Grid>
                            <Grid item xs={12} style={{paddingTop:10, paddingBottom:20, display:'flex', justifyContent:'center'}}>
                                <EgButton onClick={this.uploadSelectedFile} >{t('Candidates - Import - Upload File')}</EgButton>
                            </Grid>
                       </Grid>
                   </Paper>
                   
                </DialogContent>
            </Dialog>
            </div>
        );
    }
}

UploadDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(UploadDialog),UploadDialog)))