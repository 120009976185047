import React from 'react';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles  } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Topbar from '../components/Topbar'
import { FormControl, Grid, InputBase, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import EgProgress from '../components/EgProgress';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as AppConstants from '../util/constants/AppConstants';
import EgStatusAlert from '../components/EgStatusAlert';
import EgHttp from '../util/EgHttp';
import EgButton from '../components/EgButton';
import NavigationManager from '../managers/NavigationManager';
import { withCookies, Cookies } from 'react-cookie';
import ExtendedNavBar from '../components/ExtendedNavBar';
import EmployeeTable from './employees/EmployeeTable';
import AddHumanResource2 from './human_resource/AddHumanResource2';
import EmployeeCard from './employees/EmployeeCard';
import { setAdminDetails } from "../redux/admin";
import { connect } from "react-redux";
import { setNavEmp } from "../redux/nav";
import qs from 'qs';
import empty from '../img/empty_latest.svg';
import ImportEmployees from './employees/ImportEmployees';
import EgCommonData from '../util/EgCommonData';

const drawerWidth = AppConstants.NavigationDrawerWidth;

const mapStateToProps = state => ({
    admin: state.admin.value,
    nav: state.nav.value
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      //marginLeft:10,
      marginLeft:5
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E4E4E4',
    fontSize: 16,
    padding: '8px 26px 8px 12px',
    textAlign:'start',
   
    
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);


const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%',   
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width:'100%',
    minHeight:'73vh',
    overflow:'auto',
    marginTop:'1vh'
  },
  emptyStateImg:{
    width:'25vw',
    opacity:1,
    padding:30,
},
  
  appRight:{
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        background:"#FAFAFA",
      },
      marginRight: drawerWidth
  },
  appLeft:{
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        background:"#FAFAFA",
      },
      marginLeft: drawerWidth
  },
  addNewLink:{
    textDecoration:"none",
    color: theme.palette.text.white,

  },
 
  toolbar:{
      minHeigt:64,
      marginTop:50
  },
  content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width:'100%',
      height:window.innerHeight,

  },
  actionBtns:{
      marginLeft:5,
      marginRight:5
  }
  
});

class Employees extends React.Component {
    state = {
      open: this.props.open,
      drawerState: null,
      operationIcon: null,
      openAdd: false,
      openImport: false,
      openEmployeeId:null,
      rows:[],
      originalRows:[],
      statusBar:null,
      loading:<EgProgress />,
      filterTeam:0
    };

    constructor(props) {
        super(props);    
        
        if (!this.props.login.logged) {
            this.props.history.push('/signup') 
        } 
        else{
            NavigationManager.setLast(this.props, '/allemployees')
        }

        this.openAddEmployee = this.openAddEmployee.bind(this);   
        this.openImportEmployees = this.openImportEmployees.bind(this);    
        this.handleDrawerState = this.handleDrawerState.bind(this); 
        this.openEditEmployee = this.openEditEmployee.bind(this); 
        this.getEmployees = this.getEmployees.bind(this); 
        this.refresh = this.refresh.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this);
        this.search = this.search.bind(this);
        this.openEmployee = this.openEmployee.bind(this); 
        this.closeEmployee = this.closeEmployee.bind(this);   
        this.getFilteredEmployeesByTeam = this.getFilteredEmployeesByTeam.bind(this)
        
        this.getTraitsAverages()
    }

    componentDidMount(){
      this.props.setNavEmp(true);
      this.initializeData()
    }

    async initializeData(){
      if (this.props.admin && !this.props.admin.org.license){
        this.setState({loading:null})
        return;
      }
      const teams = await EgCommonData.getTeams();
      const positions = await EgCommonData.getPositions();
      this.setState({teams: teams, positions:positions})
      await this.getEmployees();
      const teamid=qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).team
      if (teamid){
        this.setState({ filterTeam: teamid});
        this.getFilteredEmployeesByTeam(teamid)
      }
    }
    
    async getTraitsAverages(){
      
      try{
        let response = await EgHttp.get('/reports/traits/averages');
        if (response.status === 401) {
          this.props.history.push('/signin') 
          return;
        }
        let rowsres = await response.json();
        this.setState({traitAverages:rowsres});
        return;
      }
      catch(err){
        console.error(err)
        return
      }
    }

    async getEmployees(){
      
        this.setState({loading:<EgProgress />})
        let response = await EgHttp.get( '/employees')
        if (response.status === 401) {
          this.props.history.push('/signin') 
          return;
        }
        let rowsres = await response.json();
        const origres = [...rowsres]
        this.setState({rows:rowsres, loading:null});
        this.setState({originalRows:origres});
        return;
    }

    handleStatusClose(){
      this.setState({statusBar:null})
    }

    filterTeams = event => {
        this.setState({ filterTeam: event.target.value });
        if (event.target.value === 0){
            this.setState({rows: this.state.originalRows})
            this.props.history.push('/allemployees')
        }
        else{
          this.getFilteredEmployeesByTeam(event.target.value)
          this.props.history.push('/allemployees?team='+event.target.value)
        }
    };

    getFilteredEmployeesByTeam(teamId){
          const allEmps = this.state.originalRows
          const allTeams = this.state.teams
          const filtered = allEmps.filter(function(el) {
              const teamObjInd = allTeams.findIndex(({ id }) => id === parseInt(teamId))
              const teamObj = allTeams[teamObjInd]
              const memberIndex = teamObj.team_members.findIndex(({ hr_id }) => hr_id === el.id)
              if (memberIndex > -1) return el;
              else return null
          })
          this.setState({rows: filtered})
    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }

    
    refresh(status, message){
      this.getEmployees()
      if (status === 'SUCCESS'){
          this.showStatusBar(message)
      }
    }

    openAddEmployee(){
      this.setState({openEmployeeId:null}, ()=> {
        this.setState({openAdd:true, openImport:false})
      })
    }

    openEditEmployee(id){
      this.setState({openEmployeeId:id}, ()=> {
        this.setState({openAdd:true, openImport:false})
      })
    }

    openImportEmployees(){
        this.setState({openImport:true, openAdd:false})
    }

    openEmployee(id){
      this.setState({openEmployeeId:id}, ()=> {
        this.setState({singleEmployeeOpen:true})
      })
    }

    closeEmployee(){
      this.setState({singleEmployeeOpen:false})
      this.setState({openEmployeeId:null})
      this.initializeData()
    }

    handleDrawerState(sw){
        if (sw){
            this.setState({openAdd:!this.state.openAdd, openImport: !this.state.openImport})
        }
        else{
            this.setState({openAdd:false, openImport: false})
        }
    }

    filterItems(arr, query) {
      return arr.filter(function(el) {
        return (el.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1) || (el.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      })
    }

    contactUs(){
      window.open("https://engard.io/contact")
    }

    search(term){
      this.setState({searchTerm: term})
      if (term && term.length>0){
        let filteredRows;
        filteredRows = this.filterItems(this.state.originalRows, term)
        this.setState({rows: filteredRows})
      }
      else{
        this.setState({rows: this.state.originalRows})
      }
    }
  
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
          this.state.singleEmployeeOpen?
          <div className={classes.root} >
            <CssBaseline />
            <ExtendedNavBar navPos={11}></ExtendedNavBar>
            <EmployeeCard openState={this.state.singleEmployeeOpen} hrId={this.state.openEmployeeId} refresh={this.refresh} close={this.closeEmployee} traitAverages={this.state.traitAverages}></EmployeeCard> 
          </div>
          :
          <div className={classes.root} >
            <CssBaseline />
            <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} style={{boxShadow:"0px 0px 0px" }}>
                {this.state.loading}
                <Toolbar >
                    <Topbar login={this.props.login} page={this.props.page} search={this.search}></Topbar>
                </Toolbar>
            </AppBar>

        <ExtendedNavBar navPos={11}></ExtendedNavBar>

          <main className={classes.content}>
              <div className={classes.toolbar} />
            
              <Grid container fullwidth='true'  direction="row" alignItems="flex-start">
                <Grid item xs={12 }>
                  <Grid container   direction="row" alignItems="flex-start">
                      <Grid item xs={6} >
                        <Typography variant="h1" > {t('Employees - All Employees')} </Typography>
                      </Grid>
                      <Grid item xs={6}  style={{textAlign:"end", display:'flex', justifyContent:'end'}} >
                          <FormControl style={{width:300, paddingTop:4}}>
                              <InputLabel id="teamsLbl"></InputLabel>
                                    <Select variant="outlined" labelId="teamsLbl" id="filterTeam" name="filterTeam" value={this.state.filterTeam} onChange={this.filterTeams} className={classes.selectBox} input={<BootstrapInput />}>
                                        <MenuItem value={0}>{t('Employees - All Employees')}</MenuItem>
                                        {this.state.teams? this.state.teams.map(function(t) {
                                            return <MenuItem value={t.id}>{t.name}</MenuItem>
                                        }):null}       
                                    </Select>
                          </FormControl>
                          
                          <EgButton  disabled={this.props.admin&&!this.props.admin.org.license} onClick={this.openAddEmployee} className={classes.actionBtns}>{t('Employees - Add Employee')}</EgButton>
                          <EgButton disabled={this.props.admin&&!this.props.admin.org.license} secondary="true" outlined="true" onClick={this.openImportEmployees} className={classes.actionBtns}>{t('Candidates - Import')}</EgButton>
                      </Grid>
                      {this.props.admin&&this.props.admin.org.license?                  
                      <Grid item xs={12} style={{marginTop:10}}>
                            <EmployeeTable noteTitle={t('Employees - Employee List')} login={this.props.login} editEmployee={this.openEditEmployee} addNewEmployee={this.openAddEmployee} openEmployee={this.openEmployee} rows={this.state.rows} refresh={this.refresh} loading={this.state.loading?true:false} searchTerm={this.state.searchTerm} teams={this.state.teams} > </EmployeeTable>
                      </Grid>
                      :
                      <Paper className={classes.paper}>
                              <Grid item xs={12 }>
                                  <Typography className={classes.noteTitle}> </Typography>
                              </Grid>
                              <Grid item xs={12 }  style={{textAlign:'center', padding:50}}>
                                  <Typography variant={'h2'} style={{textAlign:'center'}}>{t('Employees - Table - You need to upgrade your license')} </Typography>
                              </Grid>  

                              <Grid item xs={12 }  style={{textAlign:'center'}}>
                                  <img src={empty} className={classes.emptyStateImg} alt="empty" />  
                              </Grid>  

                              <Grid item xs={12 }  style={{textAlign:'center'}}>
                                  <EgButton type="submit"  variant="contained" onClick={this.contactUs} className={classes.actionButton}>
                                      {t('Employees - Table - ContactUs')}
                                  </EgButton>  
                              </Grid>       
                      </Paper>  
                      }
                
                      
                  </Grid>
                </Grid>
              </Grid>
          </main>
          <ImportEmployees handleDrawerState={this.handleDrawerState} openState={this.state.openImport} refresh={this.refresh}></ImportEmployees>
          <AddHumanResource2 handleDrawerState={this.handleDrawerState} openState={this.state.openAdd} hrId={this.state.openEmployeeId} refresh={this.refresh} context='employee' teams={this.state.teams} positions={this.state.positions}/>
          {this.state.statusBar}
          </div>
    );
    
}}

Employees.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default connect(mapStateToProps, { setAdminDetails, setNavEmp })(withTranslation()(withCookies(withRouter((withStyles(styles))(Employees),Employees))))
