import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import LangPicker from '../../util/LangPicker';
import EgTimeValidityIndicator from '../../components/EgTimeValidityIndicator';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    insightBox:{
        border:'1px solid #E4E4E4',
        height:'35vh',
        overflow:'hidden'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    boxTitleInd:{
        paddingInline:20,
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    }
    
  });

class SingleReportSummaryTimeBox extends React.Component {
  
    constructor(props) {
        super(props);   
        this.moveToTimeAnalysis = this.moveToTimeAnalysis.bind(this)          
    }

    state = {
        report: this.props.report?this.props.report:{},
        traitsTimes: []
    };

    componentDidMount(){
        if (this.props.report && this.props.report.assessment){
            this.setState({report: this.props.report})
            this.getTraitsFromReport(this.props.report)
        }
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report !== this.state.report){
            this.setState({report:nextProps.report});
            this.setState({report: this.props.report})
            this.getTraitsFromReport(nextProps.report)
        }
        if(nextProps.severity && nextProps.severity !== this.state.severity){
            this.setState({severity: nextProps.severity})
        }
    }

    getTraitsFromReport(report){
        const assessmentScores = report.assessment.assessment_scores
        let traitsArr = assessmentScores.map(function(scr){
            if (scr.trait.is_display){
                const traitName =  LangPicker.current() === 'en'?scr.trait.name.slice(0,9):scr.trait.trait_localized[0].localized_name.slice(0,9)
                return ({name:traitName,trait_avg:parseFloat(scr.trait.avg_response_time/1000), avg_time: parseFloat((scr.trait_aggregated_time/1000)/scr.trait_total_questions).toFixed(2)})
            }
            else{
                return null
            }
        })
        traitsArr = this.bouncer(traitsArr)
        console.log(traitsArr)
        this.setState({traitsTimes:traitsArr })
        return traitsArr
    }
    bouncer(array) {
        return array.filter(function(e) {
            return e;
        });
    }
      
    moveToTimeAnalysis(){
        this.props.moveTo(5)
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        

        return (
            <Paper elevation={0} style={{ overflow:'hidden'}} className={classes.insightBox} >
                <a href='#0' onClick={this.moveToTimeAnalysis} className={classes.link}>
                <Grid container>
                    <Grid xs={8} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Reports - Single - Time')} </Typography>
                    </Grid>
                    <Grid xs={4} className={classes.boxTitleInd}>
                        <EgTimeValidityIndicator severity={this.state.severity} ></EgTimeValidityIndicator>
                    </Grid>
                </Grid>
                <Grid container style={{paddingTop:'2vh', paddingBottom:'2vh'}}>
                    <Grid xs={12} >
                        <Paper elevation={0} >
                            <Grid container>
                                
                                <Grid xs={12} style={{paddingInline:10, paddingTop:'2vh'}}>
                                    <BarChart
                                        direction='ltr'
                                        onClick={this.moveToTimeAnalysis}
                                        width={window.innerWidth/3}
                                        height={window.innerHeight/4}
                                        data={this.state.traitsTimes?this.state.traitsTimes:[]}
                                        >
                                        <XAxis dataKey="name" interval={0}  style={{fontSize:8, padding:10}} axisLine={false}/>
                                        <YAxis unit={' sec'} style={{fontSize:10, padding:10}} axisLine={false}/>
                                        <Tooltip wrapperStyle={{fontSize: "12px"}}  />
                                        <Legend wrapperStyle={{fontSize: "12px", top:'-2.5vh'}} verticalAlign={'top'} align={'right'} height={10}  iconSize={8} />
                                        <Bar name={t('Report - Time - Response Time')} dataKey="avg_time" fill="#0085AF" barSize={20} radius={3} />
                                        <Bar name={t('Report - Time - Avg. Response')} dataKey="trait_avg" fill="#C1A5FF" barSize={20} radius={3}/>
                                    </BarChart>
                                    
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    
                </Grid> 
                </a>    
            </Paper>
        );
    }
}
SingleReportSummaryTimeBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportSummaryTimeBox),SingleReportSummaryTimeBox);