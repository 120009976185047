import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Dialog, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import testIntro from '../../img/ready.svg';
import check from '../../img/check.svg';
import AppUtils from '../../util/AppUtils';
import AudioVideoChecker from '../settings/AudioVideoChecker';

const styles = theme => ({
    root: {
        display: 'flex',
        width:'100%'
    },
    logo:{
        width:'80%',
        paddingTop:5,
        paddingBottom:5, 
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'55vh',
        display: 'flex',
        alignItems:'center'
    },
    actionButton:{
        paddingLeft:10,
        paddingRight:10,
        fontSize:14,
        margin: 5
    },  
    actionButtonMini:{
        marginBottom:3,
        paddingTop:2,
        paddingBottom:2,
        fontSize: 10,
        marginInline:10
    },  
});


class RemoteInterviewReady extends React.Component {
  
    state = {
        open: this.props.open,
    };

    constructor(props) {
        super(props);  
        this.start = this.start.bind(this);  
        this.handleClose = this.handleClose.bind(this)
        this.testMedia = this.testMedia.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.screenAndVideoGoodToGo !== this.state.screenAndVideoGoodToGo) {
            this.setState({screenAndVideoGoodToGo: nextProps.screenAndVideoGoodToGo})
        }
        if (nextProps.requireVideo !== this.state.requireVideo) {
            this.setState({requireVideo: nextProps.requireVideo})
        }
    }

    start(){
        if (!this.state.requireVideo || this.state.screenAndVideoGoodToGo) {
            this.props.startRemote();
        } else {
            console.warn('Did not begin assesment due to issues with screen or camera')
        }   
    }

    handleClose(){
        this.setState({mediaTester:null})
    }

    testMedia(){
        const mediaTesterObj =  <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={true}>
                                    
                                    <AudioVideoChecker close={this.handleClose}/>
                                </Dialog>
        this.setState({mediaTester:mediaTesterObj})
    }

    
  
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        console.log(`this.props.skipAssessment = ${this.props.skipAssessment}`)
        const title = this.props.skipAssessment ? t('Assessment - Remote - Remote Intervie') : t('Assessment - Remote - Part 2')+ " - " +t('Assessment - Remote - Remote Intervie')
        return (

                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30}}>
                        <Grid item xs={AppUtils.isMobile()?12:7}>
                            <Grid container  justify="left" direction="row" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant={'h1'} >{title}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop:'2vh'}}>
                                    <Typography variant={'h4'} >{t('Assessment - Ready - Some tips before you start')}</Typography>
                                </Grid>
                                
                                <Grid xs={12} style={{paddingTop:'1vh'}}>
                                    <table>
                                        <tr>
                                            <td><img src={check}  alt="check"/> </td>
                                            <td style={{paddingInlineStart:15}}>
                                                <Typography variant={'h4'}>{t('Assessment - Ready - Make sure your video and mike are connected')}
                                                <EgButton secondary="true" outlined="true" variant="contained"  className={classes.actionButtonMini} onClick={this.testMedia} disabled={!this.state.screenAndVideoGoodToGo&&this.state.requireVideo}>
                                                    {t('Assessment - Screen - Check your camera and microphone')} 
                                                    </EgButton>
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><img src={check}  alt="check"/> </td>
                                            <td style={{paddingInlineStart:15}}><Typography variant={'h4'}>{t('Assessment - Ready - Make Sure you are in a quiet space')} </Typography></td>
                                        </tr>
                                        <tr>
                                            <td><img src={check}  alt="check"/> </td>
                                            <td style={{paddingInlineStart:15}}><Typography variant={'h4'}>{t('Assessment - Ready - Once you completed answering click next to move to the next question')} </Typography></td>
                                        </tr>
                                        <tr>
                                            <td><img src={check}  alt="check"/> </td>
                                            <td style={{paddingInlineStart:15}}><Typography variant={'h4'}>{t('Assessment - Ready - We recommend you turn off your phone')} </Typography></td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td style={{paddingInlineStart:15, paddingTop:'2vh'}}><Typography variant={'h3'} style={{fontWeight:500}}>{t('Assessment - Ready - Good Luck')} </Typography></td>
                                        </tr>
                                        
                                    </table>
                                </Grid>
                                <Grid item xs={12} justify="center" style={{display:"flex", paddingTop:'5vh', justifyContent:'start'}}>
                                    <EgButton variant="contained"  className={classes.actionButton}  onClick={this.start} disabled={!this.state.screenAndVideoGoodToGo&&this.state.requireVideo}>
                                        {t('Assessment - Screen - Start Remote Interview')}
                                    </EgButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={AppUtils.isMobile()?12:5} justify="center" style={{display:"flex", alignItems:"center", paddingTop:0}}>
                            <img src={testIntro} className={classes.logo} alt="testIntro" />  
                        </Grid>
                    {this.state.mediaTester}
                    </Grid>
               
        );
  }
}
RemoteInterviewReady.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(RemoteInterviewReady),RemoteInterviewReady);


