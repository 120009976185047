import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination } from '@material-ui/core';
import Moment from 'react-moment';
import _ from 'lodash';
import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EgHttp from '../../util/EgHttp';
import Skeleton from '@material-ui/lab/Skeleton'
import SkeletonTable from '../../components/SkeletonTable';
import EgEmptyState from '../../components/EgEmptyState';
import RowActionsHover from '../../components/RowActionsHover';
import AppUtils from '../../util/AppUtils';


const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      width:'100%',
      minHeight:'73vh',
      overflow:'auto'
    },
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:"#272931",
        border:0,
        paddingLeft:10,
        paddingRight:10,
        textAlign:'start',
        borderRight:'1px solid #F0F0F0',
        overflowWrap:'anywhere'
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'1px solid #F0F0F0',
        
    },
    tableHeadCell:{
        paddingTop:5,
        paddingBottom:5,
        fontSize: 12,
        fontWeight:400,
        color:"#5F6066",
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0'
    },
    tableRowInner:{
        minHeight:"6.5vh",
        maxHeight:"6.5vh",
        height:"6.5vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
});
 
class TeamTable extends React.Component {
  
    state = {
        open: this.props.open,
        operationIcon: null,
        isEmpty: false,
        overlayDiv: [],
        rows: this.props.rows,
        moreMenuOpen: [],
        assignTestOpen: false,
        loading:true,
        searchTerm:this.props.searchTerm,
        rowsPerPage:10,
        page:0,
        order:'desc',
        orderBy:'created_at'
    };

    constructor(props) {
        super(props);  
        this.closeAssignTest = this.closeAssignTest.bind(this);  
        this.refresh = this.refresh.bind(this);     
        this.confirmDelete = this.confirmDelete.bind(this)  
        this.closeConfirm = this.closeConfirm.bind(this) 
        this.deleteTeam = this.deleteTeam.bind(this)
        this.editTeam = this.editTeam.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.openTeamCard = this.openTeamCard.bind(this)
        

    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.rows!==this.state.rows ){             
            this.setState({rows:nextProps.rows});           
        }
        if (nextProps.loading!==this.state.loading ){             
            this.setState({loading:nextProps.loading});           
        }
        if (nextProps.searchTerm!==this.state.searchTerm ){             
            this.setState({searchTerm:nextProps.searchTerm});           
        } 
    }

    openTeamCard(index){
        this.props.openTeam(this.state.rows[index].id)
    }
    closeAssignTest(){
        this.setState({assignTestOpen:false});
        this.setState({assignDialog:null});
    }
    closeConfirm(){
        this.setState({confirmDeleteDialog:null});
    }

    // Open employee form in edit mode
    editTeam(index){
        this.props.editTeam(this.state.rows[index].id)
    }

    refresh(code, msg){
        this.props.refresh(code, msg);
    }

    confirmDelete(index){
        const { t } = this.props; 
        this.setState({deleteIndex:index})
        this.setState({confirmDeleteDialog: <ConfirmationDialog title={t("Teams - Table - Delete Team")} message={t("Teams - Table - Are you sure")}  confirm={this.deleteTeam} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }

    // Delete Employees
    async deleteTeam(){
        const { t } = this.props;  
        this.setState({confirmDeleteDialog:null}); 
        const id = this.state.rows[this.state.deleteIndex].id
        let response = await EgHttp.delete('/teams/' + id) 
        if (response.status===200){
            _.remove(this.state.rows, {
                id: id
            });
        }
        this.setState({overlayDiv:[]})
        this.props.refresh("SUCCESS", t('Teams - Table - Team was deleted successfully'));
        EventLog.insert(this.props.admin.org.id, id, EventLog.DELETE_TEAM, this.props.admin.account.id)
        return;   
    }

    setHover(e, onOff, index){
        const { t } = this.props;
        let item, items = []
        const submenuActions=[  {text:t('Teams - Add - Edit Team'), action:this.editTeam, disabled:false},
                                {text:t('Teams - Table - Delete Team'), action:this.confirmDelete, disabled:false},
                             ]
        if (onOff){ 
            item = <RowActionsHover mainActionName={t('Teams - Table - Team Card')} mainAction={this.openTeamCard} rowIndex={index} submenuActions={submenuActions} rowsPerPage={this.state.rowsPerPage} page={this.state.page}/>
        }
        else{
            item = null;
            this.setState({moreMenuOpen:[]});
        }
        items[index] = item;
        this.setState({overlayDiv:items});
    }
    
    handleChangePage(event, newPage){
        this.setState({page:newPage})
    }
    handleChangeRowsPerPage(event){
        this.setState({rowsPerPage: parseInt(event.target.value, 10)})
    }
    createSortHandler = property => event => {
        this.onRequestSort(event, property);
    };
    onRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }    
        this.setState({ order, orderBy });       
    };  
    findParentTeam(teamid){
        if (!teamid) return null
        const allTeams = this.state.rows;
        const ind = allTeams.findIndex(({ id }) => id === teamid )
        if (ind>-1){
            return allTeams[ind].name
        }
        else return null;
    }
    
    
    render() {
        
        const { classes } = this.props;
        const { t } = this.props;
        return (
        <div style={{position:'relative'}}>
        <Grid container spacing={3} style={{}}>
            <Grid item xs={12 } style={{height:'100%'}}>
                {this.state.loading || (this.state.rows && this.state.rows.length)?
                <div>
                <Paper className={classes.paper} style={{height:'75vh'}}>
                <Table className={classes.table} aria-label="simple table" >
                    <TableHead>
                        <TableRow> 
                            <TableCell key={'name'} sortDirection={this.state.orderBy === 'name' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'20%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('name')} active={this.state.orderBy === 'name'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Teams - Table - Name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'created_at'} sortDirection={this.state.orderBy === 'created_at' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'15%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('created_at')} active={this.state.orderBy === 'created_at'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Teams - Table - Added')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'position'} className={classes.tableHeadCell} align="start" style={{width:'25%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('leader')} active={this.state.orderBy === 'leader'}>    
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Teams - Table - Leader')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'email'} className={classes.tableHeadCell} align="start" style={{width:'20%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('parent')} active={this.state.orderBy === 'parent'}>    
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Teams - Table - Parent Team')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} style={{width:'20%'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody >
                    {
                    this.state.loading?
                    <TableRow key={'skeleton1'} className={classes.tableRowBody} >
                        <TableCell className={classes.tableInnerCell} colSpan={5}>
                            <SkeletonTable style={{width:'100%'}}></SkeletonTable>
                        </TableCell>
                    </TableRow>
                    :
                    _.slice(this.state.rows.sort(AppUtils.getSorting(this.state.order, this.state.orderBy)),this.state.page*this.state.rowsPerPage, (this.state.page+1)*this.state.rowsPerPage).map((row, index) => (
                        
                        <TableRow key={row.id} className={classes.tableRowBody} >
                            <TableCell className={classes.tableInnerCell} colSpan={5} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                <div>
                                    <Table className={classes.tableInner} aria-label="inner table" >
                                    <TableBody >
                                        <TableRow key={row.name + "in"} className={classes.tableRowInner} >
                                            
                                            <TableCell className={classes.tableCell} style={{width:'20%'}}>{row.name}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'15%'}}><Moment format="MMM DD, YYYY">{row.created_at}</Moment></TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'25%'}}>{row.leader_id?(row.account.firstname + " " + row.account.lastname):''}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'20%'}}>{this.findParentTeam(row.parent_team_id)}</TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'20%',borderRight:0}}></TableCell>
                                        </TableRow>
                                    </TableBody >
                                    </Table>
                                   
                                </div>
                                {this.state.overlayDiv[index]}
                                {this.state.moreMenuOpen[index]}   
                            </TableCell>
                        </TableRow>   
                    ))}
                    </TableBody>
                </Table>
                
                </Paper>
                <TablePagination component="div"
                                count={this.state.rows?this.state.rows.length:0}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                labelRowsPerPage={t('General - Rows per page')} 
                                >
                </TablePagination>
                </div>
                :
                <EgEmptyState title={t('Teams - Table - No Teams Found')} emptyAction={this.props.addNewTeam} emptyActionTxt={t('Teams - Add - Add New Team')} />  
                }
            </Grid>  
        </Grid>
        {this.state.confirmDeleteDialog}
        {this.state.assignDialog}
        </div>      
      
        );
    }
}
TeamTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(TeamTable),TeamTable)))