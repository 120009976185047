import i18n from '../i18n/i18n'
import EgHttp from './EgHttp';


const EventLog = {
        
    insert: function(orgId, candidateId, eventId, accountId,jwtc){
        EgHttp.post( '/events' ,JSON.stringify({
            orgId: orgId,
            candidateId: candidateId,
            eventId: eventId,
            accountId: accountId }),jwtc)
        return;
        
    },
    insertAcc: function(orgId, candidateId, eventId, accountId){
        EgHttp.post( '/events/acc' ,JSON.stringify({
            orgId: orgId,
            candidateId: candidateId,
            eventId: eventId,
            accountId: accountId }))
        return; 
    },
    getMessages: function(index){
        return (
            [
                '',
                i18n.t('EventLog - New candidate added'),
                i18n.t('EventLog - Candidate details updated'),
                i18n.t('EventLog - Candidate was deleted'),
                i18n.t('EventLog - Assessment assigned to candidiate'),
                i18n.t('EventLog - Assessment unassigned'),
                i18n.t('EventLog - Started Assessment'),
                i18n.t('EventLog - Completed Assessment'),
                i18n.t('EventLog - Package Added'),
                i18n.t('EventLog - Bulk candidates added'),
                i18n.t('EventLog - New emlpoyee added'),
                i18n.t('EventLog - Employee details updated'),
                i18n.t('EventLog - Employee was deleted'),
                i18n.t('EventLog - Candidae saved as employee'),
                i18n.t('EventLog - New team added'),
                i18n.t('EventLog - Team details updated'),
                i18n.t('EventLog - Team was deleted'),
                i18n.t('EventLog - Campaign was deleted'),
                i18n.t('EventLog - Questionnaier was deleted'),
                i18n.t('EventLog - Questionnaier was added'),
            ]
        )
    },

    INSERT_CANDIDATE:1,
    EDIT_CANDIDATE:2,
    DELETE_CANDIDATE: 3,
    ASSESSMENT_ASSIGNED: 4,
    ASSESSMENT_UNASSIGNED: 5,
    START_ASSESSMENT: 6,
    END_ASSESSMENT: 7,
    PACKAGE_ADDED: 8,
    INSERT_BULK_CANDIDATES: 9,
    INSERT_EMPLOYEE:10,
    EDIT_EMPLOYEE:11,
    DELETE_EMPLOYEE: 12,
    SAVE_AS_EMPLOYEE: 13,
    INSERT_TEAM:14,
    EDIT_TEAM:15,
    DELETE_TEAM:16,
    DELETE_CAMPAIGN:17,
    DELETE_QUESTIONNAIRE:18,
    NEW_QUESTIONNAIRE:19,

}
export default EventLog;