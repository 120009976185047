import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from '../components/EgButton';
import ReportTemplateEditor from './ReportTemplateEditor';
import ManualPayment from './ManualPayment';

 
const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    

    formRow:{
        padding:10
    },
    actionButton:{
        width:200,
        marginLeft:5,
        marginRight:5
    },
    
    
  });
  
class BackofficeHome extends React.Component {
  
    constructor(props) {
        super(props);           
        this.openTemplateEditor = this.openTemplateEditor.bind(this);  
        this.openManualPayment = this.openManualPayment.bind(this);  
        this.close = this.close.bind(this)
    }

    state = {
        page:null
    };

    componentDidMount(){

    }

    openTemplateEditor(){
        try{
            this.setState({page:'templateEditor'})
        }
        catch(err){
            console.error(err)
        }
    }
    openManualPayment(){
        try{
            this.setState({page:'payment'})
        }
        catch(err){
            console.error(err)
        }
    }
    close(){
        this.setState({page:null})
    }

    
    render() {
       
        const { classes } = this.props;
        return (
            <div>
                <Paper style={{height:'90vh', width:'100vw'}} elevation={0}>
                    {this.state.page === 'templateEditor'?
                        <ReportTemplateEditor login={this.props.login} setLogin={this.props.setLogin} close={this.close}/>
                    :this.state.page === 'payment'?
                        <ManualPayment close={this.close}></ManualPayment>
                    :
                        <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                                <Grid item xs={12} justify="left">
                                    <Typography variant={'h1'} style={{paddingBottom:20}}>Engard Backoffice</Typography>
                                    <Divider></Divider>
                                </Grid>
                                <Grid item xs={3} className={classes.formRow} style={{paddingTop:30}}>
                                    <Typography variant={'h3'}>Report Template Editor</Typography>
                                </Grid>
                                <Grid item xs={9} className={classes.formRow} style={{paddingTop:20, textAlign:'start'}}>
                                    <EgButton  onClick={this.openTemplateEditor} className={classes.actionButton}>Open Editor</EgButton>
                                </Grid>
                                <Grid item xs={3} className={classes.formRow} style={{paddingTop:30}}>
                                    <Typography variant={'h3'}>Manual Credit Card Payment</Typography>
                                </Grid>
                                <Grid item xs={9} className={classes.formRow} style={{paddingTop:20, textAlign:'start'}}>
                                    <EgButton  onClick={this.openManualPayment} className={classes.actionButton}>Open Payment Form</EgButton>
                                </Grid> 
                        </Grid>
                    
                }
                </Paper>
               
        </div>     
      
    );
  }
}
BackofficeHome.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(BackofficeHome),BackofficeHome)))