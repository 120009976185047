import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
//import EventLog from '../../util/EventLog'; 
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, increment } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EgHttp from '../../util/EgHttp';
import RoleManager from '../../managers/RoleManager';
import RowActionsHover from '../../components/RowActionsHover';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'start',
      color: theme.palette.text.secondary,
      width:'100%',
      minHeight:'60vh',
      overflow:'auto',
      maxHeight:'60vh'
    },
    
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:"#272931",
        border:0,
        paddingLeft:10,
        paddingRight:10,
        textAlign:'start',
        borderRight:'1px solid #F0F0F0',
        overflowWrap:'anywhere'
        
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'1px solid #F0F0F0',
        
    },
    tableHeadCell:{
        paddingTop:5,
        paddingBottom:5,
        fontSize: 12,
        fontWeight:400,
        color:"#5F6066",
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0'
    },
    tableRowInner:{
        minHeight:"6.5vh",
        maxHeight:"6.5vh",
        height:"6.5vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
  });

  
class AccountsTable extends React.Component {
  
    state = {
        open: this.props.open,
        operationIcon: null,
        isEmpty: false,
        rows: this.props.rows,
        overlayDiv: [],
        moreMenuOpen: [],
    };

    constructor(props) {
        super(props);  
        this.confirmDelete = this.confirmDelete.bind(this)  
        this.closeConfirm = this.closeConfirm.bind(this) 
        this.deleteAccount = this.deleteAccount.bind(this)  
        this.editAccount = this.editAccount.bind(this)   
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.rows!==this.state.rows ){             
            this.setState({rows:nextProps.rows});           
        }
        if (nextProps.loading!==this.state.loading ){             
            this.setState({loading:nextProps.loading});           
        }
    }

    confirmDelete(index){
        const { t } = this.props; 
        this.setState({deleteIndex:index})
        this.setState({confirmDeleteDialog: <ConfirmationDialog title={t("Settings - Accounts - Delete Account")} message={t("Settings - Account - Are you sure")}  confirm={this.deleteAccount} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }

    closeConfirm(){
        this.setState({confirmDeleteDialog:null});
    }

    // Delete Account
    async deleteAccount(){
        const { t } = this.props;   
        this.setState({confirmDeleteDialog:null});
        const id = this.state.rows[this.state.deleteIndex].id
        let response = await EgHttp.delete('/accounts/' + id )
        if (response.status===200){
            _.remove(this.state.rows, {
                id: id
            });
        }
        this.setState({overlayDiv:[]})
        this.props.refresh("SUCCESS", t('Settings - Accounts - Account was deleted successfully'));
        //EventLog.insert(3, id, EventLog.DELETE_CANDIDATE, 0)
        return;   
    }
    // Open candidate form in edit mode
    editAccount(index){
        console.log(index)
        this.props.openEditAccount(this.state.rows[index].id)
    }

   
setHover(e, onOff, index){
    const { t } = this.props;
    console.log(index)
    let item, items = []
    const submenuActions=[  {text:t('Settings - Accounts - Edit Account'), action:this.editAccount, disabled:false},
                            {text:t('Settings - Accounts - Delete Account'), action:this.confirmDelete, disabled:false},
                            ]
    if (onOff){ 
        item = <RowActionsHover mainActionName={t(null)} mainAction={null} rowIndex={index} submenuActions={submenuActions} rowsPerPage={500} page={0}/>
    }
    else{
        item = null;
        this.setState({moreMenuOpen:[]});
    }
    items[index] = item;
    this.setState({overlayDiv:items});
}
  
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
        <div style={{}}>

        <Grid container spacing={3} style={{}}>
            
            <Grid item xs={12 } style={{height:'100%'}}>
                
                <Paper className={classes.paper} elevation={0}>
                      
                <Table className={classes.table} aria-label="simple table" >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell} align="start" style={{width:'20%'}} >{t('Settings - Accounts - Name')}</TableCell>
                            <TableCell className={classes.tableHeadCell} align="start" style={{width:'30%'}} >{t('Settings - Accounts - Email')}</TableCell>
                            <TableCell className={classes.tableHeadCell} align="start" style={{width:'20%'}}>{t('Settings - Accounts - Added')}</TableCell>
                            <TableCell className={classes.tableHeadCell} align="start" style={{width:'30%'}}>{t('Settings - Accounts - Role')}</TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody >
                    {this.state.rows?this.state.rows.map((row, index) => (
                       
                        <TableRow key={row.name} className={classes.tableRowBody} >
                            <TableCell className={classes.tableInnerCell} colspan={5} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                <div>
                                    <Table className={classes.tableInner} aria-label="inner table" >
                                        <TableRow key={row.name + "in"} className={classes.tableRowInner} >
                                            <TableCell className={classes.tableCell} style={{width:'20%'}}> 
                                                {row.firstname+ " " + row.lastname}
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'30%'}}> {row.email}</TableCell>
                                            <TableCell className={classes.tableCell} align="start" style={{width:'20%'}}><Moment format="MMM DD, YYYY">{row.created_at}</Moment></TableCell>
                                            <TableCell className={classes.tableCell} align="start" style={{width:'30%'}}>{!row.role?'Admin':RoleManager.getText(row.role)}</TableCell>
                                        </TableRow>
                                    </Table>
                                </div>
                                {this.state.overlayDiv[index]}
                                {this.state.moreMenuOpen[index]}    
                            </TableCell>
                        </TableRow> 
                    )):
                    <div></div>}
                    </TableBody>
                </Table>
                </Paper>
            </Grid> 
        </Grid>
        {this.state.confirmDeleteDialog}
        </div>  
    );
  }
}
AccountsTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails, increment })(withTranslation()(withRouter((withStyles(styles))(AccountsTable),AccountsTable)))