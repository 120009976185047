import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {  Paper, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import LangPicker from '../../util/LangPicker';
import AppUtils from '../../util/AppUtils';
import EgHttp from '../../util/EgHttp';
import RemoteInterviewReady from './RemoteInterviewReady';
import EgActivityRecorder from '../../util/EgActivityRecorder';
import EgWaitDialog from '../../components/EgWaitDialog';

const styles = theme => ({
    root: {
        display: 'flex',
        width:'100%'
    },  
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'50vh',
    },
    error:{
        fontSize:16,
        color:"#F15D5D",
        fontWeight: 500,
        textAlign:'center'
    }, 
});

 
class RemoteInterviewPanel extends React.Component {
    state = {
        candidate: this.props.candidate,
        requireVideo: false,
        cameraVideoTrack: null,
        recording: false,
        questionNumber: 0,
        curr_response: null,
        assessment_id: 0,
        questionnaireId: 0,
        questionnaire: null,
        interview: null,
        startTime: null
    };

    constructor(props) {
        super(props);  
        this.startVideoPlayback = this.startVideoPlayback.bind(this)
        this.startInterview = this.startInterview.bind(this)
        this.nextQuestion = this.nextQuestion.bind(this)
        this.handleNextButton = this.handleNextButton.bind(this)

        if (props.requireVideo) {
            this.setState({requireVideo: true})
        }
    }

    startVideoPlayback(cameraVideoTrack) {
        console.log('Start video playback in questionnaire')
        cameraVideoTrack.play("local-player");
    }

    componentDidMount() {
        if (this.props.requireVideo) {
            this.startVideoPlayback(this.props.cameraVideoTrack)
        }
        if (this.props.questionnaireId) {
            console.log(`Questionnaire ID available at Mount: ${this.props.questionnaireId}`)
            this.setState({questionnaireId:this.props.questionnaireId});
            this.getQuestionnaire(this.props.questionnaireId)
        }
    }

    componentWillReceiveProps(nextProps) {   
       
        if (nextProps.questionnaireId && nextProps.questionnaireId!==this.state.questionnaireId ) {  
            console.log(`Got questionnaire id: ${nextProps.questionnaireId}. IGNORING, WAS SUPPOSED TO LOAD QUSTIONNAIRE at didMount`)
            // this.setState({questionnaireId:nextProps.questionnaireId});
            // this.getQuestionnaire(nextProps.questionnaireId)
        }  
        if  (nextProps.assessmentState && nextProps.assessmentState!==this.state.assessmentState ) {
            console.log(`Got assessmentState`)
            console.log(JSON.stringify(nextProps.assessmentState))
            this.setState({assessmentState: nextProps.assessmentState})
            if (this.state.questionnaireId !== 0) {
                this.createNewInterview(this.state.questionnaireId, nextProps.assessmentState.id)
            }
        } 
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});
        }
        if (nextProps.cameraVideoTrack !== this.state.cameraVideoTrack) {
            console.log('Got Fresh camera video track, start playing video on UI.')
            this.startVideoPlayback(nextProps.cameraVideoTrack)
            this.setState({cameraVideoTrack: nextProps.cameraVideoTrack})
        }
    }

    async createNewInterview(qnnrId, ass_id, hr_id) {
        console.log(`fetching existing interview of assessment ${ass_id}`)
        let getResp = await EgHttp.get(`/remoteinterviews/interview/ass_id/${ass_id}`, true)
        let rowsres = await getResp.json();
        if (rowsres) {
            const numberOfInterviews = rowsres.length
            console.log(`Got ${numberOfInterviews} interviews for assessment ${ass_id}`)
            let interview = null;
            let interviewId = 0;
            if (numberOfInterviews > 0) {
                interview = rowsres[rowsres.length - 1]
                interviewId = interview.id
            } else {
                console.log(`Creating INTERVIEW for qnnr ${qnnrId} and asssmnt ${ass_id}`)
                const iwBody = {
                    hr_id: hr_id,
                    questionnaire_id: qnnrId,
                    assessment_id: ass_id,
                    notes: 'Automatically created after assessment.'
                }
                let response = await EgHttp.post('/remoteinterviews/interview', iwBody, true)
                interview = await response.json()
                interviewId = interview.id
            }
            console.log(`Using interview with id ${interviewId} for hr ${hr_id} ass_id ${ass_id}`)
            this.setState({interview: interview})
            // Create the response object for the first question
            const firstQuestion = this.state.questionnaire.questions[0];
            await this.createNewResponse(firstQuestion.id, interviewId)
        } else {
            console.error('Something went wrong fetching hr interviews')
        }
    }

    async createNewResponse(questionId, interviewId) {
        console.log(`Fetching existing response for question ${questionId} in interview ${interviewId}`)
        let response = await EgHttp.get(`/remoteinterviews/response/${interviewId}/${questionId}`, true)
        let respObj
        try {
            respObj = await response.json()
        }
        catch (err) {
            console.log('json() failed. Not sure why, defaulting to null')
            respObj = null
        }
         
        if (respObj) {
            console.log('Saving EXISTING response to state current response')
            console.log(JSON.stringify(respObj))
        } else {
            console.log(`Creating response for question ${questionId} in interview ${interviewId}`)
            const respBody = {
                question_id: questionId,
                interview_id: interviewId
            }
            console.log(JSON.stringify(respBody))
            try {
                response = await EgHttp.post('/remoteinterviews/response', JSON.stringify(respBody), true)
                respObj = await response.json()
                console.log('Saving NEW response to state current response')
                console.log(JSON.stringify(respObj))
            }
            catch (err) {
                console.error('Failure creating a new response')
                console.log(err)
                respObj = null // even-though it was already null...
            }
        }
        this.setState({curr_response: respObj})
        return respObj
    }

    async getQuestionnaire(qid) {
        const { t } = this.props;
        try {
            let response, rowsres
            if (qid === -1 || qid === '-1'){ 
                response = await EgHttp.get('/candidates/getfollowup/' + this.state.candidate.test_id + '/' + LangPicker.current(), true);
                rowsres = await response.json();
                let qtexts, questionnaire
                if (rowsres && rowsres.length){
                    qtexts = rowsres.map(function(qt){
                        if (LangPicker.current() === 'en'){
                            return {id:null, text:t("Assessment - Remote - In the question") + ": " + qt.question.text+ " " + t("Assessment - Remote - you answered") + ": "  + " " +qt.question.answer.answer_list[qt.selected_answers[0]-1].text + ". "+ qt.question.followup[0]}
                        }
                        else{
                            return {id:null, text:t("Assessment - Remote - In the question") + ": " + qt.question.question_localized[0].text+ " " + t("Assessment - Remote - you answered") + ": "  + " " +qt.question.answer_localized[0].answer_list[qt.selected_answers[0]-1].text + ". "+ qt.question.question_localized[0].followup[0]}
                        }
                    })
                }
                else{
                    this.props.complete()
                    return
                }
                questionnaire = {questions:qtexts, id:qid}
                this.setState({ questionnaire: questionnaire, loading: null });
                await this.createQuestionsForFollowup(questionnaire)
                await this.createNewInterview(qid, this.state.candidate.test_id, this.state.candidate.hr_id)
            }
            else{
                response = await EgHttp.get('/remoteinterviews/questionnaire/' + qid, true)
                rowsres = await response.json();
                this.setState({ questionnaire: rowsres, loading: null });
                await this.createNewInterview(qid, this.state.candidate.test_id, this.state.candidate.hr_id)
            }
            return;
        }
        catch (err) {
            console.error(`Failure getting questionnaire with id ${qid}`)
            console.info(err)
        }
    }

    async createQuestionsForFollowup(questionnaie){ 
        const { t } = this.props;
        let body = JSON.stringify({
            questions: questionnaie.questions
        })
        let response, rowsres;
        response = await EgHttp.post('/remoteinterviews/questions',body, true)
        rowsres = await response.json();
        questionnaie.questions = rowsres
        this.setState({ questionnaire: questionnaie})
        return;
    }

    startInterview(){
        if (!this.state.questionnaire.questions || !this.state.questionnaire.questions.length){
            this.props.complete()
            return
        }
        const firstQuestion = this.state.questionnaire.questions[0]
        const interview = this.state.interview
        EgActivityRecorder.updateContext(EgActivityRecorder.contexts.INTERVIEW_QUESTIONS, this.state.interview.id)
        console.log(`START INTERVIEW - begin recording first question ${JSON.stringify(firstQuestion)} of interview ${JSON.stringify(interview)} now = ${new Date()}`)
        this.props.recordQuestionVideo(firstQuestion.id, interview.id)
        this.setState({questionNumber:1, question:firstQuestion})
        this.setState({startTime: new Date()})
        this.setState({isWaitingDialog:<EgWaitDialog open={true} />})
        setTimeout(()=>{this.setState({isWaitingDialog:null})},2000)
    }

    handleNextButton(event) {
        EgActivityRecorder.handleClickOrTap(event)
        this.nextQuestion()
    }

    async nextQuestion() {
        this.setState({isWaitingDialog:<EgWaitDialog open={true} />})
        setTimeout(()=>{this.setState({isWaitingDialog:null})},4000)

        console.log('NEXT QUESTION')
        console.log('STOP RECORDING')
        await this.props.stopRecording(this.state.curr_response.id)
        console.log('AFTER STOP RECORDING')
        const endTime = new Date()
        const timing = endTime - this.state.startTime
        this.setState({startTime: new Date()})
        console.log(`UPDATE RESPONSE - now = ${endTime} - total time ${timing}`)
        const respBody = { response_time: timing, response_id: this.state.curr_response.id}
        try {
            const updatedRes = await EgHttp.put('/remoteinterviews/response', JSON.stringify(respBody), true)
            console.log(`response updated: ${JSON.stringify(updatedRes)}`)
        }
        catch(err) {
            console.error(`Failure updating response ${JSON.stringify(this.state.curr_response)}`)
            console.log(err)
        }
        

        if (this.state.questionNumber < this.state.questionnaire.questions.length ){
            const nextQuestion = this.state.questionnaire.questions[this.state.questionNumber]
            this.setState({questionNumber:this.state.questionNumber+1, question:nextQuestion})

            console.log('Create new response')
            await this.createNewResponse(nextQuestion.id, this.state.interview.id)
            
            console.log(`START RECORDING question ${JSON.stringify(nextQuestion)}`)
            await this.props.recordQuestionVideo(nextQuestion.id, this.state.interview.id)
        }
        else{
            EgActivityRecorder.updateContext(null, -1)
            console.log('FINISHING REMOTE INTEVIEW /_`')
            this.props.complete()
        }
    }
    
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        
        return (
            <Paper className={classes.paper}>
                {this.state.questionNumber>0 ?
                <table style={{ width:'100%'}}>
                    <tr>
                        <td style={{verticalAlign:'top'}}>
                            <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:10}}>
                                    <Grid item xs={12} justify="left" style={{ borderBottom: '1px solid #E4E4E4', position: 'relative' }}>
                                        <Typography variant={'h2'} style={{ paddingBottom: 10 }}>{t('Assessment - Questions - Question')} {this.state.questionNumber}</Typography>
                                            {this.props.requireVideo && !AppUtils.isMobile()?
                                                LangPicker.direction() === 'rtl'?
                                                    <div id="local-player" style={{ textAlign: 'end', position: 'absolute', top: '-8vh', left: -200, width: '16vw', height: '19vh', display: 'flex', flexDirection: 'column', flex: 1 , border:'5px solid #ffffff', borderRadius:5}}>
                                                    </div>:
                                                    <div id="local-player" style={{ textAlign: 'end', position: 'absolute', top: '-8vh', right: -200, width: '16vw', height: '19vh', display: 'flex', flexDirection: 'column', flex: 1 , border:'5px solid #ffffff', borderRadius:5}}>
                                                    </div>
                                            :''}
                                    </Grid>

                                    <Grid item xs={AppUtils.isMobile()?0:2}></Grid>
                                    <Grid item xs={AppUtils.isMobile()?12:8} style={{paddingBottom:'0vh', paddingTop:'3vh'}}>
                                    <Grid container  justify="left" direction="row">
                                        <Grid item xs={12} style={{paddingBottom:'10vh', paddingTop:'10vh'}}>
                                            <Typography variant={'h3'} style={{lineHeight:1.8}}>{this.state.question?this.state.question.text:''}</Typography>  
                                        </Grid>  
                                    </Grid>
                                </Grid>
                                <Grid item xs={AppUtils.isMobile()?0:2} ></Grid>
                            </Grid>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:10}}>
                                <Grid item xs={12} justify="center">
                                    {this.state.errorMsg}
                                </Grid>
                                
                                <Grid item xs={12} style={{display:"flex", justifyContent:'center', paddingTop:8}}>
                                    <EgButton 
                                        onClick={this.handleNextButton}
                                        clickdata={JSON.stringify({question_id: this.state.questionnaire.questions[this.state.questionNumber], response_id: this.state.curr_response})}
                                        onMouseEnter={EgActivityRecorder.handleMouseEnter}
                                        mouseenterdata={JSON.stringify({question_id: this.state.questionnaire.questions[this.state.questionNumber], response_id: this.state.curr_response})}
                                        onMouseLeve={EgActivityRecorder.handleMouseLeave}
                                    >{t('Assessment - Remote - Next Question')} </EgButton>
                                </Grid>
                               
                            </Grid>
                        </td>
                    </tr>
                </table>
                :
                <RemoteInterviewReady startRemote={this.startInterview} skipAssessment={this.props.skipAssessment}></RemoteInterviewReady>
            }
            {this.state.isWaitingDialog}
            </Paper> 
        );
    }
}
RemoteInterviewPanel.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(RemoteInterviewPanel),RemoteInterviewPanel);


