import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles  } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Topbar from '../components/Topbar'
import { Grid, Typography, Paper, Tabs, Tab } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PackagesPage from './settings/PackagesPage';
import { connect } from "react-redux";
import { setAdminDetails } from "../redux/admin";
import ProfilePage from './settings/ProfilePage'
import AccountsPage from './settings/AccountsPage';
import EgProgress from '../components/EgProgress';
import { withTranslation } from 'react-i18next';
import * as AppConstants from '../util/constants/AppConstants';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import NavigationManager from '../managers/NavigationManager';
import ExtendedNavBar from '../components/ExtendedNavBar';
import qs from 'qs';
import RoleManager from '../managers/RoleManager';
import ConfigurePage from './settings/ConfigurePage';

const mapStateToProps = state => ({
    admin: state.admin.value
  });
  
const drawerWidth = AppConstants.NavigationDrawerWidth;

const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%'
    },
  
    appRight:{
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        background:"#FAFAFA",
      },
      marginRight: drawerWidth
    },
    appLeft:{
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        background:"#FAFAFA",
      },
      marginLeft: drawerWidth
    },
 
    //toolbar: //theme.mixins.toolbar,
    toolbar:{
      minHeigt:64,
      marginTop:50
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width:'100%',
      height:window.innerHeight,
    },
    
});

const AntTabs = withStyles((theme) => ({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
  }))(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontSize:16,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

class Settings extends React.Component {
    state = {
      value:this.props.state?this.props.state:0,
      eventLog:[],
      loading: null,//<EgProgress />
    };

    constructor(props) {
      super(props);  
      this.logout = this.logout.bind(this);   
      this.handleChange = this.handleChange.bind(this)
      this.setProgress = this.setProgress.bind(this)
      this.search = this.search.bind(this)

      if (!this.props.login.logged) {
          this.props.history.push('/signup') 
      } 
      else{
        this.setState({login:this.props.login})
        NavigationManager.setLast(this.props, '/settings')
      }
    } 

    componentDidMount(){
        const tab=qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).tab
        if (tab ){
          this.setState({value: parseInt(tab)});
        }
        console.log(this.props.admin)
    }

    setProgress(val){
      if(val){
        this.setState({loading:<EgProgress />})
      }
      else this.setState({loading:null})
    }
    
    logout(){
      this.props.history.push('/signin') 
    }
    
    handleChange(event, val){
        this.setState({value:val})
        this.props.history.push('/settings?tab='+val)
    }

    search(term){
      this.setState({searchTerm:term})
    }
    
    render() {
        const { t, i18n } = this.props;
        const { classes } = this.props;
        
    return (
        <div className={classes.root} >
        <CssBaseline />
        
       
        <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} style={{boxShadow:"0px 0px 0px" }}>
            {this.state.loading}
            <Toolbar>
                <Topbar logout={this.logout} login={this.props.login} page={this.props.page} search={this.search}></Topbar>    
            </Toolbar>   
        </AppBar>
       
        <ExtendedNavBar navPos={6}></ExtendedNavBar>

        <main className={classes.content}>
            
            <div className={classes.toolbar} />
            
            <Grid container fullwidth='true' justify="center" direction="row" alignItems="flex-start">
            
            <Grid item xs={12}>
            
              <Grid container  justify="left" direction="row" alignItems="flex-start">
                <Grid item xs={12} justify="left">
                    <Typography variant="h1"> {t('Settings - Settings')}  </Typography>
                </Grid>
              
                 <Grid xs={12} style={{paddingTop:20}}>
                 
                    <Paper square style={{height:'75vh', borderRadius:4}}>
                        <AntTabs
                            value={this.state.value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleChange}
                            aria-label="settings"
                            style={{fontSize:24}}
                        >
                            <AntTab label={t('Settings - Assessments')}  style={{padding:15}} />
                            <AntTab label={t('Settings - Profile')}  />
                            {this.props.admin.account && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                            <AntTab label={t('Settings - Accounts')}  className={classes.tabNav} style={{}} />:''}
                             {this.props.admin.account && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                            <AntTab label={t('Settings - Configure')}  className={classes.tabNav} style={{}} />:''}
                           
                        </AntTabs>
                        {(this.state.value===0)?
                        <PackagesPage setProg={this.setProgress}></PackagesPage>
                        :
                        (this.state.value===1)?
                        <ProfilePage setProg={this.setProgress}></ProfilePage>
                        :(this.state.value===2)?
                        <AccountsPage setProg={this.setProgress} searchTerm={this.state.searchTerm}></AccountsPage>
                        ://(this.state.value===3)
                        <ConfigurePage setProg={this.setProgress}></ConfigurePage>
                        }
                    </Paper>
                 </Grid>
              </Grid>
            </Grid>
            
            </Grid>
        </main>
        </div>
    );
    
}}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withCookies(withRouter((withStyles(styles))(Settings),Settings))))