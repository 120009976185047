import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
//import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import LangPicker from '../../util/LangPicker';
//import RoleManager from '../../managers/RoleManager';
import TraitRangeSlider from './TraitRangeSlider';
import CloseOutlinedIcon from '@material-ui/icons//CloseOutlined';


const availAnswers = {anslist:['Very Low, Low, Med, High, Very High ']}
    
const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width:100,
        marginLeft:5,
        marginRight:5
    },
    errorText:{
        color: theme.palette.error.main,
        textAlign: 'end'
    },
    selectBox:{
        fontSize:13
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
    closeIcon:{
        marginTop:0
    }
});
  
class AddPredefinedModel extends React.Component {
  
    constructor(props) {
        super(props);   
        this.myRef = React.createRef()         
        this.closeDrawer = this.closeDrawer.bind(this);  
        this.savePredefinedModel = this.savePredefinedModel.bind(this);  
        this.validateName= this.validateName.bind(this);  
        this.validateAll= this.validateAll.bind(this);  
        this.updateTraitVal = this.updateTraitVal.bind(this)
    }

    state = {
        addOpen: this.props.addOpen,
        modelId: null,
        nameVal:true,
        models:[],
        personalities: [],
        traits:[],
    };

    componentDidMount(){
        if (this.props.selectedModel) {
            this.setState({selectedModel:this.props.selectedModel})
            this.initialize(this.props.selectedModel)
        }
        else{
            this.initialize()
        }
    }


    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){             
            this.setState({addOpen:nextProps.openState});   
        }
        if (nextProps.selectedModel!==this.state.selectedModel ){  
            
            this.setState({selectedModel:nextProps.selectedModel}) 
            this.setState({modelId:nextProps.selectedModel?nextProps.selectedModel.id:null}); 
            this.initialize(nextProps.selectedModel)  
        }
        
    }

    async initialize(model){
        try{
            if (model){
                this.setState({modelName:model.position})
                this.setState({selectedPersonality:model.report[0].dominant_traits[0]})
                this.getAllTraits(model)
                this.getDominantTraits()
            }
            else{
                this.setState({modelName:null})   
                this.getAllTraits()
                this.getDominantTraits()   
            }
            
        }
        catch(err){
            console.error(err)
        }
    }

    async getAllTraits(model){
        try{
            let response = await EgHttp.get( '/reports/traits/' + LangPicker.current() )
            let rowsres = await response.json();
            rowsres = rowsres.filter(trait => trait.is_display)
            const sortedTraits = rowsres.sort(function (a, b) {return a.dark && !b.dark?1:-1})

            if (model){
                sortedTraits.map(function(trait){
                    const traitInd = model.assessment[0].assessment_scores.findIndex(( {trait_id} ) => trait_id === trait.id )
                    if (traitInd>-1){
                        trait.value = model.assessment[0].assessment_scores[traitInd].value
                    }
                    else{
                        trait.value = 50
                    }
                    return null;
                })
            }
            else{
                sortedTraits.map(function(trait){
                    trait.value = 50;
                    return null
                })
            }
            this.setState({traits:sortedTraits});
            return null;
        }
        catch(err){
            console.error(err)
        } 
    }

    async getDominantTraits(){
        try{
            let response = await EgHttp.get( '/reports/dominanttraits/'+ LangPicker.current() )
            let rowsres = await response.json();

            this.setState({personalities:rowsres});
            return;
        }
        catch(err){
            console.error(err)
        } 
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    changePersonality = event => {
        this.setState({selectedPersonality:event.target.value})
    }
    
    closeDrawer(){
        this.setState({addOpen:false})
        this.props.handleDrawerState(false)
        this.setState({modelName:null, selectedPersonality: null, selectedModel:null})
    }

    validateName(event){
        this.setState({nameVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
    
    validateAll(){
        const { t, classes } = this.props;
        let failed = false;
        if (!this.state.modelName || !this.state.modelName.trim() || this.state.modelName.trim().length<2) {
            this.setState({nameVal:false})
            failed=true;
        }
       
        if (!this.state.selectedPersonality ){
            this.setState({personalityVal:false, modelErrTxt:<Typography variant={'h4'} className={classes.errorText}> {t('Lookalike - Campaigns - Please select a personality type')}</Typography>})
           
            failed=true;
        }
        if (failed){
            window.scrollTo(0, 0);
            return false;
        } 
        return true;
    }

    async savePredefinedModel(){
        if (!this.validateAll()){
            return;
        }
        const { t } = this.props;
        let body = JSON.stringify({
            firstname:'Predefined',
            lastname:'Model',
            syntheticname: this.state.modelName,
            hrId:this.state.modelId,
            traits: this.state.traits,
            dominantTraits:[this.state.selectedPersonality],
            status: -666
        })
        //Upsert
        await EgHttp.post('/data/syntmodel',body)
        
        //EventLog.insert(3, this.state.candidateId?this.state.candidateId:rowsres.id, this.state.candidateId?EventLog.EDIT_CANDIDATE:EventLog.INSERT_CANDIDATE, 0)
        this.closeDrawer();
        this.props.refresh("SUCCESS", this.state.campaignId?t('Lookalike - Models - Predefined model updated successfully'):t('Lookalike - Models - New model added successfully'));
        return;
    }
    
    updateTraitVal(tid, value){
        const traitsUpdated = this.state.traits;
        const traitInd = traitsUpdated.findIndex(( {id} ) => id === tid )
        if (traitInd > -1 && traitsUpdated && tid && traitsUpdated[traitInd]){
            traitsUpdated[traitInd].value = value;
        }
        this.setState({traits:traitsUpdated})
    }
    
    render() {
        const updateTrait = this.updateTraitVal
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>

            <React.Fragment key={i18n.dir()==='rtl'?'left':'right'}>
           
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:600}}>
            <div style={{width:800}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={9} justify="left">
                                <Typography variant={'h1'}>{!this.state.modelId?t('Lookalike - New Model'):t('Lookalike - Models - Edit Model')}  </Typography>
                            </Grid>
                            <Grid item xs={3} style={{textAlign:"end", paddingInlineEnd:5}} >
                                <EgButton secondary="true" className={classes.closeIcon} onClick={this.closeDrawer}>
                                    <CloseOutlinedIcon style={{fontSize:16}}></CloseOutlinedIcon>
                                </EgButton>     
                            </Grid>

                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:30}}>
                                <Typography variant={'h5'} style={{fontWeight:600, paddingBottom:3}}>{t('Lookalike - Models - Model Name')}</Typography>
                                <TextField autoComplete="modelname" name="modelName" variant="outlined" required fullWidth='true' id="modelName" label="" value={this.state.modelName} onChange={this.handleChange} className={classes.signupTxt} onBlur={this.validateName}
                                    error={this.state.nameVal?false:true}
                                    helperText={this.state.nameVal?"":t('Lookalike - Add Model - Please enter a valid model name')}
                                    InputProps={{
                                        classes: { input: classes.inputLabel }, 
                                    }}>
                                </TextField>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} >
                                <Typography variant={'h5'} style={{fontWeight:600}}>{t('Lookalike - Models - Personality Type') }</Typography>
                                <div style={{fontSize:11, paddingTop:2, paddingBottom:3}}>{t('Lookalike - Models - Select the best fit')}</div>
                                <FormControl fullWidth>
                                    <InputLabel id="selectedPTInp" ></InputLabel>
                                    <Select variant="outlined"
                                        labelId="role-label"
                                        id="selectedPersonality"
                                        name="selectedPersonality"
                                        value={this.state.selectedPersonality?this.state.selectedPersonality:0}
                                        onChange={this.changePersonality}
                                        className={classes.selectBox}  
                                       
                                    >
                                       {this.state.personalities?
                                        this.state.personalities.map(function(per) {
                                            return <MenuItem value={per.id} style={{fontSize:13}}>{LangPicker.current() === 'en'? per.display_name:per.trait_localized[0].localized_name}</MenuItem>
                                        }):''
                                        }    
                                      
                                    </Select>
                                </FormControl>
                                {this.state.modelErrTxt}
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} >
                            <Typography variant={'h5' } style={{fontWeight:600}}>{t('Lookalike - Models - Other Traits')}</Typography>
                            <div style={{fontSize:11, paddingTop:2, paddingBottom:3}}>{t('Lookalike - Models - Select traits values')}</div>
                            <div style={{border:'1px solid #E4E4E4', borderRadius:5}}>
                            {this.state.traits? this.state.traits.map(function(trait, index){return(
                                <Grid container>
                                    <Grid item xs={5} className={classes.formRow} style={{paddingInlineStart:10,marginTop:10}}>
                                        <Typography variant={'h5'} style={{textAlign:'start', paddingInlineStart:5}}>{LangPicker.current()==='en'?trait.display_name:trait.trait_localized[0].localized_name}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.formRow}>
                                        <TraitRangeSlider availAnswers={availAnswers} trait={trait} update={updateTrait} ref="slide"/>
                                    </Grid>
                                    <Grid item xs={1} className={classes.formRow} style={{paddingInlineEnd:0, paddingBottom:10}}>
                                        
                                    </Grid>
                                </Grid>
                            )}):''}
                            </div>
                            </Grid>
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton  onClick={this.savePredefinedModel} className={classes.actionButton}>{t('Settings - Accounts - Save')}</EgButton>
                                <EgButton secondary="true" outlined="true" onClick={this.closeDrawer} className={classes.actionButton}>{t('Settings - Accounts - Cancel')}</EgButton>   
                            </Grid>
                            
                        </Grid>
                </Paper>
            </div>
            </Drawer>
            </React.Fragment>

        </div>     
      
    );
  }
}
AddPredefinedModel.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AddPredefinedModel),AddPredefinedModel)))