
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Signup from './views/sign/Signup'
import Signin from './views/sign/Signin'
import Company from './views/sign/Company'
import Dashboard from './views/Dashboard'
import Candidates from './views/Candidates';
import Assigned from './views/Assigned';
import Lookalike from './views/Lookalike';
import Assessment from './views/Assessment';
import Reports from './views/Reports';
import Settings from './views/Settings';
import * as AppConstants from './util/constants/AppConstants';
import { withTranslation } from 'react-i18next';
import ConfirmEmail from './views/sign/ConfirmEmail';
import ResetPassword from './views/sign/ResetPassword';
import LogRocket from 'logrocket';
import env from "react-dotenv";
import Employees from './views/Employees';
import Teams from './views/Teams';
import NotFound from './NotFound';
import Analytics from './views/Analytics';
import BackofficeHome from './backoffice/BackofficeHome';
import Remote from './views/Remote';
import EgActivityRecorder from './util/EgActivityRecorder'



require('typeface-montserrat')
class App extends Component { 
  
  constructor(props) {
    super(props);
    LogRocket.init(env.LOG_ROCKET_ID, {
      console: {
        shouldAggregateConsoleErrors: true,
      }
    });

    this.setLoggedInObject = this.setLoggedInObject.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    
    this.state = {
        adminDeatails: '', 
        login:{
          logged: false,
          account:{},
          org:{},
        }
    };
  }
  componentDidMount(){
      document.title = "Engard - Hire Smart, Retain Smarter"
      window.fwSettings={
        'widget_id':73000001153,
        'locale':this.props.i18n.language
        };
      window.FreshworksWidget('hide', 'launcher');
      window.FreshworksWidget("setLabels", {
        'he': {
          banner: "ברוכים הבאים לתמיכה של Engard",
          launcher: "עזרה",
            contact_form: {
              title: "יצירת קשר",
              submit: "שליחה",
              confirmation: "נחזור אליך בהקדם."
            },
          }
        })
      this.setState({prevThrottle: 0})
      LogRocket.debug('App Loaded')
  }

  handleMouseMove(event) {
    EgActivityRecorder.handleMouseMove(event)
  }

  setLoggedInObject(islogged, account, org){
    this.setState({login:{logged:islogged, account:account, org:org}})
    if (islogged) {
      LogRocket.identify(`o_${org.id}__a_${account.id}`);
    }
  }

  logout(){
      this.setState({login:null})
  }

//function App() {
render(){
  const { i18n } = this.props;
  document.body.dir = i18n.dir();
  
  return (

    <div className="App" onMouseMove={this.handleMouseMove} style={{overflow:'auto'}}>
      <header className="App-header">
       
        <BrowserRouter>
          
          <Switch>
            <Route path="/signup">
              <Signup login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.SIGNUP}/>
            </Route>
            <Route path="/signin">
              <Signin login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.SIGNIN}/>
            </Route>
            <Route path="/confirm">
              <ConfirmEmail login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.CONFIRM}/>
            </Route>
            <Route path="/resetpass">
              <ResetPassword login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.RESETPASS}/>
            </Route>
            <Route path="/company">
              <Company login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.COMPANY}/>
            </Route>
            <Route path="/dashboard">
              <Dashboard login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.DASHBOARD}/>
            </Route>
            <Route path="/candidates">
              <Candidates login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.CANDIDATES}/>
            </Route>
            <Route path="/assigned">
              <Assigned login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.ASSIGNED}/>
            </Route>
            <Route path="/reports">
              <Reports login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.REPORTS}/>
            </Route>
            <Route path="/lookalike">
              <Lookalike login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.LOOKALIKE}/>
            </Route>
            <Route path="/remote">
              <Remote login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.REMOTE_INTERVIEW}/>
            </Route>
            <Route path="/assess" >
              <Assessment login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.ASSESSMENT}/>
            </Route>
            <Route path="/allemployees" >
              <Employees login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.ALL_EMPLOYEES}/>
            </Route>
            <Route path="/teams" >
              <Teams login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.TEAMS }/>
            </Route>
            <Route path="/analytics">
              <Analytics login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.ANALYTICS}/>
            </Route>
            <Route path="/settings/profile" >
              <Settings state={1} login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.PROFILE}/>
            </Route>
            
            <Route path="/settings" >
              <Settings state={0} login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.SETTINGS}/>
            </Route>
            <Route path="/backoffice" >
              <BackofficeHome state={0} login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.BACKOFFICE}/>
            </Route>
            <Route path="/">
              <Signup login={this.state.login} setLogin={this.setLoggedInObject} page={AppConstants.Views.SIGNUP}/>
            </Route>
            <Route component={NotFound} />

            
          </Switch>
         
          
        
       
      </BrowserRouter>
      </header>
    </div>
  );
}}

export default withTranslation()(App);


