import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';

const NavigationManager = {   
    getLast: function(props){
        const loc = props.cookies.cookies.nav
        if (loc && loc.length>0){
            props.history.push(loc)
        }
        else{
            props.history.push('/dashboard')
        }
        return;
    },
    setLast(props, loc){
        props.cookies.set("nav", loc)  
    },
    reset: function(props){
        props.cookies.set("nav", '')  
        props.history.push('/dashboard')
    }
}
NavigationManager.propTypes = {
   cookies: instanceOf(Cookies).isRequired
};
export default withCookies(withRouter(NavigationManager));
