import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Avatar } from '@material-ui/core';
import empty from '../../img/empty_latest.svg';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import * as AppConstants from '../../util/constants/AppConstants';
import AppUtils from '../../util/AppUtils';
import EventLog from '../../util/EventLog';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import SkeletonTable from '../../components/SkeletonTable';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'start',
      color: theme.palette.text.secondary,
      width:'97%',
      height:'60vh',
      maxHeight:'60vh',
      
    },
    innerPaper:{
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        height:'50vh',
        overflow:'auto',
    },
    
    
    emptyStateImg:{
        width:'22vw',
        opacity:1,
        padding:10
    },

    signupLink:{
        textDecoration:"none",
        color: theme.palette.text.white,

    },
    tableAvCell:{
        width:20,
        textAlign:"right",
        border:0,
    },
    
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:"#272931",
        border:0,
        paddingLeft:10,
        paddingRight:10,
        textAlign:'start',
        borderRight:'1px solid #F0F0F0',
        overflowWrap:'anywhere'
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'1px solid #F0F0F0',
        
    },
    
    tableRowInner:{
        minHeight:"6.5vh",
        maxHeight:"6.5vh",
        height:"6.5vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
    avatar:{
        fontSize: 14,
        fontWeight:600,
        
    },
    actionButtonEmpty:{
        width:240
    }
    
});
  
class LatestActivityNote extends React.Component {
  
    state = {
        eventLog: this.props.eventLog?this.props.eventLog:[],
        loading:true
    };
  
    componentWillReceiveProps(nextProps) {   
        if (nextProps.eventLog!==this.state.eventLog ){             
            this.setState({eventLog:nextProps.eventLog});    
        }
        if (nextProps.loading!==this.state.loading ){             
            this.setState({loading:nextProps.loading});   
            
        }
    }

  render() {
   
    const { classes } = this.props;
    const { t } = this.props;
    const eventLogMessages = EventLog.getMessages()
    return (
        <div style={{}}>

        <Grid container spacing={3} style={{}}>
            
            <Grid item xs={12 } style={{height:'100%', display:'flex', justifyContent:'end'}}>
                {this.state.loading || (this.state.eventLog && this.state.eventLog.length>0)?
                
                <Paper className={classes.paper}>
                    <Grid item xs={12 } style={{padding:5}}>
                            <Typography variant={'h4'}> {t('Dashboard - Latest Activity')} </Typography>
                    </Grid>   
                    <Paper elevation={0} className={classes.innerPaper}>
                    <Grid item xs={12 }  >
                    {this.state.loading?
                        <SkeletonTable style={{width:'100%'}}></SkeletonTable>
                        :
                        <Table className={classes.table} aria-label="simple table" >
                            <TableBody key={"tb1"}>
                            {this.state.eventLog.map((row, index) => (
                                <TableRow key={row.name + "_"+ index} className={classes.tableRowBody} >
                                    <TableCell key={row.name + index + "intc"} className={classes.tableInnerCell} colSpan={5} > 
                                        <div>
                                            <Table className={classes.tableInner} aria-label="inner table" key={row.name + "tb2" + index} >
                                            <TableBody key={row.name + "tb2in" + index}>
                                                <TableRow  className={classes.tableRowInner} key={row.name + "tb2inr" + index}>
                                                    <TableCell className={classes.tableAvCell} style={{width:'5%'}} key={row.name + "tb2inc" + index}>
                                                        <Avatar className={classes.avatar} style={{backgroundColor:AppConstants.AVATAR_COLORS[row.hr_id%AppConstants.AVATAR_COLORS.length]}}>{AppUtils.getInitials(row.firstname+ " " + row.lastname)}</Avatar>   
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{width:'30%'}} key={row.name + "tb3inc" + index}> 
                                                        {row.firstname+ " " + row.lastname}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} align="left" style={{width:'45%'}} key={row.name + "tb4inc" + index}>{eventLogMessages[row.event_id]}</TableCell>
                                                    <TableCell className={classes.tableCell} align="left" style={{width:'20%'}} key={row.name + "tb5inc" + index}><Moment format="MMM DD, h:mm a">{row.created_at}</Moment></TableCell>
                                                </TableRow>
                                            </TableBody >
                                            </Table>
                                        </div>
                                        
                                    </TableCell>
                                </TableRow>   
                            ))}
                            </TableBody>
                        </Table>
                    }
                    </Grid>
                    </Paper>
                </Paper>
                :
                <Paper className={classes.paper}>
                        <Grid item xs={12 }>
                            <Typography variant={'h4'}> {t('Dashboard - Latest Activity')} </Typography>
                        </Grid>
                        <Grid item xs={12 } justify="center" style={{paddingTop:30}}>
                            <Typography variant={'h2'} style={{textAlign:'center'}}>{t('Dashboard -  No activity found Yet')}</Typography>
                        </Grid>  

                        <Grid item xs={12 } justify="center" style={{textAlign:'center', paddingTop:'5vh'}}>
                            <img src={empty} className={classes.emptyStateImg} alt="empty" />  
                        </Grid>  

                        <Grid item xs={12 } justify="center" style={{textAlign:'center', paddingTop:'2vh'}}>
                            <EgButton  className={classes.actionButtonEmpty}>
                                <Link className={classes.signupLink} to="/candidates">{t('Dashboard -  Add New Candidates')}</Link>
                            </EgButton>  
                        </Grid>   
                </Paper>
            }
            </Grid>
        </Grid>
        </div>      
    );
  }
}
LatestActivityNote.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(LatestActivityNote),LatestActivityNote);