import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";


const EgButtonPrimaryStyled = withStyles((theme) => ({
  root: {

    background:theme.palette.primary.main,
    color:"white",
    
    paddingLeft:10,
    paddingRight:10,
    borderRadius:4,
    fontSize: 12,
    fontWeight:500,
    '&:hover': {
        backgroundColor: '#0085AF',
        color: '#efefef',
    }
  },
    
}))(Button);
const EgButtonFadedStyled = withStyles((theme) => ({
  root: {

    background:theme.palette.primary.main,
    color:"white",
    paddingLeft:10,
    paddingRight:10,
    borderRadius:4,
    fontSize: 12,
    fontWeight:500,
    opacity:0.3,
    '&:hover': {
        backgroundColor: '#0085AF',
        color: '#efefef',
        opacity:1,
    }
  },
    
}))(Button);

const EgButtonSecondaryStyled = withStyles((theme) => ({
    root: {
        background:"white",
        color:theme.palette.primary.main,
        paddingLeft:10,
        paddingRight:10,
        borderRadius:4,
        fontSize: 12,
        fontWeight:500,
        '&:hover': {
            backgroundColor: '#E6FAFF',
            color: '#0090B0',
            opacity:1,
        }
    }
}))(Button);

export default function EgButton(props) {
  return (
    <span >
        {props.secondary?
        <EgButtonSecondaryStyled  {...props} style={{border:props.outlined?"1px solid #13A7D6":0, fontSize:props.small?11:12}} />:
        props.faded?
        <EgButtonFadedStyled  {...props}   style={{border:props.outlined?"1px solid #ffffff":0, }}/>:
        <EgButtonPrimaryStyled  {...props}   style={{border:props.outlined?"1px solid #ffffff":0, }}/>
        }       
    </span>
  );
}
