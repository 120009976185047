import React from "react";
import { useTranslation } from 'react-i18next';
import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles'
import EnProgressStatic from "./EnProgressStatic";

/*
const useStyles = makeStyles(theme => ({
    hi: {
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #FC6626 , #FC264D)'
    },
  }))
*/


export default function EgWaitDialog(props) {
    const { t } = useTranslation();
    //const classes = useStyles();
    const theme = useTheme()
    let title = t('WaitDialog - Please Wait')
    let processing = t('WaitDialog - Processing')
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
        const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 95 ? 95 : prevProgress + 5));
        }, props.progressTime/20);
        return () => {
            clearInterval(timer);
        };
    }, []);
    
    return (
        <Dialog open={true} style={{padding:30, }}>
            <DialogTitle style={{display:'flex', justifyContent:'center', minWidth:250}}>{title}</DialogTitle>
            <DialogContent style={{display:'flex', justifyContent:'center', paddingBottom:30}}>
                <Grid container>
                    <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                        <CircularProgress style={{color:theme.palette.primary.main}}/>
                    </Grid>
                    {props.withProgress?
                    <Grid item xs={12} style={{display:'flex', justifyContent:'center', paddingTop:10}}>
                        <Typography variant={'h5'} style={{paddingInline:10}}>{processing}</Typography>
                        <EnProgressStatic value={progress}></EnProgressStatic>
                    </Grid>:''}
                </Grid>
            </DialogContent>
        </Dialog>
        
        
    );
}
