import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgRiskBar from '../../components/EgRiskBar';
import LangPicker from '../../util/LangPicker';
import EgSegmentParser from '../../util/EgSegmentParser'

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
    
    tableCell:{
        border:0,
        paddingBottom:'1.2vh',
        paddingTop:'1.2vh',
    },
   
    tableInner:{
        paddingTop:'2vh',
        paddingBottom:'2vh'
    },
    
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:20
    },
    riskBar:{
        padding:10, 
        width: 200,
        display:'flex',
        justifyContent:'center'
    },
    
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
    
  });

class SingleReportExecutive extends React.Component {
  
    constructor(props) {
        super(props);            
        console.log("REPORT Executive Summary")
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        execSummaryArr:[],
        invalidReport:false
        
    };

    componentDidMount(){
        if (this.props.report) this.setState({report: this.props.report})
        if (this.props.reportParts) this.setState({reportParts: this.props.reportParts})
        this.setExecSummary(this.state.report)
        this.setOverallRisk(this.props.reportParts)
        this.setState({extendedOpen: false})
        if (this.props.report.dominant_traits && this.props.report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }else{
            this.setIsMedLow(this.props.report)
        }
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report!==this.state.report ){
            this.setState({report:nextProps.report});  
            this.setExecSummary(this.state.report)
            if (nextProps.report.dominant_traits && nextProps.report.dominant_traits[0] === 0){
                this.setState({invalidReport:true})
            }
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.setExecSummary(this.state.report)
        }
    }
    async setExecSummary(report){
        if(report && report.report_part && report.report_part.length){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 1 )
            const reparts = this.state.reportParts;
            let extraTextArr = []
            let execSummaryArr = report.report_part[ind].template_id.map(function(pid){
                const partind = reparts.findIndex(({ id }) => id === pid )
                if (partind > -1){
                    if (reparts[partind].display_trait_ref && reparts[partind].display_trait_ref > 0){
                        extraTextArr.push(reparts[partind])
                        return null;
                    }
                    else{
                        console.log(reparts[partind])
                        if (LangPicker.current() === 'en'){
                            return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                        }
                        else{
                            if (reparts[partind].report_template_localized[0]){
                                return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                            }
                            else{
                                return({id: pid, trait_id: reparts[partind].trait_id,text:""})
                            }
                        }
                    }
                }
                return null
            })
            execSummaryArr = execSummaryArr.filter(function(e){return e});
            if (extraTextArr.length > 0){
                extraTextArr.map(function(part){
                    const partind = execSummaryArr.findIndex(({ trait_id }) => trait_id === part.display_trait_ref )
                    if (partind > -1){
                        execSummaryArr[partind].text = execSummaryArr[partind].text  +"\n"+  part.text;
                    }
                    return null
                })
            }
            this.setState({execSummaryArr: execSummaryArr})    
        }
    }
    
    setOverallRisk(reportParts){
        const ind = reportParts.findIndex(({ type }) => type === 0 )
        let overallRiskText;
        if (ind > -1){
            if (LangPicker.current() === 'en'){
                overallRiskText = reportParts[ind].text;
            }
            else{
                overallRiskText = reportParts[ind].report_template_localized[0].text;
            }
        } 
        else{
            overallRiskText = "";
        }
        this.setState({overallRiskText})
        const tind = reportParts.findIndex(({ type }) => type === 6 )
        if (tind > -1){
            this.setState({timeSeverity: reportParts[tind].severity})
        }
    }

    setIsMedLow(report){
        const dominant = report.dominant_traits[0];
        const scoreInd = report.assessment.assessment_scores.findIndex(({ trait_id }) => trait_id === dominant )
        if (scoreInd > -1){
            const calcScore = report.assessment.assessment_scores[scoreInd].trait_weighted_score*100/report.assessment.assessment_scores[scoreInd].trait_potential_score
            const boundaries = report.assessment.assessment_scores[scoreInd].trait.boundaries[1]
            if (calcScore > boundaries.bottom && calcScore <= boundaries.top){
                if ( report.assessment.assessment_scores[scoreInd].trait.dark === false){
                    
                    this.setState({isMedLow:true})
                }
                else if(calcScore < boundaries.top-(boundaries.top-boundaries.bottom)/2){
                    this.setState({isMedLow:true})
                }
                else{
                    this.setState({isMedLow:false})
                }
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            {this.state.invalidReport?
            <Grid item xs={12} style={{paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:10, paddingRight:10}}>
                <Typography variant={'h3'} className={classes.invalid}>{ t('Reports - Summary - Invalid Assessment')}</Typography>
            </Grid>:
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Grid container className={classes.boxTitle}>
                        <Grid xs={8} style={{display:'flex', alignItems:'center'}}>
                            <Typography variant={'h4'} style={{fontWeight:600}}>{t('Reports - Summary - Executive Summary')} </Typography>
                        </Grid>
                        <Grid xs={4} style={{display:'flex', justifyContent:'end'}} >
                        </Grid>
                    </Grid>
                    <div className={classes.tableInner}>
                        <Table  aria-label="inner table" >
                        <TableBody >
                            <TableRow key={this.state.report.candidateId + "ina"}  >
                                    <TableCell style={{width:'0%', border:0}}></TableCell>
                                    <TableCell className={classes.tableCell} style={{width:'60%', justifyContent:'start', display:'flex', paddingTop:'2vh'}}>
                                        <EgRiskBar severity={this.state.report.overall_risk} width='25vw' className={classes.riskBar} isMedLow={this.state.isMedLow}></EgRiskBar>
                                    </TableCell>
                                    <TableCell style={{width:'40%',  border:0}}></TableCell>

                            </TableRow>
                            <TableRow key={"overall" }  >
                                <TableCell style={{width:'0%', border:0}}></TableCell>
                                <TableCell style={{width:'80%', justifyContent:'start', display:'flex', paddingTop:'2vh', paddingInline:20, border:0}} >
                                    {this.state.invalidReport?'':
                                        <Typography variant={'h4'} style={{textAlign:'start', fontWeight:600}}>{this.state.overallRiskText}</Typography>
                                    }
                                </TableCell>
                                <TableCell style={{width:'40%',  border:0}}></TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                        <Table  aria-label="inner table" >
                        <TableBody >
                            <TableRow key='execsum'  >
                                <TableCell style={{width:30, border:0}}></TableCell>
                                <TableCell className={classes.tableCell} style={{width:'70%',borderTop: '1px solid #F2F2F2'}}>
                                
                                <Typography variant={'h4'} style={{lineHeight:2, textAlign:'start' , whiteSpace:'break-spaces'}}>{this.state.execSummaryArr.length?EgSegmentParser.parse(this.state.execSummaryArr[0].text):''}</Typography>
                                {(this.state.execSummaryArr && this.state.execSummaryArr.length>1) ?
                                    <div style={{paddingTop:'2vh', paddingBottom:'1vh',fontWeight:600, lineHeight:1.7, fontSize:16, textAlign:'start'}}>{ t('Reports - Summary - Follow-up Issues')}</div>:''}
                                {(this.state.execSummaryArr && this.state.execSummaryArr.length>1) ?
                                    this.state.execSummaryArr.map((score, index) => (
                                        index > 0?
                                        <Typography variant={'h4'} style={{lineHeight:2, textAlign:'start' , whiteSpace:'break-spaces'}}>{this.state.execSummaryArr.length?EgSegmentParser.parse(this.state.execSummaryArr[index].text):''}</Typography>:'' 
                                    )):''
                                }
                                    
                                </TableCell>
                                <TableCell style={{width:'30%', border:0}}></TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                    </div>
                    
                </Paper>
            </Grid>
            }
            
            </div>               
    );
  }
}
SingleReportExecutive.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportExecutive),SingleReportExecutive);