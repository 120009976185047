import i18n from '../../i18n/i18n'

export const RiskLevels = {

    get: function(){
    return(
        [
            {id:1, name: i18n.t('RiskLevel - Low Risk'), color:"#11D6A7"},
            {id:2, name: i18n.t('RiskLevel - Med Risk'), color:"#FCBF54"},
            {id:3, name: i18n.t('RiskLevel - High Risk'), color:"#F15D5D"},
            {id:4, name: i18n.t('RiskLevel - More Information'), color:"#EEEEEE"},
        ]
    )},
    getSummaryText: function(sev){
        const summaryArr = [
            i18n.t('RiskLevel - Low Risk - Text'),
            i18n.t('RiskLevel - Med Risk - Text'),
            i18n.t('RiskLevel - High Risk - Text'),
        ]
        return summaryArr[sev]
    },
}

