import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    increment: (state) => {
      
      state.value.org.tests += 1
    },
    incrementBy: (state,action) => {
      
      state.value.org.tests += action.payload
    },
    decrement: (state) => {
      state.value.org.tests -= 1
    },
    setAdminDetails: (state, action) => {
      state.value = action.payload
    },
    setAdminLang: (state,action) => {
      state.value.org.lang = action.payload
    }
  },
  
})

// Action creators are generated for each case reducer function
const { actions, reducer } = adminSlice;
export const { increment, decrement, setAdminDetails, incrementBy, setAdminLang } = actions;
export default reducer;