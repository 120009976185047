import React from 'react';

import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Divider } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Moment from 'react-moment';
import EgButton from '../../components/EgButton';
import EgHttp from '../../util/EgHttp';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width: 100,
        margin: 5
    },
    messageHeader:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.secondary.light,
    },
    messageHeaderSuc:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.primary.light,
    },
    dialogCV:{
        maxWidth:'none',
        '&.MuiDialog-paperWidthSm':{
            maxWidth:1100
        }
    }
    
})

class InterviewsAccordion extends React.Component {
  
    state = {
        open: this.props.open,
        interviews: this.props.interviews?this.props.interviews:[],
        expanded:null,
    };

    constructor(props) {
        super(props);  
        this.addInterview = this.addInterview.bind(this);  
        this.changeExpended = this.changeExpended.bind(this)
        
        if (this.props.hrId){
            this.setState({hrId:this.props.hrId});   
        }
    }

    componentDidMount(){
        
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.hrId!==this.state.hrId ){             
            this.setState({hrId:nextProps.hrId});  
            this.downloadCV(nextProps.hrId)       
        }
        
    }
    addInterview(){
        const interviews = this.state.interviews
        interviews.unshift({interviewerName:'', interviewDate:'', interviewSum:''})
        this.setState({interviews:interviews, expanded:0})
    }
    handleChange(ind,name,event){
        const interviews = this.state.interviews
        interviews[ind][name] = event.target.value
        this.setState({interviews})
        this.props.updateInterviews(interviews)
    }
    changeExpended(event, ind){
        if (this.state.expanded === ind) this.setState({expanded: null})
        else this.setState({expanded: ind})
    }


    render() {
        const { classes } = this.props;
        const { t } = this.props;
       const thisRef = this
        return (
            <div>
            
            {this.state.interviews && this.state.interviews.length?
                <div>
                <Grid item xs={12}  className={classes.formRow} style={{display:'flex',justifyContent:'end', paddingTop:0}}>
                    <EgButton size={'small'} onClick={this.addInterview}>{t('Candidates - Add - Add Interview')}</EgButton>
                </Grid>
                {this.state.interviews.map(function(inter, index){
                return(
                    <Accordion expanded={index===thisRef.state.expanded}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon /> } id="panel1a-header" onClick={(event)=>(thisRef.changeExpended(event, index))} >
                            <Grid container>
                                <Grid item xs={3}><Typography variant={'h4'}>{t('Candidates - Add - Interview')} </Typography></Grid>
                                <Grid item xs={5}><Typography variant={'h4'}>{t('Candidates - Add - By')} {inter.interviewerName} </Typography></Grid>
                                <Grid item xs={4}><Typography variant={'h4'} style={{textAlign:'end'}}>{inter.interviewDate?<Moment format="MMM DD, YYYY">{inter.interviewDate}</Moment>:''}</Typography></Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={6} className={classes.formRow} style={{paddingTop:0}}>
                                <Typography variant={'h5'}>{t('Candidates - Add - Interviewer Name')}</Typography>
                                <TextField size="small" autoComplete="interviewerName" name="interviewerName" variant="outlined" required fullWidth='true' id="interviewerName" label="" value={inter.interviewerName} onChange={(event)=>(thisRef.handleChange(index, 'interviewerName', event))}
                                        style={{textAlign:'start'}}
                                        className={classes.signupTxt}
                                    />
                                </Grid>
                                <Grid item xs={6}  className={classes.formRow} style={{paddingTop:0}}>
                                    <Typography variant={'h5'}>{t('Candidates - Add - InterviewDate')}</Typography>
                                    <TextField size="small" autoComplete="interviewDate" name="InterviewDate" type='date' variant="outlined" required fullWidth='true' id="interviewDate" label="" value={inter.interviewDate} onChange={(event)=>(thisRef.handleChange(index, 'interviewDate', event))}  className={classes.signupTxt}></TextField>
                                </Grid>
                                
                                <Grid item xs={12} className={classes.formRow} style={{paddingTop:10}}>
                                    <Typography variant={'h5'}>{t('Candidates - Add - Interview Summary')}</Typography>
                                    <TextField  size='small' multiline maxRows={14} rows={14} autoComplete="interviewSum" name="interviewSum" variant="outlined" fullWidth='true' id="interviewSum" label="" value={inter.interviewSum} onChange={(event)=>(thisRef.handleChange(index, 'interviewSum', event))} className={classes.signupTxt} InputProps={{style: {fontSize:14}}}> </TextField>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion> 
                )})}
                
                </div>
                :
                <Grid container style={{paddingTop:20, paddingBottom:20}}>
                    <Grid item xs={7} className={classes.formRow} style={{paddingInline:10}}>
                        <Typography variant={'h4'}>{t('Candidates - Add - No interviews available yet')}</Typography>
                    </Grid >
                    <Grid item xs={5}  className={classes.formRow} style={{paddingInline:10}}>
                        <EgButton size={'small'} onClick={this.addInterview}>{t('Candidates - Add - Add Interview')}</EgButton>
                    </Grid>
                </Grid>
                }

                 <Divider style={{marginTop:10, marginBottom:0}}/>
                 </div>
          
        );
    }
}

InterviewsAccordion.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(InterviewsAccordion),InterviewsAccordion)))