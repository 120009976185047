import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import testIntro from '../../img/enable_screen.svg';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';

const styles = theme => ({
    root: {
        display: 'flex',
        width:'100%'
    },
    logo:{
        width:'100%',
        paddingTop:5,
        paddingBottom:5, 
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:550,
        display:'flex',
        alignItems:'center'
      },
    
    actionButton:{
        paddingLeft:10,
        paddingRight:10,
        fontSize:14,
        margin: 5
    },    
});


class AssessScreenRec extends React.Component {
  
    state = {
        open: this.props.open,
    };

    constructor(props) {
        super(props);  
        this.continue = this.continue.bind(this);  
    }

  
    continue(){
        this.props.continue();
    }
  
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
            <Paper className={classes.paper}>
                <Grid container  justify="left"  direction="row" alignItems="center" style={{padding:30}}>
                    <Grid item xs={6} style={{display:'flex', alignItems:'center', paddingInlineEnd:10}}>
                        <Grid container  justify="left" direction="row" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant={'h1'} >{t('Assessment - Screen - Share Your Screen')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'h4'} style={{paddingTop:30}}><div dangerouslySetInnerHTML={{__html: t('Assessment - Screen - After clicking start', {interpolation: {escapeValue: false}})}} /></Typography> 
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant={'h4'} style={{paddingTop:20}}>{t('Assessment - Screen - Click Start to begin')}</Typography>
                        </Grid>
                        
                        <Grid item xs={12} justify="center" style={{display:"flex", paddingTop:'8vh', justifyContent:"start"}}>
                            <EgButton variant="contained"  className={classes.actionButton} onClick={this.continue}>
                                {t('Assessment - Opener - Continue')}
                            </EgButton>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} justify="center" style={{display:"flex", alignItems:"center", paddingTop:0}}>
                        <img src={testIntro} className={classes.logo} alt="testIntro" />  
                    </Grid>
                </Grid>
            </Paper> 
    );
  }
}
AssessScreenRec.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(AssessScreenRec),AssessScreenRec);


