import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  listText:{
    '& .MuiListItemText-primary': {
        fontWeight:600
      },
  }

});

class EgSegmentSlicer extends React.Component {

    state = {
      text: this.props.text,
      components: [],
      accords:{},
      accordCntr:1
    };

    constructor(props) {
      super(props);
      this.controlsRef = React.createRef();
      this.controlAccords = this.controlAccords.bind(this); 
    }

    componentDidMount() {
      console.log('Start Slicing')
      this.buildMarkup(this.props.text)
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.text !== this.state.text && nextProps.text !== null) {
        this.setState({text:nextProps.text})
        this.buildMarkup(nextProps.text)
      }
    }

    buildMarkup(str){
        const components = this.parse(str)
        this.setState({components:components})
    }

    controlAccords(e){
        const accords = this.state.accords;
        accords[e.target.parentElement.attributes.name.value] = !accords[e.target.parentElement.attributes.name.value]
        this.setState({accords})
        this.buildMarkup(this.state.text)
    }
   

    parse(str,inner, innerCntr){

        if (!str) return
        let openindex = str.indexOf('[')
        if (openindex === -1) return str
        let clsindex,literal, literalStr,tag;
        let literalArr = [], innerLitArr = []
        let gcntr = 0, accordCntr = 0;
        while (openindex > -1 && gcntr++<100){ //Set hard limit incase the text is broken
            tag = str[openindex+1]
            switch(tag){
                
                case 'C':{
                    accordCntr++
                    clsindex = str.indexOf(['/C'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    innerLitArr = this.parse(literalStr,true,accordCntr )
                    
                    literal =   <div style={{paddingTop:'0vh', paddingBottom:'1vh',borderBottom: '1px solid #F2F2F2', lineHeight:1.7}}>
                                    <List   sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader"  name={"acc" + accordCntr}>
                                        {innerLitArr[0]}
                                        <Collapse in={this.state.accords["acc" + accordCntr]} timeout="auto" unmountOnExit style={{padding:30}}>
                                            {innerLitArr.slice(1)}
                                        </Collapse>
                                    </List>
                               </div>
                    
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'G': {
                    clsindex = str.indexOf(['/G'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <div style={{ paddingBottom:'1vh', lineHeight:1.7}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'Q': {
                    clsindex = str.indexOf(['/Q'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <ul style={{paddingInlineStart:10,margin:0}}><li style={{paddingTop:'0.5vh', paddingBottom:'0.5vh', fontWeight:600, fontSize:14,lineHeight:1.7, listStylePosition: 'outside'}}>{literalStr}</li></ul>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'L': {
                    clsindex = str.indexOf(['/L'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <ul style={{paddingInlineStart:10,margin:0}}><li style={{paddingTop:'0.1vh', paddingBottom:'0.1vh', fontWeight:400, fontSize:14,lineHeight:1.7, listStylePosition: 'outside'}}>{literalStr}</li></ul>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'S': {
                  clsindex = str.indexOf(['/S'])
                  literalStr = str.substring(openindex+3,clsindex-1)
                  literal = <ul style={{paddingInlineStart:10,margin:0}}><li style={{paddingTop:'0.1vh', paddingBottom:'0.1vh', fontWeight:400, fontSize:14,lineHeight:1.7, listStylePosition: 'outside'}}>{literalStr}</li></ul>
                  literalArr.push(literal)
                  str = str.substring(clsindex+4,str.length)
                  openindex = str.indexOf('[')
                  break;
              }
                case 'A':{
                    clsindex = str.indexOf(['/A'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <div style={{paddingTop:'0vh', paddingBottom:'1vh',paddingInlineStart:10,borderBottom: '1px solid #F2F2F2', lineHeight:1.7}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;

                }
                case 'T':{
                    clsindex = str.indexOf(['/T'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    if (inner){
                        literal =   <ListItem button name={"acc" + innerCntr} onClick={this.controlAccords}>
                                        <ListItemText primary={literalStr} style={{textAlign:'start'}} className={this.props.classes.listText} name={"acc" + innerCntr}/>{this.state.accords["acc" + innerCntr] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                    }
                    else{
                        literal =   <div style={{paddingTop:'2vh', paddingBottom:'1vh',fontWeight:600, lineHeight:1.7, fontSize:16}}>{literalStr}</div>
                    }
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                case 'D':{
                    clsindex = str.indexOf(['/D'])
                    literalStr = str.substring(openindex+3,clsindex-1)
                    literal = <div style={{  lineHeight:1.7}}>{literalStr}</div>
                    literalArr.push(literal)
                    str = str.substring(clsindex+4,str.length)
                    openindex = str.indexOf('[')
                    break;
                }
                default:{
                  break;
                }
                
            }
        }
        
        
        return literalArr
        
    }

   
  render() {
    return (<div>
        {this.state.components}
    </div>)
  }
}

EgSegmentSlicer.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(EgSegmentSlicer);