import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import GradeBar from '../../components/GradeBar'
import { withTranslation } from 'react-i18next';
import SingleReportSummaryRecommendationsBox from './SingleReportSummaryRecommendationsBox';
import SingleReportSummaryVideoBox from './SingleReportSummaryVideoBox';
import SingleReportSummaryTimeBox from './SingleReportSummaryTimeBox';
import EgRiskBar from '../../components/EgRiskBar';
import LangPicker from '../../util/LangPicker';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:'1.5vh'
    },
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:theme.palette.text.black,
        border:0,
        paddingBottom:'1vh',
        paddingTop:'1.5vh',
        textAlign:'start',
    },
    
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto'
    },
    
    boxTitle:{
        padding:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    
    overall:{
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #FC6626 , #FC264D)'
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    },
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
    
  });

const NUM_OF_TRAITS_TO_DISPLAY = 7;

class SingleReportSummary extends React.Component {
  
    constructor(props) {
        super(props);           
        this.moveTo = this.moveTo.bind(this);
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        displayScores:[],
        invalidReport:false,
        loading:true,
        isMedLow:false,
    };

    componentDidMount(){
        console.log("REPORT AFTER MOUNT")
        
        if (this.props.report ) {
            this.setState({report: this.props.report})
            const scores = [...this.props.report.assessment.assessment_scores]
            const displayScores = scores.filter(score => score.trait.is_display === true)
            this.setState({displayScores})
            if (this.props.report.dominant_traits && this.props.report.dominant_traits[0] === 0){
                this.setState({invalidReport:true})
            }else{
                console.log("CALLING IS MED_LOW")
                this.setIsMedLow(this.props.report)
            }
            
        }
        if (this.props.reportParts) {
            this.setState({reportParts: this.props.reportParts})
            this.setOverallRisk(this.props.reportParts)
            
        }
        
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report!==this.state.report ){
            this.setState({report:nextProps.report});  
            if (nextProps.report.dominant_traits && nextProps.report.dominant_traits[0] === 0){
                this.setState({invalidReport:true})
            }
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            console.log("GOT NEW REPORT PARTS")
            this.setOverallRisk(nextProps.reportParts)
            this.setState({reportParts:nextProps.reportParts});  
        }
        if (nextProps.loading === this.state.loading) {
            this.setState({loading: nextProps.loading})
        }
    }

    setIsMedLow(report){
        const dominant = report.dominant_traits[0];
        const scoreInd = report.assessment.assessment_scores.findIndex(({ trait_id }) => trait_id === dominant )
        if (scoreInd > -1){
            const calcScore = report.assessment.assessment_scores[scoreInd].trait_weighted_score*100/report.assessment.assessment_scores[scoreInd].trait_potential_score
            const boundaries = report.assessment.assessment_scores[scoreInd].trait.boundaries[1]
            if (calcScore > boundaries.bottom && calcScore <= boundaries.top){
                if ( report.assessment.assessment_scores[scoreInd].trait.dark === false){
                    
                    this.setState({isMedLow:true})
                }
                else if(calcScore < boundaries.top-(boundaries.top-boundaries.bottom)/2){
                    this.setState({isMedLow:true})
                }
                else{
                    this.setState({isMedLow:false})
                }
            }
        }
    }

    setOverallRisk(reportParts){
        const ind = reportParts.findIndex(({ type }) => type === 0 )
        let overallRiskText;
        if (ind > -1){
            if (LangPicker.current() === 'en'){
                overallRiskText = reportParts[ind].text;
            }
            else{
                overallRiskText = reportParts[ind].report_template_localized[0].text;
            }
        } 
        else{
            overallRiskText = "";
        }
        this.setState({overallRiskText})
        const tind = reportParts.findIndex(({ type }) => type === 6 )
        if (tind > -1){
            this.setState({timeSeverity: reportParts[tind].severity})
        }
    }

    moveTo(dest){
        this.props.moveTo(null, dest)
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:10, borderTop:'1px solid #e8e8e8'}}>
            <Grid container >
            {
                this.state.invalidReport?
                <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'1vh', paddingLeft:10, paddingRight:10}}>
                    <Typography variant={'h3'} className={classes.invalid}>{ t('Reports - Summary - Invalid Assessment')}</Typography>
                </Grid>:''
            }
            <Grid item xs={4} className={classes.formRow} >
                <Paper elevation={0} className={classes.summaryBox} style={this.state.invalidReport?{height:'72vh'}:{}}>
                    <a href="#0" onClick={()=>{this.moveTo(1)}} className={classes.link}>
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Summary - Overall Recommendation')} </Typography>
                    <Table className={classes.tableInner} aria-label="inner table">
                        <TableBody >
                            <TableRow key={"overall" }  >
                                <TableCell style={{paddingTop:'2vh',paddingBottom:'2vh',paddingInline:'20' , border:0}} >
                                    {this.state.invalidReport?
                                        <EgRiskBar severity={-1}></EgRiskBar>:
                                        <EgRiskBar severity={this.state.report.overall_risk} isMedLow={this.state.isMedLow}></EgRiskBar>
                                    }
                                </TableCell>
                            </TableRow>
                            
                            <TableRow key={"overall" }  >
                                <TableCell style={{paddingInline:20, paddingTop:0, border:0}} >
                                    {this.state.invalidReport?'':
                                        <Typography variant={'h4'} style={{textAlign:'start'}}>{this.state.overallRiskText}</Typography>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </a>
                    <a href="#0" onClick={()=>{this.moveTo(2)}} className={classes.link}>
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Summary - Evaluation of Traits')} </Typography>
                    <div style={{overflow:'auto', paddingTop:'0.5vh'}}>
                    <Table className={classes.tableInner} aria-label="inner table" >
                        <TableBody >
                            {this.state.displayScores && this.state.displayScores.length?
                            this.state.displayScores.map((score, index) => (
                                index < NUM_OF_TRAITS_TO_DISPLAY?
                                this.state.invalidReport?
                                <TableRow key={"char" + index }  >
                                    <TableCell className={classes.tableCell} style={{width:'82%', paddingInlineStart:20}}>
                                        <Typography variant={'h4'} style={{paddingBottom:'0.8vh'}}>
                                            &nbsp;
                                        </Typography>
                                        <GradeBar variant="determinate" value={0} negative={score.trait.dark} boundaries={score.trait.boundaries}></GradeBar>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{width:'18%',paddingBottom:0,paddingInlineStart:0, paddingTop:'2.6vh'}}>
                                        <div style={{direction:'ltr'}}><span style={{fontWeight:500}}>{0}</span><span style={{fontSize:9}}> / 100</span></div>
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow key={"char" + index }  >
                                    <TableCell className={classes.tableCell} style={{width:'82%', paddingInlineStart:20}}>
                                        <Typography variant={'h4'} style={{paddingBottom:'0.8vh'}}>
                                            {LangPicker.current() === 'en'?score.trait.name:score.trait.trait_localized[0].localized_name}
                                        </Typography>
                                        <GradeBar variant="determinate" value={score.trait_weighted_score*100/score.trait_potential_score } negative={score.trait.dark} boundaries={score.trait.boundaries}></GradeBar>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{width:'18%',paddingBottom:0,paddingInlineStart:0, paddingTop:'2.6vh'}}>
                                        <div style={{direction:'ltr', textAlign:'center'}}><span style={{fontWeight:500}}>{Math.floor(score.trait_weighted_score*100/score.trait_potential_score)}</span><span style={{fontSize:9}}> / 100</span></div>
                                    </TableCell>
                                </TableRow>
                                :''
                            )):
                            ''}
                            <TableRow key={"chare" } > 
                                {this.state.invalidReport?
                                    '':<TableCell style={{border:0, paddingInlineStart:20, paddingTop:'2vh', textAlign:'start'}}><a href="#0" onClick={()=>{this.moveTo(1)}} className={classes.link}>{(this.state.displayScores.length-NUM_OF_TRAITS_TO_DISPLAY) +" "+ t("Report - Single - more")}</a></TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table> 
                    </div>
                </a>
                </Paper>
            </Grid>

            <Grid item xs={8} className={classes.formRow}>
                <Grid container>
                    <Grid item xs={12} style={{paddingBottom:'2vh'}}>
                        <SingleReportSummaryRecommendationsBox report={this.state.report} reportParts={this.state.reportParts} moveTo={this.moveTo} invalidReport={this.state.invalidReport}></SingleReportSummaryRecommendationsBox>
                    </Grid>
                    <Grid item xs={4}  style={{paddingInlineEnd:'1vh'}} >
                        <SingleReportSummaryVideoBox report={this.state.report} reportParts={this.state.reportParts} moveTo={this.moveTo}></SingleReportSummaryVideoBox>
                    </Grid>
                    <Grid item xs={8}  style={{paddingInlineStart:'1vh'}} >
                        <SingleReportSummaryTimeBox report={this.state.report} moveTo={this.moveTo} severity={this.state.timeSeverity}></SingleReportSummaryTimeBox>
                    </Grid>
                </Grid>
            </Grid>
            
            
            </Grid>
        </div>
                           
           

    );
  }
}
SingleReportSummary.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportSummary),SingleReportSummary);