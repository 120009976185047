import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from './EgButton';
import * as EmailValidator from 'email-validator';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    actionButton:{
        width: 100,
        margin: 5
    },
    messageHeader:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.secondary.light,
    },
    messageHeaderSuc:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.primary.light,
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
    
})

class GenericInputDialog extends React.Component {
  
    state = {
        open: this.props.open,
        candidate:this.props.candidate,
        emailVal:true,
        reportId: this.props.reportId
    };

    constructor(props) {
        super(props);  
        
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 
        this.validateEmail = this.validateEmail.bind(this)
        this.handleInput = this.handleInput.bind(this)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});         
        }
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };


    validateEmail(event){
        this.setState({emailVal:EmailValidator.validate(event.target.value)})
    }

    close(){
        this.handleClose();
    }

    
    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };
    handleInput(){
        const email = this.state.email;
        const reportId = this.state.reportId;
        this.props.takeAction(email,reportId, this.props.recom)
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
       
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    
                   <Paper elevation={0}>
                       <Grid container justify='left'>
                            
                           <Grid item xs={12} className={classes.messageHeaderSuc}>
                               <Typography variant={'h2'} style={{color:'#043241', textAlign:'center'}}>{this.props.title}</Typography>
                            </Grid>
                           
                            <Grid item xs={12} style={{paddingBottom:20, paddingTop:30}}>
                                <Typography variant={'h4'}>{this.props.message}</Typography> 
                            </Grid>
                            <Grid item xs={12}>
                                <TextField autoComplete="email" name="Email" variant="outlined" required fullWidth='true' id="email" label="" value={this.state.email} onChange={this.handleChange} onBlur={this.validateEmail}
                                    error={this.state.emailVal?false:true}
                                    helperText={this.state.emailVal?"":t('Candidates - Add - Please enter a valid Email')}  
                                    className={classes.signupTxt} 
                                />
                            </Grid>
                            
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton className={classes.actionButton} onClick={this.handleInput}>{t("Reports - Single - Send")}</EgButton> 
                                <EgButton secondary="true" outlined="true" className={classes.actionButton} onClick={this.close}>{t("ErrorDialog - Close")}</EgButton>  
                            </Grid>
                       </Grid>
                   </Paper>
                </DialogContent>
                
                
            </Dialog>
           
            </div>
        );
    }
}

GenericInputDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(GenericInputDialog),GenericInputDialog)))