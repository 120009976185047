import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import EgActivityRecorder from '../../util/EgActivityRecorder';
import LangPicker from '../../util/LangPicker';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import EgButton from '../../components/EgButton';
import Moment from 'react-moment';
import EgHttp from '../../util/EgHttp';
import EgSegmentSlicer from '../../util/EgSegmentSlicer';
import EgSegmentParser from '../../util/EgSegmentParser';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },

    messageHeader:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.secondary.light,
    },
    actionButton:{
        width: 100,
        margin: 5
    },
})

class OngoingRecommendationsDialog extends React.Component {
  
    state = {
        open: this.props.open,
        assess:this.props.assess,
    };

    constructor(props) {
        super(props);  
        this.handleClose = this.handleClose.bind(this);  
    }

    componentDidMount(){
        if (this.props.assess){
            this.initialize(this.props.assess)
        }
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.assess && nextProps.assess!==this.state.assess ){             
            this.setState({assess:nextProps.assess});  
            this.initialize(nextProps.assess)       
        }
    }

    async initialize(assess){
        try{
            const responseparts = await EgHttp.get('/reports/partsFromAssessment/' + assess.id + '/' + LangPicker.current())
            let rowsparts = await responseparts.json();
            this.setState({reportParts:rowsparts})
        }
        catch(err){
            console.error(err.message)
        }
    }

    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.close();
    };

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='75vw'
            >
                <DialogContent
                        name='Engard Employee Ongoing Recommendations Dialog'
                        onMouseEnter={EgActivityRecorder.handleMouseEnter} 
                        mouseenterdata={JSON.stringify({'recommendations dialog':this.props.report?this.props.report.id:0})}
                        onMouseLeave={EgActivityRecorder.handleMouseLeave}
                        style={{paddingBottom:20, width:'75vw', maxWidth:'75vw'}} 
                >
                    
                    <Paper elevation={0} style={{width:'70vw'}}>    
                        <Grid container>
                            <Grid item xs={12} className={classes.boxTitle}>
                                <Typography variant={'h3'}  style={{paddingTop:'2vh', paddingBottom:'0.5vh', fontWeight:500}}>{t('Employee - Ongoing - Recommendations')} </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.boxTitle}>
                                <Typography variant={'h4'}  style={{paddingTop:'2vh', paddingBottom:'1vh', fontWeight:500}}>{t('Employee - Assessment Date')}: <Moment format="MMM DD, YYYY">{this.state.assess?this.state.assess.completed_at:''}</Moment> </Typography>
                            </Grid>
                        </Grid>

                        <Grid container style={{paddingTop:'1vh', paddingBottom:'1vh', paddingInline: 20,display:'flex', justifyContent:'start'}}>
                            <Grid xs={12}>
                                <div className={classes.tableInner} style={{paddingTop:'1vh', paddingBottom:'2vh', cursor: 'pointer'}}  >
                                    
                                    {this.state.reportParts?
                                    this.state.reportParts.map((part, index) => (
                                       
                                        <Grid container style={{paddingTop:'1vh', paddingBottom:'0vh',display:'flex', justifyContent:'start'}}>
                                            <Grid xs={12} style={{paddingInline:10,paddingTop:6,paddingBottom:6, display:'flex', alignItems:'center', justifyContent:'start', minHeight:'4vh'}} >
                                            <EgSegmentSlicer text={EgSegmentParser.parseOngoing(LangPicker.current() === 'en'?part.text:part.report_template_localized[0].text,new Date(this.props.report.completed_at).getMonth()) } />
                                            </Grid>
                                            <Divider style={{width:'90%', marginTop:'0.5vh', marginBottom:'0vh',padding:0,backgroundColor:'#f6f6f6' }}/>
                                        </Grid>  
                                    )):''}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:0}} >
                            <EgButton secondary="true" outlined="true" className={classes.actionButton} onClick={this.handleClose}>{t("ErrorDialog - Close")}</EgButton>  
                        </Grid>
                    </Paper>
                </DialogContent>
                
                
            </Dialog>
            </div>
        );
    }
}

OngoingRecommendationsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(OngoingRecommendationsDialog),OngoingRecommendationsDialog)))