import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination } from '@material-ui/core';
import Moment from 'react-moment';
import _ from 'lodash';
import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EgHttp from '../../util/EgHttp';
import Skeleton from '@material-ui/lab/Skeleton'
import SkeletonTable from '../../components/SkeletonTable';
import { Statuses } from '../../util/constants/AsigneeStatus';
import EgEmptyState from '../../components/EgEmptyState';
import RowActionsHover from '../../components/RowActionsHover';
import AppUtils from '../../util/AppUtils';



const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      width:'100%',
      minHeight:'73vh',
      overflow:'auto'
    },
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:"#272931",
        border:0,
        paddingLeft:10,
        paddingRight:10,
        textAlign:'start',
        borderRight:'1px solid #F0F0F0',
        overflowWrap:'anywhere',
        padding:12
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'1px solid #F0F0F0',
        
    },
    tableHeadCell:{
        paddingTop:5,
        paddingBottom:5,
        fontSize: 12,
        fontWeight:400,
        color:"#5F6066",
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0'
    },
    tableRowInner:{
        minHeight:"6.5vh",
        maxHeight:"6.5vh",
        height:"6.5vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
    statusLabel:{
        border: "solid 1px",
        padding: 5,
        textAlign:'center',
        fontWeight:500,
        fontSize:12,
        color:theme.palette.text.white,
    },
  });
 

class EmployeeTable extends React.Component {
  
    state = {
        open: this.props.open,
        operationIcon: null,
        isEmpty: false,
        overlayDiv: [],
        rows: this.props.rows,
        moreMenuOpen: [],
        assignTestOpen: false,
        loading:true,
        searchTerm:this.props.searchTerm,
        rowsPerPage:10,
        page:0,
        order:'desc',
        orderBy:'status'
    };

    constructor(props) {
        super(props);  
        this.closeAssignTest = this.closeAssignTest.bind(this);  
        this.refresh = this.refresh.bind(this);     
        this.confirmDelete = this.confirmDelete.bind(this)  
        this.closeConfirm = this.closeConfirm.bind(this) 
        this.deleteEmployee = this.deleteEmployee.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.editEmployee = this.editEmployee.bind(this)
        this.openEmployeeCard = this.openEmployeeCard.bind(this)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.rows!==this.state.rows ){             
            this.setState({rows:nextProps.rows, loadiing:false});
        }
        if (nextProps.loading!==this.state.loading ){     
            this.setState({loading:nextProps.loading});           
        }
        if (nextProps.searchTerm!==this.state.searchTerm ){             
            this.setState({searchTerm:nextProps.searchTerm});           
        } 
        if (nextProps.teams && nextProps.teams!==this.state.teams){
            this.setState({teams:nextProps.teams, loading:false})
        }
    }

    openEmployeeCard(index){
        this.props.openEmployee(this.state.rows[index].id)
    }
    closeAssignTest(){
        this.setState({assignTestOpen:false});
        this.setState({assignDialog:null});
    }
    closeConfirm(){
        this.setState({confirmDeleteDialog:null});
    }

    // Open employee form in edit mode
    editEmployee(index){
        this.props.editEmployee(this.state.rows[index].id)
    }

    refresh(code, msg){
        this.props.refresh(code, msg);
    }

    confirmDelete(index){
        const { t } = this.props; 
        this.setState({deleteIndex:index})
        this.setState({confirmDeleteDialog: <ConfirmationDialog title={t("Employees - Table - Delete Employee")} message={t("Employees - Table - Are you sure")}  confirm={this.deleteEmployee} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }

    // Delete Employees
    async deleteEmployee(){
        const { t } = this.props;  
        this.setState({confirmDeleteDialog:null}); 
        const id = this.state.rows[this.state.deleteIndex].id
        let response = await EgHttp.delete('/employees/' + id) 
        if (response.status===200){
            _.remove(this.state.rows, {
                id: id
            });
        }
        this.setState({overlayDiv:[]})
        this.props.refresh("SUCCESS", t('Employees - Table - Employee was deleted successfully'));
        EventLog.insert(this.props.admin.org.id, id, EventLog.DELETE_EMPLOYEE, this.props.admin.account.id)
        return;   
    }
   
    setHover(e, onOff, index){
        const { t } = this.props;
        let item, items = []
        const submenuActions=[  {text:t('Employees - Table - Edit Employee'), action:this.editEmployee, disabled:false},
                                {text:t('Employees - Table - Delete Employee'), action:this.confirmDelete, disabled:false},
                             ]
        if (onOff){ 
            item = <RowActionsHover mainActionName={t('Employees - Table - Employee Card')} mainAction={this.openEmployeeCard} rowIndex={index} submenuActions={submenuActions} rowsPerPage={this.state.rowsPerPage} page={this.state.page}/>
        }
        else{
            item = null;
            this.setState({moreMenuOpen:[]});
        }
        items[index] = item;
        this.setState({overlayDiv:items});
    }

    handleChangePage(event, newPage){
        this.setState({page:newPage})
    }

    handleChangeRowsPerPage(event){
        this.setState({rowsPerPage: parseInt(event.target.value, 10)})
    }

    createSortHandler = property => event => {
        this.onRequestSort(event, property);
    }

    onRequestSort = (event, property) => {
        
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }    
        this.setState({ order, orderBy });       
    }

    getTeamNameFromId(team){
        
        if (!this.state.teams || !team) return '';
        const teamObjInd = this.state.teams.findIndex(({ id }) => id === team.team_id)
        if (teamObjInd > -1){
            return this.state.teams[teamObjInd].name
        }
        else{
            return ''
        }
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const assigStatuses = Statuses.get()
        return (
        <div style={{position:'relative'}}>

        <Grid container spacing={3} style={{}}>
            
            <Grid item xs={12 } style={{height:'100%'}}>
                
                {this.state.loading || (this.state.rows && this.state.rows.length)?
                <div>

                <Paper className={classes.paper} style={{height:'75vh'}}>
                    
                <Table className={classes.table} aria-label="simple table" >
                    <TableHead>
                        <TableRow> 
                            <TableCell key={'firstname'} sortDirection={this.state.orderBy === 'firstname' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'18%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('firstname')} active={this.state.orderBy === 'firstname'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Candidates - Table - Name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'position'} className={classes.tableHeadCell} align="start" style={{width:'17%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('position')} active={this.state.orderBy === 'position'}>    
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Candidates - Table - Position')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'team'} sortDirection={this.state.orderBy === 'team' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'17%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('team')} active={this.state.orderBy === 'team'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Teams - Table - Name')}
                                </TableSortLabel>
                            </TableCell>
                           
                            <TableCell key={'initial_assessment_date'} sortDirection={this.state.orderBy === 'initial_assessment_date' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'10%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('initial_assessment_date')} active={this.state.orderBy === 'initial_assessment_date'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Employees - Table - Initial Assessment Date')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'last_self_review'} sortDirection={this.state.orderBy === 'last_self_review' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'10%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('last_self_review')} active={this.state.orderBy === 'last_self_review'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Employees - Table - Last Survey Date')}
                                </TableSortLabel>
                            </TableCell>
                            
                            <TableCell key={'status'} sortDirection={this.state.orderBy === 'status' ? this.state.order : false} className={classes.tableHeadCell} align="left" style={{width:'13%'}}>
                                    <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('status')} active={this.state.orderBy === 'status'}>
                                        {this.state.loading?<Skeleton style={{width:100}}/>:t('Assigned - Table - Status')}
                                    </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} style={{width:'15%'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody >
                    {
                    this.state.loading?
                    <TableRow key={'skeleton1'} className={classes.tableRowBody} >
                        <TableCell className={classes.tableInnerCell} colSpan={6}>
                            <SkeletonTable style={{width:'100%'}}></SkeletonTable>
                        </TableCell>
                    </TableRow>
                    :
                    _.slice(this.state.rows.sort(AppUtils.getSorting(this.state.order, this.state.orderBy)),this.state.page*this.state.rowsPerPage, (this.state.page+1)*this.state.rowsPerPage).map((row, index) => (
                        
                        <TableRow key={row.id} className={classes.tableRowBody} >
                            <TableCell className={classes.tableInnerCell} colSpan={7} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                <div>
                                    <Table className={classes.tableInner} aria-label="inner table" >
                                    <TableBody >
                                        <TableRow key={row.name + "in"} className={classes.tableRowInner} >
                                            
                                            <TableCell className={classes.tableCell} style={{width:'18%'}}> {row.firstname+ " " + row.lastname}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'17%'}}>{_.truncate(row.position,{'length': 28})}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'17%'}}>{this.getTeamNameFromId(row.team_members[0])}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'10%'}}>{row.initial_assessment_date?<Moment format="MMM DD, YYYY">{row.initial_assessment_date}</Moment>:'-'}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'10%'}}>{row.last_self_review?<Moment format="MMM DD, YYYY">{row.last_self_review}</Moment>:'-'}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'13%'}}>
                                                <div className={classes.statusLabel} style={{backgroundColor:assigStatuses[assigStatuses.findIndex(({ id }) => id === row.status)].color,width:'100%' } }>{assigStatuses[assigStatuses.findIndex(({ id }) => id === row.status)].name}</div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'15%',borderRight:0}}></TableCell>
                                        </TableRow>
                                    </TableBody >
                                    </Table>
                                   
                                </div>
                                {this.state.overlayDiv[index]}
                                {this.state.moreMenuOpen[index]}   
                            </TableCell>
                        </TableRow>   
                    ))}
                    </TableBody>
                </Table>
                
                </Paper>
                <TablePagination component="div"
                                count={this.state.rows?this.state.rows.length:0}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                labelRowsPerPage={t('General - Rows per page')} 
                                >
                </TablePagination>
                </div>
                :
                <EgEmptyState title={t('Employees - Table - No Employees Found')} emptyAction={this.props.addNewEmployee} emptyActionTxt={t('Employees - Table - Add New Employee')} />  
                }
            </Grid>  
        </Grid>
        {this.state.confirmDeleteDialog}
        {this.state.assignDialog}
        </div>      
      
        );
    }
}
EmployeeTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(EmployeeTable),EmployeeTable)))