import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {  Paper, Typography } from '@material-ui/core';
import AnswerRadios from './AnswerRadios';
import EnProgressStatic from '../../components/EnProgressStatic';
import AnswerChecks from './AnswerChecks';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import AnswerSlider from './AnswerSlider';
import AnswerPrioritze from './AnswerPrioritze';
import AnswerDistribute from './AnswerDistribute';
import AssessmentFinished from './AssessmentFinished';
import LangPicker from '../../util/LangPicker';
import AppUtils from '../../util/AppUtils';
import EgActivityRecorder from '../../util/EgActivityRecorder';
import EgWaitDialog from '../../components/EgWaitDialog';
import NextStepScreen from './NextStepScreen';

const styles = theme => ({
    root: {
        display: 'flex',
        width:'100%'
    },  
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'50vh',
    },
    error:{
        fontSize:16,
        color:"#F15D5D",
        fontWeight: 500,
        textAlign:'center'
    }, 
});

const EXTRA_TIME = 10
 
class QustionPanel extends React.Component {
  
    state = {
        answerSelected:null,
        errorMsg:null,
        testOver:false,
        candidate: this.props.candidate,
        requireVideo: false,
        cameraVideoTrack: null,
        recording: false,
        question: null,
        prevThrottle: 0,
        isRemote:null
    };

    constructor(props) {
        super(props);  
       
        this.nextQuestion = this.nextQuestion.bind(this);  
        this.lastQuestion = this.lastQuestion.bind(this);  
        this.setSelected = this.setSelected.bind(this);
        this.startVideoPlayback = this.startVideoPlayback.bind(this)
        this.handlePrevQuestion = this.handlePrevQuestion.bind(this)
        this.handleNextQuestion = this.handleNextQuestion.bind(this)
        this.moveon = this.moveon.bind(this)

        if (props.requireVideo) {
            this.setState({requireVideo: true})
        }
    }

    startVideoPlayback(cameraVideoTrack) {
        console.log('Start video playback in questionnaire')
        cameraVideoTrack.play("local-player");
    }

    componentDidMount() {
        if (this.props.requireVideo && !this.state.testOver) {
            console.log('SHOW VIDEO AND START RECORDING ASSESSMNET QUESTIONS')
            this.startVideoPlayback(this.props.cameraVideoTrack)
            this.props.startRecording()
        } else {
            const debugMsg = this.state.testOver ? 'Not recording, ASSESSMENT IS OVER' : 'Not recording, video not required'
            console.log(debugMsg)
        }
        this.setState({isWaitingDialog:<EgWaitDialog open={true} />})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.question && nextProps.question!==this.state.question ){  
            // Assessment is over !
            if (nextProps.question.id === -1){
                console.log('ASSESSMENT QUESTIONS ARE OVER')
                this.setState({question:nextProps.question}); 
                this.setState({testOver:true})
                this.props.moveToNextAssessStep()
                return
            }
            this.setState({question:nextProps.question});  
            this.setState({isWaitingDialog:null})
            if (nextProps.question.response && nextProps.question.response.length>0){  
                this.setState({answerFromDB:nextProps.question.response[0].selected_answers});
                this.setState({answerSelected:nextProps.question.response[0].selected_answers});
                //TODO: remove all the refs from here
                if (this.refs.radios) this.refs.radios.setSelection(nextProps.question.response[0].selected_answers);  
                if (this.refs.checks) this.refs.checks.setSelection(nextProps.question.response[0].selected_answers);
                if (this.refs.prior) this.refs.prior.setSelection(nextProps.question.response[0].selected_answers);
                if (this.refs.distrib) this.refs.distrib.setSelection(nextProps.question.response[0].selected_answers);
            }
            if (nextProps.question.pause) this.props.updateTimeLeft(nextProps.question.limit_time)
        }  
        if  (nextProps.assessmentState && nextProps.assessmentState!==this.state.assessmentState ){             
            this.setState({assessmentState:nextProps.assessmentState}); 
            if (nextProps.assessmentState.total_index>=nextProps.assessmentState.total_questions){
           }    
        } 
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});
        } 
        if (nextProps.isRemote!==this.state.isRemote ){             
            this.setState({isRemote:nextProps.isRemote});
        } 
    }

    nextQuestion(){
        const { t } = this.props;
        if (this.state.answerSelected !== null) {
            const now = new Date().getTime();
            if (now - this.state.prevThrottle > 1500) {
                this.setState({ prevThrottle: now })
                this.props.nextQuestion();
            } else {
                console.error('Ignoring response as it took too fast to answer')
            }
            this.clearSelected()
        }
        else {
            this.setState({ errorMsg: <Typography className={this.props.classes.error}>{t('Assessment - Questions - You need to select an answer')}</Typography> });
        }
    }

    handleNextQuestion(event) {
        event.target.attributes.clickdata = {}
        event.target.attributes.clickdata.value = JSON.stringify({question_id: this.state.question ? this.state.question.id : -1})
        EgActivityRecorder.handleClickOrTap(event)
        this.nextQuestion()
    }

    handlePrevQuestion(event) {
        event.target.attributes.clickdata = {}
        event.target.attributes.clickdata.value = JSON.stringify({question_id: this.state.question ? this.state.question.id : -1})
        EgActivityRecorder.handleClickOrTap(event)
        this.lastQuestion()
    }

    lastQuestion(){
        this.props.lastQuestion();
        this.clearSelected()
    }

    setSelected(ans, ansArr){
        this.setState({answerSelected:ans, ansArrSelected: ansArr})
        this.props.setAnswer(ansArr);
    }
    clearSelected(){
        this.setState({answerSelected:null});
        if (this.refs.radios) this.refs.radios.clearSelection();
        if (this.refs.checks) this.refs.checks.clearSelection();
        if (this.refs.slide) this.refs.slide.clearSelection();
        this.setState({errorMsg:null});
    }
    moveon(){
        const question = this.state.question
        question.pause = false
        this.setState({question})
        this.props.updateTimeLeft(this.state.question.limit_time)
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        let questionText = null;
        let questionSubtext = null; 
        let availAnswers = null;
        let isMultiSelect = false;
        let questionType = null;
        
        if (this.state.question )
        {
            if (LangPicker.current()==='en'){
                questionText = this.state.question.text
                questionSubtext = this.state.question.subtext?this.state.question.subtext:"";
                availAnswers = this.state.question.answer.answer_list
            }
            else{
                questionText = this.state.question.question_localized&&this.state.question.question_localized[0]?this.state.question.question_localized[0].text:this.state.question.text;
                questionSubtext = this.state.question.question_localized&&this.state.question.question_localized[0]?this.state.question.question_localized[0].subtext:this.state.question.subtext;
                availAnswers = this.state.question.answer_localized&&this.state.question.answer_localized[0]?this.state.question.answer_localized[0].answer_list:this.state.question.answer.answer_list
            }
            isMultiSelect = this.state.question.multi_select
            questionType = this.state.question.qtype
        }

        return (
            <Paper className={classes.paper} style={{maxHeight:'90vh', overflow:AppUtils.isMobile()?'auto':'', direction:this.state.question && this.state.question.trait && this.state.question.trait[0]===5003?'ltr':''}}>
                {!this.state.testOver?
                this.state.question && this.state.question.pause?
                <NextStepScreen type={'test'} moveon={this.moveon} minutes={this.state.question.limit_time}/>:
                <table style={{height:'100%', width:'100%'}}>
                    <tr>
                        <td style={{verticalAlign:'top'}}>
                            <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:10}}>
                                    <Grid item xs={12} justify="left" style={{ borderBottom: '1px solid #E4E4E4', position: 'relative' }}>
                                        <Typography variant={'h2'} style={{ paddingBottom: 10 }}>{t('Assessment - Questions - Question')} {this.state.assessmentState? this.state.assessmentState.total_index + 1:'-'}</Typography>
                                            {this.props.requireVideo && !AppUtils.isMobile()?
                                                LangPicker.direction() === 'rtl'?
                                                    <div id="local-player" style={{ textAlign: 'end', position: 'absolute', top: '-8vh', left: -200, width: '16vw', height: '19vh', display: 'flex', flexDirection: 'column', flex: 1 , border:'5px solid #ffffff', borderRadius:5}}>
                                                    </div>:
                                                    <div id="local-player" style={{ textAlign: 'end', position: 'absolute', top: '-8vh', right: -200, width: '16vw', height: '19vh', display: 'flex', flexDirection: 'column', flex: 1 , border:'5px solid #ffffff', borderRadius:5}}>
                                                    </div>
                                            :''}
                                    </Grid>

                                    <Grid item xs={AppUtils.isMobile()?0:2}></Grid>
                                    <Grid item xs={AppUtils.isMobile()?12:8} style={{paddingBottom:'0vh', paddingTop:'3vh'}}>
                                    <Grid container  justify="left" direction="row">
                                        <Grid item xs={12} style={{paddingBottom:'1vh'}}>
                                            <Typography variant={'h3'} >{questionText}</Typography>  
                                        </Grid>   
                                        <Grid item xs={12} style={{paddingBottom:'2vh'}}>
                                            <Typography variant={'h3'}>{questionSubtext}</Typography> 
                                        </Grid>
                                        <Grid item xs={12} style={{paddingBottom:'2vh'}}>
                                            {
                                            questionType === 4?
                                            <Grid container>
                                                <Grid xs={12}>
                                                    <AnswerPrioritze nextButton={this.nextQuestion} availAnswers={availAnswers} answerFromDB={this.state.answerFromDB} setSelected={this.setSelected} ref="prior"/>
                                                </Grid>
                                            </Grid>
                                            :questionType === 5?
                                            <Grid container>
                                                <Grid xs={12}>
                                                    <AnswerDistribute nextButton={this.nextQuestion} availAnswers={availAnswers} answerFromDB={this.state.answerFromDB} setSelected={this.setSelected} ref="distrib"/>
                                                </Grid>
                                            </Grid>
                                            :questionType === 6?
                                                <div style={{paddingTop:20,direction:'ltr'}} >
                                                    <AnswerSlider availAnswers={availAnswers} answerFromDB={this.state.answerFromDB} setSelected={this.setSelected} ref="slide"/>
                                                </div>
                                            :isMultiSelect?
                                                    <AnswerChecks availAnswers={availAnswers} setSelected={this.setSelected}  ref="checks"/>
                                                    :
                                                    <AnswerRadios nextButton={this.nextQuestion} 
                                                                    availAnswers={availAnswers} 
                                                                    answerFromDB={this.state.answerFromDB} 
                                                                    setSelected={this.setSelected} 
                                                                    ref="radios" 
                                                                    qid={this.state.question ? this.state.question.id : -1}/>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={AppUtils.isMobile()?0:2} ></Grid>
                            </Grid>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:10}}>
                                <Grid item xs={12} justify="center">
                                    {this.state.errorMsg}
                                </Grid>
                                <Grid item xs={2} style={{display:"flex", justifyContent:'start', }}>
                                    <EgButton   secondary="true" outlined="true"  
                                                disabled={!this.state.assessmentState?true:this.state.assessmentState.total_index===0} 
                                                onClick={this.handlePrevQuestion}
                                    >
                                        {t('Assessment - Questions - Back')}  
                                    </EgButton>
                                </Grid>
                                <Grid item xs={8} style={{display:"flex", justifyContent:'center', paddingTop:8}}>
                                    <EnProgressStatic value={this.state.assessmentState?(this.state.assessmentState.total_index+1)/this.state.assessmentState.total_questions*100:0} nopercent style={{paddingInlineStart:10}}></EnProgressStatic>
                                    {AppUtils.isMobile?'':<Typography vairant={'h4'} >{t('Assessment - Questions - Questions answered')}</Typography>}
                                </Grid>
                                <Grid item xs={2} style={{display:"flex", justifyContent:'end', }}>
                                    <EgButton 
                                        onClick={this.handleNextQuestion}
                                        onMouseEnter={EgActivityRecorder.handleMouseEnter}
                                        mouseenterdata={JSON.stringify({question_id: this.state.question ? this.state.question.id : -1})}
                                        onMouseLeave={EgActivityRecorder.handleMouseLeave}
                                        mouseleavedata={JSON.stringify({question_id: this.state.question ? this.state.question.id : -1})}
                                    >
                                        {t('Assessment - Questions - Next')}
                                    </EgButton>
                                </Grid>         
                            </Grid>
                        </td>
                    </tr>
                </table>
                :
                this.state.isRemote?
                    <NextStepScreen />:
                    <AssessmentFinished candidate={this.state.candidate} />
            }
            {this.state.isWaitingDialog}
            </Paper> 
        );
    }
}
QustionPanel.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(QustionPanel),QustionPanel);


