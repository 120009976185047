import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgAssessmentValidity from '../../components/EgAssessmentValidity';
import {ValidityLevels} from '../../util/constants/ValidityLevels'

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    insightBox:{
        border:'1px solid #E4E4E4',
        height:'30vh',
        overflow:'hidden'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    }
    
  });

class SingleReportValidityBox extends React.Component {
    
    /*
    constructor(props) {
        super(props);           
    }*/

    state = {
        report: this.props.report?this.props.report:{},
        traitsTimes: []
    };

    componentDidMount(){
        if (this.props.report && this.props.report.assessment){
            this.setState({report: this.props.report})
           
        }
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report !== this.state.report){
            this.setState({report:nextProps.report});
            this.setState({report: this.props.report})
           
        }
        if(nextProps.severity && nextProps.severity !== this.state.severity){
            this.setState({severity: nextProps.severity})
        }
    }
      
   

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        console.log(this.state.report)

        return (
            <Paper elevation={0} style={{ overflow:'hidden'}} className={classes.insightBox} >
               
                <Grid container>
                    <Grid xs={12} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Reports - Single - Assessment Validity')} </Typography>
                    </Grid>
                    
                </Grid>
                <Grid xs={12} style={{paddingTop:'4vh', paddingInline:20}}>
                        <EgAssessmentValidity severity={this.state.report&&this.state.report.validity_obj?this.state.report.validity_obj.overallRisk:ValidityLevels.UNKNOWN} />
                </Grid>
                <Grid xs={12} style={{paddingTop:'2vh'}}>
                        <Typography variant={'h4'} style={{textAlign:'start', paddingInline:20}} >{ValidityLevels.getSummaryText(this.state.report&&this.state.report.validity_obj?this.state.report.validity_obj.overallRisk:ValidityLevels.UNKNOWN)} </Typography>
                </Grid>

                <Grid  xs={12} style={{paddingTop:'4vh'}}> 
                    {this.state.invalidReport?
                        '':<Typography variant={'h4'} style={{textAlign:'start', paddingInline:20}} ><a href="#0" onClick={()=>{this.props.moveTo(3)}} className={classes.link}>{ t("Report - Single - more info")}</a></Typography>
                    }
                </Grid> 
              
            </Paper>
        );
    }
}
SingleReportValidityBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportValidityBox),SingleReportValidityBox);