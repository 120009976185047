import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles  } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Topbar from '../components/Topbar'
import { Grid, Typography } from '@material-ui/core';
import EgProgress from '../components/EgProgress';
import { withRouter } from 'react-router-dom';
import SingleReport from './reports/SingleReport';
import { withTranslation } from 'react-i18next';
import * as AppConstants from '../util/constants/AppConstants';
import EgStatusAlert from '../components/EgStatusAlert';
import EgHttp from '../util/EgHttp';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import NavigationManager from '../managers/NavigationManager';
import ExtendedNavBar from '../components/ExtendedNavBar';
 
const drawerWidth = AppConstants.NavigationDrawerWidth;

const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%',   
  },
  
  appRight:{
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      background:"#FAFAFA",
    },
    marginRight: drawerWidth
  },
  appLeft:{
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      background:"#FAFAFA",
    },
    marginLeft: drawerWidth
  },
 
  toolbar:{
    minHeigt:64,
    marginTop:50
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width:'100%',
    height:window.innerHeight,

  },
  
    note:{
      marginTop:20,
    },
    latestNote:{
      marginTop:20,
      
    },
   
    fabProgress: {
      backgroundColor: '#0097A7AA', 
    },
    
});

class Analytics extends React.Component {
    state = {
      open: this.props.open,
      drawerState: null,
      operationIcon: null,
      singleReportOpen: false,
      openReportId:null,
      rows:[],
      statusBar:null,
      loading:<EgProgress />,
    };

    constructor(props) {
        super(props);    
        
        if (!this.props.login.logged) {
          this.props.history.push('/signup') 
        }  
        else{
            NavigationManager.setLast(this.props, '/reports')
        }
        this.openReport = this.openReport.bind(this); 
        this.getReports = this.getReports.bind(this); 
        this.refresh = this.refresh.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this);
        this.search = this.search.bind(this);
        this.closeReport = this.closeReport.bind(this)

        this.getReports()
    }

    async getReports(){
        try{
          this.setState({loading:<EgProgress />})
          let response = await EgHttp.get('/reports/all');
          if (response.status === 401) {
            this.props.history.push('/signin') 
            return;
          }
          let rowsres = await response.json();
          const origres = [...rowsres]
          this.setState({rows:rowsres, loading:null});
          this.setState({originalRows:origres});
          return;
        }
        catch(err){
          console.error(err)
          return
        }
    }

    filterItems(arr, query) {
      return arr.filter(function(el) {
        return (el.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1) || (el.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      })
    }

    search(term){
      this.setState({searchTerm: term})
      if (term && term.length>0){
        let filteredRows;
        filteredRows = this.filterItems(this.state.originalRows, term)
        this.setState({rows: filteredRows})
      }
      else{
        this.setState({rows: this.state.originalRows})
      }
    }

    handleStatusClose(){
        this.setState({statusBar:null})
    }

    showStatusBar(message){
      this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }

    refresh(status, message){
        this.getReports()
        if (status === 'SUCCESS'){
            this.showStatusBar(message)
        }
    }

    openReport(id){
        this.setState({openReportId:id}, ()=> {
          this.setState({singleReportOpen:true})
        })
    }

    closeReport(){
      this.setState({singleReportOpen:false})
      this.setState({openReportId:null})
      this.getReports()
    }
   

   
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        
    return (
        this.state.singleReportOpen?
        <div className={classes.root} >
          <CssBaseline />
          <ExtendedNavBar navPos={7}></ExtendedNavBar>
          <SingleReport openState={this.state.singleReportOpen} reportId={this.state.openReportId} refresh={this.refresh} close={this.closeReport}></SingleReport> 
        </div>
        :
        <div className={classes.root} >
          <CssBaseline />
          <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} style={{boxShadow:"0px 0px 0px" }}>
              {this.state.loading}
              <Toolbar >
                  <Topbar login={this.props.login} page={this.props.page} search={this.search}></Topbar>
              </Toolbar>
          </AppBar>

          <ExtendedNavBar navPos={7}></ExtendedNavBar>

          <main className={classes.content}>
              <div className={classes.toolbar} />
            
              <Grid container fullwidth='true' justify="center" direction="row" alignItems="flex-start">
            
              <Grid item xs={12 }>
                
                <Grid container  justify="start" direction="row" alignItems="flex-start">
                    <Grid item xs={6} justify="start">
                      <Typography variant="h1"> {t('Reports - Reports')} </Typography>
                    </Grid>
                    <Grid item xs={6} justify="end" style={{textAlign:"end"}} >
                          
                    </Grid>
                  
                    <Grid item xs={12} className={classes.latestNote}>
                        <Typography variant="h2" style={{textAlign:'center', backgroundColor:'#efefef',padding:10 }}> {t('Reports - Coming Soon')} </Typography>
                    </Grid>
                    
                </Grid>
              </Grid>
              
            
              </Grid>
          </main>
       
          {this.state.statusBar}
          </div>
    
    );
}}

Analytics.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default withTranslation()(withCookies(withRouter((withStyles(styles))(Analytics),Analytics)))
