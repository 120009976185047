import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, Tooltip } from '@material-ui/core';
import csvIcon from '../../img/csv_import.svg';
import cvimg from '../../img/resume.png';
import ghIcon from '../../img/greenhouse_import.png';
import icimsIcon from '../../img/icims_import.png';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import UploadDialog from './UploadDialog';
import UploadCVDialog from './UploadCVDialog';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    importOptionPaper:{

        display: "flex",
        height: 180,
        justifyContent: "center",
        alignItems: "center",
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: '#efefef',
        }
    },
    importIcon:{
        width:80,
        height:80,
    },
    
    tooltip:{
        fontSize: 16,
        color:theme.palette.text.white,
        padding:10,
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width: 150,
        margin: 5
    },
});
  
class ImportCandidates extends React.Component {
  
    constructor(props) {
        super(props);            
        this.closeDrawer = this.closeDrawer.bind(this);   
        this.openAddNew = this.openAddNew.bind(this);  
        this.openUploadCSV = this.openUploadCSV.bind(this);
        this.handleCloseUpload = this.handleCloseUpload.bind(this);    
        this.refresh = this.refresh.bind(this);  
        this.openUploadCV = this.openUploadCV.bind(this)   
    }

    state = {
        addOpen: this.props.addOpen,
        uploadOpen: false,
        uploadDialog: null,
    };

    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){             
            this.setState({addOpen:nextProps.openState});           
        }
    }
  
    closeDrawer(){
        this.setState({addOpen:false})
        this.props.handleDrawerState()
    }
    
    openAddNew(){
        this.setState({addOpen:false})
        this.props.handleDrawerState(true)
    }

    openUploadCSV(){
        this.setState({uploadOpen:true});
        this.setState({uploadDialog:<UploadDialog open={true} closeDialog={this.handleCloseUpload} refresh={this.refresh} ></UploadDialog>})
    }
    openUploadCV(){
        this.setState({uploadOpen:true});
        this.setState({uploadDialog:<UploadCVDialog open={true} closeDialog={this.handleCloseUpload} refresh={this.refresh} teams={this.props.teams} positions={this.props.positions}/>})
    }
    
    handleCloseUpload(){
        this.setState({uploadOpen:false});
        this.setState({uploadDialog:null})
    }
    refresh(code, msg){
        this.closeDrawer()
        this.props.refresh(code, msg)
    }

    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>
            <React.Fragment key={'right'}>
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:600}}>
            <div style={{width:600}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={7} justify="left">
                                <Typography variant={'h1'}> {t('Candidates - Import - Import Candidates')} </Typography>
                            </Grid>
                            <Grid item xs={5} justify="end" style={{textAlign:"end"}} >
                                    <EgButton secondary="true" outlined="true" className={classes.actionButton} onClick={this.openAddNew}>{t('Candidates - Import - Add New')} </EgButton>       
                            </Grid>
                             <Grid item xs={6} className={classes.formRow} style={{paddingTop:30}}>
                                <Typography variant={'h5'}>{t('Candidates - Import - From CSV')}</Typography>
                                <a href='#0'>
                                <Paper className={classes.importOptionPaper} onClick={this.openUploadCSV}> 
                                    <img src={csvIcon} className={classes.importIcon} alt="logo" />  
                                </Paper>
                                </a>
                            </Grid>
                           
                            <Grid item xs={6} className={classes.formRow} style={{paddingTop:30}}>
                                <Typography variant={'h5'}>{t('Candidates - Import - Upload CVs')}</Typography>
                                <a href='#0'>
                                <Paper className={classes.importOptionPaper} onClick={this.openUploadCV}> 
                                    <img src={cvimg} className={classes.importIcon} alt="logo" />  
                                </Paper>
                                </a>
                            </Grid>
                            <Grid item xs={6} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Candidates - Import - From Greenhouse')}</Typography>
                                <Paper className={classes.importOptionPaper}> 
                                <Tooltip title={<React.Fragment><Typography color="inherit" className={classes.tooltip}>{t('Candidates - Import - Coming Soon')}</Typography></React.Fragment>}>
                                <img src={ghIcon} className={classes.importIcon} alt="logo" /> 
                                </Tooltip> 
                                
                                </Paper>
                            </Grid>
                            <Grid item xs={6} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Candidates - Import - From iCIMS')}</Typography>
                                <Paper className={classes.importOptionPaper}> 
                                <Tooltip title={<React.Fragment><Typography color="inherit" className={classes.tooltip}>{t('Candidates - Import - Coming Soon')}</Typography></React.Fragment>}>
                                
                                <img src={icimsIcon} className={classes.importIcon} alt="logo" />  
                                </Tooltip>
                                </Paper>
                            </Grid>
                           
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton outlined="true" secondary="true" className={classes.actionButton} onClick={this.closeDrawer}>{t('Candidates - Import - Cancel')}</EgButton>   
                            </Grid>
                            
                        </Grid>
                </Paper>
            </div>
            </Drawer>
            {this.state.uploadDialog}
            </React.Fragment>
        </div>     
    );
  }
}
ImportCandidates.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(ImportCandidates),ImportCandidates);