import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgBarChart from '../../components/EgBarChart';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import EgEmptyState from '../../components/EgEmptyState';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    insightBox:{
        border:'1px solid #E4E4E4',
        height:'35vh',
        overflow:'hidden'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    
    invalid:{
        backgroundColor: "#EA555533",
        marginTop:'3vh',
        marginBottom:'3vh',
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
    
  });

const INITIAL_ASSESS_COMPLETED = 2
const INITIAL_ASSESS_INVITED = 1
const INITIAL_ASSESS_NOT_INVITED = 0
  
class EmployeeCardSummaryStats extends React.Component {
  
    constructor(props) {
        super(props);    
        this.initialize = this.initialize.bind(this)     
    }

    state = {
        employee: this.props.employee?this.props.employee:{},
        graphData:[],
        boundaries:[]
    };

    componentDidMount(){
        if (this.props.employee){
            this.setState({employee: this.props.employee})
            this.setState({noInitial: this.props.noInitial})
            this.initialize(this.props.employee)
        }
        if (this.props.employee && this.props.employee.assessment && this.props.employee.assessment.length>0){
            this.props.employee.assessment[0].completed_at? this.setState({intitalState:INITIAL_ASSESS_COMPLETED}): this.setState({intitalState:INITIAL_ASSESS_INVITED})
        }
        else{
             this.setState({intitalState:INITIAL_ASSESS_NOT_INVITED})
        }
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.employee && nextProps.employee !== this.state.employee){
            this.setState({employee:nextProps.employee});
            this.initialize(nextProps.employee)
        }
    }

    initialize(employee){
        if (employee){
            const reports = employee.report
            const sortedReports = reports.sort((a,b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()); 
            const thisRef = this
            let boundaries = null
            const graphData = sortedReports.map(function(oneRep){
                if (oneRep.assessment.type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW){
                    const ind = thisRef.getEngagementScoresIndex(oneRep.assessment.assessment_scores)
                    if (!boundaries) boundaries = oneRep.assessment.assessment_scores[ind].trait.boundaries 
                    const engagementScore = oneRep.assessment.assessment_scores[ind].trait_adjusted_score
                    const assessDate = (new Date(oneRep.created_at).getMonth()+1) + "/" + `${new Date(oneRep.created_at).getFullYear()}`.substring(2)
                    return ({x:assessDate, y:engagementScore})
                }
                return null
            })
            const cleanGraphData = graphData.filter(function(e){return e});
            this.setState({graphData: cleanGraphData})
            this.setState({boundaries})
        }
    }

    getEngagementScoresIndex(scores){
        const ind = scores.findIndex(({trait}) => trait.name==='Engagement')
        return ind
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        

        return (
            <Paper elevation={0} style={{ overflow:'hidden',height:'35vh'}} className={classes.insightBox} >
                <Grid container>
                    <Grid xs={12} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Employees - Single - Engagement Index')} </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'1vh', paddingInline:20}}>
                    <Typography variant={'h5'} style={{textAlign:'start'}}>{ t('Employee - Summary - Overall employee engagement level over time')}</Typography>
                </Grid>
                {this.state.noInitial || this.state.intitalState !== INITIAL_ASSESS_COMPLETED?
                <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'0vh', paddingLeft:10, paddingRight:10}}>
                    <EgEmptyState title={t('Employee - Summary - No Initial Assessment')} size={'small'} />
                </Grid>:
                !this.state.graphData || this.state.graphData.length===0?
                <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'0vh', paddingLeft:10, paddingRight:10}}>
                    <EgEmptyState title={t('Employee - Summary - No Engagement Survey Historty')} size={'small'} />
                </Grid>:
                <Grid container style={{paddingTop:'2vh', paddingBottom:'2vh'}}>
                    <Grid xs={12} >
                        <Paper elevation={0} style={{height:'21vh'}}>
                            <EgBarChart value={this.state.graphData} boundaries={this.state.boundaries} showWarnings={'true'} warnTitle={t('Employee - Single - Low Engagement Warning')} warnBody={t('Employee - Single - Employee shows low engagement')}></EgBarChart>
                        </Paper>
                    </Grid>
                    
                </Grid> 
                 }
            </Paper>
        );
    }
}
EmployeeCardSummaryStats.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(EmployeeCardSummaryStats),EmployeeCardSummaryStats);