import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from './en/en.json'
import translationsHE from './he/he.json'
import translationsES from './es/es.json'
import translationsEL from './el/el.json'
import translationsPT from './pt/pt.json'

const resources = {
  en: {
    translation: translationsEN
  },
  he: {
    translation: translationsHE
  },
  es: {
    translation: translationsES
  },
  pt: {
    translation: translationsPT
  },
  el: {
    translation: translationsEL
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en', // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;