import i18n from '../../i18n/i18n'

export const AssessmentTypes = {

    get: function(){
    return(
        [
            {id:1, name: i18n.t('Candidates - Assign - Assessment Type - Initial'), color:"#11D6A7", employeeOnly:false},
            {id:11, name: i18n.t('Candidates - Assign - Assessment Type - Risk Only'), color:"#FCBF54", employeeOnly:false},
            {id:12, name: i18n.t('Candidates - Assign - Assessment Type - Personality Only'), color:"#FCBF54", employeeOnly:false},
            {id:13, name: i18n.t('Candidates - Assign - Assessment Type - Skills Tests'), color:"#FCBF54", employeeOnly:false},
            {id:2, name: i18n.t('Candidates - Assign - Assessment Type - Employee Ongoing'), color:"#F15D5D", employeeOnly:true},
            {id:3, name: i18n.t('Candidates - Assign - Assessment Type - Manager Ongoing'), color:"#F15D5D", employeeOnly:true},
            {id:4, name: i18n.t('Candidates - Assign - Assessment Type - Remote Interview'), color:"#EEEEEE",employeeOnly:false},
            {id:-1, name: i18n.t('Candidates - Assign - Recected Candidates'), color:"#EEEEEE",employeeOnly:false},
        ]
    )},

    getNameById(tid){
        const allTypes = this.get()
        const ind = allTypes.findIndex(({id}) => id===tid)
        if (ind>-1){
            return allTypes[ind].name
        }
    },
    

    INITIAL_RISK_AND_PERSONALITY_ASSESSMENT: 1,
    EMPLOYEE_ONGOING_REVIEW: 2,
    MANAGER_ONGING_REVIEW: 3,
    REMOTE_INTERVIEW_ONLY: 4,
    INITIAL_RISK_ONLY_ASSESSMENT:11,
    INITIAL_PERSONALITY_ONLY_ASSESSMENT:12,
    INITIAL_SKILLS_ONLY_ASSESSMENT:13,
}

