import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';

const styles = theme => ({
    root: {
        display: 'flex',
        width:'100%'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'50vh',
    },
});


class NextStepScreen extends React.Component {
  
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <Paper className={classes.paper}>
                {this.props.type === 'test'?
                    <Grid container  direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                                <Typography variant={'h4'} style={{fontSize:20, paddingTop:20}}>{t('Assessment - Questions - Moving to the next step')} </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                                <Typography variant={'h4'} style={{fontSize:20, paddingTop:20}}>{t('Assessment - Questions - You have')}{" " + (this.props.minutes/1000)/60 + " "}{t('Assessment - Questions - minutes')} {t('Assessment - Questions - To complete this section')}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                                <Typography variant={'h4'} style={{fontSize:20, paddingTop:20}}>{t('Assessment - Questions - Ready')} </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'center', paddingTop:40}}>
                                <EgButton onClick={this.props.moveon}>{t('Assessment - Screen - Start')}</EgButton>
                        </Grid>
                    </Grid>
                
                :
                <Grid container  direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                    <Grid item xs={6} style={{display:'flex', justifyContent:'center'}}>
                            <Typography variant={'h4'} style={{fontSize:20}}>{t('Assessment - Questions - Moving to the next step')} </Typography>
                    </Grid>
                </Grid>
                }
            </Paper>
    );
  }
}
NextStepScreen.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(NextStepScreen),NextStepScreen);


