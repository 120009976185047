import { initializeApp } from "firebase/app";
import { getAnalytics, setUserProperties, logEvent } from "firebase/analytics";
//import {getPerformance,trace} from 'firebase/performance';
import env from "react-dotenv";

const firebaseConfig = env ? {
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    projectId: env.FIREBASE_PROJ_ID,
    storageBucket: env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.FIREBASE_MSG_SENDER_ID,
    appId: env.FIREBASE_APP_ID,
    measurementId: "G-BLWQFSNPRX"
} : {};
  
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
// const firebasePerformance = getPerformance(firebaseApp)
// const firebaseTrace = trace(firebasePerformance, "EG performance FE trace")

// CATEGORIES
const ASSESSMENT_EVT_CATEGORY = 'assessment'

const EgFirebase = {
    // Logic Events
    // Assessment stages
    logStartAssessment: function(){
        // https://firebase.google.com/docs/reference/js/analytics#logevent
        logEvent(firebaseAnalytics, 'begin_assessment', {
            event_category: ASSESSMENT_EVT_CATEGORY
          });
    },
    logEndAssessment: function(){
        // https://firebase.google.com/docs/reference/js/analytics#logevent
        logEvent(firebaseAnalytics, 'end_assessment', {
            event_category: ASSESSMENT_EVT_CATEGORY
          });
    },
    logTimeoutAssessment: function(){
        // https://firebase.google.com/docs/reference/js/analytics#logevent
        logEvent(firebaseAnalytics, 'timeout_assessment', {
            event_category: ASSESSMENT_EVT_CATEGORY
          });
    },
    
    // Navigation
    logScreenView: function(screenName, screenClass=''){
        logEvent(firebaseAnalytics, 'screen_view', {
            firebase_screen: screenName, 
            firebase_screen_class: screenClass
          });          
    },

    // User Properties
    setUserOrg: function(org) {
        setUserProperties(firebaseAnalytics, { org: org });        
    },
    setCandidateId: function(cid) {
        setUserProperties(firebaseAnalytics, {candidate_id: cid})
    }
}

export default EgFirebase;