import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Box, Slider } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';


const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%'
    },
})

const PrettoSlider = styled(Slider)({
    color: '#e1e1e1',
    
    '& .MuiSlider-rail': {
        border: '4px solid #e1e1e1',
        width:1,
        borderRadius:8,
        bottom: -10,
        height:'103%'
      },
    '& .MuiSlider-thumb': {
      height: 18,
      width: 18,
      backgroundColor: '#fff',
      border: '2px solid #11D6A7',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      marginLeft: -4,
      marginBottom: -10,
      
    },
    '& .MuiSlider-markLabel':{
        left:50,
        color:'#5F6066'
    },
    '& .MuiSlider-markLabelActive':{
        border:'1px solid #00AC83',
        borderRadius:4,
        minWidth:250,
        padding:5,
        backgroundColor:'#11D6A7',
        color:'#ffffff',
        left:45,
    },
    '& .MuiSlider-mark':{
        width:0
    }
    /*
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },*/
    
  });
  
class AnswerSlider extends React.Component {
  
    state = {
        selected: -1,
        availAnswers:null,
        lastQuestion:null
    };

    constructor(props) {
        super(props);  
        this.radioSelected = this.radioSelected.bind(this); 
        this.getAnswers = this.getAnswers.bind(this);
        this.setState({availAnswers: this.props.availAnswers})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.availAnswers!==this.state.availAnswers ){   
            this.setState({availAnswers:nextProps.availAnswers});       
        } 
    }

    radioSelected(event,value){
        
        const len = this.props.availAnswers.number;
        const multipl = (len>1)?100/(len-1):100
        const ansVal = Math.round(value/multipl)
        this.props.setSelected(ansVal, new Array(ansVal.toString()));
        this.setState({selected:parseInt(value)});
    }

    getAnswers(){
        //const marks=[{value:0,label:'No chance'},{value:10,label:'up to 10%'},{value:20,label:'up tp 20%'}, {value:30,label:'up to 30%'}, {value:40,label:'up to 40%'},{value:50,label:'up to 50%'},{value:60,label:'up to 60%'},{value:70,label:'up to 70%'},{value:80,label:'up to 80%'},{value:90,label:'up to 90%'},{value:100,label:'For sure'}]
        let answersObjArr = []
        if (this.props.availAnswers){
            const len = this.props.availAnswers.number;
            const multipl = (len>1)?Math.round(100/(len-1)):100
            
            answersObjArr = this.props.availAnswers.anslist.map((answer, index) => {
                let robj={};
        
                robj = {value:index*multipl<100?index*multipl:100, label:answer}
                return robj;
            })
        }
        return answersObjArr;
    }

    render(){
        return (
            <Box sx={{ width: 300 }}>
                <PrettoSlider style={{height:300}}
                    value={this.state.selected?this.state.selected:0}
                    track={false}
                    aria-label="answers-slider"
                    defaultValue={0}
                    step={Math.round(100/(this.props.availAnswers.number-1))}
                    marks={this.getAnswers()}
                    orientation='vertical'
                    onChange={this.radioSelected}
                />
            </Box>
        )
    }
}   
AnswerSlider.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(AnswerSlider);