import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { setAdminDetails } from "../../redux/admin";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import EgProgress from '../../components/EgProgress';
import AccountsTable from './AccountsTable';
import AddAccount from './AddAccount';
import { withTranslation } from 'react-i18next';
import EgStatusAlert from '../../components/EgStatusAlert';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    packageMain:{
        textAlign:"start",
        paddingTop:20,
        paddingInlineStart:30,
    },
    
    
  addAccountCell:{
    textAlign:'end',
    paddingTop: 15,
    paddingInlineEnd:20,
  },
  
  });

const mapStateToProps = state => ({
    admin: state.admin.value
  });
  
class AccountsPage extends React.Component {
  
  state = {
    loading:<EgProgress />,
  };

  constructor(props) {
      super(props);    
      
      if (!this.props.admin.logged) {
        this.props.history.push('/signup') 
      } 

      this.refresh = this.refresh.bind(this);
      this.openAddAccount = this.openAddAccount.bind(this);  
      this.openEditAccount = this.openEditAccount.bind(this);
      this.handleDrawerState = this.handleDrawerState.bind(this);  
      this.handleStatusClose = this.handleStatusClose.bind(this);
      this.search = this.search.bind(this);

      this.getAccounts()
  }

  componentWillReceiveProps(nextProps) {   
      if (nextProps.searchTerm!==this.state.searchTerm ){             
          this.setState({searchTerm:nextProps.searchTerm});  
          this.search(nextProps.searchTerm)         
      } 
  }

  async getAccounts(){
      
    this.setState({loading:<EgProgress />})
    let response = await EgHttp.get('/accounts/org/' + this.props.admin.org.id ) //org_id: this.props.admin.org.id,
    let rowsres = await response.json();
    const origres = [...rowsres]
    this.setState({rows:rowsres, loading:null}); 
    this.setState({originalRows:origres});
    return;
  }

  filterItems(arr, query) {
    return arr.filter(function(el) {
      return (el.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1) || (el.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    })
  }

  search(term){
    this.setState({searchTerm: term})
    if (term && term.length>0){
      let filteredRows;
      filteredRows = this.filterItems(this.state.originalRows, term)
      this.setState({rows: filteredRows})
    }
    else{
      this.setState({rows: this.state.originalRows})
    }
  }

  handleDrawerState(sw){
      if (sw){
          this.setState({openAdd:!this.state.openAdd, openImport: !this.state.openImport})
      }
      else{
          this.setState({openAdd:false, openImport: false})
      }
  }


  handleStatusClose(){
    this.setState({statusBar:null})
  }

  showStatusBar(message){
    this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
  }

  refresh(status, message){
    this.getAccounts()
    if (status === 'SUCCESS'){
        this.showStatusBar(message)
    }
  }

  openAddAccount(){
    this.setState({openAccId:null}, ()=> {
      this.setState({openAdd:true})
    })
  }

  openEditAccount(id){
    this.setState({openAccId:id}, ()=> {
      this.setState({openAdd:true, openImport:false})
    })
  }
  
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
        <div>
        <Grid container>
            
            <Grid xs={6}>
                <Typography variant={'h2'} className={classes.packageMain}>{t('Settings - Accounts - Accounts')}</Typography>
            </Grid>
            <Grid xs={6} className={classes.addAccountCell}>
                <EgButton onClick={this.openAddAccount}>{t('Settings - Accounts - Add Account')}</EgButton>
            </Grid>
            <Grid item xs={12} className={classes.latestNote}>
                  <AccountsTable noteTitle="Admins List"  openEditAccount={this.openEditAccount}   rows={this.state.rows} refresh={this.refresh} loading={this.state?false:true} searchTerm={this.state.searchTerm}> </AccountsTable>
            </Grid>
           
        </Grid>
        <AddAccount handleDrawerState={this.handleDrawerState} openState={this.state.openAdd} accountId={this.state.openAccId} refresh={this.refresh}></AddAccount> 
        {this.state.statusBar}
        </div>
    );
  }
}
AccountsPage.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AccountsPage),AccountsPage)))