import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
//import LangPicker from '../../util/LangPicker';
import Moment from 'react-moment';
import EgButton from '../../components/EgButton';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import ReportsManager from '../../managers/ReportsManager';



const INIIAL_REPORT = 1
//const LAST_EMPLOYEE_REVIEW = 2
//const LAST_MANAGER_REVIEW = 3

const INITIAL_ASSESS_COMPLETED = 2
const INITIAL_ASSESS_INVITED = 1
const INITIAL_ASSESS_NOT_INVITED = 0
const INITIAL_ASSESS_NOT_COMPLETTED = 3

const ONGOING_ASSESSMENT_INVITETD=0
const ONGOING_ASSESSMENT_NOT_COMPLETED=1
//const ONGOING_ASSESSMENT_COMPLETED=2

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    insightBox:{
        border:'1px solid #E4E4E4',
        height:'35vh',
        overflow:'hidden'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    boxTitleInd:{
        paddingInline:20,
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    }
    
  });

class EmployeeCardSummaryDates extends React.Component {
  
    constructor(props) {
        super(props);   
        this.openReport = this.openReport.bind(this)   
        this.setEmployeeAssessmentsStates = this.setEmployeeAssessmentsStates.bind(this)    
    }

    state = {
        employee: this.props.employee?this.props.employee:{},
        traitsTimes: []
    };

    componentDidMount(){
       this.setState({employee: this.props.employee})
       this.setState({noInitial: this.props.noInitial})
       this.setEmployeeAssessmentsStates(this.props.employee)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.employee && nextProps.employee !== this.state.employee){
            this.setState({employee:nextProps.employee});
            this.setEmployeeAssessmentsStates(nextProps.employee)
        }
        if(nextProps.noInitial && nextProps.noInitial !== this.state.noInitial){
            this.setState({noInitial: nextProps.noInitial})
        }
    }

    setEmployeeAssessmentsStates(employee){
        if (employee && employee.assessment && employee.assessment.length>0){
            const intialReportInd = ReportsManager.findInitialReport(employee)
            const initialAssess = employee.report[intialReportInd]
            if (initialAssess && initialAssess.created_at){ 
                this.setState({intitalState:INITIAL_ASSESS_COMPLETED})
            }
            else{
                this.setState({intitalState:INITIAL_ASSESS_INVITED})
            }
        }
        else{
            this.setState({intitalState:INITIAL_ASSESS_NOT_INVITED})
        }
        if (employee && employee.assessment && employee.assessment.length>1){
            const empOngoingInd = employee.assessment.findIndex(({ type, completed_at }) => type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW && !completed_at)
            //const managerOngoingInd = employee.assessment.findIndex(({ type, completed_at}) => type === LAST_MANAGER_REVIEW && !completed_at)
            if (empOngoingInd > -1){
                this.setState({empOngoingState:employee.assessment[empOngoingInd].status})
                console.log("setting empOngoingIndex to:" + empOngoingInd)
                this.setState({empOngoingIndex:empOngoingInd})
            }
            /*
            if (managerOngoingInd > -1){
                this.setState({managerOngoingState:employee.assessment[managerOngoingInd].status})
                this.setState({managerOngoingIndex:managerOngoingInd})
            }
            */
        }
    }

    openReport(atype, employee){
        const empOngoingIndAssess = employee.assessment.findIndex(({ type, completed_at }) => type === atype && completed_at)
        if (empOngoingIndAssess > -1){
            const empOngoingIndReport = employee.report.findIndex(({ assessment_id }) => assessment_id === employee.assessment[empOngoingIndAssess].id)
            if (empOngoingIndReport > -1) this.props.openReport(INIIAL_REPORT, employee.report[empOngoingIndReport].id)
        }
    }

    render() {    
        const { classes } = this.props;
        const { t } = this.props;
        
        return (
            <Paper elevation={0} style={{ overflow:'hidden'}} className={classes.insightBox} >
               
                <Grid container>
                    <Grid xs={12} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Employees - Single - Status')} </Typography>
                    </Grid>
                </Grid>
                <Grid container style={{paddingTop:'2vh', paddingBottom:'2vh'}}>
                    <Grid xs={12} >
                        <Paper elevation={0} >
                            <Grid container>

                                {/*--------------------- INITIAL ASSESSMENT STATUS ------------------------------*/}
                                <Grid xs={8} style={{paddingInline:30, paddingTop:'1vh',paddingBottom:'1.5vh', textAlign:'start'}}>
                                    <Typography variant={'h5'} style={{fontWeight:500, marginTop:'0.5vh'}} >{t('Employees - Single - Initial Assessment Date')} </Typography>
                                </Grid>
                                <Grid xs={4} style={{paddingInline:10, paddingTop:'1vh',paddingBottom:'1.5vh', textAlign:'start'}}>
                                    {   this.state.intitalState === INITIAL_ASSESS_NOT_INVITED?
                                            <Typography variant={'h5'} style={{fontWeight:400, marginTop:'0.5vh'}}>{t('Employee - Single - Not Completed')}</Typography>:
                                        this.state.intitalState === INITIAL_ASSESS_INVITED?
                                            <Typography variant={'h5'} style={{fontWeight:400, marginTop:'0.5vh'}}>{t('Employee - Single - Invitation Sent')}</Typography>:
                                            <Typography variant={'h5'} style={{fontWeight:400, marginTop:'0.5vh'}}>{this.state.employee.initial_assessment_date? <Moment format="MMM DD, YYYY">{ this.state.employee.initial_assessment_date}</Moment>:t('Employee - Single - Not Completed')}</Typography>   
                                    }
                                    
                                </Grid>
                                
                                {/*--------------------- EMPLOYEE ONGOING STATUS ------------------------------*/}
                                <Grid xs={8} style={{paddingInline:30, paddingTop:'1.5vh',paddingBottom:'1.5vh', textAlign:'start', borderTop:'1px solid #efefef', }}>
                                    <Typography variant={'h5'} style={{fontWeight:500}} >{t('Employees - Single - Last Followup Employee')} </Typography>
                                </Grid>
                                <Grid xs={4} style={{paddingInline:10, paddingTop:'1.5vh', paddingBottom:'1.5vh', textAlign:'start', borderTop:'1px solid #efefef', }}>
                                {this.state.noInitial || !this.state.employee.last_self_review?
                                        this.state.empOngoingState === ONGOING_ASSESSMENT_INVITETD ||  this.state.empOngoingState === ONGOING_ASSESSMENT_NOT_COMPLETED? 
                                            <Typography variant={'h5'} style={{fontWeight:400}}>{t('Employee - Single - Invitation Sent')}</Typography>:
                                            <Typography variant={'h5'} style={{fontWeight:400}}>{t('Employee - Single - Not Completed')}</Typography>:

                                        <Typography variant={'h5'} style={{fontWeight:400}}><Moment format="MMM DD, YYYY">{this.state.employee.last_self_review}</Moment></Typography>  
                                }
                                </Grid>
                               
                                
                                {/*--------------------- DAYS FROM LAST ONGOING ------------------------------*/}
                                <Grid xs={8} style={{paddingInline:30, paddingTop:'1.5vh', textAlign:'start', borderTop:'1px solid #efefef'}}>
                                    <Typography variant={'h5'} style={{fontWeight:500}} >{t('Employees - Single - Days From Last Followup')} </Typography>
                                </Grid>
                                <Grid xs={4} style={{paddingInline:10, paddingTop:'1.5vh', textAlign:'start', borderTop:'1px solid #efefef'}}>
                                {this.state.noInitial || !this.state.employee.last_self_review?
                                    '':
                                    <Typography variant={'h5'} style={{fontWeight:400}}> <Moment diff={this.state.employee?this.state.employee.last_self_review:''} unit="days">{new Date()}</Moment></Typography>  
                                }
                                </Grid>
                              
                                <Grid xs={12} style={{paddingTop:'5vh', paddingInline:20 ,display:'flex', justifyContent:'end'}}>
                                    {this.state.intitalState === INITIAL_ASSESS_INVITED || this.state.empOngoingState === ONGOING_ASSESSMENT_NOT_COMPLETED || this.state.empOngoingState === ONGOING_ASSESSMENT_INVITETD? 
                                    <EgButton  secondary="true" onClick={()=>{this.props.remind(0)}}>{t('Employee - Single - Remind')}</EgButton>:''}&nbsp;&nbsp;
                                    <EgButton  disabled={this.state.intitalState === INITIAL_ASSESS_INVITED  || this.state.empOngoingState === ONGOING_ASSESSMENT_NOT_COMPLETED || this.state.empOngoingState === ONGOING_ASSESSMENT_INVITETD} onClick={()=>{this.props.assign()}}>{t('Candidates - Table - Assign Assessment')}</EgButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    
                </Grid> 
               
            </Paper>
        );
    }
}
EmployeeCardSummaryDates.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(EmployeeCardSummaryDates),EmployeeCardSummaryDates);