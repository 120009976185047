import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";


const EgLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main, 
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.dark, 
  }
}))(LinearProgress);
export default function EgProgress() {
  return (
    <div>
      <EgLinearProgress   />
    </div>
  );
}
