import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import moreMenuIcon from '../img/more_menu_icon.svg'
import { Grid, List, ListItem, Paper } from '@material-ui/core';
import EgButton from './EgButton';


const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding:0,
    },
    moreButton:{
        position:"relative",
        minWidth:30,
        padding:10,
        margin: 5,
    },
    subMenuEn: {
        right:0, 
    },
    subMenuHe: {
        left:0,  
    }, 
    subMenuItem:{
        color: '#000000',
        fontSize: 14,
        fontWeight: 500,
        textAlign:'start'
    },
    overlay:{
        display:"block", 
        position:'absolute',
        top:0, 
        left:0, 
        zIndex:1000, 
        background:"#f0f0f066",
        height:'100%',
        width:'100%',
    },
})


class RowActionsHover extends React.Component {
  
    state = {
        moreMenuOpen:[]
    };

    constructor(props) {
        super(props);
        this.openMoreMenu = this.openMoreMenu.bind(this);  
        this.mainAction = this.mainAction.bind(this);  
       
    }

    componentDidMount(){
       
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
    }

    mainAction(index){
        const indexwp=index+(this.props.rowsPerPage*this.props.page)
        this.props.mainAction(indexwp)
    }

    openMoreMenu(index){
        const { t, i18n } = this.props;
        const { classes } = this.props;
        const indexwp=index+(this.props.rowsPerPage*this.props.page)
        let items = [...this.state.moreMenuOpen];
        let item = {...items[indexwp]};
        items = [];
        if (this.state.moreMenuOpen[index]){
            item = null;
        }
        else{
            console.log(this.props.submenuActions)
           item = <Paper style={{position:'absolute', top: 50, width:200, zIndex:1001}} className={i18n.dir()==='rtl'?classes.subMenuHe:classes.subMenuEn}>
                    <List component="nav">
                        {this.props.submenuActions.map(function(act,actind){
                            return(
                                <ListItem key={'act'+actind} button className={classes.subMenuItem} onClick={()=>act.action(indexwp)} disabled={act.disabled}>{act.text}</ListItem>
                            )})}  
                    </List>
                </Paper> 
        }
        items[index] = item;
        //this.props.updateMore
        console.log(items)
        this.setState({submenu:item});

    }


    render() {
        const { classes } = this.props;
        const { t } = this.props;
        
        return (
            <div className={classes.overlay}> 
               
                <Grid container  direction="row" alignItems="center" style={{height:'100%'}}>
                    <Grid item xs={12}  style={{display:"flex", justifyContent:"flex-end",alignItems:"center", padding:10, height:'100%'}} >
                        {this.props.mainActionName?
                            <EgButton onClick={()=>(this.mainAction(this.props.rowIndex))}>{this.props.mainActionName}</EgButton>:''
                        }
                        <span style={{position:'relative'}}>
                            <EgButton secondary="true" outlined="true" className={classes.moreButton} onClick={()=>{this.openMoreMenu(this.props.rowIndex)}}><img src={moreMenuIcon} alt="moreicon" /></EgButton>   
                        </span> 
                    </Grid>
                </Grid>  
                {/*this.state.moreMenuOpen[this.props.rowIndex]*/}    
                {this.state.submenu}    
            </div>  
            
        );
    }
}

RowActionsHover.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(RowActionsHover),RowActionsHover)))