import React from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import GenericErrorDialog from '../../components/GenericErrorDialog';
import { withTranslation } from 'react-i18next';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import EgHttp from '../../util/EgHttp';
import SignTopbar from './SignTopbar'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import EgButton from '../../components/EgButton';
import companyIcon from '../../img/company_icon.svg'
import signBg from '../../img/assessment_bg.svg';

const mapStateToProps = state => ({
    admin: state.admin.value
  });


const styles = theme => ({
    root: {
      flexGrow: 1,
    },

    signupPaper:{
        height:480,
        width:412,
        margin:0,
        borderRadius:8 
    }, 
   
    appRight:{
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
      },
    appLeft:{
        [theme.breakpoints.up('sm')]: {
        width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
    },
    signButton:{
        padding:10, 
        width:'100%',
    }, 
    inputLabel:{
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#043241',
            opacity: 1,
          },
          fontSize:14,
          height:10,
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
  });
  
  
class Company extends React.Component {
  
    state = {
        open: this.props.open,
        operationIcon: null,
        company: null,
    };

  
    constructor(props) {
        super(props);   
        //if (!this.props.login.logged) {
        //    this.props.history.push('/signup') 
        //}          
        this.addCompany = this.addCompany.bind(this);   
        this.closeErrorDialog = this.closeErrorDialog.bind(this);     
    }

    closeErrorDialog(){
        this.setState({errDialogOpen:false});
        this.setState({errDialog:null});
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value }); 
    };

    async addCompany(){
        const { t } = this.props;
        if (!this.state.company || this.state.company.length<2){
            this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Company - Illegal Company Name")} message={t("Company - Company name should include at least")}></GenericErrorDialog>})
            return;
        }

        let response = await EgHttp.post('/sign/org', JSON.stringify({
                company: this.state.company,  
                adminId: this.props.login.account.id,
                firstName: this.props.login.account.firstname,
                lastName: this.props.login.account.lastname,
                fullName: this.props.login.account.firstname + " " + this.props.login.account.lastname,
                email: this.props.login.account.email,
        }))
        let rowsres = await response.json();
        console.log("After company")
        console.log(rowsres)
        let orgId = rowsres.id
        
        
        
        if (rowsres && rowsres.id) {
            let logobj = {logged:true,  account:{id:this.props.login.account.id, firstname: this.props.login.account.firstname, lastname: this.props.login.account.lastname, fullname:this.props.login.account.firstname + " " + this.props.login.account.lastname,  email:this.props.login.account.email, role:this.props.login.account.role}, org:{id:orgId, name:this.state.company, tests:3}}
            this.props.setLogin(true,  {id:this.props.login.account.id, firstname: this.props.login.account.firstname, lastname: this.props.login.account.lastname, fullname:this.props.login.account.firstname + " " + this.props.login.account.lastname,  email:this.props.login.account.email,role:this.props.login.account.role}, {id:orgId, name:this.state.company, tests:3})
            this.props.setAdminDetails(logobj)
            console.log("MOVING TO DASHBOARD")
            this.props.history.push('/dashboard')
           
        }
        return;
    }
  
  render() {
    const { t, i18n } = this.props;
    const { classes } = this.props;
    return (
        <div>
        <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} >
            <Toolbar >
                <SignTopbar mode="signup"></SignTopbar>
            </Toolbar>
        </AppBar>
            
        <Container style={{maxWidth:'100%'}}>
        <Grid container spacing={0} justify="center" alignItems="center" style={{backgroundImage:`url(${signBg})`,width:'100vw', height:'100vh', maxWidth:'100%'}}>
            <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                
                <Paper className={classes.signupPaper} elevation={3} style={{position:'relative'}}>
                    
                    <Grid item xs={12} style={{paddingTop:30, paddingBottom:100}}>
                        <Typography variant="h1" style={{color:'#043241', textAlign:'center'}}>{t("Company - Add your company")} </Typography>
                    </Grid>
                    
                    <Grid container spacing={2} justify="center"> 
                        <Grid item xs={10} style={{padding:5}}>
                            <TextField  name="Company" variant="outlined" required fullWidth='true' id="company" label=""  placeholder="Company" onChange={this.handleChange}
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                        <img src={companyIcon} style={{width:16}} alt="companyicon"/>
                                  </InputAdornment>
                                ),
                                classes: { input: classes.inputLabel }, 
                              }}
                            className={classes.signupTxt}/>
                        </Grid>
                        <Grid item xs={10} style={{padding:70}}></Grid>
                        <Grid item xs={10} style={{padding:5}}>
                            <EgButton variant="contained"   onClick={this.addCompany} className={classes.signButton}>
                                {t("Company - Continue")}
                            </EgButton>
                        </Grid>
                    </Grid>
                    
                    
                    
                
            
            </Paper>
        </Grid>
            
      </Grid>
      {this.state.errDialog}
      </Container>
      </div>
    );
  }
}
Company.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withCookies(withRouter((withStyles(styles))(Company),Company))))
