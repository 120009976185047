import LogRocket from "logrocket"
import EgHttp from "./EgHttp"


const config = {
    throttleDelayMs: 66
}

const data = {}

const EVENT_TYPE = {
    MOUSE_MOVE: 'MOUSE_MOVE',
    MOUSE_ENTER: 'MOUSE_ENTER',
    MOUSE_LEAVE: 'MOUSE_LEAVE',
    CLICK_OR_TAP: 'CLICK_OR_TAP'
}


const EgActivityRecorder = {
    _context: null,
    context_id: -1,
    context_key: null,
    prevThrottle: 0,
    contexts: {
        ASSESSMENT_START: 'ASSESSMENT_START',
        ASSESSMENT_CAMERA_SETUP: 'ASSESSMENT_CAMERA_SETUP',
        ASSESSMENT_ARE_YOU_READY: 'ASSESSMENT_ARE_YOU_READY',
        ASSESSMENT_QUESTIONS: 'ASSESSMENT_QUESTIONS',
        INTERVIEW_QUESTIONS: 'INTERVIEW_QUESTIONS'
    },
    

    /**
     * 
     * @param {*} data JSON object, preferable flat.
     * @param {*} address An array to keep the event data
     * @param {*} priority 1:-> lowest priority, 0.1:-> most urgent
     */
    _recordEvent: function(eventType, data, address, priority=1) {
        const now = new Date().getTime();
        if (now - EgActivityRecorder.prevThrottle*priority > config.throttleDelayMs) {
            EgActivityRecorder.prevThrottle = now
            const eventDetails = {}
            eventDetails.time = now
            eventDetails.type = eventType
            eventDetails.data = data
            address.push(eventDetails)
            // console.log(`Saving ${this._context}:${eventType} -> ${JSON.stringify(data)}`)
        }
    },

    // Assessment stages
    handleMouseMove: function(event) {
        if (!EgActivityRecorder._context) {
            return
        }
        const eventsArr = data[EgActivityRecorder.context_key]
        const newData = {x: event.clientX, y: event.clientY}
        EgActivityRecorder._recordEvent(EVENT_TYPE.MOUSE_MOVE, newData, eventsArr)
    },

    handleMouseEnter: function(event) {
        if (!EgActivityRecorder._context) {
            return
        }
        let elementName = null
        let specificData = null
        if (event && event.target.attributes.mouseenterdata) {
            specificData = JSON.parse(event.target.attributes.mouseenterdata.value)
        }

        if (event && event.target.name && event.target.name.length > 0) {
            elementName =event.target.name
        } else if (event && event.target.innerText && event.target.innerText.length > 0) {
            elementName = event.target.innerText
        }

        const newData = {element: elementName, data: specificData}
        EgActivityRecorder._recordEvent(EVENT_TYPE.MOUSE_ENTER, newData, data[EgActivityRecorder.context_key], 0.9)
    },

    handleMouseLeave: function(event) {
        if (!EgActivityRecorder._context) {
            return
        }
        let elementName = null
        let specificData = null
        if (event && event.target.attributes.mouseleavedata) {
            specificData = JSON.parse(event.target.attributes.mouseleavedata.value)
        }

        if (event && event.target.name && event.target.name.length > 0) {
            elementName =event.target.name
        } else if (event && event.target.innerText && event.target.innerText.length > 0) {
            elementName = event.target.innerText
        }

        const newData = {element: elementName, data: specificData}
        EgActivityRecorder._recordEvent(EVENT_TYPE.MOUSE_LEAVE, newData, data[EgActivityRecorder.context_key], 0.9)
    },

    handleClickOrTap: function(event) {
        // it doesn't stop the propagation -> event.stopPropagation()
        if (!EgActivityRecorder._context) {
            return
        }
        let elementName = null
        let specificData = null
        if (event && event.target.attributes.clickdata) {
            specificData = JSON.parse(event.target.attributes.clickdata.value)
        }

        if (event && event.target.name && event.target.name.length > 0) {
            elementName = event.target.name
        } else if (event && event.target.innerText && event.target.innerText.length > 0) {
            elementName = event.target.innerText
        }

        const newData = {element: elementName, data: specificData}
        EgActivityRecorder._recordEvent(EVENT_TYPE.CLICK_OR_TAP, newData, data[EgActivityRecorder.context_key], 0.8)
    },

    /**
     * 
     * @param {*} context The current screen. Should be one of the enums or null.
     * @param {*} context_id The id of the assessment, interview, etc...
     * @param {*} isAssess 
     */
    updateContext: function(context, context_id, isAssess=true) {
        console.log(`Updating context from ${this._context} ${this.context_id} to ${context} ${context_id}`)
        EgActivityRecorder._postEvents(this._context, this.context_id, this.context_key, this.isAssess)
        this._context = context
        this.context_id = context_id
        this.isAssess = isAssess
        if (context_id === -1) {
            console.log('Stop recording eg events')
            this.context_key = null
            return
        }
        this.context_key = `${context}_${context_id}`
        data[this.context_key] = []
        console.log(`Switched context to ${this.context_key} is asses: ${this.isAssess}`)
    },

    stopRecordingEvents: function(isAssess=true) {
        this.updateContext(null, -1, isAssess)
    },

    flush: function() {
        console.log('Flushing recorded events till now, without switching context.')
        this._postEvents(this._context, this.context_id, this.context_key, this.isAssess)
    },

    _dumpDataToLocalStorage: function(context, context_id, context_key) {
        if (context_id === -1 || context === null) {
            console.error('Attempting to persist data while nothing defined - NOTHING WAS PERSISTED')
            return
        }

        const dataAsStr = JSON.stringify(data[context_key])
        data[context_key] = []
        window.localStorage.setItem(context_key, dataAsStr)
        console.log(`Data with key ${context_key} was saved to disk`)
    },

    _fetchDataFromLocalStorage: function(context, context_id, context_key) {
        if (context_id === -1 || context === null) {
            console.error('Attempting to fetch data while nothing defined - RETURNING NULL')
            return null
        }

        const dataAsStr = window.localStorage.getItem(context_key)
        if (!dataAsStr) {
            console.warn(`Attempting to restore data from local storage by key ${context_key}, but nothing was found`)
            return []
        }
        const restoredData = JSON.parse(dataAsStr)
        return restoredData
    },

    _removeDataFromLocalStorage: function(context, context_id, context_key) {
        if (context_id === -1 || context === null) {
            console.error('Attempting to clear data while nothing defined - Ignoring')
            return
        }

        window.localStorage.removeItem(context_key)
    },

    _removeItemsFromData: function(context, context_id, context_key, arrOfItemsToRemove) {
        if (context_id === -1 || context === null) {
            console.error('Cannot remove items from data - Ignoring')
            return
        }

        if (data[context_key]) {
            console.log(`Removing ${arrOfItemsToRemove.length} items from key ${context_key}. Current size ${data[context_key].length}`)
            data[context_key] = data[context_key].filter( ( el ) => !arrOfItemsToRemove.includes( el ) );
        } else {
            console.warn(`No data saved in key ${context_key}, not removing anything`)
        }
    },

    // // Maybe it's a bad idea to clear the entire local storage, as I don't know where else it's in use.
    // clearLocalStorage: function() {
    //     console.warn('Clearing the entire local storage')
    //     window.localStorage.clear()
    // },

    _postEvents: async(context, context_id, context_key, isAssess, retrying=0) => {
        if (context_id < 1) {
            console.log('First port, not sending anything to backend')
            return
        }

        const MAX_RETRIES = 5
        const url = `/activityrec/eventssession`
        let recordedData = []
        if (retrying > 0) {
            if (retrying < MAX_RETRIES) {
                console.log('EgRec retry to post events --> loading data from local storage')
                recordedData = EgActivityRecorder._fetchDataFromLocalStorage(context, context_id, context_key)
            } else {
                console.error(`Gave up retrying to post events for ${context_key}. The events recordings are permanently lost. :(`)
                EgActivityRecorder._removeDataFromLocalStorage(context, context_id, context_key)
            }
        } else {
            console.log('EgRec posting events')
            recordedData = data[context_key]
            EgActivityRecorder._dumpDataToLocalStorage(context, context_id, context_key)
        }
        
        if (!recordedData || recordedData.length === 0) {
            console.log('No data was recorded, no events to report to backend. Aborting.')
            return
        }
        const body = {
            context: context,
            context_id: context_id,
            recordedData: recordedData
        }

        // TOOD: HANDLE logical errors in backend response
        try {
            console.log(`SENDING RECORDING`)
            console.dir(body)
            const resp = await EgHttp.post(url, JSON.stringify(body), isAssess)
            const respData = await resp.json()
            console.log(`Saved events session in context ${context} and ctx id ${context_id} using key ${context_key}`)
            console.log(JSON.stringify(respData))
            console.log('Clearing posted data from local recording, memory and local storage')
            // clear data after posting it.
            EgActivityRecorder._removeItemsFromData(context, context_id, context_key, recordedData)
            EgActivityRecorder._removeDataFromLocalStorage(context, context_id, context_key)
        } catch(err) {
            LogRocket.error('Failure posting events to backend - will retry')
            console.log(err)
            setTimeout(() => {
                console.log('Retrying to post events due to failure.')
                EgActivityRecorder._postEvents(context, context_id, context_key, isAssess, retrying+1)
            }, 2000);
        }
    }
}

export default EgActivityRecorder;