import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Divider, Drawer } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import LangPicker from '../../util/LangPicker';
import ReportsManager from '../../managers/ReportsManager';
import EgHtmlToolTip from '../../components/EgHtmlToolTip';
import EgHttp from '../../util/EgHttp';
import GradeBar from '../../components/GradeBar';
import EgButton from '../../components/EgButton';
import AdditionalTestDialog from './AdditionalTestDialog';
import ExtendedSkillEvaluation from './ExtendedSkillEvaluation';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
    actionButtonMini:{
        marginBottom:3,
        paddingTop:2,
        paddingBottom:2,
        fontSize: 10,
        marginInlineStart:'1vw'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'0.5vh',
        
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:20
    },
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    },
    valBar:{
        paddingInline:10
    },
});

const ADDITIONAL_TESTS_FATHER = 500

class AdditionalTestResults extends React.Component {
  
    constructor(props) {
        super(props);   
        this.openTest = this.openTest.bind(this)   
        this.closeTest = this.closeTest.bind(this)    
        this.openExtendedTraits = this.openExtendedTraits.bind(this);
        this.closeExtended = this.closeExtended.bind(this)      
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        traitsSummaryArr:[],
        execSummaryArr:[],
        invalidReport:false
    };
   
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
    }
    
    intializeData(report, reportParts){
        this.setState({report})
        this.setState({reportParts})
        if (!report) return
        const scores = report.assessment.assessment_scores
        const sortedScores = scores.sort((a,b) => b.trait_adjusted_score - a.trait_adjusted_score);
        this.setState({sortedScores:sortedScores})
        
        const traitSumArr = ReportsManager.setTraitsSummary(report, reportParts)
        this.setState({traitsSummaryArr: traitSumArr})    
        if (report.dominant_traits && report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
        this.getScorePercetiles(scores)
    }

    async getScorePercetiles(scores){
        const jbody = JSON.stringify({scores:scores}) 
        let res;
        let asres
        res = await EgHttp.put('/reports/percentile', jbody)
        asres = await res.json();
        console.log(asres)
        this.setState({percentileArr:asres})
    }
    getPercentileForOne(score){
        if (this.state.percentileArr && this.state.percentileArr.length>0){
            const ind = this.state.percentileArr.findIndex(({ trait_id }) => trait_id === score.trait.id )
            if (ind > -1){
                let finalPerc =parseFloat((this.state.percentileArr[ind].perc*100).toFixed(1))
                if (finalPerc === 0) finalPerc=0.1
                if (finalPerc === 100) finalPerc=99.9
                return " " + finalPerc + "% "
            }
        }
    }
    
    openTest(score){
        console.log(score.trait_id)
        this.setState({additionalTestOpen:true},
            
            this.setState({testDialog:
                <AdditionalTestDialog login={this.props.login} open={true} closeDialog={this.closeTest} score={score} report={this.state.report} refresh={this.refresh} ></AdditionalTestDialog>
            })
        )
    }
    closeTest(){
        this.setState({additionalTestOpen:false});
        this.setState({testDialog:null});
    }
    openReadMore(index){
        this.setState({extendedOpen: true})
    }
    closeExtended(){
        this.setState({extendedOpen: false})
    }

    openExtendedTraits(){
        this.setState({extendedOpen: true})
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Grid container style={{marginBottom:'3vh'}}>
                        <Grid item xs={8} className={classes.boxTitle}>
                            <Typography variant={'h4'}  style={{paddingTop:'2vh', paddingBottom:'2vh', fontWeight:600}}>{t('Reports - Summary - Additional Test Results')} </Typography>
                        </Grid>
                        <Grid xs={4} style={{display:'flex', justifyContent:'end'}} className={classes.boxTitle} />
                    </Grid>
                    <div>
                    {this.state.report && this.state.report.assessment?
                    <Grid container style={{marginBottom:'1vh',display:'flex', justifyContent:'center',}}>
                        <Grid xs={1}></Grid>
                        <Grid xs={3} style={{paddingInline:10, display:'flex', alignItems:'center', justifyContent:'end'}} ></Grid>
                        <Grid xs={4} style={{display:'flex', alignItems:'center', }}></Grid>
                        <Grid xs={2}></Grid>
                        <Grid xs={2}></Grid>
                        <Divider style={{width:'80%', marginTop:'2.5vh', marginBottom:'1.5vh',padding:0,backgroundColor:'#f6f6f6' }}/>
                    </Grid>:''}
                    </div>
                    {this.state.report && this.state.report.assessment && this.state.sortedScores?
                    this.state.sortedScores.map((score, index) => (
                        score.trait.father_id===ADDITIONAL_TESTS_FATHER?
                        <div style={{position:'relative'}} >
                        <Grid container style={{paddingTop:'1vh', paddingBottom:'1vh',display:'flex', justifyContent:'center', position:'relative'}}>
                                
                                <Grid xs={3} style={{paddingInline:10, borderInlineEnd:'solid 1px black',display:'flex', alignItems:'center', justifyContent:'end'}} >
                                    <Typography variant={'h4'} style={{textAlign:'end', fontWeight:500, }}>
                                        {LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name}
                                    </Typography>
                                </Grid>
                                <Grid xs={4} style={{display:'flex', alignItems:'center'}}>
                                    <EgHtmlToolTip title={LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name} body={t('Report - Stats - This score is higher than') + this.getPercentileForOne(score) + t('Report - Stats - of the general population')} context={'stats'}
                                    innerElement={
                                        <div style={{width:'100%'}}>
                                            <GradeBar variant="determinate" wide={true} value={score.trait_adjusted_score}  boundaries={score.trait.boundaries}  className={classes.valBar} ></GradeBar>
                                        </div>
                                    }/>
                                </Grid>
                                <Grid xs={1} style={{display:'flex', alignItems:'center', paddingInline:10}}>
                                    <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{Math.floor(score.trait_adjusted_score)}</span><span style={{fontSize:9}}>/100</span></Typography>
                                </Grid>
                                <Grid xs={4} style={{display:'flex', alignItems:'center'}}>
                                    <EgButton secondary="true" outlined="true"  className={classes.actionButtonMini} onClick={()=>{this.openTest(score)}}>{t('Report - Open Test')}</EgButton>
                                </Grid>
                                
                            <Divider style={{width:'80%', marginTop:'1vh', marginBottom:'1vh',padding:0,backgroundColor:'#f6f6f6' }}/>
                        </Grid>  
                        </div>
                            :''
                        )):''} 
                    {this.state.testDialog}
                    <Grid xs={10} style={{display:'flex', justifyContent:'end', paddingInline:40, paddingTop:'0vh'}} >
                        <EgButton secondary="true"  onClick={()=>{this.openReadMore(0)}} className={classes.actionButton}>{t('Report - Single - more info')}</EgButton> 
                    </Grid>
                    <Grid xs={2}></Grid>
                </Paper>
            </Grid>
            <Drawer open={this.state.extendedOpen} anchor='right' onClose={this.closeExtended} PaperProps={{ style: {  width:'50vw',overflow:'hidden' } }}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.closeExtended}
                    onKeyDown={this.closeExtended}
                >
                <ExtendedSkillEvaluation report={this.state.report} reportParts={this.state.reportParts}></ExtendedSkillEvaluation>
                </div>
            </Drawer>
            </div>               
    );
  }
}
AdditionalTestResults.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(AdditionalTestResults),AdditionalTestResults);