import React from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link, withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { isExpired } from "react-jwt";
import qs from 'qs';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import LangPicker from "../../util/LangPicker";
import GenericErrorDialog from '../../components/GenericErrorDialog'
import EgHttp from '../../util/EgHttp';
import emailIcon from '../../img/email_icon.svg'
import passwordIcon from '../../img/password_icon.svg'
import EgButton from '../../components/EgButton';
import SignTopbar from './SignTopbar'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SocialRow from './SocialRow';
import signBg from '../../img/assessment_bg.svg';
import jwt_decode from "jwt-decode";
import NavigationManager from '../../managers/NavigationManager';

const mapStateToProps = state => ({
    admin: state.admin.value
});

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: 20,
      },
    signinPaper:{
        background:theme.palette.whitebg,
        height:480,
        width:412,
        margin:0,
        borderRadius:8,
    },
    
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
    inputLabel:{
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: theme.palette.text.black,
            opacity: 1,
          },
          fontSize:14,
          height:10,
    },
    xsLink:{
        textDecoration:"none",
        color:theme.palette.text.black,
        fontSize: 12,
        fontWeight:400,
        verticalAlign: 'top',
    },
    link:{
        textDecoration:'none',
        color: theme.palette.text.black,
    },
    signButton:{
        padding:10, 
        width:'100%',
    }, 
    appRight:{
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
      },
    appLeft:{
        [theme.breakpoints.up('sm')]: {
        width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
        
      },
  });
  
class Signin extends React.Component {
  
    state = {
        email:null,
        password:null, 
        errDialogOpen:false,
    };

    constructor(props) {
        super(props);            
        this.signin = this.signin.bind(this); 
        this.closeErrorDialog = this.closeErrorDialog.bind(this) ;
        this.initiate() 
    } 

    async initiate(){
        const src=qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).src
        const jwtoken = this.props.cookies.cookies.jwt;
        if (jwtoken){
            const myDecodedToken = jwt_decode(jwtoken);
            const rowsres = myDecodedToken.data
            
            const isMyTokenExpired = isExpired(jwtoken);
            
            if (!isMyTokenExpired || (src && !isMyTokenExpired)){
                if (rowsres.isRegister){
                    this.props.history.push('/signup')
                }
                else if (rowsres.org_idd === -1){
                    this.props.history.push('/company')
                }
                else{
                    let response = await EgHttp.get('/sign/config/' + rowsres.org_idd)
                    let confres = await response.json();
                    let logobj = {logged:true,  account:{id:rowsres.id, firstname: rowsres.firstname, lastname: rowsres.lastname, fullname:rowsres.firstname + " " + rowsres.lastname,  email:rowsres.email, role:rowsres.role}, org:{id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests,language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license, config:confres?confres.config:''}}
                    this.props.setLogin(true,  {id:rowsres.id, firstname: rowsres.firstname, lastname: rowsres.lastname, fullname:rowsres.firstname + " " + rowsres.lastname,  email:rowsres.email, role:rowsres.role}, {id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests, language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license, config:confres?confres.config:''})
                    this.props.setAdminDetails(logobj)             
                    NavigationManager.reset(this.props)

                }
            }
            else if (src){
                // do nothing
            }
            
        }
        LangPicker.resolve(this.props)

    } 
    
    closeErrorDialog(){
        this.setState({errDialogOpen:false});
        this.setState({errDialog:null});
    }
  
    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value }); 
    };

    async signin(){
        const { t } = this.props;
        let response = await EgHttp.post('/sign/validate', JSON.stringify({
                email:this.state.email,
                token: this.state.password}))
        
        if (response.status === 200){
            console.log("Validated!")
            let rowsres = await response.json();
            console.log(rowsres)
            if (rowsres && rowsres.id) {
                response = await EgHttp.get('/sign/config/' + rowsres.org_idd)
                let confres = await response.json();
                let logobj = {logged:true,  account:{id:rowsres.id, firstname: rowsres.firstname, lastname: rowsres.lastname, fullname:rowsres.firstname + " " + rowsres.lastname,  email:this.state.email, role:rowsres.role}, org:{id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests, language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license,  config:confres?confres.config:''}}
                this.props.setLogin(true,  {id:rowsres.id, firstname: rowsres.firstname, lastname: rowsres.lastname, fullname:rowsres.firstname + " " + rowsres.lastname,  email:this.state.email, role:rowsres.role}, {id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests, language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license,  config:confres?confres.config:''})
                this.props.setAdminDetails(logobj)
                if (rowsres.org_idd > 0){
                    this.props.history.push('/dashboard')
                }
                else{
                    this.props.history.push('/company')
                }
            }
            else{
                this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Signin - Wrong credentials")} message={t("Signin - Incorrect email or password")}></GenericErrorDialog>})
                return;
            }
        }
        else{
            this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Signin - Wrong credentials")} message={t("Signin - Incorrect email or password")}></GenericErrorDialog>})
            return;
        }   
        return;
    }
  
  
  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    
    return (
        <div >
        <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} >
            <Toolbar >
                <SignTopbar mode="signin"></SignTopbar>
            </Toolbar>
        </AppBar>
        <Container style={{maxWidth:'100%'}}>
        <Grid container spacing={0}  alignItems="center" style={{backgroundImage:`url(${signBg})`,width:'100vw', height:'100vh', maxWidth:'100%'}}>
            
            <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                
                <Paper className={classes.signinPaper} elevation={3} style={{position:'relative', zIndex:1}}>
                    
                    <Grid item xs={12} style={{paddingTop:30, paddingBottom:40}}>
                        <Typography variant="h1" style={{color:'#043241', textAlign:'center'}}>{t('Signin - SignIn')} </Typography>
                    </Grid>
                    <Grid container spacing={2} style={{display:'flex', justifyContent:'center'}}>
                        <Grid item xs={8}  >
                            <SocialRow />
                        </Grid>
                        <Grid item xs={8} style={{paddingTop:20, }}>     
                            <Typography variant="h5" >{t('Signin - Or')}</Typography>
                        </Grid>

                        <Grid item xs={8} style={{padding:5}}>    
                        </Grid>
                        
                        <Grid item xs={10} style={{padding:5}}>
                            <TextField  variant="outlined" required fullWidth='true' id="email" label="" placeholder={t('Signin - Email')} name="email" autoComplete="email" onChange={this.handleChange} 
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start"><img src={emailIcon} alt="emailicon"/></InputAdornment>),
                                    classes: { input: classes.inputLabel }, 
                                }}
                                className={classes.signupTxt}/>
                        </Grid>
                        <Grid item xs={10} style={{padding:5}}>
                            <TextField variant="outlined" required fullWidth='true' name="password" label="" placeholder={t('Signin - Password')} type="password" id="password" autoComplete="current-password" onChange={this.handleChange}  
                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><img src={passwordIcon} alt="passwordicon"/></InputAdornment >),
                                classes: { input: classes.inputLabel }, 
                              }}
                            className={classes.signupTxt}/>
                        </Grid>
                        <Grid item xs={10} style={{padding:5}}>
                            <EgButton type="submit"  variant="contained"  onClick={this.signin} className={classes.signButton}>
                                {t('Signin - SignIn')}
                            </EgButton>
                        </Grid>
                              
                    </Grid>
                    
                    <Grid item xs={12} style={{paddingBottom:20, paddingTop: 15}}>   
                        <Link to='/resetpass' className={classes.xsLink} >{t('Signin - Forgot password')}</Link> 
                    </Grid>
                    <Grid item xs={12} style={{padding:5}}>
                        <Typography  variant="h5" >{t('Signin - Agree to terms')}&nbsp;
                            <a href={LangPicker.current() === 'en'?"https://engard.io/terms":"https://engard.io/he/terms"} /*target="_blank"*/ className={classes.link}>{t('Signin - terms')}</a>
                        </Typography>
                    </Grid>
            
            </Paper>
        </Grid> 
        
      </Grid>
      {this.state.errDialog}
      </Container>
      </div>
    );
  }
}
Signin.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withCookies(withRouter((withStyles(styles))(Signin),Signin))))