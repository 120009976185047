import React from "react";
import { withStyles } from "@material-ui/core/styles";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { Tooltip } from "@material-ui/core";




const HtmlWarnTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fefefe',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      width: 300,
      fontSize: 13,
      border: '1px solid #F9A846',
      borderRadius: 5,
      padding:20,
      fontWeight:500,
      boxShadow:'2px 2px 2px #888888'
    },
}))(Tooltip);
const HtmlAlertTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fefefe',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      width: 300,
      fontSize: 13,
      border: '1px solid #FC264D',
      borderRadius: 5,
      padding:20,
      fontWeight:500,
      boxShadow:'2px 2px 2px #888888'
    },
}))(Tooltip);
const HtmlInfoTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fefefe',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 450,
      width: 450,
      fontSize: 13,
      border: '1px solid #0085AF',
      borderRadius: 3,
      padding:20,
      fontWeight:500,
      boxShadow:'2px 2px 2px #888888'
    },
}))(Tooltip);



export default function EgHtmlToolTip(props) {
    //const context = props.context
    return (
        <span style={{width:props.widthm?props.widthm:'100%'}}>
            {   
                props.context==='alert'?
                <HtmlAlertTooltip  placement="top" {...props} title={<React.Fragment><div style={{paddingBottom:10}}><WarningRoundedIcon style={{marginInlineEnd:5,color:'#FC264D', marginBottom:-5}} /><span><b>{props.title}</b></span></div>{props.body}  </React.Fragment>}  >
                    {props.innerElement}
                </ HtmlAlertTooltip>
                :
                props.context==='warn'?
                <HtmlWarnTooltip  placement="top" {...props} title={<React.Fragment><div style={{paddingBottom:10}}><WarningRoundedIcon style={{marginInlineEnd:5,color:'#F9A846', marginBottom:-5}} /><span><b>{props.title}</b></span></div>{props.body}  </React.Fragment>}  >
                    {props.innerElement}
                </ HtmlWarnTooltip>
                :
                <HtmlInfoTooltip  placement="top" {...props} title={<React.Fragment><div style={{paddingBottom:10}}><AssessmentOutlinedIcon style={{marginInlineEnd:5,color:'#0085AF', marginBottom:-5}} /><span><b>{props.title}</b></span></div>{props.body}  </React.Fragment>}  >
                    {props.innerElement}
                </ HtmlInfoTooltip>
                
              
            }       
        </span>
    );
}
