import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell, ListItem, Collapse, List, Divider } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import interview_insights from '../../img/interview_insights_full.svg';
import interview_icon from '../../img/interview_icon.svg';
import EgSegmentSlicer from '../../util/EgSegmentSlicer';
import ReportsManager from '../../managers/ReportsManager';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LangPicker from '../../util/LangPicker';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        paddingTop:'1.5vh',
        border:0,
        backgroundColor:'#FAFAFB'
    },
    
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:20
    },
    boxTitle:{
        paddingTop:'2vh',
        paddingBottom:'2vh',
        paddingInline:20,
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
});

class SingleReportInterview extends React.Component {
  
    
    constructor(props) {
        super(props); 
        this.openCritical = this.openCritical.bind(this); 
        this.openOther = this.openOther.bind(this); 
         
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        interviewSummaryArr:[],
        invalidReport:false,
        accordCrit:false,
        accordOther: false
    };

    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
    }
    
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }
    
    intializeData(report, reportParts){
        if (!reportParts || reportParts.length === 0) return
        this.setState({report})
        this.setState({reportParts})
        if (!report) return
        const intArr = ReportsManager.setInterviewSummary(report, reportParts)
        this.setState({interviewSummaryArr: intArr})    
        if (report.dominant_traits && report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
        this.filterCriticalQuestion(report)
    }

    filterCriticalQuestion(report){
        const criticalQ =  report.assessment.response.filter(function(res) {
            if (res.question.category){
                if ((res.question.category === 1 && (res.selected_answers[0] === 1 || res.selected_answers[0] === 2 )) || 
                    (res.question.category === 2 && (res.selected_answers[0] === 4 || res.selected_answers[0] === 5))
                ){ 
                    return res
                }
                
            }
        })
        this.setState({criticalQuestions: criticalQ})
    }
    openCritical(){
        const critOpen = this.state.accordCrit
        this.setState({accordCrit: !critOpen})
    }
    openOther(){
        const otherOpen = this.state.accordOther
        this.setState({accordOther: !otherOpen})
    }
    
    render() {
        console.log(LangPicker.current() === 'en')
        const { classes } = this.props;
        const { t } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            {this.state.invalidReport?
            <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'1vh', paddingLeft:10, paddingRight:10}}>
                <Typography variant={'h3'} className={classes.invalid}>{ t('Reports - Summary - Invalid Assessment')}</Typography>
            </Grid>:
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Single - Recommendations')}</Typography>
                    <Grid container>
                        <Grid xs={8}> 
                            <Grid container>
                                <Grid xs={12} style={{paddingInlineStart:20,  paddingTop:'2vh'}}>
                                    <Table><TableBody><TableRow>
                                    <TableCell style={{border:0, padding:5, width:0, paddingBottom:0}}><img src={interview_icon}  alt="overall" /></TableCell>
                                    <TableCell style={{border:0, padding:5}}><Typography variant={'h2'} >{t('Reports - Single - Interviews and Evaluation')}</Typography></TableCell>
                                    </TableRow></TableBody></Table>
                                    
                                </Grid>
                                <Grid xs={12} style={{paddingInline:65, paddingBottom:'3vh'}}>
                                    <Typography variant={'h5'} style={{color:'#58666B', textAlign:'start'}}>{t('Reports - Insights - These are recommendations for the next steps')}</Typography>
                                </Grid>
                                
                                <Grid item xs={12} style={{paddingInlineStart:65, cursor:'pointer'}}>
                                    <Grid container  style={{backgroundColor:'#fafafa'}}>
                                        <Grid item xs={8} onClick={this.openCritical} style={{paddingInline:20,  paddingTop:5, paddingBottom:5, textAlign:'start', display:'flex', alignItems:'center', backgroundColor:'#fafafa'}}><Typography variant={'h6'} >{t('Reports - Insights - Critical Questions Followup')}</Typography></Grid>
                                        <Grid item xs={4} onClick={this.openCritical} style={{textAlign:'end', paddingInlineEnd:10, paddingTop:5, paddingBottom:5}}>{this.state.accordCrit ? <ExpandLess/> : <ExpandMore/>}</Grid>
                                        <Grid item xs={12} style={{backgroundColor:'#ffffff'}}>
                                            <Collapse in={this.state.accordCrit} timeout="auto" unmountOnExit >
                                                <Grid container style={{paddingInline:20, paddingTop:20}}>
                                                {this.state.criticalQuestions && this.state.criticalQuestions.length?
                                                    this.state.criticalQuestions.map((quest) => (
                                                        <Grid item xs={12}><Grid container style={{paddingTop: 10, paddingBottom: 10}}>
                                                            <Grid item xs={9} style={{textAlign:'start', }}><Typography variant='h4'><span style={{fontSize:14, fontWeight:600}}>{t('Reports - Summary - Q')}</span>&nbsp;{LangPicker.current() === 'en'?quest.question.text:quest.question.question_localized[0].text }</Typography></Grid>
                                                            <Grid item xs={3} style={{textAlign:'start', paddingInline:20}}><Typography variant='h4'><span style={{fontSize:14, fontWeight:600}}>{t('Reports - Summary - A')}</span>&nbsp;{LangPicker.current() === 'en'?quest.question.answer.answer_list[quest.selected_answers[0]-1].text:quest.question.answer_localized[0].answer_list[quest.selected_answers[0]-1].text}</Typography></Grid>
                                                            <Grid item xs={12} style={{textAlign:'start', paddingTop:10}}><Typography variant='h4'><span style={{fontSize:14, fontWeight:600}}>{t('Reports - Summary - Followup')}</span>&nbsp;{LangPicker.current() === 'en'?quest.question.followup[0]:quest.question.question_localized[0].followup[0] }</Typography></Grid>
                                                           
                                                        </Grid><Divider /></Grid>
                                                        
                                                    )):
                                                    <Grid item xs={12}><Typography variant='h4'>{t('Reports - Summary - No Followup Questions')}</Typography></Grid>
                                                }
                                                </Grid>
                                           
                                        </Collapse>
                                    </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} style={{paddingTop:'2vh',paddingInlineStart:65, cursor:'pointer'}} >
                                    <Grid container style={{ paddiingTop:50, backgroundColor:'#fafafa'}}>
                                        <Grid item xs={8} onClick={this.openOther}  style={{paddingInline:20, paddingTop:5, paddingBottom:5,  textAlign:'start',display:'flex', alignItems:'center'}}><Typography variant={'h6'} >{t('Reports - Insights - Other Issues to Follow')}</Typography></Grid>
                                        <Grid item xs={4} onClick={this.openOther}  style={{textAlign:'end',  paddingTop:5, paddingBottom:5, paddingInlineEnd:10}}>{this.state.accordOther ? <ExpandLess/> : <ExpandMore/>}</Grid>
                                        <Grid item xs={12} style={{backgroundColor:'#ffffff'}}>
                                            <Collapse in={this.state.accordOther} timeout="auto" unmountOnExit >
                                                <Grid container style={{paddingInline:20, paddingTop:20}}>
                                                    <Grid xs={12} >
                                                    {this.state.interviewSummaryArr && this.state.interviewSummaryArr.length?
                                                        this.state.interviewSummaryArr.map((score, index) => (
                                                            <Grid container>
                                                                <Grid xs={12} style={{paddingInline:10}}>

                                                                    <Typography variant={'h4'} style={{ textAlign:'justify', lineHeight:2 }}>
                                                                        {this.state.interviewSummaryArr.length?
                                                                            <EgSegmentSlicer text={ReportsManager.getTemplateText(score.trait_id, this.state.interviewSummaryArr, this.state.reportParts)} />:''
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                    )):''}
                                                    </Grid>
                                                </Grid>
                                            </Collapse>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={1}> </Grid>
                        <Grid xs={3}> 
                            <img src={interview_insights}  alt="overall" style={{width:'60%',maxHeight:'67vh', paddingTop:'2vh'}}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            }
           </div>

    );
  }
}
SingleReportInterview.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportInterview),SingleReportInterview);