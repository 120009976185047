import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import LangPicker from '../util/LangPicker';
import { AssessmentTypes } from '../util/constants/AssessmentTypes';

const ReportsManager = {
        
    findInitialAssessment: function(employee){
        const empOngoingIndAssess = employee.assessment.findIndex(({ type, completed_at }) => (type === AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT || type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT) && completed_at)
        return empOngoingIndAssess
       
    },

    findInitialReport: function(employee){
        const empOngoingIndAssess = employee.assessment.findIndex(({ type, completed_at }) => (type === AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT || type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT) && completed_at)
        if (empOngoingIndAssess > -1){
            const empOngoingIndReport = employee.report.findIndex(({ assessment_id }) => assessment_id === employee.assessment[empOngoingIndAssess].id)
            return empOngoingIndReport
        }
        return null
    },
    
    setRecommendationsSummary: function(report, reportParts){
        if(report && report.report_part && report.report_part.length && reportParts){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 5 )
            const reparts = reportParts
            const recommSummaryArr = report.report_part[ind].template_id.map(function(pid){
                const partind = reparts.findIndex(({ id }) => id === pid )
                if (partind > -1){
                    if (LangPicker.current() === 'en'){
                        return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                    }
                    else{
                        if(reparts[partind].report_template_localized[0]){  
                            return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                        }
                        else return "";
                    }
                }
                else{
                    return ""
                }
            })
           return recommSummaryArr;
        }
    },

    setInterviewSummary: function(report, reportParts){
        if(report && report.report_part && report.report_part.length){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 4 )
            const reparts = reportParts;
            const interviewSummaryArr = report.report_part[ind].template_id.map(function(pid){
                const partind = reparts.findIndex(({ id }) => id === pid )
                if (LangPicker.current() === 'en'){
                    return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                }
                else{
                    if (reparts[partind].report_template_localized[0]){
                        return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                    }
                    else return "";
                }
                
            })
            return interviewSummaryArr;  
        }
    },

    setTraitsSummary: function(report, reportParts){
        if(report && report.report_part && report.report_part.length && reportParts){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 2 )
            const reparts = reportParts;
            let extraTextArr = []
            let traitsSummaryArr = report.report_part[ind].template_id.map(function(pid){
                const partind = reparts.findIndex(({ id }) => id === pid )
                if (partind > -1){
                    if (reparts[partind].display_trait_ref && reparts[partind].display_trait_ref > 0){
                        extraTextArr.push(reparts[partind])
                        return null;
                    }
                    else{
                        if (LangPicker.current() === 'en'){
                            return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                        }
                        else{
                            if (reparts[partind].report_template_localized[0]){
                                return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                            }
                            else{
                                return ''
                            }
                        }
                    }
                }
                return null
            })
            traitsSummaryArr = traitsSummaryArr.filter(function(e){return e});
            if (extraTextArr.length > 0){
                extraTextArr.map(function(part){
                    const partind = traitsSummaryArr.findIndex(({ trait_id }) => trait_id === part.display_trait_ref )
                    if (partind > -1){
                        traitsSummaryArr[partind].text = traitsSummaryArr[partind].text  +"\n"+  part.text;
                    }
                    return null
                })
            }
            return traitsSummaryArr  
        }
    },

    async setExecSummary(report, reportParts){
        if(report && report.report_part && report.report_part.length){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 1 )
            const reparts = reportParts;
            let extraTextArr = []
            let execSummaryArr = report.report_part[ind].template_id.map(function(pid){
                const partind = reparts.findIndex(({ id }) => id === pid )
                if (partind > -1){
                    if (reparts[partind].display_trait_ref && reparts[partind].display_trait_ref > 0){
                        extraTextArr.push(reparts[partind])
                        return null;
                    }
                    else{
                        if (LangPicker.current() === 'en'){
                            return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                        }
                        else{
                            if (reparts[partind].report_template_localized[0]){
                                return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                            }
                            else{
                                console.error(`Missing translation in ${LangPicker.current()}, partind ${partind}, text: ${reparts[partind].text}`)
                                return({id: pid, trait_id: reparts[partind].trait_id,text:""})
                            }
                        }
                    }
                }
                return null
            })
            execSummaryArr = execSummaryArr.filter(function(e){return e});
            if (extraTextArr.length > 0){
                extraTextArr.map(function(part){
                    const partind = execSummaryArr.findIndex(({ trait_id }) => trait_id === part.display_trait_ref )
                    if (partind > -1){
                        execSummaryArr[partind].text = execSummaryArr[partind].text  +"\n"+  part.text;
                    }
                    return null;
                })
            }
            return execSummaryArr   
        }
    },

    setTimeSummary: function(report, reportParts){
        if(report && report.report_part && report.report_part.length){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 6 )
            const reparts = reportParts
            let timeSummaryArr = report.report_part[ind].template_id.map(function(pid){
                const partind = reparts.findIndex(({ id }) => id === pid )
                if (partind > -1){
                    if (LangPicker.current() === 'en') {
                        return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                    }
                    else{
                        if (reparts[partind].report_template_localized[0]) {
                            return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                        } else {
                            console.error(`Missing translation in ${LangPicker.current()}, partind = ${partind}, text= ${reparts[partind].text}`)
                            return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                        }
                        
                    }
                }
                return null;
            })
            timeSummaryArr = timeSummaryArr.filter(function(n){return n; });
            return timeSummaryArr
        }
    },

    setTimeRisk(reportParts){
        const tind = reportParts.findIndex(({ type }) => type === 6 )
        if (tind > -1){
            return reportParts[tind].severity
        }
    },
    getTraitsFromReport(report){
        const assessmentScores = report.assessment.assessment_scores
        let traitsArr = assessmentScores.map(function(scr){
            if (scr.trait.is_display){
                const traitName =  LangPicker.current() === 'en'?scr.trait.display_name:scr.trait.trait_localized[0].localized_name
                return ({id:scr.trait.id,name:traitName,dark:scr.trait.dark,trait_avg:parseFloat(scr.trait.avg_response_time/1000).toFixed(1), avg_time: parseFloat((scr.trait_aggregated_time/1000)/scr.trait_total_questions).toFixed(1)})
            }
            else{
                return null;
            }
        })
        traitsArr = traitsArr.filter(function(e){return e});
        return traitsArr
    },

    getTemplateText: function(tid, summaryArr, reportParts){
        if (reportParts && reportParts.length){
            const ind = summaryArr.findIndex(({ trait_id }) => trait_id === tid )
            if (ind > -1){
                return summaryArr[ind].text
            }
            else return '';
        }
    }
    
}
ReportsManager.propTypes = {
   cookies: instanceOf(Cookies).isRequired
};
export default withCookies(withRouter(ReportsManager));
