import { EngagementLevels } from "../util/constants/EngagementLevels"

export default function EgEngagementPatch(props) {
    let value = 0
    if (props.value) value = props.value
    const engLevels = EngagementLevels.get()
    return (
        
      <div>
          {
          
            value<props.boundaries[0].top?
                <div  style={{backgroundColor:engLevels[0].color,width:'100%', height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{engLevels[0].name}</div>
                :
                value<props.boundaries[1].top?
                <div  style={{backgroundColor:engLevels[1].color,width:'100%', height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{engLevels[1].name}</div>
                :
                <div  style={{backgroundColor:engLevels[2].color,width:'100%', height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{engLevels[2].name}</div>
            }
      </div>
    );
  }