import React from 'react';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles  } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Topbar from '../components/Topbar'
import { Grid } from '@material-ui/core';
import WelcomeBanner from './dashboard/WelcomeBanner';
import DashboardNote from './dashboard/DashboardNote';
import ExtendedNavBar from '../components/ExtendedNavBar';
import LatestActivityNote from './dashboard/LatestActivityNote';
import { withRouter } from 'react-router-dom';
import EgProgress from '../components/EgProgress';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { setAdminDetails } from "../redux/admin";
import { connect } from "react-redux";
import * as AppConstants from '../util/constants/AppConstants';
import EgHttp from '../util/EgHttp';
import candidates_dashboard from '../img/candidates_dashboard.svg';
import assigned_dashboard from '../img/assigned_dashboard.svg';
import reports_dashboard from '../img/reports_dashboard.svg';
import GenericErrorDialog from '../components/GenericErrorDialog';
import NavigationManager from '../managers/NavigationManager';

const drawerWidth = AppConstants.NavigationDrawerWidth;

const mapStateToProps = state => ({
  admin: state.admin.value
});

const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%',
    },
  
    appRight:{
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        background:"#FAFAFA",
      },
      marginRight: drawerWidth
    },
    appLeft:{
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        background:"#FAFAFA",
      },
      marginLeft: drawerWidth
    },
 
    //toolbar: //theme.mixins.toolbar,
    toolbar:{
      minHeigt:64,
      marginTop:50
    },
    
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width:'100%',
      height:window.innerHeight,

    },
    note:{
      marginTop:20,
    },
    latestNote:{
      marginTop:20,
    }
});

class Dashboard extends React.Component {
    state = {
      open: this.props.open,
      operationIcon: null,
      candidateCount: 0,
      assignedCount: 0,
      reportsCount: 0,
      eventLog:[],
      loading:<EgProgress />
    };

    constructor(props) {
      console.log('Dashboard ');
      super(props);  
      
      this.logout = this.logout.bind(this); 
      this.search = this.search.bind(this);  
      this.closeErrorDialog = this.closeErrorDialog.bind(this) ;

      
      if (!this.props.login.logged) {
          this.props.history.push('/signup') 
          return;
      } 
      else{
        this.setState({login:this.props.login})
        NavigationManager.setLast(this.props, '/dashboard')
      }
      this.getDashboardData()
    } 
    
    logout(){
      this.props.history.push('/signin') 
    }
    
    async getDashboardData(){
      console.log(this.props.login)
      console.log(this.props.admin)
      const { t } = this.props;
      this.setState({loading:<EgProgress />})
      try{
          let response = await EgHttp.get( '/candidates/count')
          if (response.status === 401) {
            this.props.history.push('/signin') 
            return;
          }
          let rowsres = await response.json();
          this.setState({candidateCount:rowsres});
          
          response = await EgHttp.get('/candidates/assigned/count')
          rowsres = await response.json();
          this.setState({assignedCount:rowsres});
          
          response = await EgHttp.get('/reports/count')
          rowsres = await response.json();
         
          this.setState({reportsCount:rowsres.count});
          response = await EgHttp.get('/orgs/assessments')
          rowsres = await response.json();
          this.setState({assessments:rowsres.avail_tests});

          response = await EgHttp.get('/events')
          rowsres = await response.json();
          const origres = [...rowsres]
          this.setState({eventLog:rowsres, loading:null});
          this.setState({originalRows:origres});
        }
        catch(err){
          console.log("Failed to get initial content")
          console.log(err)
          this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("General - Error")} message={t("General - There was an error loading the page")}></GenericErrorDialog>})
        }
    }

    filterItems(arr, query) {
      return arr.filter(function(el) {
        return (el.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1) || (el.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      })
    }
    closeErrorDialog(){
      this.setState({errDialogOpen:false});
      this.setState({errDialog:null});
  }

    search(term){
      this.setState({searchTerm: term})
      if (term && term.length>0){
        let filteredRows;
        filteredRows = this.filterItems(this.state.originalRows, term)
        this.setState({eventLog: filteredRows})
      }
      else{
        this.setState({eventLog: this.state.originalRows})
      }
    }

    
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
      return (
        <div className={classes.root} >
        <CssBaseline />
        <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} style={{boxShadow:"0px 0px 0px" }}>
        {this.state.loading}
            <Toolbar >
                <Topbar logout={this.logout} login={this.props.login} page={this.props.page} search={this.search}></Topbar>    
            </Toolbar>
            
        </AppBar>

       <ExtendedNavBar navPos={1}></ExtendedNavBar>

        <main className={classes.content}>
        
            <div className={classes.toolbar} />
            
              <Grid container   direction="row" alignItems="flex-start" alignContent="flex-start" style={{height:'90vh'}}>
                  <Grid item xs={12}>
                    <WelcomeBanner userName={this.props.login.account.firstname} assessments={this.state.assessments}></WelcomeBanner>
                  </Grid>
                  
                  <Grid item xs={3} >
                      <Grid container >
                          <Grid item xs={12} className={classes.note}>
                            <DashboardNote noteTitle={t('Dashboard - Note - Candidates')} noteValue={this.state.candidateCount} noteLink={'candidates'} icon={candidates_dashboard}></DashboardNote>
                          </Grid>
                          <Grid item xs={12} className={classes.note}>
                            <DashboardNote noteTitle={t('Dashboard - Note - Assessments Assigned')} noteValue={this.state.assignedCount} noteLink={'assigned'} icon={assigned_dashboard}></DashboardNote>
                          </Grid>
                          <Grid item xs={12} className={classes.note}>
                            <DashboardNote noteTitle={t('Dashboard - Note - Reports Ready')} noteValue={this.state.reportsCount} noteLink={'reports'} icon={reports_dashboard}></DashboardNote>
                          </Grid>
                      </Grid>
                  </Grid>
                  
                  <Grid item xs={9} className={classes.latestNote}>
                    <LatestActivityNote  noteTitle={t('Latest Activity')} eventLog={this.state.eventLog} loading={this.state.loading}></LatestActivityNote>
                  </Grid>
              
           
            </Grid>
            {this.state.errDialog}
        </main>
        </div>
    );
    
}}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
};

export default connect(mapStateToProps, { setAdminDetails }) (withTranslation()(withCookies(withRouter((withStyles(styles))(Dashboard),Dashboard))))

