import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography,  } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
//import LangPicker from '../../util/LangPicker';
import EngagementHistoryTable from './EngagementHistoryTable';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    insightBox:{
        border:'1px solid #E4E4E4',
        height:'40vh',
        overflow:'auto'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    },
    
  });

const INITIAL_ASSESS_COMPLETED = 2
const INITIAL_ASSESS_INVITED = 1
const INITIAL_ASSESS_NOT_INVITED = 0

class EmployeeCardSummaryHisory extends React.Component {
  
    /*
    constructor(props) {
        super(props);            
    }*/

    state = {
        employee: this.props.employee?this.props.employee:{},
        
    };

    componentDidMount(){
        if (this.props.employee){
            this.setState({employee: this.props.employee})
            this.setState({noInitial: this.props.noInitial})
            const assessInd = this.findInitialAssessment(this.props.employee)
            if (this.props.employee.assessment && this.props.employee.assessment.length>0  && assessInd>-1){
                this.props.employee.assessment[assessInd].completed_at? this.setState({intitalState:INITIAL_ASSESS_COMPLETED}): this.setState({intitalState:INITIAL_ASSESS_INVITED})
            }
            else{
                 this.setState({intitalState:INITIAL_ASSESS_NOT_INVITED})
            }
        }
        
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.employee && nextProps.employee !== this.state.employee){
            this.setState({employee:nextProps.employee}); 
        }
        if (nextProps.noInitial && nextProps.noInitial !== this.state.noInitial){
            this.setState({noInitial:nextProps.noInitial}); 
        }
        
    }

    findInitialAssessment(employee){
        const empOngoingIndAssess = employee.assessment.findIndex(({ type, completed_at }) => (type === AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT || type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT) && completed_at)
        return empOngoingIndAssess
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;

        return (
            <Paper elevation={0} style={{ overflow:'hidden', height:'40vh'}} className={classes.insightBox}  >
                
                <Typography variant={'h4'} className={classes.boxTitle}>{t('Employee - Single - Assessment History')} </Typography>
                <Grid container style={{paddingTop:'1vh', paddingBottom:'1vh'}}>
                    
                    <Grid xs={12} >
                        
                        <Paper elevation={0} style={{ overflow:'auto', height:'40vh'}} >
                            <Grid container>
                               <EngagementHistoryTable employee={this.state.employee} remind={this.props.remind}></EngagementHistoryTable>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>      
            </Paper>
        );
    }
}
EmployeeCardSummaryHisory.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(EmployeeCardSummaryHisory),EmployeeCardSummaryHisory);