import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {cand:false, emp:false},
}

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setNavCand: (state,action) => {
        state.value.cand = action.payload
    },
    setNavEmp: (state,action) => {
        state.value.emp = action.payload
    }
  },
  
})
// Action creators are generated for each case reducer function
const { actions, reducer } = navSlice;
export const { setNavCand, setNavEmp } = actions;
export default reducer;