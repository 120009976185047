import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, incrementBy } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import CloseOutlinedIcon from '@material-ui/icons//CloseOutlined';
import EgButton from '../../components/EgButton';
import AppUtils from '../../util/AppUtils';
//import EventLog from '../../util/EventLog';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
      width:500,
      maxWidth: 500,
    },
    
    postit:{
        backgroundColor: theme.palette.primary.light,
        display:'flex',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center',
        minHeight:50,
    },
    tableRightRow:{
        display:'flex', 
        alignItems:'center', 
        padding:15, 
        borderTop:'1px solid #efefef', 
        justifyContent:'start', 
        borderBottom:'1px solid #efefef', 
    },
    tableLeftRow:{
        display:'flex', 
        alignItems:'center', 
        padding:15, 
        borderTop:'1px solid #efefef', 
        borderBottom:'1px solid #efefef', 
        justifyContent:'end',
    },
    closeIcon: {
        fontSize:14, 
        textAlign:'end', 
        paddingTop:10,
        paddingBottom:10
    },
    errMsg: {
        color: theme.palette.error.main,
    }
})


class PayDialog extends React.Component {
  
    state = {
        open: this.props.open,
        candidate:this.props.candidate,
        errorMsg:null,
        currencySymbol:AppUtils.getCurrencySymbol('en', this.props.package.currency)
    };

    constructor(props) {
        super(props);  
        
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 
        this.buyPackage = this.buyPackage.bind(this);
        this.approved = this.approved.bind(this); 
        this.failed = this.failed.bind(this); 
        //this.XXXaprove = this.XXXaprove.bind(this); 
        
        console.info("IN PAY DIALOG");
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});    
            console.log(nextProps.candidate)       
        }
    }

    close(){
        this.handleClose();
    }

    async buyPackage(cost){
        
        let response = await EgHttp.put('/orgs/package', JSON.stringify({
                orgId: this.props.admin.org.id,
                licenses:this.props.package.licenses
        }))
        console.log("Buy Package res:" + response.status)
        if (response.status === 200){
            return true;
        }
        else{
            console.log("FAILED TO ADD PACKAGE")
            return false
        }
    }

    async approved(){
        this.setState({open:false})
        this.props.closeDialog();
        const buySuccess = await this.buyPackage()
        if (buySuccess){
            //EventLog.insert(this.props.login.org.id, 0, EventLog.PACKAGE_ADDED, 0)
            this.props.incrementBy(this.props.package.licenses);
            this.props.refresh("SUCCESS", 'Congratulations! New assessments package was assigned to your account');
            this.handleClose();
        }
        
    }
    failed(err){
        const { t } = this.props;
        const { classes } = this.props;
        this.setState({errorMsg:<Typography variant={'h4'} className={classes.errMsg}>{t('Settings - Pay - There was a problem with your payment')}</Typography> })
        console.log("TRANSACTION FAILED")
        console.log(err)
    }

    handleClickOpen(){
        this.setState({open:true})
    };

    //async XXXaprove(){
    //    await this.approved()
    //}

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{width:550, maxWidth:550, padding:0}}>
                   <Paper elevation={0}>
                         <Grid container justify='left' >
                           
                            <Grid item xs={4 } justify="center" className={classes.postit}>
                               <Typography variant={'h4'} style={{color:'#0090B0'}}> <span style={{fontWeight:600, fontSize:18}}>{t(`Settings - Packages - ${this.props.package.name}`)}</span> </Typography>
                            </Grid>
                            <Grid item xs={8} style={{paddingTop:5, paddingInlineEnd:5, textAlign:'end'}}>
                                <EgButton secondary="true" className={classes.closeIcon} onClick={this.handleClose}>
                                    <CloseOutlinedIcon ></CloseOutlinedIcon>
                                </EgButton>
                                
                            </Grid>
                            
                            <Grid item xs={12} style={{padding:20}}>
                                <Grid container>
                                <Grid item xs={6} className={classes.tableLeftRow}>
                                    <Typography variant={'h4'}>{this.props.package.licenses}{" " + t('Settings - Pay - Assessments') + " "}</Typography> 
                                </Grid>
                                <Grid item xs={6} className={classes.tableRightRow}>
                                    <Typography variant={'h4'} style={{color:'#000000'}}>{this.state.currencySymbol}{this.props.package.totalCost}</Typography> 
                                </Grid>
                                <Grid item xs={12} style={{paddingTop:30, }}>
                                    <Typography variant={'h4'}>{t('Settings - Pay - Select your payment method')}</Typography> 
                                </Grid>
                                <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <PayPalScriptProvider options={{"client-id":"AY-czcgi0_GdUHiXaLNftBMK5TB_YTUG2cnpCZn-SYCTQBG6ECMjpgo0olzonl1WLQGyDyoC6C14yO6s", 
                                                                /*"client-id":"Ad_PbVKzREtoUwbIv_jswYf9lucHqRm5ObtznWlsviyD_YXUwt_rQ26K_VSjHari8rB8eItioQf9AsKr",*/
                                                                "locale":"en_IL", 
                                                                "enable-funding":"paypal,card"
                                                                }}>
                                        <PayPalButtons     
                                            style={{ layout: "vertical", color:"blue" }}  
                                            onApprove={(data, actions) => {
                                                console.log('** Paypal Order approved **')
                                                console.dir(data)
                                                return actions.order.capture().then(async (details) => {
                                                    console.log('Paypal transaction ended:')
                                                    console.dir(details)
                                                    await this.approved()
                                                });
                                            }}
                                            onError={this.failed}
                                            createOrder={(data, actions) => {
                                                return actions.order
                                                    .create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    currency_code: this.props.package.currency,
                                                                    value: this.props.package.totalCost,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                    .then((orderId) => {
                                                        // Your code here after create the order
                                                        console.log("OrderId:" + orderId)
                                                        return orderId;
                                                    });
                                            }}
                                        />
                                    </PayPalScriptProvider>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop:30, }}>
                                    {this.state.errorMsg}
                                </Grid>
                            </Grid>
                            </Grid>
                       </Grid> 
                   </Paper>
                </DialogContent> 
            </Dialog>
            </div>
        );
    }
}

PayDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, incrementBy })(withTranslation()(withRouter((withStyles(styles))(PayDialog),PayDialog)))