import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import novideo from '../../img/no-video.png';
import EgButton from '../../components/EgButton';
import EgHttp from '../../util/EgHttp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SentimentBar from '../../components/SentimentBar';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
    videoBox:{
        border:'1px solid #E4E4E4',
        height:'75vh',
        overflow:'auto',
        marginInline:10
    },
    questionBox:{
        border:'0px solid #E4E4E4',
        height:'30vh',
        overflow:'auto',
        marginInline:0
    },
    answerBox:{
        border:'0px solid #E4E4E4',
        height:'35vh',
        overflow:'auto',
        marginInline:0
    },
    boxTitle:{
        padding:20,
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    
    
  });

class SingleReportRemoteInterview extends React.Component {
  
    constructor(props) {
        super(props);            
       
        this.setCameraDuration = this.setCameraDuration.bind(this)
        this.setScreenDuration = this.setScreenDuration.bind(this)
        this.nextQuestion = this.nextQuestion.bind(this)
        this.prevQuestion = this.prevQuestion.bind(this)
        this.startPlaying = this.startPlaying.bind(this)
    }

    state = {
        report: this.props.report?this.props.report:{},
        remoteAvail:false,
        playing:false
    };

    componentDidMount(){
        if (this.props.report){
            this.setState({report:this.props.report})
            this.initalizeRemoteInterview(this.props.report)
        }
    }

    componentWillReceiveProps(nextProps) {   

        if (nextProps.report && nextProps.report.reportId){
            this.setState({report:nextProps.report}); 
            this.initalizeRemoteInterview(nextProps.report)
        }
    }

    setCameraDuration(sec){
        this.setState({videoCameraDuration:sec})
    }
    setScreenDuration(sec){
        this.setState({videoScreenDuration:sec})
    }

    async getMediaLink(intwId, questionId) {
        const LINK_EXPIRATION_TIME = 300 // FIVE MINUTES
        const url = `/remoteinterviews/medialink/${intwId}/${questionId}/${LINK_EXPIRATION_TIME}`
        console.log('Getting interview video:')
        console.log(url)
        const shit = await EgHttp.get(url, false)
        const mediaLink = await shit.text()
        console.log(' -- got it -->')
        console.log(mediaLink)
        return mediaLink
    }

    async getTranscription(intwId, questionId) {
        try{
            const url = `/remoteinterviews/transcriberesponse/${intwId}/${questionId}`
            console.log('Getting transcription:')
            console.log(url)
            const request = await EgHttp.get(url, false)
            if (request.status === 200){
                const transcription = await request.json()
                console.log(' -- got it -->')
                if (transcription && transcription.transcription && transcription.transcription.data){
                    console.log(transcription.transcription.data)
                    return transcription.transcription.data
                }
                else{
                    return null
                }
            }
            else return null
        }
        catch(err){
            console.error(err)
        }
    }

    getSentimentScore(trans){
        const sentimentArr = trans.sentiment_analysis_results
        let overAllScore = 0
        console.log("IN SENTIMENT")
        if (!sentimentArr || sentimentArr.length === 0) return -1
        sentimentArr.forEach(response => {
            const factor = response.confidence*100;
            if(response.sentiment === 'NEGATIVE'){
                overAllScore += 50-(50*response.confidence)
            }
            else if (response.sentiment === 'POSITIVE'){
                overAllScore += 50+(50*response.confidence)
            }
            else { //NEUTRAL
                overAllScore += 50
            }
        })
        overAllScore = overAllScore/sentimentArr.length
        console.log("RETURNING SCORE:" + overAllScore)
        return overAllScore
    }

    async initalizeRemoteInterview(report){
        console.log("INTIALIZING REMOTE - Interview:")
        console.dir(report.assessment.remote_interview)
        if (report.assessment.remote_interview){
            this.setState({remoteAvail:true})
            const questions = report.assessment.remote_interview.responses.map(function(r){return r.open_question})//report.assessment.remote_interview.questionnaire.questions
            report.assessment.remote_interview.questionnaire.questions = questions
            this.setState({questionnaire:report.assessment.remote_interview.questionnaire })
            const firstQuestion = questions[0]
            console.log(`Getting media link for question ${JSON.stringify(firstQuestion)}`)
            const mediaLink = await this.getMediaLink(report.assessment.remote_interview.id, firstQuestion.id)
            const transcription = await this.getTranscription(report.assessment.remote_interview.id, firstQuestion.id)
            let sentimentScore = null
            if (transcription) sentimentScore = this.getSentimentScore(transcription)
            this.setState({mediaLink: mediaLink})
            this.setState({transcription: transcription})
            this.setState({questionNumber:1, currentQuestion: firstQuestion})
            this.setState({prevDisabled:true})
            this.setState({sentimentScore})
            if ( report.assessment.remote_interview.questionnaire.questions.length === 1) this.setState({nextDisabled:true})
        }
    }
    
    async nextQuestion(){
        this.setState({playing:false})
        if (this.state.questionNumber < this.state.questionnaire.questions.length ){
            const qn = this.state.questionNumber
            const currentQuestion = this.state.questionnaire.questions[qn]
            const mediaLink = await this.getMediaLink(this.state.report.assessment.remote_interview.id, currentQuestion.id)
            const transcription = await this.getTranscription(this.state.report.assessment.remote_interview.id, currentQuestion.id)
            let sentimentScore = null
            if (transcription) sentimentScore = this.getSentimentScore(transcription)
            this.setState({mediaLink: mediaLink})
            this.setState({transcription: transcription})
            this.setState({questionNumber:qn+1, currentQuestion:currentQuestion})
            this.setState({prevDisabled:false})
            this.setState({sentimentScore})
            if(qn+1 === this.state.questionnaire.questions.length) this.setState({nextDisabled:true})
        }
        else{
            this.setState({nextDisabled:true})
        }
    }
    async prevQuestion(){
        this.setState({playing:false})
        if (this.state.questionNumber > 1 ){
            const qn = this.state.questionNumber
            const currentQuestion = this.state.questionnaire.questions[qn-2]
            const mediaLink = await this.getMediaLink(this.state.report.assessment.remote_interview.id, currentQuestion.id)
            const transcription = await this.getTranscription(this.state.report.assessment.remote_interview.id, currentQuestion.id)
            let sentimentScore = null
            if (transcription) sentimentScore = this.getSentimentScore(transcription)
            this.setState({transcription: transcription})
            this.setState({mediaLink: mediaLink})
            this.setState({questionNumber:qn-1, currentQuestion:currentQuestion})
            this.setState({nextDisabled:false})
            this.setState({sentimentScore})
            if(qn-1 === 1) this.setState({prevDisabled:true})
        }
        else{
            this.setState({prevDisabled:true})
        }
    }
    startPlaying(){
        this.setState({playing:!this.state.playing})
    }

    copyClipFull(){
        navigator.clipboard.writeText(this.state.transcription.text);
    }
    copyClipSummary(){
        navigator.clipboard.writeText(this.state.transcription.summary);
    }


    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <Grid container style={{height:'80vh', backgroundColor:'#FAFAFB', borderTop:'1px solid #e8e8e8'}}>

                <Grid item xs={12} className={classes.formRow}>
                    <Paper elevation={0} className={classes.videoBox}>
                        <Typography variant={'h4'} className={classes.boxTitle}>{t('Remote - Assign - Remote Interview')} 
                            {this.state.report.assessment && this.state.report.assessment.assessment_attributes.media?
                            <span style={{backgroundColor: '#efefef', padding:6, fontWeight:500, marginLeft:10, marginRight:20, borderRadius:5, fontSize:12}}><Moment format="mm:ss [min]">{this.state.videoCameraDuration*1000}</Moment></span> :''}
                        </Typography>
                        {this.state.remoteAvail?
                        <Grid container justify='center' style={{paddingTop:7}}>
                            <Grid xs={6} style={{padding:10, display:'flex', justifyContent:'center'}}>
                                
                                <Grid container>
                                    <Grid xs={12} style={{display:'flex', justifyContent:'start', paddingInlineStart:20, }}><Typography style={{fontWeight:600}}>{t('Assessment - Questions - Question') + " "} {this.state.questionNumber}  </Typography></Grid>
                                    <Grid xs={10} style={{display:'flex', justifyContent:'start', paddingInlineStart:20, paddingTop:'2vh'}}>
                                        <Grid container>
                                            <Grid xs={12}>
                                                <Paper elevation={0} className={classes.questionBox} style={{ height:this.state.transcription?'6vh':'30vh'}}>
                                                    <Typography style={{textAlign:'start', fontSize:this.state.transcription?14:16 }}>{this.state.currentQuestion?this.state.currentQuestion.text:''}</Typography>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={2} />
                                    {this.state.transcription?
                                    <Grid container style={{paddingTop:'2vh'}}>
                                        <Grid xs={10} style={{display:'flex', justifyContent:'start',  paddingInlineStart:20, backgroundColor:'#fafafa', paddingTop:10, paddingBottom:10}}><Typography style={{textAlign:'start', fontSize:16 ,fontWeight:500}}>{t('Assessment - Questions - Answer') }  </Typography></Grid>
                                        
                                        <Grid xs={10} style={{display:'flex', justifyContent:'start', paddingInlineStart:20, paddingTop:'1vh'}}>
                                            <Grid container>
                                            <Paper elevation={0} className={classes.answerBox}>
                                                <Grid container>
                                                <Grid xs={5} style={{display:'flex', justifyContent:'start', paddingTop:5,paddingBottom:20}}>
                                                    <Typography style={{textAlign:'start', fontSize:14, fontWeight:600}}>{t('Assessment - Questions - Summary')} </Typography>
                                                </Grid>
                                                <Grid xs={3} style={{display:'flex', justifyContent:'start', paddingTop:7,paddingBottom:20}}>
                                                    <Typography style={{textAlign:'start', fontSize:12, fontWeight:500}}>{t('Overall Sentiment')}: </Typography>
                                                </Grid>
                                               
                                                <Grid xs={4} style={{display:'flex', justifyContent:'start', alignItems:'center',paddingBottom:20, paddingTop:5}}>
                                                        {this.state.sentimentScore?
                                                        <SentimentBar value={this.state.sentimentScore}/>:''}     
                                                </Grid>
                                                <Grid xs={12}>
                                                    <Typography style={{textAlign:'start', fontSize:14, fontWeight:400}}>{this.state.transcription.summary && this.state.transcription.summary.startsWith('-')?this.state.transcription.summary.substring(2):this.state.transcription.summary} </Typography>
                                                </Grid>
                                             
                                                
                                                <Grid xs={10} style={{display:'flex', justifyContent:'start', paddingTop:20, paddingBottom:10}}>
                                                    <Typography style={{textAlign:'start', fontSize:14, fontWeight:600}}>{t('Assessment - Questions - Full Answer')} </Typography>
                                                </Grid>
                                                <Grid xs={2} style={{display:'flex', alignItems:'end', paddingBottom:4}}>
                                                    <EgButton small secondary="true" onClick={this.copyClipFull()}>Copy Text</EgButton>
                                                </Grid>
                                                <Grid xs={12}>
                                                    <Typography style={{textAlign:'start', fontSize:14}}>{this.state.transcription.text}</Typography>
                                                </Grid>
                                            </Grid>
                                            </Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={2} />
                                    </Grid>:''}
                                    <Grid xs={4} style={{display:'flex', justifyContent:'center', paddingInlineStart:20, paddingTop:'5vh'}}><EgButton secondary="true" outlined="true" disabled={this.state.prevDisabled} onClick={this.prevQuestion}>{t('Assessment - Remote - Previous Question')} </EgButton></Grid>
                                    <Grid xs={2} style={{display:'flex', justifyContent:'center', paddingInlineStart:20, paddingTop:'5vh'}}><EgButton  onClick={this.startPlaying}>{!this.state.playing?<PlayArrowIcon />:<PauseIcon />}</EgButton></Grid>
                                    <Grid xs={4} style={{display:'flex', justifyContent:'center', paddingInlineStart:20, paddingTop:'5vh'}}><EgButton secondary="true" outlined="true" disabled={this.state.nextDisabled} onClick={this.nextQuestion}>{t('Assessment - Remote - Next Question')} </EgButton></Grid>
                                    <Grid xs={2} />
                                </Grid>
                            </Grid>
                            <Grid xs={6} style={{padding:10, display:'flex', justifyContent:'center'}}>
                                {this.state.mediaLink?
                                <div style={{position:'relative'}} >
                                    <ReactPlayer url={this.state.mediaLink?this.state.mediaLink+'#t=0.1':''} controls={true} height='47vh' width='40vw' onDuration={this.setCameraDuration} style={{position:'relative'}} playing={this.state.playing} onEnded={this.startPlaying}/>
                                </div>:
                                <div>
                                    <img src={novideo} style={{width:60, margin:20}} alt="novideo"></img>
                                    <Typography variant='h4'>{t('Report - Video - Video is not available')}</Typography>
                                </div>}
                            </Grid>
                        </Grid>:
                        <Grid container justify='center' style={{paddingTop:7}}>
                            <Grid xs={6} style={{padding:10, display:'flex', justifyContent:'center'}}>
                                <Typography>{t('Report - Remote - Remote interview is not available')} </Typography>
                            </Grid>
                        </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>

    );
  }
}
SingleReportRemoteInterview.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportRemoteInterview),SingleReportRemoteInterview);