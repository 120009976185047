import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Grid,Typography } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import EgHtmlToolTip from './EgHtmlToolTip';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%',
      minWidth:100,
    },
    singleBar:{
        margin:10,
    },
    goodBar:{
        backgroundColor: theme.palette.severity.low,
        width:'85%',
        height:150,
        borderTopLeftRadius:3,
        borderTopRightRadius:3,
    },
    medBar:{
        backgroundColor: theme.palette.severity.med,
        width:'85%',
        height:150,
        borderTopLeftRadius:3,
        borderTopRightRadius:3,
    },
    badBar:{
        backgroundColor: theme.palette.severity.high,
        width:'85%',
        height:150,
        borderTopLeftRadius:3,
        borderTopRightRadius:3,
    }, 
    tooltip: {
        backgroundColor: '#fefefe',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 120,
        width: 120,
        fontSize: 13,
        border: '1px solid #0085AF',
        borderRadius: 5,
        padding:10,
        fontWeight:500,
        boxShadow:'2px 2px 2px #888888',
       
      },
})


class EgBarChart extends React.Component {
  
    state = {
        value:this.props.value,
        boundaries: this.props.boundaries,
        showWarnings: this.showWarnings,
        horizBars:[10,20,30,40,50,60,70,80,90,100]
    };

    componentDidMount() {
        this.setState({value:this.props.value})
        this.setState({boundaries: this.props.boundaries})
        this.setState({isNagative: this.props.negative})
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.value && nextProps.value!==this.state.value ){             
            this.setState({value:nextProps.value});     
        }
        if (nextProps.boundaries && nextProps.boundaries!==this.state.boundaries ){             
            this.setState({boundaries:nextProps.boundaries});     
        } 
    }
    showToolTip(event, score, show){
        const { t } = this.props;
        event.stopPropagation()
        if(show){
            this.setState({hoverTooltip:<div style={{position:'absolute', top:event.target.offsetTop, left:event.target.offsetLeft+30, zIndex:3}} className={this.props.classes.tooltip}><div style={{textAlign:'start', paddingInlineStart:10}}>{t("General - Date") + ": "  + score.x}</div><div style={{textAlign:'start', paddingInlineStart:10}}>{t("General - Score") + ": "  + score.y}</div></div>})
        }
        else this.setState({hoverTooltip:''})
        
    }
    
    render(){
        const { classes } = this.props;
        
        return (
           <Grid  container style={{border:1, width:'100%', height:'100%', display:'block', alignItems:'end', direction:'ltr'}}>
                <Grid item xs={12}style={{height:'90%', paddingInline:30, position:'relative'}} >
                    
                    <Grid  container style={{borderInlineStart:'1px solid #eaeaea', width:'100%', height:'100%', display:'flex', alignItems:'end'}}>
                        
                        {this.state.value?this.state.value.map((score) => (
                        <Grid xs={1} style={{height:'100%', display:'flex', flexDirection:'column-reverse', alignItems:'end', paddingInline:2,zIndex:2 }}>
                                <div  onMouseEnter={(event)=>this.showToolTip(event, score, true)} onMouseLeave={(event)=>this.showToolTip(event, score, false)}
                                    className={
                                        this.props.negative?
                                            this.state.boundaries?
                                                score.y > this.state.boundaries[2].bottom?classes.badBar:
                                                score.y > this.state.boundaries[1].bottom?classes.medBar:
                                                classes.goodBar
                                            :classes.goodBar
                                            :
                                            this.state.boundaries?
                                                score.y < this.state.boundaries[0].top?classes.badBar:
                                                score.y < this.state.boundaries[1].top?classes.medBar:
                                                classes.goodBar
                                            :classes.goodBar
                                        } 
                                        style={{height:`${score.y}%`, fontSize:12, fontWeight:500, color:'#ffffff'}}>
                                    {score.y}
                                </div> 
                               
                            {this.props.showWarnings && this.state.boundaries && score.y < this.state.boundaries[0].top && !this.props.negative?
                            <EgHtmlToolTip title={this.props.warnTitle} body={this.props.warnBody} context={'alert'}
                                innerElement={
                                <WarningRoundedIcon style={{marginInline:5,color:'#FC264D', marginTop:0, cursor:'pointer'}} />}
                            />:
                            this.props.showWarnings && this.state.boundaries && score.y < this.state.boundaries[1].top && !this.props.negative?
                            <EgHtmlToolTip title={this.props.warnTitle} body={this.props.warnBody} context={'warn'}
                                innerElement={
                                <WarningRoundedIcon style={{marginInline:5,color:'#F9A846', marginTop:0, cursor:'pointer'}} />}
                            />:
                           
                            this.props.showWarnings && this.state.boundaries && score.y > this.state.boundaries[2].bottom && this.props.negative?
                            <EgHtmlToolTip title={this.props.warnTitle} body={this.props.warnBody} context={'alert'}
                                innerElement={
                                <WarningRoundedIcon style={{marginInline:5,color:'#FC264D', marginTop:0, cursor:'pointer'}} />}
                            />:
                            this.props.showWarnings && this.state.boundaries && score.y > this.state.boundaries[1].bottom && this.props.negative?
                            <EgHtmlToolTip title={this.props.warnTitle} body={this.props.warnBody} context={'warn'}
                                innerElement={
                                <WarningRoundedIcon style={{marginInline:5,color:'#F9A846', marginTop:0, cursor:'pointer'}} />}
                            />:
                            ''}
                        </Grid>
                        )):''}
                        {this.state.hoverTooltip}
                        {this.state.horizBars.map((val)=>(
                            <div style={{width:'87%', height:1, position:'absolute', left:30, bottom:`${val}%`, borderTop:'dashed #f0f0f0 1px', paddingInline:30, zIndex:1, textAlign:'start', fontSize:9}}><span style={{marginInlineStart:-48, marginTop:-20}}>{val}</span></div>
                        ))}
                    </Grid>
                    
                </Grid>
               
                    <Grid item xs={12} style={{ paddingInline:30}}>
                        <Grid  container style={{borderTop:'1px solid #eaeaea', width:'100%', height:'100%', display:'flex', alignItems:'start'}}>
                            <Grid item xs={12} style={{ paddingTop:5}}></Grid>
                            {this.state.value?this.state.value.map((score) => (
                            <Grid xs={1} style={{height:'100%', display:'flex', alignItems:'end', paddingInlineStart:3}}>
                                    <Typography style={{fontSize:9, paddingInlineStart:5}}>{score.x}</Typography>
                            </Grid>
                            )):''}
                        </Grid>
                    </Grid>
                
           </Grid>
        )
        }
    }

    
EgBarChart.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(EgBarChart),EgBarChart);