import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import welcome from '../../img/welcome2.svg';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: 25,
      textAlign: 'start',
      color: theme.palette.text.secondary,
      width:'100%',  
    },
    
    testsAvail:{
        fontSize:28,
        color:"#5F6066",
        fontWeight: 600,
    },
    buyLink:{
        textDecoration:"none",
        color: theme.palette.primary.main,

    },
    
    welcomeImg:{
        width:120,
        maxWidth:120,
        marginInlineEnd:30,
    },
    actionButton:{
        //marginLeft:30,
        //marginRight:30
    }
    
  });
  
class WelcomeBanner extends React.Component {
  
    state = {
        open: this.props.open,
        operationIcon: null,
        userName: this.props.userName,
        assessments: this.props.assessments
    };

    componentWillReceiveProps(nextProps) {   
        if (nextProps.assessments!==this.state.assessments ){             
            this.setState({assessments:nextProps.assessments});           
        } 
    }   
  
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div>

            <Grid container spacing={3}>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={1} style={{textAlign:'start', paddingInlineEnd:30}}>
                            <img src={welcome} className={classes.welcomeImg} alt="welcome" />
                        </Grid>
                        <Grid item xs={11} style={{paddingInlineStart:40}}>
                            <Typography variant={'h1'} style={{fontSize:28, paddingInlineStart:0, paddingTop:15, paddingBottom:10}}> {t('Dashboard - Hello')} {this.props.userName}, {t('Dashboard - You have')} <span className={classes.testsAvail}>{this.state.assessments>0?this.state.assessments:'0'}</span> {t('Dashboard - assessments available')}</Typography>
                            
                            <EgButton secondary="true" outlined="true" className={classes.actionButton}><Link className={classes.buyLink}to="/settings" >{t('Dashboard - Get More Assessments')}</Link></EgButton>
                        </Grid>
                        
                        
                    </Grid>
                    </Paper>
                </Grid>
                
            
            </Grid>
            </div>
        );
    }
}
WelcomeBanner.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(WelcomeBanner),WelcomeBanner);