import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import Fab from '@material-ui/core/Fab';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppUtils from '../util/AppUtils';
import { Paper, List, ListItem, Typography, Divider } from '@material-ui/core';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../redux/admin";
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import searchIcon from '../img/search_icon.svg'
import questionIcon from '../img/question_icon.svg'

const mapStateToProps = state => ({
  admin: state.admin.value
});

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    email:{
      color:"#333333",
      fontSize:14,
    },
    
    searchTxt:{
        maxWidth: 366,
        width:366,
        '& label.Mui-focused': {
          color: theme.palette.primary.main,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: '#E4E4E4',
          },
          '&:hover fieldset': {
              borderColor: theme.palette.primary.main,
          },
          '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main,
          },
        }
      
    },
    
    accountAvatar:{
        background:theme.palette.primary.dark,
        color:theme.palette.text.white,
        fontSize:14,
        width:38,
        height:38
        
    },
    accountAvatarBig:{
      background:theme.palette.primary.dark,
      color:theme.palette.text.white,
      fontSize:18,
      width:48,
      height:48
  },
    listItem:{
      color:"#333333",
      
      fontSize:16,
      justifyContent:'center'
  },
  listMain:{
      color:"#333333",
      fontSize:18,
      justifyContent:'center',
      fontWeight: 600,
  },
  helpBtn:{
        width:40,
        height:40,
        color:theme.palette.text.white ,
  },
  AvatarBtn:{
      width:38,
      height:38,
      color:theme.palette.text.white ,
  },
  moreMenuHe:{
    position:'absolute', 
    top: 70, 
    left:10, 
    width:300, 
    zIndex:1001
  },
  moreMenuEn:{
    position:'absolute', 
    top: 70, 
    right:10, 
    width:300, 
    zIndex:1001,
  },
  inputLabel:{
    '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: '#043241',
        opacity: 1,
      },
      fontSize:14,
      height:20,
},
})
  
class Topbar extends React.Component {
  
  state = {
    page: this.props.page,
    helpOpen: false,
  };

  constructor(props) {
    super(props);   
    this.openMoreMenu = this.openMoreMenu.bind(this);
    this.filter = this.filter.bind(this);
    this.logout = this.logout.bind(this);
    this.openHelp = this.openHelp.bind(this);
  } 

  logout(){
    this.props.setAdminDetails(null);
    this.props.cookies.remove("jwt")
    this.props.history.push('/signin');
  }
  openProfile(){
    this.props.history.push('/settings/profile');
  }
  getAssessments(){
    this.props.history.push('/settings');
  }

  filter(event){
    this.props.search(event.target.value)
  }

  openHelp(){
    
    //if (this.state.helpOpen){
    //  window.FreshworksWidget('hide', 'launcher');
    //  this.setState({helpOpen:false})
    //}
    //else{
      //window.FreshworksWidget('show', 'launcher');
      window.FreshworksWidget('open');
      //this.setState({helpOpen:true})
    //}
  }
  
  openMoreMenu(){
        const { t, i18n } = this.props;
        let item;
        if (!this.state.moreMenuOpen){
            item =  <Paper className={i18n.dir()==='rtl'?this.props.classes.moreMenuHe:this.props.classes.moreMenuEn} >
                        <Grid container justifyContent="center" direction="row">
                        <Grid xs={12} style={{display:'flex', justifyContent:'center', paddingTop:20, paddingBottom:10}}>
                            <Avatar className={this.props.classes.accountAvatarBig}>{AppUtils.getInitials(this.props.login.account.fullname)}</Avatar>
                        </Grid>
                        <Grid xs={12} style={{display:'flex', justifyContent:'center', padding:5}}>   
                            <Typography className={this.props.classes.listMain} >{this.props.login.account.fullname}</Typography>
                        </Grid>
                        <Grid xs={12} style={{display:'flex', justifyContent:'center', paddingTop:5, paddingBottom:20}}>
                            <Typography className={this.props.classes.email} >{this.props.login.account.email}</Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                        <List component="nav">
                            <ListItem key={"del"} className={this.props.classes.listItem}  button onClick={()=>this.openProfile()}>{t('Topbar - Profile')}</ListItem>
                            <ListItem key={"edit"} className={this.props.classes.listItem} button onClick={()=>this.getAssessments()}>{t('Topbar - Get More Assessments')}</ListItem>
                        </List>
                      <Divider />
                      <List component="nav">
                          <ListItem key={"edit"} className={this.props.classes.listItem} button onClick={()=>this.logout()}>{t('Topbar - Logout')}</ListItem>    
                      </List>
                  </Paper> 
        }
        else{
            item = null;
        }
        this.setState({moreMenuOpen:item});

}
  
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
        
        <Grid container spacing={0}  alignItems="center">
            
            <Grid item xs={8} style={{textAlign:"start"}}>
                <TextField variant="outlined"  id="search" label="" placeholder={t('Topbar - Search')} name="search" autoComplete="search" 
                                margin="dense"
                                onChange={this.filter}
                                InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={searchIcon} alt="searchicon"></img>
                                  </InputAdornment>
                                ),
                                classes: { input: classes.inputLabel }, 
                              }}
                            className={classes.searchTxt}/>
            </Grid>
            <Grid item xs={4} style={{display:'flex', justifyContent:'end', paddingInlineEnd:0}}>
                        <span>
                        <Fab onClick={this.openHelp} className={classes.helpBtn} aria-label="add" style={{boxShadow:'none', backgroundColor:'#ffffff', border:'1px solid #E4E4E4'}} >
                            <img src={questionIcon} alt="questionicon" style={{width:19}}/>
                        </Fab>
                        <Fab className={classes.AvatarBtn} aria-label="add" style={{boxShadow:'none',marginLeft:10,marginRight:10 }}>
                          <Avatar className={classes.accountAvatar} onClick={()=>{this.openMoreMenu()}}>{AppUtils.getInitials(this.props.login.account.fullname)}
                           
                          </Avatar>
                        
                        </Fab>
                        {this.state.moreMenuOpen} 
                        </span>
    
            </Grid> 
        </Grid>     
      
    );
  }
}
Topbar.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };
  
export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withCookies(withRouter((withStyles(styles))(Topbar),Topbar))))