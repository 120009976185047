import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import GradeBar from '../../components/GradeBar'
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgProgress from '../../components/EgProgress';
import { Statuses } from '../../util/constants/AsigneeStatus';
import * as AppConstants from '../../util/constants/AppConstants';
import EgButton from '../../components/EgButton';
import CompareLookalikeToModel from './CompareLookalikeToModel';
import LangPicker from '../../util/LangPicker';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:'1.5vh'
    },
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:theme.palette.text.black,
        border:0,
        paddingBottom:'1.5vh',
        paddingTop:'1.5vh',
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0',
       
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto'
    },
    boxTitle:{
        padding:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'0px solid #F0F0F0',
        
    },
    statusLabel:{
        border: "solid 1px",
        padding: 5,
        textAlign:'center',
        color:theme.palette.text.white,
        cursor:"pointer"
    },
    overlay:{
        display:"block", 
        position:'absolute',
        top:0, 
        left:0, 
        zIndex:1000, 
        background:"#f0f0f066",
        height:'100%',
        width:'100%',
    },
    
});

  
const sim_boundaries=[ //TODO: get this from the db
    {"name":"low", "bottom":0, "top":55}, 
    {"name":"medium", "bottom":55, "top":75}, 
    {"name":"high","bottom":75, "top":100} 
]

class SingleCampaignSummary extends React.Component {
  
    constructor(props) {
        super(props);           
        this.moveTo = this.moveTo.bind(this);
        this.handleDrawerState = this.handleDrawerState.bind(this)
        this.getAllTraits()
    }

    state = {
        campaign: this.props.campaign?this.props.campaign:{},
        loading:true,
        overlayDiv: [],
    };

    componentDidMount(){
        if (this.props.campaign ) {
            this.setState({campaign: this.props.campaign})
            this.getModels(this.props.campaign.models)
            this.getSimilarities(this.props.campaign, this.props.campaign.models[0])
        }
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.campaign && nextProps.campaign!==this.state.campaign ){
            this.setState({campaign:nextProps.campaign});  
            this.getModels(nextProps.campaign.models)
            this.getSimilarities(nextProps.campaign,  nextProps.campaign.models[0])
        }
       
        if (nextProps.loading === this.state.loading) {
            this.setState({loading: nextProps.loading})
        }
    }

    async getSimilarities(campaign, model){
        try{
            const cands = campaign.lookalike_campaign_compared_candidates.map(function(cand){return cand.hr_id})
            this.setState({loading:<EgProgress />})
            this.setState({selectedModel: model})
            if (cands && cands.length && model){
                let body = JSON.stringify({
                    refIds: [model],//campaign.models,
                    testedIds:cands
                })
                let response = await EgHttp.put( '/data/similarities', body)
                if (response.status !== 200){
                    throw new Error('Failed to get similarities data:' + response.status)
                }
                let rowsres = await response.json(); 
                if (rowsres && rowsres.status_code === 200){
                    this.parseSimilarities(rowsres.data)
                }
                else throw new Error('Failed to get similarities data')
            }
            else{
                console.warn("No candidates or no model, not trying to compare")
            }
            return
        }
        catch(err){
            console.error(err)
        }
    }

    parseSimilarities(data){
        const models = Object.keys(data)
        models.pop()
        let scores = data[models[0]].scores; //TODO - need to fix this
        this.setState({currentScores:scores})
        const cand_with_scores = this.state.campaign.lookalike_campaign_compared_candidates.map(function(cand,index){
            const hrInd = scores.findIndex(({ hrId }) => hrId === cand.hr_id )
            if (hrInd > -1){
                cand.sim_score = scores[hrInd]?(scores[hrInd].score*100).toFixed(1):0
            }
            else{
                cand.sim_score = 0 
            }
            return cand
        })
        cand_with_scores.sort((a, b) => (a.sim_score < b.sim_score) ? 1 : -1)
        let camp_with_scores = this.state.campaign
        camp_with_scores.lookalike_campaign_compared_candidates = cand_with_scores
        this.setState({campaign:camp_with_scores})
    } 

    async getModels(modelArr){
        try{
            this.setState({loading:<EgProgress />})
            let response = await EgHttp.get( '/lookalike/models/'+modelArr )
            let rowsres = await response.json();
            this.setState({models:rowsres, loading:null});
            return;
        }
        catch(err){
            console.error(err)
        }
    }
    async getAllTraits(){
        try{
            let response = await EgHttp.get( '/reports/traits/' + LangPicker.current() )
            let rowsres = await response.json();
            this.setState({traits:rowsres});
            return;
        }
        catch(err){
            console.error(err)
        } 
    }

    moveTo(dest){
        this.props.moveTo(null, dest)
    }

    setModel(id){
        this.setState({selectedModel:id})
        this.getSimilarities(this.state.campaign, id)
    }

    openCompare(id){
        this.setState({openCompareId:id}, ()=> {
          this.setState({singleCompareOpen:true})
        })
    }
    
    handleDrawerState(val){
        this.setState({singleCompareOpen:val})
    }

    setHover(e, onOff, index){
        const { t } = this.props;
        let items = [...this.state.overlayDiv];
        const indexwp=index//+(this.state.rowsPerPage*this.state.page)
        let item = {...items[indexwp]};
        items = [];
        if (onOff){
            item =  <div className={this.props.classes.overlay}> 
                        <Grid container  justify="left" direction="row" alignItems="center" style={{height:'100%'}}>
                            <Grid item xs={12}  alignItems="center" style={{display:"flex", justifyContent:"flex-end", padding:10, paddingInline:30, height:'100%'}} >
                                <EgButton  onClick={()=>{this.openCompare(indexwp)}}>{t('Lookalike - Single - Compare traits')}</EgButton>  
                            </Grid>
                        </Grid>     
                    </div>     
        }
        else{
            item = null;
        }
        items[index] = item;
        this.setState({overlayDiv:items});
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:10, borderTop:'1px solid #e8e8e8'}}>
            <Grid container >
            
            <Grid item xs={12} className={classes.formRow} >
                <Paper elevation={0} className={classes.summaryBox} >
                    
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - Models')} </Typography>
                    <Table className={classes.tableInner} aria-label="inner table">
                        <TableBody >
                            <TableRow key={"models"}  >
                                    {this.state.models?this.state.models.map((model) => (
                                     <TableCell style={{paddingTop:'2vh',paddingBottom:'2vh',paddingInline:'20' , border:0, width:'16vw'}} >
                                            <div onClick={()=>this.setModel(model.id)} className={classes.statusLabel} style={{backgroundColor:model.id === this.state.selectedModel?Statuses.get()[0].color:Statuses.get()[3].color,width:'100%' } }>{model.id>0?model.firstname +" " + model.lastname:model.position}</div>
                                     </TableCell>
                                     )):
                                     <TableCell colspan={3} style={{paddingTop:'2vh',paddingBottom:'2vh',paddingInline:'20' , border:0, width:'16vw'}} >
                                            {t('Lookalike - Single - No models defined for this position yet')}
                                     </TableCell>}
                                     <TableCell colSpan={this.state.models?6-this.state.models.length:6} style={{paddingTop:'2vh',paddingBottom:'2vh',paddingInline:'20' , border:0}}></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - Results')} </Typography>
                    <div style={{overflow:'auto', paddingTop:'0.5vh'}}>
                    <Table className={classes.tableInner} aria-label="inner table" >
                        <TableBody >
                            <TableRow key={"charhead"}  >
                                <TableCell className={classes.tableCell} style={{width:'20%', paddingInlineStart:20, fontSize:12, fontWeight:500}}> 
                                        {t('Lookalike - Single - Candidate Name')}
                                </TableCell>
                                <TableCell className={classes.tableCell} colSpan={2} style={{width:'47%', paddingInlineStart:0, fontSize:12, fontWeight:500}}>
                                        {t('Lookalike - Single - Similarity')}
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{width:'38%',paddingBottom:0,paddingInlineStart:0, paddingTop:'0.3vh'}}>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                            
                            {this.state.campaign && this.state.campaign.lookalike_campaign_compared_candidates && this.state.campaign.lookalike_campaign_compared_candidates.length?
                            this.state.campaign.lookalike_campaign_compared_candidates.map((cand, index) => (
                                <TableRow key={cand.human_resource.id} className={classes.tableRowBody} >
                                <TableCell className={classes.tableInnerCell} colSpan={4} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                    <div>
                                    <Table className={classes.tableInner} aria-label="inner table" > 
                                        <TableRow key={"char" + index } >
                                            <TableCell className={classes.tableCell} style={{width:'20%', paddingInlineStart:20}}>
                                                <Typography variant={'h4'} style={{paddingBottom:'0.8vh'}}>
                                                    {cand.human_resource.firstname + " " + cand.human_resource.lastname}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'2%',paddingBottom:0,paddingInlineStart:0, paddingTop:'0.3vh'}}>
                                                <div style={{direction:'ltr', textAlign:'end'}}><span style={{fontWeight:500}}>{" "}</span><span style={{fontSize:12}}>{cand.human_resource.status !== AppConstants.EgHRStatus.HR_STATUS_CANDIDATE_ASSESS_COMPLETED || !cand.sim_score?'':cand.sim_score+"%" }</span></div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'40%', paddingInlineStart:20,paddingTop:18, textAlign: 'start'}}>
                                                {cand.human_resource.status !== AppConstants.EgHRStatus.HR_STATUS_CANDIDATE_ASSESS_COMPLETED?
                                                    <Typography variant={'h4'} style={{paddingBottom:'0.6vh',paddingTop:'0.6vh', backgroundColor:'#eeeeee', textAlign:'center', paddingLeft:0,paddingRight:0}}>{t('Lookalike - Single - Didnt complete initial assessment')}</Typography>:
                                                    cand.sim_score?
                                                    <GradeBar wide={true} variant="determinate" value={cand.sim_score } negative={false} boundaries={sim_boundaries}></GradeBar>:
                                                    <Typography variant={'h4'} style={{paddingBottom:'0.6vh',paddingTop:'0.6vh', backgroundColor:'#eeeeee', textAlign:'center', paddingLeft:0,paddingRight:0}}>{t('Lookalike - Single - Not available for this type of assessment')}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'38%',paddingBottom:0,paddingInlineStart:0, paddingTop:'0.3vh'}}>
                                            &nbsp;
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                    </div>
                                    {cand.human_resource.status !== AppConstants.EgHRStatus.HR_STATUS_CANDIDATE_ASSESS_COMPLETED || !cand.sim_score?'':this.state.overlayDiv[index]}
                                </TableCell>  
                                    </TableRow>
                               
                            )):
                            ''}
                            
                        </TableBody>
                    </Table> 
                    </div>
                
                </Paper>
            </Grid>

            </Grid>
            <CompareLookalikeToModel handleDrawerState={this.handleDrawerState} openState={this.state.singleCompareOpen} comparedId={this.state.openCompareId} campaign={this.state.campaign} currentScores={this.state.currentScores} selectedModel={this.state.selectedModel} models={this.state.models} refresh={this.refresh} traits={this.state.traits}></CompareLookalikeToModel>                    
        </div>
        
           

    );
  }
}
SingleCampaignSummary.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleCampaignSummary),SingleCampaignSummary);