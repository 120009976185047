import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, TextField, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, Divider } from '@material-ui/core';
//import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import LangPicker from '../../util/LangPicker';
//import RoleManager from '../../managers/RoleManager';
import { Autocomplete, Chip } from '@material-ui/lab';


 
const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width:100,
        marginLeft:5,
        marginRight:5
    },
    errorText:{
        color: theme.palette.error.main,
        textAlign: 'end'
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#c4c4c4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#c4c4c4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#c4c4c4',
            },
            '&:hover fieldset': {
                borderColor: '#c4c4c4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#c4c4c4',
            },
        },
    },
    selectBox:{
        '& .MuiOutlinedInput-input':{
            fontSize:14
        }
    }
  });
  
class AddLookalikeCampaign extends React.Component {
  
    constructor(props) {
        super(props);            
        this.closeDrawer = this.closeDrawer.bind(this);  
        this.saveLookalikeCampaign = this.saveLookalikeCampaign.bind(this);  
        this.validateName= this.validateName.bind(this);  
        this.validatePosition = this.validatePosition.bind(this);  
        this.validateAll= this.validateAll.bind(this);  
        this.changeModel = this.changeModel.bind(this);  
        this.changeCandidatesToCompare = this.changeCandidatesToCompare.bind(this)
        this.setAutoadd = this.setAutoadd.bind(this)
        this.openAddModel = this.openAddModel.bind(this)
    }

    state = {
        addOpen: this.props.addOpen,
        campaignId: this.props.campaignId,
        nameVal:true,
        positionVal: true,
        modelsVal:true,
        candidates:[],
        models:[],
        candidatesToCompare:[],
        autoAddCands:false,
        optionalModels:[],
        teams:this.props.teams,
        selectedTeam: null
    };

    componentDidMount(){
        this.initialize()
    }


    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){             
            this.setState({addOpen:nextProps.openState}); 
            this.setState({campaignName:null, forPosition: null})       
        }
        if (nextProps.teams && nextProps.teams!==this.state.teams){
            this.setState({teams:nextProps.teams})
        }
        if (nextProps.campaignId){
            this.setState({campaignId:nextProps.campaignId});  
            this.getLookalikeCampaign(nextProps.campaignId)
        }
    }

    async initialize(){
        try{
            
            let responseCand = await EgHttp.get('/lookalike/candidates')
            let rowsCand = await responseCand.json();
            this.setState({candidates:rowsCand})
            let responseMods = await EgHttp.get('/lookalike/optionalmodels')
            let rowsMods = await responseMods.json();
            let responsePredef = await EgHttp.get('/lookalike/predefmodels')
            let predefRes = await responsePredef.json();
            if (!predefRes) predefRes = []
            const combModels = rowsMods.concat(predefRes)
            this.setState({optionalModels:combModels})
        }
        catch(err){
            console.error(err)
        }
    }

    async getLookalikeCampaign(id){
        try{
            let responseCamp = await EgHttp.get('/lookalike/campaign/' + id)
            let rowsCamp = await responseCamp.json();
            console.log(rowsCamp)
            this.setState({ selectedTeam:rowsCamp.team, jobdesc:rowsCamp.job_reqirements})
            let responseCand = await EgHttp.get('/lookalike/candidates')
            let rowsCand = await responseCand.json();
            this.setState({candidates:rowsCand})
            let responseMods = await EgHttp.get('/lookalike/optionalmodels')
            let rowsMods = await responseMods.json();
            //this.setState({optionalModels:rowsMods})
            let responsePredef = await EgHttp.get('/lookalike/predefmodels')
            let predefRes = await responsePredef.json();
            if (!predefRes) predefRes = []
            const combModels = rowsMods.concat(predefRes)
            this.setState({optionalModels:combModels})
            
            const modelIds = rowsCamp.models
            const modelArr = modelIds.map(function(cid){
                const modelInd = combModels.findIndex(({ id }) => id === cid )
                if (modelInd > -1){
                    return combModels[modelInd]
                }
                return null
            })

            const candIds = rowsCamp.lookalike_campaign_compared_candidates.map(function(cand){return cand.hr_id})
            const candArr = candIds.map(function(cid){
                const candInd = rowsCand.findIndex(({ id }) => id === cid )
                if (candInd > -1){
                    return rowsCand[candInd]
                }
                return null
            })
            this.setState({campaignName:rowsCamp.campaign_name, forPosition: rowsCamp.for_position, models:modelArr, candidatesToCompare:candArr, autoAddCands:rowsCamp.auto_add_candidates?rowsCamp.auto_add_candidates:false});
        }
        catch(err){
            console.error(err);
        }
        
        return;
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    
    changeModel(event, newValue){
        this.setState({models:newValue})
        if (newValue.length > 0){
            this.setState({modelsVal:true,modelErrTxt:null })
        }
        if (newValue.length > 2){
            this.setState({modelMaxout:true})
        }
        else{
            this.setState({modelMaxout:false})
        }
    }
    changeCandidatesToCompare(event, newValue){
        this.setState({candidatesToCompare:newValue})
    }

    setAutoadd(){
        if (this.state.autoAddCands === true || this.state.autoAddCands === 'true'){
            this.setState({autoAddCands:false})
        }
        else {
            this.setState({autoAddCands:true})
        }
    }

    closeDrawer(){
        this.setState({addOpen:false})
        this.props.handleDrawerState(false)
        this.setState({campaignName:null, forPosition: null, selectedTeam:null, candidatesToCompare:[],  models:[], campaignId:null})
    }

    validateName(event){
        this.setState({nameVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
    validatePosition(event){
        this.setState({positionVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
    
    validateAll(){
        const { t, classes } = this.props;
        let failed = false;
        /*
        if (!this.state.campaignName || !this.state.campaignName.trim() || this.state.campaignName.trim().length<2) {
            this.setState({nameVal:false})
            failed=true;
        }*/
        if (!this.state.forPosition || !this.state.forPosition.trim() || this.state.forPosition.trim().length<2) {
            this.setState({positionVal:false})
            failed=true;
        }
        /*
        if (!this.state.models || this.state.models.length === 0){
            this.setState({modelsVal:false, modelErrTxt:<Typography variant={'h4'} className={classes.errorText}> {t('Lookalike - Campaigns - Please select at least one model')}</Typography>})
            return false
        }*/
        if (!this.state.models || this.state.models.length > 3){
            this.setState({modelsVal:false, modelErrTxt:<Typography variant={'h4'} className={classes.errorText}> {t('Lookalike - Campaigns - Please select up to 3 models')}</Typography>})
            
            return false
        }
        if (failed) return false;
        return true;
    }

    async saveLookalikeCampaign(){
        if (!this.validateAll()){
            return;
        }
        const { t } = this.props;
        let body = JSON.stringify({
            campaignName: this.state.forPosition,//this.state.campaignName,
            forPosition: this.state.forPosition,
            accountId: this.props.admin.account.id,
            id:this.state.campaignId,
            language: LangPicker.current(),
            orgId: this.props.admin.org.id,
            models: this.state.models,
            candidatesToCompare: this.state.candidatesToCompare,
            status:this.state.autoAddCands?1:0,
            autoAddCand:this.state.autoAddCands?this.state.autoAddCands:false,
            selectedTeam:this.state.selectedTeam,
            jobdesc: this.state.jobdesc
        })
        
        if (this.state.campaignId){
            await EgHttp.put('/lookalike/campaign',body)
        }
        else{
            await EgHttp.post('/lookalike/campaign',body)
        }
        
        //EventLog.insert(3, this.state.candidateId?this.state.candidateId:rowsres.id, this.state.candidateId?EventLog.EDIT_CANDIDATE:EventLog.INSERT_CANDIDATE, 0)
       
        this.closeDrawer();
        this.props.refresh("SUCCESS", this.state.campaignId?t('Lookalike - Campaigns - Campaigns details updated successfully'):t('Lookalike - Campaigns - New campaign added successfully'));
        return;
    }

    selectTeam = event => {
        this.setState({ selectedTeam: event.target.value });
    };

    openAddModel(){
        this.props.openAddModel()
    }

    
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>

            <React.Fragment key={i18n.dir()==='rtl'?'left':'right'}>
           
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:600}}>
            <div style={{width:600}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={12} justify="left">
                                <Typography variant={'h1'}>{!this.state.campaignId?t('Lookalike - Add Campaign'):t('Lookalike - Table - Edit Campaign')}  </Typography>
                            </Grid>
                            {/*
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:30}}>
                                <Typography variant={'h5'}>{t('Lookalike - Table - Campaign Name')}</Typography>
                                <TextField autoComplete="campaignname" name="campaignName" variant="outlined" required fullWidth='true' id="campaignName" label="" value={this.state.campaignName} onChange={this.handleChange} className={classes.signupTxt} onBlur={this.validateName}
                                    error={this.state.nameVal?false:true}
                                    helperText={this.state.nameVal?"":t('Lookalike - Add - Please enter a valid campaign name')}
                                    InputProps={{
                                        classes: { input: classes.inputLabel }, 
                                    }}>
                                </TextField>
                            </Grid>*/}
                            <Grid item xs={12} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Lookalike - Table - For Position')}</Typography>
                                <TextField size='small' autoComplete="forposition" name="forPosition" variant="outlined" required fullWidth='true' id="forPosition" label="" value={this.state.forPosition} onChange={this.handleChange} className={classes.signupTxt} onBlur={this.validatePosition}
                                    error={this.state.positionVal?false:true}
                                    helperText={this.state.positionVal?"":t('Lookalike - Table - Please enter a valid position')}
                                >
                                </TextField>
                            </Grid>

                            <Grid item xs={12} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Teams - Team')}</Typography>
                                <FormControl size='small' style={{width:'100%'}}>
                                    <InputLabel id="teamsLbl"></InputLabel>
                                    <Select size='small' variant="outlined" labelId="teamsLbl" id="selectedTeam" name="selectedTeam" value={this.state.selectedTeam} onChange={this.selectTeam} >
                                        <MenuItem value={0}>&nbsp;</MenuItem>
                                        {this.state.teams? this.state.teams.map(function(t) {
                                            return <MenuItem value={t.id}>{t.name}</MenuItem>
                                        }):null}       
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Lookalike - Add - Job Description')}</Typography>
                                <TextField  size='small' multiline maxRows={14} rows={14} autoComplete="jobdesc" name="jobdesc" variant="outlined" fullWidth='true' id="jobdesc" label="" value={this.state.jobdesc} onChange={this.handleChange} className={classes.signupTxt} InputProps={{style: {fontSize:14}}}>    
                                </TextField>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow}>
                            <Divider></Divider>
                            </Grid>

                            <Grid item xs={12} className={classes.formRow}>
                                <Grid container >

                                    <Grid item xs={9}>
                                        <Typography variant={'h5'}>{t('Lookalike - Add - Model')}</Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="selectedModelInp"></InputLabel>
                                            <Autocomplete size='small' variant="outlined" labelId="modellabel" multiple
                                                id="selectedModel"
                                                name="selectedModel"
                                                value={this.state.models}
                                                //options={this.state.optionalModels?this.state.optionalModels:[]}
                                                getOptionLabel={(option) => option?(option.id>0?option.firstname + " " + option.lastname:option.position):''}
                                                filterSelectedOptions
                                                disableCloseOnSelect
                                                getOptionDisabled={(option) => this.state.modelMaxout}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="" placeholder=""/>
                                                )}
                                                options={this.state.optionalModels?this.state.optionalModels.sort((a, b) => a.id>b.id):[]}
                                                groupBy={(option) => option.id<0?t('Lookalike - Models - Custom Models'):t('Lookalike - Models - Employees/Candidates')}
                                                className={classes.selectBox}
                                                onChange = {this.changeModel}
                                                ChipProps={{style: {backgroundColor: '#C1A5FF', color:'#ffffff'}}}
                                            >
                                            </Autocomplete>
                                        </FormControl>
                                        {this.state.modelErrTxt}
                                    </Grid>  
                                    <Grid item xs={3} style={{display:'flex', alignItems:'end', justifyContent:'center', marginBottom:5}} >
                                        <EgButton  onClick={this.openAddModel} className={classes.actionBtns}>{t('Lookalike - Add - New Model')}</EgButton>
                                    </Grid>               
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.formRow}>
                                <Grid container >

                                    <Grid item xs={12}>
                                        <Typography variant={'h5'}>{t('Lookalike - Add - Select Candidates')}</Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="selectedCandidatesInp"></InputLabel>
                                            <Autocomplete size='small' variant="outlined" labelId="modellabel" multiple
                                                id="selectedCandidates" name="selectedCandidates"
                                                limitTags={3}
                                                value={this.state.candidatesToCompare}
                                                options={this.state.candidates?this.state.candidates:[]}
                                                getOptionLabel={(option) => option?(option.firstname + " " + option.lastname):''}
                                                filterSelectedOptions
                                                disableCloseOnSelect
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="" placeholder=""/>
                                                )}
                                                className={classes.selectBox}
                                                onChange = {this.changeCandidatesToCompare}
                                            >
                                            </Autocomplete>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton  onClick={this.saveLookalikeCampaign} className={classes.actionButton}>{t('Settings - Accounts - Save')}</EgButton>
                                <EgButton secondary="true" outlined="true" onClick={this.closeDrawer} className={classes.actionButton}>{t('Settings - Accounts - Cancel')}</EgButton>   
                            </Grid>
                            
                        </Grid>
                </Paper>
            </div>
            </Drawer>
            </React.Fragment>

        </div>     
      
    );
  }
}
AddLookalikeCampaign.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AddLookalikeCampaign),AddLookalikeCampaign)))