import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import { MenuItem, Select, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import logo from '../../img/logo_blue.svg'
import EgButton from '../../components/EgButton';
import LangPicker from '../../util/LangPicker';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    logo:{
      width:140,
      paddingInlineStart:20,
      paddingTop:8,
      paddingBottom:0,
    },
    signupButton:{
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 6,
      boxShadow:'0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    },
    link:{
      textDecoration:"none",
      color:theme.palette.text.black
    },
    selectBox:{
      '& .MuiOutlinedInput-input':{
        paddingTop:8,
        paddingBottom:8, 
        fontSize:12,
        
      },
      '&:before': {
        borderColor: '#E4E4E4',
    },
    '&:after': {
        borderColor: '#E4E4E4',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E4E4E4"
    }
    }
})
  
class SignTopbar extends React.Component {
  
  state = {
    page: this.props.page
  };

  constructor(props) {
    super(props);   
    this.signup = this.signup.bind(this);
    this.signin = this.signin.bind(this);
    this.changeLang = this.changeLang.bind(this);
  } 

  signup(){
    this.props.history.push('/signup')
  }

  signin(){
    this.props.history.push('/signin')
  }
  changeLang(){
    //this.props.history.push('/signup')
    //this.props.history.push('/signin?lang=he')
  }
  
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
        
        <Grid container spacing={0} justifyContent="center" alignItems="center">
            <Grid item xs={6} style={{textAlign:'start'}}> <img src={logo} className={classes.logo} alt="logo" /> </Grid>
            <Grid item xs={4} style={{textAlign:"end"}}>
                {this.props.mode === 'signin'?
                  <Typography variant="h5">{t("Signin - Dont have an account")}</Typography>:
                  <Typography variant="h5">{t("Signup - Already a member")}</Typography>
                }
            </Grid>
            <Grid item xs={1} style={{textAlign:"start"}}>
                {this.props.mode === 'signin'?
                <EgButton secondary="true" className={classes.signupButton} onClick={this.signup}>{t("Signin - SignUp")}</EgButton>:
                <EgButton secondary="true" className={classes.signupButton} onClick={this.signin}>{t("Signin - SignIn")}</EgButton>
                } 
            </Grid> 
            <Grid item xs={1} style={{textAlign:"start", display:'flex', justifyContent:'end'}}>
              <Select onChange={this.changeLang} value={LangPicker.current()} variant="outlined" className={classes.selectBox} >
                <MenuItem value={'en'}><a className={classes.link} href='signin?lang=en'>En</a></MenuItem>
                <MenuItem value={'es'}><a className={classes.link} href='signin?lang=es'>Es</a></MenuItem>
                <MenuItem value={'pt'}><a className={classes.link} href='signin?lang=pt'>Pt</a></MenuItem>
                <MenuItem value={'he'}><a className={classes.link} href='signin?lang=he'>{LangPicker.current()==='he'?'עברית':'He'}</a></MenuItem>
              </Select>
            </Grid>
        </Grid>     
    );
  }
}
SignTopbar.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };
  
export default (withTranslation()(withCookies(withRouter((withStyles(styles))(SignTopbar),SignTopbar))))