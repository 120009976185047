import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Typography, TextField} from '@material-ui/core';
import testIntro from '../../img/assess_opener.svg';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import AppUtils from '../../util/AppUtils';

const styles = theme => ({
    root: {
        display: 'flex',
        width:'100%'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'55vh',
        
      },
    
    codeText:{
        maxWidth:50,
        marginInlineEnd:20,
        '& label.Mui-focused': {
            color: theme.palette.primary.main,
          },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: "#C2C3C6",
            },
            '&:hover fieldset': {
                borderColor: "#C2C3C6",
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        }
    },
    codeTextMob:{
        maxWidth:'11vw',
        marginInlineEnd:'2vw',
        '& label.Mui-focused': {
            color: theme.palette.primary.main,
          },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: "#C2C3C6",
            },
            '&:hover fieldset': {
                borderColor: "#C2C3C6",
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        }
    },
    resize: {
        fontSize:24,
        textAlign:'center',
        maxLength: 1
    }, 
    actionButton:{
       paddingLeft:10,
       paddingRight:10,
        fontSize:14,
        margin: 5
    },
    
});

  
  
class AssessSignin extends React.Component {
  
    state = {
        curFocus:"code1"
    };

    constructor(props) {
        super(props);  
        console.log("AssessSignin");

        this.resendSMS = this.resendSMS.bind(this)
        
        this.code1ref = React.createRef();
        this.code2ref = React.createRef();
        this.code3ref = React.createRef();
        this.code4ref = React.createRef();
        this.code5ref = React.createRef();
        this.code6ref = React.createRef();
        
    }

    componentDidMount(){
        if (AppUtils.isMobile()) return;
        this.code1ref.current.focus()
    }

    resendSMS(){
        this.props.resend()
    }
    
   // handleTestCode() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleTestCode = event => {
        switch(event.target.id){
            case "code1":
                this.code2ref.current.focus()
                break;
            case "code2":
                this.code3ref.current.focus()
                break;
            case "code3":
                this.code4ref.current.focus()
                break;
            case "code4":
                this.code5ref.current.focus()
                break;
            case "code5":
                this.code6ref.current.focus()
                this.setState({xxx:true})
                break;
            default:
                break;
        }
        this.setState({ [event.target.id]: event.target.value });
        let assemCode = this.code1ref.current.value + this.code2ref.current.value + this.code3ref.current.value + this.code4ref.current.value + this.code5ref.current.value + this.code6ref.current.value;
        this.setState({finalCode: assemCode})
        if (assemCode && assemCode.length===6 && event.target.id==='code6'){
            this.props.signin(assemCode)
        }
        
    };
  
  render() {
    const { t } = this.props;
    const { classes } = this.props;
   
    return (
            1===0?//AppUtils.isMobile() ?
            <Paper>
                <Typography variant={'h2'} style={{padding:30}} >{t('Assessment - Signin - Engard assessment is currently not available on mobile')}</Typography>
            </Paper>
            :
            <Paper className={classes.paper}>
                <Grid container  justify="center" direction="row" alignItems="center" style={{padding:0}}>
                    
                    <Grid item xs={AppUtils.isMobile()?12:6} style={{display:'flex', alignItems:'center', height:'55vh'}} >
                        <Grid container justify="start" direction="row"  style={{ paddingInlineStart:30}}>
                        
                            <Grid item xs={12} >
                                <Typography variant={'h1'} style={{paddingBottom:'4vh', textAlign:'start'}}>{t('Assessment - Signin - Welcome to Engard Assessment')}</Typography>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Typography variant={'h2'} style={{paddingBottom:'1vh', textAlign:'start', color:'#5F6066',fontWeight:600}}>{t('Assessment - Signin - Enter your assessment code')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'h4'} style={{paddingBottom:'3vh', textAlign:'start'}}>{t('Assessment - Signin - We sent you a verification code to your phone')}</Typography>
                            </Grid> 

                            <Grid item xs={12} style={{display:'flex', justifyContent:'start'}}>
                                <div style={{direction:'ltr'}}>
                                    <TextField name="TestCode" id="code1" inputRef={this.code1ref} autoComplete='off' variant="outlined" InputProps={{classes:{input:classes.resize}}} inputProps={{maxLength:1}} required   label=""  onChange={this.handleTestCode} className={AppUtils.isMobile()?classes.codeTextMob:classes.codeText}></TextField>
                                    <TextField name="TestCode" id="code2" inputRef={this.code2ref} autoComplete='off' variant="outlined" InputProps={{classes:{input:classes.resize}}} inputProps={{maxLength:1}} required   label=""  onChange={this.handleTestCode} className={AppUtils.isMobile()?classes.codeTextMob:classes.codeText}></TextField>
                                    <TextField name="TestCode" id="code3" inputRef={this.code3ref} autoComplete='off' variant="outlined" InputProps={{classes:{input:classes.resize}}} inputProps={{maxLength:1}} required   label=""  onChange={this.handleTestCode} className={AppUtils.isMobile()?classes.codeTextMob:classes.codeText}></TextField>
                                    <TextField name="TestCode" id="code4" inputRef={this.code4ref} autoComplete='off' variant="outlined" InputProps={{classes:{input:classes.resize}}} inputProps={{maxLength:1}} required   label=""  onChange={this.handleTestCode} className={AppUtils.isMobile()?classes.codeTextMob:classes.codeText}></TextField>
                                    <TextField name="TestCode" id="code5" inputRef={this.code5ref} autoComplete='off' variant="outlined" InputProps={{classes:{input:classes.resize}}} inputProps={{maxLength:1}} required   label=""  onChange={this.handleTestCode} className={AppUtils.isMobile()?classes.codeTextMob:classes.codeText}></TextField>
                                    <TextField name="TestCode" id="code6" inputRef={this.code6ref} autoComplete='off' variant="outlined" InputProps={{classes:{input:classes.resize}}} inputProps={{maxLength:1}} required   label=""  onChange={this.handleTestCode} className={AppUtils.isMobile()?classes.codeTextMob:classes.codeText} style={{marginInlineEnd:0}}></TextField>
                                </div>   
                            </Grid> 
                            <Grid item xs={12}>
                                <Grid container  justify="center" direction="row" alignItems="center">
                                    <Grid item xs={12} style={{display:"flex", justifyContent:'start', paddingTop:'4vh'}}>
                                        <EgButton  className={classes.actionButton} onClick={()=>this.props.signin(this.state.finalCode)}>
                                            {t('Assessment - Signin - Sign In')}
                                        </EgButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} style={{ paddingTop:'3vh'}}>
                                <Typography variant={'h4'} style={{paddingBottom:10, textAlign:'start'}}><a href='#0' style={{textDecoration:'none', color:'#0085AF'}} onClick={this.resendSMS}>{t('Assessment - Signin - I did not recieve the code')}</a></Typography>
                            </Grid> 
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={AppUtils.isMobile()?12:6} justify="center" style={{display:"flex", alignItems:"center"}}>
                        <img src={testIntro} style={{width:'50%'}} alt="testIntro" />
                    </Grid>
                   
                    
                </Grid>
            </Paper> 
    );
  }
}
AssessSignin.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(AssessSignin),AssessSignin);