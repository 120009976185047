import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { styled } from '@material-ui/core/styles';
import LangPicker from "../util/LangPicker";




  
  const GradeBarInvalidW = withStyles((theme) => ({
    root: {
      height: 20,
      borderRadius: 0
    },
    colorPrimary: {
      backgroundColor: '#fafafb'//theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 0,
      backgroundColor: '#eaeaea', 
    },
  }))(LinearProgress);





const GradeBarInvalidNWide = styled(GradeBarInvalidW)(({ theme, ...props }) => ({
  
  '& .MuiLinearProgress-bar':{
    borderRadius: 3,
    height: 20,
    backgroundColor: '#0085A',//Ftheme.palette.severity.high, 
    transform: `translateX(${props.value}%) !important`
  },
  }))
  

export default function SentimentBar(props) {
  let value = 0,avg = 0
  if (props.value) value = props.value
  if (props.avg) avg = props.avg
  return (
    <div style={{width:'100%', position:'relative'}}>
        {!props.isInvalid?
               
               
                    <div style={{width:160, height: 20}}>
                        {value<30?
                        <table style={{borderCollapse:'collapse'}}>
                            <tr>
                                <td  style={{width:80, minWidth:80, height:20, backgroundColor:'#efefef', display:"flex", justifyContent:'end',borderRight:'solid 1px black', borderTopLeftRadius:3, borderBottomLeftRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#FC264D', width:(40-props.value*0.8)*2, height:20, borderTopLeftRadius:3, borderBottomLeftRadius:3}}/>
                                </td>
                                <td  style={{width:80, minWidth:80, height:20,  backgroundColor:'#efefef', borderTopRightRadius:3, borderBottomRightRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#fafafa', width:0, height:20}}/>
                                </td>
                            </tr>
                        </table>:
                        value<50?
                        <table style={{borderCollapse:'collapse'}}>
                            <tr>
                                <td  style={{width:80, minWidth:80, height:20, backgroundColor:'#efefef', display:"flex", justifyContent:'end',borderRight:'solid 1px black', borderTopLeftRadius:3, borderBottomLeftRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#FC264D88', width:(40-props.value*0.8)*2, height:20, borderTopLeftRadius:3, borderBottomLeftRadius:3}}/>
                                </td>
                                <td  style={{width:80, minWidth:80, height:20,  backgroundColor:'#efefef', borderTopRightRadius:3, borderBottomRightRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#fafafa', width:0, height:20}}/>
                                </td>
                            </tr>
                        </table>:
                        value===50?
                        <table style={{borderCollapse:'collapse'}}>
                            <tr>
                                <td  style={{width:80, minWidth:80, height:20, backgroundColor:'#fafafa', display:"flex", justifyContent:'end',borderRight:'solid 1px black', borderTopLeftRadius:3, borderBottomLeftRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#fafafa', width:0, height:20}}/>
                                </td>
                                <td  style={{width:80, minWidth:80, height:20,  backgroundColor:'#fafafa', borderTopRightRadius:3, borderBottomRightRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#fafafa', width:0, height:20}}/>
                                </td>
                            </tr>
                        </table>:
                        value<70?
                        <table style={{borderCollapse:'collapse'}}>
                            <tr>
                                <td  style={{width:80, minWidth:80, height:20,  backgroundColor:'#efefef', borderTopLeftRadius:3, borderBottomLeftRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#fafafa', width:0, height:20}}/>
                                </td>
                                <td  style={{width:80, minWidth:80, height:20, backgroundColor:'#efefef', display:"flex", justifyContent:'start',borderLeft:'solid 1px black', borderTopRightRadius:3, borderBottomRightRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#11D6A788', width:(props.value*0.8-40)*2, height:20, borderTopRightRadius:3, borderBottomRightRadius:3}}/>
                                </td>
                            </tr>
                        </table>:
                        <table style={{borderCollapse:'collapse'}}>
                            <tr>
                                <td  style={{width:80, minWidth:80, height:20,  backgroundColor:'#efefef', borderTopLeftRadius:3, borderBottomLeftRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#fafafa', width:0, height:20}}/>
                                </td>
                                <td  style={{width:80, minWidth:80, height:20, backgroundColor:'#efefef', display:"flex", justifyContent:'start',borderLeft:'solid 1px black', borderTopRightRadius:3, borderBottomRightRadius:3, padding:0}} >
                                    <div style={{backgroundColor:'#11D6A7', width:(props.value*0.8-40)*2, height:20, borderTopRightRadius:3, borderBottomRightRadius:3}}/>
                                </td>
                            </tr>
                        </table>
                        }


                        <table style={{borderCollapse:'collapse'}}>
                            <tr>
                                <td style={{width:53, height:10, padding:0}}  >
                                    <div style={{fontSize:8, textAlign:'start'}}>Negative</div>
                                </td>
                                <td style={{width:54, height:10, padding:0}}  >
                                    <div style={{fontSize:8}}>Neutral</div>
                                </td>
                                <td  style={{width:53, height:10, padding:0}} >
                                    <div style={{fontSize:8, textAlign:'end'}}>Positive</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                   
                    
          :
            
                <GradeBarInvalidNWide variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)} />
                
          }

          
    </div>
  );
}