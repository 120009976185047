import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Divider, Table, TableBody, TableCell,TableRow, Typography } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { withTranslation } from 'react-i18next';
import clock_icon from '../../img/clock.svg';
import LangPicker from '../../util/LangPicker';
import EgTimeValidityIndicator from '../../components/EgTimeValidityIndicator';
import ReportsManager from '../../managers/ReportsManager';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
    
    boxTitle:{
        padding:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:'2vh'
    },
    boxTitleInd:{
        paddingInline:20,
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
  });

class SingleReportAnalytics extends React.Component {
  
    constructor(props) {
        super(props); 
        this.intializeData = this.intializeData.bind(this);         
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:null,
        timeSummaryArr:[]
    };
    
    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
    }
    
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    intializeData(report, reportParts){
        this.setState({report})
        this.setState({reportParts})
        if (!report) return
        const traitsArr = ReportsManager.getTraitsFromReport(report)
        this.setState({traitsTimes:traitsArr })
        const timeArr = ReportsManager.setTimeSummary(report, reportParts)
        this.setState({timeSummaryArr: timeArr})   
        if (reportParts){
            const timeRisk = ReportsManager.setTimeRisk(reportParts);
            this.setState({timeSeverity: timeRisk})
        }
        if (report.dominant_traits && report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
    }
   
    getTraitsFromReport(report){
        const assessmentScores = report.assessment.assessment_scores
        let traitsArr = assessmentScores.map(function(scr){
            if (scr.trait.is_display){
                const traitName =  LangPicker.current() === 'en'?scr.trait.name:scr.trait.trait_localized[0].localized_name
                return ({name:traitName,trait_avg:parseFloat(scr.trait.avg_response_time/1000), avg_time: parseFloat((scr.trait_aggregated_time/1000)/scr.trait_total_questions).toFixed(2)})
            }
            else{
                return null;
            }
        })
        traitsArr = traitsArr.filter(function(e){return e});
        this.setState({traitsTimes:traitsArr })
        return traitsArr
    }

    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0}  className={classes.summaryBox} >

                <Grid container>
                    <Grid xs={10} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Reports - Single - Time')} </Typography>
                    </Grid>
                    <Grid xs={2} className={classes.boxTitleInd}>
                        <EgTimeValidityIndicator severity={this.state.timeSeverity} ></EgTimeValidityIndicator>
                    </Grid>
                </Grid>

                <Grid container  style={{paddingTop:'3vh', paddingBottom:'2vh'}}>
                    <Grid xs={12} >
                        <Paper elevation={0} >
                            <Grid container >
                                <Grid xs={12} style={{display:'flex', justifyContent:'start', paddingBottom:'2vh', paddingTop:'1vh'}}>
                                    <BarChart
                                        direction='ltr'
                                        width={window.innerWidth/1.2}
                                        height={window.innerHeight/2.5}
                                        data={this.state.traitsTimes?this.state.traitsTimes:[]}
                                        style={{paddingTop:10}}
                                        >
                                        <XAxis dataKey="name" interval={0}  style={{fontSize:8}} axisLine={false}/>
                                        <YAxis unit={' sec'} style={{fontSize:10}} axisLine={false}/>
                                        <Tooltip wrapperStyle={{fontSize: "12px"}}  />
                                        <Legend wrapperStyle={{fontSize: "12px", top:'-1.2vh'}} verticalAlign={'top'} align={'right'} height={10}  iconSize={8} />
                                        <Bar name={t('Report - Time - Response Time')} dataKey="avg_time" fill="#0085AF" barSize={20} radius={3} />
                                        <Bar name={t('Report - Time - Avg. Response')} dataKey="trait_avg" fill="#C1A5FF" barSize={20} radius={3}/>
                                    </BarChart>
                                    
                                </Grid>
                            </Grid>
                            <Divider></Divider>
                            <Grid container>
                                <Grid xs={12} style={{paddingInlineStart:20,  paddingTop:'2vh'}}>
                                    <Table><TableBody><TableRow>
                                    <TableCell style={{border:0, padding:5, width:0, paddingBottom:0}}><img src={clock_icon}  alt="overall" /></TableCell>
                                    <TableCell style={{border:0, padding:5}}><Typography variant={'h2'} >{t('Report - Time - Response Time Insights')}</Typography></TableCell>
                                    </TableRow></TableBody></Table>
                                    
                                </Grid>
                                <Grid xs={12} style={{paddingInline:52, paddingBottom:'3vh'}}>
                                    <Typography variant={'h5'} style={{color:'#58666B', textAlign:'start'}}>{t('Report - Time - Based on the response time')}</Typography>
                                </Grid>
                                <Grid xs={12} >
                                {this.state.timeSummaryArr && this.state.timeSummaryArr.length?
                                    this.state.timeSummaryArr.map((part) => (
                                        <Grid container>
                                            <Grid xs={12} style={{paddingInline:52}}>
                                                <Typography variant={'h4'} style={{ textAlign:'start', lineHeight:2, paddingBottom: 15}}>{part.text}</Typography>
                                            </Grid>
                                        </Grid>
                                )):''}
                                </Grid>
                            </Grid>
                       
                        </Paper>
                    </Grid>
                    
                </Grid>     
            </Paper>
            </Grid>
        </div>
    );
  }
}
SingleReportAnalytics.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportAnalytics),SingleReportAnalytics);