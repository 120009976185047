import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import Dropzone from 'react-dropzone';


const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding:0,
    },
    error:{
        fontSize:14,
        color:"#F15D5D",
        fontWeight: 500,
        textAlign:'center'
    },
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
        borderWidth: 2,
        borderRadius: 4,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#ababab',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    }
})


class UploadCV extends React.Component {
  
    state = {
        open: this.props.open,
        candidate:this.props.candidate,
        files:[],
        dropzoneRef: React.createRef(),
        errorMsg:null,
        loading:null,
    };

    constructor(props) {
        super(props); 
        
        this.onDrop = this.onDrop.bind(this);
        this.openFileDialog = this.openFileDialog.bind(this)
        this.onDropRejected = this.onDropRejected.bind(this)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});        
        }
    }

    
    onDrop(files){
        console.log("IN ON DROP!!!!")
        console.log(files[0])
        this.setState({errorMsg:null})
        this.setState({files:files})
        let formData = new FormData();
        //formData.append('file', files[0]);
        formData.append('file', files[0]);
        console.log(formData)
        this.props.updateCVFile(formData)
    }
    onDropRejected(rejected){
        console.log("DROP REJECTED")
        const { t } = this.props;
        if(rejected.length>1){
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - You can select only one file')}</Typography> });
        }
        else if(rejected[0].errors[0].code === "file-too-large"){
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - File cannot acceed')}</Typography> });
        }
        else{
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - Illegal file type')}</Typography> });
        }
    }

    
   
    handleClickOpen(){
        this.setState({open:true})
    };

   
    openFileDialog() {
        console.log("CALLING OPEN FILE DLG")
        if (this.state.dropzoneRef.current) {
            console.log("CALLING OPEN FILE DLG2")
            this.state.dropzoneRef.current.open()
        }
    };
    
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const files = this.state.files.map(file => (
            <li key={file.name}>
              {file.name} - {file.size} bytes
            </li>
          ));
        console.log(files)
        return (
            <div>
            
                   <Paper elevation={0} >
                   {this.state.loading}
                       <Grid container justify='left' >
                           
                            <Grid item xs={12} >
                                <Typography variant={'h5'}>{t('Candidates - Add - Candidate CV file')}</Typography> 
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container>
                                    <Dropzone onDrop={this.onDrop} ref={this.state.dropzoneRef} maxFiles={1} onDropRejected={this.onDropRejected} accept={["application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"]} onSubmit={this.upload} maxSize={2000000} style={{width:'100%'}}>
                                        {({getRootProps, getInputProps}) => (
                                        
                                        <section className={classes.container}>
                                             <table style={{width:'100%'}}>
                                                <tr>
                                                    <td style={{width:'80%'}}>
                                                    <div {...getRootProps({className: 'dropzone'})} style={{width:'100%'}}>
                                                        <input {...getInputProps()} />
                                                        <p style={files&&files.length?{paddingInlineStart:10, color:"#043241"}:{paddingInlineStart:10}}>{files&&files.length?files:t('Candidates - Import - Drag and drop CV file here')}</p>
                                                    </div>
                                                    </td>
                                                    <td style={{textAlign:'end', paddingInlineEnd:10}}><EgButton onClick={this.openFileDialog} secondary="true" outlined>{t('Candidates - Import - Select File')}</EgButton></td>
                                                </tr>
                                            </table>
                                        </section>
                                        
                                        )}
                                    </Dropzone>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{padding:10, display:'flex', justifyContent:'center'}}>
                                {this.state.errorMsg}
                            </Grid>
                            {/*
                            <Grid item xs={12} style={{paddingTop:10, paddingBottom:20, display:'flex', justifyContent:'center'}}>
                                <EgButton onClick={this.uploadSelectedFile} >{t('Candidates - Import - Upload File')}</EgButton>
                            </Grid>*/}
                       </Grid>
                   </Paper>
                   
            </div>
        );
    }
}

UploadCV.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(UploadCV),UploadCV)))