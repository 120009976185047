import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableRow, TableCell } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import interview_insights from '../../img/interview_insights.svg';
import general_insights from '../../img/general_insights.svg';
import LangPicker from '../../util/LangPicker';
import EgSegmentParser from '../../util/EgSegmentParser'

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    insightBox:{
        border:'1px solid #E4E4E4',
        height:'40vh',
        overflow:'auto'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    }
    
  });

class SingleReportSummaryRecommendationsBox extends React.Component {
  
    constructor(props) {
        super(props);            
        
        this.setInterviewSummary = this.setInterviewSummary.bind(this)
        this.setRecommendationSummary = this.setRecommendationSummary.bind(this)

        this.setInterviewSummary(this.props.report)
        this.setRecommendationSummary(this.props.report)
        
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:null,
    };

    componentDidMount(){
        if (this.props.report && this.props.report.assessment){
            this.setState({report: this.props.report})
            this.setInterviewSummary(this.props.report)
            this.setRecommendationSummary(this.props.report)
        }
        if (this.props.invalidReport){
            this.setState({invalidReport:true})
        }
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report !== this.state.report){
            this.setState({report:nextProps.report}); 
            this.setInterviewSummary(nextProps.report)
            this.setRecommendationSummary(nextProps.report)
        }
        if (nextProps.reportParts && nextProps.reportParts !== this.state.reportParts){
            this.setState({reportParts:nextProps.reportParts}); 
            this.setInterviewSummary(nextProps.report)
            this.setRecommendationSummary(nextProps.report)
        }
        if (nextProps.invalidReport && nextProps.invalidReport !== this.state.invalidReport){
            this.setState({invalidReport:nextProps.invalidReport}); 
        }
        
    }

    async setInterviewSummary(report){
        if(report && report.report_part && report.report_part.length){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 4 )
            if (ind>-1 && report.report_part[ind].template_id.length>0){
                if (report.report_part[ind].template_id.length > 2){
                    this.setState({shortInterviewInsights:report.report_part[ind].template_id.slice(0, 2)})
                    this.setState({shortInterviewInsightsMore: report.report_part[ind].template_id.length-2})
                }
                else{
                    this.setState({shortInterviewInsights:report.report_part[ind].template_id})
                    this.setState({shortInterviewInsightsMore: " "})
                }
            }
        }
    }
    async setRecommendationSummary(report){
        console.log("setRecommendationSummary")

        if(report && report.report_part){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 5 )
            console.log("index:" + ind)
            if (ind>-1 && report.report_part[ind].template_id.length>0){
                console.log(report.report_part[ind].template_id.length)
                if (report.report_part[ind].template_id.length > 2){
                    console.log("in set recom")
                    console.log(report.report_part[ind].template_id.slice(0, 2))
                    this.setState({shortRecommendations:report.report_part[ind].template_id.slice(0, 2)})
                    this.setState({shortRecommendationsMore: report.report_part[ind].template_id.length-2})
                }
                else{
                    console.log("tempid:" + report.report_part[ind].template_id)
                    this.setState({shortRecommendations:report.report_part[ind].template_id})
                    this.setState({shortRecommendationsMore: " "})
                }
                
            }
            
        }
    }

    getTemplateText(tid){
        if (this.state.reportParts && this.state.reportParts.length){
            const ind = this.state.reportParts.findIndex(({ id }) => id === tid )
            
            if (LangPicker.current() === 'en'){
                return this.state.reportParts[ind].text
            }
            else{
                if (this.state.reportParts[ind].report_template_localized[0]){
                    return this.state.reportParts[ind].report_template_localized[0].text
                }
                else return ""
            }
        }
    }

    moveTo(dest){
        this.props.moveTo(dest)
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;

        return (
            <Paper elevation={0} style={{ overflow:'auto', height:this.state.invalidReport?'35.3vh':'40vh'}} className={classes.insightBox}  >
                
                <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Single - Recommendations')} </Typography>
                
                <Grid container style={{paddingTop:'1vh', paddingBottom:'1vh'}}>
                    
                    <Grid xs={6} style={{borderInlineEnd:'solid 1px #e5e5e5'}}>
                        <a href="#0" onClick={()=>{this.moveTo(3)}} className={classes.link}>
                        <Paper elevation={0} >
                            <Grid container>
                                <Grid xs={12} style={{paddingInlineStart:20,  paddingTop:'1vh', paddingBottom:this.state.invalidReport?'0vh':'2vh'}}>
                                    <Typography variant={'h2'} >{t('Reports - Single - Interviews and Evaluation')}</Typography>
                                </Grid>
                               
                                <Grid xs={8} style={{paddingInlineStart:20, paddingInlineEnd:20}}>

                                    <Grid container >
                                    <Grid xs={12}>
                                        <Table>
                                            {
                                            this.state.invalidReport?'':
                                            this.state.shortInterviewInsights && this.state.shortInterviewInsights.length>0?
                                            this.state.shortInterviewInsights.map((tempid, index) =>(
                                                <TableRow >
                                                   
                                                    <TableCell  style={{padding:0, textAlign:'start', border:0, paddingBottom:'1.5vh', paddingTop:index>0?'1.5vh':'0vh'}}>
                                                        <Typography variant={'h4'} style={{color:'#58666B', whiteSpace:'break-spaces'}}>{EgSegmentParser.parseQSummary(this.getTemplateText(tempid))}</Typography>
                                                    </TableCell>
                                                </TableRow >
                                            ))
                                            :''} 
                                        </Table> 
                                    </Grid>  
                                    <Grid xs={12} style={{paddingTop:'1vh', textAlign:'start'}}>
                                        {this.state.invalidReport?'':
                                        <Typography variant={'h4'} ><a href="#0" onClick={()=>{this.moveTo(3)}} className={classes.link}>{this.state.shortInterviewInsightsMore} {t('Report - Insights - more')}</a></Typography>
                                        }
                                    </Grid>    
                                </Grid>
                                </Grid>
                                <Grid xs={4} >
                                    <img src={interview_insights}  alt="overall" style={{ paddingInline:'1vw'}}/>
                                </Grid>
                                
                            </Grid>
                        </Paper>
                        </a>
                    </Grid>
                    
                    
                    <Grid xs={6} >
                        <a href="#0" onClick={()=>{this.moveTo(4)}} className={classes.link}>
                        <Paper elevation={0} >
                            <Grid container>
                                <Grid xs={12} style={{paddingInlineStart:20, paddingTop:'1vh', paddingBottom:this.state.invalidReport?'0vh':'2vh'}}>
                                    <Typography variant={'h2'} >{t('Reports - Single - Working with')} &nbsp;{this.state.report.candidate?this.state.report.candidate.firstname:''}</Typography>
                                </Grid>
                                <Grid xs={8} style={{paddingInlineStart:20, paddingInlineEnd:20}}>
                                    <Grid container >
                                        <Grid xs={12}>
                                            <Table>
                                                {this.state.invalidReport?'':
                                                this.state.shortRecommendations && this.state.shortRecommendations.length>0?
                                                this.state.shortRecommendations.map((tempid, index) =>(
                                                    <TableRow >
                                                        <TableCell  style={{padding:0, textAlign:'start', border:0, paddingBottom:'1vh', paddingTop:'0vh'}}>
                                                            <Typography variant={'h4'} style={{color:'#58666B'}}>{EgSegmentParser.parseQSummary(this.getTemplateText(tempid), this.state.shortRecommendations.length)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                                :''} 
                                            </Table> 
                                        </Grid>  
                                        <Grid xs={12} style={{paddingTop:'1vh', textAlign:'start'}}>
                                            {this.state.invalidReport?'':
                                            <Typography variant={'h4'} ><a href="#0" onClick={()=>{this.moveTo(4)}} className={classes.link}>{this.state.shortRecommendationsMore} {t('Report - Insights - more')}</a></Typography>
                                            }
                                        </Grid>    
                                    </Grid>
                                </Grid>
                                <Grid xs={4} >
                                    <img src={general_insights}  alt="overall" style={{ paddingInline:'1vw'}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                        </a>
                    </Grid>
                    
                </Grid>    
                
            </Paper>
        );
    }
}
SingleReportSummaryRecommendationsBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportSummaryRecommendationsBox),SingleReportSummaryRecommendationsBox);