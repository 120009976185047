import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import GradeCircle from '../../components/GradeCircle'
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import CloseOutlinedIcon from '@material-ui/icons//CloseOutlined';
import LangPicker from '../../util/LangPicker';
import ReportsManager from '../../managers/ReportsManager';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:5,
        paddingBottom:5,
        border:0
    },
    
    tableCell:{
        border:0,
        paddingBottom:'1.2vh',
        paddingTop:'1.2vh',
    },
    
    tableInner:{
        paddingTop:'2vh',
        paddingBottom:'2vh'
    },
    
    boxTitle:{
        paddingInline:20,
        paddingTop:'0.5vh',
        paddingBottom:'0.5vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'99vh',
        overflow:'auto',
        paddingBottom:20
    },
    
    actionButton:{
        marginLeft:10,
        marginRight:10,
        backgroundColor:'#ffffff00'
    } 
  });

class ExtendedRiskEvaluation extends React.Component {
  
    constructor(props) {
        super(props);            
        console.log("RISK EXTENDED EVALUATION")
        this.setTraitsSummary = this.setTraitsSummary.bind(this);
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        traitsSummaryArr:[],  
        context: 'risk'
    };

    componentDidMount(){
        if (this.props.report) this.setState({report: this.props.report})
        if (this.props.reportParts) this.setState({reportParts: this.props.reportParts})
        
        this.setTraitsSummary(this.props.report)
       
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report!==this.state.report ){
            this.setState({report:nextProps.report});  
            this.setTraitsSummary(nextProps.report)
            
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.setTraitsSummary(this.state.report)
        }
        
    }
    
    async setTraitsSummary(report){
        try{
            if(report && report.report_part && report.report_part.length){
                const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 7 )
                const reparts = this.state.reportParts;
                const traitsSummaryArr = report.report_part[ind].template_id.map(function(pid){
                    const partind = reparts.findIndex(({ id }) => id === pid )
                    if (LangPicker.current() === 'en'){
                        return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                    }
                    else{
                        return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                    }
                })
                const traitSumArrDisp = ReportsManager.setTraitsSummary(report, reparts)
                const finalTraitsArr = traitSumArrDisp.concat(traitsSummaryArr)
                this.setState({traitsSummaryArr: finalTraitsArr})    
            }
        }
        catch(err){
            console.error(err)
        }
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        
        return (
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:5, borderTop:'1px solid #e8e8e8'}}>
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Grid container className={classes.boxTitle}>
                        <Grid xs={10} style={{display:'flex', alignItems:'center'}}>
                            <Typography variant={'h4'} style={{fontWeight:600, paddingTop:'1.5vh', paddingBottom:'1.5vh'}}>{t('Reports - Summary - Detailed Traits Analysis')} </Typography>
                        </Grid>
                        <Grid xs={2} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                            <EgButton secondary="true" className={classes.actionButton} onClick={()=>{}}><CloseOutlinedIcon style={{fontSize:16}}></CloseOutlinedIcon></EgButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={8}>
                                <Typography variant={'h5'} style={{paddingInline:30, paddingTop:'2vh'}}>
                                {t('Reports - Summary - Learn more about traits and their meaning')}
                                </Typography>
                        </Grid>
                    </Grid>    
                    
                    <div className={classes.tableInner}>
                        <Table className={classes.tableInner} aria-label="inner table" >
                        <TableBody >
                            {this.state.report && this.state.report.assessment?
                                this.state.report.assessment.assessment_scores.map((score, index) => (
                                    score.trait.dark===true && !score.trait.is_dominant && score.trait.father_id===300?
                                   
                                    <TableRow key={"char2" + index }   >
                                        <TableCell style={{width:40, border:0}}></TableCell>
                                        <TableCell className={classes.tableCell} style={{width:'5%', paddingTop:'3vh', paddingBottom:'2vh'}}>
                                            {score.trait_adjusted_score?
                                            <GradeCircle value={Math.floor(score.trait_adjusted_score)} negative={score.trait.dark} boundaries={score.trait.boundaries}/>:
                                            <GradeCircle value={Math.floor(score.trait_weighted_score*100/score.trait_potential_score)} negative={score.trait.dark} boundaries={score.trait.boundaries}/>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{width:'90%'}}>
                                            <Typography variant={'h4'} style={{fontWeight:600, paddingBottom:5, textAlign:'start'}}>
                                                {LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name}
                                            </Typography>
                                            <Typography variant={'h4'} style={{textAlign:'start'}}>{this.state.traitsSummaryArr.length?ReportsManager.getTemplateText(score.trait_id, this.state.traitsSummaryArr,this.state.reportParts):''}
                                            </Typography> 
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{width:'5%', paddingInlineStart:50}}>
                                        </TableCell>
                                    </TableRow>
                                    :''
                            )):''}
                        </TableBody>
                        </Table>
                    </div>
                </Paper>
            </Grid>
            </div>               
           

    );
  }
}
ExtendedRiskEvaluation.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(ExtendedRiskEvaluation),ExtendedRiskEvaluation);