import React from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import * as EmailValidator from 'email-validator';
import { withCookies, Cookies } from 'react-cookie';
import { isExpired, decodeToken } from "react-jwt";
import jwt_decode from "jwt-decode";
import { withTranslation } from 'react-i18next';
import LangPicker from "../../util/LangPicker";
import GenericErrorDialog from '../../components/GenericErrorDialog';
import EgHttp from '../../util/EgHttp';
import SignTopbar from './SignTopbar'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SocialRow from './SocialRow';
import passwordIcon from '../../img/password_icon.svg'
import emailIcon from '../../img/email_icon.svg'
import nameIcon from '../../img/name_icon.svg'
import EgButton from '../../components/EgButton';
import signBg from '../../img/assessment_bg.svg';
import NavigationManager from '../../managers/NavigationManager';
import EgActivityRecorder from '../../util/EgActivityRecorder';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    signupPaper:{
        height:480,
        width:412,
        margin:0,
        borderRadius:8 
    },
    
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
    
    xsText:{
        color:"#7F7F7F",
        fontSize: 12,
        fontWeight:500,
    },
    
    appRight:{
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
      },
    appLeft:{
        [theme.breakpoints.up('sm')]: {
        width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
        
      },
    inputLabel:{
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#043241',
            opacity: 1,
          },
          fontSize:14,
          height:10,
    },
    signButton:{
        padding:10, 
        width:'100%',
    },
    link:{
        textDecoration:'none',
        color: theme.palette.text.black,
    }, 
  });
  
  
class Signup extends React.Component {
  
  state = {
    open: this.props.open,
    operationIcon: null,
    fullname: null,
    email: null,
    password: null,
    emailVal:true,
    nameVal:true,
    passVal:true,
    errDialogOpen:false,
  };

    constructor(props) {
        super(props);            
        this.signup = this.signup.bind(this);   
        this.validateEmail = this.validateEmail.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validatePassword = this.validatePassword.bind(this); 
        this.closeErrorDialog = this.closeErrorDialog.bind(this) ;

        LangPicker.resolve(this.props)
    }

    componentDidMount(){
       this.initiate();
    }

    async initiate(){
        const jwtoken = this.props.cookies.cookies.jwt;
        if (jwtoken){
            const myDecodedToken = jwt_decode(jwtoken);
            const rowsres = myDecodedToken.data
            const isMyTokenExpired = isExpired(jwtoken);

            if (!isMyTokenExpired){
                if (rowsres.isRegister){
                    this.registerAndMoveToCompany(rowsres.firstName, rowsres.lastName, rowsres.email, rowsres.token, true)
                    return;
                }
                else{
                    const response = await EgHttp.get('/sign/config/' + rowsres.org_idd)
                    let confres = await response.json();
                    let logobj = {logged:true,  account:{id:rowsres.id, firstname: rowsres.firstname, lastname: rowsres.lastname, fullname:rowsres.firstname + " " + rowsres.lastname,  email:rowsres.email, role:rowsres.role}, org:{id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests, language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license, config:confres?confres.config:''}}
                    this.props.setLogin(true,  {id:rowsres.id, firstname: rowsres.firstname, lastname: rowsres.lastname, fullname:rowsres.firstname + " " + rowsres.lastname,  email:rowsres.email, role:rowsres.role}, {id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests, language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license, config:confres?confres.config:''})
                    this.props.setAdminDetails(logobj)
                    NavigationManager.getLast(this.props)
                }
            }
        }
    }

    closeErrorDialog(){
        this.setState({errDialogOpen:false});
        this.setState({errDialog:null});
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value }); 
    };

    validateEmail(event){
        this.setState({emailVal:EmailValidator.validate(event.target.value)})
    }
    validateName(event){
        this.setState({nameVal:(event.target.value && event.target.value.length>1) })
    }
    validatePassword(event){
        this.setState({passVal:event.target.value})
    }
    validateAll(){
        let failed = false;
        if (!EmailValidator.validate(this.state.email)){ 
            this.setState({emailVal:false})
            failed=true;
        }
        if (!this.state.fullname || this.state.fullname.length<2) {
            this.setState({nameVal:false})
            failed=true;
        }
        if (!this.state.password) {
            this.setState({passVal:false})
            failed=true;   
        }
        if (failed) return false;
        return true;
    }

    async signup(){
        const { t } = this.props;
        if (!this.validateAll()){
            return;
        }

        let responseex = await EgHttp.post('/sign/exists', JSON.stringify({
                email: this.state.email,}))
        
        if (responseex.status !== 404) {
            this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Signup - Failed to Signup")} message={t("Signup - Email is already in use")}></GenericErrorDialog>})
            return;
        }
        else{
            const firstName = this.state.fullname.split(' ').slice(0, -1).join(' ');
            const lastName = this.state.fullname.split(' ').slice(-1).join(' ');
            this.registerAndMoveToCompany(firstName, lastName, this.state.email, this.state.password, false)
            return;
        }
    }

    registerAndMoveToCompany(first, last, email, token, isEmailConfirmed){
        
        return EgHttp.post('/sign',JSON.stringify({
                firstName: first,
                lastName: last,
                email: email,
                token: token,   
                isConfirmed: isEmailConfirmed,
                language: LangPicker.current(),
                orgId: -1 /* When signing up we first add the account without a company and later adding it */  
            }))
        
        .then((response)=> response.json())
        .then((rowsres) => {  
            if (rowsres && rowsres.id) {
                let logobj = {logged:true,  account:{id:rowsres.id, firstname: first, lastname: last, fullname:first + " " + last,  email}, org:{id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests, language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license, config:rowsres.config}}
                this.props.setLogin(true,  {id:rowsres.id, firstname: first, lastname: last, fullname:first + " " + last,  email:email}, {id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests, language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license, config:rowsres.config})
                this.props.setAdminDetails(logobj)
            }
            if (isEmailConfirmed){
                this.props.history.push('/company');
            }
            else{
                this.props.history.push('/confirm');
            }
        })
    }
  
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>
            <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} >
                <Toolbar >
                    <SignTopbar mode="signup"></SignTopbar>
                </Toolbar>
            </AppBar>
            <Container style={{maxWidth:'100%'}}>
            <Grid container spacing={0}  alignItems="center" style={{backgroundImage:`url(${signBg})`,width:'100vw', height:'100vh', maxWidth:'100%'}}>
                
                <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                    
                    <Paper className={classes.signupPaper} elevation={3} style={{position:'relative', zIndex:1}}>
                        <Grid item xs={12} style={{paddingTop:30, paddingBottom:40}}>
                            <Typography  variant="h1" style={{color:'#043241', textAlign:'center'}}>{t('Signup - Create account')}</Typography>
                        </Grid>
                        <form className={classes.form} noValidate>
                        
                        <Grid container spacing={2} justifyContent="center">
                            
                            <Grid item xs={8}  >
                                <SocialRow />
                            </Grid>
                            <Grid item xs={8} style={{paddingTop:20, }}>     
                                <Typography variant="h5" >{t('Signin - Or')}</Typography>
                            </Grid>
                                
                            <Grid item xs={10} style={{padding:5}}>
                                <TextField autoComplete="fullname" name="Name" variant="outlined" required fullWidth={true} id="fullname"  placeholder={t('Signup - Name')} onChange={this.handleChange} onBlur={this.validateName}
                                error={this.state.nameVal?false:true}
                                label={this.state.nameVal?"": t('Signup - Please enter a valid name')}
                                
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={nameIcon} style={{width:16}} alt="nameicon"/>
                                    </InputAdornment>
                                    ),
                                    classes: { input: classes.inputLabel }, 
                                }}
                                className={classes.signupTxt}/>
                            </Grid>
                            
                            <Grid item xs={10} style={{padding:5}}>
                                <TextField variant="outlined" required fullWidth={true} id="email"  placeholder={t('Signup - Email')} name="email" autoComplete="email" onChange={this.handleChange} onBlur={this.validateEmail}
                                    error={this.state.emailVal?false:true}
                                    label={this.state.emailVal?"":t('Signup - Please enter a valid email')}
                                    InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={emailIcon} alt="emailicon"/>
                                    </InputAdornment>
                                    ),
                                    classes: { input: classes.inputLabel }, 
                                }}
                                className={classes.signupTxt}/>
                            </Grid>
                            <Grid item xs={10} style={{padding:5}}>
                                <TextField variant="outlined" required fullWidth={true} name="password" placeholder={t('Signup - Password')} type="password" id="password" autoComplete="current-password"  onChange={this.handleChange} onBlur={this.validatePassword}
                                error={this.state.passVal?false:true}
                                label={this.state.passVal?"":t('Signup - Password cant be empty')}
                                onMouseEnter={EgActivityRecorder.handleMouseEnter}
                                
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={passwordIcon} alt="passwordicon"/>
                                    </InputAdornment >
                                    ),
                                    classes: { input: classes.inputLabel }, 
                                }}
                                className={classes.signupTxt}/>
                            </Grid>
                            <Grid item xs={10} style={{padding:5}}>
                                <EgButton   variant="contained"  onClick={this.signup} className={classes.signButton} onMouseEnter={EgActivityRecorder.handleMouseEnter} mouseenterdata={JSON.stringify({test: 'Mouse Enter'})}>
                                    {t('Signup - SignUp')}
                                </EgButton>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={12} style={{padding:5}}>
                           
                        </Grid>
                        <Typography  variant="h5" >{t('Signin - Agree to terms')}&nbsp;
                            <a href={LangPicker.current() === 'en'?"https://engard.io/terms":"https://engard.io/he/terms"} /*target="_blank"*/ className={classes.link}>{t('Signin - terms')}</a>
                        </Typography>
                </form>
                </Paper>
            </Grid>   
        </Grid>
        {this.state.errDialog}
        
        </Container>
        </div>
        );
    }
    }
Signup.propTypes = {
    cookies: instanceOf(Cookies).isRequired,
    classes: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withCookies(withRouter((withStyles(styles))(Signup),Signup))))
