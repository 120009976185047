import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import qs from 'qs';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { instanceOf } from 'prop-types';
import GenericErrorDialog from '../../components/GenericErrorDialog';
import EgHttp from '../../util/EgHttp';
import SignTopbar from './SignTopbar'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import EgButton from '../../components/EgButton';
import signBg from '../../img/assessment_bg.svg';
import LangPicker from '../../util/LangPicker';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    signupPaper:{
        height:480,
        width:412,
        margin:0,
        borderRadius:8 
    },
    
    signupLink:{
        textDecoration:"none",
        color: theme.palette.text.white,
    },
    appRight:{
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
      },
    appLeft:{
        [theme.breakpoints.up('sm')]: {
        width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
    },
    signButton:{
        padding:10, 
        width:'100%',
    }, 
});

//const PAGE_STATE_EMAIL_SENT = 0;
const PAGE_STATE_EMAIL_SUCCESS_CONFIRM = 1;
const PAGE_STATE_EMAIL_ALREADY_CONFIRM = 2;


class ConfirmEmail extends React.Component {
  
    state = {
        open: this.props.open,
        orgId:null,
    };

    constructor(props) {
        super(props);   
        this.confirm = this.confirm.bind(this); 
        this.closeErrorDialog = this.closeErrorDialog.bind(this); 
    }

    componentDidMount(){
        const id=qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id
        const confirmToken = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).t
        this.setConfirmPageState(id, confirmToken)

        LangPicker.resolve(this.props)
    }

    closeErrorDialog(){
        this.setState({errDialogOpen:false});
        this.setState({errDialog:null});
    }

    async setConfirmPageState(id, confirmToken){
        if (id){
            const isConf = await this.isConfirmed(id);
            if (isConf){
                this.setState({pageState:2})
            }
            else{
                this.confirm(id, confirmToken)
            }
        }
        else{
            this.setState({pageState:null})
        }
    }

    async isConfirmed(id){
        try{
            let response = await EgHttp.get('/sign/isconfirmed/' + id)
            if (response.status === 200){
                let rowsres = await response.json();
                if (rowsres.emailconfirmed){
                    this.setState({orgId:rowsres.org_id})
                    return true;
                }
                else return false;
            }
            return false;
        }
        catch(err){
            console.error(err)
            return false;
        }
    }

    async confirm(id, confirmToken){
        try{
            const { t } = this.props;
            let response = await EgHttp.put('/sign/confirm', JSON.stringify({
                    id:id,
                    confirmtoken: confirmToken?confirmToken.trim():confirmToken}))
            
            if (response.status === 200){
                let rows = await response.json();
                let rowsres = rows[0]
                if (rowsres && rowsres.id) { 
                    let logobj = {logged:true,  account:{id:rowsres.id, firstname: rowsres.firstname, lastname: rowsres.lastname, fullname:rowsres.firstname + " " + rowsres.lastname,  email:this.state.email, role:rowsres.role}, org:{id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests}}
                    this.props.setLogin(true,  {id:rowsres.id, firstname: rowsres.firstname, lastname: rowsres.lastname, fullname:rowsres.firstname + " " + rowsres.lastname,  email:this.state.email, role:rowsres.role}, {id:rowsres.org_idd, name:rowsres.org_name, tests:rowsres.org_tests, language:rowsres.language, time_limit:rowsres.time_limit, video_required:rowsres.video_required, license:rowsres.license})
                    this.props.setAdminDetails(logobj)
                    this.setState({pageState:1})
                }
                else{
                    this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Confirm - Failed to Confirm")} message={t("Confirm - We are sorry")}></GenericErrorDialog>})
                    return;
                }
            }
            else{
                this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Confirm - Failed to Confirm")} message={t("Confirm - We are sorry")}></GenericErrorDialog>}) 
                return;
            }   
            return;
        }
        catch(err){
            console.error(err)
            return
        }
    }
  
  
    render() {
        const { t,i18n } = this.props;
        const { classes } = this.props;
        return (
            <div>
            <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} >
                <Toolbar >
                    <SignTopbar mode="signup"></SignTopbar>
                </Toolbar>
            </AppBar>
            
            <Container style={{maxWidth:'100%'}}>
            <Grid container spacing={0} justify="center" alignItems="center" style={{backgroundImage:`url(${signBg})`,width:'100vw', height:'100vh', maxWidth:'100%'}}>
                
                <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                    
                    <Paper className={classes.signupPaper} elevation={3} style={{position:'relative', zIndex:1}}>
                        <Grid item xs={12} style={{paddingTop:30, paddingBottom:80}}>
                            <Typography variant="h1" style={{color:'#043241', textAlign:'center'}}>{t("Confirm - Confirm Email")} </Typography>
                        </Grid>
                        <Grid container spacing={2} justify="center">
                            
                            {this.state.pageState===PAGE_STATE_EMAIL_SUCCESS_CONFIRM?
                            <div>
                                <Grid item xs={12} style={{padding:20}}/>
                                <Grid item xs={12} >
                                    <Typography variant="h4">{t("Confirm - Your Email was successfully confirmed")}<br />{t("Confirm - Click Continue in order to complete your registration")}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{padding:80}}/>
                                <Grid item xs={12} >
                                    <EgButton type="submit"  variant="contained"  className={classes.signButton}>
                                        <Link className={classes.signupLink}to="/company" >{t('Confirm - Continue')}</Link>
                                    </EgButton>  
                                </Grid>
                            </div>
                            :this.state.pageState===PAGE_STATE_EMAIL_ALREADY_CONFIRM?
                            <div>
                                <Grid item xs={12} style={{padding:20}}/>
                                <Grid item xs={12}>
                                    <Typography variant="h4">{t("Confirm - Your Email is already confirmed")}<br /><br />{t("Confirm - Click Continue to proceed")}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{padding:80}}/>
                                <Grid item xs={12} style={{padding:5}}>
                                    <EgButton type="submit"  variant="contained"  className={classes.signButton}>
                                        {this.state.orgId && this.state.orgId>0?
                                        <Link className={classes.signupLink}to="/signin" >{t('Confirm - Continue')}</Link>
                                        :
                                        <Link className={classes.signupLink}to="/company" >{t('Confirm - Continue')}</Link>
                                        }
                                    </EgButton>  
                                </Grid>
                            </div>
                            : // PAGE_STATE_EMAIL_SENT
                            <div>
                                <Grid item xs={12} style={{padding:20}}/>
                                <Grid item xs={12} style={{padding:5}}>
                                    <Typography variant="h4" >{t("Confirm - An email with confirmation link was sent to you")} <br/> {t("Confirm - Click the confirmation link to proceed")}</Typography>
                                </Grid>
                            </div>
                            }
                        </Grid>
                </Paper>
            </Grid>
                
        </Grid>
        {this.state.errDialog}
        </Container>
        </div>
        );
    }
}
ConfirmEmail.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
};

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withCookies(withRouter((withStyles(styles))(ConfirmEmail),ConfirmEmail))))