import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, Table, TableRow, TableCell } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import LangPicker from '../../util/LangPicker';
import CompareBar from '../../components/CompareBar';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

 
const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        paddingInline:5,
        paddingTop:8,
        paddingBottom:8,
    },
    boxTitle:{
        padding:'1.5vh',
        paddingInline:30,
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    cellLegand:{
        paddingTop:'1.7vh', 
        paddingBottom:'1.6vh',
        border:0, 
        paddingInline:5,
        backgroundColor: "#FAFAFB"
    }
    
  });
  
class CompareLookalikeToModel extends React.Component {
  
    constructor(props) {
        super(props);            
        this.closeDrawer = this.closeDrawer.bind(this);  
    }

    state = {
        addOpen: this.props.addOpen,
        selectedModel:null,
        selectedScore:[]
    }

    componentDidMount(){
        this.initialize()
    }


    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){             
            this.setState({addOpen:nextProps.openState}); 
            this.initialize()   
        }
        if (nextProps.comparedId !==this.state.comparedId){
            this.setState({comparedId:nextProps.comparedId});  
            this.initialize()   
        }
    }

    async initialize(){
        try{
            if (!this.props.models) return
            const selInd = this.props.models.findIndex(({ id }) => id === this.props.selectedModel )
            if (selInd > -1) this.setState({selectedModel:this.props.models[selInd]})
            this.setState({selectedCandidate:this.props.campaign.lookalike_campaign_compared_candidates[this.props.comparedId]})
            const scoreInd = this.props.currentScores.findIndex(({ hrId }) => hrId === this.props.campaign.lookalike_campaign_compared_candidates[this.props.comparedId].hr_id )
            const traitDist = this.props.currentScores[scoreInd].traitDistances
            const filteredTraits = this.props.traits.filter(trait => trait.is_display)
            const sortedTraits = filteredTraits.sort(function (a, b) {return a.dark && !b.dark?1:-1})
            let traitsScoresNet = sortedTraits.map(function (trait){
                    const traitInd = traitDist.findIndex(( {traitId} ) => traitId === trait.id )
                    if (traitInd > -1) return { traitId:trait.id, 
                                                displayName:LangPicker.current()==='en'?trait.display_name:trait.trait_localized[0].localized_name,
                                                refScore:traitDist[traitInd].refScore,
                                                hrScore:traitDist[traitInd].hrScore,
                                                dark:trait.dark
                                        }
                    return null
            })
            traitsScoresNet = traitsScoresNet.filter(function(e){return e}); 
            console.log(traitsScoresNet)
            /*
            traitsScoresNet = traitsScoresNet.sort(function (a, b) {
                return a.dark && !b.dark?1:0
            });*/
            this.setState({traitMutualScores:traitsScoresNet})
            const dominiantTraitScore = (this.props.currentScores[scoreInd].dominantTraitsScores.score*100).toFixed(1);
            this.setState({dominiantTraitScore})
            
        }
        catch(err){
            console.error(err)
        }
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    
    closeDrawer(){
        this.setState({addOpen:false})
        this.props.handleDrawerState(false)
        this.setState({campaignName:null, forPosition: null, candidatesToCompare:[],  models:[], campaignId:null})
    }

    render() {
        const thisRef = this
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>
            <React.Fragment key={i18n.dir()==='rtl'?'left':'right'}>
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:800}}>
            <div style={{width:800}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:0, paddingTop:'1vh', paddingBottom:50}}>
                            
                            <Grid item xs={6} style={{paddingInlineStart:30, paddingTop:'2vh'}}>
                                <Typography variant={'h1'}>{t('Lookalike - Single - Compare traits')}  </Typography>
                            </Grid>
                            
                            <Grid item xs={6} style={{paddingInlineEnd:10, paddingTop:'2vh', textAlign:'end'}} >
                                <EgButton secondary="true" className={classes.closeIcon} onClick={this.closeDrawer} >
                                    <CloseOutlinedIcon style={{fontSize:16}}></CloseOutlinedIcon>
                                </EgButton>     
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'3vh', paddingInline:0}}>
                                <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - Personality Type Similarity')}</Typography>
                                
                            </Grid>
                            <Grid item xs={2} className={classes.formRow} style={{paddingInlineStart:20}}>
                                        <Typography variant={'h5'} style={{textAlign:'end'}}>{''}</Typography>
                            </Grid>
                            <Grid item xs={8} className={classes.formRow}>
                                <CompareBar variant="determinate" value={this.state.dominiantTraitScore } isref={false} ></CompareBar>
                            </Grid>
                            <Grid item xs={2} className={classes.formRow}>
                                <Typography variant={'h5'}>{this.state.dominiantTraitScore+'%'}</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.formRow} style={{paddingTop:'3vh', paddingInline:0, paddingBottom:'2vh'}}>
                                <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - Traits Similarity')}</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.formRow} style={{paddingTop:'3vh', paddingInline:0, paddingBottom:'2vh'}}>
                            <Table>
                                    <TableRow>
                                        <TableCell className={classes.cellLegand} style={{width:'10%'}}>
                                            <div style={{backgroundColor:'#0085AF', width:30, height:15}}></div>
                                        </TableCell>
                                        <TableCell className={classes.cellLegand} style={{width:'40%'}}>
                                            <Typography variant={'h4'} style={{textAlign:'start'}}>{this.state.selectedModel?(this.state.selectedModel.id>0?this.state.selectedModel.firstname+" "+this.state.selectedModel.lastname:this.state.selectedModel.position):''}  </Typography>
                                        </TableCell>
                                        <TableCell className={classes.cellLegand} style={{width:'10%'}}>        
                                            <div style={{backgroundColor:'#C1A5FF',  width:30, height:15}}></div>
                                        </TableCell>
                                        <TableCell className={classes.cellLegand} style={{width:'40%'}}>
                                            <Typography style={{textAlign:'start'}}variant={'h4'}>{this.state.selectedCandidate?this.state.selectedCandidate.human_resource.firstname+" "+this.state.selectedCandidate.human_resource.lastname:''}  </Typography>
                                        </TableCell>
                                        <TableCell className={classes.cellLegand} style={{width:30}}>
                                         </TableCell>
                                    </TableRow>
                                </Table>
                            </Grid>
                            {this.state.traitMutualScores? this.state.traitMutualScores.map(function(trait, index){return(
                                index === 0?
                                    <Grid container>
                                        <Grid item xs={12} className={classes.formRow} style={{paddingInlineStart:30, fontWeight:600}}>
                                            <Typography variant={'h4'} style={{fontWeight:600}}>{t('Report - Traits - Productive Personaliy Traits')} </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.formRow} style={{paddingInlineStart:5}}>
                                            <Typography variant={'h5'} style={{textAlign:'start', paddingInlineStart:30}}>{trait.displayName}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className={classes.formRow}>
                                            <CompareBar variant="determinate" value={trait.refScore*100 } isref={true} style={{margin:2}}></CompareBar>
                                            <CompareBar variant="determinate" value={trait.hrScore*100 }  isref={false} style={{margin:2}}></CompareBar>
                                        </Grid>
                                        <Grid item xs={2} className={classes.formRow} style={{paddingInlineEnd:0, paddingBottom:20}}>
                                            <Typography variant={'h5'} style={{marginTop:-5}}>{(trait.refScore*100).toFixed(0)}</Typography>
                                            <Typography variant={'h5'} style={{marginTop:0}}>{(trait.hrScore*100).toFixed(0)}</Typography>
                                        </Grid>
                                    </Grid>
                                    :
                                    trait.dark && index >0 && !thisRef.state.traitMutualScores[index-1].dark?
                                    <Grid  container>  
                                        <Grid item xs={12} className={classes.formRow} style={{paddingInlineStart:30, fontWeight:600}}>
                                            <Typography variant={'h4'} style={{fontWeight:600}}>{t('Report - Traits - Counterproductive Personaliy Traits')}</Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.formRow} style={{paddingInlineStart:5}}>
                                            <Typography variant={'h5'} style={{textAlign:'start', paddingInlineStart:30}}>{trait.displayName}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className={classes.formRow}>
                                            <CompareBar variant="determinate" value={trait.refScore*100 } isref={true} style={{margin:2}}></CompareBar>
                                            <CompareBar variant="determinate" value={trait.hrScore*100 }  isref={false} style={{margin:2}}></CompareBar>
                                        </Grid>
                                        <Grid item xs={2} className={classes.formRow} style={{paddingInlineEnd:0, paddingBottom:20}}>
                                            <Typography variant={'h5'} style={{marginTop:-5}}>{(trait.refScore*100).toFixed(0)}</Typography>
                                            <Typography variant={'h5'} style={{marginTop:0}}>{(trait.hrScore*100).toFixed(0)}</Typography>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container>
                                        <Grid item xs={4} className={classes.formRow} style={{paddingInlineStart:5}}>
                                            <Typography variant={'h5'} style={{textAlign:'start', paddingInlineStart:30}}>{trait.displayName}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className={classes.formRow}>
                                            <CompareBar variant="determinate" value={trait.refScore*100 } isref={true} style={{margin:2}}></CompareBar>
                                            <CompareBar variant="determinate" value={trait.hrScore*100 }  isref={false} style={{margin:2}}></CompareBar>
                                        </Grid>
                                        <Grid item xs={2} className={classes.formRow} style={{paddingInlineEnd:0, paddingBottom:20}}>
                                            <Typography variant={'h5'} style={{marginTop:-5}}>{(trait.refScore*100).toFixed(0)}</Typography>
                                            <Typography variant={'h5'} style={{marginTop:0}}>{(trait.hrScore*100).toFixed(0)}</Typography>
                                        </Grid>
                                    </Grid>
                            )}):''}
                        
                            <Grid item xs={12} className={classes.formRow}></Grid>

                        </Grid>
                </Paper>
            </div>
            </Drawer>
            </React.Fragment>

        </div>     
      
    );
  }
}
CompareLookalikeToModel.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(CompareLookalikeToModel),CompareLookalikeToModel)))