import { RiskLevels } from "../util/constants/RiskLevels"

export default function EgRiskPatch(props) {
    let value = 0
    if (props.value) value = props.value
    const riskLevels = RiskLevels.get()
    
    return (
      
      <div>
          {
          props.direct!==null && props.direct!==undefined?
          <div  style={{backgroundColor:riskLevels[props.direct].color,width:100, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{riskLevels[props.direct].name}</div>:
          props.negative?
            value<props.boundaries[0].top?
                <div  style={{backgroundColor:riskLevels[0].color,width:200, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{riskLevels[0].name}</div>
              :
              value<props.boundaries[1].top?
              <div  style={{backgroundColor:riskLevels[1].color,width:200, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{riskLevels[1].name}</div>
              :
              <div  style={{backgroundColor:riskLevels[2].color,width:200, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{riskLevels[2].name}</div>
          :
            value<props.boundaries[0].top?
            <div  style={{backgroundColor:riskLevels[2].color,width:200, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{riskLevels[2].name}</div>
                :
                value<props.boundaries[1].top?
                <div  style={{backgroundColor:riskLevels[1].color,width:200, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{riskLevels[1].name}</div>
                :
                <div  style={{backgroundColor:riskLevels[0].color,width:200, height:30 , border: "solid 1px",padding: 5,textAlign:'center', color:'#ffffff', fontSize:13, fontWeight:600} }>{riskLevels[0].name}</div>
            }
      </div>
    );
  }