import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, TextField } from '@material-ui/core';
import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width:100,
        marginLeft:5,
        marginRight:5
    },
    actionButtonWide:{
        width:200,
        marginLeft:5,
        marginRight:5
    },
    actionButtonMini:{
        marginBottom:3,
        paddingTop:2,
        paddingBottom:2,
        fontSize: 10
    },
    
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
  });
  
class AddQuestionnaire extends React.Component {
  
    constructor(props) {
        super(props);            
        this.closeDrawer = this.closeDrawer.bind(this);  
        this.saveQuestionnaire = this.saveQuestionnaire.bind(this);  
        this.validateName= this.validateName.bind(this);   
        this.validateAll= this.validateAll.bind(this);  
        this.addQuestion = this.addQuestion.bind(this)
    }

    state = {
        addOpen: this.props.addOpen,
        questionnaireId: this.props.questionnaireId,
        questionnaireName:null,
        questionnaire:null,
        nameVal:true,
        questions:[],
        qVal:[]
        
    };

    componentDidMount(){
        this.initialize()
    }


    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){             
            this.setState({addOpen:nextProps.openState}); 
            this.setState({questionnaireName:null})       
        }
        if (nextProps.questionnaire){
            this.setState({ questionnaire:nextProps.questionnaire, 
                            questions: nextProps.questionnaire.questions, 
                            questionnaireName:nextProps.questionnaire.name,     
                            questionnaireId: nextProps.questionnaire.id });  
            const qv = nextProps.questionnaire.questions.map(function(q){return true})
            this.setState({qVal: qv})                 
        }
    }

    async initialize(){
        try{
            console.log("id:" + this.props.questionnaireId)
        }
        catch(err){
            console.error(err)
        }
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    updateQuestion(index, event){
        const qus = this.state.questions
        qus[index].text = event.target.value
        this.setState({questions:qus})
    }
    
    closeDrawer(){
        this.setState({addOpen:false})
        this.props.handleDrawerState(false)
        this.setState({questionnaireName:null, questionnaireId:null, questions:[], qVal:[]})
    }

    validateName(event){
        this.setState({nameVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
    validateQuestion(index, event){
        const qvals = this.state.qVal;
        qvals[index] = event.target.value.trim() && event.target.value.trim().length>1
        this.setState({qVal:qvals})
    }
    
    validateAll(){
        let failed = false;
        if (!this.state.questionnaireName || !this.state.questionnaireName.trim() || this.state.questionnaireName.trim().length<2) {
            this.setState({nameVal:false})
            failed=true;
        }
        const qv = this.state.qVal
        this.state.questions.map(function(q, index){
            failed = failed && !(q.text.trim() && q.text.trim().length>1)
            qv[index] = q.text.trim() && q.text.trim().length>1
            return null
        })
        
        this.setState({qVal:qv})
        if (failed) return false;
        return true;
    }

    addQuestion(){
        const qus = this.state.questions;
        const qvals = this.state.qVal;
        qus.push({id:null, text:"", type:"AUDIO_VIDEO"})
        this.setState({questions: qus})
        qvals.push(true)
        this.setState({qVal: qvals})
    }

    removeQuestion(index){
        const qus = this.state.questions;
        const qvals = this.state.qVal;
        qus.splice(index, 1);
        qvals.splice(index, 1);
        this.setState({questions:qus})
        this.setState({qVal:qvals})
    }

    async saveQuestionnaire(){
        if (!this.validateAll()){
            return;
        }
        const { t } = this.props;
        let body = JSON.stringify({
            name: this.state.questionnaireName,
            id:this.state.questionnaireId,
        })
        let response;
        if (this.state.questionnaireId){
            const qobj = this.state.questions
            body = JSON.stringify({
                questionnaire_name: this.state.questionnaireName,
                questionnaire_id: this.state.questionnaireId,
                questions: qobj,
            })
            response = await EgHttp.put('/remoteinterviews/questionnaire',body)
        }
        else{
            response = await EgHttp.post('/remoteinterviews/questionnaire',body)
            let rowsres = await response.json();
            EventLog.insertAcc(this.props.admin.org.id, 0, EventLog.NEW_QUESTIONNAIRE, this.props.admin.account.id)
            if (rowsres && rowsres.id && this.state.questions.length>0){
                const qobj = this.state.questions
                body = JSON.stringify({
                    questions: qobj,
                    questionnaire_id: rowsres.id
                })
                
                response = await EgHttp.post('/remoteinterviews/questions',body)
                rowsres = await response.json();
            }
        }
        
        this.closeDrawer();
        this.props.refresh("SUCCESS", this.state.questionnaireId?t('Remote - Add - Questionnaire details updated successfully'):t('Remote - Add - New questionnaire added successfully'));
        return;
    }

    
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>

            <React.Fragment key={i18n.dir()==='rtl'?'left':'right'}>
           
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:600}}>
            <div style={{width:600}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={12} justify="left">
                                <Typography variant={'h1'}>{!this.state.questionnaireId?t('Remote - Add New Questionnaire'):t('Remote - Table - Edit Questionnaire')}  </Typography>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:30}}>
                                <Typography variant={'h5'}>{t('Remote - Table - Questionnaire Name')}</Typography>
                                <TextField autoComplete="questionnairename" name="questionnaireName" variant="outlined" required fullWidth='true' id="questionnaireName" label="" value={this.state.questionnaireName} onChange={this.handleChange} className={classes.signupTxt} onBlur={this.validateName}
                                    error={this.state.nameVal?false:true}
                                    helperText={this.state.nameVal?"":t('Remote - Add - Please enter a valid questionnaire name')}
                                    InputProps={{
                                        classes: { input: classes.inputLabel }, 
                                    }}>
                                </TextField>
                            </Grid>
                            
                            {this.state.questions.map((row, index) => (
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'1vh'}}>
                                <Grid container>
                                    <Grid item xs={6}><Typography variant={'h5'}>{t('Remote - Add - Question') + " " + (index+1)}</Typography> </Grid>
                                    <Grid item xs={6} style={{display:'flex', justifyContent:'end'}}><EgButton secondary="true" outlined="true" onClick={()=>(this.removeQuestion(index))} className={classes.actionButtonMini}>{t('Remote - Add - Remove Question')}</EgButton> </Grid>
                                    <Grid item xs={12}>
                                        <TextField autoComplete={"question"+index} name={"question"+index} variant="outlined" required fullWidth='true' id={row.id} label="" value={row.text} onChange={(event)=>(this.updateQuestion(index, event))} className={classes.signupTxt} onBlur={(event)=>(this.validateQuestion(index,event))}
                                            error={this.state.qVal[index]?false:true}
                                            helperText={this.state.qVal[index]?"":t('Remote - Add - Please enter a valid question')}
                                            multiline
                                            rows={3}
                                            InputProps={{ 
                                                classes: { input: classes.inputLabel }, 
                                            }}>
                                                {row.text}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            ))}
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'1vh', textAlign:'center'}}>
                                <EgButton secondary="true" outlined="true" onClick={this.addQuestion} className={classes.actionButtonWide}>{t('Remote - Add - Add Another Question')}</EgButton>   
                            </Grid>

                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton  onClick={this.saveQuestionnaire} className={classes.actionButton}>{t('Settings - Accounts - Save')}</EgButton>
                                <EgButton secondary="true" outlined="true" onClick={this.closeDrawer} className={classes.actionButton}>{t('Settings - Accounts - Cancel')}</EgButton>   
                            </Grid>
                            
                        </Grid>
                </Paper>
            </div>
            </Drawer>
            </React.Fragment>

        </div>     
      
    );
  }
}
AddQuestionnaire.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AddQuestionnaire),AddQuestionnaire)))