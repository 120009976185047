import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, IconButton, FormControl, InputLabel, TextField, Select, MenuItem } from '@material-ui/core';
//import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../../redux/admin";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import Dropzone from 'react-dropzone';
import EgProgress from '../../components/EgProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EgWaitDialog from '../../components/EgWaitDialog';

const filter = createFilterOptions();

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding:0,
    },
    formRow:{
        padding:20
    },
    error:{
        fontSize:14,
        color:"#F15D5D",
        fontWeight: 500,
        textAlign:'center'
    },
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    }
})


class UploadCVDialog extends React.Component {
  
    state = {
        open: this.props.open,
        candidate:this.props.candidate,
        files:[],
        dropzoneRef: React.createRef(),
        errorMsg:null,
        loading:null,
        selectedTeam:0,
    };

    constructor(props) {
        super(props); 
        
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 
        this.onDrop = this.onDrop.bind(this);
        this.openFileDialog = this.openFileDialog.bind(this)
        this.onDropRejected = this.onDropRejected.bind(this)
        this.uploadSelectedFile = this.uploadSelectedFile.bind(this)
        this.getPartialPos = this.getPartialPos.bind(this)
    }

    componentDidMount(){
        this.setState({teams:this.props.teams, positions:this.props.positions})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});        
        }
    }

    selectTeam = event => {
        this.setState({ selectedTeam: event.target.value });
    };
    getPartialPos(event){
        const newValue = event.target.value
        if (newValue){
            if (typeof newValue === 'string') {
                if (this.state.positions && this.state.positions.findIndex(({ for_position }) => for_position === newValue ) > -1){
                    const indPos = this.state.positions.findIndex(({ for_position }) => for_position === newValue )
                    this.setState({selectedPosition: this.state.positions[indPos],  selectedTeam: this.state.positions[indPos].team})
                }
                else{
                    this.setState({selectedPosition:{id:0,for_position:newValue}})
                }
            } 
            else {
                this.setState({selectedPosition: newValue, selectedTeam: newValue.team})
            }
        }
    }

    close(){
        this.handleClose();
    }

    onDrop(files){
        this.setState({errorMsg:null})
        this.setState({files:files})
    }
    onDropRejected(rejected){
        const { t } = this.props;
        if(rejected.length>1){
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - You can select only one file')}</Typography> });
        }
        else if(rejected[0].errors[0].code === "file-too-large"){
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - File cannot acceed')}</Typography> });
        }
        else{
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - Illegal file type')}</Typography> });
        }
    }

    uploadSelectedFile(){
        const { t } = this.props;
        if (this.state.files && this.state.files.length) {
            this.upload()
        }
        else{
            this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - No files selected')}</Typography> });
        }
    }

    async upload(){
        this.setState({loading:<EgProgress />})
       
        const { t } = this.props;
        try{
            let formData = new FormData();
            if (!this.state.files || !this.state.files.length) return
            this.setState({isWaitingDialog:<EgWaitDialog open={true} withProgress={true} progressTime={this.state.files.length*30*1000}/>})
            for (let i = 0; i < this.state.files.length; i++) {
                formData.append('files',  this.state.files[i]);
            }
            formData.append('position', this.state.selectedPosition?this.state.selectedPosition.for_position.trim():'')
            formData.append('positionId', this.state.selectedPosition?this.state.selectedPosition.id:null)
            formData.append('teamId', this.state.selectedTeam)
            let jobReq = '';
            if (this.state.positions && this.state.positions.length && this.state.selectedPosition && this.state.selectedPosition.id){
                const posInd = this.state.positions.findIndex(({ id }) => id === this.state.selectedPosition.id )
                if (posInd > -1){
                    jobReq = this.state.positions[posInd].job_reqirements
                    if (jobReq) jobReq = jobReq.replace(/[^\x00-\x7F]/g, "")
                }
            }
            formData.append('jobReq', jobReq)    

            let response = await EgHttp.postFile('/candidates/importFromCVs', formData);
            if (response.status === 200){
                this.setState({files:[]})
                let impRes = await response.json();
                if (impRes.inserted > 0){
                    this.handleClose();
                    this.props.refresh("SUCCESS", t('Candidates - Import - Successfull imported candidates from CSV') + " " + t('Candidates - Import - Imported') + impRes.inserted + " " + t('Candidates - Import - Failed') + impRes.failed);
                }
                else{
                    this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - Failed to import candidates') +": "+ await response.text()}</Typography> });
                }
            }
            else{
                this.setState({errorMsg:<Typography className={this.props.classes.error}>{t('Candidates - Import - Error') +": "+ await response.text()}</Typography> });
            }
            this.setState({ loading:null, isWaitingDialog:null});
            console.log(this.props.admin)
            // EventLog.insertAcc(this.props.admin.org.id, 0, EventLog.INSERT_BULK_CANDIDATES, this.props.admin.account.id)
            return; 
        } 
        catch(err){
            console.error(err.message)
        }
    }

    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };
    openFileDialog() {
        if (this.state.dropzoneRef.current) {
            this.state.dropzoneRef.current.open()
        }
    };
    
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const files = this.state.files.map(file => (
            <li key={file.name}>
              {file.name} - {file.size} bytes
            </li>
          ));
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
               maxWidth={1000}
            >
                <DialogContent style={{padding:0, width:1000, maxWidth:1000}}>
                   <Paper elevation={0} >
                   {this.state.loading}
                   {this.state.isWaitingDialog}
                       <Grid container justify='left' >
                           <Grid item xs={10} style={{paddingTop:20, paddingInlineStart:20}}>
                               <Typography variant={'h1'} >{t('Candidates - Import - Import candidates from their CV')}</Typography>
                            </Grid>
                            <Grid item xs={2} style={{padding:10, textAlign:'end'}}>
                                <IconButton aria-label="close" onClick={this.close} size="small">
                                    <CloseIcon></CloseIcon>
                                </IconButton>
                            </Grid>
                            <Grid item xs={6} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Candidates - Add - Position')}</Typography>
                                <FormControl fullWidth>
                                            <InputLabel id="selectedModelInp"></InputLabel>
                                            <Autocomplete size="small" onBlur={this.getPartialPos}
                                                value={this.state.selectedPosition}
                                                onChange={(event, newValue) => {
                                                    console.log(newValue)
                                                    if (newValue){
                                                        if (typeof newValue === 'string') {
                                                            if (this.state.positions && this.state.positions.findIndex(({ for_position }) => for_position === newValue ) > -1){
                                                                const indPos = this.state.positions.findIndex(({ for_position }) => for_position === newValue )
                                                                this.setState({selectedPosition: this.state.positions[indPos],  selectedTeam: this.state.positions[indPos].team})
                                                            }
                                                            else{
                                                                this.setState({selectedPosition:{id:0,for_position:newValue}})
                                                            }
                                                        } 
                                                        else {
                                                            console.log("setting new team to: " +  newValue.team)
                                                            this.setState({selectedPosition: newValue, selectedTeam: newValue.team})
                                                        }
                                                    }
                                                    
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                id="positionsAutoComp"
                                                options={this.state.positions}
                                                getOptionLabel={(option) => {
                                                    if (typeof option === 'string') {
                                                    return option;
                                                    }
                                                    if (option.inputValue) {
                                                    return option.inputValue;
                                                    }
                                                    return option.for_position;
                                                }}
                                                renderOption={(option) => (option.for_position + ""+ (option.teamName?(" (" + option.teamName + ")"):"" ) )}
                                                style={{ width: '100%' }}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params} label="" variant="outlined" />
                                                )}
                                                />
                                        </FormControl>
                            </Grid>  
                            
                            <Grid item xs={6} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Teams - Team')}</Typography>
                                <FormControl size="small" style={{width:'100%'}}>
                                    <InputLabel id="teamsLbl"></InputLabel>
                                    <Select variant="outlined" labelId="teamsLbl" id="selectedTeam" name="selectedTeam" value={this.state.selectedTeam} onChange={this.selectTeam} >
                                        <MenuItem value={0}>&nbsp;</MenuItem>
                                        {this.state.teams? this.state.teams.map(function(t) {
                                            return <MenuItem value={t.id}>{t.name}</MenuItem>
                                        }):null}       
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} style={{paddingInlineStart:20, paddingBottom:5, }}>
                                <Typography variant={'h5'}>{t('Candidates - Import - Select a candidates CV file')} - {t('Candidates - Import - Select up to 50 files')} </Typography> 
                            </Grid>
                            
                            <Grid item xs={12} style={{paddingInline:20}}>
                                <Grid container>
                                    <Dropzone onDrop={this.onDrop} ref={this.state.dropzoneRef} maxFiles={50} onDropRejected={this.onDropRejected} accept={["application/pdf","application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]} onSubmit={this.upload} maxSize={2000000}>
                                        {({getRootProps, getInputProps}) => (
                                        <section className={classes.container}>
                                            <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <p>{t('Candidates - Import - Drag and drop CV files here')}</p>
                                            
                                            </div>
                                           
                                            <EgButton onClick={this.openFileDialog} secondary="true" outlined>{t('Candidates - Import - Select Files')}</EgButton>
                                            <div><h4>Files:</h4></div>
                                            <div>{files}</div>
                                        </section>
                                        )}
                                    </Dropzone>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{padding:10, display:'flex', justifyContent:'center'}}>
                                {this.state.errorMsg}
                            </Grid>
                            <Grid item xs={12} style={{paddingTop:10, paddingBottom:20, display:'flex', justifyContent:'center'}}>
                                <EgButton onClick={this.uploadSelectedFile} >{t('Candidates - Import - Upload Files')}</EgButton>
                            </Grid>
                       </Grid>
                   </Paper>
                   
                </DialogContent>
            </Dialog>
            </div>
        );
    }
}

UploadCVDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(UploadCVDialog),UploadCVDialog)))