import i18n from '../i18n/i18n'
import qs from 'qs';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';

const LangPicker = {
        
    resolve: function(props){
        
        let lang=qs.parse(props.location.search, { ignoreQueryPrefix: true }).lang
        if (lang){
            console.log("SETTING ASSESS COOKIE TO: " + lang)
            props.cookies.set("lang", lang)  
        }
        if (lang && lang !== i18n.language){
            i18n.changeLanguage(lang)
            document.body.dir = i18n.dir();
        }
        if (!lang){
            lang = props.cookies.cookies.lang
            if (lang && lang!== i18n.language){
                i18n.changeLanguage(lang)
                document.body.dir = i18n.dir();
            }
        }
        if (lang){
            console.log('Setting help lang to: ' + lang)
            window.fwSettings={
                'widget_id':73000001153,
                'locale':lang
            };
        }
        return;
    },

    current: function(){
        return i18n.language
    },
    direction: function(){
        return i18n.dir()
    },
}
LangPicker.propTypes = {
   cookies: instanceOf(Cookies).isRequired
};
export default withCookies(withRouter(LangPicker));
