import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import novideo from '../../img/no-video.png';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
    videoBox:{
        border:'1px solid #E4E4E4',
        height:'60vh',
        overflow:'auto',
        marginInline:10
    },
    boxTitle:{
        padding:20,
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    
    
  });

class SingleReportVideo extends React.Component {
  
    constructor(props) {
        super(props);            
       
        this.setCameraDuration = this.setCameraDuration.bind(this)
        this.setScreenDuration = this.setScreenDuration.bind(this)
    }

    state = {
        report: this.props.report?this.props.report:{},
        
    };

    componentWillReceiveProps(nextProps) {   

        if (nextProps.report && nextProps.report.reportId){
            this.setState({report:nextProps.report}); 
        }
    }

    setCameraDuration(sec){
        this.setState({videoCameraDuration:sec})
    }
    setScreenDuration(sec){
        this.setState({videoScreenDuration:sec})
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        console.log(this.state.report)
        return (
            <Grid container style={{height:'80vh', backgroundColor:'#FAFAFB', borderTop:'1px solid #e8e8e8'}}>

                <Grid item xs={6} className={classes.formRow}>
                    <Paper elevation={0} className={classes.videoBox}>
                        <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Single - Camera')} 
                            {this.state.report.assessment && this.state.report.assessment.assessment_attributes.media?
                            <span style={{backgroundColor: '#efefef', padding:6, fontWeight:500, marginLeft:10, marginRight:20, borderRadius:5, fontSize:12}}><Moment format="mm:ss [min]">{this.state.videoCameraDuration*1000}</Moment></span> :''}
                        </Typography>
                    
                        <Grid container justify='center' style={{paddingTop:7}}>
                            <Grid xs={12} style={{padding:10, display:'flex', justifyContent:'center'}}>
                                {this.state.report.assessment && this.state.report.assessment.assessment_attributes.media?
                                <div style={{position:'relative'}} >
                                    <ReactPlayer url={this.state.report?this.state.report.encCameraUrl+'#t=2.0':''} controls={true} height='47vh' width='40vw' onDuration={this.setCameraDuration} style={{position:'relative'}} />
                                </div>:
                                <div>
                                    <img src={novideo} style={{width:60, margin:20}} alt="novideo"></img>
                                    <Typography variant='h4'>{t('Report - Video - Video is not available')}</Typography>
                                </div>}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={6} className={classes.formRow}>
                    <Paper elevation={0} className={classes.videoBox}>
                        <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Single - Screen')} 
                            {this.state.report.assessment && this.state.report.assessment.assessment_attributes.media?
                            <span style={{backgroundColor: '#efefef', padding:6, fontWeight:500, marginLeft:10, marginRight:20, borderRadius:5, fontSize:12}}><Moment format="mm:ss [min]">{this.state.videoScreenDuration*1000}</Moment></span> :''}
                        </Typography>
                    
                        <Grid container justify='center' style={{paddingTop:7}}>
                            <Grid xs={12} style={{padding:10, display:'flex', justifyContent:'center'}}>
                                {this.state.report.assessment && this.state.report.assessment.assessment_attributes.media?
                                <div style={{position:'relative'}} >
                                    <ReactPlayer url={this.state.report?this.state.report.encScreenUrl+'#t=2.0':''} controls={true} height='47vh' width='40vw' onDuration={this.setScreenDuration} style={{position:'relative'}} />
                                </div>:
                                <div>
                                <img src={novideo} style={{width:60, margin:20}} alt="novideo"></img>
                                <Typography variant='h4'>{t('Report - Video - Video is not available')}</Typography>
                            </div>}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                           
            </Grid>

    );
  }
}
SingleReportVideo.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportVideo),SingleReportVideo);