import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Tabs, Tab,  CssBaseline  } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import Moment from 'react-moment';
import EgButton from '../../components/EgButton';
import CloseOutlinedIcon from '@material-ui/icons//CloseOutlined';
import EgStatusAlert from '../../components/EgStatusAlert';
import SkeletonReport from '../../components/SkeletonReport';
import EmployeeCardSummary from './EmployeeCardSummary';
import EmployeeCardRecommendations from './EmployeeCardRecommendations';
import EmployeeCardAnalytics from './EmployeeCardAnalytics';
import SingleReport from '../reports/SingleReport';
import ExtendedNavBar from '../../components/ExtendedNavBar';
import RoleManager from '../../managers/RoleManager';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import SingleReportPersonality from '../reports/SingleReportPersonality';
import ReportsManager from '../../managers/ReportsManager';
import SingleReportRisk from '../reports/SingleReportRisk';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import LangPicker from '../../util/LangPicker';

const mapStateToProps = state => ({
    admin: state.admin.value
});

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    formRow:{
        paddingTop:'2vh',
        paddingInline:18,
        borderBottom:'#e8e8e8'
    },
  });
 
const AntTabs = withStyles((theme) => ({
    root: {
    },
    indicator: {
      backgroundColor: theme.palette.primary.dark,
    },
}))(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontSize:14,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.primary.dark,
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

const EMPLOYEE_SUMMARY = 0;
const EMPLOYEE_PERSONALITY = 3;
const EMPLOYEE_RISK = 4;
const EMPLOYEE_RECOMMENDATIONS = 2;
const EMPLOYEE_SAISFACTION = 1;


class EmployeeCard extends React.Component {
  
    constructor(props) {
        super(props);            
        this.handleChange = this.handleChange.bind(this);   
        this.handleStatusClose = this.handleStatusClose.bind(this)
        this.openReport = this.openReport.bind(this); 
        this.closeReport = this.closeReport.bind(this);
        this.refresh = this.refresh.bind(this)
    

        if (this.props.hrId){
            this.getEmployeeCard(this.props.hrId)
        }
    }

    state = {
        addOpen: this.props.addOpen,
        hrId: this.props.hrId,
        reportState: 0,
        moreMenuOpen: false,
        singleReportOpen:false
    };

    linkRef = React.createRef();

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.hrId !== this.props.hrId){
            this.setState({hrId:nextProps.hrId}); 
            this.getEmployeeCard(nextProps.hrId)
        }
    }
    componentDidMount(){
        console.log(RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_TEAM_ADMIN))
        if (this.props.admin && !RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_TEAM_ADMIN) ){
            this.setState({reportState: EMPLOYEE_RECOMMENDATIONS})
        }
        else{
            this.setState({reportState: EMPLOYEE_SUMMARY})
        }
    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }
    refresh(status, message){
        if (status === 'SUCCESS'){
            this.showStatusBar(message)
            this.getEmployeeCard(this.state.hrId)
        }
    }

    handleStatusClose(){
        this.setState({statusBar:null})
    }
   
    
    async getEmployeeCard(id){
        try{
            console.log(`Fetching employee card ${id}`)
            this.setState({loading:true})
            let response = await EgHttp.get('/employees/' + id + "/" + LangPicker.current())
            let empres = await response.json();
            if (response.status !== 200 || empres === null || empres === undefined || empres.length === 0) {
                console.error(`Error: No employee  with id ${id}`)
                return
            }
            this.setState({employee:empres})
            let responseparts
            if (empres.report && empres.report.length > 0){
                const initialReportInd = ReportsManager.findInitialReport(empres)
                const initialReport = empres.report[initialReportInd]
                this.setState({initialReport})
                responseparts = await EgHttp.get('/reports/parts/' + initialReport.id + '/' + LangPicker.current())
                let rowsparts = await responseparts.json();  
                this.setState({reportParts:rowsparts})
            }
            else {
                responseparts = []
            }
            this.setState({loading:false})
        }
        catch(err){
            console.error(err);
            return;
        }
        
    }

    openReport(type, id){
        this.setState({openReportId:id}, ()=> {
          this.setState({singleReportOpen:true})

        })
    }

    closeReport(){
      this.setState({singleReportOpen:false})
      this.setState({openReportId:null})
    }
   
    handleChange(event, val){
        this.setState({reportState:val})
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{overflow: 'hidden'}}>
                 <a ref={this.linkRef}/>
            {this.state.singleReportOpen?
            <div className={classes.root} >
                <CssBaseline />
                <ExtendedNavBar navPos={5}></ExtendedNavBar>
                <SingleReport openState={this.state.singleReportOpen} reportId={this.state.openReportId} refresh={this.refresh} close={this.closeReport}></SingleReport> 
            </div>
            :
            this.state.employee?

            <div style={{width:'100%', overflow:'hidden'}}>
                <Paper  elevation={0} style={{height: '100%'}}>

                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{ paddingTop:'4vh'}}>
                            
                            <Grid item xs={9} justify="left" style={{paddingInline:30}}>
                                <Typography variant={'h1'}> {this.state.employee.firstname + " " + this.state.employee.lastname} - {t('Employees - Table - Employee Card')}</Typography>
                            </Grid>
                            <Grid item xs={3} style={{textAlign:"end", paddingInlineEnd:20}} >
                                <EgButton secondary="true" className={classes.closeIcon} onClick={()=>{this.props.close()}}>
                                    <CloseOutlinedIcon style={{fontSize:16}}></CloseOutlinedIcon>
                                </EgButton>     
                            </Grid>
                            <Grid item xs={12} style={{paddingInline:30}}>
                                <Typography variant={'h4'} style={{textAlign:'start'}}> {this.state.employee.email} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;+{this.state.employee.phone}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{t("Candidates - Table - Position")}: { this.state.employee.position} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{t("Employee - Single - Start work date")}: <Moment format="MMM DD, YYYY">{ this.state.employee.start_work_date}</Moment> </Typography>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow}>
                                <AntTabs
                                    value={this.state.reportState}
                                    onChange={this.handleChange}
                                    aria-label="settings"
                                >   
                                    {!this.props.admin?'':RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_TEAM_ADMIN)?
                                    <AntTab  label={t('Reports - Single - Summary')} style={{padding:'1.5vh'}} />:<AntTab  style={{display:'none'}}/>}
                                     {!this.props.admin?'':RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_TEAM_ADMIN)?
                                    <AntTab   label={t('Employees - Single - Indexes')} />:<AntTab  style={{display:'none'}}/>}
                                    <AntTab  label={t('Reports - Single - Work Recommendations')}  />
                                    {!this.props.admin?'':RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_TEAM_ADMIN)?
                                    <AntTab   label={t('Employees - Single - Personality Traits')} />:<AntTab  style={{display:'none'}}/>}
                                    {(this.state.initialReport && (this.state.initialReport.assessment.type === AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT || this.state.initialReport.assessment.type === AssessmentTypes.INITIAL_RISK_ONLY_ASSESSMENT)) ||
                                    (!this.props.admin?'':RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_TEAM_ADMIN))?
                                    <AntTab  label={t('Employees - Single - Initial Risk')}/>:<AntTab  style={{display:'none'}}/>}
                                   
                                </AntTabs>
                            </Grid>
                            
                            {/*<EmployeeCardPersonality employee={this.state.employee} reportParts={this.state.reportParts}></EmployeeCardPersonality>*/}
                            {this.state.reportState === EMPLOYEE_SUMMARY?
                            <EmployeeCardSummary employee={this.state.employee} reportParts={this.state.reportParts} moveTo={this.handleChange} openReport={this.openReport} loading={this.loading} refresh={this.refresh}/>
                            : this.state.reportState === EMPLOYEE_PERSONALITY?
                            <SingleReportPersonality report={this.state.initialReport} reportParts={this.state.reportParts} traitAverages={this.props.traitAverages} moveTo={this.handleChange}/>
                            : this.state.reportState === EMPLOYEE_RISK?
                            <SingleReportRisk report={this.state.initialReport} reportParts={this.state.reportParts} ></SingleReportRisk>
                                
                            : this.state.reportState === EMPLOYEE_SAISFACTION?
                            <EmployeeCardAnalytics employee={this.state.employee} reportParts={this.state.reportParts}></EmployeeCardAnalytics>
                            : this.state.reportState === EMPLOYEE_RECOMMENDATIONS?
                            <EmployeeCardRecommendations employee={this.state.employee} reportParts={this.state.reportParts}></EmployeeCardRecommendations>
                            :''
                            }
                            
                        </Grid>
                </Paper>
            </div>
            :
            <div>
                <SkeletonReport style={{width:'100%'}}></SkeletonReport>
            </div>
            }
            {this.state.moreMenuOpen}   
            {this.state.emailInput}
            {this.state.statusBar}

        </div>     
      
    );
  }
}
EmployeeCard.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withStyles(styles)(EmployeeCard),EmployeeCard));
