import React from 'react';
import { connect } from "react-redux";
import { setAdminDetails } from "../redux/admin";
import PropTypes from 'prop-types';
import { ExportToCsv } from 'export-to-csv';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles  } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Topbar from '../components/Topbar'
import { Divider, FormControl, Grid, InputBase, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import EgProgress from '../components/EgProgress';
import EgButton from '../components/EgButton';
import { withRouter } from 'react-router-dom';
import ReportsTable from './reports/ReportsTable';
import SingleReport2 from './reports/SingleReport2';
import { withTranslation } from 'react-i18next';
import * as AppConstants from '../util/constants/AppConstants';
import EgStatusAlert from '../components/EgStatusAlert';
import EgHttp from '../util/EgHttp';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import NavigationManager from '../managers/NavigationManager';
import ExtendedNavBar from '../components/ExtendedNavBar';
import { AssessmentTypes } from '../util/constants/AssessmentTypes';
import qs from 'qs';
import LogRocket from 'logrocket';
import AddHumanResource2 from './human_resource/AddHumanResource2';
import EgCommonData from '../util/EgCommonData';
 
const drawerWidth = AppConstants.NavigationDrawerWidth;

const mapStateToProps = state => ({
  admin: state.admin.value,
  nav: state.nav.value
});

const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%',   
  },
  
  appRight:{
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      background:"#FAFAFA",
    },
    marginRight: drawerWidth
  },
  appLeft:{
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      background:"#FAFAFA",
    },
    marginLeft: drawerWidth
  },
 
  toolbar:{
    minHeigt:64,
    marginTop:50
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width:'100%',
    height:window.innerHeight,

  },
  actionBtns:{
    marginLeft:5,
    marginRight:5
  },
    
    latestNote:{
      marginTop:20, 
    },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginLeft:5
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E4E4E4',
    fontSize: 16,
    padding: '8px 26px 8px 12px',
    textAlign:'start',
   
    
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

class Reports extends React.Component {
    state = {
      open: this.props.open,
      drawerState: null,
      operationIcon: null,
      singleReportOpen: false,
      openReportId:null,
      rows:[],
      statusBar:null,
      loading:<EgProgress />,
      filterType:0,
      rejectedRows:null,
      filterTeam:0
    };

    constructor(props) {
        super(props);    
        
        if (!this.props.login.logged) {
          this.props.history.push('/signup') 
        }  
        else{
            NavigationManager.setLast(this.props, '/reports')
        }
        this.openReport = this.openReport.bind(this); 
        this.getReports = this.getReports.bind(this); 
        this.refresh = this.refresh.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this);
        this.search = this.search.bind(this);
        this.closeReport = this.closeReport.bind(this)
        this.getRejected = this.getRejected.bind(this)
        this.setRowsByType = this.setRowsByType.bind(this)
        this.getFilteredReportsByType = this.getFilteredReportsByType.bind(this)
        this.openEditCandidate = this.openEditCandidate.bind(this); 
        this.handleDrawerState = this.handleDrawerState.bind(this); 
        this.downloadActiveCandidates = this.downloadActiveCandidates.bind(this)
        this.getFilteredCandidatesByTeam = this.getFilteredCandidatesByTeam.bind(this)
        
        this.getTraitsAverages()
        this.getReports()
        this.getRejected()
    }

    async getReports(){
        try{
            this.setState({loading:<EgProgress />})
            let response = await EgHttp.get('/reports/all');
            if (response.status === 401) {
              this.props.history.push('/signin') 
              return;
            }
            let rowsres = await response.json();
            const origres = [...rowsres]
            this.setState({rows:rowsres, loading:null});
            this.setState({originalRows:origres});
            this.getAllReportTypes()
            this.initFilter()
            const teams = await EgCommonData.getTeams();
            const positions = await EgCommonData.getPositions();
            this.setState({teams: teams, positions:positions})
            return;
        }
        catch(err){
          console.error(err)
          return
        }
    }

    async downloadActiveCandidates() {
      const allowed  = this.props.admin.org.config && this.props.admin.org.config['allow-candidates-download'] === "true"?true:false
      if (!allowed) {
        LogRocket.error('Attempt to download canditates, but it is not allowed, aborting')
        return
      }
      try {
        LogRocket.info('Begin downloading list of active candidates')
        if (this.state.originalRows.length)
          this.setState({loading:<EgProgress />})
        const response = await EgHttp.get('/candidates/flat/candidates');
        if (response.status === 401) {
          this.props.history.push('/signin') 
          return;
        }
        const rowsres = await response.json();
        if (rowsres.length < 1) {
          LogRocket.info('There are no candidates to download')
          console.log("No candidates available, aborting download")
          this.setState({loading:null})
          return
        }

        const STATUS_LOOKUP = {
          0: "HR_STATUS_CANDIDATE_NEW",
          1: "HR_STATUS_CANDIDATE_ASSIGNED",
          2: "HR_STATUS_CANDIDATE_ASSESS_IN_PROGRESS",
          3: "HR_STATUS_CANDIDATE_ASSESS_ABORTED",
          4: "HR_STATUS_CANDIDATE_ASSESS_COMPLETED",
          5: "HR_STATUS_CANDIDATE_ASSESS_TIMED_OUT",
          6: "HR_STATUS_CANDIDATE_REJECTED",
          104: "HR_SATAUS_EMPLOYEE_INITIAL_COMPLETED"
        }
        const RISK_LOOKUP = {
          0: 'Reports - Low Risk',
          1: 'Reports - Moderate Risk',
          2: 'Reports - High Risk'
        }
        const { t } = this.props;
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: "candidates"
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const OVALL_RISK_KEY = 'overall_risk'
        const fixedData = rowsres.map((candi) => {
          const statusStr = `Candidates - ${STATUS_LOOKUP[candi['status']]}`
          candi['status'] = t(statusStr)
          if (candi[OVALL_RISK_KEY] !== null) {
            candi[OVALL_RISK_KEY] = t(RISK_LOOKUP[candi[OVALL_RISK_KEY]])
          } else {
            candi[OVALL_RISK_KEY] = '-'
          }
          return candi
        })
       
        const csvExporter = new ExportToCsv(options);
       
        csvExporter.generateCsv(fixedData);

        
        this.setState({loading: null})
        // this.setState({loading: null, download:<CSVDownload data={rowsres} headers={headers} target="candidates.csv" itarget="_blank" />})
      
      } catch (error) {
        LogRocket.error('Failure downloading active candidates')
        LogRocket.info(error)
      }
    }

    async getRejected() {
      console.log('Fetching REJECTED candidates')
      try{
        this.setState({loading:<EgProgress />})
        let response = await EgHttp.get('/reports/rejected');
        if (response.status === 401) {
          this.props.history.push('/signin') 
          return;
        }
        let rowsres = await response.json();
        console.log(`Updating REJECTED candidates with ${rowsres.length} poor guys.`)
        this.setState({rejectedRows:rowsres, loading:null});
        return;
      }
      catch(err){
        console.error(err)
        return
      }
    }

    initFilter(){
      const typeid=qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).type
      const teamid = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).team
      if (typeid){
        this.setState({ filterType: typeid});
        this.setRowsByType(typeid)
      }
      if (teamid){
       this.getFilteredCandidatesByTeam(teamid)
      }
    }

    openEditCandidate(id){
      this.setState({openCandId:id}, ()=> {
        this.setState({openAdd:true})
      })
    }
    handleDrawerState(sw){
      if (sw){
          this.setState({openAdd:!this.state.openAdd})
      }
      else{
          this.setState({openAdd:false})
      }
    }

    async getTraitsAverages(){
      
      try{
        let response = await EgHttp.get('/reports/traits/averages');
        if (response.status === 401) {
          this.props.history.push('/signin') 
          return;
        }
        let rowsres = await response.json();
        this.setState({traitAverages:rowsres});
        return;
      }
      catch(err){
        console.error(err)
        return
      }
    }
    getAllReportTypes(){
      const assessTypes = AssessmentTypes.get();
      const filteredTypes = assessTypes.filter(function(el){
        return !el.employeeOnly
      })
      
      this.setState({types:filteredTypes})
    }

    filterItems(arr, query) {
      return arr.filter(function(el) {
        return (el.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1) || (el.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      })
    }

    search(term){
      this.setState({searchTerm: term})
      const currRows = this.getFilteredReportsByType(this.state.filterType)
      if (term && term.length>0){
        let filteredRows;
        filteredRows = this.filterItems(currRows, term)

        this.setState({rows: filteredRows})
      }
      else{
        this.setState({rows: currRows})
      }
    }

    handleStatusClose(){
        this.setState({statusBar:null})
    }

    showStatusBar(message){
      this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }

    refresh(status, message){
        this.getReports()
        if (status === 'SUCCESS'){
            this.showStatusBar(message)
        }
    }

    openReport(id, lastPage){
        this.setState({openReportId:id}, ()=> {
          this.setState({singleReportOpen:true})
        })
        this.setState({lastPage: lastPage})
    }

    closeReport(pageNum){
      this.setState({singleReportOpen:false})
      this.setState({openReportId:null})
      this.getReports()
    }

    filterTypes = event => {
      this.setState({ filterType: event.target.value });
      const searchFld = document.getElementById('search');
      searchFld.value = ""
      if (event.target.value === 0){
          this.setState({rows: this.state.originalRows})
          this.props.history.push('/reports')
      }
      else{
        this.setRowsByType(event.target.value)
        this.props.history.push('/reports?type='+event.target.value)
      }
  };

  setRowsByType(typeId) {
    const rowsByType = this.getFilteredReportsByType(typeId)
    this.setState({rows: rowsByType})
  }

  getFilteredReportsByType(typeId){
    let filtered = []
    if (typeId === -1) {
      filtered = this.state.rejectedRows
    } else if (typeId === 0) {
      filtered = this.state.originalRows
    } else {
      const allReports = this.state.originalRows
      filtered = allReports.filter(function(el) {
         return el.assessment_type === parseInt(typeId)
      })
    }

    return filtered
}
filterTeams = event => {
  this.setState({ filterTeam: event.target.value });
  if (event.target.value === 0){
      this.setState({rows: this.state.originalRows})
      this.props.history.push('/reports')
  }
  else{
    this.getFilteredCandidatesByTeam(event.target.value)
    this.props.history.push('/reports?team='+event.target.value)
  }
};
getFilteredCandidatesByTeam(teamId){
  const allEmps = this.state.originalRows
  const allTeams = this.state.teams
  const filtered = allEmps.filter(function(el) {
      const teamObjInd = allTeams.findIndex(({ id }) => id === parseInt(teamId))
      const teamObj = allTeams[teamObjInd]
      const memberIndex = teamObj.team_members.findIndex(({ hr_id }) => hr_id === el.hr_id)
      if (memberIndex > -1) return el;
      else return null
  })
  this.setState({rows: filtered})
}

render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        
    return (
        this.state.singleReportOpen?
        <div className={classes.root} >
          <CssBaseline />
          <ExtendedNavBar navPos={5}></ExtendedNavBar>
          <SingleReport2 openState={this.state.singleReportOpen} reportId={this.state.openReportId} refresh={this.refresh} close={this.closeReport} traitAverages={this.state.traitAverages} />
        </div>
        :
        <div className={classes.root} >
          <CssBaseline />
          <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} style={{boxShadow:"0px 0px 0px" }}>
              {this.state.loading}
              {this.state.download}
              <Toolbar >
                  <Topbar login={this.props.login} page={this.props.page} search={this.search}></Topbar>
              </Toolbar>
          </AppBar>

          <ExtendedNavBar navPos={5}></ExtendedNavBar>

          <main className={classes.content}>
              <div className={classes.toolbar} />
            
              <Grid container fullwidth='true' direction="row" alignItems="flex-start">
            
              <Grid item xs={12 }>
                
                <Grid container  direction="row" alignItems="flex-start">
                    <Grid item xs={6} >
                      <Typography variant="h1"> {t('Reports - Results')} </Typography>
                    </Grid>
                    <Grid item xs={6}  style={{textAlign:"end", display:'flex', justifyContent:'end'}} >
                           <FormControl style={{width:300, paddingTop:4}}>
                                <InputLabel id="teamsLbl"></InputLabel>
                                      <Select variant="outlined" labelId="teamsLbl" id="filterTeam" name="filterTeam" value={this.state.filterTeam} onChange={this.filterTeams} className={classes.selectBox} input={<BootstrapInput />}>
                                          <MenuItem value={0}>{t('Candidates - All Candidates')}</MenuItem>
                                          {this.state.teams? this.state.teams.map(function(t) {
                                              return <MenuItem value={t.id}>{t.name}</MenuItem>
                                          }):null}       
                                      </Select>
                          </FormControl>
                          <FormControl style={{width:300, paddingTop:4}}>
                              <InputLabel id="atypesLbl"></InputLabel>
                                    <Select variant="outlined" labelId="atypesLbl" id="filterType" name="filterType" value={this.state.filterType} onChange={this.filterTypes} className={classes.selectBox} input={<BootstrapInput />}>
                                        <MenuItem value={0}>{t('Reports - All Assessment Types')}</MenuItem>
                                        <Divider />
                                        {this.state.types? this.state.types.map(function(t) {
                                            return (
                                             t.id>0?
                                            <MenuItem value={t.id}>{t.name}</MenuItem>:null
                                            )
                                        }):null}
                                        <Divider />
                                        {this.state.types?
                                        <MenuItem value={-1}>{this.state.types[this.state.types.length-1].name}</MenuItem>:null}
                                    </Select>
                          </FormControl>
                          {
                            this.props.admin.org && this.props.admin.org.config && this.props.admin.org.config["allow-candidates-download"] === "true" ?
                              <EgButton  onClick={this.downloadActiveCandidates} className={classes.actionBtns}>{t('Candidates - Export')}</EgButton>
                              :
                              null
                          }
                      </Grid>
                  
                    <Grid item xs={12} className={classes.latestNote}>
                          <ReportsTable noteTitle="Candidate List" login={this.props.login} openReport={this.openReport}  openCandidate={this.openEditCandidate} rows={this.state.rows} refresh={this.refresh} loading={this.state.loading?true:false} filter={this.state.filterType} lastPage={this.state.lastPage} getRejected={this.getRejected}> </ReportsTable>
                    </Grid>
                    
                </Grid>
              </Grid>
              </Grid>
          </main>
          <AddHumanResource2 conetxt='candidate' handleDrawerState={this.handleDrawerState} openState={this.state.openAdd} hrId={this.state.openCandId} refresh={this.refresh} teams={this.state.teams} positions={this.state.positions} />
          {this.state.statusBar}
          </div>
    
    );
}}

Reports.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

// export default withTranslation()(withCookies(withRouter((withStyles(styles))(Reports),Reports)))
export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withCookies(withRouter((withStyles(styles))(Reports),Reports))))
