import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles  } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%'
    },
    radioWrap:{
        border:'1px solid #E4E4E4',
        borderRadius:4,
        minWidth:300,
        padding:2,
        margin:5,
        "&:hover":{
            backgroundColor:"#E9FCF1"
        },         
    },
    radioWrapChecked:{
        border:'1px solid #00AC83',
        borderRadius:4,
        minWidth:300,
        padding:2,
        margin:5,
        color:'#ffffff',
        fontWeight:500,
        backgroundColor:'#11D6A7'
    }   
})


const GreenCheckbox = withStyles({
    root: {
      color: '#C2C3C6',
      '&$checked': {
        color: '#ffffff',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  
  class AnswerChecks extends React.Component {
  
    state = {
        selected: null,
        availAnswers:null,
        selectionArr:[]
    };

    constructor(props) {
        super(props);  
        console.log("Answer Checkboxes");
        this.optionSelected = this.optionSelected.bind(this); 
        
        console.log(this.props.availAnswers)
        this.setState({availAnswers: this.props.availAnswers})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.availAnswers!==this.state.availAnswers ){             
            this.setState({availAnswers:nextProps.availAnswers});       
        }  
    }

    optionSelected(event){
        
        this.setState({selected:event.target.value});
        let selArr = this.state.selectionArr;
        let newSelArr = new Array(event.target.value)
        selArr = _.xor(selArr, newSelArr);
        this.setState({selectionArr:selArr})
        this.props.setSelected(event.target.value, selArr);
        console.log(selArr);
    }

    clearSelection(){
        this.setState({selectionArr:[]});
    }
    
    isInCheckList(index){
        const res = this.state.selectionArr.find(el => el === index)
        return res;
    }
    setSelection(sel){
        this.setState({selectionArr: sel.toString().split(',')});
    }


    render(){

        const { classes } = this.props;
        return (
            <FormControl component="fieldset" >
            <FormLabel component="legend" ></FormLabel>
            <FormGroup  aria-label="position" name="position" defaultValue="top" onChange={this.optionSelected} style={{justifyContent:'center'}}>
                {this.props.availAnswers?
               
                    this.props.availAnswers.map((answer, index) => (
                        <FormControlLabel
                        value={(index+1)}
                        control={<GreenCheckbox  checked={this.state.selectionArr.indexOf((index+1).toString())>-1}/>}
                        label={answer.text}
                        labelPlacement="right"
                        className={this.state.selectionArr.indexOf((index+1).toString())>-1?classes.radioWrapChecked:classes.radioWrap}
                        />
                    ))
                
                :''
            }
            </FormGroup>
            </FormControl>
            );
        }
    }

    
AnswerChecks.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(AnswerChecks);