import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Divider, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../../redux/admin";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next';

import env from "react-dotenv";
import LangPicker from '../../util/LangPicker'; 
import EgButton from '../../components/EgButton';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding:0,
    },
    signupLink:{
        textDecoration:"none",
        color: theme.palette.text.white,
    },
    emptyStateImg:{
        width:250,
        opacity:0.5
    },
    actionButton:{
        width: 100,
        margin: 5
    },
    actionButtonMini:{
        marginBottom:3,
        paddingTop:2,
        paddingBottom:2,
        fontSize: 10,
        marginInlineStart:'1vw'
    },
    tableLeftRow:{
        display:'flex', 
        alignItems:'center', 
        padding:15, 
        borderTop:'1px solid #efefef', 
        borderRight:'1px solid #efefef', 
        justifyContent:'right',
    },
    tableRightRow:{
        display:'flex', 
        alignItems:'center', 
        padding:15, 
        borderTop:'1px solid #efefef', 
        justifyContent:'left', 
    },
    postit:{
        backgroundColor: theme.palette.primary.light,
        display:'flex',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center',
    },
    actionButtonEmpty:{
        paddingInline:10
    },
    selectBox:{
        '& .MuiOutlinedInput-input':{
            fontSize:14,
            paddingTop:12,
            paddingBottom:12
        }
    }
})


class AdditionalTestDialog extends React.Component {
  
    state = {
        open: this.props.open,
        human_resource:this.props.human_resource,
        assessLang:LangPicker.current(),
        actionBtnDisabled: false,
        subjectsToAdd:[]
    };

    constructor(props) {
        super(props);          
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 
    }

    componentDidMount(){

        this.setState({human_resource:this.props.human_resource})

    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.human_resource!==this.state.human_resource ){             
            this.setState({human_resource:nextProps.human_resource});  
        }
    }

    close(){
        this.handleClose();
    }
    
    
    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };
    
    
    render() {
        console.log(this.props.report)
        const { classes } = this.props;
        const { t } = this.props;
        const weakThis = this;
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

                maxWidth='800px'
            >
                <DialogContent style={{padding:0,width:1000, maxWidth:800, overflowX:'hidden'}}>
                    {this.state.loading}
                   
                   <Paper elevation={0} style={{width:1000, maxWidth:800, }} >
                       <Grid container  >
                             <Grid item xs={11} style={{paddingTop:20, paddingBottom:15, paddingInlineStart:20}}>
                               <Typography variant={'h1'} >{t('Reports - Test Answers')}</Typography>
                            </Grid>
                            <Grid item xs={1} style={{paddingTop:10, paddingBottom:15, paddingInlineStart:20}}>
                                <IconButton aria-label="close" onClick={this.close} size="small">
                                        <CloseIcon></CloseIcon>
                                </IconButton>
                            </Grid>
                            {
                            this.props.report.assessment.response.map((quest) => (
                                    quest.weighted_score[0].trait_id===this.props.score.trait_id?
                                    <Grid item xs={12}>
                                        <Grid container style={{paddingLeft:20, paddingRight:20, paddingTop:15, paddingBottom:15}}>
                                            <Grid item xs={8} style={{textAlign:'start', }}><Typography variant='h4'><span style={{fontSize:14, fontWeight:600}}>{t('Reports - Summary - Q')}</span>&nbsp;{LangPicker.current() === 'en' || !quest.question.question_localized[0]?quest.question.text:quest.question.question_localized[0].text }</Typography></Grid>
                                            <Grid item xs={4} style={{textAlign:'start', paddingInline:20}}><Typography variant='h4'><span style={{fontSize:14, fontWeight:600}}>{t('Reports - Summary - A')}</span>&nbsp;{LangPicker.current() === 'en' || !quest.question.answer_localized[0]?quest.question.answer.answer_list[quest.selected_answers[0]-1].text:quest.question.answer_localized[0].answer_list[quest.selected_answers[0]-1].text}</Typography></Grid>   
                                        </Grid>
                                        <Divider></Divider>
                                    </Grid>
                                    :''
                                ))
                            }
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30, paddingBottom:30}} >
                                    <EgButton  secondary="true" outlined="true"  className={classes.actionButton} onClick={this.close}>{t('Reports - Single - Close')}</EgButton>   
                                </Grid>
                       </Grid>
                   </Paper>
                
                
                </DialogContent>
                
                
            </Dialog>
            </div>
        );
    }
}

AdditionalTestDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(AdditionalTestDialog),AdditionalTestDialog)))