import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
//import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import LangPicker from '../../util/LangPicker';
import RoleManager from '../../managers/RoleManager';
import * as EmailValidator from 'email-validator';
 
const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    txtFieldName:{
        fontSize: 14,
        fontWeight:500,
        color:"#7F7F7F"
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width:100,
        marginLeft:5,
        marginRight:5
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
    
  });
  
class AddAccount extends React.Component {
  
    constructor(props) {
        super(props);            
        this.closeDrawer = this.closeDrawer.bind(this);  
        this.openImport = this.openImport.bind(this);  
        this.saveAccount = this.saveAccount.bind(this);  
        this.validateEmail = this.validateEmail.bind(this);  
        this.validatefName= this.validatefName.bind(this);  
        this.validatelName= this.validatelName.bind(this);  
        this.validateAll= this.validateAll.bind(this);  
    }

    state = {
        addOpen: this.props.addOpen,
        candidateId: this.props.accountId,
        roles: RoleManager.getRoles(),
        lnameVal:true,
        fnameVal:true,
        emailVal:true
    };

    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){             
            this.setState({addOpen:nextProps.openState}); 
            this.setState({firstName:null, lastName: null, email: null,  role: null, accountId:null})  
                
        }
        if (nextProps.accountId){
            this.setState({accountId:nextProps.accountId});  
            this.getAccount(nextProps.accountId)
            
        }
    }

    async getAccount(id){
        
        let response = await EgHttp.get('/accounts/' + id)
        let rowsres = await response.json();
        this.setState({firstName:rowsres.firstname, lastName: rowsres.lastname, email: rowsres.email,  selectedRole: rowsres.role});
        return;

    }

  
    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    changeAccount = event => {
        this.setState({selectedRole:event.target.value})
    }

    handleDrawerState(sw){
        if (sw){
            this.setState({openAdd:!this.state.openAdd, openImport: !this.state.openImport})
        }
        else{
            this.setState({openAdd:false, openImport: false})
        }
    }
    
    closeDrawer(){
        this.setState({firstName:null, lastName: null, email: null,  role: RoleManager.FULL_SYSTETM_ADMIN, accountId:null})
        this.props.handleDrawerState()
    }

    openImport(){
        this.setState({addOpen:false})
        this.props.handleDrawerState(true)
    }
    validateEmail(event){
        this.setState({emailVal:EmailValidator.validate(event.target.value.trim())})
    }
    validatefName(event){
        this.setState({fnameVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
    validatelName(event){
        this.setState({lnameVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
    validateAll(){
        let failed = false;
        if (!EmailValidator.validate(this.state.email.trim())){ 
            this.setState({emailVal:false})
            failed=true;
        }
        if (!this.state.firstName.trim() || this.state.firstName.trim().length<2) {
            this.setState({fnameVal:false})
            failed=true;
        }
        if (!this.state.lastName.trim() || this.state.lastName.trim().length<2) {
            this.setState({lnameVal:false})
            failed=true;
        }
        
        if (failed) return false;
        return true;
    }

    async saveAccount(){
        if (!this.validateAll()){
            return;
        }
        const { t } = this.props;
        //let rowsres;
        let body = JSON.stringify({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            role:  this.state.selectedRole,
            id:this.state.accountId,
            language: LangPicker.current(),
            orgId: this.props.admin.org.id})

        if (this.state.accountId){
            await EgHttp.put('/accounts',body);
        }
        else{
            await EgHttp.post('/accounts',body);
            //let rowsres = await response.json();
        }

        //EventLog.insert(3, this.state.candidateId?this.state.candidateId:rowsres.id, this.state.candidateId?EventLog.EDIT_CANDIDATE:EventLog.INSERT_CANDIDATE, 0)
       
        this.closeDrawer();
        this.props.refresh("SUCCESS", this.state.accountId?t('Settings - Accounts - Account details updated successfully'):t('Settings - Accounts - New account added successfully'));
        return;
    }

    
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>

            <React.Fragment key={i18n.dir()==='rtl'?'left':'right'}>
           
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:600}}>
            <div style={{width:600}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={12} justify="left">
                                <Typography variant={'h1'}>{!this.state.accountId?t('Settings - Accounts - Add New Account'):t('Settings - Accounts - Edit Account')}  </Typography>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:30}}>
                                <Typography variant={'h5'}>{t('Settings - Accounts - First Name')}</Typography>
                                <TextField autoComplete="firstname" name="FirstName" variant="outlined" required fullWidth='true' id="firstName" label="" value={this.state.firstName} onChange={this.handleChange} className={classes.signupTxt} onBlur={this.validatefName}
                                    error={this.state.fnameVal?false:true}
                                    helperText={this.state.fnameVal?"":t('Candidates - Add - Please enter a valid first name')}
                                    InputProps={{
                                        classes: { input: classes.inputLabel }, 
                                    }}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Settings - Accounts - Last Name')}</Typography>
                                <TextField autoComplete="lastname" name="LastName" variant="outlined" required fullWidth='true' id="lastName" label="" value={this.state.lastName} onChange={this.handleChange} className={classes.signupTxt} onBlur={this.validatelName}
                                    error={this.state.lnameVal?false:true}
                                    helperText={this.state.lnameVal?"":t('Candidates - Add - Please enter a valid last name')}
                                >
                                    
                                </TextField>
                            </Grid>
                            <Grid item xs={12} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Settings - Accounts - Email')}</Typography>
                                <TextField autoComplete="email" name="Email" variant="outlined" required fullWidth='true' id="email" label="" value={this.state.email} onChange={this.handleChange} className={classes.signupTxt} onBlur={this.validateEmail}
                                    error={this.state.emailVal?false:true}
                                    helperText={this.state.emailVal?"":t('Candidates - Add - Please enter a valid Email')}  >
                                    
                                </TextField>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Settings - Accounts - Role')}</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="selectedRoleInp"></InputLabel>
                                    <Select variant="outlined"
                                        labelId="role-label"
                                        id="selectedRole"
                                        name="selectedRole"
                                        value={this.state.selectedRole?this.state.selectedRole:0}
                                        onChange={this.changeAccount}
                                        className={classes.selectBox}
                                        
                                    >
                                       {this.state.roles?
                                        this.state.roles.map(function(role) {
                                            return <MenuItem value={role.id} >{role.text}</MenuItem>
                                        }):''
                                        }    
                                      
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton  onClick={this.saveAccount} className={classes.actionButton}>{t('Settings - Accounts - Save')}</EgButton>
                                <EgButton secondary="true" outlined="true" onClick={this.closeDrawer} className={classes.actionButton}>{t('Settings - Accounts - Cancel')}</EgButton>   
                            </Grid>
                            
                        </Grid>
                </Paper>
            </div>
            </Drawer>
            </React.Fragment>

        </div>     
      
    );
  }
}
AddAccount.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AddAccount),AddAccount)))