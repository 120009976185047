import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import Skeleton from '@material-ui/lab/Skeleton'
import SkeletonTable from '../../components/SkeletonTable';
import EgEmptyState from '../../components/EgEmptyState';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import EgEngagementPatch from '../../components/EgEngagementPatch';
import OngoingReportDialog from './OngoingReportDialog';
import OngoingRecommendationsDialog from './OngoingRecommendationsDialog';
import AppUtils from '../../util/AppUtils';


const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      width:'100%',
      minHeight:'25vh',
      overflow:'auto'
    },
    tableCell:{
        fontSize: 12,
        fontWeight:400,
        color:"#272931",
        border:0,
        paddingLeft:10,
        paddingRight:10,
        textAlign:'start',
        borderRight:'1px solid #F0F0F0',
        overflowWrap:'anywhere',
        padding:5
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'1px solid #F0F0F0',
        
    },
    tableHeadCell:{
        paddingTop:5,
        paddingBottom:5,
        fontSize: 11,
        fontWeight:400,
        color:"#5F6066",
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0'
    },
    tableRowInner:{
        minHeight:"6vh",
        maxHeight:"6vh",
        height:"6vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
    overlay:{
        display:"block", 
        position:'absolute',
        top:0, 
        left:0, 
        zIndex:1000, 
        background:"#f0f0f066",
        height:'100%',
        width:'100%',
    },
   
    statusLabel:{
        border: "solid 1px",
        padding: 5,
        textAlign:'center',
        fontWeight:500,
        fontSize:12,
        color:theme.palette.text.white,
    },
  });
 

class EngagementHistoryTable extends React.Component {
  
    state = {
        open: this.props.open,
        isEmpty: false,
        rows: this.props.rows,
        loading:true,
        searchTerm:this.props.searchTerm,
        order:'desc',
        orderBy:'created_at',
        reportDialog:null
    };

    constructor(props) {
        super(props);  
        this.refresh = this.refresh.bind(this);    
        this.closeReport = this.closeReport.bind(this) 
        this.closeRecommendations = this.closeRecommendations.bind(this)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.employee && nextProps.employee!==this.state.employee ){             
            this.setState({employee:nextProps.employee, loadiing:false});
        }
    }
    componentDidMount(){  
        if (this.props.employee){
            this.setState({employee: this.props.employee, loading:false})
        }
    }
    

    refresh(code, msg){
        this.props.refresh(code, msg);
    }

    getEngagementScoresIndex(scores){
        const ind = scores.findIndex(({trait}) => trait.name==='Engagement')
        return ind
    }

    openReport(report){
        this.setState({reportDialog: <OngoingReportDialog report={report} open={true} style={{width:'40vh'}} close={this.closeReport}/>})
    }
    openReccomandations(assess, report){
        this.setState({recommendationsDialog: <OngoingRecommendationsDialog report={assess} assess={assess} open={true} style={{width:'40vh'}} close={this.closeRecommendations}/>})
    }
   
    closeReport(){
        this.setState({reportDialog:null});
    }
    closeRecommendations(){
        this.setState({recommendationsDialog:null});
    }

    sendReminder(assessment){
        this.props.remind(assessment)
    }

    createSortHandler = property => event => {
        this.onRequestSort(event, property);
    }

    onRequestSort = (event, property) => {
        
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }    
        this.setState({ order, orderBy });       
    }

    
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
        <div style={{position:'relative', width:'100%'}}>

        <Grid container spacing={3} style={{}}>
            
            <Grid item xs={12 } style={{height:'100%'}}>
                
                {this.state.loading || (this.state.employee && this.state.employee.assessment && this.state.employee.assessment.length>1)?
                <div>

                <Paper className={classes.paper} elevation={0} style={{height:'35vh'}}>
                    
                <Table className={classes.table} aria-label="simple table" >
                    <TableHead>
                        <TableRow> 
                            <TableCell key={'created_at'} sortDirection={this.state.orderBy === 'created_at' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'20%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('created_at')} active={this.state.orderBy === 'created_at'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Employee - Table - Survey Assigned Date')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'completed_at'} sortDirection={this.state.orderBy === 'completed_at' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'20%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('completed_at')} active={this.state.orderBy === 'completed_at'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Employee - Table - Survey Complete Date')}
                                </TableSortLabel>
                            </TableCell>
                            
                            <TableCell key={'Engagement Level'} className={classes.tableHeadCell} align="start" style={{width:'20%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('position')} active={this.state.orderBy === 'position'}>    
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Employee - Table - Engagement Level')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'actions'}  align="left" style={{width:'40%'}}  className={classes.tableHeadCell}>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody >
                    {
                    this.state.loading?
                    <TableRow key={'skeleton1'} className={classes.tableRowBody} >
                        <TableCell className={classes.tableInnerCell} colSpan={6}>
                            <SkeletonTable style={{width:'100%'}}></SkeletonTable>
                        </TableCell>
                    </TableRow>
                    :
                        this.state.employee.assessment.sort(AppUtils.getSorting(this.state.order, this.state.orderBy)).map((row, index) => (
                        row.type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW?
                        <TableRow key={row.id} className={classes.tableRowBody} >
                            <TableCell className={classes.tableInnerCell} colSpan={6} > 
                                <div>
                                    <Table className={classes.tableInner} aria-label="inner table" >
                                    <TableBody >
                                        <TableRow key={row.name + "in"} className={classes.tableRowInner} >
                                            
                                            <TableCell className={classes.tableCell} style={{width:'20%'}}> {row.created_at?<Moment format="MMM DD, YYYY">{row.created_at}</Moment>:''}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'20%'}}>{row.completed_at?<Moment format="MMM DD, YYYY">{row.completed_at}</Moment>:<i>{t("Employee - Single - Not Completed")}</i>}</TableCell>
                                            
                                            <TableCell className={classes.tableCell} align="left" style={{width:'20%'}}>
                                                {this.getEngagementScoresIndex(row.assessment_scores) > -1?<EgEngagementPatch value={row.assessment_scores[this.getEngagementScoresIndex(row.assessment_scores)].trait_adjusted_score} boundaries={row.assessment_scores[this.getEngagementScoresIndex(row.assessment_scores)].trait.boundaries} />:''}
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'40%',borderRight:0, textAlign:'end'}}>
                                                {row.completed_at?
                                                    <div>
                                                        <EgButton secondary="true" small onClick={()=>{this.openReport(row)}}>{t("Employee - Table - Open Report")}</EgButton> | 
                                                        <EgButton secondary="true" small onClick={()=>{this.openReccomandations(row)}}>{t("Employee - Table - Work Recommendations")}</EgButton>
                                                    </div>:
                                                    <div>
                                                        <EgButton secondary="true" small onClick={()=>{this.sendReminder(row)}}>{t("Assigned - Reminder - Send Reminder")}</EgButton>
                                                    </div>}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody >
                                    </Table>
                                   
                                </div>
                            </TableCell>
                        </TableRow>  :''
                    ))}
                    </TableBody>
                </Table>
                
                </Paper>
                {this.state.reportDialog}
                {this.state.recommendationsDialog}
                </div>
                :
                <div style={{paddingTop:'4vh'}}>
                    <EgEmptyState title={t('Employee - Summary - No Engagement Survey Historty')} size={'small'} />
                </div>
                }
            </Grid>  
        </Grid>
        {this.state.assignDialog}
        </div>      
      
        );
    }
}
EngagementHistoryTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(EngagementHistoryTable),EngagementHistoryTable)))