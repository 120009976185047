import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { Snackbar } from '@material-ui/core';

const styles = theme => ({
    root: {
      display: 'flex',
    },
    
    
})
const EgStatusAlertStyled = withStyles((theme) => ({
    root: {
        background:theme.palette.primary.dark,
        fontSize: 14,
        color:"#ffffff",
        paddingLeft:50, 
        paddingRight:50,
        direction:'ltr'
    },
  }))(MuiAlert);

  class EgStatusAlert extends React.Component {
  
    state = {
        value:0
    };

    render(){

        //const { classes } = this.props;

        return (
            <div>
                <Snackbar open={true} autoHideDuration={5000} {...this.props} >
                    <EgStatusAlertStyled  {...this.props} icon={<CheckCircleOutlineOutlinedIcon style={{color:'#ffffff'}}></CheckCircleOutlineOutlinedIcon>}>
                        {this.props.message}
                    </EgStatusAlertStyled>
                </Snackbar>
            </div>
            );
        }
    }

    
    EgStatusAlert.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(EgStatusAlert);