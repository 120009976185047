import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import CloseOutlinedIcon from '@material-ui/icons//CloseOutlined';
import EgStatusAlert from '../../components/EgStatusAlert';
import PredefinedModelTable from './PredefinedModelTable';
import AddPredefinedModel from './AddPredefinedModel';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    formRow:{
        paddingTop:'2vh',
        paddingInline:18,
        borderBottom:'#e8e8e8'
    },
    
    closeIcon:{
        marginTop:-10
    }
    
  });
 
class PredefinedModels extends React.Component {
  
    constructor(props) {
        super(props);            
        this.handleStatusClose = this.handleStatusClose.bind(this)
        this.openAddModel = this.openAddModel.bind(this)
        this.openEditModel = this.openEditModel.bind(this)
        this.refresh = this.refresh.bind(this)
        this.handleDrSt = this.handleDrSt.bind(this)
    }

    state = {
        addOpen: this.props.addOpen,
    };

    linkRef = React.createRef();

    componentWillReceiveProps(nextProps) {   
    }

    componentDidMount(){
        this.getPredefinedModels()
    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }
   
    refresh(status, message){
        this.getPredefinedModels()
        if (status === 'SUCCESS'){
            this.showStatusBar(message)
        }
    }

    handleStatusClose(){
        this.setState({statusBar:null})
    }

    openAddModel(){
        this.setState({openModeld:null}, ()=> {
          this.setState({openAdd:true})
        })
    }
    openEditModel(id){
        this.setState({openModeld:id}, ()=> {
          this.setState({openAdd:true})
        })
      }

    handleDrSt(val){
        this.setState({openAdd:val})
    }

    async getPredefinedModels(){
        try{ 
            this.setState({loading:true})
            let response = await EgHttp.get('/lookalike/predefmodels')
            let modelsRes = await response.json();
            if (response.status !== 200 || modelsRes === null || modelsRes === undefined || modelsRes.length === 0) {
                console.error(`Error: No models found`)
                this.setState({models:[], loading:false})
                return
            }
            this.setState({models:modelsRes, loading:false})
        }
        catch(err){
            console.error(err);
            return;
        }
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{overflow: 'hidden',width:'100%'}}>
            <a ref={this.linkRef}/>
            <div style={{width:'100%', overflow:'hidden'}}>
                <Paper  elevation={0} style={{height: '100%'}}>
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{ paddingTop:'2vh'}}>
                            <Grid item xs={9} justify="left" style={{paddingInline:30, paddingTop:'2vh'}}>
                                <Typography variant={'h1'}>  {t('Lookalike - Predefined Models')}  </Typography>
                            </Grid>
                            <Grid item xs={3} style={{textAlign:"end", paddingInlineEnd:20}} >
                                <EgButton secondary="true" className={classes.closeIcon} onClick={()=>{this.props.close()}}>
                                    <CloseOutlinedIcon style={{fontSize:16}}></CloseOutlinedIcon>
                                </EgButton>     
                            </Grid>
                            <Grid item xs={9} className={classes.formRow} style={{paddingInline:30, paddingTop:'1vh'}}>
                               
                            </Grid>
                            <Grid item xs={3} justify="right" style={{textAlign:"end", paddingInlineEnd:40}} >
                                <EgButton  onClick={this.openAddModel} className={classes.actionBtns}>{t('Lookalike - New Model')}</EgButton>
                            </Grid>
                            <Grid item xs={12}  style={{paddingBottom:'1vh'}} />
                                <PredefinedModelTable models={this.state.models}  editPredefinedModel={this.openEditModel} addPredefinedModel={this.openAddModel} refresh={this.refresh}></PredefinedModelTable>
                            </Grid>
                </Paper>
                <AddPredefinedModel handleDrawerState={this.handleDrSt}  openState={this.state.openAdd} refresh={this.refresh}></AddPredefinedModel> 
            </div>
           
            {this.state.moreMenuOpen}   
            {this.state.statusBar}
                       
        </div>     
    )
  }
}
PredefinedModels.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(PredefinedModels),PredefinedModels);
