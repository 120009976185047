import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import general_insights from '../../img/general_insights_full.svg';
import ReportsManager from '../../managers/ReportsManager';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        paddingTop:'1.5vh',
        border:0,
        backgroundColor:'#FAFAFB'
    },
    
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:20
    },
    boxTitle:{
        paddingTop:'2vh',
        paddingBottom:'2vh',
        paddingInline:20,
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
  });

class AdditionalPersonalDetails extends React.Component {
  
    /*
    constructor(props) {
        super(props);           
    }*/

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        recommSummaryArr:[],
        invalidReport:false
        
    };

    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
    }
    
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }
    intializeData(report, reportParts){
        this.setState({report})
        this.setState({reportParts})
        if (!report) return
        const recArr = ReportsManager.setRecommendationsSummary(report, reportParts)
        this.setState({recommSummaryArr: recArr})    
        if (report.dominant_traits && report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Single - Additional Personal Details')}</Typography>
                    
                    <Grid container style={{marginTop:0}}>
                        <Grid xs={8}> 
                            <Grid container>
                                <Grid xs={12} style={{paddingInlineStart:20,   paddingTop:'2vh'}}>
                                    <Typography variant={'h2'} >{t('Reports - Single - Additional personal petails for')}&nbsp; {this.state.report.human_resource?this.state.report.human_resource.firstname:''}</Typography>
                                </Grid>
                                
                                <Grid xs={12} style={{paddingTop:30}}>
                                <Grid container>
                                
                                    {this.state.report && this.state.report.human_resource && this.state.report.human_resource.custom_properties && this.state.report.human_resource.custom_properties.hr_managed_properties? this.state.report.human_resource.custom_properties.hr_managed_properties.map(function(field) {
                                        return (
                                            <Grid xs={6} style={{paddingInlineStart:30,paddingTop:10}}>
                                                <Grid container>
                                                    <Grid item xs={4}  >
                                                        <Typography variant={'h4'} style={{textAlign:'start', fontWeight:600, fontSize:16}}>{field.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}  >
                                                        <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, fontSize:16}}>{field.value}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            )
                                            }):null
                                    }  
                                </Grid>   
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={1}> </Grid>
                        <Grid xs={3}> 
                            <img src={general_insights}  alt="overall" style={{width:'60%', maxHeight:'67vh', paddingTop:20}}/>
                        </Grid>
                    </Grid>
                   
                    
                </Paper>
            </Grid>
           </div>
    );
  }
}
AdditionalPersonalDetails.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(AdditionalPersonalDetails),AdditionalPersonalDetails);