import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import SingleReportSummaryVideoBox from './SingleReportSummaryVideoBox';
import LangPicker from '../../util/LangPicker';
import SingleReportPersonalityBox from './SingleReportPersonalityBox';
import SingleReportRiskBox from './SingleReportRiskBox';
import SingleReportValidityBox from './SingleReportValidityBox';
import SingleReportExecutiveBox from './SingleReportExecutiveBox';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import SingleReportSummaryRemoteBox from './SingleReportSummaryRemoteBox';


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:'1.5vh'
    },
    
    overall:{
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #FC6626 , #FC264D)'
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    },
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
  });

const REPORT_RISK = 2

class SingleReportSummary2 extends React.Component {
  
    constructor(props) {
        super(props);           
        this.moveTo = this.moveTo.bind(this);
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        displayScores:[],
        invalidReport:false,
        loading:true,
        isMedLow:false,
    };

    componentDidMount(){
        if (this.props.report ) {
            this.setState({report: this.props.report})
            const scores = [...this.props.report.assessment.assessment_scores]
            const displayScores = scores.filter(score => score.trait.is_display === true)
            this.setState({displayScores})
            if (this.props.report.dominant_traits && this.props.report.dominant_traits[0] === 0){
                this.setState({invalidReport:true})
            }else{
                this.setIsMedLow(this.props.report)
            }
            
        }
        if (this.props.reportParts) {
            this.setState({reportParts: this.props.reportParts})
            this.setOverallRisk(this.props.reportParts) 
        }
        
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report!==this.state.report ){
            this.setState({report:nextProps.report});  
            if (nextProps.report.dominant_traits && nextProps.report.dominant_traits[0] === 0){
                this.setState({invalidReport:true})
            }
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setOverallRisk(nextProps.reportParts)
            this.setState({reportParts:nextProps.reportParts});  
        }
        if (nextProps.loading === this.state.loading) {
            this.setState({loading: nextProps.loading})
        }
    }

    setIsMedLow(report){
        const dominant = report.dominant_traits[0];
        const scoreInd = report.assessment.assessment_scores.findIndex(({ trait_id }) => trait_id === dominant )
        if (scoreInd > -1){
            const calcScore = report.assessment.assessment_scores[scoreInd].trait_weighted_score*100/report.assessment.assessment_scores[scoreInd].trait_potential_score
            const boundaries = report.assessment.assessment_scores[scoreInd].trait.boundaries[1]
            if (calcScore > boundaries.bottom && calcScore <= boundaries.top){
                if ( report.assessment.assessment_scores[scoreInd].trait.dark === false){
                    
                    this.setState({isMedLow:true})
                }
                else if(calcScore < boundaries.top-(boundaries.top-boundaries.bottom)/2){
                    this.setState({isMedLow:true})
                }
                else{
                    this.setState({isMedLow:false})
                }
            }
        }
    }

    setOverallRisk(reportParts){
        const ind = reportParts.findIndex(({ type }) => type === 0 )
        let overallRiskText;
        if (ind > -1){
            if (LangPicker.current() === 'en'){
                overallRiskText = reportParts[ind].text;
            }
            else{
                overallRiskText = reportParts[ind].report_template_localized[0]?reportParts[ind].report_template_localized[0].text:'';
            }
        } 
        else{
            overallRiskText = "";
        }
        this.setState({overallRiskText})
        const tind = reportParts.findIndex(({ type }) => type === 6 )
        if (tind > -1){
            this.setState({timeSeverity: reportParts[tind].severity})
        }
    }

    moveTo(dest, event){
        if (this.state.report && this.state.report.assessment && this.state.report.assessment.type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT && dest >= REPORT_RISK){
            this.props.moveTo(null, dest-1)
        }
        else{
            this.props.moveTo(null, dest)
        }
        if (dest === 3 && event){
            event.stopPropagation()
        }
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:10, borderTop:'1px solid #e8e8e8'}}>
            <Grid container >
            {
                this.state.invalidReport?
                <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'1vh', paddingLeft:10, paddingRight:10}}>
                    <Typography variant={'h3'} className={classes.invalid}>{ t('Reports - Summary - Invalid Assessment')}</Typography>
                </Grid>:''
            }
            <Grid item xs={4} className={classes.formRow} >   
                    <a href="#0" onClick={()=>{this.moveTo(1)}} className={classes.link}>
                        <SingleReportPersonalityBox  report={this.state.report} reportParts={this.state.reportParts} moveTo={this.moveTo} invalidReport={this.state.invalidReport} ></SingleReportPersonalityBox>  
                    </a> 
            </Grid>

            <Grid item xs={8} className={classes.formRow}>
                <Grid container>
                    {this.state.report && this.state.report.assessment && this.state.report.assessment.type !== AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT?
                    <Grid item xs={4}  style={{paddingInlineEnd:'2vh'}} >
                        <a href="#0" onClick={()=>{this.moveTo(2)}} className={classes.link}>
                            <SingleReportRiskBox report={this.state.report} reportParts={this.state.reportParts} moveTo={this.moveTo} />
                        </a>
                    </Grid>:''}
                    <Grid item xs={4}  style={{paddingInlineEnd:'2vh'}} >
                        <a href="#0" onClick={()=>{this.moveTo(3)}} className={classes.link}>
                            <SingleReportValidityBox report={this.state.report} reportParts={this.state.reportParts} moveTo={this.moveTo} />
                        </a>
                    </Grid>
                    
                    <Grid item xs={4}  style={{paddingInlineEnd:'0vh'}} >
                        <SingleReportSummaryVideoBox small={true} report={this.state.report} reportParts={this.state.reportParts} moveTo={this.moveTo} />
                    </Grid>
                    {this.state.report && this.state.report.assessment && this.state.report.assessment.type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT?
                    <Grid item xs={4}  style={{paddingInlineEnd:'0vh', paddingInlineStart:'2vh'}} >
                        <SingleReportSummaryRemoteBox small={true} report={this.state.report} reportParts={this.state.reportParts} moveTo={this.moveTo} />
                    </Grid> :''
                    }
                    <Grid item xs={12} style={{paddingBottom:'0vh', paddingTop:'2vh'}}>
                        <SingleReportExecutiveBox report={this.state.report} reportParts={this.state.reportParts} moveTo={this.moveTo} invalidReport={this.state.invalidReport} />
                    </Grid>
                    

                    
                </Grid>
            </Grid>
            
            
            </Grid>
        </div>
                           
    );
  }
}
SingleReportSummary2.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportSummary2),SingleReportSummary2);