import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import PackageNote from './PackageNote';
import { connect } from "react-redux";
import { setAdminDetails, incrementBy } from "../../redux/admin";
import { withRouter } from 'react-router-dom';
import  PayDialog from "./PayDialog";
import { withTranslation } from 'react-i18next';
import EgStatusAlert from '../../components/EgStatusAlert';
import EgHttp from '../../util/EgHttp';
import Skeleton from '@material-ui/lab/Skeleton';


const mapStateToProps = state => ({
    admin: state.admin.value
  });
  
const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
});

class PackagesPage extends React.Component {

  state = {
    payDialogOpen: null,
    packages: null,
    numberOfTests: 0
  };

  constructor(props) {
    super(props);  
    console.log("PACK CONSTTRUCTOR")
    this.getPackages()
    this.handleClose = this.handleClose.bind(this);  
    this.openPayment = this.openPayment.bind(this);
    this.refresh = this.refresh.bind(this);
    this.showStatusBar = this.showStatusBar.bind(this);
    this.handleStatusClose = this.handleStatusClose.bind(this);
    this.getPackages = this.getPackages.bind(this)
    this.getAssessments = this.getAssessments.bind(this)

    this.getAssessments()
}

  openPayment(pack){
    this.setState({payDialogOpen: <PayDialog open closeDialog={this.handleClose} refresh={this.refresh} package={pack} style={{maxWidth:500}}/>})
  }

  handleClose(){
    this.setState({payDialogOpen:null});
  }
  refresh(status, message){
    if (status === 'SUCCESS'){
        this.showStatusBar(message)
    }
  }
  handleStatusClose(){
    this.setState({statusBar:null})
  }
  showStatusBar(message){
    this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
  }
  async getPackages() {
    const result = await EgHttp.get('/public/packages')
    const packs = await result.json()
    this.setState({packages:packs})
    return packs
  }

  async getAssessments() {
    const result = await EgHttp.get('/orgs/assessments')
    const parsed = await result.json()
    const num_of_tests = parsed.avail_tests
    let tadmin = {
        ...this.props.admin
      }
    let torg = {
      ...this.props.admin.org
    }
    torg.tests = num_of_tests
    tadmin.org = torg
    this.setState({numberOfTests:num_of_tests})
    this.props.setAdminDetails(tadmin)
  }
  
  render() {
    const { t } = this.props;
    return (
        <div>
        <Grid container>
            <Grid xs={12} style={{paddingTop:30}}>
                <Typography variant={'h1'} style={{textAlign:'start', paddingInlineStart:20}}>{t('Settings - Packages - You currently have')} {this.props.admin.org?this.state.numberOfTests:'-'} {t('Settings - Packages - assessments available')} </Typography>
            </Grid>
            <Grid xs={12} style={{paddingTop:30}}>
                <Typography variant={'h4'} style={{textAlign:'start', paddingInlineStart:20}}>{t('Settings - Packages - Select the assessment package')}</Typography>
            </Grid>
            <Grid xs={4} style={{padding:20}}>
                {this.state.packages?
                <PackageNote noteTitle={t(`Settings - Packages - ${this.state.packages.basic.name}`)} testNumber={this.state.packages.basic.licenses} testCost={this.state.packages.basic.costPerTest} totalCost={this.state.packages.basic.totalCost} currency={this.state.packages.basic.currency}  openPayment={() => {this.openPayment(this.state.packages.basic)}}></PackageNote>:
                <Skeleton variant="rectangular" height={'45vh'}></Skeleton>
                }
            </Grid>
            <Grid xs={4} style={{padding:20}}>
                {this.state.packages?
                <PackageNote noteTitle={t(`Settings - Packages - ${this.state.packages.pro.name}`)} testNumber={this.state.packages.pro.licenses} testCost={this.state.packages.pro.costPerTest} totalCost={this.state.packages.pro.totalCost} currency={this.state.packages.pro.currency} openPayment={() => {this.openPayment(this.state.packages.pro)}}></PackageNote>:
                <Skeleton variant="rectangular" height={'45vh'}></Skeleton>
                }
            </Grid>
            <Grid xs={4} style={{padding:20}}>
                {this.state.packages?
                  <PackageNote noteTitle={t(`Settings - Packages - ${this.state.packages.enterprise.name}`)}  testNumber={this.state.packages.enterprise.licenses} testCost={this.state.packages.enterprise.costPerTest} totalCost={this.state.packages.enterprise.totalCost} currency={this.state.packages.enterprise.currency} openPayment={() => {this.openPayment(this.state.packages.enterprise)}}></PackageNote>:
                  <Skeleton variant="rectangular" height={'45vh'}></Skeleton>
                }
            </Grid>
        </Grid>
        {this.state.payDialogOpen}
        {this.state.statusBar}
        </div>
    );
  }
}
PackagesPage.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails, incrementBy })(withTranslation()(withRouter((withStyles(styles))(PackagesPage),PackagesPage)))