import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { ReactMediaRecorder } from "react-media-recorder";
import { Grid, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { setAdminDetails } from "../../redux/admin";
import { withStyles  } from '@material-ui/core/styles';
import EgButton from '../../components/EgButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const mapStateToProps = state => ({
  admin: state.admin.value
});

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
})

class AudioVideoChecker extends React.Component {

  state = {
    counter:5,
    showVideo:'none',
    recText:null
  };

  constructor(props) {
    super(props);
    console.log("AUDIO VIDEO CHECKER CONSTTRUCTOR")
  }

  recordShortVideo(startr, stopr) {
    this.setState({showVideo:'none', recText: "Recording...(5)"})
    this.setState({disableRec:true})
    console.log('START RECORDING!!')
    startr()
    setTimeout(stopr, 5000)
    setTimeout(()=>{this.setState({showVideo:'block'})},5000)
    setInterval(()=>{
        if (this.state.counter > 1){
          this.setState({counter:this.state.counter-1, recText:"Recording...(" +(this.state.counter-1) +")"})
        }
        else{
            this.setState({recText:null})
        }
      },1000)
  }


  render() {
    const { t } = this.props;
    return (
      <div style={{padding:20}}>
        <Grid container>
          <Grid xs={12} style={{display:'flex', justifyContent:'center'}}>
              <Typography variant='h2'>{t('Settings - Media Tetster - Test your camera and microphone')}</Typography>
          </Grid>
          <Grid xs={12} style={{display:'flex', justifyContent:'center', paddingTop:'2vh'}}>
              <Typography variant='h4'>{t('Settings - Media Tetster - To test your camera')}</Typography>
          </Grid>

         

          <Grid xs={12}>
            <ReactMediaRecorder
                askPermissionOnMount
                video
                render={({ status, startRecording, stopRecording, mediaBlobUrl }) =>
                (
                  <div>
                    {/*<div>
                      <p style={{color:"blue"}}>STATUS: {status}</p>
                  </div>*/}
                   <Grid xs={12} style={{display:'flex', justifyContent:'center', paddingTop:'3vh'}}>
                   <EgButton disabled={this.state.disableRec} startIcon={<FiberManualRecordIcon style={{marginInlineEnd:10}}/>} onClick={() => {this.recordShortVideo(startRecording, stopRecording)}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("Settings - Media Tetster - Test Media")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</EgButton>
                  </Grid>
                  <Grid xs={12} style={{display:'flex', justifyContent:'center', paddingBottom:'6vh',paddingTop:'2vh'}}>
                    <Typography variant='h3'>{this.state.recText}</Typography>
                    <video src={mediaBlobUrl} style={{display:this.state.showVideo, maxWidth:480}}controls autoPlay />
                  </Grid>
                    
                  </div>
                )}
            ></ReactMediaRecorder>
            </Grid>
          <Grid xs={12} style={{display:'flex', justifyContent:'center', paddingTop:20}}>
                  <EgButton secondary="true" outlined="true" onClick ={this.props.close}>{t("ErrorDialog - Close")}</EgButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AudioVideoChecker.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired
};

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AudioVideoChecker),AudioVideoChecker)))

// export default AudioVideoTest