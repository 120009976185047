import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Box, LinearProgress, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%',
      minWidth:100,
    },
    
    
})
const EgLinearProgress = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 5
    },
    colorPrimary: {
      backgroundColor: '#e4e4e4', 
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#11D6A7', 
    }
  }))(LinearProgress);

  class EnProgressStatic extends React.Component {
  
    state = {
        value:0
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.value!==this.state.value ){             
          this.setState({value:nextProps.value});     
        }
         
    }
    

    render(){

        //const { classes } = this.props;

        return (
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                  <EgLinearProgress variant="determinate" {...this.props} />
              </Box>
            {this.props.nopercent?'':
              <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${Math.round(this.state.value)}%`}</Typography>
              </Box>
            }
            </Box>
            );
        }
    }

    
EnProgressStatic.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(EnProgressStatic);