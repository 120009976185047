import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as AppConstants from '../util/constants/AppConstants'
import dashboardIcon from '../img/dashboard_icon.svg'
import candidatesIcon from '../img/candidates_icon.svg'
import employeesIcon from '../img/employees_icon.svg'
import allEmployeesIcon from '../img/all_employees_icon.svg'
import candidatesNewIcon from '../img/candidates_new.svg'
import assignedIcon from '../img/assigned_icon.svg'
import reportsIcon from '../img/reports_icon.svg'
import remoteInterviewIcon from '../img/remote_interview.svg'
import teamIcon from '../img/Team.svg'
import lookalikeIcon from '../img/jobs.svg'//'../img/lookalike.svg'
import settingsIcon from '../img/settings_icon.svg'
import logo from '../img/logo_blue.svg'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { setNavCand, setNavEmp } from "../redux/nav";
import { connect } from "react-redux";
import RoleManager from '../managers/RoleManager';
import { setAdminDetails } from "../redux/admin";

const drawerWidth = AppConstants.NavigationDrawerWidth;

const mapStateToProps = state => ({
    nav: state.nav.value,
    admin: state.admin.value,
});

const styles = theme => ({
    root: {
        display: 'flex',
        width:'100%',
        anchor:'right'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: theme.palette.blackbg
        },
  },
    arrowIcon:{
            '& .MuiSvgIcon-root':{
                color:'#ffffff'
            },
            color:'#ffffff'
    },
  
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:theme.palette.blackbg,
  },
  
  navItems:{
    textAlign:'start',
    paddingInlineStart:0,
  },
  logo:{
    width:140,
    paddingInline:10,
    paddingTop:40,
    paddingBottom:10,
    
      
    },
    menuText:{
        padding:5,
        fontSize:14,
        color:theme.palette.text.white,
       
          
    },
    menuTextSelected:{
        padding:5,
        fontSize:14,
        color:theme.palette.text.white,
        fontWeight: 700,
          
    },
    menuIcon:{
        color:theme.palette.text.white, 
        padding:5   ,
        minWidth:0,
    },

    menuItem:{
        paddingTop:10,
        textAlign:'start',
        '&:hover': {
          backgroundColor: '#45444366',
          color: '#efefef',
      }
    },
    menuItemSelected:{
        paddingTop:10,
        textAlign:'start',
        backgroundColor:'#454443',
        '&:hover': {
          backgroundColor: '#454443',
          color: '#efefef',
      }
    },
    
    navLink:{
        textDecoration:"none",
        

    },
    
});


class ExtendedNavBar extends React.Component {
  
    state = {
        open: this.props.open,
        candidateNestedOpen: this.props.nav.cand,
        employeesNestedOpen: this.props.nav.emp
    };
    constructor(props) {
        super(props);  
        this.setState({candidateNestedOpen:this.props.nav.cand})
        this.setState({employeesNestedOpen:this.props.nav.emp})
        
        this.openCandidateSubMenu = this.openCandidateSubMenu.bind(this);  
        this.openEmployeesSubMenu = this.openEmployeesSubMenu.bind(this);
    }
   
    openCandidateSubMenu(){
        this.props.setNavCand(!this.state.candidateNestedOpen)
        this.setState({candidateNestedOpen:!this.state.candidateNestedOpen})
    }
    openEmployeesSubMenu(){
        this.props.setNavEmp(!this.state.employeesNestedOpen)
        this.setState({employeesNestedOpen:!this.state.employeesNestedOpen})
    }
  
  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    let navPos = this.props.navPos
    return (
       
        <nav className={classes.drawer} aria-label="folders">
            
        <Drawer
            anchor={i18n.dir()==='rtl'?'right':'left'}
            classes={{
            paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
        >
                <Grid container spacing={0} className={classes.navItems}>
                    <Grid item xs={12} style={{textAlign:'center'}}>
                        <img src={logo} className={classes.logo} alt="logo" />
                    </Grid>
                
                    <Grid item xs={12}>
  
                    <List>
                        <ListItem button key={'dashboard'} className={navPos===1?classes.menuItemSelected:classes.menuItem}>
                            <ListItemIcon className={classes.menuIcon}><img src={dashboardIcon} alt="dashboardicon"/></ListItemIcon>
                            <ListItemText ><Link className={classes.navLink} to="/dashboard"><span className={classes.menuText}>{t('NavBar - Dashboard')}</span></Link></ListItemText>
                        </ListItem>
                        <ListItem button key={'Lookalike'} className={navPos===8?classes.menuItemSelected:classes.menuItem} >
                            <ListItemIcon className={classes.menuIcon}><img src={lookalikeIcon} alt="lookalikeicon"/></ListItemIcon>
                            <ListItemText ><Link className={classes.navLink} to="/lookalike"><span className={classes.menuText}>{t('NavBar - Lookalike')}</span></Link></ListItemText>
                        </ListItem>
                        <ListItem button onClick={this.openCandidateSubMenu} key={'candidates'} className={navPos===2?classes.menuItemSelected:classes.menuItem}>
                            <ListItemIcon className={classes.menuIcon}><img src={candidatesIcon} alt="candidateicon"/></ListItemIcon>
                            <ListItemText ><span className={classes.menuText}>{t('NavBar - Candidates')}</span></ListItemText>
                            {this.state.candidateNestedOpen ? <ExpandLess className={classes.arrowIcon}/> : <ExpandMore className={classes.arrowIcon}/>}
                        </ListItem>
                        <Collapse in={this.state.candidateNestedOpen} timeout='auto' unmountOnExit>
                                <ListItem button key={'candidates'} className={navPos===3?classes.menuItemSelected:classes.menuItem} style={{paddingInlineStart:30}}>
                                    <ListItemIcon className={classes.menuIcon}><img src={candidatesNewIcon} alt="candidateicon"/></ListItemIcon>
                                    <ListItemText ><Link className={classes.navLink} to="/candidates"><span className={classes.menuText}>{t('NavBar - New Candidates')}</span></Link></ListItemText>
                                </ListItem>
                                <ListItem button key={'assigned'} className={navPos===4?classes.menuItemSelected:classes.menuItem} style={{paddingInlineStart:30}}>
                                    <ListItemIcon className={classes.menuIcon}><img src={assignedIcon} alt="assignedicon"/></ListItemIcon>
                                    <ListItemText ><Link className={classes.navLink} to="/assigned"><span className={classes.menuText}>{t('NavBar - Assigned')}</span></Link></ListItemText>
                                </ListItem>
                                {this.props.admin && this.props.admin.account && RoleManager.block(this.props.admin.account.role,RoleManager.ONLY_INVITATIONS_ACCESS)?'':
                                <ListItem button key={'reports'} className={navPos===5?classes.menuItemSelected:classes.menuItem} style={{paddingInlineStart:30}}>
                                    <ListItemIcon className={classes.menuIcon}><img src={reportsIcon} alt="reportsicon"/></ListItemIcon>
                                    <ListItemText ><Link className={classes.navLink} to="/reports"><span className={classes.menuText}>{t('NavBar - Reports')}</span></Link></ListItemText>
                                </ListItem>}
                               
                        </Collapse>

                      {/*
                      <ListItem button onClick={this.openEmployeesSubMenu} key={'employees'} className={navPos===10?classes.menuItemSelected:classes.menuItem}>
                          <ListItemIcon className={classes.menuIcon}><img src={employeesIcon} alt="employeesicon"/></ListItemIcon>
                          <ListItemText ><span className={classes.menuText}>{t('NavBar - Employees')}</span></ListItemText>
                          {this.state.employeesNestedOpen ? <ExpandLess className={classes.arrowIcon}/> : <ExpandMore className={classes.arrowIcon}/>}
                     </ListItem>*/}
                      {/*<Collapse in={this.state.employeesNestedOpen} timeout='auto' unmountOnExit>*/}
                      {this.props.admin && this.props.admin.account && RoleManager.block(this.props.admin.account.role,RoleManager.ONLY_INVITATIONS_ACCESS)?'':
                        <ListItem button key={'allEmployees'} className={navPos===11?classes.menuItemSelected:classes.menuItem} >
                            <ListItemIcon className={classes.menuIcon}><img src={allEmployeesIcon} alt="employeesicon"/></ListItemIcon>
                            <ListItemText ><Link className={classes.navLink} to="/allemployees"><span className={classes.menuText}>{t('NavBar - Employees')}</span></Link></ListItemText>
                        </ListItem>}
                        {this.props.admin && this.props.admin.account && RoleManager.block(this.props.admin.account.role,RoleManager.ONLY_INVITATIONS_ACCESS)?'':
                        <ListItem button key={'teams'} className={navPos===12?classes.menuItemSelected:classes.menuItem} >
                            <ListItemIcon className={classes.menuIcon}><img src={teamIcon} alt="reportsicon"/></ListItemIcon>
                            <ListItemText ><Link className={classes.navLink} to="/teams"><span className={classes.menuText}>{t('NavBar - Teams')}</span></Link></ListItemText>
                        </ListItem>}
                      {/*</Collapse>*/}
                      
                        <ListItem button key={'remoteInterview'} className={navPos===9?classes.menuItemSelected:classes.menuItem}>
                          <ListItemIcon className={classes.menuIcon}><img src={remoteInterviewIcon} alt="reportsicon"/></ListItemIcon>
                          <ListItemText ><Link className={classes.navLink} to="/remote"><span className={classes.menuText}>{t('NavBar - Remote Interview')}</span></Link></ListItemText>
                      </ListItem>
                    
                    {/*
                      <ListItem button key={'settings'} className={navPos===7?classes.menuItemSelected:classes.menuItem}>
                          <ListItemIcon className={classes.menuIcon}><img src={reportsIcon} alt="reportsicon"/></ListItemIcon>
                          <ListItemText ><Link className={classes.navLink} to="/analytics"><span className={classes.menuText}>{t('NavBar - Analytics')}</span></Link></ListItemText>
                      </ListItem>
                    */}
                    
                      
                      <ListItem button key={'settings'} className={navPos===6?classes.menuItemSelected:classes.menuItem}>
                          <ListItemIcon className={classes.menuIcon}><img src={settingsIcon} alt="settingsicon"/></ListItemIcon>
                          <ListItemText ><Link className={classes.navLink} to="/settings"><span className={classes.menuText}>{t('NavBar - Settings')}</span></Link></ListItemText>
                      </ListItem>
                      
                  </List>
                </Grid>
              </Grid>
        </Drawer>
    
    </nav>
    
    );
  }
}
ExtendedNavBar.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default connect(mapStateToProps, { setAdminDetails, setNavCand, setNavEmp })(withTranslation()(withCookies(withStyles(styles)(ExtendedNavBar),ExtendedNavBar)));