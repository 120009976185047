//----------------------------------------------------------------------------------------
// AppUtils - This calss includes all the utility functions used by components 
// across the application
//---------------------------------------------------------------------------------------

const AppUtils = {
        
    dbDateFormat: function(date, onlyDate){
        
        var dt = new Date(date);
        var day = dt.getDate();
        var month = dt.getMonth() + 1;
        var year = dt.getFullYear();
        var hours = dt.getHours();
        var minutes = dt.getMinutes();
    
        if (day<10) day = "0"+day;
        if (month<10) month = "0"+month;
        if (hours<10) hours = "0"+hours;
        if (minutes<10) minutes = "0"+minutes;
        var dtstr;
        if (onlyDate === true || onlyDate==null) {dtstr= year + "-" + month + "-" + day}
        else {dtstr= year + "-" + month + "-" + day +" "+hours + ":" + minutes;}
         
        return dtstr; 
    },

    
    smsDateFormat: function(date){
    
        var dt = new Date(date);
        var day = dt.getDate();
        var month = dt.getMonth() + 1;
        var year = dt.getFullYear();
        var hours = dt.getHours();
        var minutes = dt.getMinutes();

        if (day<10) day = "0"+day;
        if (month<10) month = "0"+month;
        if (hours<10) hours = "0"+hours;
        if (minutes<10) minutes = "0"+minutes;
        var dtstr;
        dtstr= day + "-" + month + "-" +year 
        
        return dtstr; 
    },


    getDayName: function(day){
        if (day===0) return "Sunday";
        else if (day===1) return "Monday";
        else if (day===2) return "Tuesday";
        else if (day===3) return "Wednesday";
        else if (day===4) return "Thursday";
        else if (day===5) return "Friday";
        else if (day===6) return "Saturday";
        else return "";
    },

    getHebDayName: function(day){
        if (day===0) return "ראשון";
        else if (day===1) return "שני";
        else if (day===2) return "שלישי";
        else if (day===3) return "רביעי";
        else if (day===4) return "חמישי";
        else if (day===5) return "ששי";
        else if (day===6) return "שבת";
        else return "";
    },

    copyObject: function(o) {
        var output, v, key;
        output = Array.isArray(o) ? [] : {};
        for (key in o) {
          v = o[key];
          output[key] = (typeof v === "object" && v !== null) ? this.copyObject(v) : v;
        }
        return output;
    }, 
    
    getInitials: function(string) {
        try{
            let names = string.split(' '),
                initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        }catch(err){
            return '';
        }
    },
    
    getCurrencySymbol: function(locale, currency) {
        try {
            const res = (0).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).replace(/\d/g, '').trim()
            return res
        }
        catch(err) {
            console.error(`getCurrencySymbol: ${err}`)
            return ""
        }
    },

    isMobile(){
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true
       }
       else{
            return false
       }
    } ,

    getSorting: function(order, orderBy) { 
        return order === 'desc'
          ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
          : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
    }
    
}
export default AppUtils;