import React from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from "@material-ui/core";
import report_overall_icon from '../img/report_overall_icon.svg';
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    hi: {
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #FC6626 , #FC264D)'
    },
    med: {
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #F9A846 , #FFD25F)'
        
    },
    medlow: {
        padding:10, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right,  #FFD25F,#11d6a760 )'
        
    },
    low: {
        padding:10, 
        borderRadius:3,
        backgroundImage: 'linear-gradient(to right, #00AC83 , #11D6A7)'
    },
    invalid:{
        padding:10, 
        borderRadius:3, 
        backgroundColor: '#E2E2E2'
    }
  }))
//const INVALID = -1
const LOW_RISK = 0;
const MODERATE_RISK = 1;
//const MODERATE_LOW_RISK = 11;
const HIGH_RISK = 2;
//const EXTREME_RISK = 3;

export default function EgRiskBar(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme()
    let barText = '';
    let styleClass = null;
    if (props.severity === HIGH_RISK){
        barText = t('Reports - High Risk')
        styleClass = classes.hi
    }
    else if (props.severity === MODERATE_RISK){
        if (props.isMedLow){
            barText = t('Reports - Moderate-Low Risk');
            styleClass = classes.medlow
        }
        else{
            barText = t('Reports - Moderate Risk');
            styleClass = classes.med
        }
    }
   
    else if (props.severity === LOW_RISK){ //LOW_RISK
        barText = t('Reports - Low Risk');
        styleClass = classes.low
    }
    else{
        barText = t('Reports - Invalid Assessment');
        styleClass = classes.invalid
    }
    return (
        <div className={styleClass} style={{width:props.width}}>
            <Typography variant={'h4'} style={{color:theme.palette.text.white, fontWeight:500,  display:'flex', justifyContent: 'center', alignItems:'center'}}>
                <img src={report_overall_icon}  alt="overall" style={{paddingInline:10,maxHeight:24}}/>
                {barText}
            </Typography>
        </div>
    );
}
