import React from 'react';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles  } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Topbar from '../components/Topbar'
import { Grid, Typography } from '@material-ui/core';
import EgProgress from '../components/EgProgress';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as AppConstants from '../util/constants/AppConstants';
import EgStatusAlert from '../components/EgStatusAlert';
import EgHttp from '../util/EgHttp';
import EgButton from '../components/EgButton';
import NavigationManager from '../managers/NavigationManager';
import { withCookies, Cookies } from 'react-cookie';
import ExtendedNavBar from '../components/ExtendedNavBar';
import TeamTable from './teams/TeamTable';
import AddTeam from './teams/AddTeam';
//import TeamCard from './teams/TeamCard';
import { setAdminDetails } from "../redux/admin";
import { connect } from "react-redux";
import { setNavEmp } from "../redux/nav";
import RoleManager from '../managers/RoleManager';

const drawerWidth = AppConstants.NavigationDrawerWidth;

const mapStateToProps = state => ({
  admin: state.admin.value,
  nav: state.nav.value
});

const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%',   
  },

  appRight:{
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      background:"#FAFAFA",
    },
    marginRight: drawerWidth
  },
  appLeft:{
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      background:"#FAFAFA",
    },
    marginLeft: drawerWidth
  },
 
  //toolbar: //theme.mixins.toolbar,
  toolbar:{
    minHeigt:64,
    marginTop:50
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width:'100%',
    height:window.innerHeight,

  },
  actionBtns:{
    marginLeft:5,
    marginRight:5
  }
  
});

class Teams extends React.Component {
    state = {
      open: this.props.open,
      drawerState: null,
      operationIcon: null,
      openAdd: false,
      openTeamId:null,
      rows:[],
      originalRows:[],
      statusBar:null,
      loading:<EgProgress />,
    };

    constructor(props) {
        super(props);    
        
        if (!this.props.login.logged) {
            this.props.history.push('/signup') 
        } 
        else{
            NavigationManager.setLast(this.props, '/teams')
        }

        this.openAddTeam = this.openAddTeam.bind(this);     
        this.handleDrawerState = this.handleDrawerState.bind(this); 
        this.openEditTeam = this.openEditTeam.bind(this); 
        this.getTeams = this.getTeams.bind(this); 
        this.refresh = this.refresh.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this);
        this.search = this.search.bind(this);
        this.openTeam = this.openTeam.bind(this); 
        this.closeTeam = this.closeTeam.bind(this);   
        
        this.getTeams()
    }

    componentDidMount(){
        this.props.setNavEmp(true)   
    }

    async getTeams(){
      
      this.setState({loading:<EgProgress />})
      let response = await EgHttp.get( '/teams')
      if (response.status === 401) {
        this.props.history.push('/signin') 
        return;
      }
      let rowsres = await response.json();
      const origres = [...rowsres]
      this.setState({rows:rowsres, loading:null});
      this.setState({originalRows:origres});
      return;

  }

    handleStatusClose(){
      this.setState({statusBar:null})
    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }

    
    refresh(status, message){
      this.getTeams()
      if (status === 'SUCCESS'){
          this.showStatusBar(message)
      }
    }

    openAddTeam(){
      this.setState({openTeamId:null}, ()=> {
        this.setState({openAdd:true})
      })
    }

    openEditTeam(id){
        this.setState({openTeamId:id}, ()=> {
            this.setState({openAdd:true})
        })
    }

    openTeam(id){
      this.props.history.push('/allemployees?team='+id)
    }

    closeTeam(){
      this.setState({singleTeamOpen:false})
      this.setState({openTeamId:null})
      this.getTeams()
    }

    handleDrawerState(sw){
        if (sw){
            this.setState({openAdd:!this.state.openAdd})
        }
        else{
            this.setState({openAdd:false})
        }
    }

    filterItems(arr, query) {
      return arr.filter(function(el) {
        return (el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) 
      })
    }

    search(term){
      this.setState({searchTerm: term})
      if (term && term.length>0){
        let filteredRows;
        filteredRows = this.filterItems(this.state.originalRows, term)
        this.setState({rows: filteredRows})
      }
      else{
        this.setState({rows: this.state.originalRows})
      }
    }
  
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
          this.state.singleTeamOpen?''
          /*
          <div className={classes.root} >
            <CssBaseline />
            <ExtendedNavBar navPos={11}></ExtendedNavBar>
            <TeamCard openState={this.state.singleTeamOpen} hrId={this.state.openTeamId} refresh={this.refresh} close={this.closeTeam}></TeamCard> 
          </div>*/
          :
          <div className={classes.root} >
            <CssBaseline />
            <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} style={{boxShadow:"0px 0px 0px" }}>
                {this.state.loading}
                <Toolbar >
                    <Topbar login={this.props.login} page={this.props.page} search={this.search}></Topbar>
                </Toolbar>
            </AppBar>

        <ExtendedNavBar navPos={12}></ExtendedNavBar>

          <main className={classes.content}>
              <div className={classes.toolbar} />
            
              <Grid container fullwidth='true' justify="center" direction="row" alignItems="flex-start">
            
                <Grid item xs={12 }>
                  
                  <Grid container  justify="left" direction="row" alignItems="flex-start">
                      <Grid item xs={6} justify="left">
                        <Typography variant="h1" > {t('Teams - All Teams')} </Typography>
                      </Grid>
                      <Grid item xs={6} justify="right" style={{textAlign:"end"}} >
                            {RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_TEAM_ADMIN)?
                            <EgButton  onClick={this.openAddTeam} className={classes.actionBtns}>{t('Teams - Add Team')}</EgButton>:''}
                      </Grid>
                    
                      <Grid item xs={12} style={{marginTop:10}}>
                            <TeamTable noteTitle={t('Teams - Team List')} login={this.props.login} openTeam={this.openTeam} addNewTeam={this.openAddTeam}  editTeam={this.openEditTeam} rows={this.state.rows} refresh={this.refresh} loading={this.state?false:true} searchTerm={this.state.searchTerm}> </TeamTable>
                      </Grid>
                      
                  </Grid>
                </Grid>
              </Grid>
          </main>
          <AddTeam handleDrawerState={this.handleDrawerState} openState={this.state.openAdd} teamId={this.state.openTeamId} refresh={this.refresh} context='team'></AddTeam> 
          {this.state.statusBar}
          </div>
    );
    
}}

Teams.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default connect(mapStateToProps, { setAdminDetails, setNavEmp })(withTranslation()(withCookies(withRouter((withStyles(styles))(Teams),Teams))))
