import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Typography, Checkbox, FormControlLabel} from '@material-ui/core';
import testIntro from '../../img/assess_opener.svg';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import AppUtils from '../../util/AppUtils';
import EgActivityRecorder from '../../util/EgActivityRecorder';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';

const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%'
  },
  
  logo:{
    width:'65%',
    paddingTop:5,
    paddingBottom:5,
    
    },
    
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'55vh',
        display:'flex', 
        alignItems:'center',
      },
    
    check:{
        color: '#666666',
        fontSize: '10px !important',
    },
    
    actionButton:{
        fontSize:14,
        paddingLeft:10,
        paddingRight:10
    },  
});

const GreenCheckbox = withStyles({
    root: {
      color: "#0085AF",
      padding:5,
      '&$checked': {
        color: "#0085AF",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  
class AssessOpener extends React.Component {
  
    state = {
        candidate: this.props.candidate,
        nextDisabled: true,
        privacyApproveChk: true,
    };

    constructor(props) {
        super(props);  
        
        this.start = this.start.bind(this);  
        this.privacyBoxChange = this.privacyBoxChange.bind(this)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});    
        }
    }

    start() {
        this.props.continue();
    }

    privacyBoxChange(event){
        this.setState({privacyApproveChk:!this.state.privacyApproveChk})
    }
    embedParams(str){
        str = str.replace('%company%',this.state.candidate?this.state.candidate.org_name:'');
        str = str.replace('%org_email%',this.state.candidate?this.state.candidate.admin_email:'');
        return str
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div>
                <Paper className={classes.paper}>
                    <Grid container justify="left" direction="row" alignItems="center" style={{ paddingLeft: 30, paddingRight: 30  }}>
                        <Grid item xs={AppUtils.isMobile()?12:7} >
                            <Grid container justify="left" direction="row" >
                                <Grid item xs={12} style={{ paddingTop: '1vh'}}>
                                    <Typography variant={'h1'}>{(this.state.candidate?this.state.candidate.firstname:'') + ", " + t('Assessment - Opener - Welcome to Engard Assessment') }</Typography>
                                </Grid>
                                {this.state.candidate?
                                <Grid item xs={12} style={{ paddingTop: '2vh' }}>
                                    <Typography variant={'h4'}>{(this.state.candidate.admin_first) + " " +(this.state.candidate.admin_last) + " " + t('Assessment - Opener - from') + " " + (this.state.candidate.org_name) + " " +  t('Assessment - Opener - invited you to participate')}</Typography>
                                </Grid>:''}

                                <Grid item xs={12} style={{ paddingTop: '2vh' }}>
                                    <Paper style={{maxHeight:'30vh', overflow:'auto', padding:10, backgroundColor:'#fbfbfb'}}>
                                    {
                                    this.state.candidate && this.state.candidate.assessment_type === AssessmentTypes.EMPLOYEE_ONGOING_REVIEW?
                                        <div>
                                            <Typography variant={'h4'}><div dangerouslySetInnerHTML={{__html: this.embedParams(t('Assessment - Consent Employee - Title', {interpolation: {escapeValue: false}}))}} /> </Typography>
                                            <Typography variant={'h4'}><div dangerouslySetInnerHTML={{__html: this.embedParams(t('Assessment - Consent Employee', {interpolation: {escapeValue: false}}))}} /> </Typography>
                                        </div>:
                                        <div>
                                            {this.state.candidate && this.props.orgLegal && this.props.orgLegal['consent-text']?
                                                <div>
                                                <Typography variant={'h4'} style={{fontWeight:600,paddingBottom:20}}>{this.props.orgLegal['consent-title']} </Typography>
                                                <Typography variant={'h4'} style={{paddingBottom:20}}><div dangerouslySetInnerHTML={{__html: this.props.orgLegal['consent-text']}}></div></Typography>
                                                </div>:''
                                            }
                                            <Typography variant={'h4'}><div dangerouslySetInnerHTML={{__html: this.embedParams(t('Assessment - Consent - Title', {interpolation: {escapeValue: false}}))}} /> </Typography>
                                            <Typography variant={'h4'}><div dangerouslySetInnerHTML={{__html: this.embedParams(t('Assessment - Consent', {interpolation: {escapeValue: false}}))}} /> </Typography>
                                        </div>
                                    }
                                    </Paper>
                                    
                                </Grid>
                            
                                <Grid xs={12} style={{ paddingTop: '0vh' }}>
                                    <FormControlLabel className={classes.check} style={{margin:0}}
                                            control={<GreenCheckbox checked={this.state.privacyApproveChk} onChange={this.privacyBoxChange} inputProps={{ 'aria-label': 'primary checkbox', fontSize: 10, margin:0 }} ></GreenCheckbox>}
                                            label={<Typography variant={'h5'}> {t('Assessment - Opener - I agree to Engard')} </Typography>} />
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '1vh', paddingBottom:'1vh' }}>
                                    <EgButton variant="contained" className={classes.actionButton} id="continue-btn" onClick={this.start} disabled={!this.state.privacyApproveChk} onMouseEnter={EgActivityRecorder.handleMouseEnter} onMouseLeave={EgActivityRecorder.handleMouseLeave}>
                                        {t('Assessment - Opener - Continue')}
                                    </EgButton>
                                </Grid>
                            </Grid> 
                        </Grid>

                        <Grid item xs={AppUtils.isMobile()?12:5} justify="center" style={{ display: "flex", alignItems: "center" }}>
                            <img src={testIntro} className={classes.logo} alt="testIntro" />
                        </Grid>
                    </Grid>
                </Paper> 
            </div>
    );
  }
}
AssessOpener.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(AssessOpener),AssessOpener);


