import React from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from "@material-ui/core";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    hi: {
        padding:9, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #FC6626 , #FC264D)'
    },
    med: {
        padding:9, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right, #F9A846 , #FFD25F)'
        
    },
    medlow: {
        padding:9, 
        borderRadius:3, 
        backgroundImage: 'linear-gradient(to right,  #FFD25F,#11d6a760 )'
        
    },
    low: {
        padding:9, 
        borderRadius:3,
        backgroundImage: 'linear-gradient(to right, #00AC83 , #11D6A7)'
    },
    invalid:{
        padding:9, 
        borderRadius:3, 
        backgroundColor: '#E2E2E2'
    }
  }))

const VALID = 0;
const SUSPECIOUS = 1;
const INVALID = 2;

export default function EgAssessmentValidity(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme()
    let barText = '';
    let styleClass = null;
    
    if (props.severity === VALID){
        barText = t('ValidityLevel - Valid');
        styleClass = classes.low
    }
    else if (props.severity === SUSPECIOUS){
        barText = t('ValidityLevel - Borderline');
        styleClass = classes.med
    }
    else if (props.severity === INVALID){
        barText = t('ValidityLevel - Invalid');
        styleClass = classes.hi
    }
    else{
        barText = t('ValidityLevel - Unknown');
        styleClass = classes.invalid
    }
    
    return (
        <div className={styleClass} style={{width:props.width}}>
            <Typography variant={'h4'} style={{color:theme.palette.text.white, fontWeight:500,  display:'flex', justifyContent: 'center', alignItems:'center'}}>
                {   props.severity === VALID?<CheckCircleOutlinedIcon />:
                    props.severity === SUSPECIOUS?<ReportProblemOutlinedIcon style={{fontSize:26}}/>:
                    <CancelOutlinedIcon />
                }
                &nbsp; { barText}
            </Typography>
        </div>
    );
}
