import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from './EgButton';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    actionButton:{
        width: 100,
        margin: 5
    },
    messageHeader:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.primary.light,
    }
    
})

class ConfirmationDialog extends React.Component {
  
    state = {
        open: this.props.open,
        candidate:this.props.candidate,
    };

    constructor(props) {
        super(props);  
        
        this.confirm = this.confirm.bind(this);  
        this.cancel = this.cancel.bind(this); 
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
    }

    cancel(){
        this.setState({open:false})
        this.props.cancel();
    }

    confirm(){
        this.setState({open:false})
        this.props.confirm();
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
       
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    
                   <Paper elevation={0}>
                       <Grid container justify='left'>
                            
                           <Grid item xs={12} className={classes.messageHeader}>
                               <Typography variant={'h2'} style={{color:'#043241', textAlign:'center'}}>{this.props.title}</Typography>
                            </Grid>
                           
                            <Grid item xs={12} style={{paddingBottom:20, paddingTop:30}}>
                                <Typography variant={'h4'}>{this.props.message}</Typography> 
                            </Grid>
                            
                            <Grid item xs={6}  style={{textAlign:"end", paddingTop:30,paddingBottom:10}} >
                                <EgButton className={classes.actionButton} onClick={this.confirm}>{t("ConfirmationDialog - Yes")}</EgButton>  
                            </Grid>
                            <Grid item xs={6}  style={{textAlign:"start", paddingTop:30,paddingBottom:10}} >
                                <EgButton secondary="true" outlined="true" className={classes.actionButton} onClick={this.cancel}>{t("ConfirmationDialog - No")}</EgButton>  
                            </Grid>
                       </Grid>
                   </Paper>
                </DialogContent>
                
                
            </Dialog>
            </div>
        );
    }
}

ConfirmationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(ConfirmationDialog),ConfirmationDialog)))