import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import AppUtils from '../../util/AppUtils';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'start',
      color: theme.palette.text.secondary,
      border:'1px solid #eeeeee',
      borderRadius:4,
      width:'100%',
      height:'47vh'
    },
    
    
    noteValue:{
        fontSize:36,
        color:theme.palette.text.black,
        fontWeight: 600,
        paddingTop:30,
        textAlign:"center"
        
    },
    noteDetails:{
        fontSize:24,
        color:theme.palette.text.black,
        fontWeight: 500,
        paddingTop:20,
        textAlign:"center"
        
    },
    noteDetails2:{
        fontSize:18,
        color:theme.palette.text.black,
        fontWeight: 500,
        paddingTop:10,
        textAlign:"center"
        
    },
    navLink:{
      textDecoration:"none",
    },
    buyButton:{
        margin: 5,
        paddingLeft: 80,
        paddingRight: 80,
    },
    
  });
  
class PackageNote extends React.Component {
  
  state = {
    
  };
  
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
        <div style={{}}>

        <Grid container spacing={3} style={{}}>
            
            <Grid item xs={12 } style={{height:'100%'}}>
                <Paper elevation={0} className={classes.paper}>
                        <Typography variant={'h4'}> {this.props.noteTitle} </Typography>
                        <Typography className={classes.noteDetails}> {this.props.testNumber + ' ' + t('Settings - Packages - Assessments')} </Typography>  
                        <Typography className={classes.noteDetails2}> {'('+ AppUtils.getCurrencySymbol('en', this.props.currency) +this.props.testCost + ' / ' +  t('Settings - Packages - Assessment') + ')'} </Typography> 
                        <Typography className={classes.noteValue}> {AppUtils.getCurrencySymbol('en', this.props.currency)+this.props.totalCost } </Typography> 
                        <Grid xs={12} style={{display:'flex', padding:70, justifyContent:'center'}}>
                            <EgButton className={classes.buyButton} onClick={()=>{this.props.openPayment(this.props.totalCost)}}>{t('Settings - Packages - Buy')}</EgButton> 
                        </Grid> 
                        
                </Paper>
            </Grid>
            
           
        </Grid>  
        </div>       
    );
  }
}
PackageNote.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(PackageNote),PackageNote);