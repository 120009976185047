import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, TextField, FormControl, InputLabel, Select, MenuItem, Tab, Tabs, Divider } from '@material-ui/core';
import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import * as EmailValidator from 'email-validator';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import LangPicker from '../../util/LangPicker';
import UploadCV from '../candidates/UploadCV';
import CVDialog from './CVDialog';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import UploadCVDialog from '../candidates/UploadCVDialog';
import cvimg from '../../img/resume.png';
import InterviewsAccordion from './InterviewsAccordion';
import RoleManager from '../../managers/RoleManager';

require('typeface-montserrat')

const filter = createFilterOptions();

const mapStateToProps = state => ({
    admin: state.admin.value
  });

  const AntTabs = withStyles((theme) => ({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
  }))(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontSize:14,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    phoneText:{
        fontSize:'15px !important', 
        paddingTop:0,
        paddingBottom:0,
        paddingLeft:10,
        fontFamily: 'Montserrat',
        width:'100% !important',
        borderColor: '#E4E4E4 !important',
    },
    dropdownClass1:{
        backgroundColor:theme.palette.whitebg,
    },
    importOptionPaper:{

        display: "flex",
        height: 60,
        width:60,
        justifyContent: "center",
        alignItems: "center",
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: '#efefef',
        }
    },
    importIcon:{
        width:40,
        height:40,
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width: 150,
        margin: 5
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
});
  
class AddHumanResource2 extends React.Component {
  
    constructor(props) {
        super(props);            
        this.closeDrawer = this.closeDrawer.bind(this);  
        this.openImport = this.openImport.bind(this);  
        this.saveHumanResource = this.saveHumanResource.bind(this);  
        this.validateEmail = this.validateEmail.bind(this);
        this.validatefName = this.validatefName.bind(this);
        this.validatelName = this.validatelName.bind(this);
        this.changeCustomField = this.changeCustomField.bind(this)
        this.updateCVFile = this.updateCVFile.bind(this)
        this.clearCV = this.clearCV.bind(this)
        this.openCVDialog = this.openCVDialog.bind(this)
        this.closeCV = this.closeCV.bind(this)
        this.getPartialPos = this.getPartialPos.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
        this.openUploadCV = this.openUploadCV.bind(this)
        this.handleCloseUpload = this.handleCloseUpload.bind(this)
        this.refresh = this.refresh.bind(this)
        this.updateInterviews = this.updateInterviews.bind(this)
    }

    state = {
        addOpen: this.props.addOpen,
        hrId: this.props.hrId,
        context: this.props.context,
        fnameVal: true,
        lnameVal: true,
        emailVal: true,
        selectedTeam:0,
        positions:this.props.positions,
        selectedPosition:{},
        tabvalue:0,
        interviewerName:'',
        interviews:[],
    };

    componentDidMount(){
        this.setState({positions:this.props.positions})
        this.setFixedFields()
    }
    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){
            this.setState({addOpen:nextProps.openState}); 
            if (!this.state.hrId){  
                this.setState({firstName:null, lastName: null, email: null,  phone: null, position: null, hrId:null, selectedTeam:null, selectedPosition:null, CvFileName:null, startDate:new Date(), tabvalue:0})  
            }
            else if (nextProps.openState === true){
                this.getHumanResource(this.state.hrId, this.props.context)   
            }
        }
        if (nextProps.hrId !== this.state.hrId){
            this.setState({hrId:nextProps.hrId});  
            if (nextProps.hrId){
                this.getHumanResource(nextProps.hrId, this.props.context)   
            }
        }
        if (nextProps.teams && nextProps.teams !== this.state.teams){
            this.setState({teams:nextProps.teams});  
        }
        if (nextProps.positions && nextProps.positions !== this.state.positions){
            this.setState({positions:nextProps.positions});  
        }
        this.setFixedFields()
    }

    validateEmail(event){
        this.setState({emailVal:EmailValidator.validate(event.target.value.trim())})
    }
    validatefName(event){
        this.setState({fnameVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
    validatelName(event){
        this.setState({lnameVal:(event.target.value.trim() && event.target.value.trim().length>1) })
    }
    validateAll(){
        let failed = false;
        if (!this.state.email || !EmailValidator.validate(this.state.email.trim())){ 
            this.setState({emailVal:false})
            failed=true;
        }
        if (!this.state.firstName || !this.state.firstName.trim() || this.state.firstName.trim().length<2) {
            this.setState({fnameVal:false})
            failed=true;
        }
        if (!this.state.firstName || !this.state.lastName.trim() || this.state.lastName.trim().length<2) {
            this.setState({lnameVal:false})
            failed=true;
        }
        
        if (failed) return false;
        return true;
    }

    async getHumanResource(id, context){
        let response,rowsres,selPosObj
        if (context === 'employee'){
            response = await EgHttp.get('/employees/'+ id + "/" + LangPicker.current())
            rowsres = await response.json();
            let formatedDate = ""
            if (rowsres.start_work_date){
                const dt = new Date(rowsres.start_work_date)
                const mnth = dt.getMonth()+1<10?"0"+(dt.getMonth()+1):""+(dt.getMonth()+1)
                const day = dt.getDate()<10?"0"+(dt.getDate()):""+(dt.getDate())
                formatedDate =dt.getFullYear() + "-" + mnth + "-" + day
            }
            selPosObj = this.findPositionInList(rowsres.position,rowsres.team_members)
            this.setState({firstName:rowsres.firstname, lastName: rowsres.lastname, email: rowsres.email,  phone: rowsres.phone, position: rowsres.position, selectedPosition:selPosObj, selectedTeam:rowsres.team_members[0]?rowsres.team_members[0].team_id:0,prevTeam:rowsres.team_members[0]?rowsres.team_members[0].team_id:0, startDate:formatedDate});
        }
        else{
            response = await EgHttp.get('/candidates/'+ id)
            rowsres = await response.json();
            const thisRef = this
            
            selPosObj = this.findPositionInList(rowsres.position,rowsres.team_members)
            this.setState({firstName:rowsres.firstname, lastName: rowsres.lastname, email: rowsres.email,  phone: rowsres.phone, selectedPosition:selPosObj, prevTeam:rowsres.team_members[0]?rowsres.team_members[0].team_id:0, selectedTeam:rowsres.team_members[0]?rowsres.team_members[0].team_id:0});
            if (rowsres.custom_properties && rowsres.custom_properties && rowsres.custom_properties["custom-hr-properties"]){
                rowsres.custom_properties["custom-hr-properties"].forEach(function(field){
                    thisRef.setState({[field.name]:field.value})
                })
            }
            else{
                if (this.props.admin.org.config["custom-hr-properties"]){
                    this.props.admin.org.config["custom-hr-properties"].forEach(function(field){
                        thisRef.setState({[field.name]:null})
                    })
                }
            }
            if (rowsres.custom_properties && rowsres.custom_properties && rowsres.custom_properties["hr_managed_properties"]){
                this.setState({hrManagedProps: rowsres.custom_properties["hr_managed_properties"]})   
            }
            else{
                this.setState({hrManagedProps:null})
            }    
        }
        if (rowsres.custom_properties && rowsres.custom_properties.cv){
            this.setState({CvText: rowsres.custom_properties.cv, CvFileName:rowsres.custom_properties.pdf_url })
        }
        else{
            this.setState({CvText: null, CvFileName:null})
        }
        if (rowsres.custom_properties && rowsres.custom_properties.interviews){
            console.log(rowsres.custom_properties.interviews)
            this.setState({interviews:rowsres.custom_properties.interviews})
        }
        else{
            this.setState({interviews:[]})
        }
       
        if(this.state.teams && this.state.positions){
            const teams = this.state.teams
            const enrPositions = this.state.positions.map(function(pos){
                const teamlInd = teams.findIndex(({ id }) => id === pos.team )
                if (teamlInd > -1){
                    pos["teamName"] = teams[teamlInd].name
                }
                return pos
            })
            this.setState({positions:enrPositions})   
        }
        return;

    }

    findPositionInList(positionName, team_members){
        if (this.state.positions && team_members && team_members[0]){
            const posInd = this.state.positions.findIndex(({ for_position, team }) => for_position === positionName && team === team_members[0].team_id) 
            if (posInd > -1 ){
                return this.state.positions[posInd]
            }
        }
        return {id:0, for_position:positionName, team:team_members && team_members[0]?team_members[0].team_id:0}
    }

    selectTeam = event => {
        this.setState({ selectedTeam: event.target.value });
    };

  
    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    setPhone = event => {
        this.setState({phone: event})
    }

    
    closeDrawer(){
        this.setState({addOpen:false, firstName:null, lastName: null, email: null,  phone: null, position: null, CvFileName: null, cvFile:null, interviews:[], tabvalue:0})
        this.props.handleDrawerState()
    }

    refresh(){
        const { t } = this.props;
        this.closeDrawer()
        this.props.refresh("SUCCESS", t('Candidates - Add - New candidate added successfully'));
    }

    openImport(){
        this.setState({addOpen:false})
        this.props.handleDrawerState(true)
    }

    async saveHumanResource(){
        const { t } = this.props;
        if (!this.validateAll()){
            return;
        }
        let response, personId, rowsres
        try{
            let body = JSON.stringify({
                firstName: this.state.firstName.trim(),
                lastName: this.state.lastName.trim(),
                email: this.state.email.trim(),
                phone: this.state.phone,  
                position:  this.state.selectedPosition?this.state.selectedPosition.for_position.trim():'',
                positionId: this.state.selectedPosition?this.state.selectedPosition.id:0,
                id:this.state.hrId,
                orgId: this.props.admin.org.id,
                teamId: this.state.selectedTeam,
                prevTeamId:this.state.prevTeam,
                startDate:this.state.startDate,
                interviews: this.state.interviews
            })
            // Add or update employee
            if (this.state.context === 'employee'){
                if (this.state.hrId){
                    response = await EgHttp.put('/employees', body)
                    personId = this.state.hrId
                }
                else{
                    response = await EgHttp.post('/employees', body)
                    rowsres = await response.json();
                    personId = rowsres.id
                }
                
                EventLog.insert(this.props.admin.org.id, this.state.hrId?this.state.hrId:rowsres.id, this.state.hrId?EventLog.EDIT_EMPLOYEE:EventLog.INSERT_EMPLOYEE, this.props.admin.account.id)
            
                this.closeDrawer();
                this.props.refresh("SUCCESS", this.state.hrId?t('Employees - Add - Employee details updated successfully'):t('Employees - Add - New employee added successfully'));
            
            }
            else{    // Add or update candidate
                let candidateId
                if (this.state.hrId){
                    candidateId = this.state.hrId
                    response = await EgHttp.put('/candidates', body)
                    const extraHRFields = this.getExtraHRFields()
                    const thisRef = this
                    if (extraHRFields){
                        const extraFieldsBody = extraHRFields.map(function(field){
                            return {name:field.name, value: thisRef.state[field.name]}
                        })
                    }
                }
                else{
                    response = await EgHttp.post('/candidates', body)
                    rowsres = await response.json();
                    candidateId = rowsres.id
                }
                // Add or update the extra HR fields 
                const extraHRFields = this.getExtraHRFields()
                const thisRef = this
                if (extraHRFields){
                    const extraFieldsBody = extraHRFields.map(function(field){
                        return {name:field.name, value: thisRef.state[field.name]}
                    })
                    const extraConfigBody = {config:extraFieldsBody}
                    response = await EgHttp.post('/candidates/set_custom_prop/'+candidateId, JSON.stringify(extraConfigBody))
                }
                personId = candidateId;
            }
            //Add/Update CV
            if (this.state.cvFile){
                console.log("UPLOADING CV for person: " + personId)
                let jobReq = '';
                if (this.state.positions && this.state.positions.length && this.state.selectedPosition && this.state.selectedPosition.id){
                    const posInd = this.state.positions.findIndex(({ id }) => id === this.state.selectedPosition.id )
                    if (posInd > -1){
                        jobReq = this.state.positions[posInd].job_reqirements
                    }
                }
                let body = JSON.stringify({
                    job_requirements:jobReq.replace(/[^\x00-\x7F]/g, "")
                })
                response = await EgHttp.postFile('/candidates/importcv/' + personId, this.state.cvFile, body);
                if (response.status === 200){
                    console.log("Uploaded CV File SUCCESS")
                    rowsres = await response.json();
                }
            }
            EventLog.insert(this.props.admin.org.id, this.state.hrId?this.state.hrId:rowsres.id, this.state.hrId?EventLog.EDIT_CANDIDATE:EventLog.INSERT_CANDIDATE, this.props.admin.account.id)
            this.closeDrawer();
            this.props.refresh("SUCCESS", this.state.hrId?t('Candidates - Add - Candidate details updated successfully'):t('Candidates - Add - New candidate added successfully'));
            
        }
        catch(err){
            console.error(err)
        }
        
    }

    getExtraHRFields(){
        if (this.props.admin && this.props.admin.org.config && this.props.admin.org.config["custom-hr-properties"]){
            return this.props.admin.org.config["custom-hr-properties"]
        }
        else return false
    }

    setFixedFields(){
        if (this.props.admin && this.props.admin.account && !RoleManager.block(this.props.admin.account.role,RoleManager.ONLY_INVITATIONS_ACCESS)){
            return
        }
        const thisRef = this
        if (this.props.admin && this.props.admin.org.config && this.props.admin.org.config["fixed-hr-fields"]){
            this.props.admin.org.config["fixed-hr-fields"].map(function(fld){
                thisRef.setState({[fld.name]:fld.value})
            })
        }
    }
    getFixedFields(val){
        
        if (this.props.admin && this.props.admin.account && !RoleManager.block(this.props.admin.account.role,RoleManager.ONLY_INVITATIONS_ACCESS)){
            return false
        }
        if (this.props.admin && this.props.admin.org.config && this.props.admin.org.config["fixed-hr-fields"]){
            if (val){
                const ind = this.props.admin.org.config["fixed-hr-fields"].findIndex(({ name }) => name === val )
                if (ind > -1){
                    return this.props.admin.org.config["fixed-hr-fields"][ind]
                }
            }
            else{
                return this.props.admin.org.config["fixed-hr-fields"]
            }
        }
        else return false
    }

    changeCustomField(name, val){
        this.setState({[""+name]:val.target.value})
    }

    updateCVFile(file){
        this.setState({cvFile:file})
    }

    clearCV(){
        this.setState({CvText:null})
    }
    closeCV(){
        this.setState({CVDialog:null})
    }
    openCVDialog(){
        this.setState({CVDialog:<CVDialog cv={this.state.CvFileName} hrId={this.state.hrId} open={true} closeDialog={this.closeCV} ></CVDialog>})
    }
    changePosition(){
        return
    }
    getPartialPos(event){
     
        const newValue = event.target.value
        if (newValue){
            if (typeof newValue === 'string') {
                if (this.state.positions && this.state.positions.findIndex(({ for_position }) => for_position === newValue ) > -1){
                    const indPos = this.state.positions.findIndex(({ for_position }) => for_position === newValue )
                    this.setState({selectedPosition: this.state.positions[indPos],  selectedTeam: this.state.positions[indPos].team})
                }
                else{
                    console.log("in here")
                    this.setState({selectedPosition:{id:0,for_position:newValue}})
                }
            } 
            else {
                console.log("NEW VALUE IS NOT STRING")
                this.setState({selectedPosition: newValue, selectedTeam: newValue.team})
            }
        }
    }
    handleTabChange(event, val){
        this.setState({tabvalue:val})
    }
    openUploadCV(){
        this.setState({uploadOpen:true});
        this.setState({uploadDialog:<UploadCVDialog open={true} closeDialog={this.handleCloseUpload} refresh={this.refresh} teams={this.state.teams} positions={this.state.positions}/>})
    }
    handleCloseUpload(){
        this.setState({uploadOpen:false});
        this.setState({uploadDialog:null})
    }

    updateInterviews(interviews){
        this.setState({interviews})
    }

    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
        const thisRef = this
        return (
            <div>

            <React.Fragment key={i18n.dir()==='rtl'?'left':'right'}>
           
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:800}}>
            <div style={{width:800}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={8} justify="left" style={{paddingBottom:20, paddingInlineStart:10}}>
                                {this.state.hrId?
                                    <Typography variant={'h1'}> {this.state.context === 'employee'?t('Employees - Add - Edit Employee'):t('Candidates - Add - Edit Candidate')}  </Typography>
                                :
                                    <Typography variant={'h1'}> {this.state.context === 'employee'?t('Employees - Add - Add New Employee'):t('Candidates - Add - Add New Candidate')}  </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} justify="end" style={{textAlign:"end"}} >
                                {!this.state.hrId && !(this.state.context==='employee')?
                                    <EgButton secondary="true" outlined="true" className={classes.actionButton} onClick={this.openImport}>{t('Candidates - Add - Import')} </EgButton>:''
                                }       
                            </Grid>
                            {this.state.hrId?
                            <Grid item xs={12}>
                            <AntTabs value={this.state.tabvalue} indicatorColor="primary" textColor="primary" onChange={this.handleTabChange} aria-label="hr_options" style={{fontSize:12}}>
                                    <AntTab label={t('HR - Personal Details')}  />
                                    <AntTab label={t('HR - Interview Summary')}  />
                            </AntTabs>
                            </Grid>
                            :''}
                            {this.state.tabvalue === 0?
                            <Grid item xs={12}><Grid container>
                            <Grid item xs={6} className={classes.formRow} style={{paddingTop:20}}>
                                <Typography variant={'h5'}>{t('Candidates - Add - First Name')}</Typography>
                                <TextField size="small" autoComplete="FirstName" name="FirstName" variant="outlined" required fullWidth='true' id="firstName" label="" value={this.state.firstName} onChange={this.handleChange} onBlur={this.validatefName}
                                    error={this.state.fnameVal?false:true}
                                    helperText={this.state.fnameVal?"":t('Candidates - Add - Please enter a valid first name')}
                                    className={classes.signupTxt}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.formRow} style={{paddingTop:20}}>
                                <Typography variant={'h5'}>{t('Candidates - Add - Last Name')}</Typography>
                                <TextField size="small" autoComplete="lastname" name="LastName" variant="outlined" required fullWidth='true' id="lastName" label="" value={this.state.lastName} onChange={this.handleChange} onBlur={this.validatelName}
                                    error={this.state.lnameVal?false:true}
                                    helperText={this.state.lnameVal?"":t('Candidates - Add - Please enter a valid last name')}
                                    style={{textAlign:'start'}}
                                    className={classes.signupTxt}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Candidates - Add - Email')}</Typography>
                                <TextField size="small" autoComplete="email" name="Email" variant="outlined" required fullWidth='true' id="email" label="" value={this.state.email} onChange={this.handleChange} onBlur={this.validateEmail}
                                    error={this.state.emailVal?false:true}
                                    helperText={this.state.emailVal?"":t('Candidates - Add - Please enter a valid Email')}  
                                    className={classes.signupTxt} 
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Candidates - Add - Phone')}</Typography>
                                <PhoneInput
                                    value={this.state.phone}
                                    onChange={this.setPhone}
                                    inputClass={classes.phoneText}
                                    dropdownClass={classes.dropdownClass1}
                                    placeholder={''}
                                    style={{direction:'ltr'}}
                                    className={classes.signupTxt}
                                    country={{'he':'il', 'en':'us'}[LangPicker.current()]}
                                />
      
                            </Grid>
                            
                            <Grid item xs={6} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Candidates - Add - Position')}</Typography>
                                <FormControl fullWidth>
                                            <InputLabel id="selectedModelInp"></InputLabel>
                                            <Autocomplete size="small" onBlur={this.getPartialPos}
                                                disabled={ this.getFixedFields() && this.getFixedFields("selectedPosition")?true:false}
                                                value={this.state.selectedPosition}
                                                onChange={(event, newValue) => {
                                                    console.log(newValue)
                                                    if (newValue){
                                                        if (typeof newValue === 'string') {
                                                            if (this.state.positions && this.state.positions.findIndex(({ for_position }) => for_position === newValue ) > -1){
                                                                const indPos = this.state.positions.findIndex(({ for_position }) => for_position === newValue )
                                                                this.setState({selectedPosition: this.state.positions[indPos],  selectedTeam: this.state.positions[indPos].team})
                                                            }
                                                            else{
                                                                this.setState({selectedPosition:{id:0,for_position:newValue}})
                                                            }
                                                        } 
                                                        else {
                                                            this.setState({selectedPosition: newValue, selectedTeam: newValue.team})
                                                        }
                                                    }
                                                    
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                id="positionsAutoComp"
                                                options={this.state.positions}
                                                getOptionLabel={(option) => {
                                                    if (typeof option === 'string') {
                                                    return option;
                                                    }
                                                    if (option.inputValue) {
                                                    return option.inputValue;
                                                    }
                                                    return option.for_position;
                                                }}
                                                renderOption={(option) => (option.for_position + ""+ (option.teamName?(" (" + option.teamName + ")"):"" ) )}
                                                style={{ width: '100%' }}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params} label="" variant="outlined" />
                                                )}
                                                />
                                        </FormControl>
                                {this.state.modelErrTxt}
                            </Grid>  
                            
                            <Grid item xs={6} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Teams - Team')}</Typography>
                                <FormControl size="small" style={{width:'100%'}}>
                                    <InputLabel id="teamsLbl"></InputLabel>
                                    <Select variant="outlined" labelId="teamsLbl" id="selectedTeam" name="selectedTeam" value={this.state.selectedTeam} onChange={this.selectTeam} >
                                        <MenuItem value={0}>&nbsp;</MenuItem>
                                        {this.state.teams? this.state.teams.map(function(t) {
                                            return <MenuItem value={t.id}>{t.name}</MenuItem>
                                        }):null}       
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            {this.state.context === 'employee'?
                            <Grid item xs={12} className={classes.formRow}>
                                <Typography variant={'h5'}>{t('Employees - Table - Started')}</Typography>
                                    <TextField size={'small'} autoComplete="started_at" name="StartedAt" type='date' variant="outlined" required fullWidth='true' id="startDate" label="" value={this.state.startDate} onChange={this.handleChange}  className={classes.signupTxt}>
                                </TextField>
                            </Grid>:''}

                            {this.getExtraHRFields()? 
                                    this.getExtraHRFields().map(function(field,index) {
                                    return(
                                        <Grid item xs={6} className={classes.formRow}>
                                        <Typography variant={'h5'}>{field.name}</Typography>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id={"selectedField"+index}></InputLabel>
                                            <Select variant="outlined" labelId="field-label" className={classes.selectBox} size={'small'}
                                                id={field.name}
                                                name={field.name}
                                                disabled={ thisRef.getFixedFields() && thisRef.getFixedFields(field.name)?true:false}
                                                value={thisRef.getFixedFields() && thisRef.getFixedFields(field.name)? thisRef.getFixedFields(field.name).value:thisRef.state[field.name]?thisRef.state[field.name]:null}
                                                onChange={(val)=>{thisRef.changeCustomField(field.name, val)}}
                                            >
                                            {field.values?
                                                field.values.map(function(val) {
                                                    return <MenuItem value={val} >{val}</MenuItem>
                                                }):''
                                            }    
                                            </Select>
                                        </FormControl>
                                        </Grid>
                                    )}):
                                    ''
                            }
                            {this.state.hrManagedProps? 
                                    this.state.hrManagedProps.map(function(field,index) {
                                    return(
                                        <Grid item xs={6} className={classes.formRow}>
                                            <Typography variant={'h5'}>{field.name}</Typography>
                                            <TextField size={'small'} name={field.name} variant="outlined" disabled fullWidth='true' id={field.name} label="" value={field.value}
                                                className={classes.signupTxt} 
                                            />
                                        </Grid>
                                    )}):
                                    ''
                            }

                            <Grid item xs={12} className={classes.formRow}>
                                {this.state.CvText?
                                    <Grid container>
                                        <Grid item xs={12} ><Typography variant={'h5'}>{t('Candidates - Add - Candidate CV file')}</Typography> </Grid>
                                        <Grid item xs={8}>
                                            <TextField size="small" autoComplete="cvfile" name="cvfile" variant="outlined" required fullWidth='true' id="cvfile" label="" value={this.state.CvFileName && Object.keys(this.state.CvFileName).length > 0 ?this.state.CvFileName.substring(this.state.CvFileName.lastIndexOf('/')+1):''} disabled className={classes.signupTxt} />
                                        </Grid>
                                        <Grid item xs={4} style={{paddingInlineStart:20, paddingTop:5}}>
                                            <EgButton  onClick={this.openCVDialog}>{t('Candidates - Add - Open CV')}</EgButton> &nbsp;&nbsp;
                                            <EgButton outlined="true" secondary="true" onClick={this.clearCV}>{t('Candidates - Add - Replace CV')}</EgButton>    
                                        </Grid>
                                    </Grid>:
                                    <UploadCV updateCVFile={this.updateCVFile} />
                                }
                            </Grid>
                            
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton className={classes.actionButton} onClick={this.saveHumanResource}>{t('Candidates - Add - Save')}</EgButton>
                                <EgButton outlined="true" secondary="true"  className={classes.actionButton} onClick={this.closeDrawer}>{t('Candidates - Add - Cancel')}</EgButton>   
                            </Grid>
                            {!this.state.hrId?
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}><Divider style={{marginTop:20, marginBottom:20}}/></Grid>
                                        <Grid item xs={8} className={classes.formRow}>
                                            <Typography variant={'h4'} style={{paddingTop:20, textAlign:'end'}}>{t('Candidates - Add - Want to add new candidate directly from CV?')}</Typography>
                                        </Grid>
                                        <Grid item xs={2} className={classes.formRow}>
                                            <a href='#0'>
                                            <Paper className={classes.importOptionPaper} onClick={this.openUploadCV}> 
                                                <img src={cvimg} className={classes.importIcon} alt="logo" />  
                                            </Paper>
                                            </a>
                                            {this.state.uploadDialog}
                                        </Grid>
                                        <Grid item xs={2} />
                                    </Grid>
                                </Grid> :''}
                            </Grid></Grid>
                             :
                             <Grid item xs={12} style={{paddingTop:20}}>
                                <InterviewsAccordion interviews={this.state.interviews} updateInterviews={this.updateInterviews}/>
                                   
                                <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                    <EgButton className={classes.actionButton} onClick={this.saveHumanResource}>{t('Candidates - Add - Save')}</EgButton>
                                    <EgButton outlined="true" secondary="true"  className={classes.actionButton} onClick={this.closeDrawer}>{t('Candidates - Add - Cancel')}</EgButton>   
                                </Grid>
                                </Grid>
                        }
                        </Grid>
                </Paper>
            </div>
            </Drawer>
            </React.Fragment>
            {this.state.CVDialog}
        </div>    
    );
  }
}
AddHumanResource2.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(AddHumanResource2),AddHumanResource2)))