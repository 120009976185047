import i18n from '../../i18n/i18n'

export const EngagementLevels = {

    get: function(){
    return(
        [
            {id:1, name: i18n.t('EngagementLevels - Low'), color:"#F15D5D"},
            {id:2, name: i18n.t('EngagementLevels - Med'), color:"#FCBF54"},
            {id:3, name: i18n.t('EngagementLevels - High'), color:"#11D6A7"},
            {id:4, name: i18n.t('EngagementLevels - Unknown'), color:"#EEEEEE"},
        ]
    )},
    getSummaryText: function(sev){
        const summaryArr = [
            i18n.t('EngagementLevels - Low'),
            i18n.t('EngagementLevels - Med'),
            i18n.t('EngagementLevels - High'),
        ]
        return summaryArr[sev]
    },
}

