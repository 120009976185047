import i18n from '../../i18n/i18n'

export const ValidityLevels = {

    get: function(){
    return(
        [
            {id:0, name: i18n.t('ValidityLevel - Valid'), color:"#11D6A7"},
            {id:1, name: i18n.t('ValidityLevel - Borderline'), color:"#FCBF54"},
            {id:2, name: i18n.t('ValidityLevel - Invalid'), color:"#F15D5D"},
            {id:-1, name: i18n.t('ValidityLevel - Unknown'), color:"#EEEEEE"},
        ]
    )},
    getSummaryText: function(sev){
        const summaryArr = [
            i18n.t('ValidityLevel - Summary Valid'),
            i18n.t('ValidityLevel - Summary Borderline'),
            i18n.t('ValidityLevel - Summary Invalid'),
            i18n.t('ValidityLevel - Summary Unknown')
        ]
        return summaryArr[sev]
    },

    VALID: 0,
    BORDERLINE: 1,
    INVALID: 2,
    UNKNOWN: 3
}