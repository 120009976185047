import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  login:null
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLogin: (state, loginobj) => {
        state.login = loginobj;  
    },
    setLogout: (state) => {
        state.login = null;
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { setLogin, setLogout } = loginSlice.actions

export default loginSlice.reducer