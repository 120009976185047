import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, MenuItem, Select } from '@material-ui/core';
import { connect } from "react-redux";
import { setAdminDetails } from "../../redux/admin";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import EgStatusAlert from '../../components/EgStatusAlert';
import EgHttp from '../../util/EgHttp';
import EgButton from '../../components/EgButton';
import LangPicker from '../../util/LangPicker';

const mapStateToProps = state => ({
    admin: state.admin.value
});
  
const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:10,
        paddingInlineStart:30,
    },
    actionButton:{
        width: 100,
        margin: 5
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
    inputLabel:{
        height:30
    },
    link:{
        textDecoration:"none",
        color:theme.palette.text.black
    },
    selectBox:{
        width:200,
        '& .MuiOutlinedInput-input':{
          paddingTop:8,
          paddingBottom:8, 
          fontSize:16,
          
        },
    }
});
  
class ConfigurePage extends React.Component {
  
    state = {
        rows:{} ,
        org: this.props.admin.org.name, 
        videoRequired: this.props.admin.org.video_required?1:0,
        timeLimit: this.props.time_limit?this.props.time_limit:30,
        changeTimeLimit: this.props.admin.org.time_limit,
        language: LangPicker.current()
    };

    constructor(props) {
        super(props);  
        this.saveConfigure = this.saveConfigure.bind(this); 
        this.handleStatusClose= this.handleStatusClose.bind(this)
        this.changeVideo= this.changeVideo.bind(this)
        this.changeTimeLimit = this.changeTimeLimit.bind(this)
        this.changeLang = this.changeLang.bind(this)
        
        if (!this.props.admin.logged) {
        this.props.history.push('/signup') 
        } 

        this.getOrg()
    }

    changeVideo(event){
        this.setState({videoRequired:event.target.value})
    }
    changeTimeLimit(event){
        this.setState({timeLimit:event.target.value})
    }
    changeLang(event){
        this.setState({language:event.target.value})
    }

    async getOrg(){
        this.props.setProg(true)
        let response = await EgHttp.get('/accounts/org/details/get')
        let rowsres = await response.json();
        this.setState({rows:rowsres});
        this.setState({timeLimit:rowsres.time_limit?rowsres.time_limit:30, videoRequired:rowsres.video_required!==null?rowsres.video_required:1, language:rowsres.language?rowsres.language:'en'})
        this.props.setProg(false);
        return;
    }

    async saveConfigure(){
            
        await EgHttp.put('/accounts/org/details/set', JSON.stringify({
                language: this.state.language,
                videoRequired: this.state.videoRequired,
                timeLimit:this.state.timeLimit
        }))
        .catch((error) => {
            this.showStatusBar('Failed to update profile details');
            return;
        });
          
        this.showStatusBar('Organization details updated successfully!');
        this.props.history.push('/signin?lang=' + this.state.language)
        return;

    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }
    handleStatusClose(){
        this.setState({statusBar:null})
    }
      
  
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <Grid container>
                <Grid container>
                    <Grid xs={12} style={{paddingInlineStart:30, paddingTop:'2vh',paddingBottom:'2vh'}}>
                        <Typography variant={'h2'}>{t('Settings - Configure - Configure')}</Typography>
                    </Grid>
                    {/*----------------- System Language -------------------*/}
                    <Grid item xs={3} className={classes.formRow} style={{paddingTop:15}}>
                        <Typography variant={'h4'} style={{textAlign:'start'}}>{t('Settings - Configure - System Language')}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.formRow} style={{paddingTop:5, textAlign:'start'}}>
                        <Select onChange={this.changeLang} value={this.state.language} variant="outlined" className={classes.selectBox} >
                            <MenuItem value={'en'}>{LangPicker.current()==='he'?'אנגלית':'En'}</MenuItem>
                            <MenuItem value={'es'}>{LangPicker.current()==='he'?'ספרדית':'Es'}</MenuItem>
                            <MenuItem value={'he'}>{LangPicker.current()==='he'?'עברית':'He'}</MenuItem>
                            <MenuItem value={'pt'}>{LangPicker.current()==='he'?'פורטוגזית':'Pt'}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}></Grid>
                    
                     {/*----------------- Video Required -------------------*/}
                     <Grid item xs={3} className={classes.formRow} style={{paddingTop:15}}>
                        <Typography variant={'h4'} style={{textAlign:'start'}}>{t('Settings - Configure - Video Required')}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.formRow} style={{paddingTop:5, textAlign:'start'}}>
                        <Select onChange={this.changeVideo} value={this.state.videoRequired} variant="outlined" className={classes.selectBox} >
                            <MenuItem value={1}>{t('General - Yes')}</MenuItem>
                            <MenuItem value={0}>{t('General - No')}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}></Grid>
                    
                     {/*----------------- Initial assessment Time limit  -------------------*/}
                     <Grid item xs={3} className={classes.formRow} style={{paddingTop:15}}>
                        <Typography variant={'h4'} style={{textAlign:'start'}}>{t('Settings - Configure - Assessment time limit')}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.formRow} style={{paddingTop:5, textAlign:'start'}}>
                        <Select onChange={this.changeTimeLimit} value={this.state.timeLimit} variant="outlined" className={classes.selectBox} >
                            <MenuItem value={30}>{t('Settings - Configure - 30 minutes')}</MenuItem>
                            <MenuItem value={45}>{t('Settings - Configure - 45 minutes')}</MenuItem>
                            <MenuItem value={60}>{t('Settings - Configure - 60 minutes')}</MenuItem>
                            <MenuItem value={90}>{t('Settings - Configure - 90 minutes')}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6} className={classes.formRow} style={{paddingTop:15}}></Grid>
                    <Grid item xs={6} justify="center" style={{textAlign:"center", paddingTop:15}} >
                        <EgButton className={classes.actionButton} onClick={this.saveConfigure}>{t('Settings - Profile - Save')}</EgButton> 
                    </Grid>
                </Grid>
                {this.state.statusBar}
            </Grid>
        );
    }
}
ConfigurePage.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(ConfigurePage),ConfigurePage)))