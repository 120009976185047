import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import ReportsManager from '../../managers/ReportsManager';
import EgAssessmentValidity from '../../components/EgAssessmentValidity';
import ResponseTimeAnalysis from './validity/ResponseTimeAnalysis';
import SocialDesirabiityAnalysis from './validity/SocialDesirabiityAnalysis';
import ResponsePatternsAnalysis from './validity/ResponsePatternsAnalysis';
import ResponseDiscrepanciesAnalysis from './validity/ResponseDiscrepanciesAnalysis';
import VideoScreenAnalysis from './validity/VideoScreenAnalysis';
import EgHttp from '../../util/EgHttp';



const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
    
    boxTitle:{
        padding:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:'2vh'
    },
    boxTitleInd:{
        paddingInline:20,
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    accord:{
        //display:'flex', 
        //flexDirection:'column', 
       // alignItems:'center'
    }
    
  });

class SingleReportValidity extends React.Component {
  
    constructor(props) {
        super(props); 
        this.intializeData = this.intializeData.bind(this);     
        this.handleChange = this.handleChange.bind(this) 
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:null,
        overallValidity:0
    };
    
    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
    }
    
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    intializeData(report, reportParts){
        this.setState({report})
        console.log(report)
        this.setState({reportParts})
        if (!report) return
        const traitsArr = ReportsManager.getTraitsFromReport(report)
        this.setState({traitsTimes:traitsArr })
        ReportsManager.setTimeSummary(report, reportParts) 
        if (reportParts){
            const timeRisk = ReportsManager.setTimeRisk(reportParts);
            this.setState({timeSeverity: timeRisk})
        }
        if (report.dominant_traits && report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
        
        this.setState({overallValidity:report.validity_obj?report.validity_obj.overallRisk:-1})
        this.checkVideoValidity(report)
       
    }
    
   async checkVideoValidity(report){
        try{
            let response = await EgHttp.get('/reports/framesanalysis/' + report.assessment.id + '/1000');
            if (response.status === 401) {
              this.props.history.push('/signin') 
              return;
            }
            let rowsres = await response.json();
            this.setState({videoIssues:rowsres});
            console.log(rowsres)
            return;
          }
          catch(err){
            console.error(err)
            return
          }
    }
    


    handleChange(event, newExpanded){
        this.setState({expanded:this.state.expanded===newExpanded?null:newExpanded})
    }

   
   
    
    render() {
        const { t } = this.props;
        const { classes } = this.props;
        
        return (
            
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0}  className={classes.summaryBox} >

                <Grid container>
                    <Grid xs={10} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Reports - Single - Assessment Validity')} </Typography>
                    </Grid>
                    <Grid xs={2} className={classes.boxTitleInd}>
                        
                    </Grid>
                </Grid>
                <Grid container style={{paddingBotom:'20vh'}}>
                    <Grid xs={12} style={{display:'flex', justifyContent:'center', paddingBottom:'2vh'}}>
                        <Grid container style={{width:'100%' ,marginBottom:'1vh',display:'flex', justifyContent:'center',paddingTop:'3vh', paddingInlineStart:20, paddingInlineEnd:50 }}>
                            <Grid xs={4} style={{paddingInline:10, display:'flex', alignItems:'center', justifyContent:'end'}} />
                            <Grid xs={4} style={{display:'flex', alignItems:'center'}}>
                                <Typography variant={'h4'} style={{textAlign:'center', fontWeight:600,fontSize:16 }}>
                                    {t('Reports - Single - Overall Assessment Validity Score')}
                                </Typography>
                            </Grid>
                            <Grid xs={4} style={{display:'flex', alignItems:'center'}}>
                                <EgAssessmentValidity severity={this.state.overallValidity} width={200}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* RESPONSE TIME ANALYSIS */}
                <ResponseTimeAnalysis report={this.state.report} reportParts={this.state.reportParts} handleChange={this.handleChange} expanded={this.state.expanded}/>
                
                {/* SOCIAL DESIRABILIY ANALYSIS */}
                <SocialDesirabiityAnalysis report={this.state.report} reportParts={this.state.reportParts} handleChange={this.handleChange} expanded={this.state.expanded}/>
                
                {/* RESPONSE PATTERNS ANALYSIS */}
                <ResponsePatternsAnalysis report={this.state.report} reportParts={this.state.reportParts} handleChange={this.handleChange} expanded={this.state.expanded}/>
                
                {/* DISCRAPENCIES ANALYSIS */}
                <ResponseDiscrepanciesAnalysis report={this.state.report} reportParts={this.state.reportParts} handleChange={this.handleChange} expanded={this.state.expanded}/>
                
                {/* VIDEO AND SCREEN RECORDING ANALYSIS*/}
                <VideoScreenAnalysis report={this.state.report} reportParts={this.state.reportParts} handleChange={this.handleChange} expanded={this.state.expanded} videoIssues={this.state.videoIssues}/>
                
            </Paper>
            </Grid>
        </div>
    );
  }
}
SingleReportValidity.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportValidity),SingleReportValidity);