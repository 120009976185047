import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Grid, Toolbar, Typography } from '@material-ui/core';
import EgProgress from '../components/EgProgress';
import { withRouter } from 'react-router-dom';
import AssessSignin from './assessment/AssessSignin';
import AssessOpener from './assessment/AssessOpener';
import QuestionPanel from './assessment/QuestionPanel';
import VideoManager from '../util/VideoManager';
import EventLog from '../util/EventLog';
import AssessScreenRec from './assessment/AssessScreenRec';
import AccessTimeIcon from '@material-ui/icons/TimerOff';
import { withTranslation } from 'react-i18next';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import LangPicker from "../util/LangPicker"
import GenericErrorDialog from '../components/GenericErrorDialog'
import EgHttp from '../util/EgHttp';
import EgFirebase from '../util/EgFirebase'
import assessBg from '../img/assessment_bg.svg';
import noMike from '../img/mike.png';
import AssessAppBar from './assessment/AssessAppBar';
import AssessReady from './assessment/AssessReady';
import qs from 'qs';
import * as AppConstants from '../util/constants/AppConstants';
import AssessVideoRec from './assessment/AssessVideoRec';
import AgoraRTC from 'agora-rtc-react';
import PoweredByFooter from '../components/PoweredByFooter';
import EgStatusAlert from '../components/EgStatusAlert';
import LogRocket from 'logrocket';
import AppUtils from '../util/AppUtils';
import RemoteInterviewPanel from './assessment/RemoteInterviewPanel';
import AssessmentFinished from './assessment/AssessmentFinished';
import EgActivityRecorder from '../util/EgActivityRecorder';
import AssessCustomDetails from './assessment/AssessCustomDetails';
import CameraErrorDialog from '../components/CameraErrorDialog';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },

  content: {
    flexGrow: 1,
    width: '100%',
    height: window.innerHeight,
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${assessBg})`,
  },

  note: {
    marginTop: 0,
    marginBottom: 40
  },
  latestNote: {
    marginTop: 0,
    marginBottom: '3vh'
  },
  latestQNote: {
    marginTop: 80,
    marginBottom: 20
  },
  timer: {
    color: '#0097A7',
    fontSize: 24,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,

  },
  appRight: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      background: theme.palette.whitebg,
    },
    boxShadow: "0px 0px 0px"
  },
  appLeft: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      background: theme.palette.whitebg,
    },
    boxShadow: "0px 0px 0px"

  },

});

const STEP_NOT_SIGNED = 0;
const STEP_SIGNED_IN = 1;
const STEP_VIDEO_CAPTURE = 2;
const STEP_SCREEN_CAPTURE = 3;
const STEP_ARE_YOU_READY = 4;
const STEP_QUESTIONS = 5;
const STEP_TIMEOUT = 6;
const STEP_REMOTE_INTERVIEW = 7;
const STEP_FINISHED = 99;
const STEP_CUSTOM_DETAILS = 98;


class Assessment extends React.Component {
  state = {
    open: this.props.open,
    rows: [],
    loading: null,
    assess_step: STEP_NOT_SIGNED,
    startstop: true,
    startTime: 0,
    errDialog: null,
    errDialogOpen: false,
    requireVideo: false,
    initialTestTime: AppConstants.AssessmentTime,
    agoraTokens: null,
    screenAndVideoGoodToGo: true,
    screenClient: null,
    screenVideoTrack: null,
    cameraClient: null,
    cameraVideoTrack: null,
    videoApproved: false,
    recording: false,
    videoFrameNotValidated: true
  };

  constructor(props) {
    super(props);

    this.signin = this.signin.bind(this);
    this.resend = this.resend.bind(this);
    this.handleStatusClose = this.handleStatusClose.bind(this);
    this.showStatusBar = this.showStatusBar.bind(this);
    this.startAssessessment = this.startAssessessment.bind(this);
    this.moveToNextQuestion = this.moveToNextQuestion.bind(this);
    this.moveToPreviosQuestion = this.moveToPreviosQuestion.bind(this);
    this.completeAssessment = this.completeAssessment.bind(this);
    this.saveResponse = this.saveResponse.bind(this);
    this.setAnswer = this.setAnswer.bind(this);
    this.moveToScreenState = this.moveToScreenState.bind(this)
    this.closeErrorDialog = this.closeErrorDialog.bind(this)
    this.updateTimeLeft = this.updateTimeLeft.bind(this)
    this.setScreenAndVideoGoodToGo = this.setScreenAndVideoGoodToGo.bind(this)
    this.startCamera = this.startCamera.bind(this)
    this.startAudio = this.startAudio.bind(this)
    this.startScreenCapture = this.startScreenCapture.bind(this)
    this.stopBroadcastingCamera = this.stopBroadcastingCamera.bind(this)
    this.stopBroascastingScreen = this.stopBroascastingScreen.bind(this)
    this.stopRecordingAssessmentQuestions = this.stopRecordingAssessmentQuestions.bind(this)
    this.moveToNextAssessStep = this.moveToNextAssessStep.bind(this)
    this.startRecordingAssessmentQuestions = this.startRecordingAssessmentQuestions.bind(this)
    this.startRecording = this.startRecording.bind(this)
    this.startBroadcastingCamera = this.startBroadcastingCamera.bind(this)
    this.startRecordingRemoteInterviewQuestion = this.startRecordingRemoteInterviewQuestion.bind(this)
    this.stopRecordingInterviewResponse = this.stopRecordingInterviewResponse.bind(this)
    this.handleAssessmentTimeout = this.handleAssessmentTimeout.bind(this)
    this.checkVideoFrame =this.checkVideoFrame.bind(this)
    this.closeErrorDialogAndRestartRemoteInterview = this.closeErrorDialogAndRestartRemoteInterview.bind(this)
    
    LangPicker.resolve(this.props)
    this.getCandIdFromQS()
    this.setState({ loading: null })
    LogRocket.info('Assessment Constructor')
    
 
  }

  closeErrorDialog() {
    this.setState({ errDialogOpen: false });
    this.setState({ errDialog: null });
  }
  closeErrorDialogAndRestartRemoteInterview(){
    this.setState({ errDialogOpen: false });
    this.setState({ errDialog: null });
    this.moveToScreenState(STEP_REMOTE_INTERVIEW)

  }

  getCandIdFromQS() {
    let qq = this.props.location.search;
    const params = new URLSearchParams(qq);
    const cand_id = params.get('id');
    this.setState({ cand_id: cand_id })
    return cand_id
  }

  handleStatusClose(){
    this.setState({statusBar:null})
  }
  showStatusBar(message){
    this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
  }

  async resend(){
    const { t } = this.props;
      try{
            this.setState({ loading: <EgProgress />, })
            let response = await EgHttp.post('/assess/sms', JSON.stringify({
                cand_id: this.getCandIdFromQS(),
                orgId: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).o
            }), true)
           
            if (response.status === 200) {
                this.showStatusBar(t("General - SMS Sent Successfully"))
            }
            else {
              this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialog} open={true} title={t("Assessment - Signin - Wrong Code")} message={t("Assessment - Signin - The assessment code is incorrect")}></GenericErrorDialog> })
              LogRocket.info('Showing error dialog', {'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id, 'message': 'Assessment - Signin - The assessment code is incorrect'})
              return;
            }
          return;
      }
      catch(err){
        this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("General - Error")} message={t("General - There was an error loading the page")}></GenericErrorDialog>})
        LogRocket.error("Failed to resend code", {'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id, 'error': err})
        LogRocket.info("Showing error dialog", {'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id, 'message': 'General - There was an error loading the page'})
      }
  
  }

  async moveToScreenState(screenState){
    switch(screenState){
        case STEP_NOT_SIGNED:  
            break;
        case STEP_SIGNED_IN:
            this.setState({ assess_step: STEP_SIGNED_IN });
            break;
        case STEP_CUSTOM_DETAILS:
            if (this.state.orgConfig && this.state.orgConfig.hr_managed_properties && this.state.orgConfig.hr_managed_properties.length>0){
              this.setState({ assess_step: STEP_CUSTOM_DETAILS });
              break;
            }
            else{
              this.moveToScreenState(STEP_VIDEO_CAPTURE)
              return
            }
        case STEP_VIDEO_CAPTURE:
            if (this.state.requireVideo) {
              EgActivityRecorder.updateContext(EgActivityRecorder.contexts.ASSESSMENT_CAMERA_SETUP, this.state.candidate.test_id)
              this.setState({ assess_step: STEP_VIDEO_CAPTURE });
              console.log('Starting camera at step STEP_VIDEO_CAPTURE')
              await this.startCamera()
            }
            else {
              if (this.state.skipAssessment) {
                this.moveToNextAssessStep()
              } else {
                this.moveToScreenState(STEP_ARE_YOU_READY)
              }
            }
            break;
        case STEP_SCREEN_CAPTURE:
            EgActivityRecorder.stopRecordingEvents(true)
            if (this.state.skipAssessment) {
              this.moveToNextAssessStep()
            } else {
              if (this.state.requireVideo && !AppUtils.isMobile()) {
                this.setState({ assess_step: STEP_SCREEN_CAPTURE });
              }
              else {
                this.moveToScreenState(STEP_ARE_YOU_READY)
              }  
            }
            break;
        case STEP_ARE_YOU_READY:
           EgActivityRecorder.updateContext(EgActivityRecorder.contexts.ASSESSMENT_ARE_YOU_READY, this.state.candidate.test_id)

            this.setState({ assess_step: STEP_ARE_YOU_READY });
            if (this.state.requireVideo) {
              await this.startScreenCapture(this.state.agoraTokens)
            }
            break;
        case STEP_REMOTE_INTERVIEW:
            EgActivityRecorder.updateContext(null, -1)
            console.log('Transitionng from assessment to interview --> Stop camera and screen capture, ask to stop recording.')
            this.stopRecordingAssessmentQuestions()
            console.warn('We do not wait for the stop record to respond')
            await this.stopBroascastingScreen()
            // await this.stopBroadcastingCamera()
            console.log('Starting AUDIO capture. Camera started at STEP_VIDEO_CAPTURE. Not broadcasting yet.')
            const ac = await this.startAudio()
            if (ac) {
              console.log('Audio started for remote interview')
            }
            this.setState({assess_step: STEP_REMOTE_INTERVIEW});
            break;
        case STEP_TIMEOUT:
          this.setState({ assess_step: STEP_TIMEOUT });
          this.handleAssessmentTimeout()
          break;
        case STEP_FINISHED:
          this.setState({ assess_step: STEP_FINISHED });
          break;
        default: 
            this.setState({ assess_step:STEP_NOT_SIGNED})
    }
}

  async handleAssessmentTimeout() {
    console.log('Assessment time is over. Flagging assessment and HR as timed out.')
    EgActivityRecorder.stopRecordingEvents()
    EgFirebase.logTimeoutAssessment()
    this.stopBroadcastingCamera()
    this.stopBroascastingScreen()
    this.stopRecordingAssessmentQuestions()

    const assid = this.state.candidate.test_id
    const body = JSON.stringify({
      timedOut: true,
      hrId: this.state.candidate.hr_id
    })
    const assResponse = await EgHttp.put(`/assess/assessmentstatus/ass_id/${assid}`, body, true)
    const ressData = await assResponse.json()
    console.log('Assessment timed out:')
    console.dir(ressData)
  }

  async getOrgLegal(){
    try {
      const query = '/assess/legal'
      let response = await EgHttp.get(query, true)
      let rowsres = await response.json()
      this.setState({orgLegal: rowsres.legal})
    }
    catch(err) {
      return null
    }
  }

  async getOrgConfig(){
    try {
      this.setState({ loading: <EgProgress />, })
      const query = '/assess/config'
      let response = await EgHttp.get(query, true)
      let rowsres = await response.json();
      this.setState({orgConfig: rowsres.config})
      this.setState({ loading: null })
    }
    catch(err) {
      this.setState({ loading: null, })
      return 0
    }
  }

  async signin(test_code) {
    const { t } = this.props;
    try {
      this.setState({ loading: <EgProgress />, })
      let response = await EgHttp.post('/assess/validate', JSON.stringify({
        cand_id: this.getCandIdFromQS(),
        token: test_code,
        orgId: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).o
      }), true)

      let rowsres = await response.json();
      this.setState({ loading: null, })
      if (rowsres && rowsres[0]) {
        const candidid = `o_${rowsres[0].org_id}__c_${rowsres[0].hr_id})`
        LogRocket.identify(candidid);
        LogRocket.info('Candidate successfully logged in', { "candidateId": candidid })

        if (rowsres[0].status === 2) { //Assessment already completed
          this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialog} open={true} title={t("Assessment - Signin - Assessment Completed")} message={t("Assessment - Signin - This assessment was already completed")}></GenericErrorDialog> })
          LogRocket.error('Candidate already finished the assessment', { candiate: rowsres[0].hr_id, assessment: rowsres[0].test_id })
          return;
        }
        EgFirebase.setUserOrg(rowsres[0].org_id)
        EgFirebase.setCandidateId(rowsres[0].hr_id)
        console.log(`Questionnaire id for remote interview: ${rowsres[0].questionnaire_id}`)
        this.setState({ candidate: rowsres[0], requireVideo: rowsres[0].video_required, requireFollowup:rowsres[0].followup_required, questionnaireId: rowsres[0].questionnaire_id });
        const numberOfQuestions = await this.getNumberOfQuestions()
        this.setState({skipAssessment: (numberOfQuestions === 0)})

        this.moveToScreenState(STEP_SIGNED_IN)
        EgActivityRecorder.updateContext(EgActivityRecorder.contexts.ASSESSMENT_START, this.state.candidate.test_id)
        this.framesValidatorTimer = setInterval(() => {
          if (this.state.question && this.state.question.id > 0) {
            const video_frame_sufix = `qid${this.state.question.id}`
            VideoManager.uploadVideoFrame(this.state['cameraVideoTrack'], this.state.candidate.test_id, video_frame_sufix)
          }
        }, 4500)
    

        if (rowsres[0].time_remaining) {
          this.setState({ initialTestTime: rowsres[0].time_remaining })
        }
        if (rowsres[0].last_answered_qid) {
          this.setState({ retakeQuestionId: rowsres[0].last_answered_qid })
        }
        console.error('Loading tokens even if not required, since the logic should be fixed')
        const tokens = await VideoManager.getTokens(rowsres[0])
        this.setState({ agoraTokens: tokens })  
        await this.getOrgLegal()
        await this.getOrgConfig()
      }
      else {
        this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialog} open={true} title={t("Assessment - Signin - Wrong Code")} message={t("Assessment - Signin - The assessment code is incorrect")}></GenericErrorDialog> })
        LogRocket.error('Candidate typed wrong code')
        return;
      }
      return;
    }
    catch (err) {
      LogRocket.captureMessage('Failed to get signin to assessment', {
        tags: {
          topic: 'ASSESS',
          stage: 'LOGIN'
        },
        extra: {
          error: err,
          issue: 'Candidate could not event begin the assessment.',
          userMsg: t("General - There was an error loading the page")
        },
      });
      LogRocket.error('Failed to get signin to assessment', { error: err })

      this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialog} open={true} title={t("General - Error")} message={t("General - There was an error loading the page")}></GenericErrorDialog> })
    }
  }

  async startAssessessment() {
    const { t } = this.props;
    try {
      if (this.state.skipAssessment) {
        console.log('SKIPING ASSESSMENT')
        this.moveToNextAssessStep()
      } else {
        EgFirebase.logStartAssessment()
        this.setState({ loading: <EgProgress /> })
        this.setState({ assess_step: STEP_QUESTIONS });
        EgActivityRecorder.updateContext(EgActivityRecorder.contexts.ASSESSMENT_QUESTIONS, this.state.candidate.test_id)
        console.log("Initializing assessment")
        await this.initializeAssessment();
        const question = await this.getNextAvailableQuestion()
        this.setState({ question })
        if (question.response && question.response.length) {
          this.setState({ currentAnswer: question.response[0].selected_answers })
        }
        else {
          this.setState({ currentAnswer: [] })
        }
        this.setState({ startTime: new Date().getTime() })
        this.setState({ loading: null })
        EventLog.insert(this.state.candidate.org_id, this.state.candidate.hr_id, EventLog.START_ASSESSMENT, 0, true);  
      }
    }
    catch (err) {
      LogRocket.error('Failed to start assessment', { error: err })
      this.setState({ loading: null })
      this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialog} open={true} title={t("General - Error")} message={t("General - There was an error loading the page")}></GenericErrorDialog> })
    }
    return
  }

  async startRecordingAssessmentQuestions() {
    const localThis = this
    // To fix a race condition this recursion retries every 50ms till data is ready
    // TODO: get rid of this recursion by synciing between state.question and start recording
    setTimeout(async function () {
      // Async start recording, so we can retry every 50 millisecs.
      if (!localThis.state || !localThis.state.question) {
        console.log('No question loaded yet, waiting')        
        localThis.startRecordingAssessmentQuestions()
        return
      }
      if (localThis.state.requireVideo && localThis.state.question.id !== -1) {
        console.log('START RECORDING ASSESSMENT QUESTIONS')
        // Load tokens for screen and camera recording
        let tokens = null
        if (localThis.state.requireVideo) {
          console.log('Get agora tokens to record assessment')
          tokens = localThis.state.agoraTokens
          console.log('Start BROADCASTING camera video track')
          const broadcasting = await localThis.startBroadcastingCamera(tokens, localThis.state.cameraVideoTrack)
          if (broadcasting) {
            LogRocket.info("Camera is ON AIR", {'candidate': localThis.state.candidate.hr_id, 'assessment': localThis.state.candidate.test_id})
            localThis.setScreenAndVideoGoodToGo(true)  
          } else {
            console.error('WE ARE NOT BROACASTING VIDEO, WE CANNOT RECORD THIS')
          }
    
          LogRocket.info(`AGORA - Agora RTC version: ${AgoraRTC.VERSION}`)
          LogRocket.info('AGORA - tokens for this session', {tokens: tokens})

          await localThis.startRecording(tokens)
        }
      } else {
        console.log(`Video is disabled, NOT RECORDING ASSESSMENT or question id is -1 -> ${JSON.stringify(localThis.state.question)}`)
      }                  
    }, 50); 
  }

  async startRecordingRemoteInterviewQuestion(question_id, interview_id) {
    console.log(`Getting agora tokens for recording question ${question_id} in interview ${interview_id}`)
    LogRocket.info(`START recording question ${question_id} in interview ${interview_id}`)
    let audioTrack = null
    if (this.state.audioTrack === null) {
      audioTrack = await this.startAudio()
    } else {
      audioTrack = this.state.audioTrack
    }
    
    const interviewAgoraToken = await VideoManager.getInterviewQuestionToken(this.state.candidate, interview_id, question_id)
    this.setState({agoraTokens: interviewAgoraToken})
    const broascasting = await this.startBroadcastingCamera(interviewAgoraToken, this.state.cameraVideoTrack, audioTrack)
    if (broascasting) {
      console.log('Broascasting Audio and Video - START recording')
      await this.startRecording(interviewAgoraToken, true)
    } else {
      const errorMsg = `Failed to start broascasting Video and Audio, interview question ${question_id} will not be recorded.`
      LogRocket.error(errorMsg)
      console.error(errorMsg)
    }
  }

  async startRecording(agoraTokens, recordSound=false) {
    // return
    // TODO: not catching failure 
    await VideoManager.startRecordingCam(agoraTokens, this.state.cameraVideoTrack, recordSound)
    // TEMP May 17 20022:  we are continuing with assessment, even if there are issues with screen recording
    if (this.state.screenClient) {
      await VideoManager.startRecordingScreen(agoraTokens)
    }
    this.setState({ recording: true })
  }

  async initializeAssessment() {
      try{
          const response = await EgHttp.put('/assess/initialize', JSON.stringify({
              assessmentId: this.state.candidate.test_id,
              assessmentType: this.state.candidate.assessment_type,
              hrId: this.state.candidate.hr_id
          }), true)
          const assessmentState = await response.json();
          this.setState({assessmentState: assessmentState}) 
          this.setState({ initialTestTime: assessmentState.time_remaining })
      }
      catch(err){
          console.error("Failed to initialize assessment")
          console.error(err)
          throw(err)
      }
      return;
  }

  async getNextAvailableQuestion(){
      const { t } = this.props;
      try{
          let query;
          if (LangPicker.current() === 'en') {
            query = '/assess/nextAvailableQuestion/aid/' + this.state.candidate.test_id + '/type/' + this.state.candidate.assessment_type
          }
          else {
            query = '/assess/nextAvailableQuestion/aid/' + this.state.candidate.test_id + '/type/' + this.state.candidate.assessment_type + '/lang/' + LangPicker.current() 
          }
          let response = await EgHttp.get(query, true)
          let rowsres = await response.json();
          return rowsres
      }
      catch(err){
        console.error("Failed to get next available question")
        console.error(err)
        this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("General - Error")} message={t("General - There was an error loading the page")}></GenericErrorDialog>})
      }
  }

  async getNumberOfQuestions (){
    try {
      
        const query = '/assess/totalNumberOfQuestions/aid/' + this.state.candidate.test_id + '/type/' + this.state.candidate.assessment_type;
        let response = await EgHttp.get(query, true)
        let rowsres = await response.json();
        console.log(`Found ${rowsres.result} questions for assessment ${this.state.candidate.test_id} - type ${this.state.candidate.assessment_type}`)
        return rowsres.result
    }
    catch(err) {
      console.error("Failed to get total number of questions")
      console.error(err)
      return 0
    }
}

  async moveToNextQuestion() {
      const { t } = this.props;
      try{
          this.setState({ loading: <EgProgress /> })
          const updatedAssessmentState = await this.saveResponse()
          this.setState({assessmentState:updatedAssessmentState})
          const question = await this.getNextAvailableQuestion()
          this.setState({question})
          //Assessment completed -> No more questions
          if (question.id === -1){
              console.log('Assessment completed, NO MORE QUESTIONS')
          } else {
            EgActivityRecorder.flush()
          }
          if (question.response && question.response.length){
              this.setState({ currentAnswer: question.response[0].selected_answers })
          }
          else{
              this.setState({ currentAnswer: []})
          }
          this.setState({ startTime: new Date().getTime() })
          this.setState({ loading: null })
      }
      catch(err){
        console.error("Failed to move to next question")
        console.error(err)
        this.setState({ loading: null })
        this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("General - Error")} message={t("General - There was an error loading the page")}></GenericErrorDialog>})
      }
  }

  async saveResponse() {
    try{
          // If the user clicked next without changing the previous selection - move on
          if (!this.state.currentAnswer && this.state.question.response[0].selected_answers ){
            return;
          }
          else {
                let response = await EgHttp.post('/assess/response', JSON.stringify({
                    candidateId: this.state.candidate.hr_id,
                    assessmentId: this.state.candidate.test_id,
                    questionId: this.state.question.id,
                    questCategory: this.state.question.category,
                    answerArray: this.state.currentAnswer,
                    assessmentType: this.state.candidate.assessment_type,
                    timeToAnswer: new Date().getTime() - this.state.startTime,
                    timeLeft: this.state.timeLeft, 
                    responseAttributes:null //TODO: Need to pass the response attributes
                }), true)
                if (response.status === 200){
                  return await response.json();
                }
                else{
                    throw new Error("/assess/response failed with status: " + response.status)
                }
          }
    }
    catch(err){
        console.error("Failed to save response")
        console.error(err)
        throw(err)
    }
  }

  async completeAssessment() {
    console.log('Complete assesssment')
    EgActivityRecorder.stopRecordingEvents()
    this.moveToScreenState(STEP_FINISHED)
    const { t } = this.props;
    try {
      EgFirebase.logEndAssessment()
      this.setState({ loading: <EgProgress /> })
      this.setState({ startstop: false })

      try {
        LogRocket.info("Stopping recording camera and screen through API.")
        this.stopRecordingAssessmentQuestions()  
      } catch (err) {
        LogRocket.error("Failed to stop recording camera and screen", { 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id, 'error': err })
      }
      try {
        LogRocket.info("Stopping camera and screen broadcasting and recording.")
        this.stopBroadcastingCamera()
        this.stopBroascastingScreen()
      } catch (err) {
        LogRocket.error("Crash while attempting to stop camera and screen recorders.", { 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id, 'error': err })
      }
  
      LogRocket.info('Assessment finished, backend should call complete by itself...')
      let response = await EgHttp.put('/assess/complete', JSON.stringify({
        assessmentId: this.state.candidate.test_id,
        assessmentType: this.state.candidate.assessment_type,
        hrId: this.state.candidate.hr_id
      }), true)
      if (response.status !== 200) {
        throw new Error('Failed to complete asseessment, complete returned with status: ' + response.status)
      }
      this.setState({ loading: null })
      EventLog.insert(this.state.candidate.org_id, this.state.candidate.hr_id, EventLog.END_ASSESSMENT, 0, true)
    }
    catch (err) {
      this.setState({ loading: null })
      this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialog} open={true} title={t("General - Error")} message={t("General - There was an error loading the page")}></GenericErrorDialog> })
      LogRocket.error("Failed to complete assessment", { 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id, 'error': err })
      LogRocket.info("Showing error dialog", { 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id, 'message': 'General - There was an error loading the page' })
    }
  }
  
  async moveToPreviosQuestion() {
      const { t } = this.props;
      try{
          this.setState({ loading: <EgProgress /> })
          let query;
          if (LangPicker.current() === 'en') {
            query = '/assess/previousAvailableQuestion/aid/' + this.state.candidate.test_id + '/type/' + this.state.candidate.assessment_type
          }
          else {
            query = '/assess/previousAvailableQuestion/aid/' + this.state.candidate.test_id +  '/type/' + this.state.candidate.assessment_type + '/lang/' + LangPicker.current() 
          }
          let response = await EgHttp.get(query, true)
          let rowsres = await response.json();

          const video_frame_sufix = `qid${rowsres.id}`
          VideoManager.uploadVideoFrame(this.state['cameraVideoTrack'], this.state.candidate.test_id, video_frame_sufix)

          this.setState({ question: rowsres.question });
          this.setState({ currentAnswer: rowsres.question.response[0].selected_answers})
          this.setState({ assessmentState:rowsres.updatedState})
          this.setState({ startTime: new Date().getTime() })
          this.setState({ loading: null })
          EgActivityRecorder.flush()
      }
      catch(err){
        console.error("Failed to move to previous question")
        console.error(err)
        this.setState({ loading: null })
        this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("General - Error")} message={t("General - There was an error loading the page")}></GenericErrorDialog>})
      }
  }

  async moveToNextAssessStep(){
    console.log("Moving to next step: " + this.state.questionnaireId)
    if ((this.state.questionnaireId && this.state.questionnaireId > 0) || this.state.questionnaireId === -1){
        this.moveToScreenState(STEP_REMOTE_INTERVIEW)
    }
    
    else{
        this.completeAssessment()
    }
  }
  
  setAnswer(ansArr) {
    this.setState({ currentAnswer: ansArr})
  }
  updateTimeLeft(timeLeft) {
    this.setState({ timeLeft: timeLeft })
    this.setState({initialTestTime:timeLeft})
  }

  componentWillUnmount() {
    LogRocket.info('Assessment will unmount')
    this.stopBroadcastingCamera()
    this.stopBroascastingScreen()
  }

  /**
   *  VIDEO AND SCREEN STREAMING SECTION
   */
    setScreenAndVideoGoodToGo(goodToGo) {
      this.setState({ screenAndVideoGoodToGo: goodToGo })
    }

    async checkVideoFrame(cameraVideoTrack) {
      console.log('Checking video')
      const videoAnalysis = await VideoManager.uploadVideoFrame(cameraVideoTrack, this.state.candidate.test_id, 'initial_framing')
      if (videoAnalysis && videoAnalysis['result'] && videoAnalysis['result']['data']['frame_issues'] !== 4) {
        console.log('Face detected, can proceed')
        this.setState({videoFrameNotValidated: false})
      } else {
        console.log('Face not visible in the camera, retrying')
        this.checkVideoFrame(cameraVideoTrack)
      }
    }

    async _externalCameraCheck(){
        console.log("EXTERNAL CAMERA CHECK: CHECKING CAMERA BEFORE AGORA");
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ video: true })
          .then(function(stream) {
              // Camera is available.
              console.log("EXTERNAL CAMERA CHECK: Camera is available.");
              
              stream.getTracks().forEach(track => track.stop()); // Stop the camera after checking.
          })
          .catch(function(err) {
              // Camera is not available.
              console.log("EXTERNAL CAMERA CHECK: Camera is not available: ", err);
            
          });
      } else {
          console.log("Browser does not support Media Devices. Please try with a different browser.");
      }
    }

    async startCamera() {
      await this._externalCameraCheck()
      // TODO: EZE - What if user cancels camera permission after granting it, during assessment?
      // Currently not handled.
      LogRocket.info('AGORA - Open CAM and start broadcasting', {'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})
      console.log('AGORA - Open CAM and start broadcasting')
      let cameraVideoTrack = null
      const videoTrackOptions = {encoderConfig:{VideoEncoderConfigurationPreset:'720p_1'}}
      try {
          cameraVideoTrack = await AgoraRTC.createCameraVideoTrack(videoTrackOptions)
          LogRocket.info("AGORA - Successfully started CAM video track", {'videoConfig': {videoTrackOptions}, 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})

          this.setState({ videoApproved: true })
          let retries = 0
          if (cameraVideoTrack.isPlaying) {
            this.checkVideoFrame(cameraVideoTrack)
          } else {
            const inrvl = setInterval(() => {
              console.log(`Video Analysis - Retry ${retries}`)
              if (cameraVideoTrack.isPlaying) {
                this.checkVideoFrame(cameraVideoTrack)
                clearInterval(inrvl)
              }
              retries += 1
              if (retries > 10) {
                clearInterval(inrvl)
                console.error('GAVE UP attempting to send first frame for analysis')
              }
            }, 200)
          }
      } catch (err) {
          LogRocket.error("AGORA - Faiulre creating video track", {'videoConfig': {videoTrackOptions}, 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})
          this.hanldeCameraError(err)
          return false
      }

      // So UI starts showing video ASAP
      this.setState({ cameraVideoTrack: cameraVideoTrack })
      console.log('Camera is capturing but not broadasting yet.')

      return true
    }

    async startAudio() {
      // Audio Channel for Remote Interviews
      const { t } = this.props;

      LogRocket.info("Opening LOCAL Audio channel")
      let audioTrack = null
      const audioTrackOptions = { encoderConfig: { AudioEncoderConfigurationPreset: "speech_standard" } }
      try {
        audioTrack = await AgoraRTC.createMicrophoneAudioTrack(audioTrackOptions)
        console.log('Audio Track Created')
        this.setState({audioTrack: audioTrack})
        return audioTrack
        // audioTrack.play()
      } catch (err) {
        console.log('Failure creating audio track')
        console.log(err)
        console.log("step: " + this.state.assess_step)
        LogRocket.error('Failure creating audio track', {'error': err})
        // if (this.state.assess_step === STEP_REMOTE_INTERVIEW){
        this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialogAndRestartRemoteInterview} open={true} title={t("Assessment - Opener - Microphone Problem")} message={t("Assessment - Opener - There was a problem with your microphone. Please fix it and restart.")} image={noMike}></GenericErrorDialog> })
        // }
        return null
      }        
    }

    async startBroadcastingCamera(agoraTokens, videoTrack, audioTrack=null, retries=0) {
      const { t } = this.props;
      const clientOptions = {
        mode: "live",
        codec: "vp8",
        role: "host"
      }
      const client = AgoraRTC.createClient(clientOptions);
      client.on("exception", async (event) => {
        LogRocket.warn("AGORA Exception detected by client.", {'event': event})
        if (event.code === 2001) {
          LogRocket.warn("This user has a fucked up mic, we are not picking up any sound")
          console.log("step: " + this.state.assess_step)
          if (this.state.assess_step === STEP_REMOTE_INTERVIEW){
            this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialogAndRestartRemoteInterview} open={true} title={t("Assessment - Opener - Microphone Problem")} message={t("Assessment - Opener - There was a problem with your microphone. Please fix it and restart.")} image={noMike}></GenericErrorDialog> })
          }
        } else if (event.code === 4001) {
          LogRocket.info("We might have audio again, but we are ignoring it.")
        }
      });

      try {
        await client.join(agoraTokens.camera.apid,
            agoraTokens.camera.channel,
            agoraTokens.camera.token,
            agoraTokens.camera.uid)
            LogRocket.info("AGORA - async join client to channel", {'joinConfig': {"channel": agoraTokens.camera.channel}, 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})
        } catch (err) {
            LogRocket.error("AGORA - Failure joining to camera video channel", {'err': err, 'joinConfig': {"channel": agoraTokens.camera.channel}, 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})
            if (retries === 0) {
              const tokens = await VideoManager.getTokens(this.state.candidate)
              this.setState({ agoraTokens: tokens })  
              LogRocket.info('Retrying with fresh tokens: ', {'tokens':tokens})
              return await this.startBroadcastingCamera(tokens, videoTrack, audioTrack, retries=retries+1)
            } else {
              this.hanldeCameraError(err)
              return false
            }
      }

      this.setState({ cameraClient: client })
      if (audioTrack) {
        console.log('Broascasting Audio and Video')
        await client.publish([videoTrack, audioTrack])
      } else {
        console.log('Broascasting Video Only')
        await client.publish(videoTrack);
      }

      return true
    }

    hanldeCameraError(err) {
        const { t } = this.props;
        this.setState({ errDialog: <CameraErrorDialog closeDialog={this.closeErrorDialog} open={true} title={t("Assessment - Opener - Camera Access Error")} message={t("Assessment - Opener - In order to continue, you need to allow Engard to use your camera")}></CameraErrorDialog> })
        LogRocket.error("AGORA - Failure to operate client camera. Cannot capture video. Showing error to user: Assessment - Opener - In order to continue, you need to allow Engard to use your camera", {'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id, 'error': err})
        LogRocket.captureMessage('Cannot transmit video from camera.', {
          tags: {
            topic: 'AGORA',
            device: 'CAM'
          },
          extra: {
            error: err,
            issue: 'Failure to operate client camera. Cannot capture video.',
            userMsg: 'In order to continue, you need to allow Engard to use your camera',
            candidate: this.state.candidate ? this.state.candidate.hr_id : 'unknown',
            assessment: this.state.candidate ? this.state.candidate.test_id : 'unknown'
          },
        });
        

        this.setState({ nextDisabled: true })
        this.setScreenAndVideoGoodToGo(false)
    }

    async startScreenCapture(agoraTokens) {
        const clientOptions = {
          mode: "live",
          codec: "vp8",
          role: "host"
        }

        LogRocket.info('AGORA - Open SCREEN CAP and start broadcasting', {'agoraConfig': clientOptions, 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})

        let screenVideoTrack;
        const videoTrackConfig = {
          encoderConfig: {
            framerate: 15,
            height: 720,
            width: 1280
          }
        }
        try {
          screenVideoTrack = await AgoraRTC.createScreenVideoTrack(videoTrackConfig, "auto")
          LogRocket.info('AGORA - Screen video track configured', {'agoraConfig': videoTrackConfig, 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})
        } catch (err) {
          LogRocket.error("AGORA - Failure configuring screen capture video track", {'agoraConfig': videoTrackConfig, 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})
          this.hanldeScreenError(err)
          return false
        }

        // join the channel
        let client = null
        try {
          client = AgoraRTC.createClient(clientOptions);
          await client.join(agoraTokens.screen.apid,
                  agoraTokens.screen.channel,
                  agoraTokens.screen.token,
                  agoraTokens.screen.uid)
  
          // publish local tracks to channel
          await client.publish(screenVideoTrack);  
        } catch (err) {
          LogRocket.error('Failed to start broadcasting screen', {'err': err})
          const tokens = await VideoManager.getTokens(this.state.candidate)
          this.setState({ agoraTokens: tokens })  
          LogRocket.info('Retrying screen broadcasting with fresh tokens: ', {'tokens':tokens})
          try {
            await client.join(tokens.screen.apid,
                tokens.screen.channel,
                tokens.screen.token,
                tokens.screen.uid)

              // publish local tracks to channel
              await client.publish(screenVideoTrack);  
          } catch (err2) {
            LogRocket.error('FATAL - Cannot broadcast screen', {'err': err2})
          }
        }

        LogRocket.info('Client screen capture joined the channel and published the screen video track.', {'channel': agoraTokens.screen.channel, 'candidate': this.state.candidate.hr_id, 'assessment': this.state.candidate.test_id})
        this.setState({ screenVideoTrack: screenVideoTrack, screenClient: client })
        this.setScreenAndVideoGoodToGo(true)
    }

    hanldeScreenError(err) {
        // bypass screen issues - May 17 2022. Till we use other screen recording method
        // this.setScreenAndVideoGoodToGo(false)
        LogRocket.error("AGORA - Failure to capture client screen. Should show error to user: In order to complete the assesment you need to let us record your screen.", {'candidate': this.state.candidate.id, 'assessment': this.state.candidate.test_id, 'error': err})
        LogRocket.captureMessage('Cannot transmit screen capture.', {
          tags: {
            topic: 'AGORA',
            device: 'SCREEN'
          },
          extra: {
            error: err,
            issue: 'Failure to capture screen.',
            userMsg: 'In order to complete the assesment you need to let us record your screen',
            candidate: this.state.candidate ? this.state.candidate.hr_id : 'unknown',
            assessment: this.state.candidate ? this.state.candidate.test_id : 'unknown'
          },
        });


        // bypass screen issues - May 17 2022. Till we use other screen recording method
        //TODO: L10n - Strings have to be Localized
        // this.setState({ errDialog: <GenericErrorDialog closeDialog={this.closeErrorDialog} open={true} title={"Screen Capture Error"} message={"In order to complete the assesment you need to let us record your screen."}></GenericErrorDialog> })
    }

  async stopRecordingAssessmentQuestions() {
    if (this.state.recording) {
      console.log('Stop video frames validation.')
      clearInterval(this.framesValidatorTimer);

      this.setState({ recording: false })
      console.log('Stop recording video and screen without waiting for response')
      // VideoManager.stopRecordingCam(this.state.agoraTokens, this.state.candidate.test_id)
      // VideoManager.stopRecordingScreen(this.state.agoraTokens, this.state.candidate.test_id)
      VideoManager.stopRecordingScreenAndCamera(this.state.agoraTokens, this.state.candidate.test_id)

    }
  }

  async stopRecordingInterviewResponse(responseId) {
    if (this.state.recording) {
      this.setState({ recording: false })
      console.log('Stop recording interview video')
      await VideoManager.stopRecordingInterview(this.state.agoraTokens, responseId)
    } else {
      console.warn('Asked to stop recording interview question, but was not recording an interview question')
    }
  }

  async stopBroascastingScreen() {
    if (this.state.screenClient) {
      console.log('STOP broadcsating screen')
      try {
        this.state.screenVideoTrack.stop()
        this.state.screenVideoTrack.close()
        await this.state.screenClient.leave()
      } catch (err) {
        LogRocket.error('AGORA - Failure rleasing screen capture track', { error: err })
      }
      this.setState({ screenVideoTrack: null, screenClient: null })
    } else {
      console.log('Requested to stop broadcasting screen, but it was not broadcasting')
    }
  }

    async stopBroadcastingCamera() {
        if (this.state.cameraClient) {
          console.log('Stop broascasting camera and audio')
            try {
              console.log('CAM STOP')
              this.state.cameraVideoTrack.stop()
              console.log('CAM CLOSE')
              this.state.cameraVideoTrack.close()
              console.log('AU STOP')
              this.state.audioTrack.stop()
              console.log('AU CLOSE')
              this.state.audioTrack.close()
              await this.state.cameraClient.leave()
              console.log('AV IS DEAD')
            } catch (err) {
              LogRocket.error('AGORA - Failed closing camera track:', {error: err})
            }
            this.setState({ cameraVideoTrack: null, cameraClient: null })
        } else {
          console.log('Asked to stop broadcasting camera, but it was not broascasting')
        }
    }
// ** end VIDEO AND SCREEN STREAMING SECTION ** //

  render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
       return (
         <div className={classes.root} >
           <CssBaseline />
           {this.state.startstop ?
             <AppBar position="fixed" className={i18n.dir() === 'rtl' ? classes.appRight : classes.appLeft} style={this.state.assess_step === STEP_QUESTIONS ? { display: 'block', backgroundColor:'#ffffff' } : { display: 'none',backgroundColor:'#ffffff' }}>
               {this.state.loading}
               <Toolbar >
                   <AssessAppBar startTimer={this.state.assess_step === STEP_QUESTIONS ? true : false} timeout={()=>{this.moveToScreenState(STEP_TIMEOUT)}} candidate={this.state.candidate} updateTimeLeft={this.updateTimeLeft} initialTestTime={this.state.initialTestTime}> </AssessAppBar>
               </Toolbar>
             </AppBar> : ''
           }

        <main className={classes.content} style={{ backgroundImage: 'url(' + { assessBg } + ')' }}>

          <Grid container fullwidth='true' justify="center" direction="row" alignItems="flex-start">

            <Grid item xs={AppUtils.isMobile()?0:2}></Grid>
            <Grid item xs={AppUtils.isMobile()?12:8} style={{paddingInline:'3vw'}}>

              <Grid container justify="left" direction="row" alignItems="flex-start">
                {(() => {
                  switch (this.state.assess_step) {
                    case STEP_NOT_SIGNED: // Not signed in yet
                      return <Grid item xs={12} className={classes.latestNote}>
                                <AssessSignin signin={this.signin} resend={this.resend} />
                            </Grid>;
                    case STEP_SIGNED_IN: // Test greetings
                      return <Grid item xs={12} className={classes.latestNote}>
                                <AssessOpener continue={()=>{this.moveToScreenState(STEP_CUSTOM_DETAILS)}} candidate={this.state.candidate} orgLegal={this.state.orgLegal}/>
                            </Grid>;
                    case STEP_CUSTOM_DETAILS: //if additionan details are required - collect
                    return <Grid item xs={12} className={classes.latestNote}>
                              <AssessCustomDetails continue={()=>{this.moveToScreenState(STEP_VIDEO_CAPTURE)}} candidate={this.state.candidate} orgConfig={this.state.orgConfig}/>
                          </Grid>;
                    case STEP_VIDEO_CAPTURE: // Video capture
                    return <Grid item xs={12} className={classes.latestNote}>
                              <AssessVideoRec continue={()=>{this.moveToScreenState(STEP_SCREEN_CAPTURE)}} candidate={this.state.candidate} requireVideo={this.state.requireVideo} cameraTrack={this.state.cameraVideoTrack} videoApproved={this.state.videoApproved} videoFrameNotValidated={this.state.videoFrameNotValidated}/>
                          </Grid>;
                    case STEP_SCREEN_CAPTURE: // ScreenCapture
                      return <Grid item xs={12} className={classes.latestNote}>
                                <AssessScreenRec continue={()=>{this.moveToScreenState(STEP_ARE_YOU_READY)}} />
                            </Grid>;
                    case STEP_ARE_YOU_READY: // Are you ready
                      return <Grid item xs={12} className={classes.latestNote}>
                                  <AssessReady startAssess={this.startAssessessment} screenAndVideoGoodToGo={this.state.screenAndVideoGoodToGo} requireVideo={this.state.requireVideo}></AssessReady>
                              </Grid>;
                    case STEP_QUESTIONS: // Questions
                      return <Grid item xs={12} className={classes.latestQNote}>
                                <QuestionPanel candidate={this.state.candidate} question={this.state.question} assessmentState={this.state.assessmentState}  requireVideo={this.state.requireVideo} nextQuestion={this.moveToNextQuestion} lastQuestion={this.moveToPreviosQuestion}  setAnswer={this.setAnswer} moveToNextAssessStep={this.moveToNextAssessStep} startRecording={this.startRecordingAssessmentQuestions} cameraVideoTrack={this.state.cameraVideoTrack} isRemote={this.state.questionnaireId} updateTimeLeft={this.updateTimeLeft}/>
                            </Grid>;
                    case STEP_REMOTE_INTERVIEW: //Remote Interview
                        return <Grid item xs={12} className={classes.latestQNote}>
                                <RemoteInterviewPanel candidate={this.state.candidate} questionnaireId={this.state.questionnaireId}  requireVideo={this.state.requireVideo} complete={this.completeAssessment} cameraVideoTrack={this.state.cameraVideoTrack} recordQuestionVideo={this.startRecordingRemoteInterviewQuestion} stopRecording={this.stopRecordingInterviewResponse} skipAssessment={this.state.skipAssessment} />
                            </Grid>;
                    case STEP_TIMEOUT: // Timeout
                      return <Grid item xs={12} className={classes.latestQNote} style={{backgroundColor:'#ffffff',paddingTop:80}}>
                                <div className={classes.timer}>
                                    <AccessTimeIcon style={{ marginRight: 5, fontSize: 64 }} />
                                    <Typography className={classes.timer}>{t('Assessment - Quest - We are sorry, but your Time is up')}</Typography>
                                </div>
                                <div style={{paddingBottom: 100}}>
                                    <Typography className={classes.timer}>{t('Assessment - Quest - Your assessment time finished before')}</Typography>
                                </div>
                            </Grid>;
                    case STEP_FINISHED: //assessment_finished
                        return <Grid item xs={12} className={classes.latestQNote} style={{paddingTop:20}}>
                            <AssessmentFinished candidate={this.state.candidate} ></AssessmentFinished>
                        </Grid>;
                    default:
                      return null;
                  }
                })()}
                </Grid>
                <Grid xs={12} style={{ direction: 'ltr' }}>
                    <PoweredByFooter></PoweredByFooter>
                </Grid>
            </Grid>
            <Grid item xs={AppUtils.isMobile()?0:2}></Grid>
          </Grid>
          

      </main>
                   
      { this.state.errDialog }
      {this.state.statusBar}
      </div>
    );
  }
}

Assessment.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired
};

export default withTranslation()(withCookies(withRouter((withStyles(styles))(Assessment), Assessment)))

