import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Table, TableBody, TableRow, TableCell, TableHead, TableSortLabel, TablePagination, List, ListItem } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import SkeletonTable from '../../components/SkeletonTable';
import Skeleton from '@material-ui/lab/Skeleton'
import _ from 'lodash';
import AddPredefinedModel from './AddPredefinedModel';
import EgEmptyState from '../../components/EgEmptyState';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import LangPicker from '../../util/LangPicker';
import RowActionsHover from '../../components/RowActionsHover';
import AppUtils from '../../util/AppUtils';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:'1.5vh'
    },
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:theme.palette.text.black,
        border:0,
        paddingBottom:'1.5vh',
        paddingTop:'1.5vh',
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0',
        overflowWrap:'anywhere'
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto'
    },  
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'0px solid #F0F0F0',
        
    },
    tableHeadCell:{
        paddingTop:15,
        paddingBottom:15,
        fontSize: 12,
        fontWeight:400,
        color:"#5F6066",
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0'
    },
    tableRowInner:{
        minHeight:"6.5vh",
        maxHeight:"6.5vh",
        height:"6.5vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
});

class PredefinedModelTable extends React.Component {
  
    constructor(props) {
        super(props);           
        this.handleDrawerState = this.handleDrawerState.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.setHover = this.setHover.bind(this)
        this.confirmDelete = this.confirmDelete.bind(this)  
        this.closeConfirm = this.closeConfirm.bind(this) 
        this.deleteModel = this.deleteModel.bind(this)
        this.refresh = this.refresh.bind(this)
        this.openEditModel = this.openEditModel.bind(this)
        this.getTraits()
    }

    state = {
        loading:true,
        overlayDiv: [],
        moreMenuOpen:[],
        rowsPerPage:10,
        page:0,
        traits:[],
        models:[]
    };

    componentDidMount(){
        if (this.props.models){
            this.setState({models:this.props.models})
        }
    }
    componentWillReceiveProps(nextProps) {   
        if (nextProps.models && nextProps.models!==this.state.models ){  
            this.setState({models:nextProps.models}, this.setState({loading:false}));           
        }
        if (nextProps.searchTerm!==this.state.searchTerm ){             
            this.setState({searchTerm:nextProps.searchTerm});           
        }
    }
    handleChangePage(event, newPage){
        this.setState({page:newPage})
    }
    createSortHandler = property => event => {
        this.onRequestSort(event, property);
    };
    onRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }    
        this.setState({ order, orderBy });       
    };  
    
    openEditModel(ind){
        this.setState({openModel:this.state.models[ind]}, ()=> {
          this.setState({singleEditOpen:true})
        })
    }
    async getTraits(){
        try{
            let response = await EgHttp.get( '/reports/dominanttraits/'+ LangPicker.current() )
            let rowsres = await response.json();
            this.setState({traits:rowsres})
        }
        catch(error){
            console.error(error)
        }
    }
    
    handleDrawerState(val){
        this.setState({singleEditOpen:val})
    }

    setHover(e, onOff, index){
        const { t } = this.props;
        let item, items = []
        const submenuActions=[ {text:t('Lookalike - Table - Delete Campaign'), action:this.confirmDelete, disabled:false},
                             ]
        if (onOff){ 
            item = <RowActionsHover mainActionName={t('Lookalike - Models - Edit Model')} mainAction={this.openEditModel} rowIndex={index} submenuActions={submenuActions} rowsPerPage={this.state.rowsPerPage} page={this.state.page}/>
        }
        else{
            item = null;
            this.setState({moreMenuOpen:[]});
        }
        items[index] = item;
        this.setState({overlayDiv:items});
    }


    confirmDelete(index){
        const { t } = this.props; 
        this.setState({deleteIndex:index, moreMenuOpen:[]})
        this.setState({confirmDeleteDialog: <ConfirmationDialog title={t("Lookalike - Table - Delete Model")} message={t("Lookalike - Models - Are you sure")}  confirm={this.deleteModel} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }
    closeConfirm(){
        this.setState({confirmDeleteDialog:null});
    }

    getDominantTraitDescription(traitId){
        if(this.state.traits){
            const traitInd = this.state.traits.findIndex(( {id} ) => id === traitId )
            if (traitInd > -1){
                if (LangPicker.current() === 'en'){
                    return this.state.traits[traitInd].display_name
                }
                else return this.state.traits[traitInd].trait_localized[0].localized_name
            }
            else return ''
        }
        return '';
    }
    
    // Delete model
    async deleteModel(){
        const { t } = this.props;  
        this.setState({confirmDeleteDialog:null}); 
        const id = this.state.models[this.state.deleteIndex].id
        let response = await EgHttp.delete('/lookalike/predefmodels/' + id) 
        if (response.status===200){
            _.remove(this.state.models, {
                id: id
            });
        }
        this.setState({overlayDiv:[]})
        this.props.refresh("SUCCESS", t('Lookalike - Models - Model was deleted successfully'));
        //EventLog.insert(this.props.admin.org.id, id, EventLog.DELETE_CAMPAIGN, this.props.admin.account.id)
        return;   
    }

    refresh(message){
        this.props.refresh(message)
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:10, borderTop:'1px solid #e8e8e8'}}>
            <Grid container >
            {this.state.loading || (this.state.models && this.state.models.length)?
            <Grid item xs={12} className={classes.formRow} >
                <Paper elevation={0} className={classes.summaryBox} > 
                        <div>
                            <Table className={classes.table} aria-label="simple table" >
                                <TableHead>
                                    <TableRow> 
                                            <TableCell key={'modelName'} sortDirection={this.state.orderBy === 'modelname' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'25%', textAlign:'start'}}>
                                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('modelname')} active={this.state.orderBy === 'modelname'}>
                                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Lookalike - Models - Model Name')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell key={'description'} sortDirection={this.state.orderBy === 'description' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'55%', textAlign:'start'}}>
                                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('description')} active={this.state.orderBy === 'description'}>
                                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Lookalike - Models - Description')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className={classes.tableHeadCell} style={{width:'20%'}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {this.state.loading?
                                    <TableRow key={'skeleton1'} className={classes.tableRowBody} >
                                        <TableCell className={classes.tableInnerCell} colSpan={5}>
                                            <SkeletonTable style={{width:'100%'}}></SkeletonTable>
                                        </TableCell>
                                    </TableRow>
                                    :
                                    _.slice(this.state.models.sort(AppUtils.getSorting(this.state.order, this.state.orderBy)),this.state.page*this.state.rowsPerPage, (this.state.page+1)*this.state.rowsPerPage).map((row, index) => (
                                    <TableRow  className={classes.tableRowBody} >
                                        <TableCell className={classes.tableInnerCell} colSpan={5} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                        <div>
                                            <Table className={classes.tableInner} aria-label="inner table" >
                                            <TableBody >
                                                <TableRow key={row.firstname + "in"} className={classes.tableRowInner} >
                                                    <TableCell className={classes.tableCell} style={{width:'25%'}}>{row.position}</TableCell>
                                                    <TableCell className={classes.tableCell} align="left" style={{width:'55%'}}>{row.report[0]?this.getDominantTraitDescription(row.report[0].dominant_traits[0]):''}</TableCell>
                                                    <TableCell className={classes.tableCell} style={{width:'20%',borderRight:0}}></TableCell>
                                                </TableRow>
                                            </TableBody >
                                            </Table>
                                        
                                        </div>
                                            {this.state.overlayDiv[index]}
                                            {this.state.moreMenuOpen[index]}   
                                        </TableCell>
                                    </TableRow>   
                                    ))}
                                </TableBody>
                            </Table>
                                    
                            <TablePagination component="div"
                                            count={this.state.models?this.state.models.length:0}
                                            page={this.state.page}
                                            onPageChange={this.handleChangePage}
                                            rowsPerPage={this.state.rowsPerPage}
                                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                                            labelRowsPerPage={t('General - Rows per page')} 
                                            >
                            </TablePagination>
                     </div>    
                </Paper>
            </Grid>
            :
            <div style={{paddingTop:'1vh', width:'100%'}}>
                <EgEmptyState title={t('Lookalike - Models - No Predefined Models Found')} emptyAction={this.props.addPredefinedModel} emptyActionTxt={t('Lookalike - New Model')}/>
            </div>
            }
            </Grid>
            <AddPredefinedModel handleDrawerState={this.handleDrawerState} openState={this.state.singleEditOpen}   selectedModel={this.state.openModel} models={this.state.models} refresh={this.refresh} traits={this.state.traits}></AddPredefinedModel>                    
            {this.state.confirmDeleteDialog}
        </div>
    );
  }
}
PredefinedModelTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(PredefinedModelTable),PredefinedModelTable);