import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Tabs, Tab, List, ListItem, Tooltip  } from '@material-ui/core';
import SingleReportSummary from './SingleReportSummary';
import SingleReportAnalytics from './SingleReportAnalytics';
import SingleReportVideo from './SingleReportVideo';
import SingleReportRecommendations from './SingleReportRecommendations';
import { withTranslation } from 'react-i18next';
import EgHttp from '../../util/EgHttp';
import Moment from 'react-moment';
import EgButton from '../../components/EgButton';
import CloseOutlinedIcon from '@material-ui/icons//CloseOutlined';
import SingleReportEvaluation from './SingleReportEvaluation';
import GenericInputDialog from '../../components/GenericInputDialog';
import EgStatusAlert from '../../components/EgStatusAlert';
import LangPicker from '../../util/LangPicker';
import SingleReportInterview from './SingleReportInterview';
import SkeletonReport from '../../components/SkeletonReport';
import SingleReportExecutive from './SingleReportExecutive';
import RoleManager from '../../managers/RoleManager';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import SingleReportRemoteInterview from './SingleReportRemoteInterview';
import SingleReportRisk from './SingleReportRisk';

const mapStateToProps = state => ({
    admin: state.admin.value,
    nav: state.nav.value
});

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    formRow:{
        paddingTop:'2vh',
        paddingInline:18,
        borderBottom:'#e8e8e8'
    },
    subMenuEn: {
        right:0, 
    },
    subMenuHe: {
        left:0,  
    },
    actionButton:{
        marginLeft:10,
        marginRight:10
    }
  });
 
const AntTabs = withStyles((theme) => ({
    root: {
    },
    indicator: {
      backgroundColor: theme.palette.primary.dark,
    },
}))(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 20,
      fontSize:13,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(2),
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.primary.dark,
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

const REPORT_SUMMARY = 0;
const REPORT_EXECUTIVE = 1;
const REPORT_EVALUATION = 2;
const REPORT_INTERVIEW = 3;
const REPORT_RECOMMENDATIONS = 4;
const REPORT_TIME_INSIGHTS = 5;
const REPORT_VIDEO = 6;
const REPORT_REMOTE_INTERVIEW = 7;
const REPORT_RISK = 8;

const CAMERA_DEVICE = 'camera'
const SCREEN_DEVICE = 'screen'
const EXPRY_TIME_IN_SECS = 1800 // 30 minutes

class SingleReport extends React.Component {
  
    constructor(props) {
        super(props);            
        this.handleChange = this.handleChange.bind(this);
        this.openShareMenu = this.openShareMenu.bind(this);
        this.closeEmailDialog = this.closeEmailDialog.bind(this)
        this.sendInEmail = this.sendInEmail.bind(this)
        this.handleStatusClose = this.handleStatusClose.bind(this)

        if (this.props.reportId){
            this.getReport(this.props.reportId)
        }
    }

    state = {
        addOpen: this.props.addOpen,
        reportId: this.props.reportId,
        reportState: 0,
        moreMenuOpen: false,
    };

    linkRef = React.createRef();

    componentDidMount(){
        if (this.props.admin && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)){
            this.setState({reportState:REPORT_SUMMARY})
        }
        else{
            this.setState({reportState:REPORT_INTERVIEW})
        }
        
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.reportId !== this.props.reportId){
            this.setState({reportId:nextProps.reportId}); 
            this.getReport(nextProps.reportId)
            
        }
    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }
    refresh(status, message){
        if (status === 'SUCCESS'){
            this.showStatusBar(message)
        }
    }

    handleStatusClose(){
        this.setState({statusBar:null})
    }

    openShareMenu(repid){
        const { t, i18n } = this.props;
        const { classes } = this.props;
        let item=null;
        try{
            if (this.state.moreMenuOpen){
                item = null;
            }
            else{
                
                item =  <Paper style={{position:'absolute', top: 70, width:180, zIndex:1001, margin:10}} className={i18n.dir()==='rtl'?classes.subMenuHe:classes.subMenuEn}>
                            <List component="nav">
                                {this.props.admin && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                                <ListItem key={'download'} button onClick={()=>this.downloadPDF(repid)}>
                                    <Typography variant={'h4'}>{t('Reports - Single - Download as PDF')}</Typography>
                                </ListItem>:''}
                                <ListItem key={'download'} button onClick={()=>this.downloadPDF(repid, "recom")}>
                                    <Typography variant={'h4'}>{t('Reports - Single - Download Recommendations as PDF')}</Typography>
                                </ListItem>
                                {this.props.admin && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                                <ListItem key={'sendinemail'} button onClick={()=>this.openEmailDialog(repid)}>
                                    <Typography variant={'h4'}>{t('Reports - Single - Send in Email')}</Typography>
                                </ListItem>:''}
                                <ListItem key={'sendinemail'} button onClick={()=>this.openEmailDialog(repid, 'recom')}>
                                    <Typography variant={'h4'}>{t('Reports - Single - Send Recommendations in Email')}</Typography>
                                </ListItem>
                            </List>
                        </Paper> 
            
            }
            this.setState({moreMenuOpen:item}); 
        }
        catch(err){
            console.error(err)
            return;
        }
    }

    async openEmailDialog(id, recom){
        const { t } = this.props;
        this.setState({moreMenuOpen:null}); 
        this.setState({emailInput: <GenericInputDialog reportId={id} open={true} title={t('Reports - Single - Send report to email')} message={t('Reports - Single - Who would you like to share this report with?')} takeAction={this.sendInEmail} closeDialog={this.closeEmailDialog} recom={recom}></GenericInputDialog>})
    }

    async sendInEmail(email,id, recom){
        const { t } = this.props;
        try{
            let response = await EgHttp.get('/reports/email/'+email+'/pdf/' + id + '/language/' + LangPicker.current()  + '/recom/' + (recom === 'recom'?'true':'false'))
            if (response.status !== 200 ) {
                console.error(`Error: No report ready with id ${id}`)
                return
            }
            this.setState({emailInput:null})
            this.closeEmailDialog()
            this.refresh("SUCCESS",t('Reports - Single - Report sent successfully'))
        }
        catch(err){
            //this.closeEmailDialog()
            console.error(err);
            return;
        }
    }

    closeEmailDialog(){
        this.setState({emailInput:null})
    }

    async downloadPDF(id, recom){ 
        try{
            this.setState({moreMenuOpen:null}); 
            let response = await EgHttp.get('/reports/pdf/' + id + '/language/' + LangPicker.current() + '/recom/' + (recom === 'recom'?'true':'false'))
            let reportres = await response.blob();
            if (response.status !== 200 || reportres === null || reportres === undefined ) {
                console.error(`Error: No report ready with id ${id}`)
                return
            }
            const href = window.URL.createObjectURL(reportres);
            const a = this.linkRef.current;
            a.download = 'EngardReport.pdf';
            a.href = href;
            a.click();
            a.href = '';
        }
        catch(err){
            console.error(err);
            return;
        }
       
    }

    async getReport(id){
        try{
            // TODO: check why get report is called twice
            console.log(`Fetching report ${id}`)
            this.setState({loading:true})
            let response = await EgHttp.get('/reports/single/' + id)
            let reportres = await response.json();
            if (response.status !== 200 || reportres === null || reportres === undefined || reportres.length === 0) {
                console.error(`Error: No report ready with id ${id}`)
                return
            }
            if (reportres.assessment.assessment_attributes.video_required){
                const curl = await this.getVideoUrl(reportres.assessment_id, CAMERA_DEVICE)
                const surl = await this.getVideoUrl(reportres.assessment_id, SCREEN_DEVICE)
                reportres.encCameraUrl = curl
                reportres.encScreenUrl = surl
            }
            reportres.assessment.assessment_scores.sort(function (a, b) {
                return a.trait_id - b.trait_id;
            });
            this.setState({report:reportres})
            let responseparts = await EgHttp.get('/reports/parts/' + id)
            let rowsparts = await responseparts.json();  
            this.setState({reportParts:rowsparts})
            this.setState({loading:false})
        }
        catch(err){
            console.error(err);
            return;
        }
        
    }

    async getS3Key(assesmentId, device=CAMERA_DEVICE) {
        try{
            const pathPart = device===CAMERA_DEVICE ? "cameraRecS3Key" : "screenRecS3Key"
            const res = await EgHttp.get(`/reports/${pathPart}/${assesmentId}`)
            const data = await res.json()
            console.log(`Got S3 Key for ${device}: ${data.s3_key}`)
            return data.s3_key
        }
        catch(err){
            console.error(err)
        }
    }

    async getSignedUrl(s3Key, expirationTimeInSecs) {
        try{
            console.log(`Fetching signed url for video ${s3Key}`)
            if (!s3Key) {
                return null
            }
            // This is related to this bug:
            // https://engard.atlassian.net/browse/DEV-140
            const s3key_no_slash = s3Key.replaceAll("/", "_w31N_k_");
            const urlEncodedKey = encodeURIComponent(s3key_no_slash)
            const res = await EgHttp.get(`/reports/recordingurl/${urlEncodedKey}/${expirationTimeInSecs}`)
            const data = await res.json()
            if (res.status === 200) {
                const signedUrl = data.signed_url;
                console.log(`Got signed url: ${signedUrl}`)
                return signedUrl
            } else {
                return null
            }
        }
        catch(err){
            console.error(err)
            return null;
        }
    }

    async getVideoUrl(assessmentId, device=CAMERA_DEVICE) {
        try{
            console.log(`Getting a URL for the ${device} video recording`)
            const s3Key = await this.getS3Key(assessmentId, device)
            // TODO: Security issue -- this can be spoiled to get an eternal link to the media.
            const signedUrl = await this.getSignedUrl(s3Key, EXPRY_TIME_IN_SECS)
            if (signedUrl) {
                console.warn(`${device} video expires in two minutes!!! fix this one tests are done`)
                return decodeURI(signedUrl)
            } else {
                console.warn(`There is no ${device} recording for this report`)
                return ''
            }
        }
        catch(err){
            console.error(err)
            return '';
        }
    }

    handleChange(event, val){
        this.setState({reportState:val})
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{overflow: 'hidden'}}>
                 <a ref={this.linkRef}/>
            {this.state.report?
           
            <div style={{width:'100%', overflow:'hidden'}}>
                <Paper  elevation={0} style={{height: '100%'}}>

                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{ paddingTop:'4vh'}}>
                            
                            <Grid item xs={9} justify="left" style={{paddingInline:30}}>
                                <Typography variant={'h1'}> {this.state.report.human_resource.firstname + " " + this.state.report.human_resource.lastname} - {t('Reports - Single - Pre-employment Assessment')}</Typography>
                            </Grid>
                            <Grid item xs={3} style={{textAlign:"end", paddingInlineEnd:20}} >
                                    <EgButton secondary="true"  onClick={()=>{this.openShareMenu(this.state.reportId)}} className={classes.actionButton}>{t('Reports - Single - Share')}</EgButton> 
                                    <EgButton secondary="true" className={classes.closeIcon} onClick={()=>{this.props.close()}}>
                                        <CloseOutlinedIcon style={{fontSize:16}}></CloseOutlinedIcon>
                                    </EgButton>     
                            </Grid>
                            <Grid item xs={12} style={{paddingInline:30}}>
                                <Typography variant={'h4'} style={{textAlign:'start'}}> {this.state.report.human_resource.email} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;+{this.state.report.human_resource.phone}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{t("Candidates - Table - Position")}: { this.state.report.human_resource.position} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{t("Reports - Single - Assessment Date")} <Moment format="MMM DD, YYYY">{ this.state.report.assessment.completed_at}</Moment> </Typography>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow}>
                                <AntTabs
                                    value={this.state.reportState}
                                    onChange={this.handleChange}
                                    aria-label="settings"
                                >
                                    {this.props.admin && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                                    <AntTab label={t('Reports - Single - Summary')} style={{padding:'1.5vh'}} />:<Tooltip title={t("General - No Permissions")}><span><AntTab disabled label={t('Reports - Single - Summary')} style={{padding:'1.5vh'}} /></span></Tooltip>}
                                    {this.props.admin && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                                    <AntTab label={t('Reports - Single - Executive')} />:<Tooltip title={t("General - No Permissions")}><span><AntTab disabled label={t('Reports - Single - Executive')} /></span></Tooltip>}
                                    {this.props.admin && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                                    <AntTab label={t('Reports - Single - Evaluation')} />:<Tooltip title={t("General - No Permissions")}><span><AntTab disabled label={t('Reports - Single - Evaluation')} /></span></Tooltip>}
                                    <AntTab label={t('Reports - Single - Interview')} />
                                    <AntTab label={t('Reports - Single - Work Recommendations')} />
                                    {this.props.admin && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                                    <AntTab label={t('Reports - Single - Analytics')} />:<Tooltip title={t("General - No Permissions")}><span><AntTab disabled label={t('Reports - Single - Analytics')} /></span></Tooltip>}
                                    {this.props.admin && RoleManager.enable(this.props.admin.account.role,RoleManager.AUTH_ADMINS)?
                                    <AntTab  label={t('Reports - Single - Video')}/>:<Tooltip title={t("General - No Permissions")}><span><AntTab disabled label={t('Reports - Single - Video')}/></span></Tooltip>}
                                    <AntTab  label={t('Remote - Assign - Remote Interview')}/>
                                    {/*<AntTab  label={t('Risk')}/>*/}
                                </AntTabs>
                            </Grid>
                            
                            {this.state.reportState === REPORT_SUMMARY?
                            <SingleReportSummary report={this.state.report} reportParts={this.state.reportParts} moveTo={this.handleChange} loading={this.loading}/>
                            : this.state.reportState === REPORT_EVALUATION?
                            <SingleReportEvaluation report={this.state.report} reportParts={this.state.reportParts}></SingleReportEvaluation>
                            : this.state.reportState === REPORT_EXECUTIVE?
                            <SingleReportExecutive report={this.state.report} reportParts={this.state.reportParts}></SingleReportExecutive>
                            : this.state.reportState === REPORT_TIME_INSIGHTS?
                            <SingleReportAnalytics report={this.state.report} reportParts={this.state.reportParts}></SingleReportAnalytics>
                            : this.state.reportState === REPORT_VIDEO?
                            <SingleReportVideo report={this.state.report} ></SingleReportVideo>
                            : this.state.reportState === REPORT_RECOMMENDATIONS?
                            <SingleReportRecommendations report={this.state.report} reportParts={this.state.reportParts}></SingleReportRecommendations>
                            : this.state.reportState === REPORT_INTERVIEW?
                            <SingleReportInterview report={this.state.report} reportParts={this.state.reportParts}></SingleReportInterview>
                            : this.state.reportState === REPORT_REMOTE_INTERVIEW?
                            <SingleReportRemoteInterview report={this.state.report} ></SingleReportRemoteInterview>
                            : this.state.reportState === REPORT_RISK?
                            <SingleReportRisk report={this.state.report} ></SingleReportRisk>
                            :''
                            }
                            
                        </Grid>
                </Paper>
            </div>
            :
            <div>
                <SkeletonReport style={{width:'100%'}}></SkeletonReport>
            </div>
            }
            {this.state.moreMenuOpen}   
            {this.state.emailInput}
            {this.state.statusBar}

        </div>     
      
    );
  }
}
SingleReport.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withStyles(styles)(SingleReport),SingleReport));
