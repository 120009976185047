import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Divider } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import Drawer from '@material-ui/core/Drawer';
import LangPicker from '../../util/LangPicker';
import ReportsManager from '../../managers/ReportsManager';
import CompareBar from '../../components/CompareBar';
import EgRiskPatch from '../../components/EgRiskPatch';
import EgRiskBar from '../../components/EgRiskBar';
import ExtendedRiskEvaluation from './ExtendedRiskEvaluation';
import EgHtmlToolTip from '../../components/EgHtmlToolTip';
import EgHttp from '../../util/EgHttp';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
   
    boxTitle:{
        paddingInline:20,
        paddingTop:'0.5vh',
        
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:20
    },
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    },
   
    valBar:{
        paddingInline:10
    },
    riskBar:{
        padding:10, 
        width: 20,
        maxWidth: '20px !important',
        display:'flex',
        justifyContent:'center'
    },
    overlay:{
        display:"block", 
        position:'absolute',
        top:'-1vh', 
        left:0, 
        zIndex:1000, 
        background:"#f0f0f066",
        height:'100%',
        width:'100%',
    },
    readmore:{
        marginLeft:10,
        marginRight:10,
        backgroundColor:'#ffffff00'
    }
    
});

const RISK_CATEGORY_FATHER = 300

class SingleReportRisk extends React.Component {
  
    constructor(props) {
        super(props);            
        console.info("REPORT EVALUATION")
        this.openExtendedTraits = this.openExtendedTraits.bind(this);
        this.closeExtended = this.closeExtended.bind(this)
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        traitsSummaryArr:[],
        execSummaryArr:[],
        invalidReport:false,
        overlayDiv:[]
        
    };
   
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
    }
    
    intializeData(report, reportParts){
        this.setState({report})
        this.setState({reportParts})
        if (!report) return
        const scores = report.assessment.assessment_scores
        const sortedScores = scores.sort((a,b) => b.trait_adjusted_score - a.trait_adjusted_score);
        this.setState({sortedScores:sortedScores})
        
        const traitSumArr = ReportsManager.setTraitsSummary(report, reportParts)
        this.setState({traitsSummaryArr: traitSumArr})    
        if (report.dominant_traits && report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
        this.getScorePercetiles(scores)
    }

    async getScorePercetiles(scores){
        const jbody = JSON.stringify({scores:scores}) 
        let res;
        let asres
        res = await EgHttp.put('/reports/percentile', jbody)
        asres = await res.json();
        this.setState({percentileArr:asres})
    }
    getPercentileForOne(score){
        if (this.state.percentileArr && this.state.percentileArr.length>0){
            const ind = this.state.percentileArr.findIndex(({ trait_id }) => trait_id === score.trait.id )
            if (ind > -1){
                let finalPerc =parseFloat((this.state.percentileArr[ind].perc*100).toFixed(1))
                if (finalPerc === 0) finalPerc=0.1
                if (finalPerc === 100) finalPerc=99.9
                return " " + finalPerc + "% "
            }
        }
    }
    
    closeExtended(){
        this.setState({extendedOpen: false})
    }

    openExtendedTraits(){
        this.setState({extendedOpen: true})
    }

    openReadMore(index){
        this.setState({extendedOpen: true})
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            {this.state.report && this.state.report.assessment && this.state.report.assessment.type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT?
            <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'1vh', paddingLeft:10, paddingRight:10}}>
                <Typography variant={'h3'} className={classes.invalid}>{ t('Reports - Summary - Risk not measured')}</Typography>
            </Grid>:
            <Grid item xs={12} className={classes.formRow}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Grid container style={{marginBottom:'3vh'}}>
                        <Grid item xs={8} className={classes.boxTitle}>
                            <Typography variant={'h4'}  style={{paddingTop:'2vh', paddingBottom:'2vh', fontWeight:600}}>{t('Reports - Summary - Risk Analysis')} </Typography>
                        </Grid>
                        <Grid xs={4} style={{display:'flex', justifyContent:'end'}} className={classes.boxTitle} >
                           
                        </Grid>
                    </Grid>
                    <div>
                    {this.state.report && this.state.report.assessment?
                    <Grid container style={{marginBottom:'1vh',display:'flex', justifyContent:'center',}}>
                        <Grid xs={1}></Grid>
                        <Grid xs={3} style={{paddingInline:10, display:'flex', alignItems:'center', justifyContent:'end'}} >
                            
                        </Grid>
                        <Grid xs={4} style={{display:'flex', alignItems:'center', }}>
                            <Typography variant={'h4'} style={{textAlign:'center', fontWeight:600,fontSize:16, }}>
                            {t('Report - Risk - Overall Counterproductive Risk Potential')}
                            </Typography>
                        </Grid>
                        <Grid xs={2}>
                            <EgRiskBar severity={this.state.report.overall_risk} width={200} className={classes.riskBar} isMedLow={this.state.isMedLow}></EgRiskBar>
                        </Grid>
                        <Grid xs={2}></Grid>
                        <Divider style={{width:'80%', marginTop:'2.5vh', marginBottom:'1.5vh',padding:0,backgroundColor:'#f6f6f6' }}/>
                    </Grid>:''}
                    </div>
                    {this.state.report && this.state.report.assessment && this.state.sortedScores?
                    this.state.sortedScores.map((score, index) => (
                        score.trait.father_id===RISK_CATEGORY_FATHER?
                        <div style={{position:'relative'}}  /*onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}*/>
                        <Grid container style={{paddingTop:'0vh', paddingBottom:'0vh',display:'flex', justifyContent:'center', position:'relative'}}>
                                <Grid xs={1}></Grid>
                                <Grid xs={3} style={{paddingInline:10, borderInlineEnd:'solid 1px black',display:'flex', alignItems:'center', justifyContent:'end'}} >
                                    <Typography variant={'h4'} style={{textAlign:'end', fontWeight:500, }}>
                                        {LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name}
                                    </Typography>
                                </Grid>
                                <Grid xs={3} style={{display:'flex', alignItems:'center'}}>
                                    <EgHtmlToolTip title={LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name} body={t('Report - Stats - This score is higher than') + this.getPercentileForOne(score) + t('Report - Stats - of the general population')} context={'stats'}
                                    innerElement={
                                        <div style={{width:'100%'}}>
                                        <CompareBar wide={true} variant="determinate" value={score.trait_adjusted_score } className={classes.valBar} isref={true}></CompareBar>
                                        </div>
                                    }/>
                                </Grid>
                                <Grid xs={1} style={{display:'flex', alignItems:'center', paddingInline:10}}>
                                    <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{Math.floor(score.trait_adjusted_score)}</span><span style={{fontSize:9}}>/100</span></Typography>
                                </Grid>
                                <Grid xs={2} style={{display:'flex', alignItems:'center'}}>
                                    <EgRiskPatch  value={Math.floor(score.trait_adjusted_score)} negative={score.trait.dark} boundaries={score.trait.boundaries}/>                            
                                </Grid>
                                <Grid xs={2}></Grid>
                            <Divider style={{width:'80%', marginTop:'1vh', marginBottom:'1vh',padding:0,backgroundColor:'#f6f6f6' }}/>
                        </Grid>  
                        
                        {this.state.overlayDiv[index]}
                        </div>
                            :''
                        )):''}
                        <Grid xs={10} style={{display:'flex', justifyContent:'end', paddingInline:40, paddingTop:'0vh'}} >
                            <EgButton secondary="true"  onClick={()=>{this.openReadMore(0)}} className={classes.actionButton}>{t('Report - Single - more info')}</EgButton> 
                        </Grid>
                        <Grid xs={2}></Grid>
                    
                    
                </Paper>
               
            </Grid>

            }
            <Drawer open={this.state.extendedOpen} anchor='right' onClose={this.closeExtended} PaperProps={{ style: {  width:'50vw',overflow:'hidden' } }}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.closeExtended}
                    onKeyDown={this.closeExtended}
                >
                <ExtendedRiskEvaluation report={this.state.report} reportParts={this.state.reportParts}></ExtendedRiskEvaluation>
                </div>
            </Drawer>
            </div>               
    );
  }
}
SingleReportRisk.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportRisk),SingleReportRisk);