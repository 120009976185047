import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TableSortLabel, Table, TableBody, TableCell, TableHead, TableRow, TablePagination } from '@material-ui/core';
import Moment from 'react-moment';
import _ from 'lodash';
import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EgHttp from '../../util/EgHttp';
import Skeleton from '@material-ui/lab/Skeleton'
import SkeletonTable from '../../components/SkeletonTable';
import EgEmptyState from '../../components/EgEmptyState';
import RowActionsHover from '../../components/RowActionsHover';
import AppUtils from '../../util/AppUtils';


const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      width:'100%',
      minHeight:'73vh',
      overflow:'auto'
    },
    tableCell:{
        fontSize: 14,
        fontWeight:400,
        color:"#272931",
        border:0,
        paddingLeft:10,
        paddingRight:10,
        textAlign:'start',
        borderRight:'1px solid #F0F0F0',
        overflowWrap:'anywhere'
    },
    tableInnerCell:{ 
        position:"relative",
        padding:0,
        borderBottom:'1px solid #F0F0F0',
    },
    tableHeadCell:{
        paddingTop:5,
        paddingBottom:5,
        fontSize: 12,
        fontWeight:400,
        color:"#5F6066",
        textAlign:'start',
        borderBottom:'1px solid #F0F0F0'
    },
    tableRowInner:{
        minHeight:"6.5vh",
        maxHeight:"6.5vh",
        height:"6.5vh",
        padding:0, 
    },
    tableRowBody:{
        padding:10,
        position:"relative", 
    },
    table:{
        position:"relative",
    },
  });
 

class LookalikeCampaignTable extends React.Component {
  
    state = {
        open: this.props.open,
        overlayDiv: [],
        rows: this.props.rows,
        moreMenuOpen: [],
        loading:true,
        searchTerm:this.props.searchTerm,
        rowsPerPage:10,
        page:0,
        order:'desc',
        orderBy:'created_at'
    };

    constructor(props) {
        super(props);  
        this.refresh = this.refresh.bind(this);     
        this.confirmDelete = this.confirmDelete.bind(this)  
        this.closeConfirm = this.closeConfirm.bind(this) 
        this.deleteCampaign = this.deleteCampaign.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.editCampaign = this.editCampaign.bind(this)
        this.openCampaign = this.openCampaign.bind(this)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.rows!==this.state.rows ){             
            this.setState({rows:nextProps.rows});           
        }
        if (nextProps.loading!==this.state.loading ){             
            this.setState({loading:nextProps.loading});           
        }
        if (nextProps.searchTerm!==this.state.searchTerm ){             
            this.setState({searchTerm:nextProps.searchTerm});           
        } 
        if (nextProps.teams!==this.state.teams ){             
            this.setState({teams:nextProps.teams});           
        } 
    }

    closeConfirm(){
        this.setState({confirmDeleteDialog:null});
    }

    // Open campaign form in edit mode
    editCampaign(index){
        this.props.editCampaign(this.state.rows[index].id)
    }
    
    refresh(code, msg){
        this.props.refresh(code, msg);
    }

    confirmDelete(index){
        const { t } = this.props; 
        this.setState({deleteIndex:index})
        this.setState({confirmDeleteDialog: <ConfirmationDialog title={t("Lookalike - Table - Delete Campaign")} message={t("Lookalike - Table - Are you sure")}  confirm={this.deleteCampaign} cancel={this.closeConfirm} open={true}></ConfirmationDialog>})
    }
    
    // Delete campaign
    async deleteCampaign(){
        const { t } = this.props;  
        this.setState({confirmDeleteDialog:null}); 
        const id = this.state.rows[this.state.deleteIndex].id
        let response = await EgHttp.delete('/lookalike/campaign/' + id) 
        if (response.status===200){
            _.remove(this.state.rows, {
                id: id
            });
        }
        this.setState({overlayDiv:[]})
        this.props.refresh("SUCCESS", t('Lookalike - Table - Campaign was deleted successfully'));
        EventLog.insert(this.props.admin.org.id, 0, EventLog.DELETE_CAMPAIGN, this.props.admin.account.id)
        return;   
    }

    setHover(e, onOff, index){
        const { t } = this.props;
        let item, items = []
        const submenuActions=[  {text:t('Lookalike - Table - Edit Campaign'), action:this.editCampaign, disabled:false},
                                {text:t('Lookalike - Table - Delete Campaign'), action:this.confirmDelete, disabled:false},
                             ]
        if (onOff){ 
            item = <RowActionsHover mainActionName={t('Lookalike - Table - Open Campaign')} mainAction={this.openCampaign} rowIndex={index} submenuActions={submenuActions} rowsPerPage={this.state.rowsPerPage} page={this.state.page}/>
        }
        else{
            item = null;
            this.setState({moreMenuOpen:[]});
        }
        items[index] = item;
        this.setState({overlayDiv:items});
    }

    handleChangePage(event, newPage){
        this.setState({page:newPage})
    }
    handleChangeRowsPerPage(event){
        this.setState({rowsPerPage: parseInt(event.target.value, 10)})
    }
    createSortHandler = property => event => {
        this.onRequestSort(event, property);
    };
    onRequestSort = (event, property) => {
        
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }    
        this.setState({ order, orderBy });       
    };  
    openCampaign(index){
        this.props.openCampaign(this.state.rows[index].id)
    }
    getTeamName(tid){
        if (!this.state.teams) return null;
        const teamlInd = this.state.teams.findIndex(({ id }) => id === tid )
        if (teamlInd > -1) return this.state.teams[teamlInd].name
        else return null
    }
    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
        <div style={{position:'relative'}}>
        <Grid container spacing={3} style={{}}>
            <Grid item xs={12 } style={{height:'100%'}}>
                {this.state.loading || (this.state.rows && this.state.rows.length)?
                <div>
                <Paper className={classes.paper} style={{height:'75vh'}}>
                <Table className={classes.table} aria-label="simple table" >
                    <TableHead>
                        <TableRow> 
                            <TableCell key={'forposition'} sortDirection={this.state.orderBy === 'forposition' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'25%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('forposition')} active={this.state.orderBy === 'forposition'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Lookalike - Table - For Position')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'teamname'} sortDirection={this.state.orderBy === 'teamname' ? this.state.order : false} className={classes.tableHeadCell} align="start" style={{width:'25%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('teamname')} active={this.state.orderBy === 'teamname'}>
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Teams - Table - Name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'startdate'} className={classes.tableHeadCell} align="start" style={{width:'15%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('startdate')} active={this.state.orderBy === 'startdate'}>    
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Lookalike - Table - Campaign Start Date')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'cands'} className={classes.tableHeadCell} align="start" style={{width:'10%', textAlign:'start'}}>
                                <TableSortLabel style={{flexDirection:'initial'}} direction={this.state.order} onClick={this.createSortHandler('cands')} active={this.state.orderBy === 'cands'}>    
                                    {this.state.loading?<Skeleton style={{width:100}}/>:t('Lookalike - Table - Candidates')}
                                </TableSortLabel>
                            </TableCell>
                            
                            <TableCell className={classes.tableHeadCell} style={{width:'30%'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody >
                    {
                    this.state.loading?
                    <TableRow key={'skeleton1'} className={classes.tableRowBody} >
                        <TableCell className={classes.tableInnerCell} colSpan={5}>
                            <SkeletonTable style={{width:'100%'}}></SkeletonTable>
                        </TableCell>
                    </TableRow>
                    :
                    _.slice(this.state.rows.sort(AppUtils.getSorting(this.state.order, this.state.orderBy)),this.state.page*this.state.rowsPerPage, (this.state.page+1)*this.state.rowsPerPage).map((row, index) => (
                        
                        <TableRow key={row.id} className={classes.tableRowBody} >
                            <TableCell className={classes.tableInnerCell} colSpan={5} onMouseEnter={(event) => this.setHover(event, true, index)} onMouseLeave={(event) => this.setHover(event, false,index)}> 
                                <div>
                                    <Table className={classes.tableInner} aria-label="inner table" >
                                    <TableBody >
                                        <TableRow key={row.name + "in"} className={classes.tableRowInner} >
                                            <TableCell className={classes.tableCell} align="left" style={{width:'25%'}}>{row.for_position}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'25%'}}>{this.getTeamName(row.team)}</TableCell>
                                            <TableCell className={classes.tableCell} align="left" style={{width:'15%'}}><Moment format="MMM DD, YYYY">{row.created_at}</Moment></TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'10%'}}>{row.lookalike_campaign_compared_candidates?row.lookalike_campaign_compared_candidates.length:0}</TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'30%',borderRight:0}}></TableCell>
                                        </TableRow>
                                    </TableBody >
                                    </Table>
                                   
                                </div>
                                {this.state.overlayDiv[index]}
                                {this.state.moreMenuOpen[index]}   
                            </TableCell>
                        </TableRow>   
                    ))}
                    </TableBody>
                </Table>
                
                </Paper>
                <TablePagination component="div"
                                count={this.state.rows?this.state.rows.length:0}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                labelRowsPerPage={t('General - Rows per page')} 
                                >
                </TablePagination>
                </div>
                :
                <EgEmptyState title={t('Lookalike - Table - No Campaigns Found')} emptyAction={this.props.addNewCampaign} emptyActionTxt={t('Lookalike - Table - Open New Campaign')}/>
                }
            </Grid>  
        </Grid>
        {this.state.confirmDeleteDialog}
        {this.state.confirmMoveDialog}
        {this.state.assignDialog}
        </div>      
      
        );
    }
}
LookalikeCampaignTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(LookalikeCampaignTable),LookalikeCampaignTable)))