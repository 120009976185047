import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {  Table, TableBody, TableCell,TableRow, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import LangPicker from '../../../util/LangPicker';
import { ValidityLevels } from '../../../util/constants/ValidityLevels';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EgValidityPatch from '../../../components/EgValidityPatch';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import FavoriteBorderOutlinedIcon from '@material-ui/icons//FavoriteBorderOutlined';
import GradeBar from '../../../components/GradeBar';



const Accordion = withStyles({
    root: {
        borderTop: '1px solid #f6f6f6',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
        width:'100%'
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#fffffff',
      marginBottom: -1,
      minHeight: '6vh',
      '&$expanded': {
        minHeight: '6vh',
        backgroundColor:'#fafafa'
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      width:'100%'
    },
  }))(MuiAccordionDetails);

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
  });

class SocialDesirabiityAnalysis extends React.Component {
  
    constructor(props) {
        super(props); 
        this.intializeData = this.intializeData.bind(this);         
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:null,
    };
    
    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
        if (nextProps.expanded!==this.state.expanded ){
            this.setState({expanded:nextProps.expanded});  
        }
        
    }
    
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    intializeData(report, reportParts){
        this.setState({report})
        this.setState({reportParts})
        if (report){
          const ind = report.assessment.assessment_scores.findIndex(({ trait }) => trait.id === 3000 )
            if (ind > -1){
                this.setState({sdScore:report.assessment.assessment_scores[ind]})
            }
        }
    }
   
    

    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            <Accordion className={classes.accord}  expanded={this.state.expanded === 'social'} onChange={(event)=>{this.props.handleChange(event,'social')}}>
                <AccordionSummary style={{width:'100%'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Grid container style={{paddingTop:'0vh', paddingBottom:'0vh',display:'flex', justifyContent:'center', position:'relative'}}>
                                <Grid xs={3}></Grid>
                                <Grid xs={3} style={{paddingInline:10, display:'flex', alignItems:'center', justifyContent:'start'}} >
                                    <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                        {t('Report - Validity - Social Desirability')}
                                    </Typography>
                                </Grid>
                                <Grid xs={2} style={{display:'flex', alignItems:'center'}}>
                                
                                </Grid>
                                <Grid xs={2} style={{display:'flex', alignItems:'center'}}>
                                    <EgValidityPatch validityLevel={this.state.report&&this.state.report.validity_obj? this.state.report.validity_obj.social:ValidityLevels.UNKNOWN}/>                                                                                                     
                                </Grid>
                                <Grid xs={2}></Grid>
                        
                    </Grid> 
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid xs={12} style={{paddingInlineStart:20,  paddingTop:'0.5vh'}}>
                            <Table><TableBody><TableRow>
                            <TableCell style={{border:0, padding:5, width:0, paddingBottom:0}}><FavoriteBorderOutlinedIcon style={{fontSize:18, color:'#666666'}}/></TableCell>
                            <TableCell style={{border:0, padding:5}}><Typography variant={'h3'} >{t('Report - Validity - Social Desirability')}</Typography></TableCell>
                            </TableRow></TableBody></Table>
                            
                        </Grid>
                        <Grid xs={12} style={{paddingInline:52, paddingBottom:'3vh'}}>
                            <Typography variant={'h5'} style={{color:'#58666B', textAlign:'start'}}>{t('Report - Time - social desirability explained')}</Typography>
                        </Grid>
                        <Grid xs={12} style={{paddingInline:52, paddingBottom:'6vh'}}>
                           
                            {this.state.sdScore?
                              
                              <Grid container style={{paddingTop:'1vh', paddingBottom:'0vh',display:'flex', justifyContent:'start'}}>
                                
                                <Grid xs={2} style={{paddingInline:2,paddingTop:6,paddingBottom:6, borderInlineEnd:'solid 1px black',display:'flex', alignItems:'center', justifyContent:'start'}} >
                                    <Typography variant={'h4'} style={{textAlign:'end', fontWeight:400, }}>
                                        {LangPicker.current() === 'en'?this.state.sdScore.trait.display_name:this.state.sdScore.trait.trait_localized[0].localized_name}
                                    </Typography>
                                </Grid>
                                <Grid xs={4} style={{display:'flex', alignItems:'center'}}>
                                    {this.state.sdScore.trait_adjusted_score?
                                    <GradeBar variant="determinate" wide={true} value={this.state.sdScore.trait_adjusted_score} negative={true} boundaries={this.state.sdScore.trait.boundaries}  className={classes.valBar}></GradeBar>:
                                    <GradeBar variant="determinate" wide={true} value={this.state.sdScore.trait_weighted_score*100/this.state.sdScore.trait_potential_score} negative={true} boundaries={this.state.sdScore.trait.boundaries}  className={classes.valBar}></GradeBar>
                                    }
                                </Grid>
                                <Grid xs={1} style={{display:'flex', alignItems:'center', paddingInline:10, paddingInlineEnd:40}}>
                                    {this.state.sdScore.trait_adjusted_score?
                                    <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{this.state.sdScore.trait_adjusted_score}</span><span style={{fontSize:9}}>/100</span></Typography>:
                                    <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{Math.floor(this.state.sdScore.trait_weighted_score*100/this.state.sdScore.trait_potential_score)}</span><span style={{fontSize:9}}>/100</span></Typography>
                                    }
                                </Grid>
                                <Grid xs={4} style={{display:'flex', alignItems:'center'}}>
                                </Grid>
                               
                            </Grid>  

                    
                            :''
                            }
                      
                   
                        </Grid>
                    </Grid>
                    
                </AccordionDetails>
            </Accordion>
                
    );
  }
}
SocialDesirabiityAnalysis.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SocialDesirabiityAnalysis),SocialDesirabiityAnalysis);