import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TableBody, TableRow, TableCell, Table } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
})

class SkeletonTable extends React.Component {
  
    render() {
        return (
            
             <Table style={{width:'100%'}} aria-label="simple table" >
                 <TableBody>
                     <TableRow style={{border:0}}>
                         <TableCell style={{border:0}}><Skeleton width={110}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={100}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={150}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={95}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={40}></Skeleton></TableCell>
                     </TableRow>
                     <TableRow>
                         <TableCell style={{border:0}}><Skeleton width={70}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={65}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={140}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={80}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={80}></Skeleton></TableCell>
                     </TableRow>
                     <TableRow>
                         <TableCell style={{border:0}}><Skeleton width={90}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={90}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={100}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={80}></Skeleton></TableCell>
                         <TableCell style={{border:0}}><Skeleton width={60}></Skeleton></TableCell>
                     </TableRow>
                 </TableBody>
             </Table>
            
           
        );
    }
}

SkeletonTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(SkeletonTable),SkeletonTable)))