import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Box, Slider } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';


const styles = theme => ({
    root: {
      display: 'flex',
      width:'100%'
    },
})

const PrettoSlider = styled(Slider)({
    color: '#e1e1e1',
    marginBottom:5,
    '& .MuiSlider-rail': {
        border: '4px solid #e1e1e1',
        borderRadius:8,
      },
    '& .MuiSlider-track': {
        height:10,
        color: '#C1A5FF',
        borderRadius:8,
      },
    '& .MuiSlider-thumb': {
      height: 18,
      width: 18,
      backgroundColor: '#fff',
      border: '2px solid #C1A5FF',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      marginLeft: -4,
      marginBottom: -10,
      
    },
    '& .MuiSlider-markLabel':{
        left:50,
        color:'#333333',
        fontSize:9
    },
    '& .MuiSlider-markLabelActive':{
        border:'1px solid #00AC83',
        borderRadius:4,
        minWidth:250,
        padding:5,
        backgroundColor:'#11D6A7',
        color:'#ffffff',
        left:45,
    },
    '& .MuiSlider-mark':{
        width:2,
        height:10,
        color:'#ffffff'
    }
    
  });
  
class TraitRangeSlider extends React.Component {
  
    state = {
        selected: -1,
        availAnswers:null,
        lastQuestion:null
    };

    constructor(props) {
        super(props);  
        this.changeValue = this.changeValue.bind(this); 
        this.getMarks = this.getMarks.bind(this);
        this.setState({availAnswers: this.props.availAnswers})
    }
    componentDidMount(){
        this.setState({trait:this.props.trait})
        this.setState({value: this.props.trait.value})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.availAnswers!==this.state.availAnswers ){   
            this.setState({availAnswers:nextProps.availAnswers});       
        } 
        if (nextProps.trait!==this.state.trait ){   
            this.setState({value:nextProps.trait.value});
        } 

    }

    changeValue(event,value){
        this.props.update(this.props.trait.id, value);
        this.setState({value:parseInt(value)});
    }

    getMarks(){
        const { t } = this.props;  
        let answersObjArr = []
        answersObjArr =  [{value:0,label:t('Slider - Very Low')},{value:25,label:t('Slider - Low')},{value:50,label:t('Slider - Med')},{value:75,label:t('Slider - High')},{value:100,label:t('Slider - Very High')}]
        return answersObjArr;
    }

    render(){
        return (
            <Box sx={{ width: 340 }}>
                
                <PrettoSlider style={{width:'100%', height:10}}
                    value={this.state.value?this.state.value:0}
                    track={true}
                    aria-label="answers-slider"
                    defaultValue={50}
                    step={25}
                    marks={this.getMarks()}
                    orientation='horizontal'
                    onChange={this.changeValue}
                />
               
            </Box>
        )
    }
}   
TraitRangeSlider.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(TraitRangeSlider));