import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import env from "react-dotenv";
import googleIcon from '../../img/google.png';
import msIcon from '../../img/ms365.png';
import linkedIcon from '../../img/linkedin.png';
import Fab from '@material-ui/core/Fab';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    googleIcon:{
        width:20,
    },
    msIcon:{
        width:30,
    },
    linkedIcon:{
        width:30,
    },
    socialButton:{
        background: theme.palette.whitebg,
        border: '1px solid #F0F0F0',
        color:"white",
        
        fontSize: 18,
        fontWeight:800,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#efefef',
        }
    },
})
  
class SocialRow extends React.Component {
  
  state = {
    page: this.props.page
  };

  constructor(props) {
    super(props);   
    this.signup = this.signup.bind(this);
    this.signin = this.signin.bind(this);
  } 

  signup(){
    this.props.history.push('/signup')
  }

  signin(){
    this.props.history.push('/signin')
  }
  
  render() {
    const { classes } = this.props;
    return (
        <Grid container >
            <Grid item xs={4} >
                <a href={env.API_URL + "/sign/auth/google"}>
                <Fab className={classes.socialButton} aria-label="add" >
                    <img src={googleIcon} className={classes.googleIcon} alt="logo" />  
                </Fab>
                </a>
            </Grid>
            <Grid item xs={4} >
                <a href={env.API_URL + "/sign/auth/azure"}>
                <Fab className={classes.socialButton} aria-label="add">
                    <img src={msIcon} className={classes.msIcon} alt="logo" />  
                </Fab>
                </a>
            </Grid>
            <Grid item xs={4} >
                <a href={env.API_URL + "/sign/auth/linkedin"}>
                <Fab className={classes.socialButton} aria-label="add">
                    <img src={linkedIcon} className={classes.linkedIcon} alt="logo" />  
                </Fab>
                </a>
            </Grid>
        </Grid>
    );
  }
}
SocialRow.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };
  
export default (withTranslation()(withCookies(withRouter((withStyles(styles))(SocialRow),SocialRow))))