import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import GradeBar from '../../components/GradeBar'
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import Drawer from '@material-ui/core/Drawer';
import LangPicker from '../../util/LangPicker';
import ReportsManager from '../../managers/ReportsManager';
import ExtendedPersonalityEvaluation from './ExtendedPersonalityEvaluation';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import EgHttp from '../../util/EgHttp';
import EgHtmlToolTip from '../../components/EgHtmlToolTip';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';

const VIDICTIVENESS = 1002

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0, 
    },
    
    tableInner:{
        paddingTop:'2vh',
        paddingBottom:'2vh'
    },
    
    boxTitle:{
        paddingInline:20,
        paddingTop:'0.5vh',
        
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    summaryBox:{
        border:'1px solid #E4E4E4',
        height:'77vh',
        overflow:'auto',
        paddingBottom:20,
    },
    
    actionButton:{
        marginLeft:10,
        marginRight:10,
        backgroundColor:'#ffffff00'
    } ,
    invalid:{
        backgroundColor: "#EA555533",
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    },
    valBar:{
        paddingInline:10
    },
    
});

class SingleReportPersonality extends React.Component {
  
    constructor(props) {
        super(props);            
        console.info("REPORT Personality")
        this.openExtendedTraits = this.openExtendedTraits.bind(this);
        this.closeExtended = this.closeExtended.bind(this)
        this.compareToAvg = this.compareToAvg.bind(this)
        this.compareCpToAvg = this.compareCpToAvg.bind(this)
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        traitsSummaryArr:[],
        execSummaryArr:[],
        invalidReport:false ,
        percentileArr:[],
    };
   
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
    }

    intializeData(report, reportParts){    
        if (report){
            let scores = report.assessment.assessment_scores
            if (report.assessment.type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT){
                scores = scores.filter(score => score.trait_id !== VIDICTIVENESS)
            }
            const sortedScores = scores.sort((a,b) => a.trait_adjusted_score?b.trait_adjusted_score - a.trait_adjusted_score : b.trait_weighted_score*100/b.trait_potential_score - a.trait_weighted_score*100/a.trait_potential_score);
            this.setState({sortedScores:sortedScores})
            this.setState({report})
            this.setState({reportParts})
            if (!report) return
            const traitSumArr = ReportsManager.setTraitsSummary(report, reportParts)
            this.setState({traitsSummaryArr: traitSumArr})    
            if (report.dominant_traits && report.dominant_traits[0] === 0){
                this.setState({invalidReport:true})
            }
            this.getScorePercetiles(scores)
        }
    }

    async getScorePercetiles(scores){
        const jbody = JSON.stringify({scores:scores}) 
        let res;
        let asres
        res = await EgHttp.put('/reports/percentile', jbody)
        asres = await res.json();
        this.setState({percentileArr:asres})
        console.log(asres)
    }
    getPercentileForOne(score){
        if (this.state.percentileArr && this.state.percentileArr.length>0){
            const ind = this.state.percentileArr.findIndex(({ trait_id }) => trait_id === score.trait.id )
            if (ind > -1){
                let finalPerc =parseFloat((this.state.percentileArr[ind].perc*100).toFixed(1))
                if (finalPerc === 0) finalPerc=0.1
                if (finalPerc === 100) finalPerc=99.9
                return " " + finalPerc + "% "
            }
        }
    }
    
    closeExtended(){
        this.setState({extendedOpen: false})
    }

    openExtendedTraits(context){
        this.setState({extendedContext:context},()=> this.setState({extendedOpen: true}))
        
    }

    compareToAvg(){
        const cur = this.state.compared
        this.setState({compared:!cur})
    }
    compareCpToAvg(){
        const cur = this.state.comparedcp
        this.setState({comparedcp:!cur})
    }

    getAvgVal(tid){
        const avgs = this.props.traitAverages;
        if (avgs){
            const ind = avgs.findIndex(({ trait_id }) => trait_id === tid )
            if (ind > -1){
                return avgs[ind].adj_avg
            }
        }
    }
    isValidityIssues(id){
        if (this.state.report){
            if (this.state.report.validity_obj && this.state.report.validity_obj.tooSlowAnswers && this.state.report.validity_obj.tooSlowAnswers.length > 0){
                const ind = this.state.report.validity_obj.tooSlowAnswers.findIndex(({ trait }) => trait.id === id )
                if (ind > -1){
                    return true
                }
            }
            if (this.state.report.validity_obj && this.state.report.validity_obj.discrapencyArr && this.state.report.validity_obj.discrapencyArr.length > 0){
                const indd = this.state.report.validity_obj.discrapencyArr.findIndex((t) => t.trait_id===id )
                if (indd > -1){
                    return true
                }
            }
            return false
        }
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const thisIns = this
        return (
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:20, borderTop:'1px solid #e8e8e8'}}>
            {this.state.invalidReport?
            <Grid item xs={12} style={{paddingTop:'1vh', paddingBottom:'1vh', paddingLeft:10, paddingRight:10}}>
                <Typography variant={'h3'} className={classes.invalid}>{ t('Reports - Summary - Invalid Assessment')}</Typography>
            </Grid>:
            <Grid item xs={12}>
            <Grid container>
            <Grid item xs={6} className={classes.formRow} style={{paddingInlineEnd:10}}>
                <Paper elevation={0} className={classes.summaryBox}>
                    <Grid container>
                        <Grid item xs={6} className={classes.boxTitle}>
                            <Typography variant={'h4'}  style={{paddingTop:'2vh', paddingBottom:'2vh', fontWeight:600}}>{t('Report - Traits - Productive Personaliy Traits')} </Typography>
                        </Grid>
                        <Grid xs={6} style={{display:'flex', justifyContent:'end', paddingInline:'2vw'}} className={classes.boxTitle} >
                        < FormControlLabel style={{marginInlineStart:5}} control={<Checkbox checked={this.state.compared} onChange={this.compareToAvg} color="primary" size='small'/>} label={<Typography variant={'h4'}>{t("Report - Traits - Compare to average")}</Typography>} />
                        </Grid>
                    </Grid>

                    <Grid container style={{paddingTop:'1vh', paddingBottom:'1vh', paddingInline: 20,display:'flex', justifyContent:'start'}}>
                    <Grid xs={12}>
                            <div className={classes.tableInner} style={{paddingTop:'1vh', paddingBottom:'2vh', cursor: 'pointer'}}  onClick={()=>{this.openExtendedTraits('productive')}} >
                        
                                {this.state.sortedScores?
                                this.state.sortedScores.map((score, index) => (
                                    !score.trait.dark && !score.trait.is_dominant && score.trait.is_display?
                                   
                                    <Grid container style={{paddingTop:'1vh', paddingBottom:'0vh',display:'flex', justifyContent:'start'}}>
                                     
                                    <Grid xs={4} style={{paddingInline:10,paddingTop:6,paddingBottom:6, display:'flex', alignItems:'center', justifyContent:'start', minHeight:'4vh'}} >
                                        <Typography variant={'h5'} style={{textAlign:'start', fontWeight:400, }}>
                                           {LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name}
                                        </Typography>
                                       
                                    </Grid>
                                    <Grid xs={6} style={{display:'flex', alignItems:'center', borderInlineStart:'solid 1px black'}}>
                                        
                                        <EgHtmlToolTip title={LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name} body={t('Report - Stats - This score is higher than') + this.getPercentileForOne(score) + t('Report - Stats - of the general population')} context={'stats'}
                                        innerElement={
                                            <div style={{width:'100%'}}>
                                            {score.trait_adjusted_score?
                                            <GradeBar variant="determinate" wide={true} value={score.trait_adjusted_score} negative={score.trait.dark} boundaries={score.trait.boundaries} avg={this.state.compared?this.getAvgVal(score.trait_id):0} className={classes.valBar}></GradeBar>:
                                            <GradeBar variant="determinate" wide={true} value={score.trait_weighted_score*100/score.trait_potential_score} negative={score.trait.dark} boundaries={score.trait.boundaries} avg={this.state.compared?this.getAvgVal(score.trait_id):0}  className={classes.valBar}></GradeBar>
                                            }
                                            </div>
                                        } />
                                        
                                    </Grid>
                                    <Grid xs={2} style={{display:'flex', alignItems:'center', paddingInline:10}}>
                                       
                                        {score.trait_adjusted_score?
                                        <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{score.trait_adjusted_score}</span><span style={{fontSize:9}}>&#47;100</span></Typography>:
                                        <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{Math.floor(score.trait_weighted_score*100/score.trait_potential_score)}</span><span style={{fontSize:9}}>&#47;100</span></Typography>
                                        }
                                        
                                         {thisIns.isValidityIssues(score.trait.id)?
                                            <EgHtmlToolTip title={t('Report - Validity - Validity Issues')} body={t('Report - Validity - There appear to be validity issues')} context={'warn'}
                                                innerElement={
                                                <WarningRoundedIcon style={{marginInline:5,color:'#F9A846', marginTop:0, cursor:'pointer'}} onClick={(event)=>{this.props.moveTo(event,3)}} />}
                                            />:''}
                                    </Grid>
                                    
                                    <Divider style={{width:'90%', marginTop:'0.5vh', marginBottom:'0vh',padding:0,backgroundColor:'#f6f6f6' }}/>
                                    </Grid>  
                                    
                                    :''
                                )):''}
                            
                        </div>
                        <Grid xs={12} style={{display:'flex', justifyContent:'end', paddingInline:40, paddingTop:'0vh'}} >
                            <EgButton secondary="true"  onClick={()=>{this.openExtendedTraits('productive')}} className={classes.actionButton}>{t('Report - Single - more info')}</EgButton> 
                        </Grid>
                    </Grid>
                    
                    
                    </Grid>
                    
                </Paper>
            </Grid>
            
            <Grid item xs={6} className={classes.formRow}>
            
            <Paper elevation={0} className={classes.summaryBox}>
                <Grid container>
                    <Grid item xs={6} className={classes.boxTitle}>
                        <Typography variant={'h4'}  style={{paddingTop:'2vh', paddingBottom:'2vh', fontWeight:600}}>{t('Report - Traits - Counterproductive Personaliy Traits')} </Typography>
                    </Grid>
                    <Grid xs={6} style={{display:'flex', justifyContent:'end', paddingInline:'2vw'}} className={classes.boxTitle} >
                    <FormControlLabel style={{marginInlineStart:5}} control={<Checkbox checked={this.state.comparedcp} onChange={this.compareCpToAvg} color="primary" size='small'/>} label={<Typography variant={'h4'}>{t("Report - Traits - Compare to average")}</Typography>} />
                    </Grid>
                </Grid>

                <Grid container style={{paddingTop:'1vh', paddingBottom:'1vh', paddingInline: 20,display:'flex', justifyContent:'start'}}>
                    <Grid xs={12}>
                    <div className={classes.tableInner} style={{paddingTop:'1vh', paddingBottom:'2vh', cursor: 'pointer'}} onClick={()=>{this.openExtendedTraits('counter')}}>
                    
                            {this.state.sortedScores?
                            this.state.sortedScores.map((score, index) => (
                                score.trait.dark && !score.trait.is_dominant && score.trait.is_display?
                               
                                <Grid container style={{paddingTop:'1vh', paddingBottom:'0vh',display:'flex', justifyContent:'start'}}>
                                
                                <Grid xs={4} style={{paddingInline:10,paddingTop:6,paddingBottom:6, borderInlineEnd:'solid 1px black',display:'flex', alignItems:'center', justifyContent:'start', minHeight:'4vh'}} >
                                    <Typography variant={'h5'} style={{textAlign:'start', fontWeight:400, }}>
                                        {LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name}
                                    </Typography>
                                </Grid>
                                <Grid xs={6} style={{display:'flex', alignItems:'center'}}>
                                    <EgHtmlToolTip title={LangPicker.current() === 'en'?score.trait.display_name:score.trait.trait_localized[0].localized_name} body={t('Report - Stats - This score is higher than') + this.getPercentileForOne(score) + t('Report - Stats - of the general population')} context={'stats'}
                                        innerElement={
                                            <div style={{width:'100%'}}>
                                                {score.trait_adjusted_score?
                                                    <GradeBar variant="determinate" wide={true} value={score.trait_adjusted_score} negative={score.trait.dark} boundaries={score.trait.boundaries}  className={classes.valBar} avg={this.state.comparedcp?this.getAvgVal(score.trait_id):0}></GradeBar>:
                                                    <GradeBar variant="determinate" wide={true} value={score.trait_weighted_score*100/score.trait_potential_score} negative={score.trait.dark} boundaries={score.trait.boundaries}  className={classes.valBar} avg={this.state.comparedcp?this.getAvgVal(score.trait_id):0}></GradeBar>
                                                }
                                            </div>
                                        } />
                                    
                                </Grid>
                                <Grid xs={2} style={{display:'flex', alignItems:'center', paddingInline:10, paddingInlineEnd:40}}>
                                    {score.trait_adjusted_score?
                                    <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{score.trait_adjusted_score}</span><span style={{fontSize:9}}>/100</span></Typography>:
                                    <Typography variant={'h4'} style={{ textAlign:'start'}}><span style={{fontWeight:500, textAlign:'start'}}>{Math.floor(score.trait_weighted_score*100/score.trait_potential_score)}</span><span style={{fontSize:9}}>/100</span></Typography>
                                    }
                                    {thisIns.isValidityIssues(score.trait.id)?
                                        <EgHtmlToolTip title={t('Report - Validity - Validity Issues')} body={t('Report - Validity - There appear to be validity issues')} context={'warn'}
                                            innerElement={
                                            <WarningRoundedIcon style={{marginInline:5,color:'#F9A846', marginTop:0, cursor:'pointer'}} onClick={(event)=>{this.props.moveTo(event,3)}}/>}
                                        />:''
                                    }
                                </Grid>
                                
                                <Divider style={{width:'90%', marginTop:'0.5vh', marginBottom:'0vh',padding:0,backgroundColor:'#f6f6f6' }}/>
                            </Grid>  

                            :''
                        )):''}
                        <Grid xs={12} style={{display:'flex', justifyContent:'end', paddingInline:40, paddingTop:'1vh'}} >
                            <EgButton secondary="true"  onClick={()=>{this.openExtendedTraits('counter')}} className={classes.actionButton}>{t('Report - Single - more info')}</EgButton> 
                        </Grid>
                </div>
                </Grid>
                
                </Grid>
                
            </Paper>
        </Grid>
       </Grid>
        </Grid>
            
            }
            <Drawer open={this.state.extendedOpen}  anchor='right' onClose={this.closeExtended} PaperProps={{ style: {  width:'50vw',overflow:'hidden' } }}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.closeExtended}
                    onKeyDown={this.closeExtended}
                >
                <ExtendedPersonalityEvaluation context={this.state.extendedContext} report={this.state.report} reportParts={this.state.reportParts}></ExtendedPersonalityEvaluation>
                </div>
            </Drawer>
            </div>               
    );
  }
}
SingleReportPersonality.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportPersonality),SingleReportPersonality);