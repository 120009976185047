import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import general_insights from '../../img/general_insights.svg';
import LangPicker from '../../util/LangPicker';
import EgSegmentParser from '../../util/EgSegmentParser'

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    insightBox:{
        border:'1px solid #E4E4E4',
        height:'40vh',
        overflow:'auto'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
  });

class SingleReportExecutiveBox extends React.Component {
  
    /*
    constructor(props) {
        super(props);            
    }*/

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        execSummaryArr:[],
        invalidReport:false,
        execStrength:[],
        execWeakness:[],
        execCounter:[],
    };

    componentDidMount(){
        if (this.props.report) this.setState({report: this.props.report})
        if (this.props.reportParts) this.setState({reportParts: this.props.reportParts})
        this.setExecSummary(this.state.report)
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report!==this.state.report ){
            this.setState({report:nextProps.report});  
            this.setExecSummary(this.state.report)
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts}, ()=>{this.setExecSummary(this.state.report)});  
        }
    }

    async setExecSummary2p0(report){
        const execStrength = this.setExecItems2p0(report, 'execStrength')
        const execWeakness = this.setExecItems2p0(report, 'execWeakness')
        const execCounter = this.setExecItems2p0(report, 'execCounter')
        console.log(execStrength)
        console.log(execWeakness)

        this.setState({execStrength:execStrength,execWeakness:execWeakness,execCounter:execCounter})
    }

    setExecItems2p0(report, category){
        let sortedExec=[]
        if(report && report.report_part && report.report_part.length){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 1 )
            const reparts = this.state.reportParts;
            if (report.report_part[ind].template_id[category] && report.report_part[ind].template_id[category].length>0){
                let execSummaryArr = report.report_part[ind].template_id[category].map(function(pid){
                    const partind = reparts.findIndex(({ id }) => id === pid )
                    if (partind > -1){
                        if (LangPicker.current() === 'en'){
                            return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                        }
                        else{
                            if (reparts[partind].report_template_localized[0]){
                                return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                            }
                            else{
                                return({id: pid, trait_id: reparts[partind].trait_id,text:""})
                            }
                        } 
                    }
                    return null
                })
                execSummaryArr = execSummaryArr.filter(function(e){return e});
            
                // sort the array
                const scores = [...this.props.report.assessment.assessment_scores]
                const sortedScores = scores.sort((a,b) => b.trait_adjusted_score - a.trait_adjusted_score);
                sortedExec = sortedScores.map(function(score){
                    const sumind = execSummaryArr.findIndex(({ trait_id }) => trait_id === score.trait_id )
                    if (sumind > -1){
                        return execSummaryArr[sumind]
                    }
                    return null;
                })
                sortedExec = sortedExec.filter(function(e){return e});
            }
            return sortedExec 
        }
    }

    async setExecSummary(report){
        if (report.version_id && report.version_id >0){
            this.setExecSummary2p0(report)
            return;
        }
        console.log("IN SET EXECUTIVE")
        if(report && report.report_part && report.report_part.length){
            const ind = report.report_part.findIndex(({ structure_section_id }) => structure_section_id === 1 )
            const reparts = this.state.reportParts;
            let extraTextArr = []
            let execSummaryArr = report.report_part[ind].template_id.map(function(pid){
                const partind = reparts.findIndex(({ id }) => id === pid )
                if (partind > -1){
                    if (reparts[partind].display_trait_ref && reparts[partind].display_trait_ref > 0){
                        extraTextArr.push(reparts[partind])
                        return null;
                    }
                    else{
                        if (LangPicker.current() === 'en'){
                            return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].text })
                        }
                        else{
                            if (reparts[partind].report_template_localized[0]){
                                return({id: pid, trait_id: reparts[partind].trait_id,text:reparts[partind].report_template_localized[0].text })
                            }
                            else{
                                return({id: pid, trait_id: reparts[partind].trait_id,text:""})
                            }
                        }
                    }
                }
                return null;
            })
            execSummaryArr = execSummaryArr.filter(function(e){return e});
            if (extraTextArr.length > 0){
                extraTextArr.map(function(part){
                    const partind = execSummaryArr.findIndex(({ trait_id }) => trait_id === part.display_trait_ref )
                    if (partind > -1){
                        execSummaryArr[partind].text = execSummaryArr[partind].text  +"\n"+  part.text;
                    }
                    return null
                })
            }
            this.setState({execSummaryArr: execSummaryArr})    
        }
    }
   
    render() {
        const { classes } = this.props;
        const { t } = this.props;

        return (
            <Paper elevation={0} style={{ overflow:'hidden', height:this.state.invalidReport?'35.3vh':'40vh'}} className={classes.insightBox}  >
                <Typography variant={'h4'} className={classes.boxTitle}>{t('Reports - Single - Executive')} </Typography>
                <Grid container style={{paddingTop:'1vh', paddingBottom:'1vh'}}>
                    <Grid xs={12} style={{borderInlineEnd:'solid 1px #e5e5e5'}}>
                        <Paper elevation={0}   style={{height:'34vh',overflow:'auto', paddingBottom:'2vh'}}>
                            <Grid container>
                               
                                <Grid xs={10} style={{paddingInlineStart:20, paddingInlineEnd:20, paddingTop:'1.5vh'}}>
                                    <Grid container >
                                        {this.state.report && !this.state.report.version_id?
                                        <Grid xs={12}>
                                            <Typography variant={'h4'} style={{lineHeight:2, textAlign:'start' , whiteSpace:'break-spaces'}}>{this.state.execSummaryArr.length?EgSegmentParser.parse(this.state.execSummaryArr[0].text):''}</Typography>
                                            {this.state.execSummaryArr && this.state.execSummaryArr.length>1 ?
                                                <div style={{paddingTop:'2vh', paddingBottom:'1vh',fontWeight:600, lineHeight:1.7, fontSize:16, textAlign:'start'}}>{ t('Reports - Summary - Follow-up Issues')}</div>:''}
                                            {this.state.execSummaryArr && this.state.execSummaryArr.length>1 ?
                                                this.state.execSummaryArr.map((score, index) => (
                                                    index > 0?
                                                        <Typography variant={'h4'} style={{lineHeight:2, textAlign:'start' , whiteSpace:'break-spaces'}}>{this.state.execSummaryArr.length?EgSegmentParser.parse(this.state.execSummaryArr[index].text):''}</Typography>:''
                                                )):''}
                                        </Grid>  
                                        :
                                        <Grid xs={12}>
                                             <Typography variant={'h4'} style={{textAlign:'start', paddingBottom:10, fontWeight:600}}>{t('Report - Single - Strengths')} </Typography>
                                            {this.state.execStrength.map((score, index) => (
                                                <Typography variant={'h5'} style={{lineHeight:2, textAlign:'start' , whiteSpace:'break-spaces'}}>{this.state.execStrength.length?EgSegmentParser.parse(this.state.execStrength[index].text):''}</Typography>
                                            ))}
                                            {this.state.execWeakness.length===0?'':
                                             <Typography variant={'h4'} style={{textAlign:'start', paddingBottom:10, paddingTop:10, fontWeight:600}}>{t('Report - Single - Weaknesses')} </Typography>}
                                            {this.state.execWeakness.map((score, index) => (
                                                <Typography variant={'h5'} style={{lineHeight:2, textAlign:'start' , whiteSpace:'break-spaces'}}>{this.state.execWeakness.length?EgSegmentParser.parse(this.state.execWeakness[index].text):''}</Typography>
                                            ))}
                                             {this.state.execCounter.length===0?'':
                                             <Typography variant={'h4'} style={{textAlign:'start', paddingBottom:10, paddingTop:10, fontWeight:600}}>{t('Report - Single - Issues To Follow')} </Typography>}
                                            {this.state.execCounter.map((score, index) => (
                                                <Typography variant={'h5'} style={{lineHeight:2, textAlign:'start' , whiteSpace:'break-spaces'}}>{this.state.execCounter.length?EgSegmentParser.parse(this.state.execCounter[index].text):''}</Typography>
                                            ))}
                                        </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid xs={2} style={{paddingTop:'2vh', textAlign:'center'}} >
                                    <img src={general_insights}  alt="overall" style={{ paddingInline:'1vw', width:'100%'}}/>
                                </Grid>
                                
                            
                            </Grid>
                        </Paper>
                    </Grid>    
                </Grid>    
            </Paper>
        );
    }
}
SingleReportExecutiveBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportExecutiveBox),SingleReportExecutiveBox);