import i18n from '../i18n/i18n'
import qs from 'qs';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import EgHttp from './EgHttp';

const EgCommonData = {
        
    getTeams: async function(){
        try{
            let response = await EgHttp.get('/teams' ) 
            let rowsres = await response.json();
            return rowsres
        }
        catch(err){
            console.error("Error in EgCommonData:getTeams")
            console.error(err.message)
            return null
        } 
    },
    
    getPositions: async function(){
        try{
            let response = await EgHttp.get( '/lookalike/campaigns')
            let rowsres = await response.json();
            const origres = [...rowsres]
            return origres
        }
        catch(err){
            console.error("Error in EgCommonData:getPositions")
            console.error(err.message)
            return null
        } 
    },

    
}
EgCommonData.propTypes = {
   cookies: instanceOf(Cookies).isRequired
};
export default withCookies(withRouter(EgCommonData));
