import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, FormControl, InputLabel, Select, MenuItem, TextareaAutosize } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgHttp from '../util/EgHttp';
import EgButton from '../components/EgButton';
import EgProgress from '../components/EgProgress';
import EgStatusAlert from '../components/EgStatusAlert';
 
const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    txtFieldName:{
        fontSize: 14,
        fontWeight:500,
        color:"#7F7F7F"
    },
    formRow:{
        padding:10
    },
    actionButton:{
        width:100,
        marginLeft:5,
        marginRight:5
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },

    selectBox:{
        '& .MuiOutlinedInput-input':{
            paddingTop:8,
            paddingBottom:8, 
            fontSize:16,
            textAlign:'left'
        }
    }
    
  });
  
class ReportTemplateEditor extends React.Component {
  
    constructor(props) {
        super(props);           
        this.saveAccount = this.saveAccount.bind(this);  
        this.handleChange = this.handleChange.bind(this)
        this.getReportParts = this.getReportParts.bind(this)
        this.getPersonalityTypes = this.getPersonalityTypes.bind(this)
        this.getReportDisplayTraits = this.getReportDisplayTraits.bind(this)
        this.getText = this.getText.bind(this)
        this.getTextNoTrait = this.getTextNoTrait.bind(this)
        this.updateHebrewText = this.updateHebrewText.bind(this)
        this.updateEnglishText = this.updateEnglishText.bind(this)
        this.showStatusBar = this.showStatusBar.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this);
        
    }

    state = {
        addOpen: this.props.addOpen,
        candidateId: this.props.accountId,
        personalityTypes:[],
        reportParts:[],
        traits:[],
        severities:[{id:0, name:"LOW"}, {id:1, name:"MED"}, {id:2, name:"HIGH"}],
        selectedPersonalityType:0,
        selectedTraitPart:0,
        selectedReportPart:0,
        selectedSeverity:0,
        isTraitDisabled: false,
        isTextChanged:false
    };

   
    componentDidMount(){
        
        this.getPersonalityTypes();
        this.getReportParts();
        this.getReportDisplayTraits()
        this.setState({ loading: null, })
    }

    showStatusBar(message){
        this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }
    handleStatusClose(){
        this.setState({statusBar:null})
    }

    async getPersonalityTypes(){
        this.setState({ loading: <EgProgress />, })
        let response = await EgHttp.get('/backoffice/personalityTypes')
        let rowsres = await response.json();
        this.setState({personalityTypes: rowsres})
        this.setState({ loading: null, })
        return;

    }
    async getReportParts(){
        this.setState({ loading: <EgProgress />, })
        let response = await EgHttp.get('/backoffice/reportStructure')
        let rowsres = await response.json();
        this.setState({reportParts: rowsres})
        this.setState({ loading: null, })
        return;
    }
    async getReportDisplayTraits(){
        this.setState({ loading: <EgProgress />, })
        let response = await EgHttp.get('/backoffice/displayTraits')
        let rowsres = await response.json();
        this.setState({traits: rowsres})
        this.setState({ loading: null, })
        return;
    }

    async getText()
    {
        this.setState({ loading: <EgProgress />, })
        const dominant = this.state.selectedPersonalityType
        const trait = this.state.selectedTraitPart
        const part = this.state.selectedReportPart
        const severity = this.state.selectedSeverity
        let response = await EgHttp.get('/backoffice/text/dominant/'+dominant+'/trait/'+trait+'/part/'+part+'/seveity/'+severity)
        let rowsres = await response.json();
        if (rowsres && rowsres.length>0){
            rowsres[0].en_text?this.setState({englishText: rowsres[0].en_text}):this.setState({englishText: ''})
            rowsres[0].he_text?this.setState({hebrewText: rowsres[0].he_text}):this.setState({hebrewText: ''})
            this.setState({templateId:rowsres[0].id})
        }else{
            this.setState({englishText: ""})
            this.setState({hebrewText: ""})
            this.setState({templateId:0})
        }
        this.setState({ loading: null, })
        return;
        
    }
   async getTextNoTrait(){
    
        this.setState({ loading: <EgProgress />, })
        const dominant = this.state.selectedPersonalityType
        const part = this.state.selectedReportPart
        const severity = this.state.selectedSeverity
        let response = await EgHttp.get('/backoffice/text/dominant/'+dominant+'/trait/'+dominant+'/part/'+part+'/seveity/'+severity)
        let rowsres = await response.json();
        if (rowsres && rowsres.length>0){
            console.log(rowsres[0])
            this.setState({englishText: rowsres[0].en_text})
            this.setState({hebrewText: rowsres[0].he_text})
            this.setState({templateId:rowsres[0].id})
        }else{
            this.setState({englishText: ""})
            this.setState({hebrewText: ""})
            this.setState({templateId:0})
        }
        this.setState({ loading: null, })
        return;
        
    
   }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange(event) {
        
        let iscontinue = false;
        if (this.state.isTextChanged){
            iscontinue = window.confirm("Are you sure you want to make changes without saving first?")
        }
        else{
            iscontinue = true
        }
        if (iscontinue){
            this.setState({isTextChanged:false})
            this.setState({ [event.target.name]: event.target.value });
            
            if (event.target.name === 'selectedReportPart'){
                if (/*event.target.value === 1 ||*/ event.target.value === 4 || event.target.value === 5){
                    this.setState({isTraitDisabled: true})
                    setTimeout(this.getTextNoTrait, 300)
                }
                else{
                    this.setState({isTraitDisabled: false})
                    setTimeout(this.getText, 300)
                }
            }
            else{
                if (/*this.state.selectedReportPart === 1 ||*/ this.state.selectedReportPart === 4 || this.state.selectedReportPart === 5){
                    setTimeout(this.getTextNoTrait, 300)
                }
                else{
                    setTimeout(this.getText, 300)
                }
            }
        }
    }

    updateHebrewText(event){
        if (!this.state.isTextChanged){
            this.setState({isTextChanged:true})
        }
        this.setState({hebrewText:event.target.value})
    }
    updateEnglishText(event){
        if (!this.state.isTextChanged){
            this.setState({isTextChanged:true})
        }
        this.setState({englishText:event.target.value})
    }

    

    async saveAccount(){
        try{
            this.setState({ loading: <EgProgress />, })
            let body = JSON.stringify({
                id:this.state.templateId,
                englishText: this.state.englishText,
                hebrewText: this.state.hebrewText
            })
            let response = await EgHttp.put('/backoffice/save', body)
           
            if (response.status === 200) {
                this.showStatusBar("Text Saved Successfully");
                this.setState({isTextChanged:false})
            }
            else {
                this.showStatusBar("ERROR - Failed to save text!")
                return;
            }
            this.setState({ loading: null, })
          return;
        }
        catch(err){
            console.error(err)
        }
        
    }

    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div>
                {this.state.loading}
                <Paper style={{height:'100%', width:'100vw'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                            <Grid item xs={10} justify="left">
                                <Typography variant={'h1'}>Report Templates Editor</Typography>
                            </Grid>
                            <Grid item xs={2} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton secondary="true" onClick={this.props.close} className={classes.actionButton}>Back</EgButton>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:30}}>
                                <Grid container >
                                    <Grid item xs={3}>
                                        <Typography variant={'h5'} style={{textAlign:'start', paddingBottom:5}}>Personality Type</Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select variant="outlined"
                                                labelId="demo-simple-select-label"
                                                id="selectedPersonalityType"
                                                name="selectedPersonalityType"
                                                value={this.state.selectedPersonalityType}
                                                onChange={this.handleChange}
                                                className={classes.selectBox}
                                            >
                                                {this.state.personalityTypes.map(function(ptype) {
                                                    return <MenuItem value={ptype.id}>{ptype.display_name}</MenuItem>
                                                })}
                                                
                                            </Select>
                                        </FormControl></Grid>
                                    <Grid item xs={9}>
                                        
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={12} className={classes.formRow}>
                                <Grid container >
                                    <Grid item xs={3} style={{paddingRight:10}}>
                                        <Typography variant={'h5'} style={{textAlign:'start', paddingBottom:5}}>Report Part</Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select variant="outlined"
                                                labelId="demo-simple-select-label"
                                                id="selectedReportPart"
                                                name="selectedReportPart"
                                                value={this.state.selectedReportPart}
                                                onChange={this.handleChange}
                                                className={classes.selectBox}
                                            >
                                                {this.state.reportParts.map(function(ptype) {
                                                    return <MenuItem value={ptype.id}>{ptype.template_name}</MenuItem>
                                                })}
                                                
                                            </Select>
                                        </FormControl></Grid>
                                    <Grid item xs={3} style={{paddingRight:10}}>
                                        <Typography variant={'h5'} style={{textAlign:'start', paddingBottom:5}}>Trait</Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select variant="outlined"
                                                disabled={this.state.isTraitDisabled}
                                                labelId="demo-simple-select-label"
                                                id="selectedTraitPart"
                                                name="selectedTraitPart"
                                                value={this.state.selectedTraitPart}
                                                onChange={this.handleChange}
                                                className={classes.selectBox}
                                            >
                                                {this.state.traits.map(function(ptype) {
                                                    return <MenuItem value={ptype.id}>{ptype.display_name}</MenuItem>
                                                })}
                                                
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}  style={{paddingRight:10}}>
                                        <Typography variant={'h5'} style={{textAlign:'start', paddingBottom:5}}>Severity Level</Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select variant="outlined"
                                                labelId="demo-simple-select-label"
                                                id="selectedSeverity"
                                                name="selectedSeverity"
                                                value={this.state.selectedSeverity}
                                                onChange={this.handleChange}
                                                className={classes.selectBox}
                                            >
                                                {this.state.severities.map(function(ptype) {
                                                    return <MenuItem value={ptype.id}>{ptype.name}</MenuItem>
                                                })}
                                                
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container style={{paddingTop:'1vh'}}>
                                <Grid item xs={6}>
                                    <Typography variant={'h5'} style={{textAlign:'start', paddingBottom:5, paddingLeft:10}}>English Text</Typography>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={30}
                                        placeholder="English Text"
                                        value={this.state.englishText}
                                        style={{ width: '94%', height:'50vh', padding:10 , overflow:'auto'}}
                                        onChange={this.updateEnglishText}
                                        />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={'h5'} style={{textAlign:'start', paddingBottom:5, paddingLeft:10}}>Hebrew Text</Typography>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={30}
                                        placeholder="טקסט בעברית"
                                        value={this.state.hebrewText}
                                        style={{ width: '94%', height:'50vh', direction:'rtl', padding:10, overflow:'auto'}}
                                        onChange={this.updateHebrewText}
                                        />
                                </Grid>
                            </Grid>
                            
                           
                            
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton  onClick={this.saveAccount} className={classes.actionButton}>{t('Settings - Accounts - Save')}</EgButton>
                                 
                            </Grid>
                            
                        </Grid>
                </Paper>
                {this.state.statusBar}
        </div>     
      
    );
  }
}
ReportTemplateEditor.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(ReportTemplateEditor),ReportTemplateEditor)))