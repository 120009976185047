import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from './EgButton';
import EgActivityRecorder from '../util/EgActivityRecorder';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    actionButton:{
        width: 100,
        margin: 5
    },
    messageHeader:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.secondary.light,
    },
    messageHeaderSuc:{
        paddingTop:5, 
        paddingBottom:5, 
        backgroundColor: theme.palette.primary.light,
    },
    
})

class GenericErrorDialog extends React.Component {
  
    state = {
        open: this.props.open,
        candidate:this.props.candidate,
    };

    constructor(props) {
        super(props);  
        
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});         
        }
    }

    close(){
        this.handleClose();
    }

    
    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };

    render() {
        const { classes } = this.props;
        const { t } = this.props;
       
        return (
            <div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                        name='Engard Error Dialog'
                        onMouseEnter={EgActivityRecorder.handleMouseEnter} 
                        mouseenterdata={JSON.stringify({'error dialog':this.props.message})}
                        onMouseLeave={EgActivityRecorder.handleMouseLeave} 
                >
                    
                   <Paper elevation={0}>
                       <Grid container justify='left'>
                            
                           <Grid item xs={12} className={this.props.variant==='success'?classes.messageHeaderSuc:classes.messageHeader}>
                               <Typography variant={'h2'} style={{color:'#043241', textAlign:'center'}}>{this.props.title}</Typography>
                            </Grid>
                           
                            <Grid item xs={12} style={{paddingBottom:20, paddingTop:30}}>
                                <Typography variant={'h4'}>{this.props.message}</Typography> 
                            </Grid>
                            {this.props.image?
                            <Grid ietm xs={12} style={{display:'flex', justifyContent:'center'}}>
                                <img src={this.props.image} style={{width:'80%'}}></img>
                            </Grid>:''}
                            
                            <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                <EgButton secondary="true" outlined="true" className={classes.actionButton} onClick={this.close}>{t("ErrorDialog - Close")}</EgButton>  
                            </Grid>

                       </Grid>
                   </Paper>
                </DialogContent>
                
                
            </Dialog>
            </div>
        );
    }
}

GenericErrorDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(GenericErrorDialog),GenericErrorDialog)))