import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import testIntro from '../../img/thank_you.svg';

const styles = theme => ({
    root: {
        display: 'flex',
        width:'100%'
    },
    logo:{
        width:200,
        paddingTop:40,
        paddingBottom:40,
        paddingRight:15,   
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        width:'100%',
        minHeight:'50vh',
    },
});


class AssessmentFinished extends React.Component {
  
    state = {
        candidate: this.props.candidate,
    };

    componentWillReceiveProps(nextProps) {   
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});    
        }
    }

    
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <Paper className={classes.paper}>
            <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:30, paddingTop:50}}>
                <Grid xs={6}>
                    <Grid container>    
                        <Grid item xs={12} justify="left" >
                            <Typography variant={'h1'}>{t('Assessment - Questions - Thank You')}, {this.state.candidate?this.state.candidate.firstname:''}</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{paddingTop:50}} >
                            <Typography variant={'h4'}>{t('Assessment - Questions - We are processing your assessment')} </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:10}} >
                            <Typography variant={'h4'}>{t('Assessment - Questions - You will be contacted soon')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} justify="center" style={{display:"flex", alignItems:"center"}}>
                    <img src={testIntro} className={classes.logo} alt="testIntro" />
                </Grid>
            </Grid>
            </Paper>
    );
  }
}
AssessmentFinished.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(AssessmentFinished),AssessmentFinished);


