import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import TimerCountdown from './TimerCountdown';
import Moment from 'react-moment';
import AppUtils from '../../util/AppUtils';

const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor:'#ffffffff'
    },
})
  
class AssessAppBar extends React.Component {
  
    state = {
        page: this.props.page
    };

    constructor(props) {
        super(props);  
        this.timeout = this.timeout.bind(this) 
        this.updateTimeLeft = this.updateTimeLeft.bind(this)
    } 

    componentWillReceiveProps(nextProps) {      
        if (nextProps.startTimer!==this.state.startTimer ){             
          this.setState({startTimer:nextProps.startTimer});    
        }
        if (nextProps.candidate!==this.state.candidate ){             
            this.setState({candidate:nextProps.candidate});    
        }
        if (nextProps.initialTestTime!==this.state.initialTestTime ){             
            this.setState({initialTestTime:nextProps.initialTestTime});    
        }
    }

    timeout(){
        this.props.timeout();
    }
    updateTimeLeft(timeLeft){
        this.props.updateTimeLeft(timeLeft)
    }

    render() {
        const { t } = this.props;
        
        return (
            
            <Grid container spacing={0} justify="center" alignItems="center">
                <Grid item xs={AppUtils.isMobile()?12:3} style={AppUtils.isMobile()?{textAlign:'center'}:{textAlign:'start'}}> <TimerCountdown start={this.state.startTimer} timeout={this.timeout} updateTimeLeft={this.updateTimeLeft} initialTestTime={this.state.initialTestTime}></TimerCountdown> </Grid>
                {AppUtils.isMobile()?'':<Grid item xs={9} style={{textAlign:"end",paddingBottom:10, paddingInlineEnd:10}}>
                    
                        <span  style={{textAlign:"end", fontSize:16, color:'#5F6066', fontWeight:600}}>
                            {this.state.candidate?this.state.candidate.firstname + " " + this.state.candidate.lastname:''}
                        </span>
                        <span  style={{textAlign:"end", paddingInlineEnd:20, borderInlineEnd:'1px solid #E4E4E4', fontSize:16, color:'#5F6066'}}>
                            , <Moment format="MMM DD, YYYY">{new Date()}</Moment>
                        </span>
                        <span  style={{textAlign:"start", paddingInlineStart:20 ,fontSize:16, color:'#5F6066'}}>
                            {t('Assessment - Questions - Applying for')} {this.state.candidate?this.state.candidate.position:''}, 
                        </span>
                        <span  style={{textAlign:"start", paddingInlineStart:20 ,fontSize:16, color:'#5F6066'}}>
                        {this.state.candidate?this.state.candidate.org_name:''}
                        </span>
                </Grid>}
            </Grid>     
        );
    }
}
AssessAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
};
  
export default (withTranslation()(withCookies(withRouter((withStyles(styles))(AssessAppBar),AssessAppBar))))