import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} style={{color:props.color, width:60, height:60, borderRadius:3}} />
      <Box
        top={10}
        left={10}
        bottom={10}
        right={10}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{background:'#F2F2F2',borderRadius:50}}
      >
        <Typography variant="caption" component="div" color="textSecondary" style={{fontSize:16,color:"#043241",fontFamily:"Montserrat"}}>
            {props.value}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
