import React from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import GenericErrorDialog from '../../components/GenericErrorDialog';
import { withTranslation } from 'react-i18next';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import EgHttp from '../../util/EgHttp';
import * as EmailValidator from 'email-validator';
import qs from 'qs';
import SignTopbar from './SignTopbar'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import EgButton from '../../components/EgButton';
import emailIcon from '../../img/email_icon.svg'
import passwordIcon from '../../img/password_icon.svg'
import signBg from '../../img/assessment_bg.svg';

const mapStateToProps = state => ({
    admin: state.admin.value
  });


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    signupPaper:{
        height:480,
        width:412,
        margin:0,
        borderRadius:8 
    }, 
   
    appRight:{
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
      },
    appLeft:{
        [theme.breakpoints.up('sm')]: {
        width: '100%',
          background:theme.palette.whitebg,
        },
        boxShadow:"0px 0px 0px"
    },
    signButton:{
        padding:10, 
        width:'100%',
    }, 
    inputLabel:{
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#043241',
            opacity: 1,
          },
          fontSize:14,
          height:10,
    },
    signupTxt:{
        background:theme.palette.whitebg,
        padding:0,
        borderRadius:4,
        '& label.Mui-focused': {
            color: '#E4E4E4',
          },
        '& .MuiInput-underline:after': {
        borderBottomColor: '#E4E4E4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4E4',
            },
            '&:hover fieldset': {
                borderColor: '#E4E4E4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4E4E4',
            },
        },
    },
  });
  
const PAGE_STATE_ENTER_EMAIL = 0;
const PAGE_STATE_EMAIL_SENT = 1;
const PAGE_STATE_RESET_PASSWORD = 2;
const PAGE_STATE_PASSWORD_CHANGED = 3;

class ResetPassword extends React.Component {
  
    state = {
        email: null,
        pageState: PAGE_STATE_ENTER_EMAIL,
    };

  
    constructor(props) {
        super(props);   
        this.sendReset = this.sendReset.bind(this);   
        this.closeErrorDialog = this.closeErrorDialog.bind(this);  
        this.gotoSignin = this.gotoSignin.bind(this);  
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount(){
        const id=qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id
        const confirmToken = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).t
        this.setState({id:id, confirmToken:confirmToken})
        if (id && confirmToken){
            this.setState({pageState:PAGE_STATE_RESET_PASSWORD})
        }
    }

    closeErrorDialog(){
        this.setState({errDialogOpen:false});
        this.setState({errDialog:null});
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value }); 
    };

    async sendReset(){
        const { t } = this.props;
        if (!this.state.email || this.state.email.length<2 || !EmailValidator.validate(this.state.email)){
            this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Reset - Illegal Email")} message={t("Reset - Please enter a valid email address")}></GenericErrorDialog>})
            return;
        }
        
        let response = await EgHttp.post('/sign/reset', JSON.stringify({
                email: this.state.email,  
        }))
        
        if (response.status===200 ) {
            //let rowsres = await response.json();
            this.setState({pageState:PAGE_STATE_EMAIL_SENT});
            return
        }
        else{
            this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Reset - Illegal Email")} message={t("Reset - Please enter a valid email address")}></GenericErrorDialog>})
            return;
        }
    }

    async resetPassword(){
        const { t } = this.props;
        console.log('Reset Password')
        if (!this.state.password || this.state.password.length<2 ){
            console.error('Bad pwassword')
            this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Reset - Illegal Password")} message={t("Reset - Please enter a valid password")}></GenericErrorDialog>})
            return;
        }
        if (this.state.password !== this.state.confirmpassword ){
            console.error('Bad password confirmation')
            this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Reset - Password and confirm are different")} message={t("Reset - Password and confirmation password must be the same")}></GenericErrorDialog>})
            return;
        }
        
        let response = await EgHttp.put('/sign/newpass', JSON.stringify({
                id: this.state.id, 
                token: this.state.password,
                confirmtoken: this.state.confirmToken
        }))

        console.log('Password reset request finished...')
        console.log(JSON.stringify(response))
        if (response.status===200 ) {
            let rowsres = await response.json();
            console.log(JSON.stringify(rowsres))
            if (rowsres && rowsres.count){
                console.log('Reset pwd succeess')
                this.setState({pageState:PAGE_STATE_PASSWORD_CHANGED});
                return
            }
            else{
                console.error('Reset pwd failure - bad response data')
                this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Reset - Failed to reset Email")} message={t("Reset - We were not able to reset your Email")}></GenericErrorDialog>})
                return;
            }
        }
        else{
            console.error(`Reset pwd failure - response.status != 200 => ${response.status}`)
            this.setState({errDialog:<GenericErrorDialog closeDialog={this.closeErrorDialog}  open={true} title={t("Reset - Failed to reset Email")} message={t("Reset - We were not able to reset your Email")}></GenericErrorDialog>})
            return;
        }
    }

    gotoSignin(){
        this.props.history.push('/signin') 
    }
  
    render() {
        const { t,i18n } = this.props;
        const { classes } = this.props;
        return (
            <div>
            <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} >
                <Toolbar >
                    <SignTopbar mode="signup"></SignTopbar>
                </Toolbar>
            </AppBar>
            
            <Container style={{maxWidth:'100%'}}>
            <Grid container spacing={0} justify="center" alignItems="center" style={{backgroundImage:`url(${signBg})`,width:'100vw', height:'100vh', maxWidth:'100%'}}>
            <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                
                
                    
                    {this.state.pageState===PAGE_STATE_ENTER_EMAIL?
                    <Paper className={classes.signupPaper} elevation={3} style={{position:'relative'}}>
                        <Grid item xs={12} style={{paddingTop:30, paddingBottom:80}}>
                            <Typography variant="h1" style={{color:'#043241', textAlign:'center'}}>{t("Reset - Reset Password")} </Typography>
                        </Grid>
                        <Grid item xs={12} >     
                            <Typography variant="h4">{t('Reset - To receive a link to reset your password')}<br /> {t('Reset - please enter your email address')}</Typography>
                        </Grid>
                        
                        <Grid container spacing={2} justify="center" style={{paddingTop:30, paddingBottom:40}}>
                            <Grid item xs={9} style={{padding:5}}>
                                <TextField  name="Email" variant="outlined" required fullWidth='true' id="email" label=""  placeholder={t("Signup - Email")} onChange={this.handleChange}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                         <img src={emailIcon} style={{width:16}} alt="emailicon"/>
                                    </InputAdornment>
                                    ),
                                    classes: { input: classes.inputLabel }, 
                                }}
                                className={classes.signupTxt}/>
                            </Grid>
                            <Grid item xs={9} style={{padding:60}}></Grid>
                            <Grid item xs={9} style={{padding:5}}>
                                <EgButton variant="contained"  onClick={this.sendReset} className={classes.signButton}>
                                    {t("Company - Continue")}
                                </EgButton>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{padding:30}} />
                        
                    </Paper>
                    
                    :this.state.pageState===PAGE_STATE_EMAIL_SENT?

                    <Paper className={classes.signupPaper} elevation={3} style={{position:'relative'}}>
                        <Grid item xs={12} style={{paddingTop:30, paddingBottom:80}}>
                            <Typography variant="h1" style={{color:'#043241', textAlign:'center'}}>{t("Reset - Reset Password")} </Typography>
                        </Grid> 
                        <Grid item xs={12} >     
                            <Typography variant="h4" style={{padding:80}}>{t('Reset - An Email with password reset instructions was sent to you')}<br /> </Typography>
                        </Grid>
                    </Paper>

                    :this.state.pageState===PAGE_STATE_RESET_PASSWORD?
                    <Paper className={classes.signupPaper} elevation={3} style={{position:'relative'}}>
                        <Grid item xs={12} style={{paddingTop:30, paddingBottom:80}}>
                            <Typography variant="h1" style={{color:'#043241', textAlign:'center'}}>{t("Reset - Reset Password")} </Typography>
                        </Grid> 
                        <Grid item xs={12} >     
                            <Typography variant="h4" >{t('Reset - Please enter a new password')}</Typography>
                        </Grid>
                        
                        <Grid container spacing={2} justify="center">
                            
                            <Grid item xs={9} style={{padding:5}}>
                                <TextField variant="outlined" required fullWidth='true' name="password" label="" placeholder={t('Signup - Password')} type="password" id="password"   onChange={this.handleChange} 
                                    
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={passwordIcon} style={{width:16}} alt="passwordicon"/>
                                        </InputAdornment >
                                        ),
                                        classes: { input: classes.inputLabel }, 
                                    }}
                                    className={classes.signupTxt}/>
                            </Grid>
                            <Grid item xs={9} style={{padding:5}}>
                                <TextField variant="outlined" required fullWidth='true' name="confirmpassword" label="" placeholder={t('Signup - Confirm Password')} type="password" id="confirmpassword"   onChange={this.handleChange} 
                                    
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={passwordIcon} style={{width:16}} alt="passwordicon"/>
                                        </InputAdornment >
                                        ),
                                        classes: { input: classes.inputLabel }, 
                                    }}
                                    className={classes.signupTxt}/>
                            </Grid>
                            <Grid item xs={9} style={{padding:40}}></Grid>
                            <Grid item xs={9} style={{padding:5}}>
                                <EgButton variant="contained"  onClick={this.resetPassword} className={classes.signButton}>
                                    {t("Company - Continue")}
                                </EgButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{padding:30}} />
                        
                    </Paper>
                    :this.state.pageState===PAGE_STATE_PASSWORD_CHANGED?

                    <Paper className={classes.signupPaper} elevation={3} style={{position:'relative'}}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} style={{paddingTop:30, paddingBottom:80}}>
                                <Typography variant="h1" style={{color:'#043241', textAlign:'center'}}>{t("Reset - Reset Password")} </Typography>
                            </Grid> 
                            <Grid item xs={12} >     
                                <Typography  variant="h4" style={{paddingTop:80}}>{t('Reset - Your password was changed successfully')}<br /> </Typography>
                            </Grid>
                            <Grid item xs={9} style={{padding:40}}></Grid>
                            <Grid item xs={9} > 
                                <EgButton variant="contained" onClick={this.gotoSignin} className={classes.signButton}>
                                    {t("Company - Continue")}
                                </EgButton>
                            </Grid>
                        </Grid>
                    </Paper>
                    :
                    <div></div>
                    }
            </Grid>
                
        </Grid>
        {this.state.errDialog}
        </Container>
        </div>
        );
    }
}
ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withCookies(withRouter((withStyles(styles))(ResetPassword),ResetPassword))))
