import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles  } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import EgButton from '../../components/EgButton';
import { t } from 'i18next';
import AppUtils from '../../util/AppUtils';
import EgActivityRecorder from '../../util/EgActivityRecorder';

const styles = theme => ({
    
    root: {
      display: 'flex',
      width:'100%'
    },
    fadedBtn:{
        opacity:0.5,
        "&:hover":{
            opacity:"1",
        },  
    },
    
    radioWrap:{
        border:'1px solid #E4E4E4',
        borderRadius:4,
        minWidth:'25vw',
        width:'100%',
        padding:5,
        margin:5,
        "&:hover":{
            backgroundColor:"#E9FCF1"
        },
        display:'flex',         
    },
    radioWrapMob:{
        border:'1px solid #E4E4E4',
        borderRadius:4,
        minWidth:'80vw',
        width:'100%',
        padding:5,
        margin:5,
        "&:hover":{
            backgroundColor:"#E9FCF1"
        },
        display:'flex',         
    },
    radioWrapChecked:{
        border:'1px solid #00AC83',
        borderRadius:4,
        minWidth:'25vw',
        width:'100%',
        padding:5,
        margin:5,
        backgroundColor:'#11D6A7',
        display:'flex',
    },
    radioWrapCheckedMob:{
        border:'1px solid #00AC83',
        borderRadius:4,
        minWidth:'80vw',
        width:'100%',
        padding:5,
        margin:5,
        backgroundColor:'#11D6A7',
        display:'flex',
    }   
})

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor:  '#f5f8fa',
    backgroundImage:'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto #00AC83',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor:  '#ebf1f5',
    },
   
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#00AC83',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#00AC83',
    },
});
  
const GreenRadio = withStyles({
    root: {
    }
  })((props) => <Radio color="default" disableRipple checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />);

class AnswerRadios extends React.Component {
  
    state = {
        selected: -1,
        availAnswers:null,
        lastQuestion:null,
        nextbtn: []
    };

    constructor(props) {
        super(props);  
        this.radioSelected = this.radioSelected.bind(this); 
        this.clearBtnArr = this.clearBtnArr.bind(this); 
        this.cancelFadeTimer = this.cancelFadeTimer.bind(this);
        this.resumeFade = this.resumeFade.bind(this)
        this.moveToNext = this.moveToNext.bind(this)
        this.setState({availAnswers: this.props.availAnswers})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.availAnswers!==this.state.availAnswers ){   
            this.setState({availAnswers:nextProps.availAnswers});
        } 
        if (nextProps.nextButton!==this.state.nextButton ){   
            this.setState({nextButton:nextProps.nextButton});       
        } 
    }

    radioSelected(event,index){
        const { classes } = this.props
        this.cancelFadeTimer()
        this.setState({fadeTimer: null})
        this.props.setSelected(event.target.value, new Array(event.target.value));
        this.setState({selected:parseInt(event.target.value)});
        let btnArr = []
        btnArr[index-1] = <EgButton  className={classes.fadedBtn} 
                            onClick={this.moveToNext} 
                            onMouseOver={this.cancelFadeTimer} 
                            onMouseOut={this.resumeFade}
                            onMouseEnter={EgActivityRecorder.handleMouseEnter}
                            mouseenterdata={JSON.stringify({question_id:this.props.qid, answer_ix: index})}
                            onMouseLeave={EgActivityRecorder.handleMouseLeave}
                            mouseleavedata={JSON.stringify({question_id:this.props.qid, answer_ix: index})}
                        >{t('Assessment - Questions - Next Question')} &#62;</EgButton>
        this.setState({nextbtn:btnArr})
        this.setState({startFade:true})
        const clearTimeout = setTimeout(this.clearBtnArr,3000);
        this.setState({fadeTimer: clearTimeout})
    }
    moveToNext(event){
        event.target.attributes.clickdata = {}
        event.target.attributes.clickdata.value = JSON.stringify({question_id:this.props.qid, answer_ix: this.state.selected, selected:true})
        EgActivityRecorder.handleClickOrTap(event)
        this.cancelFadeTimer()
        this.setState({fadeTimer: null})
        this.clearBtnArr()
        this.props.nextButton()
    }

    resumeFade(){
        this.setState({startFade:true})
        const clearTimeout = setTimeout(this.clearBtnArr,3000);
        this.setState({fadeTimer: clearTimeout})
    }
    cancelFadeTimer(){
        if (this.state.fadeTimer){
            clearTimeout(this.state.fadeTimer)
        }
    }

    clearBtnArr(){
        this.setState({nextbtn:[]})
        clearTimeout(this.state.fadeTimer)
    }
    clearSelection(){
        this.setState({selected:null});
    }

    setSelection(sel){
        this.setState({selected:parseInt(sel)});
    }

    render(){
        
        const { classes } = this.props
        return (
            <FormControl component="fieldset">
                <FormLabel component="legend"></FormLabel>
                <RadioGroup aria-label="answers" defaultValue="" name="radio-buttons-group" onChange={this.radioSelected}>
                    {this.state.availAnswers?
                    this.state.availAnswers.map((answer, index) => (
                       <span style={{width:'100%',display:'inline-flex', alignItems:'center'}}>
                            <FormControlLabel   value={(index+1)} 
                                                control={<GreenRadio  checked={this.state.selected===parseInt(index+1)}/>}  
                                                label={this.state.selected===parseInt(index+1)?<Typography style={{fontSize:16, color:'#ffffff', fontWeight:500}}>{answer.text}</Typography>:<Typography style={{fontSize:16, color:'#5F6066'}}>{answer.text}</Typography>} 
                                                labelPlacement="right"
                                                className={AppUtils.isMobile()?(this.state.selected===parseInt(index+1)?classes.radioWrapCheckedMob:classes.radioWrapMob):(this.state.selected===parseInt(index+1)?classes.radioWrapChecked:classes.radioWrap)}
                                                key={"answerRadio" + index}
                                                onMouseEnter={EgActivityRecorder.handleMouseEnter}
                                                mouseenterdata={JSON.stringify({question_id: this.props.qid, answer_num: (index+1)})}
                                                onMouseLeave={EgActivityRecorder.handleMouseLeave}
                                                mouseleavedata={JSON.stringify({question_id: this.props.qid, answer_num: (index+1)})}
                                                onClick={EgActivityRecorder.handleClickOrTap}
                                                clickdata={JSON.stringify({question_id:this.props.qid, answer_ix: index, selected:false})}
                            >
                            </FormControlLabel> 
                            {AppUtils.isMobile()?'':
                            <div style={{width:200, height:40}}>{this.state.nextbtn[index]} </div>}
                        </span>
                       
                    )
                    ):
                    ''
                    }
                </RadioGroup>
            </FormControl>
        )
    }
}   
AnswerRadios.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(AnswerRadios);