import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { styled } from '@material-ui/core/styles';
import LangPicker from "../util/LangPicker";


const GradeBarRed = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.gradebg, 
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.severity.high, 
  }
}))(LinearProgress);
const GradeBarRedW = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 0
  },
  colorPrimary: {
    backgroundColor: '#fafafb'//theme.palette.gradebg, 
  },
  bar: {
    borderRadius: 0,
    backgroundColor: theme.palette.severity.high, 
  }
}))(LinearProgress);
const GradeBarBlue = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 5
    },
    colorPrimary: {
      backgroundColor: theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.severity.med, 
    }
  }))(LinearProgress);
  const GradeBarBlueW = withStyles((theme) => ({
    root: {
      height: 20,
      borderRadius: 0
    },
    colorPrimary: {
      backgroundColor: '#fafafb'//theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 0,
      backgroundColor: theme.palette.severity.med, 
    }
  }))(LinearProgress);
  const GradeBarGreen = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 5
    },
    colorPrimary: {
      backgroundColor: theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.severity.low, 
    },
  }))(LinearProgress);
  const GradeBarGreenW = withStyles((theme) => ({
    root: {
      height: 20,
      borderRadius: 0
    },
    colorPrimary: {
      backgroundColor: '#fafafb'//theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 0,
      backgroundColor: theme.palette.severity.low, 
    },
  }))(LinearProgress);

  const GradeBarInvalid = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 5
    },
    colorPrimary: {
      backgroundColor: theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#eaeaea', 
    },
  }))(LinearProgress);
  const GradeBarInvalidW = withStyles((theme) => ({
    root: {
      height: 20,
      borderRadius: 0
    },
    colorPrimary: {
      backgroundColor: '#fafafb'//theme.palette.gradebg, 
    },
    bar: {
      borderRadius: 0,
      backgroundColor: '#eaeaea', 
    },
  }))(LinearProgress);

const GradeBarGreenN = styled(GradeBarGreen)(({ theme, ...props }) => ({
    
    '& .MuiLinearProgress-bar':{
      borderRadius: 5,
      backgroundColor: theme.palette.severity.low, 
      transform: `translateX(${props.value}%) !important`
    },
}))
const GradeBarBlueN = styled(GradeBarBlue)(({ theme, ...props }) => ({
    
  '& .MuiLinearProgress-bar':{
    borderRadius: 5,
    backgroundColor: theme.palette.severity.med, 
    transform: `translateX(${props.value}%) !important`
  },
}))
const GradeBarRedN = styled(GradeBarRed)(({ theme, ...props }) => ({
    
  '& .MuiLinearProgress-bar':{
    borderRadius: 5,

    backgroundColor: theme.palette.severity.high, 
    transform: `translateX(${props.value}%) !important`
  },
}))

const GradeBarInvalidN = styled(GradeBarInvalid)(({ theme, ...props }) => ({
    
  '& .MuiLinearProgress-bar':{
    borderRadius: 5,

    backgroundColor: theme.palette.severity.high, 
    transform: `translateX(${props.value}%) !important`
  },
}))

const GradeBarGreenNWide = styled(GradeBarGreenW)(({ theme, ...props }) => ({
    
  '& .MuiLinearProgress-bar':{
    borderRadius: 3,
    height: 20,
    backgroundColor: theme.palette.severity.low, 
    transform: `translateX(${props.value}%) !important`
  },
}))
const GradeBarBlueNWide = styled(GradeBarBlueW)(({ theme, ...props }) => ({
  
'& .MuiLinearProgress-bar':{
  borderRadius: 3,
  height: 20,
  backgroundColor: theme.palette.severity.med, 
  transform: `translateX(${props.value}%) !important`
},
}))
const GradeBarRedNWide = styled(GradeBarRedW)(({ theme, ...props }) => ({
  
'& .MuiLinearProgress-bar':{
  borderRadius: 3,
  height: 20,
  backgroundColor: '#0085A',//Ftheme.palette.severity.high, 
  transform: `translateX(${props.value}%) !important`
},
}))
const GradeBarInvalidNWide = styled(GradeBarInvalidW)(({ theme, ...props }) => ({
  
  '& .MuiLinearProgress-bar':{
    borderRadius: 3,
    height: 20,
    backgroundColor: '#0085A',//Ftheme.palette.severity.high, 
    transform: `translateX(${props.value}%) !important`
  },
  }))
  

export default function GradeBar(props) {
  let value = 0,avg = 0
  if (props.value) value = props.value
  if (props.avg) avg = props.avg
  return (
    <div style={{width:'100%', position:'relative'}}>
        {!props.isInvalid?
                props.wide?
                props.negative?
                  value<props.boundaries[0].top?
                    <GradeBarGreenNWide  variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)} />
                    :
                    value<props.boundaries[1].top?
                    <GradeBarBlueNWide variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
                    :
                    <GradeBarRedNWide variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
                :
                  value<props.boundaries[0].top?
                      <GradeBarRedNWide variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
                      :
                      value<props.boundaries[1].top?
                      <GradeBarBlueNWide variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
                      :
                      <GradeBarGreenNWide variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}  />
                  
                :
                props.negative?
                  value<props.boundaries[0].top?
                    <GradeBarGreenN  variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)} />
                    :
                    value<props.boundaries[1].top?
                    <GradeBarBlueN variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
                    :
                    <GradeBarRedN variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
                :
                  value<props.boundaries[0].top?
                      <GradeBarRedN variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
                      :
                      value<props.boundaries[1].top?
                      <GradeBarBlueN variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
                      :
                      <GradeBarGreenN variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
          :
              props.wide?
                <GradeBarInvalidNWide variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)} />
                :
                <GradeBarInvalidN variant="determinate"  value={LangPicker.direction()==='rtl'?(100-value):-(100-value)}   />
          }

          <div
            style={{
             
              position: "absolute",
              top: 0,
              start: 0,
              width: avg + "%",
              height: 20,
              backgroundColor: "#ffffff00",
              opacity: '0.5',
              backgroundSize: "6px 6px",
              backgroundImage:
                'repeating-linear-gradient(45deg, #0085AF 0, #0085AF 2.5px, #ffffff00 0, #ffffff00 50%)',
              borderRadius:5
            }}
      ></div>
    </div>
  );
}