import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { instanceOf } from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles  } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Topbar from '../components/Topbar'
import { FormControl, Grid, InputBase, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import AssignedTable from './assigned/AssignedTable';
import EgProgress from '../components/EgProgress';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, increment } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import * as AppConstants from '../util/constants/AppConstants';
import EgStatusAlert from '../components/EgStatusAlert';
import EgHttp from '../util/EgHttp';
import NavigationManager from '../managers/NavigationManager';
import { withCookies, Cookies } from 'react-cookie';
import ExtendedNavBar from '../components/ExtendedNavBar';
import AddHumanResource2 from './human_resource/AddHumanResource2';
import EgCommonData from '../util/EgCommonData';

const mapStateToProps = state => ({
  admin: state.admin.value
});

const drawerWidth = AppConstants.NavigationDrawerWidth;

const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%'
  },
  appRight:{
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      background:"#FAFAFA",
    },
    marginRight: drawerWidth
  },
  appLeft:{
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      background:"#FAFAFA",
    },
    marginLeft: drawerWidth
  },
 
  //toolbar: //theme.mixins.toolbar,
  toolbar:{
    minHeigt:64,
    marginTop:50
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width:'100%',
    height:window.innerHeight,
  },
  note:{
    marginTop:20,
  },
  latestNote:{
      marginTop:20,
  },
    
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginLeft:5
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E4E4E4',
    fontSize: 16,
    padding: '8px 26px 8px 12px',
    textAlign:'start',
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

class Assigned extends React.Component {
    state = {
      open: this.props.open,
      drawerState: null,
      operationIcon: null,
      rows:[],
      loading:<EgProgress />,
      filterTeam:0
    };

    constructor(props) {
        super(props);  
        
        if (!this.props.login.logged) {
          this.props.history.push('/signup') 
        } 
        else{
            NavigationManager.setLast(this.props, '/assigned')
        }
        this.getAssigned = this.getAssigned.bind(this); 
        this.refresh = this.refresh.bind(this);
        this.handleStatusClose = this.handleStatusClose.bind(this);
        this.search = this.search.bind(this);
        this.openEditCandidate = this.openEditCandidate.bind(this); 
        this.handleDrawerState = this.handleDrawerState.bind(this); 
        this.getFilteredCandidatesByTeam = this.getFilteredCandidatesByTeam.bind(this)
        this.getAssigned() 
    }

    async getAssigned(){
      
      this.setState({loading:<EgProgress />})
      let response = await EgHttp.get('/candidates/assigned/all');
      if (response.status === 401) {
        this.props.history.push('/signin') 
        return;
      }
      let rowsres = await response.json();
      const origres = [...rowsres]
      this.setState({rows:rowsres, loading:null});
      this.setState({originalRows:origres});
      const teams = await EgCommonData.getTeams();
      const positions = await EgCommonData.getPositions();
      this.setState({teams: teams, positions:positions})
      return;
    }
    handleStatusClose(){
      this.setState({statusBar:null})
    }

    showStatusBar(message){
      this.setState({statusBar: <EgStatusAlert onClose={this.handleStatusClose} severity="success" message={message} ></EgStatusAlert>});
    }

    refresh(status, message){
      this.getAssigned()
      if (status === 'SUCCESS'){
          this.showStatusBar(message)
      }
    }

    openEditCandidate(id){
      this.setState({openCandId:id}, ()=> {
        this.setState({openAdd:true})
      })
    }
    handleDrawerState(sw){
      if (sw){
          this.setState({openAdd:!this.state.openAdd})
      }
      else{
          this.setState({openAdd:false})
      }
  }

    filterItems(arr, query) {
      return arr.filter(function(el) {
        return (el.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1) || (el.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      })
    }
    filterTeams = event => {
      this.setState({ filterTeam: event.target.value });
      if (event.target.value === 0){
          this.setState({rows: this.state.originalRows})
          this.props.history.push('/assigned')
      }
      else{
        this.getFilteredCandidatesByTeam(event.target.value)
        this.props.history.push('/assigned?team='+event.target.value)
      }
  };
    getFilteredCandidatesByTeam(teamId){
      const allEmps = this.state.originalRows
      const allTeams = this.state.teams
      const filtered = allEmps.filter(function(el) {
          const teamObjInd = allTeams.findIndex(({ id }) => id === parseInt(teamId))
          const teamObj = allTeams[teamObjInd]
          const memberIndex = teamObj.team_members.findIndex(({ hr_id }) => hr_id === el.id)
          if (memberIndex > -1) return el;
          else return null
      })
      this.setState({rows: filtered})
  }

    search(term){
      this.setState({searchTerm: term})
      if (term && term.length>0){
        let filteredRows;
        filteredRows = this.filterItems(this.state.originalRows, term)
        this.setState({rows: filteredRows})
      }
      else{
        this.setState({rows: this.state.originalRows})
      }
    }
  
    render() {
        const { classes } = this.props;
        const { t, i18n } = this.props;
    return (
        <div className={classes.root} >
        <CssBaseline />
        <AppBar position="fixed" className={i18n.dir()==='rtl'?classes.appRight:classes.appLeft} style={{boxShadow:"0px 0px 0px" }}>
            {this.state.loading}
            <Toolbar>
                <Topbar login={this.props.login} page={this.props.page} search={this.search}></Topbar>
            </Toolbar>
        </AppBar>

        <ExtendedNavBar navPos={4}></ExtendedNavBar>

        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid container fullwidth='true' justify="center" direction="row" alignItems="flex-start">
           
            <Grid item xs={12 }>
              <Grid container  justify="left" direction="row" alignItems="flex-start">
                  <Grid item xs={6} justify="left">
                    <Typography variant="h1" > {t('Assigned - Assigned')} </Typography>
                  </Grid>
                  <Grid item xs={6} justify="right" style={{textAlign:"end"}} >
                      <FormControl style={{width:300, paddingTop:4}}>
                            <InputLabel id="teamsLbl"></InputLabel>
                                  <Select variant="outlined" labelId="teamsLbl" id="filterTeam" name="filterTeam" value={this.state.filterTeam} onChange={this.filterTeams} className={classes.selectBox} input={<BootstrapInput />}>
                                      <MenuItem value={0}>{t('Candidates - All Candidates')}</MenuItem>
                                      {this.state.teams? this.state.teams.map(function(t) {
                                          return <MenuItem value={t.id}>{t.name}</MenuItem>
                                      }):null}       
                                  </Select>
                        </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} className={classes.latestNote}>
                        <AssignedTable rows={this.state.rows} refresh={this.refresh} openCandidate={this.openEditCandidate} loading={this.state.loading?true:false}></AssignedTable>
                  </Grid>
                  
              </Grid>
            </Grid>
            
           
            </Grid>
        </main>
        <AddHumanResource2 conetxt='candidate' handleDrawerState={this.handleDrawerState} openState={this.state.openAdd} hrId={this.state.openCandId} refresh={this.refresh} teams={this.state.teams} positions={this.state.positions} />
        {this.state.statusBar}
        </div>
    );
    
}}

Assigned.propTypes = {
    classes: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired
  };
 export default connect(mapStateToProps, { setAdminDetails, increment })(withTranslation()(withCookies(withRouter((withStyles(styles))(Assigned),Assigned))))

