import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Select, MenuItem } from '@material-ui/core';
//import EventLog from '../../util/EventLog';
import { withRouter } from 'react-router-dom';
import { setAdminDetails, decrement } from "../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgHttp from '../util/EgHttp';
import EgButton from './EgButton';
import LangPicker from '../util/LangPicker';
import EgProgress from './EgProgress';

const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding:0,
    },
    actionButton:{
        width: 100,
        margin: 5
    },
    tableLeftRow:{
        display:'flex', 
        alignItems:'center', 
        padding:15, 
        borderTop:'1px solid #efefef', 
        
        justifyContent:'end',
    },
    tableRightRow:{
        display:'flex', 
        alignItems:'center', 
        padding:15, 
        borderTop:'1px solid #efefef', 
        justifyContent:'start', 
    },
})

class ReminderDialog extends React.Component {
  
    state = {
        open: this.props.open,
        human_resource:this.props.human_resource,
        context:this.props.context?this.props.context:'candidate',
        moreTime:this.props.moreTime,
    };

    constructor(props) {
        super(props);  
        
        this.handleClose = this.handleClose.bind(this);  
        this.close = this.close.bind(this); 
        this.remind = this.remind.bind(this);
        this.changeAddedTime = this.changeAddedTime.bind(this)
    }
    componentDidMount(){
        this.setState({context:this.props.context})
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.open!==this.state.open ){             
            this.setState({open:nextProps.open});           
        }
        if (nextProps.human_resource!==this.state.human_resource ){             
            this.setState({human_resource:nextProps.human_resource});    
        }
    }

    close(){
        this.handleClose();
    }

    async remind(){
        const { t } = this.props;
        this.setState({loading:<EgProgress />})
        this.setState({actionBtnDisabled:true})
        const jbody = JSON.stringify({
            id:this.state.human_resource.id,
            org_name: this.props.admin.org.name,
            acc_name: this.props.admin.account.firstname,
            hr_name: this.state.human_resource.firstname,
            cand_id: this.state.human_resource.id,
            email: this.state.human_resource.email,
            token: this.state.context === 'candidate'?this.state.human_resource.assessment[this.props.assessIndex].token:this.props.assessment.token,
            type: this.state.context === 'candidate'?this.state.human_resource.assessment[this.props.assessIndex].type:this.props.assessment.type,
            assid: this.state.context === 'candidate'?this.state.human_resource.assessment[this.props.assessIndex].id:this.props.assessment.id,
            language: LangPicker.current(),
            phone: this.state.human_resource.phone,
            moreTime: this.state.moreTime,
        })
        if (this.state.context === 'candidate'){

            await EgHttp.put('/candidates/remind', jbody)
        }
        else{
            await EgHttp.put('/employees/remind', jbody)
        }
        //EventLog.insert(this.props.admin.org.id, this.state.candidate.id, EventLog.ASSESSMENT_ASSIGNED, 0)
        this.setState({loading:null})
        this.setState({actionBtnDisabled:false})
        this.handleClose();
        this.props.refresh("SUCCESS", t('Assigned - Reminder - Reminder was sent successfully'));
        return;
    }

    handleClickOpen(){
        this.setState({open:true})
    };

    handleClose(){
        this.setState({open:false})
        this.props.closeDialog();
    };
    changeAddedTime(event){
        this.setState({moreTime:event.target.value})
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <div>
            
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent style={{padding:0}}>
                  {this.state.loading}
                   <Paper elevation={0} >
                       <Grid container justify='left' >
                           <Grid item xs={12} style={{paddingTop:20, paddingInlineStart:20}}>
                                {this.props.moreTime?
                                <Typography variant={'h1'} >{t('Assigned - Reminder - Send Assessment Reminder with time')}</Typography>:
                               <Typography variant={'h1'} >{t('Assigned - Reminder - Send Reminder')}</Typography>}
                            </Grid>
                            <Grid item xs={12} style={{paddingInlineStart:20, paddingTop:30, }}>
                                {this.props.moreTime?
                                <Typography variant={'h4'}>{t('Assigned - Reminder - Add Time and Send Assessment Reminder')}</Typography> :
                                <Typography variant={'h4'}>{t('Assigned - Reminder - Send Assessment Reminder')}</Typography> }
                            </Grid>
                            <Grid item xs={12} style={{padding:20}}>
                                <Grid container>
                                {this.props.moreTime?
                                <Grid item xs={12} >
                                    <Grid container>
                                        <Grid item xs={4} className={classes.tableLeftRow} style={LangPicker.direction()==='rtl'?{borderLeft:'1px solid #efefef'}:{borderRight:'1px solid #efefef'}}>
                                        <Typography variant={'h5'}>{t('Assigned - Reminder - Additional Time')}</Typography> 
                                        </Grid>
                                        <Grid item xs={8} className={classes.tableRightRow}>
                                          
                                            <Select onChange={this.changeAddedTime} value={this.state.moreTime}  style={{fontSize:14, color:'#000000'}}>
                                                <MenuItem value={15}>{t('Settings - Configure - 15 minutes')}</MenuItem>
                                                <MenuItem value={30}>{t('Settings - Configure - 30 minutes')}</MenuItem>
                                                <MenuItem value={45}>{t('Settings - Configure - 45 minutes')}</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :''}
                                <Grid item xs={4} className={classes.tableLeftRow} style={LangPicker.direction()==='rtl'?{borderLeft:'1px solid #efefef'}:{borderRight:'1px solid #efefef'}}>
                                    <Typography variant={'h5'}>{t('Assigned - Reminder - Name')}</Typography> 
                                </Grid>
                                <Grid item xs={8} className={classes.tableRightRow}>
                                    <Typography variant={'h4'} style={{color:'#000000'}}>{this.state.human_resource?this.state.human_resource.firstname + " " + this.state.human_resource.lastname:''}</Typography> 
                                </Grid>
                               
                                <Grid item xs={4} className={classes.tableLeftRow} style={LangPicker.direction()==='rtl'?{borderLeft:'1px solid #efefef'}:{borderRight:'1px solid #efefef'}}>
                                    <Typography variant={'h5'} >{t('Assigned - Reminder - Send to Email')}</Typography> 
                                </Grid>
                                <Grid item xs={8} className={classes.tableRightRow} >
                                    <Typography variant={'h4'} >{this.state.human_resource?this.state.human_resource.email:''}</Typography> 
                                </Grid>
                                
                                <Grid item xs={4} className={classes.tableLeftRow} style={LangPicker.direction()==='rtl'?{borderLeft:'1px solid #efefef',borderBottom:'1px solid #efefef'}:{borderRight:'1px solid #efefef',borderBottom:'1px solid #efefef'}} >
                                    <Typography variant={'h5'}>{t('Assigned - Reminder - Send to Phone')}</Typography> 
                                </Grid>
                                <Grid item xs={8} className={classes.tableRightRow} style={{borderBottom:'1px solid #efefef'}}>
                                    <Typography variant={'h4'} style={{color:'#000000'}}>{this.state.human_resource?this.state.human_resource.phone:''}</Typography> 
                                </Grid>
                                <Grid item xs={12} justify="center" style={{textAlign:"center", paddingTop:30}} >
                                    <EgButton  onClick={this.remind} className={classes.actionButton}>{t('Assigned - Reminder - Send')}</EgButton>
                                    <EgButton secondary="true" outlined="true"  className={classes.actionButton} onClick={this.close}>{t('Candidates - Assign - Cancel')}</EgButton>   
                                </Grid>
                            </Grid>
                            </Grid>
                       </Grid>
                   </Paper>
                </DialogContent>
                
                
            </Dialog>
            </div>
        );
    }
}

ReminderDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setAdminDetails, decrement })(withTranslation()(withRouter((withStyles(styles))(ReminderDialog),ReminderDialog)))