import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import EgRiskBar from '../../components/EgRiskBar';
import { RiskLevels } from '../../util/constants/RiskLevels'

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    
    insightBox:{
        border:'1px solid #E4E4E4',
        height:'30vh',
        overflow:'hidden'
    },
    boxTitle:{
        paddingInline:20,
        paddingTop:'2vh',
        paddingBottom:'2vh',
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    }
    
  });

class SingleReportRiskBox extends React.Component {
  
    constructor(props) {
        super(props);   
        this.moveToTimeAnalysis = this.moveToTimeAnalysis.bind(this)          
    }

    state = {
        report: this.props.report?this.props.report:{},
        traitsTimes: []
    };

    componentDidMount(){
        if (this.props.report && this.props.report.assessment){
            this.setState({report: this.props.report})
        }
    }

    componentWillReceiveProps(nextProps) {   
        
        if (nextProps.report && nextProps.report !== this.state.report){
            this.setState({report:nextProps.report});
            this.setState({report: this.props.report})
        }
        if(nextProps.severity && nextProps.severity !== this.state.severity){
            this.setState({severity: nextProps.severity})
        }
    }

   
    bouncer(array) {
        return array.filter(function(e) {
            return e;
        });
    }
      
    moveToTimeAnalysis(){
        this.props.moveTo(5)
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        

        return (
            <Paper elevation={0} style={{ overflow:'hidden'}} className={classes.insightBox} >
                
                <Grid container>
                    <Grid xs={12} className={classes.boxTitle}>
                        <Typography variant={'h4'} style={{fontWeight:600}} >{t('Reports - Summary - Potential Risk')} </Typography>
                    </Grid>
                    <Grid xs={12} style={{paddingTop:'4vh', paddingInline:20}}>
                        {this.state.report && this.state.report.validity_obj && this.state.report.validity_obj.overallRisk===2?
                            <EgRiskBar severity={-1}></EgRiskBar>:
                            <EgRiskBar severity={this.state.report.overall_risk} isMedLow={this.state.isMedLow}></EgRiskBar>
                        }
                    </Grid>
                    <Grid xs={12} style={{paddingTop:'2vh'}}>
                        <Typography variant={'h4'} style={{textAlign:'start', paddingInline:20}} >{RiskLevels.getSummaryText(this.state.report.overall_risk)} </Typography>
                    </Grid>

                    <Grid  xs={12} style={{paddingTop:'4vh'}}> 
                        {this.state.invalidReport?
                            '':<Typography variant={'h4'} style={{textAlign:'start', paddingInline:20}} ><a href="#0" onClick={()=>{this.props.moveTo(2)}} className={classes.link}>{ t("Report - Single - more info")}</a></Typography>
                        }
                    </Grid> 
                    
                </Grid>
                
                
            </Paper>
        );
    }
}
SingleReportRiskBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(SingleReportRiskBox),SingleReportRiskBox);