import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import EmployeeCardSummaryDates from './EmployeeCardSummaryDates';
import EmployeeCardSummaryStats from './EmployeeCardSummaryStats';
import AssignTestDialog from '../../components/AssignTestDialog';
import { connect } from 'react-redux';
import { setAdminDetails } from "../../redux/admin";
import ReminderDialog from '../../components/ReminderDialog';
import SingleReportPersonalityBox from '../reports/SingleReportPersonalityBox';
import EmployeeCardSummaryHisory from './EmployeeCardSummaryHistory';
import { AssessmentTypes } from '../../util/constants/AssessmentTypes';
import ReportsManager from '../../managers/ReportsManager';

const mapStateToProps = state => ({
    admin: state.admin.value
});

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        padding:'1.5vh'
    },
   
    recommend:{
        padding: '1vh',
        border: '2px solid #F15D5D',
        color:"#F15D5D", 
        width:400,
        fontWeight:600,
        textAlign:'center',
        borderRadius:3,
    },
    videoBox:{
        border:'1px solid #E4E4E4',
        height:'34.8vh',
        overflow:'auto'
    },
    link:{
        textDecoration:'none',
        color: theme.palette.primary.dark
    },
    invalid:{
        backgroundColor: "#EA555533",
        marginTop:'3vh',
        marginBottom:'3vh',
        paddingTop:'1vh',
        paddingBottom:'1vh',
        textAlign:'center',
    }
    
});

const INITIAL_ASSESS_COMPLETED = 2
const INITIAL_ASSESS_INVITED = 1
const INITIAL_ASSESS_NOT_INVITED = 0

class EmployeeCardSummary extends React.Component {
  
    constructor(props) {
        super(props);           
        this.moveTo = this.moveTo.bind(this);
        this.openReport = this.openReport.bind(this); 
        this.inviteToAssessment = this.inviteToAssessment.bind(this)
        this.closeInvite = this.closeInvite.bind(this)
        this.refresh = this.refresh.bind(this)
        this.remind = this.remind.bind(this)
        this.closeRemindDialog = this.closeRemindDialog.bind(this)
    }

    state = {
        employee: this.props.employee?this.props.employee:{},
        reportParts: this.props.reportParts?this.props.reportParts:[],
        displayScores:[],
        invalidReport:false,
        loading:true,
        isMedLow:false,
        singleReportOpen: false,
        openReportId:null,
    };

    componentDidMount(){
        let scores=[], displayScores=[]
        if (this.props.employee ) {
            this.setState({employee: this.props.employee})
            const initialReportInd = ReportsManager.findInitialReport(this.props.employee)
            const initialReport = this.props.employee.report[initialReportInd]
            this.setState({initialReport})
            const initialAssessInd = this.findInitialAssessment(this.props.employee)
            if (this.props.employee.assessment[initialAssessInd] && this.props.employee.assessment[initialAssessInd].completed_at){
                scores = [...this.props.employee.assessment[initialAssessInd].assessment_scores]
                displayScores = scores.filter(score => score.trait.is_display === true)
                this.setState({displayScores})
                if (this.props.reportParts) {
                    this.setState({reportParts: this.props.reportParts})
                }     
                this.setState({initialState: INITIAL_ASSESS_COMPLETED})   
            }
            else{
                this.props.employee.assessment[initialAssessInd]?this.setState({initialState: INITIAL_ASSESS_INVITED}):this.setState({initialState: INITIAL_ASSESS_NOT_INVITED})
                this.setState({noInitialAssessment:true})
            }
        } 
    }
  
   
    componentWillReceiveProps(nextProps) {   
        // TODO: HANDLE INVALID INITIAL REPORT - DELETE THE REPORT WHEN MOVING TO EMPLOYEE
        if (nextProps.employee && nextProps.employee!==this.state.employee ){
            this.setState({employee:nextProps.employee});  
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
        }
        if (nextProps.loading === this.state.loading) {
            this.setState({loading: nextProps.loading})
        }
    }

    findInitialAssessment(employee){
        const empOngoingIndAssess = employee.assessment.findIndex(({ type, completed_at }) => (type === AssessmentTypes.INITIAL_RISK_AND_PERSONALITY_ASSESSMENT || type === AssessmentTypes.INITIAL_PERSONALITY_ONLY_ASSESSMENT) && completed_at)
        if (empOngoingIndAssess > -1){
            return empOngoingIndAssess
        }
        return null
    }
    
    moveTo(dest){
        this.props.moveTo(null, dest)
    }
    openReport(type, reportId){
        this.props.openReport(type, reportId)
    }
    inviteToAssessment(priority){
        this.setState({assignTestOpen:true, assigned: this.state.employee.id},
            this.setState({assignDialog:
                <AssignTestDialog login={this.props.admin} open={true} closeDialog={this.closeInvite} human_resource={this.state.employee} refresh={this.refresh} context={priority?priority:'employee'} noInitial={this.state.noInitialAssessment} ></AssignTestDialog>
            })
        )
    }
    remind(assessment){
        // find the relevant assessment
        const assesments = this.state.employee.assessment
        const openAssess = assesments.filter(assessment => assessment.status == 0 || assessment.status === 1)
        const assess = openAssess && openAssess.length? openAssess[0]:null
        this.setState({remindTestOpen:true, assigned: this.state.employee.id},
            this.setState({remindDialog:
                <ReminderDialog login={this.props.admin} open={true} closeDialog={this.closeRemindDialog} human_resource={this.state.employee} refresh={this.refresh} context='employee' assessment={assess}></ReminderDialog>
            })
        )
    }

    refresh(code, msg){
        this.props.refresh(code, msg);
    }
    
    closeInvite(){
        this.setState({assignTestOpen:false});
        this.setState({assignDialog:null});
    }
    closeRemindDialog(){
        this.setState({remindTestOpen:false});
        this.setState({remindDialog:null});
    }
   
    render() {
        const { classes } = this.props;
        return (
           
            <div style={{width:'100%', background:'#FAFAFB', paddingInline:10, borderTop:'1px solid #e8e8e8'}}>
            <Grid container >
            <Grid item xs={8} className={classes.formRow}>
                <Grid container>
                   
                    <Grid item xs={6}  style={{paddingInlineEnd:'1vh'}} >
                        <EmployeeCardSummaryStats employee={this.state.employee} moveTo={this.moveTo} noInitial={this.state.noInitialAssessment}></EmployeeCardSummaryStats>
                    </Grid> 
                    <Grid item xs={6}  >
                        <EmployeeCardSummaryDates employee={this.state.employee} reportParts={this.state.reportParts} moveTo={this.moveTo} noInitial={this.state.noInitialAssessment} openReport={this.openReport} assign={this.inviteToAssessment} remind={this.remind}></EmployeeCardSummaryDates>
                    </Grid>
                    
                    <Grid item xs={12} style={{paddingTop:'2vh'}}>
                        <EmployeeCardSummaryHisory employee={this.state.employee} reportParts={this.state.reportParts} moveTo={this.moveTo} noInitial={this.state.noInitialAssessment} remind={this.remind}></EmployeeCardSummaryHisory>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} className={classes.formRow} >   
                    <a href="#0" onClick={()=>{this.moveTo(1)}} className={classes.link}>
                        <SingleReportPersonalityBox  report={this.state.initialReport} reportParts={this.state.reportParts} moveTo={this.moveTo} invalidReport={this.state.invalidReport} ></SingleReportPersonalityBox>  
                    </a> 
            </Grid>

            
            </Grid>
            {this.state.assignDialog}
            {this.state.remindDialog}
        </div>
    );
  }
}
EmployeeCardSummary.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withStyles(styles)(EmployeeCardSummary),EmployeeCardSummary));