
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import EgHttp from './EgHttp';
import LogRocket from 'logrocket';

//import LangPicker from './LangPicker';

const VideoManager = {
    
    getTokens: async function(candidate){
        console.log("CALLING GET TOKENS")
        const camToken = await this.getToken(candidate, "camera")
        const screenToken = await this.getToken(candidate, "screen")
        const tokens = { camera: camToken, screen: screenToken }
        const tokensSize = Object.keys(tokens).length
        console.log(`Agora Tokens Object size is ${tokensSize}`)
        return tokens
    },

    getInterviewQuestionToken: async function(candidate, interview_id, question_id) {
      const randPart = Math.floor(Math.random() * 100)
      const uid_for_agora = parseInt(`${candidate.hr_id}${randPart}`)
      const rawChannel = `chiw${interview_id}qid${question_id}`
      const video_name = `inrvw${interview_id}qid${question_id}`
      const device = 'camera'
      
      console.log(`Asking for remote interview agora tokens. uid ${uid_for_agora}, device ${device}, channel ${rawChannel}`)
      let response = await EgHttp.get(`/agora_connect/token/${uid_for_agora}/${device}/${rawChannel}`, true)
      if (response.status !== 200) {
          console.error('ERROR - cannot read token from backend')
          return null;
      }
      console.log('Token response OK, fetching data')
      const tokenres = await response.json();
      console.log(`Token data is here: ${JSON.stringify(tokenres)}`)
      const token = tokenres.token
      const channel = tokenres.channel
      const apid = tokenres.apid
  
      const tokenData = {
          token: token,
          channel: channel,
          rawchannel: rawChannel,
          uid: uid_for_agora,
          apid: apid,
          name: video_name,
          org: `org${candidate.org_id}`,
          device: device,
          videoPath: [`inrvw${interview_id}`, `qid${question_id}`]
      }
      const agoraTokens = {'camera': tokenData}
      return agoraTokens;
    },

    getToken: async function(candidate, device){
        const uid_for_agora = candidate.hr_id
        const test_id = candidate.test_id
        const rawChannel = `chas${test_id}`
        const video_name = `assmt${test_id}`
        console.log(`Asking for agora tokens uid ${uid_for_agora}, device ${device}, channel ${rawChannel}`)
        let response = await EgHttp.get(`/agora_connect/token/${uid_for_agora}/${device}/${rawChannel}`, true)
        if (response.status !== 200) {
            console.log('ERROR - cannot read token from backend')
            return;
        }
        console.log(`Response OK, waiting for data. res ${response}`)
        const tokenres = await response.json();
        console.log(`data ${JSON.stringify(tokenres)}`)
        const token = tokenres.token
        const channel = tokenres.channel
        const apid = tokenres.apid

        const tokenData = {
            token: token,
            channel: channel,
            rawchannel: rawChannel,
            uid: uid_for_agora,
            apid: apid,
            name: video_name,
            org: `org${candidate.org_id}`,
            device: device
        }
        return tokenData;
    },

    startRecordingCam: async function(agoraTokens, cameraVideoTrack, recordSound=false) {
        const cameraIsLive = (cameraVideoTrack !== null)
        if (!cameraIsLive) {
          LogRocket.error("AGORA - Not recording camera, camera appears to be off")
          console.error(`Camera is not alive, cannot start recording. Agora tokens ${JSON.stringify(agoraTokens)}`)
          return false
        }

        let rqBody = {
          channel: agoraTokens.camera.rawchannel,
          name: agoraTokens.camera.name,
          organization: agoraTokens.camera.org,
          device: agoraTokens.camera.device,
          recordSound: recordSound
        }
        if (agoraTokens.camera.videoPath !== null && typeof agoraTokens.camera.videoPath !== 'undefined') {
          console.log('ADDING VIDEO PATH TO REQUEST BODY')
          LogRocket.info('Defining video path items')
          rqBody.videoPath = agoraTokens.camera.videoPath
        }

        LogRocket.info(`AGORA - Start recording from camera on raw channel ${rqBody.channel}`)
        console.log(`Start recroding camera on channel ${rqBody.channel}`)
        const recordingRes = await EgHttp.post('/agora_connect/startrecording', JSON.stringify(rqBody), true)
        if (recordingRes.status !== 200) {
          console.error(`Failure when POSTing agora_connect/startrecording. body ${JSON.stringify(rqBody)}`)
          LogRocket.error('AGORA - Failure when attempting to start recording video', {request: rqBody, response: recordingRes})
          LogRocket.captureMessage('AGORA - Failure when starting to record camera', {
            tags: {
              topic: 'AGORA',
              device: 'CAM'
            },
            extra: {
              request: rqBody,
              response: recordingRes
            },
          });
          
          return false
        }
        console.log(`Apparently we started recording OK: body ${rqBody}`)
        return true
    },

    startRecordingScreen: async function(agoraTokens) {
        const rqBody = {
          channel: agoraTokens.screen.rawchannel,
          name: agoraTokens.screen.name,
          organization: agoraTokens.screen.org,
          device: agoraTokens.screen.device
        }
        LogRocket.info(`AGORA - Start recording screen raw channel: ${rqBody.channel}`)
        console.log(`Start recroding screen on channel ${rqBody.channel}`)
        const recordingRes = await EgHttp.post('/agora_connect/startrecording', JSON.stringify(rqBody), true)
        if (recordingRes.status !== 200) {
          console.error(`Failure when POSTing agora_connect/startrecording. body ${JSON.stringify(rqBody)}`)
          LogRocket.error('AGORA - Failure when attempting to start recording video', {request: rqBody, response: recordingRes})
          LogRocket.captureMessage('AGORA - Failure when starting to record camera', {
            tags: {
              topic: 'AGORA',
              device: 'SCREEN'
            },
            extra: {
              request: rqBody,
              response: recordingRes
            },
          });
        }
        console.log(`Apparently we started recording OK: body ${rqBody}`)
    },

    // stopRecordingCam: async function(agoraTokens, test_id) {
    //     LogRocket.info("AGORA - Stop Recording Camera Request")
    //     console.log(`Stop recording camera. tokens ${JSON.stringify(agoraTokens)}, assid ${test_id}`)
    //     const url = `/agora_connect/stoprecording/${agoraTokens.camera.device}/${agoraTokens.camera.rawchannel}/${test_id}`
    //     const stopResponse = await EgHttp.get(url, true)
    //     if (stopResponse.status !== 200) {
    //       console.error(`Failed to stop recording camera of assid ${test_id}`)
    //       LogRocket.error('AGORA - There were problems stoping the recording. It will stop automatically 30 seconds after camera stops broadcasting', {url: url, response: stopResponse})
    //       LogRocket.captureMessage('AGORA - Failure when stopping to record camera', {
    //         tags: {
    //           topic: 'AGORA',
    //           device: 'CAM'
    //         },
    //         extra: {
    //           url: url,
    //           response: stopResponse
    //         },
    //       });
    //     }
    // },

  //   stopRecordingScreen: async function(agoraTokens, test_id) {
  //     LogRocket.info("AGORA - Stop Recording Screen Request")
  //     console.log(`Stop recording screen. tokens ${JSON.stringify(agoraTokens)}, assid ${test_id}`)
  //     if (agoraTokens) {
  //       const url = `/agora_connect/stoprecording/${agoraTokens.screen.device}/${agoraTokens.screen.rawchannel}/${test_id}`
  //       const stopResponse = await EgHttp.get(url, true)
  //       const stopResponseData = await stopResponse.json()
  //       if (stopResponse.status !== 200) {
  //         console.error(`Failed to stop recording screen of assid ${test_id}`)
  //         LogRocket.error('AGORA - There were problems stoping the screen capture recording. It will stop automatically 30 seconds after camera stops broadcasting', { url: url, response: stopResponse })
  //         LogRocket.captureMessage('AGORA - Failure when stopping to record sceen capture', {
  //           tags: {
  //             topic: 'AGORA',
  //             device: 'SCREEN'
  //           },
  //           extra: {
  //             url: url,
  //             response: stopResponseData
  //           },
  //         });
  //       }
  //     } else {
  //       console.warn("Agora data not available, probably nothing to stop")
  //     }
  // },

  // Both functions above merged into one - DELETE the comented functions above (28 - Oct - 2022)
  stopRecordingScreenAndCamera: async function(agoraTokens, test_id) {
    LogRocket.info("AGORA - Stop Recording Screen and Camera Request")
    console.log(`Stop recording screen and camera. tokens ${JSON.stringify(agoraTokens)}, assid ${test_id}`)
    if (agoraTokens) {
      const url = `/agora_connect/stoprecording/${test_id}?channels[]=${agoraTokens.screen.rawchannel}&channels[]=${agoraTokens.camera.rawchannel}&devices[]=${agoraTokens.screen.device}&devices[]=${agoraTokens.camera.device}`
      console.log(`URL: ${url}`)
      const stopResponse = await EgHttp.get(url, true)
      const stopResponseData = await stopResponse.json()
      if (stopResponse.status !== 200) {
        console.error(`Failed to stop recording screen of assid ${test_id}`)
        LogRocket.error('AGORA - There were problems stoping the screen capture recording. It will stop automatically 30 seconds after camera stops broadcasting', { url: url, response: stopResponse })
        LogRocket.captureMessage('AGORA - Failure when stopping to record sceen capture', {
          tags: {
            topic: 'AGORA',
            device: 'SCREEN'
          },
          extra: {
            url: url,
            response: stopResponseData
          },
        });
      }
    } else {
      console.warn("Agora data not available, probably nothing to stop")
    }

  },

  stopRecordingInterview: async function (agoraTokens, response_id) {
    LogRocket.info("AGORA - Stop Recording Interview")
    console.log(`Stop recording interview. tokens ${JSON.stringify(agoraTokens)}, response id ${response_id}`)
    const url = `/agora_connect/stoprecordinginterview/${agoraTokens.camera.device}/${agoraTokens.camera.rawchannel}/${response_id}`

    const stopResponse = await EgHttp.get(url, true)
    if (stopResponse.status !== 200) {
      console.error(`Failed to stop recording interview of response ${response_id}`)
      LogRocket.error('AGORA - There were problems stoping the recording. It will stop automatically 30 seconds after camera stops broadcasting', { url: url, response: stopResponse })
      LogRocket.captureMessage('AGORA - Failure when stopping to record camera', {
        tags: {
          topic: 'AGORA',
          device: 'CAM'
        },
        extra: {
          url: url,
          response: stopResponse
        },
      });
    }
  },

  imageDataToBlob: function(imageData) {
      let w = imageData.width;
      let h = imageData.height;
      let canvas = document.createElement("canvas");
      canvas.width = w;
      canvas.height = h;
      let ctx = canvas.getContext("2d");
      ctx.putImageData(imageData, 0, 0);        // synchronous
    
      // const blobed = canvas.toBlob((blobedImage) => {
      //   console.log(`Converted to blob, returning jpeg, 0.9 quality`)
      //   return blobedImage
      // }, 'image/jpeg', 0.9)
      // console.dir(blobed)
      // return blobed
      // const prom =  Promise((resolve) => {
      //  return canvas.toBlob(resolve); // implied image/png format
      // });
      // prom.then((res) => {
      //   console.log('returning .... 44.4.4.4.')
      //   return res
      // })
      return new Promise((resolve, reject) => {
        canvas.toBlob(resolve, 'image/jpeg', 0.9); // implied image/png format
      })
  },

  waitingForAnswer: false,
  uploadVideoFrame: async function (videoTrack, assid, filnameSufix=null) {
    if (this.waitingForAnswer) {
      console.warn('Waiting for process video frame to finish. Not sending another one.')
      return
    }
    try{
      const frameData = videoTrack.getCurrentFrameData()

      console.log(`Frame caputred size w${frameData.width}xh${frameData.height} color space ${frameData.colorSpace}. Size ${frameData.data.length}`)
      console.log('Converting to BLOB')
      const blobedFrameData = await this.imageDataToBlob(frameData)
      console.log(`blobed type: ${blobedFrameData.type}, size: ${blobedFrameData.size} typeof: ${typeof(blobedFrameData)}`)
      const formData = new FormData()
      // fails const frameBlob = new Blob(blobedFrameData)
      const sufix = filnameSufix ? `_${filnameSufix}` : ''
      const filename = `ass${assid}${sufix}`
      formData.append('video-frame', blobedFrameData, filename)

      this.waitingForAnswer = true
      const res = await EgHttp.putFile(`/data/analyzevideoframe/${assid}`, formData, true);
      const response = await res.json()
      this.waitingForAnswer = false
      if (res.status !== 200) {
        console.error('Something went wrong uploading video frame')
      } else {
        console.log(`Video frame ${filnameSufix} submitted for review.`)
      }
      console.log(JSON.stringify(response))
      return response
    }
    catch(err){
      console.error(err.message)
      return null
    }
  }
}

VideoManager.propTypes = {
   cookies: instanceOf(Cookies).isRequired
};
export default withCookies(withRouter(VideoManager));
