import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Drawer, Table, TableRow, TableCell } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { setAdminDetails } from "../../redux/admin";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import EgButton from '../../components/EgButton';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import GradeBar from '../../components/GradeBar';

 
const mapStateToProps = state => ({
    admin: state.admin.value
  });

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    formRow:{
        paddingInline:5,
        paddingTop:8,
        paddingBottom:8,
    },
    boxTitle:{
        padding:'1.5vh',
        paddingInline:30,
        textAlign:'start',
        fontWeight:600,
        backgroundColor: "#FAFAFB"
    },
});
  
class CompareCVtoRequirements extends React.Component {
  
    constructor(props) {
        super(props);            
        this.closeDrawer = this.closeDrawer.bind(this);  
    }

    state = {
        addOpen: this.props.addOpen,
        candidates: this.props.candidates
    }

    componentDidMount(){
        this.initialize(this.props.candidates, this.props.comparedId)
    }

    componentWillReceiveProps(nextProps) {   
        if (nextProps.openState!==this.state.addOpen ){             
            this.setState({addOpen:nextProps.openState}); 
            this.initialize(this.props.candidates, this.props.comparedId)   
        }
        if (nextProps.comparedId !==this.state.comparedId){
            this.setState({comparedId:nextProps.comparedId});  
            this.initialize(this.props.candidates,nextProps.comparedId)   
        }
    }

    async initialize(candidates, comparedId){
        try{
            if (candidates && comparedId!==null){
                this.setState({meetingReq: candidates[comparedId].cvCompMeeting})
                this.setState({notMeetingReq: candidates[comparedId].cvCompNotMeeting})
                this.setState({grade: candidates[comparedId].cvGrade})
                this.setState({summary: candidates[comparedId].cvSummary})
                this.setState({suggestions: candidates[comparedId].cvSuggestions})
            }
        }
        catch(err){
            console.error(err)
        }
    }

    // handleChange() - updates the state params and fields value in real time
    //--------------------------------------------------------------------------------------
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    
    closeDrawer(){
        this.setState({addOpen:false})
        this.props.handleDrawerState(false)
        this.setState({campaignName:null, forPosition: null, candidatesToCompare:[],  models:[], campaignId:null,meetingReq:null, notMeetingReq:null,summary:null,suggestions:null })
    }

    render() {
        const thisRef = this
        const { classes } = this.props;
        const { t, i18n } = this.props;
        return (
            <div>
            <React.Fragment key={i18n.dir()==='rtl'?'left':'right'}>
            <Drawer anchor={i18n.dir()==='rtl'?'left':'right'} open={this.state.addOpen}  style={{width:800}}>
            <div style={{width:800}}>
                <Paper style={{height:'100%'}} elevation={0}>
                    
                    <Grid container  justify="left" direction="row" alignItems="flex-start" style={{padding:0, paddingTop:'1vh', paddingBottom:50}}>
                            
                            <Grid item xs={9} style={{paddingInlineStart:30, paddingTop:'2vh'}}>
                                <Typography variant={'h1'}>{t('Lookalike - Single - Compare CV to Requirements')}  </Typography>
                            </Grid>
                            
                            <Grid item xs={3} style={{paddingInlineEnd:10, paddingTop:'2vh', textAlign:'end'}} >
                                <EgButton secondary="true" className={classes.closeIcon} onClick={this.closeDrawer} >
                                    <CloseOutlinedIcon style={{fontSize:16}}></CloseOutlinedIcon>
                                </EgButton>     
                            </Grid>

                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'3vh', paddingInline:0}}>
                                <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - CV Similarity')}</Typography>  
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} style={{paddingInlineStart:20}}>
                                {this.state.grade?
                                    <Table className={classes.tableInner} aria-label="inner table" > 
                                        <TableRow key={"row1" } >
                                            <TableCell className={classes.tableCell} style={{width:'10%',paddingBottom:0,paddingInlineStart:0, paddingTop:'0.3vh', borderBottom:0}}>
                                                <div style={{direction:'ltr', textAlign:'end'}}><span style={{fontWeight:500}}>{" "}</span><span style={{fontSize:14}}>{this.state.grade+ "%"}</span></div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'70%', paddingInlineStart:20,paddingTop:18, textAlign: 'start', borderBottom:0}}>
                                                <GradeBar wide={true} variant="determinate" value={this.state.grade} negative={false} boundaries={this.props.boundaries}></GradeBar>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width:'20%',paddingBottom:0,paddingInlineStart:0, paddingTop:'0.3vh', borderBottom:0}}>
                                            &nbsp;
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                :''}
                            </Grid>
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'2vh', paddingInline:0}}>
                                <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - Summary')}</Typography>  
                            </Grid>
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'2h', paddingInline:30}}>
                                <Typography variant={'h4'} >{this.state.summary}</Typography>  
                            </Grid>

                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'2vh', paddingInline:0}}>
                                <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - Meeting Requirements')}</Typography>  
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} style={{paddingInlineStart:40}}>
                                <Typography variant={'h4'}>
                                    {this.state.meetingReq && this.state.meetingReq.length?
                                    this.state.meetingReq.map(function(item){
                                        return(
                                            <li>{item}</li>
                                        )
                                    }):''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'3vh', paddingInline:0}}>
                                <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - Not Meeting Requirements')}</Typography>  
                            </Grid>
                            
                            <Grid item xs={12} className={classes.formRow} style={{paddingInlineStart:40}}>
                                <Typography variant={'h4'}>
                                    {this.state.notMeetingReq && this.state.notMeetingReq.length?
                                    this.state.notMeetingReq.map(function(item){
                                        return(
                                            <li>{item}</li>
                                        )
                                    }):''}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'2vh', paddingInline:0}}>
                                <Typography variant={'h4'} className={classes.boxTitle}>{t('Lookalike - Single - Suggestions')}</Typography>  
                            </Grid>
                            <Grid item xs={12} className={classes.formRow} style={{paddingTop:'2vh', paddingInline:30}}>
                                <Typography variant={'h4'}>
                                    {this.state.suggestions && this.state.suggestions.length && Array.isArray(this.state.suggestions)?
                                    this.state.suggestions.map(function(item){
                                        return(
                                            <li>{item}</li>
                                        )
                                    }):this.state.suggestions}
                                </Typography>
                            </Grid>


                        </Grid>
                </Paper>
            </div>
            </Drawer>
            </React.Fragment>

        </div>     
      
    );
  }
}
CompareCVtoRequirements.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default connect(mapStateToProps, { setAdminDetails })(withTranslation()(withRouter((withStyles(styles))(CompareCVtoRequirements),CompareCVtoRequirements)))