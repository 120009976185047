import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {  Table, TableBody, TableCell,TableRow, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import clock_icon from '../../../img/clock.svg';
//import LangPicker from '../../../util/LangPicker';
import ReportsManager from '../../../managers/ReportsManager';
import { ValidityLevels } from '../../../util/constants/ValidityLevels';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EgValidityPatch from '../../../components/EgValidityPatch';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CompareBar from '../../../components/CompareBar';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import _ from 'lodash';
import EgHtmlToolTip from '../../../components/EgHtmlToolTip';


const Accordion = withStyles({
    root: {
        borderTop: '1px solid #f6f6f6',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
        width:'100%'
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#fffffff',
      marginBottom: -1,
      minHeight: '6vh',
      '&$expanded': {
        minHeight: '6vh',
        backgroundColor:'#fafafa'
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      width:'100%'
    },
  }))(MuiAccordionDetails);

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
   
    formRow:{
        paddingTop:'1.5vh',
        border:0
    },
  });

class ResponseTimeAnalysis extends React.Component {
  
    constructor(props) {
        super(props); 
        this.intializeData = this.intializeData.bind(this);         
        this.isTooSlow = this.isTooSlow.bind(this)   
    }

    state = {
        report: this.props.report?this.props.report:{},
        reportParts: this.props.reportParts?this.props.reportParts:null,
        timeSummaryArr:[],
        timeValidity:0
    };
    
    componentWillReceiveProps(nextProps) {  
        if (nextProps.report && nextProps.report.hr_id){
            this.setState({report:nextProps.report}); 
        }
        if (nextProps.reportParts && nextProps.reportParts!==this.state.reportParts ){
            this.setState({reportParts:nextProps.reportParts});  
            this.intializeData(this.props.report, nextProps.reportParts)
        }
        if (nextProps.expanded!==this.state.expanded ){
            this.setState({expanded:nextProps.expanded});  
        }
        
    }
    
    componentDidMount(){
        this.intializeData(this.props.report, this.state.reportParts)
    }

    intializeData(report, reportParts){
        this.setState({report})
        this.setState({reportParts})
        if (!report) return
        const traitsArr = ReportsManager.getTraitsFromReport(report)
        const maxMyTimes = traitsArr.reduce(function(prev, current) {
            return (_.toNumber(prev.avg_time) > _.toNumber(current.avg_time)) ? prev : current
        }) 
        
        const maxAvgTimes = traitsArr.reduce(function(prev, current) {
            return (_.toNumber(prev.trait_avg) > _.toNumber(current.trait_avg)) ? prev : current
        }) 
        this.setState({barBaseline:Math.max(_.toNumber(maxMyTimes.avg_time),_.toNumber(maxAvgTimes.trait_avg))*1.2})

        this.setState({traitsTimes:traitsArr })
        const timeArr = ReportsManager.setTimeSummary(report, reportParts)
        this.setState({timeSummaryArr: timeArr})   
        if (reportParts){
            const timeRisk = ReportsManager.setTimeRisk(reportParts);
            this.setState({timeSeverity: timeRisk})
        }
        this.setState({timeSummaryArr: timeArr})  
        if (report.dominant_traits && report.dominant_traits[0] === 0){
            this.setState({invalidReport:true})
        }
        let timeValidity
        if (report.validity_obj){
            (report.validity_obj.tooSlowAnswers.length > 0 || report.validity_obj.tooFastAnswers.length > 0) ? timeValidity = ValidityLevels.BORDERLINE : timeValidity = ValidityLevels.VALID
            if (report.validity_obj.tooFastAnswers.length > 3) {//TODO: need to get this number from the server
                timeValidity = ValidityLevels.INVALID
            }
            this.setState({timeValidity})
        }
    }
   
    isTooSlow(id){
        if (this.state.report && this.state.report.validity_obj){
            const ind = this.state.report.validity_obj.tooSlowAnswers.findIndex(({ trait }) => trait.id === id )
            if (ind > -1){
                return true
            }
            return false
        }
    }

    render() {
        const { t } = this.props;
        const { classes } = this.props;
        const thisIns = this
        return (
            
            <Accordion className={classes.accord} expanded={this.state.expanded === 'panelTime'} onChange={(event)=>{this.props.handleChange(event,'panelTime')}}>
                <AccordionSummary style={{width:'100%'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Grid container style={{paddingTop:'0vh', paddingBottom:'0vh',display:'flex', justifyContent:'center', position:'relative'}}>
                            <Grid xs={3} />
                            <Grid xs={3} style={{paddingInline:10, display:'flex', alignItems:'center', justifyContent:'start'}} >
                                <Typography variant={'h4'} style={{textAlign:'start', fontWeight:500, }}>
                                    {t('Report - Validity - Response Time Analysis')}
                                </Typography>
                            </Grid>
                            <Grid xs={2} style={{display:'flex', alignItems:'center'}} />
                            <Grid xs={2} style={{display:'flex', alignItems:'center'}}>
                                <EgValidityPatch validityLevel={this.state.timeValidity}/>                                     
                            </Grid>
                            <Grid xs={2} />
                    </Grid> 
                </AccordionSummary>
                    
                <AccordionDetails style={{width:'100%'}}>
                    <Grid container  style={{paddingTop:'1vh', paddingBottom:'2vh'}}>
                        <Grid xs={12} >
                            <Paper elevation={0} >
                                <Grid container>
                                    <Grid xs={12} style={{paddingInlineStart:20,  paddingTop:'0.5vh'}}>
                                        <Table><TableBody><TableRow>
                                        <TableCell style={{border:0, padding:5, width:0, paddingBottom:0}}><img src={clock_icon}  alt="overall" /></TableCell>
                                        <TableCell style={{border:0, padding:5}}><Typography variant={'h3'} >{t('Report - Time - Response Time Insights')}</Typography></TableCell>
                                        </TableRow></TableBody></Table>
                                        
                                    </Grid>
                                    <Grid xs={12} style={{paddingInline:52, paddingBottom:'3vh'}}>
                                        <Typography variant={'h5'} style={{color:'#58666B', textAlign:'start'}}>{t('Report - Time - Based on the response time')}</Typography>
                                    </Grid>
                                    <Grid xs={12} >
                                    {this.state.timeSummaryArr && this.state.timeSummaryArr.length?
                                        this.state.timeSummaryArr.map((part) => (
                                            <Grid container>
                                                <Grid xs={12} style={{paddingInline:52}}>
                                                    <Typography variant={'h4'} style={{ textAlign:'start', lineHeight:2, paddingBottom: 15}}>{part.text}</Typography>
                                                </Grid>
                                            </Grid>
                                    )):''}
                                    </Grid>
                                </Grid>

                                <Grid container style={{paddingBottom:'6vh'}}>
                                    <Grid xs={6}>
                                        {this.state.traitsTimes? this.state.traitsTimes.map(function(trait, index){return(
                                            !trait.dark?
                                            <Grid container>
                                                <Grid item xs={4} className={classes.formRow} style={{paddingInline:5}}>
                                                
                                                    <Typography variant={'h5'} style={{textAlign:'end',display:'flex', alignItems:'start', justifyContent:'end'}}> 
                                                        {thisIns.isTooSlow(trait.id)?
                                                            <EgHtmlToolTip title={t('Report - Validity - Slow Response Time')} body={t('Report - Validity - Average response time for questions in this trait were especially slow')} context={'warn'} widthm={'15%'}
                                                                innerElement={
                                                                    <WarningRoundedIcon style={{marginInline:5,color:'#F9A846', marginTop:-5, cursor:'pointer'}} />}
                                                            />
                                                            :''}{trait.name}
                                                    </Typography>
                                                
                                                </Grid>  
                                                <Grid item xs={6} className={classes.formRow}>
                                                    <CompareBar variant="determinate" value={trait.avg_time } isref={true} style={{margin:2}} baseline={thisIns.state.barBaseline}></CompareBar>
                                                    <CompareBar variant="determinate" value={trait.trait_avg }  isref={false} style={{margin:2}} baseline={thisIns.state.barBaseline}></CompareBar>
                                                </Grid>
                                                <Grid item xs={2} className={classes.formRow} style={{paddingInlineEnd:0, paddingBottom:5}}>
                                                    <Typography variant={'h5'} style={{marginTop:-5}}>{trait.avg_time}&nbsp;sec</Typography>
                                                    <Typography variant={'h5'} style={{marginTop:0}}>{trait.trait_avg}&nbsp;sec</Typography>
                                                </Grid>
                                            </Grid>:''
                                        )}):''}
                                    </Grid>
                                    <Grid xs={6}>
                                        {this.state.traitsTimes? this.state.traitsTimes.map(function(trait, index){return(
                                            trait.dark?
                                            <Grid container>
                                                <Grid item xs={4} className={classes.formRow} style={{paddingInline:5}}>
                                                
                                                    <Typography variant={'h5'} style={{textAlign:'end',display:'flex', alignItems:'start', justifyContent:'end'}}> 
                                                        {thisIns.isTooSlow(trait.id)?
                                                            <EgHtmlToolTip title={t('Report - Validity - Slow Response Time')} body={t('Report - Validity - Average response time for questions in this trait were especially slow')} context={'warn'} widthm={'15%'}
                                                                innerElement={
                                                                <WarningRoundedIcon style={{marginInline:5,color:'#F9A846', marginTop:-5, cursor:'pointer'}} />}
                                                            />
                                                            :''}{trait.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} className={classes.formRow}>
                                                    <CompareBar variant="determinate" value={trait.avg_time } isref={true} style={{margin:2}} baseline={thisIns.state.barBaseline}></CompareBar>
                                                    <CompareBar variant="determinate" value={trait.trait_avg }  isref={false} style={{margin:2}} baseline={thisIns.state.barBaseline}></CompareBar>
                                                </Grid>
                                                <Grid item xs={2} className={classes.formRow} style={{paddingInlineEnd:0, paddingBottom:5}}>
                                                    <Typography variant={'h5'} style={{marginTop:-5}}>{trait.avg_time}&nbsp;sec</Typography>
                                                    <Typography variant={'h5'} style={{marginTop:0}}>{trait.trait_avg}&nbsp;sec</Typography>
                                                </Grid>
                                            </Grid>:''
                                        )}):''}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        
                    </Grid>    
                </AccordionDetails> 
            </Accordion>
    );
  }
}
ResponseTimeAnalysis.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withTranslation()(withStyles(styles)(ResponseTimeAnalysis),ResponseTimeAnalysis);