import i18n from '../i18n/i18n'
import EgHttp from '../util/EgHttp';


const RoleManager = {
        
    insert: function(orgId, candidateId, eventId, accountId,jwtc){
        EgHttp.post( '/events' ,JSON.stringify({
            orgId: orgId,
            candidateId: candidateId,
            eventId: eventId,
            accountId: accountId }),jwtc)
        return;
        
    },
   

    getText(roleid){
        const roles = this.getRoles()
        const roleInd = roles.findIndex(({ id }) => id === roleid )
        if (roleInd > -1){
            return roles[roleInd].text;
        }
        else{
            return '';
        }
    },

    enable(role,auth){
        
        if (!role) return true
        if(auth%role === 0){
            return true
        }
        else{
            return false
        }
    },
    block(role, auth){
        if (!role) return false
        if(auth%role === 0){
            return true
        }
        else{
            return false
        }
    },

    getRoles(){
        return(
            [
                {id: 3, name: "FULL_SYSTETM_ADMIN", text:i18n.t('Roles - Full System Admin')},
                {id: 5, name: "SYSTEM_ADMIN", text:i18n.t('Roles - System Admin')},
                {id: 7, name: "TEAM_MANAGER_WITH_FULL_TEAM_ACCESS", text:i18n.t('Roles - Team Manager Full Access')},
                {id: 11, name: "TEAM_MANAGER_WITH_LIMITED_TEAM_ACCESS", text:i18n.t('Roles - Team Manager Limited Access')},
                {id: 13, name: "ONLY_INVITATIONS_ACCESS", text:i18n.t('Roles - Only Invitations Access')},
            ]
        )
    },
    SUPER_ADMIN:3,
    SYSTEM_ADMIN:5,
    TEAM_MANAGER_WITH_FULL_TEAM_ACCESS: 7,
    TEAM_MANAGER_WITH_LIMITED_TEAM_ACCESS: 11,
    ONLY_INVITATIONS_ACCESS:13,

    AUTH_SUPER_ADMIN:3,
    AUTH_ADMINS:15,
    AUTH_TEAM_ADMIN:105,
    INVITATIONS_ACCESS: 13,
    AUTH_TEAM_MANAGER:1155,

    

}
export default RoleManager;